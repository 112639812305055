import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserJourneyService {
  constructor(private router: Router) {}

  get userJourney(): Array<string> {
    const userJourneyString = sessionStorage.getItem('UserJourney');
    let userJourney: Array<string> = [];
    if (userJourneyString) {
      userJourney = JSON.parse(userJourneyString);
    }
    return userJourney;
  }

  subscribeToRouterEvents() {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.appendToUserJourney(event.urlAfterRedirects);
      });
  }

  private appendToUserJourney(newEntry: string): void {
    let userJourney = this.userJourney;

    userJourney.push(newEntry);

    // maximum of 50 entries wanted
    if (userJourney.length > 50) {
      userJourney = userJourney.slice(-50);
    }

    sessionStorage.setItem('UserJourney', JSON.stringify(userJourney));
  }
}
