import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertDialogResult,
  AlertService,
  RequestEventMessage,
  RequestStateStore,
  TenantSettingsService
} from '@core/services';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { TranslocoService } from '@ngneat/transloco';
import { RequestService } from '../request.service';
import { RequestContent, RequestContentValidation, RequestPosition } from '../request.types';
import { ProcessBarStepData } from '../shared/process-bar/process-bar.types';
import { UnsavedChangesStateStore } from '../unsaved-changes-state-store.service';
import { RequestEditAction } from './edit.types';
import { PaymentDecision } from './payment-choose-panel';
import { GenericPositionComponent, PositionsComponent } from './positions';

@Component({
  selector: 'request-edit',
  templateUrl: './edit.component.html'
})
export class RequestEditComponent implements AfterViewInit {
  @Input() requestContent!: RequestContent;
  @Input() validationReader?: ValidationReader;
  @Output() move = new EventEmitter<ProcessBarStepData>();
  @Output() mileageUpdate: EventEmitter<number> = new EventEmitter<number>();
  @Output() requestContentChange: EventEmitter<RequestContent> = new EventEmitter<RequestContent>();
  @Output() validation: EventEmitter<string[]> = new EventEmitter<string[]>();

  tireOperationPending = false;
  actionLoading = false;
  selectedAction!: RequestEditAction;
  shouldShowPositions = false;

  @ViewChild(PositionsComponent) private positionComponent?: PositionsComponent;

  /**
   * Constructor
   */
  constructor(
    private requestService: RequestService,
    private alertService: AlertService,
    private router: Router,
    private tenantSettingsService: TenantSettingsService,
    private translocoService: TranslocoService,
    private requestStore: RequestStateStore,
    private unsavedChangesStateStore: UnsavedChangesStateStore,
    private scroller: ViewportScroller
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngAfterViewInit(): void {
    this.checkShowPositions();
    if (this.validationReader) {
      this.highlightPositionsWithValidationErrors(this.validationReader.keys);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get hasOutstandingPayment(): boolean {
    return this.requestContent.paymentInformationData.outstandingPayments?.some(
      (x) => x.productType === this.requestContent.requestProcess.editProductType
    );
  }

  get isUnresolvedOutstandingPayment(): boolean {
    const paymentInformation = this.requestContent.paymentInformationData;
    if (!(paymentInformation && !paymentInformation.selectedPaymentOption)) {
      return false;
    }

    if (!this.requestContent.requestProcess.editProductType) {
      return false;
    }

    if (this.hasOutstandingPayment) {
      return paymentInformation.paymentOptions?.indexOf(paymentInformation.selectedPaymentOption) < 0;
    }

    return false;
  }

  get isBlockingPaymentOptionSelected(): boolean {
    if (!this.hasOutstandingPayment || this.isUnresolvedOutstandingPayment) {
      return false;
    }

    const paymentInformation = this.requestContent.paymentInformationData;
    if (!paymentInformation?.selectedPaymentOption) {
      return false;
    }
    return paymentInformation.blockingPayments.indexOf(paymentInformation.selectedPaymentOption) >= 0;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  sendPaymentInformation(): void {
    const paymentDecision: PaymentDecision = {
      contractId: this.requestContent.requestProcess.generalInformation.contractNumber,
      selectedPaymentOption: this.requestContent.paymentInformationData.selectedPaymentOption,
      requestType: this.requestContent.requestProcess.productType,
      companyCode: this.requestContent.requestProcess.generalInformation.companyCode,
      contractProvider: this.requestContent.requestProcess.contractProvider,
      subtype: this.requestContent.paymentInformationData.selectedSubtype
    };

    this.requestService.sendPaymentInformation(paymentDecision).subscribe();
  }

  checkShowPositions(): void {
    if (!this.hasOutstandingPayment || !this.tenantSettingsService.blockingPaymentOptionsDisableRequestCreation) {
      this.shouldShowPositions = true;
      return;
    }

    if (!this.isBlockingPaymentOptionSelected && this.requestContent.paymentInformationData?.paymentSelectionLocked) {
      this.shouldShowPositions = true;
      return;
    }

    this.shouldShowPositions = false;
  }

  addPositions(editProductType: ProductTypeIdentifier) {
    // TODO: Find related endpoints for validate and save in REST API structure
    this.router.navigate(['/request/edit'], {
      queryParams: {
        requestGuid: this.requestContent.versionId,
        editProductType: editProductType
      }
    });
  }

  save() {
    this.actionLoading = true;
    this.selectedAction = 'Save';

    // We need a timeout because some editable fields have a debounce time and take some times until updating the
    // request content.
    setTimeout(() => {
      this.requestService.save(this.requestContent, undefined, this.selectedAction).subscribe({
        next: (requestContent: RequestContent) => {
          this.updateRequestContent(requestContent);
          this.clearDeletedPositions();
          this.clearValidationErrors();
          this.unsavedChangesStateStore.addEventMessage('Saved');
          this.actionLoading = false;
        },
        error: () => {
          this.actionLoading = false;
        }
      });
    }, 200);
  }

  continue() {
    this.selectedAction = 'Continue';
    this.actionLoading = true;
    const invalidPositions = this.getInvalidPositions();
    if (invalidPositions.length) {
      for (const position of invalidPositions) {
        position.hasValidationError = true;
      }
      this.requestStore.addEventMessage({
        eventName: 'SubmitFailed'
      } as RequestEventMessage);
      this.actionLoading = false;
      return;
    }

    // We need a timeout because some editable fields have a debounce time and take some times until updating the
    // request content.
    setTimeout(() => {
      this.requestService.save(this.requestContent, undefined, this.selectedAction).subscribe({
        next: (requestContent: RequestContent) => {
          this.updateRequestContent(requestContent);
          this.clearDeletedPositions();
          this.clearPositionValidationErrors();
          this.unsavedChangesStateStore.addEventMessage('Saved');
          this.actionLoading = false;
          this.move.emit({ stepName: 'next', requestGuid: requestContent.versionId });
        },
        error: (error: HttpErrorResponse) => {
          this.actionLoading = false;
          if (error.status !== HttpStatusCode.UnprocessableEntity) {
            return;
          }

          const response: RequestContentValidation = error.error;
          // In case we do not hit save until this point, the backend created
          // the first version for us, so we have to save the versionId here
          this.requestContent = response.content;

          // while it is "error", the object was persisted successfully (since we must have a UnprocessableEntity here)
          // so we clear deleted positions anyways, and handle validation errors.
          this.clearDeletedPositions();
          const validationReader = new ValidationReader(response.error);
          this.validation.emit(validationReader.values);
          this.highlightPositionsWithValidationErrors(validationReader.keys);
        }
      });
    }, 200);
  }

  updateDeductibleAmount() {
    const totalDeductibles =
      this.requestContent.requestProcess.generalInformation.deductibleAmount *
      this.requestContent.requestProcess.generalInformation.deductibleQuantity;

    this.requestContent.totalDeductibles = Math.round(totalDeductibles * 100) / 100;
  }

  private getInvalidPositions(): GenericPositionComponent[] {
    const activePositions = this.positionComponent?.positions?.filter((p) => !p.positionData.isPositionMarkedAsDeleted);
    return activePositions?.filter((p) => p.positionFormGroup.invalid) ?? [];
  }

  private clearValidationErrors() {
    this.validation.emit([]);
    this.clearPositionValidationErrors();
    this.requestStore.addEventMessage({ eventName: 'BackendValidation' } as RequestEventMessage);
  }

  private clearPositionValidationErrors() {
    this.requestContent.requestPositions.forEach((p) => (p.validationErrors = new Set<string>()));
  }

  private updateRequestContent(requestContent: RequestContent) {
    this.requestContent.versionState = requestContent.versionState;
    this.requestContent.versionId = requestContent.versionId;
    this.requestContent.databaseId = requestContent.databaseId;
    this.requestContent.displayUndoButton = this.requestContent.versionState != 'New';
  }

  isSaveDisabled() {
    if (this.tenantSettingsService.blockingPaymentOptionsDisableRequestCreation) {
      return this.isUnresolvedOutstandingPayment || this.isBlockingPaymentOptionSelected || this.tireOperationPending;
    }
    return this.isUnresolvedOutstandingPayment;
  }

  isContinueDisabled() {
    return this.isUnresolvedOutstandingPayment || this.isBlockingPaymentOptionSelected || this.tireOperationPending;
  }

  processEditUndo() {
    const confirmUndoRequestDialogRef = this.alertService.open({
      message: this.translocoService.translate('Request_Edit_UndoChanges'),
      actions: {
        confirm: {
          label: this.translocoService.translate('Common_Yes')
        },
        cancel: {
          show: true,
          label: this.translocoService.translate('Common_No')
        }
      }
    });

    confirmUndoRequestDialogRef.afterClosed().subscribe((result: AlertDialogResult) => {
      if (result !== 'confirmed') {
        return;
      }

      this.selectedAction = 'Undo';
      this.actionLoading = true;
      this.requestService
        .getRequestEditData(this.requestContent.versionId, this.requestContent.requestProcess.editProductType)
        .subscribe({
          next: (res: RequestContent) => {
            this.requestContent = res;
            this.clearValidationErrors();
            this.unsavedChangesStateStore.addEventMessage('Undo');
            this.actionLoading = false;
          }
        });
    });
  }

  clearDeletedPositions() {
    this.requestContent.requestPositions = this.requestContent.requestPositions.filter(
      (position: RequestPosition) => !position.isPositionMarkedAsDeleted
    );
  }

  private highlightPositionsWithValidationErrors(keys: string[]) {
    let scrolledToPosition = false;
    this.requestContent.requestPositions.forEach((position, index) => {
      position.validationErrors = new Set<string>();
      const matches = keys.filter((k) => k.startsWith(`RequestPositions[${index}]`));
      for (const match of matches) {
        let inputName = match.split('.').pop() as string;
        if (inputName === 'DamageTypeId') {
          inputName = 'DamageType';
        }
        position.validationErrors.add(inputName);

        // we want to scroll to the first invalid position
        if (!scrolledToPosition) {
          this.scrollToInvalidPosition(index);
          scrolledToPosition = true;
        }
      }
    });
    this.requestStore.addEventMessage({ eventName: 'BackendValidation' } as RequestEventMessage);
  }

  private scrollToInvalidPosition(index: number) {
    const position = this.positionComponent?.positions?.find((p) => p.index == index);
    const element: HTMLElement | undefined = position?.elementRef.nativeElement;
    if (!element) {
      return;
    }

    const { x, y } = element.getBoundingClientRect();
    // scroll 100px above the position to show its header
    this.scroller.scrollToPosition([x, y - 100]);
  }
}
