import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AlertDialogResult, AlertService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { TextModule } from '../../pages/request/summary/summary.types';

@Component({
  selector: 'important-information',
  templateUrl: './important-information.component.html',
  styleUrls: ['./important-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportantInformationComponent implements OnInit, OnDestroy {
  @Input() requestEditOptions?: string;
  @Input() userNote: string = '';
  @Input() selectedTextModuleId?: number;
  @Input() showTextModule?: boolean;
  @Input() textModules?: TextModule[];
  @Input() userNoteInfoTextKey: string = 'Request_Summary_ImportantInformation_Text';
  @Input() initiallyOpen: boolean = false;

  @Output() userNoteChange = new EventEmitter<string>();
  @Output() selectedTextModuleIdChange = new EventEmitter<number>();
  @Output() closeDialogDecision = new EventEmitter<boolean>();

  @ViewChild('importantInformationPanel') importantInformationPanel!: MatExpansionPanel;

  private selectedTextModuleIdSubscription$?: Subscription;
  private userNoteSubscription$?: Subscription;
  selectedTextModuleContent!: string;
  importantInformationForm = this.formBuilder.group({
    selectedTextModuleId: new FormControl<number | null>(null),
    userNote: new FormControl<string>('')
  });
  hasData: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private translocoService: TranslocoService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    // Watch if text module is selected to populate hint with text
    this.watchTextModulesSelection();

    this.watchUserNoteChanges();

    // Show fields based on business logic
    if (this.requestEditOptions && this.requestEditOptions !== 'Full' && this.requestEditOptions !== 'Limited') {
      this.importantInformationForm.get('userNote')?.disable();
    }

    // Populate form data
    this.importantInformationForm.patchValue(
      {
        userNote: this.userNote,
        selectedTextModuleId: this.selectedTextModuleId ?? 0
      },
      { emitEvent: false }
    );

    // If there is data inside important information panel open it by default
    this.hasData = this.userNote?.length > 0 || !!this.selectedTextModuleId;
  }

  ngOnDestroy(): void {
    this.selectedTextModuleIdSubscription$?.unsubscribe();
    this.userNoteSubscription$?.unsubscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private watchTextModulesSelection(): void {
    const selectedTextModuleIdFormControl = this.importantInformationForm.get('selectedTextModuleId');
    if (this.showTextModule && selectedTextModuleIdFormControl) {
      // Attach event on text module section if it is changed then show hint with additional text
      this.selectedTextModuleIdSubscription$ = selectedTextModuleIdFormControl.valueChanges.subscribe((value) => {
        this.selectedTextModuleIdChange.emit(value ?? 0);
      });
    }
  }

  private watchUserNoteChanges(): void {
    const userNoteFormControl = this.importantInformationForm.get('userNote');
    if (!userNoteFormControl) {
      return;
    }
    this.userNoteSubscription$ = userNoteFormControl.valueChanges.subscribe((value) => {
      this.userNoteChange.emit(value ?? '');
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open/Close the panel.
   * @returns true when the toggle was successful
   */
  toggle(): boolean {
    // Trigger this condition only to open panel
    if (!this.importantInformationPanel.expanded) {
      this.importantInformationPanel.toggle();
      return true;
    }

    // If form is empty, then we can close it
    if (!this.userNote?.length && !this.selectedTextModuleId) {
      this.importantInformationPanel.close();
      return true;
    }

    // Otherwise if form is changed alert user that will lose changes with collapsing section
    const closeDialogRef = this.alertService.open({
      message: this.translocoService.translate('Request_Summary_DeleteImportantNotification_Question'),
      actions: {
        confirm: {
          label: this.translocoService.translate('Common_Yes')
        },
        cancel: {
          show: true,
          label: this.translocoService.translate('Common_No')
        }
      }
    });

    closeDialogRef.afterClosed().subscribe((result: AlertDialogResult) => {
      if (result === 'confirmed') {
        this.importantInformationForm.reset();
        this.importantInformationPanel.close();
      }
      this.closeDialogDecision.emit(result === 'confirmed');
    });

    return false;
  }
}
