import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { InvoiceClarificationStateCommon, InvoiceStateCommon } from './invoice-state-label.types';

@Component({
  selector: 'invoice-state-label',
  templateUrl: './invoice-state-label.component.html',
  styleUrls: ['./invoice-state-label.component.scss']
})
export class InvoiceStateLabelComponent implements OnInit {
  @Input() invoiceState!: InvoiceStateCommon | InvoiceClarificationStateCommon | undefined;
  @Input() titleLabelKey: string = '';
  @Input() stateText: string = '';
  tooltip: string = '';

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    if (!this.invoiceState || this.invoiceState == 'Unknown') {
      return;
    }

    if (this.titleLabelKey) {
      this.tooltip = this.translocoService.translate(this.titleLabelKey) + ': ' + this.stateText;
    } else {
      this.tooltip = this.stateText;
    }
  }
}
