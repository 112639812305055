import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import {
  ConfigurationService,
  ContextDataService,
  RoleCommon,
  TenantSettingsService,
  UserService
} from '@core/services';
import { isNullUndefinedEmpty } from '@core/utils/helper-functions';
import { InformationBubbleService } from 'app/components/information-bubble';
import { InboxService } from 'app/pages/inbox/inbox.service';
import { InformationService } from 'app/pages/information/information.service';

@Component({
  selector: 'navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationMenuComponent {
  isMenuOpen = false;
  userId: string;
  dealerId: string;
  legalNoticeUrl: string;
  dataPrivacyPolicyUrl: string;
  dealerDamageUrl: string;
  infocenterUrl: string;
  userGuideUrl: string;
  credi2Url: string;
  isLogOnPageEnabled: boolean;

  constructor(
    private configurationService: ConfigurationService,
    private contextDataService: ContextDataService,
    private tenantSettingsService: TenantSettingsService,
    private inboxService: InboxService,
    private userService: UserService,
    private informationService: InformationService,
    public informationBubbleService: InformationBubbleService
  ) {
    this.userId = this.userService.userId;
    this.dealerId = this.userService.dealerId;

    this.legalNoticeUrl = this.configurationService.legalNoticeUrl?.trim();
    this.dataPrivacyPolicyUrl = this.configurationService.dataPrivacyPolicyUrl?.trim();
    this.dealerDamageUrl = this.configurationService.dealerDamageUrl?.trim();
    this.infocenterUrl = this.configurationService.infocenterUrl?.trim();
    this.userGuideUrl = this.configurationService.userGuideUrl?.trim();
    this.credi2Url = this.configurationService.credi2Url?.trim();
    this.isLogOnPageEnabled = this.configurationService.isLogOnPageEnabled;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get isDashboardAccessible(): boolean {
    return !isNullUndefinedEmpty(this.contextDataService.data.contextId);
  }

  get unreadMessageCount(): number {
    return this.inboxService.unreadMessageCount;
  }

  get unreadInformationCount(): number {
    return this.informationService.unreadCount;
  }

  get showStatusOverviewMenuItem(): boolean {
    return !this.userService.hasRole(RoleCommon.AudiDamageManagingMember);
  }

  get showAftersalesMenuItem(): boolean {
    return this.tenantSettingsService.enableAftersalesOverview && this.userService.hasRole(RoleCommon.Aftersales);
  }

  get showCallMeBackMenuItem(): boolean {
    return this.tenantSettingsService.enableCallMeBack;
  }

  get showInvoiceClarificationMenuItem(): boolean {
    return (
      this.tenantSettingsService.enableInvoiceClarificationMenu &&
      this.userService.hasAnyRoleOf([
        RoleCommon.ServiceAndMaintenance,
        RoleCommon.Tire,
        RoleCommon.Mobility,
        RoleCommon.Warranty,
        RoleCommon.MotorInsurance,
        RoleCommon.InsuranceRepair
      ]) &&
      !this.userService.isManagingDealer
    );
  }

  /**
   * Collapse menu if screen is resized.
   */
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMenuOpen = false;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  onNavClick() {
    this.isMenuOpen = false;
  }
}
