<ng-container *transloco="let t">
  <h2 mat-dialog-title class="ui-test--phone-number-header-title">{{ t('PhoneNumberDialog_InformationText') }}</h2>
  <mat-dialog-content class="mat-typography">
    <mat-card *ngIf="showError" class="message-state error ui-test--phone-number-mat-card-technical-error">
      <mat-card-content>{{ t('Common_TechnicalError') }}</mat-card-content>
    </mat-card>
    <input-with-validation
      resourceKeyPrefix="Common"
      name="phoneNumber"
      type="tel"
      class="ui-test--phone-number-input-with-validation-input"
      [control]="phoneNumber"></input-with-validation>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="ui-test--phone-number-button-cancel" mat-stroked-button [mat-dialog-close]="true">
      {{ t('PhoneNumberDialog_CancelButton') }}
    </button>
    <button
      class="ui-test--phone-number-button-confirm"
      mat-raised-button
      color="primary"
      (click)="confirm()"
      [disabled]="!phoneNumber.valid || confirmLoading"
      [class.button-spinner]="confirmLoading">
      {{ t('PhoneNumberDialog_ConfirmButton') }}
    </button>
  </mat-dialog-actions>
</ng-container>
