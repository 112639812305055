import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import {
  InvoiceClarificationStateCommon,
  InvoiceStateCommon
} from 'app/components/invoice-state-label/invoice-state-label.types';
import { OpenProcessesData } from 'app/pages/open-processes/open-processes.types';
import { StatusDetailsDialogService } from 'app/pages/status-overview/status-details-dialog/status-details-dialog.service';
import { StatusDetailsCloseStatus } from 'app/pages/status-overview/status-details-dialog/status-details-dialog.types';
import { StatusOverviewData } from 'app/pages/status-overview/status-overview.types';

@Component({
  selector: 'overview-panel',
  templateUrl: './overview-panel.component.html',
  styleUrls: ['./overview-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewPanelComponent {
  @Input() data!: OpenProcessesData | StatusOverviewData;
  @Input() isManagingDealer!: boolean;
  @Input() isPanelExpanded = false;
  @Output() refreshData = new EventEmitter();

  constructor(private statusDetailsDialogService: StatusDetailsDialogService) {}

  // The following getter are necessary, because we expect two different Input types
  // here and only one of them has the both date-fields.

  get hasDateOfUpdate(): boolean {
    return 'dateOfUpdate' in this.data;
  }

  get dateOfUpdate(): string {
    return this.data.dateOfUpdate as string;
  }

  get hasValidUntil(): boolean {
    return 'validUntil' in this.data;
  }

  get validUntil(): string {
    return this.data.validUntil as string;
  }

  get hasInvoiceState(): boolean {
    return 'invoiceState' in this.data;
  }

  get invoiceState(): InvoiceStateCommon {
    return this.data.invoiceState as InvoiceStateCommon;
  }

  get invoiceStateText(): string {
    return this.data.invoiceStateText as string;
  }

  get hasRightInvoiceState(): boolean {
    return 'invoiceClarificationState' in this.data || 'onlineInvoiceState' in this.data;
  }

  get rightInvoiceState(): InvoiceStateCommon | InvoiceClarificationStateCommon {
    if (this.data.invoiceClarificationState === 'Unknown') {
      return this.data.onlineInvoiceState as InvoiceStateCommon;
    }

    return this.data.invoiceClarificationState as InvoiceClarificationStateCommon;
  }

  get rightInvoiceStateText(): string {
    if (this.data.invoiceClarificationState === 'Unknown') {
      return this.data.onlineInvoiceStateText as string;
    }

    return this.data.invoiceClarificationStateText as string;
  }

  get rightInvoiceStateTitleLabelKey(): string {
    if (this.data.invoiceClarificationState === 'Unknown') {
      return 'Common_OnlineInvoiceStateTitleLabel';
    }

    return 'Common_InvoiceClarificationStateTitleLabel';
  }

  get isDMS(): boolean {
    return this.data.sourceApplication === 'DMS';
  }

  openStatusDetailsDialog(event: Event, requestGuid: string, productType: ProductTypeIdentifier) {
    event.stopPropagation();
    const statusDetailsDialogRef = this.statusDetailsDialogService.openDialog(requestGuid, productType, '');

    statusDetailsDialogRef.afterClosed().subscribe((result: StatusDetailsCloseStatus) => {
      if (result === StatusDetailsCloseStatus.DeleteFromOpenProcesses) {
        this.refreshData.emit();
      }
    });
  }
}
