import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '@core/services';
import { DateTime, DateTimeFormatOptions } from 'luxon';

/**
 * Convert date into specific string format
 */
@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  /**
   * Transform
   *
   * @param value A date that should be returned in specific format
   */
  transform(
    value: DateTime | Date | string | undefined | null,
    format: string = 'DATE_SHORT',
    applyUserTimeZone: boolean = true
  ): string {
    // Check for more formats:
    // https://github.com/moment/luxon/blob/master/docs/formatting.md

    if (!value) {
      return '';
    }
    const formatToUse: DateTimeFormatOptions = (<any>DateTime)[format];
    formatToUse.day = formatToUse.day === 'numeric' ? '2-digit' : formatToUse.day;
    formatToUse.month = formatToUse.month === 'numeric' ? '2-digit' : formatToUse.month;

    let dateTimeToUse: DateTime;
    if (value instanceof Date) {
      dateTimeToUse = DateTime.fromJSDate(value);
    } else if (typeof value === 'string') {
      dateTimeToUse = DateTime.fromISO(value, { zone: 'UTC' });
      if (!dateTimeToUse.isValid) {
        return value;
      }
    } else {
      dateTimeToUse = value;
    }
    if (applyUserTimeZone) {
      return dateTimeToUse.setZone(this.userService.timeZone).toLocaleString(formatToUse);
    }
    return dateTimeToUse.toLocaleString(formatToUse);
  }
}
