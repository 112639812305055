import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { ErrorHandlingModule } from '@core/services';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { PaginationModule } from 'app/components/pagination/pagination.module';
import { RequestStateLabelModule } from 'app/components/request-state-label/request-state-label.module';
import { SortModule } from 'app/components/sort/sort.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { RequestDuplicatesComponent } from './request-duplicates.component';

@NgModule({
  imports: [
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    PaginationModule,
    RequestStateLabelModule,
    ValidationSummaryModule,
    PageHeaderModule,
    SortModule,
    PipesModule,
    ErrorHandlingModule,
    SharedModule
  ],
  exports: [],
  declarations: [RequestDuplicatesComponent],
  providers: []
})
export class RequestDuplicatesModule {}
