import { Pipe, PipeTransform } from '@angular/core';
import { LocaleFormatService } from '@core/services';

@Pipe({
  name: 'numericFormat'
})
export class NumericFormatPipe implements PipeTransform {
  PHONE_NUMBERS_FORMATS: RegExp = /(\+?\d+[- ]?)?(\(\d+\)[- ]?)?(?=\d([- ]?\d)*\d*)/g;

  constructor(private localeFormatService: LocaleFormatService) {}

  /**
   * Accepts numbers and text containing numbers normalizing numbers into correct format with thousands groupings and decimal separators
   * Example (DE locale): 'You have exceeded value limits between 0 - 2000. You have entered 20000.34' -> 'You have exceeded value limits between 0 - 2.000. You have entered 20.000,34'
   * @param value - Input parameter number or text containing number
   * @returns - Input value with correctly formatted numbers
   */
  transform(value?: string | number): string {
    if (!value || value == null) {
      return '';
    }
    const isNumericType = typeof value == 'number';
    let originValue = isNumericType ? value.toString() : value;

    if (isNumericType) {
      return this.localeFormatService.formatNumericString(originValue);
    }

    // This regular expression extracts all numbers also with decimal places from text. Dash separated numbers like phone numbers are removed from formatting 0123-111-1234567
    originValue
      ?.replace(this.PHONE_NUMBERS_FORMATS, '')
      .match(/-?\d+(\.\d+)?/g)
      ?.forEach((numericPart) => {
        const formattedNumericPart = this.localeFormatService.formatNumericString(numericPart);
        originValue = originValue.replace(numericPart, formattedNumericPart);
      });

    return originValue;
  }
}
