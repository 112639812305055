import { Inject, Injectable } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateAdapter, MatDateFormats as MatLuxonDateFormats } from '@matheo/datepicker/core';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class DateTimeService {
  /**
   * Constructor
   */
  constructor(
    private dateAdapter: DateAdapter<DateTime>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatLuxonDateFormats
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Static properties
  // -----------------------------------------------------------------------------------------------------

  /**
   * Default custom date format configuration
   */
  static CUSTOM_MAT_DATE_FORMATS: MatLuxonDateFormats = {
    parse: {
      /**
       * This property is used when you type manually date in field which of these formats will be parsed
       */
      dateInput: ['ddMMyy', 'ddMMyyyy', 'dd.MM.yyyy', 'dd/MM/yyyy', 'd.M.yyyy', 'd.MM.yyyy', 'dd.M.yyyy'],
      datetimeInput: 'f',
      timeInput: 'h:mm',
      monthInput: 'LLL',
      yearInput: 'yyyy'
    },
    display: {
      dateInput: 'dd.MM.yyyy',
      datetimeInput: 'f',
      timeInput: 'T',
      monthInput: 'LLL yyyy',
      yearInput: 'yyyy',
      dateA11yLabel: 'LL',
      monthLabel: 'LLL',
      monthDayLabel: 'LLL d',
      monthDayA11yLabel: 'LLLL d',
      monthYearLabel: 'MMMM yyyy',
      monthYearA11yLabel: 'MMMM yyyy',
      timeLabel: 'T'
    }
  };

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Configure date pickers and adapters based on culture date format
   */
  configureCulture(culture: string) {
    // TODO: Consider better way of doing these things dynamically, like new property in tenant settings
    let dateFormat = '';
    switch (culture) {
      case 'en-GB':
      case 'es':
        dateFormat = 'dd/MM/yyyy';
        break;
      case 'de-DE':
      case 'de-AT':
        dateFormat = 'dd.MM.yyyy';
        break;
      default:
        dateFormat = 'dd/MM/yyyy';
        break;
    }

    this.dateFormats.display.dateInput = dateFormat;
  }

  /**
   * The language provided will be used as translation of date picker component and date adapter functioning
   */
  configureLocale(language: string) {
    this.dateAdapter.setLocale(language);
  }
}
