import { Component, QueryList, ViewChildren, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTab } from '@angular/material/tabs';
import { Params, Router } from '@angular/router';
import { AlertDialogResult, AlertService, UserService } from '@core/services';
import { ExternalApplicationAssignResult, ProductTypeIdentifier } from '@core/types';
import { TranslocoService } from '@ngneat/transloco';
import { RequestService } from 'app/pages/request/request.service';
import { ALL_PRINT_TABS, StatusDetails, StatusDetailsCloseStatus } from './status-details-dialog.types';
import { StatusDetailsService } from './status-details.service';

@Component({
  selector: 'status-details-dialog',
  templateUrl: './status-details-dialog.component.html',
  styleUrls: ['./status-details-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatusDetailsDialogComponent {
  @ViewChildren('matTab')
  set children(components: QueryList<MatTab>) {
    if (this.isLoading) {
      return;
    }

    components.forEach(this.getSelectedTab.bind(this));
  }

  statusDetails!: StatusDetails;
  isLoading = false;
  versionChangeLoading = false;
  selectedTab = 0;
  // used for the button spinners
  deleteInProgress = false;

  versionDropdownOptions: number[] = [];
  selectedVersionIndex: number = -1;
  latestIndex: number = -1;
  latestStatusDetails!: StatusDetails;

  selectedPrintTabs: string[] = ['details'];

  constructor(
    private dialogRef: MatDialogRef<StatusDetailsDialogComponent>,
    private dialog: MatDialog,
    private router: Router,
    private alertService: AlertService,
    private translocoService: TranslocoService,
    private requestService: RequestService,
    private statusDetailsService: StatusDetailsService,
    public viewContainerRef: ViewContainerRef,
    public userService: UserService
  ) {}

  createMobilityRequest(requestGuid: string, contractDatabaseId: number, vehicleIdentificationNumber: string): void {
    this.dialog.closeAll();
    // first hide the dialog. Then redirect -> Shows loading behavior
    setTimeout(() => {
      this.redirectTo('/request/create', {
        productType: 'Mobility',
        contractDatabaseId: contractDatabaseId.toString(),
        referencedRequestGuid: requestGuid,
        vin: vehicleIdentificationNumber
      });
    });
  }

  editRequest(requestGuid: string): void {
    this.dialog.closeAll();
    // first hide the dialog. Then redirect -> Shows loading behavior
    setTimeout(() => {
      this.redirectTo('/request/edit', {
        requestGuid: requestGuid
      });
    });
  }

  editExternalRequest(requestGuid: string, productType: ProductTypeIdentifier): void {
    this.statusDetailsService
      .editExternalRequest(requestGuid, productType)
      .subscribe((result: ExternalApplicationAssignResult) => {
        if (result.isValid) {
          window.open(result.targetUri, result.targetWindow);
        } else {
          this.alertService.open({ message: result.errorMessage });
        }
      });
  }

  onlineInvoice(requestGuid: string): void {
    this.dialog.closeAll();
    // first hide the dialog. Then redirect -> Shows loading behavior
    setTimeout(() => {
      this.router.navigate(['/request/online-invoice'], {
        queryParams: {
          requestGuid: requestGuid
        }
      });
    });
  }

  requestInvoiceClarification(): void {
    this.dialog.closeAll();
    setTimeout(() => {
      this.router.navigate(['/invoice-clarification/start'], {
        queryParams: {
          internalInvoiceNumber: this.statusDetails.internalInvoiceNumber,
          productType: this.statusDetails.productType,
          requestGuid: this.statusDetails.requestGuid
        }
      });
    });
  }

  cancelInvoiceClarification(): void {
    this.dialog.closeAll();
    setTimeout(() => {
      this.router.navigate(['/invoice-clarification/cancel'], {
        queryParams: {
          invoiceClarificationId: this.statusDetails.invoiceClarificationHistoryData.invoiceClarificationId,
          productType: this.statusDetails.productType,
          editOptions: this.statusDetails.invoiceClarificationHistoryData.invoiceClarificationEditOptions,
          requestGuid: this.statusDetails.requestGuid
        }
      });
    });
  }

  cancelRequest(requestGuid: string): void {
    this.dialog.closeAll();

    setTimeout(() => {
      this.router.navigate(['/request/cancel'], {
        queryParams: {
          requestGuid: requestGuid
        }
      });
    });
  }

  deleteFromOpenProcesses(requestGuid: string): void {
    const deleteConfirmationRef = this.alertService.open({
      title: this.translocoService.translate('StatusDetails_DeleteFromOpenProcesses_Title'),
      message: this.translocoService.translate('StatusDetails_DeleteFromOpenProcesses_Text'),
      icon: {
        show: true,
        name: 'info',
        color: 'primary'
      },
      actions: {
        confirm: {
          label: this.translocoService.translate('Common_Yes')
        },
        cancel: {
          show: true,
          label: this.translocoService.translate('Common_No')
        }
      }
    });

    deleteConfirmationRef.afterClosed().subscribe((result: AlertDialogResult) => {
      if (result === 'confirmed') {
        this.deleteRequest(requestGuid);
      }
    });
  }

  printDialogData(): void {
    setTimeout(() => {
      window.print();
    });
  }

  printAllDialogData(): void {
    this.selectedPrintTabs = ALL_PRINT_TABS;
    this.printDialogData();
  }

  onSelectedVersionChange() {
    if (this.selectedVersionIndex == this.latestIndex) {
      this.statusDetails = this.latestStatusDetails;
      return;
    }

    this.versionChangeLoading = true;

    this.statusDetailsService
      .getRelatedRequestVersion(
        this.statusDetails.requestGuid,
        this.selectedVersionIndex,
        this.statusDetails.productType
      )
      .subscribe((statusDetailsForVersionIndex: StatusDetails) => {
        // the old versions are read-only
        statusDetailsForVersionIndex.canCancel = false;
        statusDetailsForVersionIndex.canCancelInvoiceClarification = false;
        statusDetailsForVersionIndex.canDelete = false;
        statusDetailsForVersionIndex.canEdit = false;
        statusDetailsForVersionIndex.canEditExternal = false;
        statusDetailsForVersionIndex.canRequestInvoiceClarification = false;
        statusDetailsForVersionIndex.canRequestMobility = false;
        statusDetailsForVersionIndex.displayOnlineInvoiceButton = false;

        // copy this data because it is exclusively attached to the latest version
        statusDetailsForVersionIndex.invoiceClarificationHistoryData =
          this.latestStatusDetails.invoiceClarificationHistoryData;
        statusDetailsForVersionIndex.invoiceHistory = this.latestStatusDetails.invoiceHistory;

        this.statusDetails = statusDetailsForVersionIndex;
        this.versionChangeLoading = false;
      });
  }

  initializeVersionDropdown(): void {
    for (let i = this.statusDetails.versionIndex; i > 0; i--) {
      this.versionDropdownOptions.push(i);
    }

    this.selectedVersionIndex = this.statusDetails.versionIndex;
    this.latestStatusDetails = this.statusDetails;
    this.latestIndex = this.statusDetails.versionIndex;
  }

  private deleteRequest(requestGuid: string): void {
    if (!requestGuid) {
      return;
    }

    this.deleteInProgress = true;

    this.requestService.deleteRequest(requestGuid).subscribe({
      next: () => {
        this.dialogRef.close(StatusDetailsCloseStatus.DeleteFromOpenProcesses);
      }
    });
  }

  private redirectTo(uri: string, params: Params) {
    // A little hack to force route to be reloaded if you are on the same URL
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri], { queryParams: params }));
  }

  private getSelectedTab(tab: MatTab, index: number): void {
    if (tab.textLabel === 'details' && this.statusDetails.isDetailsDefault) {
      this.selectedTab = index;
    }

    if (tab.textLabel === 'events' && this.statusDetails.isEventPositionDefault) {
      this.selectedTab = index;
    }

    if (tab.textLabel === 'positions' && this.statusDetails.isRequestPositionDefault) {
      this.selectedTab = index;
    }

    if (tab.textLabel === 'requestDetails' && this.statusDetails.isRequestDetailsDefault) {
      this.selectedTab = index;
    }

    if (tab.textLabel === 'history' && this.statusDetails.isInvoiceNotesDefault) {
      this.selectedTab = index;
    }
  }
}
