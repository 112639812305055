import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INTERCEPTOR_SKIP_HEADER } from '@core/interceptors/add-header.interceptor';
import { environment } from 'environments/environment';
import { UploadedFile, UploadFile } from './file-upload.types';

@Injectable({ providedIn: 'root' })
export class FileUploadService {
  constructor(private httpClient: HttpClient) {}

  postFile(url: string, uploadFile: UploadFile) {
    // need to skip the interceptor, otherwise the Content-Type would be overwritten
    const headers = new HttpHeaders().set(INTERCEPTOR_SKIP_HEADER, '');
    const formData = new FormData();
    formData.append('file', uploadFile.file ?? '');
    formData.append('FileCategory', uploadFile.category);
    return this.httpClient.post<UploadedFile[]>(`${environment.isportCoreApiUrl}/${url}`, formData, { headers });
  }

  /**
   * Remove file with the given guid
   * @returns Guid of the removed file.
   */
  removeFile(url: string, guid: string) {
    return this.httpClient.delete<string>(`${environment.isportCoreApiUrl}/${url}/${guid}`);
  }
}
