import { Pipe, PipeTransform } from '@angular/core';
import { Validators } from '@angular/forms';
import { formattedMaxValidator, formattedMinValidator } from '@core/validators/numericValidators';
import { ValidatorLimits } from './validators.types';

@Pipe({
  name: 'validators'
})
export class ValidatorsPipe implements PipeTransform {
  transform(value: ValidatorLimits) {
    return this.generateValidators(value.required, value.min, value.max);
  }

  private generateValidators(required: boolean = false, min: number = NaN, max: number = NaN) {
    const validators = [];

    if (required) {
      validators.push(Validators.required);
    }

    if (!isNaN(min)) {
      validators.push(formattedMinValidator(min));
    }

    if (!isNaN(max)) {
      validators.push(formattedMaxValidator(max));
    }

    return validators;
  }
}
