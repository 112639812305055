import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { MatDatepickerModule } from '@matheo/datepicker';
import { ValidationSummaryModule } from '../validation-summary/validation-summary.module';
import { OfferCriteriaChooseDialogComponent } from './offer-criteria-choose-dialog/offer-criteria-choose-dialog.component';
import { OfferDashboardComponent } from './offer-dashboard/offer-dashboard.component';
import { OfferDetailsDialogComponent } from './offer-details-dialog/offer-details-dialog.component';
import { OfferPanelComponent } from './offer-panel.component';

@NgModule({
  imports: [
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatRadioModule,
    MatDialogModule,
    MatTableModule,
    PipesModule,
    ValidationSummaryModule,
    SharedModule,
    MatCardModule
  ],
  exports: [OfferPanelComponent],
  declarations: [
    OfferPanelComponent,
    OfferCriteriaChooseDialogComponent,
    OfferDashboardComponent,
    OfferDetailsDialogComponent
  ],
  providers: []
})
export class OfferPanelModule {}
