import { Component, Input } from '@angular/core';
import { WarrantorDealer } from '../../dashboard.types';

@Component({
  selector: 'warrantor-dealer',
  templateUrl: './warrantor-dealer.component.html'
})
export class WarrantorDealerComponent {
  @Input() warrantorDealer?: WarrantorDealer;
}
