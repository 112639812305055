import { Component, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { InformationBubbleService } from './information-bubble.service';

@Component({
  selector: 'information-bubble',
  templateUrl: './information-bubble.component.html',
  styleUrls: ['./information-bubble.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InformationBubbleComponent {
  @Input() content?: string = '';

  constructor(private informationBubbleService: InformationBubbleService) {}

  @HostListener('click')
  onClick() {
    this.informationBubbleService.hideBubble();
  }

  onDismissClick(event: MouseEvent) {
    // prevent the anchor tag from navigating to the Information page
    event.preventDefault();
    event.stopPropagation();

    this.informationBubbleService.hideBubble();
  }
}
