import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { RequestProcessStateCommon } from 'app/components/request-state-label/request-state-label.types';
import { Filterable, SortKey } from 'app/components/sort/sort.types';

export interface AftersalesOverviewData extends Filterable {
  chassisNumber: string;
  vehicleBrand: string;
  createdDate: string;
  modifiedDate: string;
  customer: string;
  licensePlateNumber: string;
  modelCode: string;
  modelName: string;
  productType: ProductTypeIdentifier;
  canJumpToJourney: boolean;
  productName: string;
  serviceAdvisor: string;
  aftersalesProcessState: RequestProcessStateCommon;
  continueToken: string;
  isContinueTokenUri: boolean;
  show: boolean;
  mileage: number;
  firstTimeRegistrationDate: string;
  loading: boolean;
  dealerIdentifier: string;
  brokerNumber: string;
}

export const AftersalesOverviewSortKeys: SortKey[] = [
  ['modifiedDate', 'Common_Updated'],
  ['createdDate', 'Common_Created'],
  ['aftersalesProcessState', 'Common_AftersalesProcessState'],
  ['chassisNumber', 'Common_VehicleIdentificationNumber'],
  ['licensePlateNumber', 'Common_LicensePlateNumber'],
  ['customer', 'Common_Customer'],
  ['productName', 'Common_ProductName'],
  ['serviceAdvisor', 'Common_ServiceAdvisor']
];

export const AftersalesOverviewFilterEntries: string[] = [
  'createdDate',
  'modifiedDate',
  'chassisNumber',
  'licensePlateNumber',
  'modelName',
  'modelCode',
  'serviceAdvisor',
  'productType',
  'productName',
  'aftersalesProcessState',
  'customer'
];
