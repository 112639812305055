import { Component, Input, OnInit } from '@angular/core';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { RequestProcessStateCommon } from 'app/components/request-state-label/request-state-label.types';
import { TotalAmounts } from 'app/components/request-total-amounts/request-total-amounts.types';
import { PositionItem, Positions, StatusDetails } from '../status-details-dialog.types';

@Component({
  selector: 'status-details-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class StatusDetailsPositionsComponent implements OnInit {
  @Input() statusDetails!: StatusDetails;

  requestNoteCssClass: string = '';

  constructor(private positionConfigurationService: PositionConfigurationService) {}

  ngOnInit(): void {
    this.requestNoteCssClass = this.getOptionalNoteClass(this.statusDetails.requestState);
  }

  get totalAmounts() {
    return <TotalAmounts>{
      totalNetAmount: this.statusDetails.totalNetAmount,
      totalGoodwill: this.statusDetails.totalGoodwill,
      totalDeductibles: this.statusDetails.totalDeductibles,
      expectedPayout: this.statusDetails.expectedPayout,
      outstandingPayment: this.statusDetails.outstandingPayment,
      isDisplayTotalGoodwillValue: this.statusDetails.displayTotalGoodwillValue,
      isDisplayTotalDeductibleValue: this.statusDetails.displayTotalDeductibleValue,
      isDisplayExpectedPayoutValue: this.statusDetails.displayExpectedPayoutValue,
      isDisplayPayableByCustomer: this.statusDetails.displayPayableByCustomer,
      displayCurrencyUnit: this.statusDetails.displayCurrencyUnit
    };
  }

  getPosition(key: string): PositionItem[] | any[] {
    return this.statusDetails.positions[key as keyof Positions];
  }

  isEnabled(position: PositionItem, parameter: string) {
    return this.positionConfigurationService.isEnabled(
      position.itemType,
      position.productType,
      parameter,
      position.sourceApplication
    );
  }

  canDisplayTireText(position: PositionItem) {
    const tire = position.tirePosition?.tire;
    return tire?.tireName && tire.brand && tire.size && tire.speedIndex && tire.loadIndex;
  }

  getTireText(position: PositionItem) {
    const tire = position.tirePosition.tire;
    return `${position.activityTypeName} ${tire.brand} ${tire.tireName} (${tire.size} ${tire.speedIndex}, ${tire.loadIndex})`;
  }

  private getOptionalNoteClass(state: RequestProcessStateCommon): string {
    switch (state) {
      case 'NotApproved':
        return 'chat-danger';
      case 'InquiryCall':
        return 'chat-warning';
      default:
        return '';
    }
  }
}
