import {
  InvoiceClarificationStateCommon,
  InvoiceStateCommon
} from 'app/components/invoice-state-label/invoice-state-label.types';
import { OverviewData } from 'app/components/overview/overview-panel/overview-panel.types';
import { SortKey } from 'app/components/sort/sort.types';

export interface StatusOverviewData extends OverviewData {
  dateOfUpdate: string;
  invoiceClarificationId: string;
  invoiceState: InvoiceStateCommon;
  invoiceStateText: string;
  hasNewDocument: boolean;
  hasNewMessages: boolean;
  onlineInvoiceState: InvoiceStateCommon;
  onlineInvoiceStateText: string;
  invoiceClarificationState: InvoiceClarificationStateCommon;
  invoiceClarificationStateText: string;
  isDummyRequest: boolean;
  internalInvoiceNumber: string;
  invoiceNumber: string;
}

export const StatusOverviewSortKeys: SortKey[] = [
  ['dateOfUpdate', 'Common_Updated'],
  ['requestIdForCustomer', 'Common_RequestId'],
  ['licensePlateNumber', 'Common_LicensePlateNumber'],
  ['vehicleIdentificationNumber', 'Common_VehicleIdentificationNumber'],
  ['modelName', 'Common_ModelName'],
  ['creationDate', 'Common_Created'],
  ['serviceAdvisor', 'Common_ServiceAdvisor']
];

export const StatusOverviewFilterEntries: string[] = [
  'dateOfUpdate',
  'requestId',
  'requestIdForCustomer',
  'licensePlateNumber',
  'vehicleIdentificationNumber',
  'modelName',
  'creationDate',
  'serviceAdvisor',
  'productType',
  'contractNumber',
  'dealerName',
  'customerName',
  'customerPhoneNumber',
  'internalInvoiceNumber',
  'insuranceReferenceNumber',
  'workshopOrderNumber',
  'invoiceNumber'
];
