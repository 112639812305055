<ng-container *ngIf="requestInvoiceSummary; else requestInvoiceSummaryLoading">
  <div *transloco="let t" class="request-invoice-page">
    <page-header [title]="t('Request_OnlineInvoice_Summary_Title')"></page-header>

    <sending-restricted></sending-restricted>

    <mat-card class="main mb-40">
      <mat-card-content>
        <process-bar type="Invoice"></process-bar>

        <!-- Render for Summary step -->
        <ng-container *ngIf="selectedStep === 'Summary'">
          <div>
            <h2 class="subtitle ui-test--request-online-invoice-header-title">
              {{ t('Request_OnlineInvoiceSummary_Headline') }}
            </h2>
            <small class="ui-test--request-online-invoice-small-information-text">
              {{ t('Request_OnlineInvoiceSummary_InformationText') }}
            </small>
          </div>

          <validation-summary [errorList]="errorList"></validation-summary>

          <!-- General information component -->
          <mat-divider class="my-10"></mat-divider>
          <request-general-information
            [requestContent]="requestContent"
            [isDisplayOnly]="true"></request-general-information>

          <!-- Positions component -->
          <mat-divider class="my-10"></mat-divider>
          <request-positions-preview [requestContent]="requestContent"></request-positions-preview>

          <!-- Position amounts -->
          <request-total-amounts [totalAmounts]="totalAmounts"></request-total-amounts>

          <!-- Contract data component -->
          <mat-divider class="my-10"></mat-divider>
          <contact-data
            [contactData]="requestContent.contactData"
            [showSecondaryEmail]="showSecondaryEmail"
            [editOptions]="requestContent.requestEditOptions"></contact-data>

          <!-- Footer actions-->
          <mat-divider class="my-10"></mat-divider>
          <div class="flex-row justify-between content-center mt-20">
            <div class="justify-start content-center mr-20">
              <mat-checkbox
                [(ngModel)]="requestInvoiceSummary.hasAcceptedOnlineInvoiceGuidelines"
                class="justify-start content-center mr-10 ui-test--request-online-invoice-mat-check-box-invoice-guide-lines"
                color="primary">
                {{ t('OnlineInvoiceSummaryViewModel_HasAcceptedOnlineInvoiceGuidelines') }}
              </mat-checkbox>

              <a
                [href]="requestInvoiceSummary.creditNoteInformationUrl"
                class="external-url justify-start content-center ui-test--request-online-invoice-link-credit-note-information"
                target="_blank"
                rel="noopener"
                *ngIf="requestInvoiceSummary.displayCreditNoteInformationButton">
                <mat-icon color="primary">info</mat-icon>
              </a>
            </div>

            <div class="flex-row justify-end content-center">
              <button
                mat-stroked-button
                class="mr-5 ui-test--request-online-invoice-button-status-overview"
                [disabled]="confirmActionLoading"
                (click)="openStatusDetailsDialog($event)">
                {{ t('Request_Summary_StatusOverview_Index') }}
              </button>
              <button
                mat-raised-button
                class="continue-button ui-test--request-online-invoice-button-confirm"
                color="primary"
                [disabled]="confirmActionLoading || (userService.isSendingRestricted | async)"
                [class.button-spinner]="confirmActionLoading"
                (click)="continue()">
                <mat-icon class="ui-test--request-online-invoice-mat-icon-confirm">skip_next</mat-icon>
                {{ t('Request_Summary_Confirm') }}
              </button>
            </div>
          </div>
        </ng-container>

        <!-- Render for Confirmation step -->
        <ng-container *ngIf="selectedStep === 'TransmissionReport'">
          <request-confirmation [requestGuid]="requestGuid" mode="Transmitted"></request-confirmation>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-template #requestInvoiceSummaryLoading>
  <div class="spinner spinner-small"></div>
</ng-template>
