<ng-container *ngIf="requestContent && !loading">
  <div *transloco="let t" class="request-page">
    <page-header [title]="t('PageTitle_Request_' + requestContent.requestProcess.productType)"></page-header>

    <sending-restricted></sending-restricted>

    <mat-card class="main mb-40">
      <mat-card-content>
        <process-bar
          [productType]="requestContent.requestProcess.productType"
          [step]="requestContent.requestProcess.productType"
          type="General"
          [editOptions]="requestContent.requestEditOptions"
          (stepChange)="onStepChange($event)"></process-bar>

        <validation-summary [errorList]="errorList"></validation-summary>

        <!-- Render for Edit step -->
        <ng-container *ngIf="isEditStep">
          <request-edit
            *ngIf="requestContent"
            [(requestContent)]="requestContent"
            [validationReader]="validationReader"
            (validation)="displayValidation($event)"
            (move)="changeStep($event)"
            (mileageUpdate)="triggerContractSearch($event)"></request-edit>
        </ng-container>

        <!-- Render for Questionnaire step -->
        <ng-container *ngIf="selectedStep === 'Questionnaires'">
          <request-questionnaire
            [requestGuid]="requestGuid"
            [productType]="requestContent.requestProcess.editProductType !== 'Unknown' ? requestContent.requestProcess.editProductType : requestContent.requestProcess.productType"
            (validation)="displayValidation($event)"
            (move)="changeStep($event)"
            (questionnaireDataChange)="setQuestionnaireData($event)"></request-questionnaire>
        </ng-container>

        <!-- Render for Summary step -->
        <ng-container *ngIf="selectedStep === 'Summary'">
          <request-summary
            [requestGuid]="requestGuid"
            [vin]="requestContent.requestProcess.generalInformation.vehicleIdentificationNumber"
            [productType]="requestContent.requestProcess.editProductType"
            (validation)="displayValidation($event)"
            (move)="changeStep($event)"
            (importantInformation)="setImportantInformation($event)"></request-summary>
        </ng-container>

        <!-- Render for Confirmation step -->
        <ng-container *ngIf="selectedStep === 'TransmissionReport'">
          <request-confirmation [requestGuid]="requestGuid"></request-confirmation>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="loading">
  <div class="spinner"></div>
</ng-container>
