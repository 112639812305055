<ng-container *transloco="let t">
  <div class="flex-row justify-end content-center mt-20">
    <strong class="flex flex-1 justify-end content-center ui-test--request-total-amount-strong-total-net-amount-label">
      {{ t('Common_TotalNetAmount') }}:
    </strong>
    <strong class="flex flex-grow-15 justify-end content-center">
      <ng-container *ngIf="totalAmounts.totalNetAmount; else noAmount">
        <span class="ui-test--request-total-amount-span-total-net-amount" style="border-bottom: 3px double">
          {{ totalAmounts.totalNetAmount | currencyOrDash }}
        </span>
        <span
          class="ui-test--request-total-amount-span-totalNetAmountCurrency"
          style="border-bottom: 3px double transparent">
          &nbsp;{{ totalAmounts.displayCurrencyUnit }}
        </span>
      </ng-container>
    </strong>
  </div>

  <div class="flex-row justify-end content-center" *ngIf="totalAmounts.isDisplayTotalGoodwillValue">
    <strong class="flex flex-1 justify-end content-center ui-test--request-total-amount-strong-total-goodwill-label">
      {{ t('Common_TotalGoodwill') }}:
    </strong>
    <strong class="flex flex-grow-15 justify-end content-center ui-test--request-total-amount-strong-total-goodwill">
      <ng-container *ngIf="totalAmounts.totalGoodwill; else noAmount">
        {{ totalAmounts.totalGoodwill | currencyOrDash }} {{ totalAmounts.displayCurrencyUnit }}
      </ng-container>
    </strong>
  </div>

  <div class="flex-row justify-end content-center" *ngIf="totalAmounts.isDisplayTotalDeductibleValue">
    <strong class="flex flex-1 justify-end content-center ui-test--request-total-amount-strong-total-deductible-label">
      {{ t('Common_TotalDeductibles') }}:
    </strong>
    <strong class="flex flex-grow-15 justify-end content-center ui-test--request-total-amount-strong-total-deductible">
      <ng-container *ngIf="totalAmounts.totalDeductibles; else noAmount">
        {{ totalAmounts.totalDeductibles | currencyOrDash }} {{ totalAmounts.displayCurrencyUnit }}
      </ng-container>
    </strong>
  </div>

  <div class="flex-row justify-end content-center" *ngIf="totalAmounts.isDisplayExpectedPayoutValue">
    <strong
      class="flex flex-1 justify-end content-center ui-test--request-total-amount-strong-total-expected-payout-label">
      {{ t('Common_ExpectedPayout') }}:
    </strong>
    <strong
      class="flex flex-grow-15 justify-end content-center ui-test--request-total-amount-strong-total-expected-payout">
      <ng-container *ngIf="totalAmounts.expectedPayout; else noAmount">
        {{ totalAmounts.expectedPayout | currencyOrDash }} {{ totalAmounts.displayCurrencyUnit }}
      </ng-container>
    </strong>
  </div>

  <div class="flex-row justify-end content-center" *ngIf="totalAmounts.isDisplayPayableByCustomer">
    <strong
      class="flex flex-1 justify-end content-center ui-test--request-total-amount-strong-payable-by-customer-label">
      {{ t('Common_PayableByCustomer') }}:
    </strong>
    <strong
      class="flex flex-grow-15 justify-end content-center ui-test--request-total-amount-strong-payable-by-customer">
      <ng-container *ngIf="totalAmounts.expectedCustomerShare; else noAmount">
        {{ totalAmounts.expectedCustomerShare | currencyOrDash }} {{ totalAmounts.displayCurrencyUnit }}
      </ng-container>
    </strong>
  </div>
</ng-container>

<ng-template #noAmount>
  {{ showDashAsNoValue ? '-' : (0 | currencyOrDash : showDashAsNoValue) + ' ' + totalAmounts.displayCurrencyUnit }}
</ng-template>
