import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorMessageDialogComponent } from './error-message-dialog/error-message-dialog.component';
import { ErrorDialogOptions } from './error-message.types';

@Injectable({ providedIn: 'root' })
export class ErrorHandlingService {
  constructor(private _dialog: MatDialog) {}

  showErrorDialog(errorData: HttpErrorResponse, options: ErrorDialogOptions) {
    if (options.closeAllDialogs) {
      this._dialog.closeAll();
    }

    // shift this to the next render cycle, preventing issues with closing previous dialogs
    setTimeout(() => {
      const errorMessageDialog = this._dialog.open(ErrorMessageDialogComponent);
      errorMessageDialog.componentInstance.errorData = errorData;
      if (options.errorMessage) {
        errorMessageDialog.componentInstance.errorMessage = options.errorMessage;
      }
      if (options.showContactButton) {
        errorMessageDialog.componentInstance.showContactButton = options.showContactButton;
      }
      if (options.title) {
        errorMessageDialog.componentInstance.title = options.title;
      }
      if (options.hideServerErrorInformationText) {
        errorMessageDialog.componentInstance.hideServerErrorInformationText = options.hideServerErrorInformationText;
      }
      if (options.redirectUrl) {
        errorMessageDialog.componentInstance.redirectUrl = options.redirectUrl;
      }
      if (options.redirectUrlText) {
        errorMessageDialog.componentInstance.redirectUrlText = options.redirectUrlText;
      }
      if (options.isRedirectUrlExternal) {
        errorMessageDialog.componentInstance.isRedirectUrlExternal = options.isRedirectUrlExternal;
      }
    });
  }
}
