import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@core/modules';
import { AlertService } from './alert.service';
import { AlertDialogComponent } from './dialog/dialog.component';

@NgModule({
  imports: [MatIconModule, MatButtonModule, MatDialogModule, SharedModule],
  exports: [],
  declarations: [AlertDialogComponent],
  providers: [AlertService]
})
export class AlertModule {}
