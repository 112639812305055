import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextDataService } from '@core/services';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class PhoneNumberVerificationService {
  constructor(private contextDataService: ContextDataService, private httpClient: HttpClient) {}

  save(phoneNumber: string) {
    return this.httpClient.patch(
      `${environment.isportCoreApiUrl}/api/settings/user/phone-number?serviceAdvisorId=${this.contextDataService.data.user.serviceAdvisorId}`,
      {
        phoneNumber: phoneNumber
      }
    );
  }
}
