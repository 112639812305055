import { Component, Input } from '@angular/core';

@Component({
  selector: 'self-service-status-label',
  templateUrl: './self-service-status-label.component.html',
  styleUrls: ['./self-service-status-label.component.scss']
})
export class SelfServiceStatusLabelComponent {
  @Input() status: string = 'Unknown';
}
