import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { FileUploadModule } from 'app/components/file-upload/file-upload.module';
import { ImportantInformationModule } from 'app/components/important-information/important-information.module';
import { PositionsModule } from 'app/components/positions/positions.module';
import { RequestTotalAmountsModule } from 'app/components/request-total-amounts/request-total-amounts.module';
import { WorkInstructionsModule } from 'app/components/work-instructions/work-instructions.module';
import { ContactDataModule } from '../../../components/contact-data/contact-data.module';
import { GeneralInformationModule } from '../shared/general-information/general-information.module';
import { PositionsPreviewModule } from '../shared/positions-preview/positions-preview.module';
import { RequestSummaryEventsComponent } from './events/events.component';
import { RequestSummaryQuestionnaireComponent } from './questionnaire/questionnaire.component';
import { RequestSummaryComponent } from './summary.component';

@NgModule({
  imports: [
    ImportantInformationModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDividerModule,
    PositionsModule,
    WorkInstructionsModule,
    GeneralInformationModule,
    PositionsPreviewModule,
    RequestTotalAmountsModule,
    FileUploadModule,
    ContactDataModule,
    PipesModule,
    SharedModule
  ],
  exports: [RequestSummaryComponent],
  declarations: [RequestSummaryComponent, RequestSummaryEventsComponent, RequestSummaryQuestionnaireComponent],
  providers: []
})
export class RequestSummaryModule {}
