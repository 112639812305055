import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { BrowserCompatibilityModule } from '@core/services';
import { InformationBubbleModule } from '../information-bubble';
import { PhoneNumberVerificationModule } from '../phone-number-verification/phone-number-verification.module';
import { AnnouncementComponent } from './announcement/announcement.component';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LayoutComponent } from './layout.component';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';

@NgModule({
  imports: [
    BrowserCompatibilityModule,
    HttpClientModule,
    InformationBubbleModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatDialogModule,
    PhoneNumberVerificationModule,
    RouterModule,
    PipesModule,
    SharedModule
  ],
  exports: [LayoutComponent],
  declarations: [
    BaseLayoutComponent,
    LayoutComponent,
    BreadcrumbComponent,
    NavigationMenuComponent,
    AnnouncementComponent
  ],
  providers: []
})
export class LayoutModule {}
