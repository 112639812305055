<table aria-hidden="true" *transloco="let t">
  <tbody>
    <!-- Contract Number -->
    <tr>
      <td class="ui-test--contract-information-td-contract-number-label">{{ t('Common_ContractNumber') }}</td>
      <td class="contract-number ui-test--contract-information-td-contract-number">{{ contract.contractNumber }}</td>
    </tr>

    <!-- Start Date -->
    <tr *ngIf="contract.contractStartDateHasValue">
      <td class="ui-test--contract-information-td-start-date-label">{{ t('Common_StartDate') }}</td>
      <td class="start-date ui-test--contract-information-td-start-date">
        {{ contract.startDate | dateFormat:'DATE_SHORT':false }}
      </td>
    </tr>

    <!-- Expiration Date -->
    <tr *ngIf="contract.contractExpireDateHasValue">
      <td class="ui-test--contract-information-td-expiration-date-label">{{ t('Common_ExpirationDate') }}</td>
      <td class="expiration-date ui-test--contract-information-td-expiration-date">
        {{ contract.expireDate | dateFormat:'DATE_SHORT':false }}
      </td>
    </tr>

    <!-- Contract State -->
    <tr>
      <td class="ui-test--contract-information-td-contract-state-label">{{ t('Common_ContractState') }}</td>
      <td class="contract-state ui-test--contract-information-td-contract-state">{{ contract.contractState }}</td>
    </tr>

    <!-- Major Customer -->
    <tr *ngIf="contract.showMajorCustomer">
      <td class="ui-test--contract-information-td-major-customer-label">{{ t('Common_MajorCustomer') }}</td>
      <td class="major-customer ui-test--contract-information-td-major-customer">
        {{ contract.majorCustomer | yesNoUnknown }}
      </td>
    </tr>

    <!-- Add Outstanding Payment -->
    <tr *ngIf="contract.showOutstandingCustomerPayment">
      <td class="pt-30 ui-test--contract-information-td-outstanding-customer-paymet">
        {{ t('Dashboard_Index_OutstandingCustomerPayment') }}
      </td>
      <td class="pt-30 outstanding-payment">
        <div *ngFor="let customerPayment of contract.outstandingCustomerPayment; let i = index">
          <mat-icon [class]="'ui-test--contract-information-mat-icon-product-type-icon-' + i">
            {{ customerPayment.productTypeModel.displayText | productTypeIcon }}
          </mat-icon>
          <strong [class]="'ui-test--contract-information-strong-amount-label-' + i">
            {{ t('Dashboard_Index_AmountForProductType') | stringFormat :
            dashboardService.getProductTypeText(customerPayment.productTypeModel.displayText) }}
          </strong>
          <div *ngFor="let item of customerPayment.items; let index = index">
            <span [class]="'ui-test--contract-information-span-amount-to-be-pay-' + index + '-customer-' + i">
              {{ t('Dashboard_Index_AmountToBePay') + ': '}}
            </span>
            <span [class]="'ui-test--contract-information-span-subtype-' + index + '-customer-' + i">
              {{ item.hasSubType ? item.subtype + ' ' : ''}}
            </span>
            <span [class]="'ui-test--contract-information-span-outstanding-amount-' + index + '-customer-' + i">
              {{ item.outstandingAmount + ' '}}
            </span>
            <span [class]="'ui-test--contract-information-span-currency-' + index + '-customer-' + i">
              {{ item.currency + ' '}}
            </span>
          </div>
        </div>
      </td>
    </tr>

    <!-- Additional Agreement -->
    <tr *ngIf="contract.showAdditionalAgreement">
      <td class="pt-30 ui-test--contract-information-td-additional-agreement-label">
        {{ t('Common_AdditionalAgreement') }}
      </td>
      <td class="pt-30 additional-agreement">
        <span
          class="ui-test--contract-information-span-additional-agreement"
          [innerHtml]="contract.shortAdditionalAgreement"></span>
        <a
          *ngIf="contract.additionalAgreement && contract.additionalAgreement.length > 255"
          (click)="showAdditionalAgreement()"
          class="see-more-link ui-test--contract-information-link-more">
          {{ t('Common_More') }}
        </a>
      </td>
    </tr>

    <!-- Covered services -->
    <tr>
      <td class="pt-30 covered-services ui-test--contract-information-td-covered-services-label">
        {{ t('Common_CoveredServices') }}
      </td>
      <td class="pt-30">
        <div *ngFor="let baseProduct of contract.baseProducts; let i = index; let first = first" [class.mt-15]="!first">
          <span
            *ngFor="let productType of productTypesFromProduct(baseProduct); let productIndex = index"
            [class]="'ui-test--contract-information-span-product-type-' + i + '-' + productIndex">
            <!-- Create new when no request exists -->
            <mat-icon
              [class]="'ui-test--contract-information-mat-icon-' + i + '-' + productIndex"
              (click)="startRequestForProductType(productType)">
              {{ productType.displayText | productTypeIcon }}
            </mat-icon>
          </span>

          <strong [class]="'ui-test--contract-information-strong-' + i">{{ baseProduct.name }}</strong>

          <div *ngFor="let agreement of baseProduct.agreements; let agreementIndex = index">
            <span
              style="white-space: pre-wrap"
              class="agreement"
              [class]="'ui-test--contract-information-span-base-product-' + i + '-' + agreementIndex"
              [innerHtml]="agreement.displayText | nl2br | numericFormat"></span>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
