import { MotorInsuranceProcessQuestionDataType } from '@core/pipes';
import { ProductTypeIdentifier, SourceApplication } from '@core/types';
import { FileUploadCategory } from 'app/components/file-upload';
import { InvoiceClarificationHistoryData } from 'app/components/invoice-clarification-history';
import { InvoiceStateCommon } from 'app/components/invoice-state-label';
import { ActivityTypeIdentifierCommon, DamageType, VehicleType } from 'app/components/positions';
import { RequestProcessStateCommon } from 'app/components/request-state-label';

export enum StatusDetailsCloseStatus {
  DeleteFromOpenProcesses
}

export const ALL_PRINT_TABS = [
  'details',
  'events',
  'positions',
  'requestDetails',
  'answer',
  'invoiceHistory',
  'attachments',
  'invoiceClarificationHistory'
];

export interface StatusDetails {
  requestIdForCustomer: string;
  requestGuid: string;
  versionIndex: number;
  requestState: RequestProcessStateCommon;
  productType: ProductTypeIdentifier;
  invoiceNumber: string;
  internalInvoiceNumber?: string;
  invoiceState: InvoiceStateCommon;
  creditNoteNumber: string;
  creditNoteState: InvoiceStateCommon;
  createdDate: string;
  modifiedDate: string;
  licensePlateNumber: string;
  displayCurrencyUnit: string;
  vehicleIdentificationNumber: string;
  modelName: string;
  modelCode: string;
  contractNumber: string;
  contractDatabaseId: number;
  mileage: number;
  totalDeductibles: number;
  dateOfEvent: string;
  isDateOfEventVisible: boolean;
  totalNetAmount: number;
  totalGoodwill: number;
  expectedPayout: number;
  positions: Positions;
  positionTypeKeys: ActivityTypeIdentifierCommon[];
  questionnaires: Questionnaire[];
  events: Event[];
  responseNotes: ResponseNote[];
  rentalCarNotes: ResponseNote[];
  responseNotesByTimestamp: ResponseNote[];
  isOnlineInvoiceEnabled: boolean;
  isOnlineInvoiceRequested: boolean;
  invoiceHistory: InvoiceHistory[];
  invoiceClarificationHistoryData: InvoiceClarificationHistoryData;
  canRequestInvoiceClarification: boolean;
  canCancelInvoiceClarification: boolean;
  responseInformationText: string;
  isReadonly: boolean;
  displayEventPane: boolean;
  displayAnswerPane: boolean;
  displayPositionsPane: boolean;
  displayRequestDetailsPane: boolean;
  displayInvoiceHistoryPane: boolean;
  displayAttachmentsPane: boolean;
  rentalCarContractStatus: InvoiceStateCommon;
  displayTotalGoodwillValue: boolean;
  displayExpectedPayoutValue: boolean;
  displayTotalDeductibleValue: boolean;
  displayOnlineInvoiceButton: boolean;
  canCancel: boolean;
  canDelete: boolean;
  canEdit: boolean;
  canEditExternal: boolean;
  canRequestMobility: boolean;
  insuranceReferenceNumber: string;
  isInsuranceReferenceNumberActive: boolean;
  outstandingPayment?: number;
  displayPayableByCustomer: boolean;
  fileAttachments: FileAttachment[];
  isDetailsDefault: boolean;
  isRequestDetailsDefault: boolean;
  isRequestPositionDefault: boolean;
  isEventPositionDefault: boolean;
  isInvoiceNotesDefault: boolean;
  isAttachmentsSortingAscending: boolean;
  getAttachmentsSortingIndicator: string;
  requestEditOptions: string;
  requestCancelOptions: string;
  shouldPrintButtonBeVisible: boolean;
  isManagingMember: boolean;
  dealerName?: string;
  isDifferentDealer: boolean;
  isClarificationFromDifferentDealer: boolean;
  sourceApplication: SourceApplication;
  workshopOrderNumber: string;
  isLeasingReturnUserAnswerVisible: boolean;
  isLeasingReturnUserAnswer: boolean;
  annotation: string;
  textModuleText: string;
}

export interface Positions {
  MaintenancePackagePosition: any[];
  ServicePosition: PositionItem[];
  WorkPosition: any[];
  SparePart: any[];
  WarrantyPosition: PositionItem[];
  WarrantySparePart: PositionItem[];
  WarrantyDiagnosticPosition: any[];
  TireServicePosition: PositionItem[];
  TireSparePart: any[];
  TirePosition: PositionItem[];
  MobilityPosition: PositionItem[];
  MobilitySparePart: any[];
}

export interface Questionnaire {
  displayText: string;
  keyName: string;
  questions: Question[];
}

export interface Question {
  displayOrder: number;
  isMultiline: boolean;
  displayText: string;
  keyName: string;
  typeKeyName: string;
  value: string;
  groupId: number;
  dataType: MotorInsuranceProcessQuestionDataType;
}

export interface PositionItem {
  laborPositionIndex: number;
  itemType: string;
  itemTypeDisplay: string;
  typeIdentifier: string;
  externalProductNumber: string;
  activityTypeName: string;
  quantity: number;
  quantityUnitText: string;
  displayQuantityUnit: boolean;
  unitPrice: number;
  netFixedPrice: number;
  discount: number;
  netAmount: number;
  goodwill: number;
  goodwillAmount: number;
  materialGoodwill?: number;
  materialGoodwillAmount?: number;
  displayCurrencyUnit: string;
  tirePosition?: any;
  damageType?: DamageType;
  productType: ProductTypeIdentifier;
  rentalReason?: RentalReason;
  sourceApplication: SourceApplication;
  typeOfDamageTitle: string;
  approvalState: string;
  vehicleType: VehicleType;
  responseNotes: ResponseNote[];
  responseNotesByTimestamp: ResponseNote[];
  displayPositionNote: boolean;
}

export interface RentalReason {
  id: number;
  name: string;
  description: string;
  activityTypes?: any;
}

export interface ResponseNote {
  timestamp: string;
  text: string;
  language: string;
}

export interface FileAttachment {
  uid: string;
  filename: string;
  contentType: string;
  contentLength: number;
  fileData?: any;
  fileCategory: FileUploadCategory;
  isFromResponse: boolean;
  createdDate: string;
}

export interface Event {
  eventClassName: string;
  eventName: string;
}

export interface InvoiceNote {
  timestamp: string;
  text: string;
}

export interface InvoiceHistory {
  transmissionDate: string;
  invoiceNumber: string;
  invoiceNotes: InvoiceNote[];
  invoiceState: InvoiceStateCommon;
}
