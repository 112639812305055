import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BrowserCompatibilityService } from '../browser-compatibility.service';
import { BrowserWarningButtonService } from '../browser-warning-button';

@Component({
  selector: 'browser-warning',
  templateUrl: './browser-warning-dialog.component.html'
})
export class BrowserWarningDialogComponent {
  constructor(
    dialogRef: MatDialogRef<BrowserWarningDialogComponent>,
    private browserWarningButtonService: BrowserWarningButtonService,
    private browserService: BrowserCompatibilityService
  ) {
    dialogRef.disableClose = true;
    dialogRef.updateSize('550px');
  }

  onLinkClick() {
    this.browserService.hintConfirmed = true;
    this.browserWarningButtonService.hideButton();
  }
}
