<div *transloco="let t">
  <page-header [title]="t('PageTitle_InvoiceClarification')"></page-header>

  <mat-card>
    <validation-summary [errorList]="errorList"></validation-summary>

    <work-instructions step="InvoiceClarification"></work-instructions>

    <sending-restricted></sending-restricted>

    <form [formGroup]="form" class="flex-col">
      <div class="flex-col justify-around content-center align-center">
        <mat-form-field>
          <mat-label class="ui-test--ic-edit-mat-label-internal-invoice-number">
            {{ t('InvoiceClarificationViewModel_InternalInvoiceNumber') }}
          </mat-label>
          <input
            class="ui-test--ic-edit-input-internal-invoice-number"
            matInput
            formControlName="internalInvoiceNumber"
            id="internalInvoiceNumber" />
        </mat-form-field>
        <mat-form-field>
          <mat-label class="ui-test--ic-edit-mat-label-mileage">
            {{ t('InvoiceClarificationViewModel_Mileage') }}
          </mat-label>
          <input
            class="ui-test--ic-edit-input-mileage"
            matInput
            formControlName="mileage"
            autocomplete="off"
            type="number" />
        </mat-form-field>
        <mat-form-field>
          <mat-label class="ui-test--ic-edit-mat-label-license-plate-number">
            {{ t('InvoiceClarificationViewModel_LicensePlateNumber') }}
          </mat-label>
          <input
            class="ui-test--ic-edit-input-license-plate-number"
            matInput
            trim="blur"
            formControlName="licensePlateNumber"
            id="licensePlateNumber" />
        </mat-form-field>
        <mat-form-field>
          <mat-label class="ui-test--ic-edit-mat-label-vehicle-identification-number">
            {{ t('InvoiceClarificationViewModel_VehicleIdentificationNumber') }}
          </mat-label>
          <input
            class="ui-test--ic-edit-input-vehicle-identification-number"
            matInput
            trim="blur"
            #vehicleIdentificationNumber
            formControlName="vehicleIdentificationNumber"
            id="vehicleIdentificationNumber" />
          <mat-hint class="ui-test--ic-edit-mat-hint-vehicle-identification-number" align="end">
            {{vehicleIdentificationNumber.value.length || 0}}/17
          </mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label class="ui-test--ic-edit-mat-label-contract-number">
            {{ t('InvoiceClarificationViewModel_ContractNumber') }}
          </mat-label>
          <input
            class="ui-test--ic-edit-input-contract-number"
            matInput
            trim="blur"
            formControlName="contractNumber"
            id="contractNumber" />
        </mat-form-field>
      </div>

      <mat-divider class="my-10"></mat-divider>
      <h2 class="subtitle ui-test--ic-edit-header-credit-note">{{ t('PageTitle_CreditNote') }}</h2>

      <div class="flex-col justify-around content-center align-center">
        <div class="flex-col align-end">
          <mat-checkbox
            class="ui-test--ic-edit-mat-check-box-is-partial-credit-note-checked"
            *ngIf="invoiceClarification.isPartialCreditNoteCheckBoxActive"
            formControlName="isPartialCreditNoteChecked"
            (change)="checkboxChange($event, 'isPartialCreditNoteChecked')"
            labelPosition="before">
            {{ t('InvoiceClarificationViewModel_IsPartialCreditNoteChecked') }}
          </mat-checkbox>
          <mat-checkbox
            class="ui-test--ic-edit-mat-check-box-is-new-invoice-checked"
            formControlName="isNewInvoiceChecked"
            (change)="checkboxChange($event, 'isNewInvoiceChecked')"
            labelPosition="before">
            {{ t('InvoiceClarificationViewModel_IsNewInvoiceChecked') }}
          </mat-checkbox>
        </div>
      </div>
    </form>

    <ng-container *ngIf="!controlsHidden">
      <mat-divider class="my-10"></mat-divider>
      <file-upload
        [uploadUrl]="'api/invoiceclarification/attachments/' + invoiceClarification.invoiceClarificationId"
        [fileCategories]="invoiceClarification.fileUploadCategories"
        [acceptedFileTypes]="invoiceClarification.acceptedFiles"
        [existingFiles]="invoiceClarification.attachments"
        uploadInstructionInformationText1Key="Invoice_Clarification_UploadInstructionInformation_Text1"
        uploadInstructionInformationText2Key="Invoice_Clarification_UploadInstructionInformation_Text2"
        uploadInstructionInformationText3Key=""></file-upload>

      <mat-divider class="my-10"></mat-divider>
      <important-information
        userNoteInfoTextKey="Invoice_Clarification_ImportantInformation_Text"
        [(userNote)]="invoiceClarification.userNote"
        (closeDialogDecision)="handleImportantInformationCloseDialogDecision($event)"
        [initiallyOpen]="true"></important-information>
    </ng-container>

    <mat-divider class="my-10"></mat-divider>
    <contact-data
      [contactData]="invoiceClarification.contactData"
      [showSecondaryEmail]="showSecondaryEmail"></contact-data>

    <mat-divider class="my-10"></mat-divider>

    <div class="flex justify-end">
      <button
        class="mr-5 ui-test--ic-edit-button-save"
        (click)="save()"
        mat-stroked-button
        [class.button-spinner]="saving"
        [disabled]="saving || form.invalid">
        <mat-icon class="ui-test--ic-edit-mat-icon-save">save</mat-icon>
        {{ t('Common_Button_SaveChanges') }}
      </button>
      <button
        (click)="confirm()"
        class="continue-button ui-test--ic-edit-button-confirm"
        mat-raised-button
        color="primary"
        [class.button-spinner]="saving"
        [disabled]="saving || form.invalid || (userService.isSendingRestricted | async)">
        <mat-icon class="ui-test--ic-edit-mat-icon-confirm">skip_next</mat-icon>
        {{ t('Invoice_Clarification_Confirm') }}
      </button>
    </div>
  </mat-card>
</div>
