import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { OfferPanelModule } from 'app/components/offer-panel/offer-panel.module';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { SendingRestrictedModule } from 'app/components/sending-restricted/sending-restricted.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { DashboardContractDetailsDialogComponent } from './contract-details-dialog/contract-details-dialog.component';
import { DashboardContractDetailsComponent } from './contract-details/contract-details.component';
import { ContractInformationComponent } from './contract-details/contract-information/contract-information.component';
import { CustomerInformationComponent } from './contract-details/customer-information/customer-information.component';
import { PrintComponent } from './contract-details/print/print.component';
import { VehicleInformationComponent } from './contract-details/vehicle-information/vehicle-information.component';
import { WarrantorDealerComponent } from './contract-details/warrantor-dealer/warrantor-dealer.component';
import { ContractPanelComponent } from './contract-panel/contract-panel.component';
import { DashboardComponent } from './dashboard.component';
import { PaymentChooseDialogComponent } from './payment-choose-dialog/payment-choose-dialog.component';
import { SpecialAgreementDialogComponent } from './special-agreement-dialog/special-agreement-dialog.component';

@NgModule({
  imports: [
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule,
    MatRadioModule,
    MatTooltipModule,
    MatTabsModule,
    MatButtonModule,
    MatMenuModule,
    OfferPanelModule,
    PageHeaderModule,
    ValidationSummaryModule,
    SendingRestrictedModule,
    PipesModule,
    RouterModule,
    SharedModule
  ],
  exports: [],
  declarations: [
    DashboardComponent,
    DashboardContractDetailsComponent,
    ContractPanelComponent,
    PaymentChooseDialogComponent,
    SpecialAgreementDialogComponent,
    DashboardContractDetailsDialogComponent,
    ContractInformationComponent,
    WarrantorDealerComponent,
    CustomerInformationComponent,
    VehicleInformationComponent,
    PrintComponent
  ],
  providers: []
})
export class DashboardModule {}
