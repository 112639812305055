import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '@core/directives';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { MatDatepickerModule } from '@matheo/datepicker';
import { DatePickerModule } from '../datepicker';
import { DropdownWithSearchModule } from '../dropdown-with-search';
import { FilterComponent } from './filter.component';

@NgModule({
  imports: [
    DirectivesModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    DropdownWithSearchModule,
    DatePickerModule,
    PipesModule,
    SharedModule
  ],
  exports: [FilterComponent],
  declarations: [FilterComponent],
  providers: []
})
export class FilterModule {}
