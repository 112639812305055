import { Component, Input, OnInit } from '@angular/core';
import { FluentValidationResult } from '@core/validation-reader';
import { ActivityTypeIdentifierCommon, Position } from 'app/components/positions';
import { RequestContent } from '../../request.types';

@Component({
  selector: 'request-positions-preview',
  templateUrl: './positions-preview.component.html'
})
export class PositionsPreviewComponent implements OnInit {
  @Input() requestContent!: RequestContent;
  @Input() plausibilitiesResult!: FluentValidationResult;

  private _positions: Position[] = [];
  private _positionTypeOrderedKeys: ActivityTypeIdentifierCommon[] = [
    'MaintenancePackagePosition',
    'WorkPosition',
    'ServicePosition',
    'SparePart',
    'TireServicePosition',
    'TireSparePart',
    'TirePosition',
    'MobilityPosition',
    'MobilitySparePart',
    'WarrantyPosition',
    'WarrantySparePart',
    'WarrantyDiagnosticPosition'
  ];
  positionsMap = new Map<string, Position[]>();
  errorList: string[] = [];

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.setupPositions();
    this.readValidationMessages();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private readValidationMessages() {
    if (this.plausibilitiesResult) {
      for (const error of this.plausibilitiesResult.errors) {
        // The error for positions is in format RequestContent.RequestPositions[index].PropertyName
        const propertyPath = error.propertyName.split('.');
        const requestPosition = Number(propertyPath[1].replace('RequestPositions[', '').replace(']', ''));
        const propertyName = propertyPath[2];
        this._positions[requestPosition].hasErrors = true;

        // TODO: Test case with multiple messages for one property
        this._positions[requestPosition].errorMap.set(propertyName, error.errorMessage);

        // Push errorMessage to the summary errorList
        this.errorList.push(error.errorMessage);
      }
    }
  }

  private setupPositions() {
    this._positions = this.requestContent.requestPositions.map(
      (position) =>
        <Position>{
          templateName: position.templateName,
          positionId: position.positionId,
          laborPositionIndex: position.laborPositionIndex,
          itemType: position.itemType,
          externalProductNumber: position.externalProductNumber,
          activityTypeDescription: position.activityTypeDescription,
          quantity: position.quantity,
          quantityUnitText: position.quantityUnitText,
          unitPrice: position.unitPrice,
          netFixedPrice: position.netFixedPrice,
          discount: position.discount,
          netAmount: position.netAmount,
          goodwill: position.goodwill,
          goodwillAmount: position.goodwillAmount,
          materialGoodwill: position.materialGoodwill,
          materialGoodwillAmount: position.materialGoodwillAmount,
          displayCurrencyUnit: position.displayCurrencyUnit,
          productType: position.productTypeForDamageType,
          sourceApplication: position.sourceApplication,
          vehicleType: position.vehicleType,
          damageTypeId: position.damageTypeId,
          damageType: position.damageType,
          tire: position.tire,
          tireReplacements: position.tireReplacements,
          hasErrors: false,
          errorMap: new Map<string, string>()
        }
    );

    // Go through position type order keys and prepare all positions into template array
    this._positionTypeOrderedKeys.forEach((key) => {
      const foundPositions = this._positions.filter((position) => position.itemType === key);

      if (foundPositions.length > 0) {
        // If there are found positions sort them based on laborPositionIndex
        foundPositions.sort((a: Position, b: Position) => a.laborPositionIndex - b.laborPositionIndex);

        // Add found positions into template array
        this.positionsMap.set(key, foundPositions);
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  asIsOrder() {
    return 0;
  }

  positionIdentifier(_index: number, item: Position) {
    return item.positionId;
  }
}
