import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertConfig } from '@core/services/alert/alert.types';
import { AlertDialogComponent } from '@core/services/alert/dialog/dialog.component';
import { TranslocoService } from '@ngneat/transloco';
import { merge } from 'lodash-es';
import { take } from 'rxjs';
import { ContextDataService } from '../context-data';

@Injectable()
export class AlertService {
  private defaultConfig: AlertConfig = {
    title: '',
    message: '',
    icon: {
      show: false
    },
    actions: {
      confirm: {
        show: true,
        color: 'primary'
      },
      additionalButton: {
        show: false
      },
      cancel: {
        show: false
      }
    },
    dismissible: false // dismissible = false is fine if the confirm or cancel button is shown
  };

  constructor(
    private matDialog: MatDialog,
    private translocoService: TranslocoService,
    private contextDataService: ContextDataService
  ) {
    this.subscribeIsAuthenticatedEvent();
  }

  open(config: AlertConfig = {}): MatDialogRef<AlertDialogComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this.defaultConfig, config);

    // Open the dialog
    return this.matDialog.open(AlertDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: 'alert-dialog-panel',
      width: '550px'
    });
  }

  /**
   * Default translations can be initialized only after successful login.
   * Otherwise forbidden page is displayed before authentication process is finished.
   */
  private subscribeIsAuthenticatedEvent() {
    this.contextDataService
      .isAuthenticated()
      .pipe(take(1))
      .subscribe((authenticated) => {
        if (authenticated) {
          this.initializeDefaultConfigTranslations();
        }
      });
  }

  /**
   * Load the default translations for the buttons.
   *
   * If the `translate` function of transloco is called too early during the page load, it can happen that the translations are missing.
   */
  private initializeDefaultConfigTranslations() {
    this.translocoService.selectTranslate('Common_OK').subscribe((value) => {
      if (this.defaultConfig.actions?.confirm) {
        this.defaultConfig.actions.confirm.label = value;
      }
    });

    this.translocoService.selectTranslate('Common_Cancel').subscribe((value) => {
      if (this.defaultConfig.actions?.cancel) {
        this.defaultConfig.actions.cancel.label = value;
      }
    });
  }
}
