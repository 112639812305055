import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfigurationService, ContextDataService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { BreadcrumbService } from 'app/components/layout/breadcrumb/breadcrumb.service';
import { Breadcrumb } from 'app/components/layout/breadcrumb/breadcrumb.types';

import { VehicleSelectionService } from '../vehicle-selection/vehicle-selection.service';
import { DashboardService } from './dashboard.service';
import { Contract, Dashboard } from './dashboard.types';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashboardData!: Dashboard;
  contract!: Contract;
  carportContracts!: any[];
  isWaitingForCarportContracts = false;
  displayBackToVehicleSelection = false;
  errorList!: string[];
  loading = true;

  constructor(
    private dashboardService: DashboardService,
    private vehicleSelectionService: VehicleSelectionService,
    private translocoService: TranslocoService,
    private contextDataService: ContextDataService,
    private router: Router,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    // Check is it dashboard data available
    if (!this.contextDataService.data.processId) {
      // If not, redirect back to contract search
      this.router.navigate(['/contract-search']);
      return;
    }

    this.loadDashboardData();
  }

  onContractSelection(contractIndex?: number) {
    if (typeof contractIndex === 'number' && contractIndex >= 0) {
      this.dashboardData.selectedContract = contractIndex;
    }
    this.contract = this.dashboardData?.contracts[this.dashboardData.selectedContract];

    this.breadcrumbService.breadcrumb = {
      vehicleIdentificationNumber: this.contract.vehicle.vehicleIdentificationNumber
    } as Breadcrumb;
  }

  private loadDashboardData() {
    this.dashboardService.load().subscribe({
      next: (result: Dashboard) => {
        this.displayBackToVehicleSelection = this.vehicleSelectionService.availableVehicles.length > 0;
        this.dashboardData = this.dashboardService.dashboardData = result;
        this.onContractSelection();

        if (this.dashboardData.missingPermissionError) {
          this.errorList.push(this.translocoService.translate('Dashboard_Index_MissingPermissionError'));
        }

        this.loading = false;
      },
      error: () => {
        this.router.navigate(['/contract-search']);
      }
    });
  }
}
