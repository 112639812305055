import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  AlertService,
  ContextDataService,
  LocaleFormatService,
  TenantSettingsService,
  UserService
} from '@core/services';
import { ExternalApplicationAssignResult } from '@core/types/application-assign.types';
import { stringFormat } from '@core/utils/helper-functions';
import { TranslocoService } from '@ngneat/transloco';
import { BreadcrumbService } from 'app/components/layout/breadcrumb/breadcrumb.service';
import { environment } from 'environments/environment';
import { tap } from 'rxjs';
import { OutstandingCustomerPayment, PaymentDecision } from '../request/edit/payment-choose-panel';
import { BaseProduct, Contract, Dashboard, ProductType } from './dashboard.types';
import { PaymentChooseDialogComponent } from './payment-choose-dialog/payment-choose-dialog.component';
import { PaymentChoose, PaymentOption, SubType } from './payment-choose-dialog/payment-choose-dialog.types';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(
    private translocoService: TranslocoService,
    private tenantSettingsService: TenantSettingsService,
    private httpClient: HttpClient,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private contextDataService: ContextDataService,
    private breadcrumbService: BreadcrumbService,
    private alertService: AlertService,
    private userService: UserService,
    private localeFormatService: LocaleFormatService
  ) {}

  dashboardData: Dashboard | null = null;

  resetDashboard() {
    this.dashboardData = null;
    this.contextDataService.contextId = undefined;
    this.breadcrumbService.clearBreadcrumb();
  }

  create(vin: string = '') {
    let url = `${environment.isportCoreApiUrl}/api/dashboard`;
    if (vin) {
      url = `${url}?selectedVehicleIdentificationNumber=${vin}`;
    }
    return this.httpClient.post(url, {});
  }

  load() {
    return this.httpClient.get<Dashboard>(`${environment.isportCoreApiUrl}/api/dashboard`).pipe(
      tap((data: Dashboard) => {
        this.userService.isReadOnly.subscribe((isReadOnly: boolean) => {
          data.isReadOnly = isReadOnly;
        });
      })
    );
  }

  getActionForProductType(productType: ProductType): void {
    if (!this.dashboardData) {
      return;
    }

    const selectedContractData = this.dashboardData.contracts[this.dashboardData.selectedContract];

    if (!this.isProductTypeSupportedForContract(selectedContractData.id, productType)) {
      return;
    }

    if (selectedContractData.hasInvalidContractInformation) {
      this.snackbar.open(this.translocoService.translate('Common_InvalidContractInformation_Error'));
      return;
    }

    if (productType.isExternalRequest) {
      this.triggerExternalRequest(selectedContractData, productType);
      return;
    }

    if (selectedContractData.showOutstandingCustomerPayment) {
      let productTypeHasOutstandingPayment = false;

      const paymentChoose: PaymentChoose = {} as PaymentChoose;
      selectedContractData.outstandingCustomerPayment.forEach((o) => {
        if (o.productType !== productType.displayText) {
          return;
        }
        productTypeHasOutstandingPayment = true;
        this.parseOutstandingPayment(o, paymentChoose);
      });

      if (productTypeHasOutstandingPayment && this.tenantSettingsService.requestPaymentOptionSelectionInDashboard) {
        this.handlePaymentSelectionInDashboard(paymentChoose, selectedContractData, productType);
        return;
      }
    }

    this.triggerRequest(selectedContractData.id, selectedContractData.vehicle.vehicleIdentificationNumber, productType);
  }

  getProductTypeText(productTypeDisplayText: string): string {
    return this.translocoService.translate(`Common_${productTypeDisplayText}`);
  }

  isProductTypeSupportedForContract(contractDatabaseId: number, productType: ProductType): boolean {
    if (!this.dashboardData) {
      return false;
    }

    const contract = this.dashboardData.contracts.find((c) => c.id === contractDatabaseId);

    if (!contract) {
      return false;
    }

    return contract.productTypes.some((item) => item.displayText === productType.displayText);
  }

  triggerRequest(
    contractDatabaseId: number,
    vin: string,
    productType: ProductType,
    paymentOption?: string | null,
    outstandingPayment?: number | null,
    subtype?: string | null
  ) {
    if (!this.isProductTypeSupportedForContract(contractDatabaseId, productType)) {
      return;
    }

    this.router.navigate(['/request-duplicates'], {
      queryParams: {
        productType: productType.displayText,
        vin: vin,
        contractDatabaseId: contractDatabaseId.toString(),
        paymentOption: paymentOption ?? '',
        outstandingPayment: outstandingPayment?.toString() ?? '',
        subtype: subtype ?? ''
      }
    });
  }

  static productTypesFromProduct(baseProduct: BaseProduct): ProductType[] {
    const productTypes: ProductType[] = [];

    baseProduct.productTypes.forEach(function (productType) {
      if (
        !productTypes.some(function (pt) {
          return pt.displayText == productType.displayText;
        })
      ) {
        productTypes.push(productType);
      }
    });

    return productTypes;
  }

  private triggerExternalRequest(selectedContractData: Contract, productType: ProductType): void {
    this.httpClient
      .post<ExternalApplicationAssignResult>(`${environment.isportCoreApiUrl}/api/externalrequest`, {
        chassisNumber: selectedContractData.vehicle.vehicleIdentificationNumber,
        contractId: selectedContractData.contractNumber,
        licensePlateNumber: selectedContractData.vehicle.licensePlateNumber,
        productType: productType.displayText,
        companyCode: selectedContractData.companyCode,
        sourceApplication: selectedContractData.contractProvider,
        id: selectedContractData.id
      })
      .subscribe({
        next: (result: ExternalApplicationAssignResult) => {
          if (result.isValid) {
            window.open(result.targetUri, result.targetWindow);
          } else {
            this.alertService.open({ message: result.errorMessage });
          }
        }
      });
  }

  /**
   * If the product type of an outstanding customer payment matches the selected type, this method will be executed to fill the payment choose object.
   * @param o the outstanding customer payment object to check
   * @param paymentChoose the target data to be filled
   */
  private parseOutstandingPayment(o: OutstandingCustomerPayment, paymentChoose: PaymentChoose) {
    {
      const availableSubtypes: SubType[] = o.items.map(
        (item) =>
          <SubType>{
            subtype: item.subtype,
            text:
              item.subtype +
              ' ' +
              this.localeFormatService.formatNumericValueFromNumber(item.outstandingAmount) +
              ' ' +
              this.translocoService.translate('Common_CurrencySymbol_' + item.currency),
            outstandingAmount: item.outstandingAmount
          }
      );
      paymentChoose.availableSubtypes = availableSubtypes;

      if (availableSubtypes.length > 1) {
        paymentChoose.chosenSubtypeDisplayText = this.translocoService.translate('Common_PaymentChoose_Subtype');
        paymentChoose.chosenPaymentOptionDisplayText = this.translocoService.translate(
          'Common_PaymentChoose_PaymentOption'
        );
        paymentChoose.mustSubtypeChoose = true;
      } else {
        const outstandingPayment = o.items[0].outstandingAmount;
        const paymentOptionDisplayText = stringFormat(
          this.translocoService.translate('Common_PaymentChoose_Message'),
          this.localeFormatService.formatNumericString(outstandingPayment.toString()),
          this.translocoService.translate('Common_CurrencySymbol_' + o.items[0].currency)
        );

        paymentChoose.chosenPaymentOptionDisplayText = paymentOptionDisplayText;
        paymentChoose.chosenAmount = outstandingPayment;
        paymentChoose.chosenSubtype = paymentChoose.availableSubtypes[0].subtype;
        paymentChoose.mustSubtypeChoose = false;
      }
    }
  }

  /**
   * If handling payments in the dashboard is active, this handles preparing and showing the dialog.
   */
  private handlePaymentSelectionInDashboard(
    paymentChoose: PaymentChoose,
    selectedContractData: Contract,
    productType: ProductType
  ) {
    const availablePaymentOptions = selectedContractData.paymentOptions.map(
      (paymentOption) =>
        <PaymentOption>{
          key: paymentOption,
          text: this.translocoService.translate(`Common_PaymentOptions_${paymentOption}`)
        }
    );

    paymentChoose.availablePaymentOptions = availablePaymentOptions;
    paymentChoose.action = () => {
      if (!paymentChoose.chosenPaymentOption) {
        this.snackbar.open(this.translocoService.translate('Common_PaymentChoose_selectAnPaymentOption'));
        return;
      }

      if (paymentChoose.mustSubtypeChoose && (!paymentChoose.chosenAmount || !paymentChoose.chosenSubtype)) {
        this.snackbar.open(this.translocoService.translate('Common_PaymentChoose_SelectSubtype'));
        return;
      }

      this.httpClient
        .post<void>(`${environment.isportCoreApiUrl}/api/payments`, {
          contractId: selectedContractData.contractNumber,
          selectedPaymentOption: paymentChoose.chosenPaymentOption,
          requestType: productType.displayText,
          companyCode: selectedContractData.companyCode,
          contractProvider: selectedContractData.contractProvider,
          subtype: paymentChoose.chosenSubtype
        } as PaymentDecision)
        .subscribe();

      if (
        this.dashboardData &&
        this.dashboardData.blockingPaymentOptions
          .toLowerCase()
          .indexOf(paymentChoose.chosenPaymentOption.toLowerCase()) !== -1 &&
        this.tenantSettingsService.blockingPaymentOptionsDisableRequestCreation === true
      ) {
        const paymentOptionTranslationKey = this.translocoService.translate(
          `Common_PaymentOptions_${paymentChoose.chosenPaymentOption}`
        );
        const stopPaymentOption = stringFormat(
          this.translocoService.translate('Common_PaymentChoose_stopPaymentOption'),
          paymentOptionTranslationKey
        );
        this.snackbar.open(`${stopPaymentOption}`);
      } else {
        this.triggerRequest(
          selectedContractData.id,
          selectedContractData.vehicle.vehicleIdentificationNumber,
          productType,
          paymentChoose.chosenPaymentOption,
          paymentChoose.chosenAmount,
          paymentChoose.chosenSubtype
        );
      }
    };

    const paymentChooseDialogRef = this.dialog.open(PaymentChooseDialogComponent);
    paymentChooseDialogRef.componentInstance.paymentChoose = paymentChoose;
  }
}
