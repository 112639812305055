import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { PositionsModule } from 'app/components/positions/positions.module';
import { PositionsPreviewComponent } from './positions-preview.component';

@NgModule({
  imports: [MatCardModule, PositionsModule, SharedModule, PipesModule],
  exports: [PositionsPreviewComponent],
  declarations: [PositionsPreviewComponent],
  providers: []
})
export class PositionsPreviewModule {}
