<div class="questionnaire-information" *transloco="let t">
  <mat-card>
    <mat-card-title class="ui-test--request-information-header-title">{{ information.headline }}</mat-card-title>
    <mat-card-content>
      <p
        class="ui-test--request-information-paragraph-information"
        [innerHtml]="information.informationText | nl2br"></p>
    </mat-card-content>
  </mat-card>

  <div class="flex-row justify-end content-center">
    <button
      mat-raised-button
      class="ui-test--request-information-button-abort"
      color="primary"
      (click)="chooseAbort()"
      [disabled]="abortLoading"
      [class.button-spinner]="abortLoading"
      [class.mr-10]="information.canContinue">
      {{ t('Information_AbortButton') }}
    </button>

    <button
      *ngIf="information.canContinue"
      class="ui-test--request-information-button-continue"
      mat-raised-button
      (click)="chooseContinue()">
      {{ t('Information_ContinueButton') }}
    </button>
  </div>
</div>
