<div class="spinner" *ngIf="loading"></div>
<ng-container *ngIf="!loading">
  <div *transloco="let t">
    <h2 class="ui-test--contract-details-dialog-header-title" mat-dialog-title>{{ t("PageTitle_Dashboard") }}</h2>

    <mat-dialog-content class="flex-col">
      <contract-details [dashboardData]="dashboardData" [contract]="contract"></contract-details>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button class="ui-test--contract-details-dialog-header-close" mat-raised-button mat-dialog-close>
        {{ t('Common_Close') }}
      </button>
    </mat-dialog-actions>
  </div>
</ng-container>
