import { NgModule } from '@angular/core';
import { IdentityProviders } from '@core/services';
import {
  AuthModule,
  LogLevel,
  OpenIdConfiguration,
  StsConfigLoader,
  StsConfigStaticLoader
} from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';

/**
 * This config template is used to generate the different configurations for the identity providers.
 */
const configTemplate: OpenIdConfiguration = {
  configId: '', // dynamically fill this
  authority: environment.auth.serviceUrl,
  redirectUrl: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  clientId: environment.auth.serviceIdentityClientId,
  scope: 'openid', // 'openid profile ' + your scopes. Available scopes at: https://signin-t.fs01.vwf.vwfs-ad/adfs/.well-known/openid-configuration
  responseType: 'code',
  silentRenew: true,
  silentRenewUrl: `${window.location.origin}/silent-renew.html`,
  renewTimeBeforeTokenExpiresInSeconds: 20,
  maxIdTokenIatOffsetAllowedInSeconds: 500, // https://nice-hill-002425310.azurestaticapps.net/docs/documentation/configuration#maxidtokeniatoffsetallowedinseconds
  useRefreshToken: false,
  autoUserInfo: false, // prevent calling userinfo API after authorization
  historyCleanupOff: false,
  allowUnsafeReuseRefreshToken: false, // this is required if the refresh token is not rotated
  logLevel: environment.production ? LogLevel.None : LogLevel.Debug,
  customParamsAuthRequest: {
    resource: 'urn://vwfsag/apis/sport/1/',
    domain_hint: '' // dynamically fill this
  }
};

/**
 * Create the configurations for the oidc client.
 * The `configId` and the `domain_hint` are set to the respective identity provider domain hints.
 */
const configFactory = () => {
  const configurations: OpenIdConfiguration[] = [];

  for (const identityProvider of IdentityProviders) {
    // deep copy the template
    const config = JSON.parse(JSON.stringify(configTemplate));

    config.configId = identityProvider;
    if (config.customParamsAuthRequest) {
      config.customParamsAuthRequest.domain_hint = identityProvider;
    }

    configurations.push(config);
  }

  return new StsConfigStaticLoader(configurations);
};

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: configFactory
      }
    })
  ],
  exports: [AuthModule]
})
export class AuthConfigModule {}
