import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ParseDatePipe } from '@core/pipes';
import {
  ConfigurationService,
  ContextDataService,
  RoleCommon,
  TenantSettingsService,
  UserService
} from '@core/services';
import {
  deCapitalizeFirstLetter,
  getUrlParamsFromObject,
  hintReplace,
  stringFormat
} from '@core/utils/helper-functions';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { TranslocoService } from '@ngneat/transloco';
import { BreadcrumbService } from 'app/components/layout';
import { VehicleSelectionLoadResult } from 'app/pages/vehicle-selection/vehicle-selection.types';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { DashboardService } from '../dashboard/dashboard.service';
import { Contract } from '../dashboard/dashboard.types';
import { VehicleSelectionService } from '../vehicle-selection/vehicle-selection.service';
import { ContractSearchConfigurator } from './contract-search.configurator';
import { SearchIndexFields } from './contract-search.enums';
import { ContractSearchService } from './contract-search.service';
import { ContractRequest, GetVehicleInformationResponse, SearchIndexViewModel } from './contract-search.types';

@Component({
  selector: 'contract-search',
  templateUrl: './contract-search.component.html',
  styleUrls: ['./contract-search.component.scss']
})
export class ContractSearchComponent implements OnInit, OnDestroy {
  showOfferPanel: boolean = false;
  vin: string = '';
  contract!: Contract;
  maxDate: DateTime = DateTime.now();
  errorList: string[] = [];
  formConfigurator!: ContractSearchConfigurator;
  submitLoading: boolean = false;
  failedSearchCount: number = 0;
  hasNoContractsFound: boolean = false;
  isInvalidSearchRequest: boolean = false;
  isVehicleIdentificationNumberUsedInPreviousSearch: boolean = false;
  businessError: string = '';
  contractSearchForm = this.formBuilder.group({
    vehicleIdentificationNumber: new FormControl<string | null>(null, Validators.maxLength(17)),
    licensePlateNumber: new FormControl<string | null>(null),
    contractNumber: new FormControl<string | null>(null, Validators.maxLength(100)),
    dateOfFirstRegistration: new FormControl<DateTime | null>(null),
    damageDate: new FormControl<DateTime | null>(null),
    mileage: new FormControl<string | null>(null, [Validators.min(1), Validators.max(9999999)])
  });

  private inputFieldSubscriptions$: Subscription = new Subscription();
  private isMissingSearchParameterPageSubscription$: Subscription = new Subscription();

  constructor(
    private contractSearch: ContractSearchService,
    private vehicleSelectionService: VehicleSelectionService,
    private dashboardService: DashboardService,
    public configurationService: ConfigurationService,
    private tenantSettingsService: TenantSettingsService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private contextDataService: ContextDataService,
    private translocoService: TranslocoService,
    private parseDatePipe: ParseDatePipe,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.loadFormConfigurator();
    this.setupConfiguratorWatchers();
    this.watchInputChanges();
    this.breadcrumbService.clearBreadcrumb();

    this.checkExternalSearch();
  }

  private checkExternalSearch() {
    // check if url query params contain references
    const queryParamKeys = this.route.snapshot.queryParamMap.keys;
    const referenceIdKey = queryParamKeys.find((key) => key.toLocaleLowerCase() === 'reference_id');
    const sourceAppKey = queryParamKeys.find((key) => key.toLocaleLowerCase() === 'sourceproc');
    let referenceId = null;
    let sourceApplication = null;

    if (referenceIdKey) {
      referenceId = this.route.snapshot.queryParamMap.get(referenceIdKey);
      sourceApplication = this.route.snapshot.queryParamMap.get(sourceAppKey ?? '');
      if (referenceId) {
        this.contextDataService.routingParameters = null;
        this.loadExternalSearch(referenceId, sourceApplication);
        return;
      }
    }

    const urlParams = new URLSearchParams(this.contextDataService.routingParameters ?? '');
    for (const [key, value] of urlParams) {
      if (key.toLocaleLowerCase() == 'reference_id') {
        referenceId = value;
      }

      if (key.toLocaleLowerCase() == 'sourceproc') {
        sourceApplication = value;
      }
    }
    if (referenceId) {
      this.contextDataService.routingParameters = null;
      this.loadExternalSearch(referenceId, sourceApplication);
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe vehicleIdentificationNumber, licensePlate, contractNumber value changes
    this.inputFieldSubscriptions$.unsubscribe();

    this.isMissingSearchParameterPageSubscription$.unsubscribe();
  }

  onSubmit(): void {
    if (!this.contractSearchForm.valid) {
      this.handleInvalidRequest();
      return;
    }
    this.isInvalidSearchRequest = false;
    this.showOfferPanel = false;

    this.errorList = [];
    this.submitLoading = true;
    this.businessError = '';
    this.isVehicleIdentificationNumberUsedInPreviousSearch =
      !!this.contractSearchForm.value.vehicleIdentificationNumber;

    // Prepare form parameters before calling search API
    const urlParams = getUrlParamsFromObject(this.contractSearchForm.getRawValue());
    urlParams.append('enabledIndexFields', this.formConfigurator.enabledIndexFields.toString());
    urlParams.append('ignoreDataProtection', this.formConfigurator.ignoreDataProtection.toString());
    urlParams.append('isExternalSearch', this.formConfigurator.isExternalSearch.toString());
    urlParams.append('failedSearchCount', this.failedSearchCount.toString());

    this.contractSearch.search(urlParams).subscribe({
      next: (contracts: Contract[]) => {
        this.handleContractsResponse(contracts);
      },
      error: (res: HttpErrorResponse) => {
        if (
          [HttpStatusCode.UnprocessableEntity, HttpStatusCode.NotFound, HttpStatusCode.BadRequest].includes(res.status)
        ) {
          this.handleExpectedErrorCodes(res);
        }
        this.submitLoading = false;
        if (res.status === HttpStatusCode.BadRequest) {
          // on a validation error we didn't even perform a search, so we don't need to do vehicle search / offer panel.
          return;
        }
        this.caseNoContract();
      }
    });
  }

  get isContractNumberMissing(): boolean {
    return !this.formConfigurator.displayContractNumber || !this.contractSearchForm.value.contractNumber;
  }

  get isDamageDateMissing(): boolean {
    return !this.formConfigurator.displayDamageDate || !this.contractSearchForm.value.damageDate;
  }

  get isMileageMissing(): boolean {
    return !this.formConfigurator.displayMileage || !this.contractSearchForm.value.mileage;
  }

  get isLicensePlateNumberMissing(): boolean {
    return !this.formConfigurator.displayLicensePlateNumber || !this.contractSearchForm.value.licensePlateNumber;
  }

  get isVehicleIdentificationNumberMissing(): boolean {
    return (
      !this.formConfigurator.displayVehicleIdentificationNumber ||
      !this.contractSearchForm.value.vehicleIdentificationNumber
    );
  }

  get showBusinessError() {
    return (
      this.businessError &&
      (this.contextDataService.data.tenant == 'VolkswagenFinanceSpainSA' || this.failedSearchCount > 0)
    );
  }

  showMissingRequiredSearchParameterMessage(): boolean {
    return (
      this.isInvalidSearchRequest &&
      this.isVehicleIdentificationNumberMissing &&
      this.isLicensePlateNumberMissing &&
      this.isContractNumberMissing
    );
  }

  setupMissingSearchParameters(contractRequest?: ContractRequest): void {
    let enabledIndexFields = this.tenantSettingsService.searchIndexFirstTimeRegistrationDisplaySettings;
    let enabledFieldsForMissingParameter = this.tenantSettingsService.searchIndexEnabledFieldsForMissingParameter;

    if (this.formConfigurator.isExternalSearch) {
      if ((enabledIndexFields & SearchIndexFields.Mileage) === SearchIndexFields.Mileage) {
        // add mileage to enabled fields for missing parameters
        enabledFieldsForMissingParameter |= SearchIndexFields.Mileage;
      }

      if (this.formConfigurator.ignoreDataProtection) {
        // remove first registration and contract number from missing parameter fields
        enabledIndexFields &= ~SearchIndexFields.DateOfFirstRegistration;
        enabledIndexFields &= ~SearchIndexFields.ContractNumber;
        enabledFieldsForMissingParameter &= ~SearchIndexFields.DateOfFirstRegistration;
        enabledFieldsForMissingParameter &= ~SearchIndexFields.ContractNumber;
      }
    }

    // Reset failed search count for missing search parameters and the indicator that no contracts were found
    this.failedSearchCount = 0;
    this.hasNoContractsFound = false;

    // Keep current form configurator, but recalculate computed properties based of new values
    // of enabledIndexFields and enabledFieldsForMissingParameter properties
    this.formConfigurator.consolidate(enabledIndexFields, enabledFieldsForMissingParameter);

    if (contractRequest) {
      // Pre-populate data if it is external search with redirect data
      this.contractSearchForm.patchValue({
        vehicleIdentificationNumber: contractRequest.vehicleIdentificationNumber,
        licensePlateNumber: contractRequest.licensePlateNumber,
        contractNumber: contractRequest.contractNumber,
        mileage: contractRequest.mileage,
        dateOfFirstRegistration: DateTime.fromISO(contractRequest.dateOfFirstRegistration?.toString()),
        damageDate: DateTime.fromISO(contractRequest.damageDate?.toString())
      });

      this.formConfigurator.isExternalSearch = contractRequest.isExternalSearch;
      this.formConfigurator.ignoreDataProtection = contractRequest.ignoreDataProtection;
    }
  }

  private setupConfiguratorWatchers(): void {
    // When missing search parameter is configured make sure to disable proper fields based on new configuration
    this.isMissingSearchParameterPageSubscription$ = this.formConfigurator.isMissingSearchParameterPageChange.subscribe(
      (value) => {
        if (value) {
          this.contractSearchForm.enable({ emitEvent: false });

          // Disable controls per missing search parameter logic
          if (!this.formConfigurator.isVehicleIdentificationNumberEnabled) {
            this.contractSearchForm.controls.vehicleIdentificationNumber.disable({ emitEvent: false });
          }

          if (!this.formConfigurator.isLicensePlateNumberEnabled) {
            this.contractSearchForm.controls.licensePlateNumber.disable({ emitEvent: false });
          }

          if (!this.formConfigurator.isContractNumberEnabled) {
            this.contractSearchForm.controls.contractNumber.disable({ emitEvent: false });
          }
        }
      }
    );
  }

  private handleInvalidRequest(): void {
    this.isInvalidSearchRequest = true;
    this.errorList = [];
    if (this.isMileageMissing) {
      this.errorList.push(
        hintReplace(
          this.translocoService.translate('Common_ErrorMessageFor_NotEmpty'),
          this.translocoService.translate('Common_Mileage')
        )
      );
    }
    if (this.isDamageDateMissing) {
      this.errorList.push(
        hintReplace(
          this.translocoService.translate('Common_ErrorMessageFor_NotEmpty'),
          this.translocoService.translate('Common_DamageDate')
        )
      );
    }
    if (this.showMissingRequiredSearchParameterMessage()) {
      this.errorList.push(this.getMissingRequiredSearchParameterMessage());
    }
    if (
      this.contractSearchForm.value.damageDate &&
      this.contractSearchForm.value.damageDate.toISODate() > this.maxDate.toISODate()
    ) {
      this.errorList.push(this.translocoService.translate('ErrorMessage_DamageDateNotInFuture'));
    }
  }

  private getMissingRequiredSearchParameterMessage(): string {
    if (this.formConfigurator.displayContractNumber) {
      return stringFormat(
        this.translocoService.translate('Common_ErrorMessageForMissingMultipleOptionalSearchParameter'),
        this.translocoService.translate('Common_DamageDate'),
        this.translocoService
          .translate('Common_ContractNumber')
          .concat(', ', this.translocoService.translate('Common_LicensePlateNumber')),
        this.translocoService.translate('Common_VehicleIdentificationNumber')
      );
    } else {
      return stringFormat(
        this.translocoService.translate('Common_ErrorMessageForMissingMultipleOptionalSearchParameter'),
        this.translocoService.translate('Common_DamageDate'),
        this.translocoService.translate('Common_LicensePlateNumber'),
        this.translocoService.translate('Common_VehicleIdentificationNumber')
      );
    }
  }

  private handleContractsResponse(contracts: Contract[]): void {
    const result: VehicleSelectionLoadResult = this.vehicleSelectionService.load(contracts);
    switch (result) {
      case VehicleSelectionLoadResult.Single:
        this.dashboardService.resetDashboard();
        this.dashboardService.create().subscribe({
          next: () => {
            this.router.navigate(['/dashboard']);
          }
        });
        break;
      case VehicleSelectionLoadResult.Multiple:
        this.router.navigate(['/vehicle-selection']);
        break;
    }
  }

  private loadExternalSearch(referenceId: string, sourceApplication: string | null): void {
    this.contractSearch.externalSearch(referenceId, sourceApplication).subscribe({
      next: (response: Contract[] | SearchIndexViewModel) => {
        if (response instanceof Array) {
          this.handleContractsResponse(response);
          return;
        }

        this.contractSearchForm.patchValue({
          vehicleIdentificationNumber: response.vehicleIdentificationNumber,
          licensePlateNumber: response.licensePlateNumber,
          mileage: response.mileage,
          dateOfFirstRegistration: DateTime.fromISO(response.dateOfFirstRegistration),
          damageDate: DateTime.fromISO(response.damageDate)
        });
        // Since we found the search-attempt, but it has no results or missing parameters check, there was no contract available.
        // This means that we should start the caseNoContract hook to also start the aftersales process.
        this.caseNoContract();
      },
      error: (res: HttpErrorResponse) => {
        // https://www.rfc-editor.org/rfc/rfc4918#page-78
        if (res.status === HttpStatusCode.UnprocessableEntity) {
          const redirectResponse = res.error as ContractRequest;
          this.setupMissingSearchParameters(redirectResponse);
        }
      }
    });
  }

  private loadFormConfigurator(isReset = false): void {
    const enabledIndexFields = this.tenantSettingsService.searchIndexDisplaySettings;
    const isAftersalesUser = this.userService.hasRole(RoleCommon.Aftersales);

    if (isReset) {
      this.formConfigurator.reset(enabledIndexFields);
    } else {
      this.formConfigurator = new ContractSearchConfigurator(enabledIndexFields, isAftersalesUser);
    }

    if (this.configurationService.searchParameterEventDateIsPrefilled) {
      this.contractSearchForm.patchValue({ damageDate: DateTime.utc() });
    }
  }

  private watchInputChanges(): void {
    const vehicleIdentificationNumberFormControl = this.contractSearchForm.controls.vehicleIdentificationNumber;
    const licensePlateNumberFormControl = this.contractSearchForm.controls.licensePlateNumber;
    const contractNumberFormControl = this.contractSearchForm.controls.contractNumber;
    const dateOfFirstRegistrationFormControl = this.contractSearchForm.controls.dateOfFirstRegistration;

    // Attach event only if element exists and if it is enabled per missing parameter logic
    // If it is undefined that there is no missing parameters, if it is true then it is enabled, otherwise it is disabled

    // When vehicle number is changed, disable other inputs
    this.handleControlDependencies(
      vehicleIdentificationNumberFormControl,
      [licensePlateNumberFormControl, contractNumberFormControl, dateOfFirstRegistrationFormControl],
      false
    );

    // When license plate is changed, disable other inputs
    this.handleControlDependencies(
      licensePlateNumberFormControl,
      [vehicleIdentificationNumberFormControl, contractNumberFormControl, dateOfFirstRegistrationFormControl],
      false
    );

    // When contract number is changed, disable other inputs
    this.handleControlDependencies(
      contractNumberFormControl,
      [licensePlateNumberFormControl, vehicleIdentificationNumberFormControl, dateOfFirstRegistrationFormControl],
      false
    );

    this.handleControlDependencies(contractNumberFormControl, [dateOfFirstRegistrationFormControl], true);

    // When dateOfFirstRegistration is changed, toggle the contractNumber
    this.handleControlDependencies(dateOfFirstRegistrationFormControl, [contractNumberFormControl], true);
  }

  private handleControlDependencies(
    control: FormControl<string | DateTime | null>,
    controls: (AbstractControl | null)[],
    isControlOnMissingSearchParameterPage: boolean
  ): void {
    if (!control) {
      return;
    }

    this.inputFieldSubscriptions$.add(
      control.valueChanges.subscribe((value) => {
        const isMissingSearchParameterPage = isControlOnMissingSearchParameterPage
          ? this.formConfigurator.isMissingSearchParameterPage
          : !this.formConfigurator.isMissingSearchParameterPage;
        if (isMissingSearchParameterPage && !control.disabled) {
          this.toggleInputAvailability(value?.toString() ?? null, controls);
        }
      })
    );
  }

  private toggleInputAvailability(watcherValue: string | null, formControls: (AbstractControl | null)[]): void {
    for (const formControl of formControls) {
      // Skip if formControl is null
      if (!formControl) {
        continue;
      }

      // Enable or disable field based on value existence
      if (watcherValue) {
        formControl.setValue('', { emitEvent: false });
        formControl.disable({ emitEvent: false });
      } else {
        formControl.enable({ emitEvent: false });
      }

      // reset the validators
      formControl.markAsUntouched();
    }
  }

  // this will handle the notFound and unprocessableEntity cases, which are expected errors (missing parameters, or no contract available), and usually no technical issue
  private handleExpectedErrorCodes(res: HttpErrorResponse): void {
    switch (res.status) {
      case HttpStatusCode.UnprocessableEntity:
        if (res.error.type === 'BusinessError') {
          this.businessError = res.error.errorMessage;
          if (this.contextDataService.data.tenant == 'VolkswagenFinanzdienstleistungen') {
            this.failedSearchCount++;
          }
          return;
        }
        if (res.error.type === 'MissingParametersError' && !this.formConfigurator.isMissingSearchParameterPage) {
          this.setupMissingSearchParameters();
          this.submitLoading = false;
          return;
        }
        // Increase failed search count on every unprocessable status response and reset too many failed attempts
        this.failedSearchCount++;
        break;
      case HttpStatusCode.NotFound:
        this.failedSearchCount = 0;
        this.hasNoContractsFound = true;
        break;
    }

    // Reset configurator for missing search parameter page after three failed attempts
    if (this.failedSearchCount >= 3 && this.formConfigurator.isMissingSearchParameterPage) {
      // Enable all fields and remove value from them
      this.contractSearchForm.enable({ emitEvent: false });
      this.contractSearchForm.patchValue({
        dateOfFirstRegistration: null,
        contractNumber: ''
      });

      this.loadFormConfigurator(true);
    }

    const validationReader = new ValidationReader(res.error);
    this.errorList = validationReader.values;

    validationReader.keys.forEach((key) => {
      this.contractSearchForm.get(deCapitalizeFirstLetter(key))?.setErrors({ incorrect: true });
    });
  }

  private caseNoContract(): void {
    const vin = this.contractSearchForm.controls.vehicleIdentificationNumber?.value;
    if (
      !vin ||
      typeof vin !== 'string' ||
      !this.contextDataService.data.processId ||
      (this.formConfigurator.isMissingSearchParameterPage && !this.hasNoContractsFound)
    ) {
      return;
    }
    this.contractSearch.getVehicleInformation(this.contextDataService.data.processId, vin).subscribe({
      next: (res: GetVehicleInformationResponse) => {
        this.vin = res.result.vehicle.vehicleIdentificationNumber;
        this.submitLoading = false;
        // !!-operator because we need explicit boolean values for the && operator, otherwise it'd return the mileage value if the first condition is true.
        this.showOfferPanel = !res.result.hasContracts && !!this.contractSearchForm.controls.mileage?.value;
      },
      error: (res: HttpErrorResponse) => {
        const validationReader = new ValidationReader(res.error);
        this.errorList = validationReader.values;
        this.submitLoading = false;
        this.showOfferPanel = false;
      }
    });
  }
}
