<div class="mt-20" *transloco="let t">
  <h3 class="section-title ui-test--sdd-attachments-header-title">{{ t('StatusDetails_Attachments_Title') }}</h3>

  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    class="mat-elevation-z8 attachments-table"
    [matSortActive]="defaultSort.active"
    [matSortDirection]="defaultSort.direction"
    (matSortChange)="sortFileAttachments($event)"
    aria-describedby="attachments-title">
    <ng-container matColumnDef="filename">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="ui-test--sdd-attachments-th-filename">
        {{ t('StatusDetails_Attachments_Filename') }}
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="flex-row justify-start content-center">
          <mat-icon [class]="'ui-test--sdd-attachments-mat-icon-load' + i">
            {{ element.isFromResponse ? 'file_download' : 'file_upload' }}
          </mat-icon>
          <!-- href set to the uid to prevent the browser from marking all attachments as visited -->
          <a
            [class]="'ui-test--sdd-attachments-link-link-' + i"
            [href]="element.uid"
            (click)="$event.preventDefault(); downloadFile(element.uid, element.filename)">
            {{ element.filename }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="fileCategory">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="ui-test--sdd-attachments-th-category">
        {{ t('StatusDetails_Attachments_Category') }}
      </th>
      <td mat-cell *matCellDef="let element; let i = index" [class]="'ui-test--sdd-attachments-td-category-' + i">
        {{ t(element.fileCategory.resourceKey) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="ui-test--sdd-attachments-th-created">
        {{ t('StatusDetails_Attachments_Created') }}
      </th>
      <td mat-cell *matCellDef="let element; let i = index" [class]="'ui-test--sdd-attachments-td-created-' + i">
        {{ element.createdDate | dateFormat:'DATETIME_SHORT' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
