import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { EntriesCounterModule } from 'app/components/entries-counter';
import { FilterModule } from 'app/components/filter/filter.module';
import { OverviewModule } from 'app/components/overview/overview.module';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { ScrollTopButtonModule } from 'app/components/scroll-top-button';
import { SortModule } from 'app/components/sort/sort.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OpenProcessesComponent } from './open-processes.component';

@NgModule({
  imports: [
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    PageHeaderModule,
    FilterModule,
    PipesModule,
    SortModule,
    OverviewModule,
    ValidationSummaryModule,
    SharedModule,
    EntriesCounterModule,
    MatProgressBarModule,
    InfiniteScrollModule,
    ScrollTopButtonModule
  ],
  exports: [],
  declarations: [OpenProcessesComponent],
  providers: []
})
export class OpenProcessesModule {}
