import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextDataService } from '@core/services';
import { environment } from 'environments/environment';
import { map } from 'rxjs';
import { ConfigurationKeys } from './configuration.enums';
import { ConfigurationRequest, SettingItem, SettingsRoot } from './configuration.types';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  private configurationSettings!: SettingsRoot;

  constructor(private contextDataService: ContextDataService, private httpClient: HttpClient) {}

  get workshopGuidelinesUrlForServiceAndMaintenance(): string {
    return this.getConfigValue(ConfigurationKeys.WorkshopGuidelinesUrlForServiceAndMaintenance);
  }

  get workshopGuidelinesUrlForMotorInsurance(): string {
    return this.getConfigValue(ConfigurationKeys.WorkshopGuidelinesUrlForMotorInsurance);
  }

  get workshopGuidelinesUrlForWarranty(): string {
    return this.getConfigValue(ConfigurationKeys.WorkshopGuidelinesUrlForWarranty);
  }

  get creditNoteInformationUrl(): string {
    return this.getConfigValue(ConfigurationKeys.CreditNoteInformationUrl);
  }

  get showDefaultServiceAdvisor(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShowDefaultServiceAdvisor) === 'true';
  }

  get displayCreditNoteInformationButton(): boolean {
    return this.getConfigValue(ConfigurationKeys.DisplayCreditNoteInformationButton) === 'true';
  }

  get inboxPollingInterval(): number {
    return Number(this.getConfigValue(ConfigurationKeys.InboxPollingInterval));
  }

  get announcementPollingIntervalInSeconds(): number {
    return Number(this.getConfigValue(ConfigurationKeys.AnnouncementPollingIntervalInSeconds));
  }

  get searchParameterEventDateIsPrefilled(): boolean {
    return this.getConfigValue(ConfigurationKeys.SearchParameterEventDateIsPrefilled) === 'true';
  }

  get tireClientCacheAbsoluteExpiration(): number {
    return Number(this.getConfigValue(ConfigurationKeys.TireClientCacheAbsoluteExpiration));
  }

  get validationConfigurationCacheAbsoluteExpiration(): number {
    return Number(this.getConfigValue(ConfigurationKeys.ValidationConfigurationCacheAbsoluteExpiration));
  }

  get isMileageMandatoryInSearch(): boolean {
    return this.getConfigValue(ConfigurationKeys.IsMileageMandatoryInSearch) == 'true';
  }

  get showInsuranceReferenceField(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShowInsuranceReferenceField) === 'true';
  }

  get showAllRequestVersionNotesServiceAndMaintenance(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShowAllRequestVersionNotesServiceAndMaintenance) === 'true';
  }

  get showAllRequestVersionNotesInsuranceRepair(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShowAllRequestVersionNotesInsuranceRepair) === 'true';
  }

  get showAllRequestVersionNotesTire(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShowAllRequestVersionNotesTire) === 'true';
  }

  get showAllRequestVersionNotesWarranty(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShowAllRequestVersionNotesWarranty) === 'true';
  }

  get showAllRequestVersionNotesMobility(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShowAllRequestVersionNotesMobility) === 'true';
  }

  get showAllRequestVersionNotesMotorInsurance(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShowAllRequestVersionNotesMotorInsurance) === 'true';
  }

  get showContractInformationInTireRequest(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShowContractInformationInTireRequest) === 'true';
  }

  get showContractInformationInEditQuestionnairesForProductTypes(): string {
    return this.getConfigValue(ConfigurationKeys.ShowContractInformationInEditQuestionnairesForProductTypes);
  }

  get shouldPrintButtonBeVisible(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShouldPrintButtonBeVisible) === 'true';
  }

  get configurationKeyCacheAbsoluteExpiration(): number {
    return Number(this.getConfigValue(ConfigurationKeys.ConfigurationKeyCacheAbsoluteExpiration));
  }

  get useCheckForDoubleInputsWithMobility(): number {
    return Number(this.getConfigValue(ConfigurationKeys.UseCheckForDoubleInputsWithMobility));
  }

  get useCheckForDoubleRentalReasons(): boolean {
    return this.getConfigValue(ConfigurationKeys.UseCheckForDoubleRentalReasons) === 'true';
  }

  get dataPrivacyPolicyUrl(): string {
    return this.getConfigValue(ConfigurationKeys.DataPrivacyPolicyUrl);
  }

  get dealerDamageUrl(): string {
    return this.getConfigValue(ConfigurationKeys.DealerDamageUrl);
  }

  get infocenterUrl(): string {
    return this.getConfigValue(ConfigurationKeys.InfocenterUrl);
  }

  get legalNoticeUrl(): string {
    return this.getConfigValue(ConfigurationKeys.LegalNoticeUrl);
  }

  get userGuideUrl(): string {
    return this.getConfigValue(ConfigurationKeys.UserGuideUrl);
  }

  get isActivityTypeClassesSortingAscending(): boolean {
    return this.getConfigValue(ConfigurationKeys.IsActivityTypeClassesSortingAscending) === 'true';
  }

  get isRentalReasonsSortingAscending(): boolean {
    return this.getConfigValue(ConfigurationKeys.IsRentalReasonsSortingAscending) === 'true';
  }

  get isDamageTypesSortingAscending(): boolean {
    return this.getConfigValue(ConfigurationKeys.IsDamageTypesSortingAscending) === 'true';
  }

  get enableOfferManagement(): boolean {
    return this.getConfigValue(ConfigurationKeys.EnableOfferManagement) === 'true';
  }

  get enableMileageUpdate(): boolean {
    return this.getConfigValue(ConfigurationKeys.EnableMileageUpdate) === 'true';
  }

  get shouldSecondaryEmailAddressVisible(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShouldSecondaryEmailAddressVisible) === 'true';
  }

  get showSecondaryMailAddressInCallMeBack(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShowSecondaryMailAddressInCallMeBack) === 'true';
  }

  get credi2Url(): string {
    return this.getConfigValue(ConfigurationKeys.Credi2Url);
  }

  get mobilityAllowedForProductType(): string {
    return this.getConfigValue(ConfigurationKeys.MobilityAllowedForProductType);
  }

  get enablePerformanceLogging(): boolean {
    return this.getConfigValue(ConfigurationKeys.EnablePerformanceLogging) === 'true';
  }

  get showOfferPanelButtonOnNonSuccess(): boolean {
    return this.getConfigValue(ConfigurationKeys.ShowOfferPanelButtonOnNonSuccess) === 'true';
  }

  get chatbotExcludedBusinessPartnerIds(): string[] {
    return this.getConfigValue(ConfigurationKeys.ChatbotExcludedBusinessPartnerIds).split(',');
  }

  get sendingRestrictedDealers(): string[] {
    return this.getConfigValue(ConfigurationKeys.SendingDeniedDealers).split(',');
  }

  get isLogOnPageEnabled(): boolean {
    return this.getConfigValue(ConfigurationKeys.IsLogOnPageEnabled) === 'true';
  }

  get exclusiveActivityTypeClasses(): string[] {
    return this.getConfigValue(ConfigurationKeys.ExclusiveActivityTypeClasses)?.split('|');
  }

  get automotiveExpertOfficeListUrl(): string {
    return this.getConfigValue(ConfigurationKeys.AutomotiveExpertOfficeListUrl);
  }

  get tireTreadDepthFrom(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.TireTreadDepthValueValidation)?.valueFrom ?? NaN);
  }

  get tireTreadDepthTo(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.TireTreadDepthValueValidation)?.valueTo ?? NaN);
  }

  get positionUnitPriceFrom(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.PositionUnitPriceValueValidation)?.valueFrom ?? NaN);
  }

  get positionUnitPriceTo(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.PositionUnitPriceValueValidation)?.valueTo ?? NaN);
  }

  get positionNetFixedPriceFrom(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.PositionNetFixedPriceValueValidation)?.valueFrom ?? NaN);
  }

  get positionNetFixedPriceTo(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.PositionNetFixedPriceValueValidation)?.valueTo ?? NaN);
  }

  get positionDiscountFrom(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.PositionDiscountValueValidation)?.valueFrom ?? NaN);
  }

  get positionDiscountTo(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.PositionDiscountValueValidation)?.valueTo ?? NaN);
  }

  get positionGoodwillFrom(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.PositionGoodwillValueValidation)?.valueFrom ?? NaN);
  }

  get positionGoodwillTo(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.PositionGoodwillValueValidation)?.valueTo ?? NaN);
  }

  get positionMaterialGoodwillFrom(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.PositionMaterialGoodwillValueValidation)?.valueFrom ?? NaN
    );
  }

  get positionMaterialGoodwillTo(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.PositionMaterialGoodwillValueValidation)?.valueTo ?? NaN
    );
  }

  get workPositionQuantityFrom(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.WorkPositionQuantityValueValidation)?.valueFrom ?? NaN);
  }

  get workPositionQuantityTo(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.WorkPositionQuantityValueValidation)?.valueTo ?? NaN);
  }

  get sparePartQuantityFrom(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.SparePartQuantityValueValidation)?.valueFrom ?? NaN);
  }

  get sparePartQuantityTo(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.SparePartQuantityValueValidation)?.valueTo ?? NaN);
  }

  get tireServicePositionQuantityFrom(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.TireServicePositionQuantityValueValidation)?.valueFrom ?? NaN
    );
  }

  get tireServicePositionQuantityTo(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.TireServicePositionQuantityValueValidation)?.valueTo ?? NaN
    );
  }

  get tirePositionQuantityFrom(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.TirePositionQuantityValueValidation)?.valueFrom ?? NaN);
  }

  get tirePositionQuantityTo(): number {
    return Number(this.getConfigSettingValue(ConfigurationKeys.TirePositionQuantityValueValidation)?.valueTo ?? NaN);
  }

  get mobilityPositionQuantityFrom(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.MobilityPositionQuantityValueValidation)?.valueFrom ?? NaN
    );
  }

  get mobilityPositionQuantityTo(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.MobilityPositionQuantityValueValidation)?.valueTo ?? NaN
    );
  }

  get mobilitySparePartPositionQuantityFrom(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.MobilitySparePartPositionQuantityValueValidation)?.valueFrom ?? NaN
    );
  }

  get mobilitySparePartPositionQuantityTo(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.MobilitySparePartPositionQuantityValueValidation)?.valueTo ?? NaN
    );
  }
  get warrantyPositionNetFixedPriceFrom(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.WarrantyPositionNetFixedPriceValueValidation)?.valueFrom ?? NaN
    );
  }

  get warrantyPositionNetFixedPriceTo(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.WarrantyPositionNetFixedPriceValueValidation)?.valueTo ?? NaN
    );
  }

  get warrantySparePartPositionNetFixedPriceFrom(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.WarrantySparePartPositionNetFixedPriceValueValidation)?.valueFrom ??
        NaN
    );
  }

  get warrantySparePartPositionNetFixedPriceTo(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.WarrantySparePartPositionNetFixedPriceValueValidation)?.valueTo ??
        NaN
    );
  }

  get warrantyDiagnosticPositionNetFixedPriceFrom(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.WarrantyDiagnosticPositionNetFixedPriceValueValidation)?.valueFrom ??
        NaN
    );
  }

  get warrantyDiagnosticPositionNetFixedPriceTo(): number {
    return Number(
      this.getConfigSettingValue(ConfigurationKeys.WarrantyDiagnosticPositionNetFixedPriceValueValidation)?.valueTo ??
        NaN
    );
  }

  /**
   * Load configuration settings from endpoint based on provided keys
   */
  load(keys: string[]) {
    // If query is too long use POST. Which is not REST compliant but works
    const url = `${environment.isportCoreApiUrl}/api/configurations`;
    const requestBody = {
      application: 'ISPORT',
      keys: keys,
      tenant: this.contextDataService.data.tenant
    } as ConfigurationRequest;
    return this.httpClient.post<SettingsRoot>(url, requestBody).pipe(
      map((result: SettingsRoot) => {
        this.configurationSettings = result;
      })
    );
  }

  /**
   * Find property/option in configuration settings object and then return child property called valueFrom
   */
  private getConfigValue(key: ConfigurationKeys): string {
    return this.configurationSettings[key]?.valueFrom;
  }

  private getConfigSettingValue(key: ConfigurationKeys): SettingItem {
    return this.configurationSettings[key];
  }
}
