import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '@core/modules';
import { CustomPaginator } from '@core/utils';
import { PaginationComponent } from './pagination.component';

@NgModule({
  imports: [MatIconModule, MatPaginatorModule, MatFormFieldModule, MatSelectModule, SharedModule],
  exports: [PaginationComponent],
  declarations: [PaginationComponent],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginator }]
})
export class PaginationModule {}
