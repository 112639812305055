import { Component, Input } from '@angular/core';
import { StatusDetails } from '../status-details-dialog.types';

@Component({
  selector: 'status-details-metadata',
  templateUrl: './metadata.component.html'
})
export class StatusDetailsMetadataComponent {
  @Input() statusDetails!: StatusDetails;
}
