<div class="spinner" *ngIf="loading"></div>

<validation-summary [errorList]="errorList"></validation-summary>

<div class="navigation" *transloco="let t">
  <div class="display-block-xs">
    <page-header [title]="t('Menu_StatusOverview_Index')"></page-header>
    <div class="mb-15" *ngIf="filterCriteria">
      <filter
        [filterCriteria]="filterCriteria"
        [visibleFilterParameters]="['datePickerFrom', 'datePickerTo', 'productList', 'requestStatusList', 'invoiceStatusList', 'clarificationStatusList', 'advisorList', 'searchButton']"
        (submitFilter)="getData($event)"></filter>
    </div>
    <sort
      [visibleComponents]="['panelExpandButton', 'search']"
      [sortKeys]="sortKeys"
      [panelExpanded]="panelExpanded"
      (search)="search($event)"
      (sort)="sort($event)"
      (panelExpandedChange)="onPanelExpandedChange($event)"></sort>
    <entries-counter
      *ngIf="overviewData.length > 0 && !loading"
      [shownEntriesCount]="shownEntriesCount"
      [totalEntriesCount]="totalEntriesCount"></entries-counter>
    <div
      class="mb-60"
      *ngIf="!loading && overviewData"
      infinite-scroll
      [infiniteScrollDistance]="5"
      [infiniteScrollThrottle]="500"
      (scrolled)="onScroll()">
      <div *ngFor="let data of overviewData; let i = index">
        <overview-panel
          *ngIf="data.show"
          [class]="'ui-test--status-overview-overview-panel-panel-' + i"
          [data]="data"
          [isPanelExpanded]="panelExpanded"
          [isManagingDealer]="isManagingDealer"></overview-panel>
      </div>
      <mat-progress-bar *ngIf="scrollLoading" mode="buffer" class="my-15"></mat-progress-bar>
      <scroll-top-button></scroll-top-button>
    </div>
  </div>
</div>
