import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { RequestProcessStateCommon } from 'app/components/request-state-label/request-state-label.types';
import { SortKey } from 'app/components/sort/sort.types';
import { environment } from 'environments/environment';

export interface RequestDuplicate {
  $type: string;
  createdDate: string;
  licensePlateNumber: string;
  productType: ProductTypeIdentifier;
  requestIdForCustomer: string;
  requestGuid: string;
  requestState: RequestProcessStateCommon;
  serviceAdvisor: string;
  validUntil: string;
  requestIndex: number;
  requestId: number;
  requestStateText: string;
  modifiedDate: string;
  isHighlighted: boolean;
}

export class RequestDuplicateData implements RequestDuplicate {
  [key: string]: string | number | boolean;

  $type = '';
  createdDate = '';
  licensePlateNumber = '';
  productType: ProductTypeIdentifier = 'Unknown';
  requestIdForCustomer = '';
  requestGuid = '';
  requestState: RequestProcessStateCommon = 'Unspecified';
  serviceAdvisor = '';
  validUntil = '';
  requestIndex = -1;
  requestId = -1;
  requestStateText = '';
  modifiedDate = '';
  isHighlighted = false;
  show = false;

  constructor(requestDuplicate: RequestDuplicate) {
    requestDuplicate && Object.assign(this, requestDuplicate);
  }

  get displayRequestId() {
    let displayText = this.requestIdForCustomer;

    if (!displayText && environment.isLocal) {
      displayText = this.requestId + (this.requestIndex > 0 ? '–' + this.requestIndex : '');
    }
    if (!displayText && !environment.isLocal) {
      displayText = '–';
    }

    return displayText;
  }

  get displayRequestIdTitle() {
    let displayText = this.requestIdForCustomer;

    if (!displayText && environment.isLocal) {
      displayText = this.requestId + (this.requestIndex > 0 ? '–' + this.requestIndex : '');
    }

    if (environment.isLocal) {
      displayText += ' [' + this.requestGuid + ']';
    }

    return displayText;
  }
}

export const RequestDuplicateSortKeys: SortKey[] = [
  ['modifiedDate', 'Common_Updated'],
  ['displayRequestId', 'Common_RequestId'],
  ['licensePlateNumber', 'Common_LicensePlateNumber'],
  ['createdDate', 'Common_Created'],
  ['serviceAdvisor', 'Common_ServiceAdvisor']
];
