import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertService, ConfigurationService, ContextDataService, UserService } from '@core/services';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { TranslocoService } from '@ngneat/transloco';
import { FileUploadComponent } from 'app/components/file-upload';
import { FileUploadCategory, UploadFile } from 'app/components/file-upload/file-upload.types';
import { ImportantInformationComponent } from 'app/components/important-information';
import { ActivityTypeIdentifierCommon } from 'app/components/positions';
import { TotalAmounts } from 'app/components/request-total-amounts/request-total-amounts.types';
import { ContactDataComponent } from '../../../components/contact-data/contact-data.component';
import { RequestService } from '../request.service';
import { ImportantInformation, RequestContent } from '../request.types';
import { ProcessBarStepData } from '../shared/process-bar/process-bar.types';
import { UnsavedChangesStateStore } from '../unsaved-changes-state-store.service';
import { RequestSummary, RequestSummaryAction } from './summary.types';
@Component({
  selector: 'request-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class RequestSummaryComponent implements OnInit {
  @Input() requestGuid!: string;
  @Input() productType!: ProductTypeIdentifier | undefined;
  @Input() vin!: string | undefined;
  @Output() importantInformation: EventEmitter<ImportantInformation> = new EventEmitter<ImportantInformation>();
  @Output() move: EventEmitter<ProcessBarStepData> = new EventEmitter<ProcessBarStepData>();
  @Output() validation: EventEmitter<string[]> = new EventEmitter<string[]>();

  @ViewChild(ContactDataComponent) contactDataComponent?: ContactDataComponent;
  @ViewChild(FileUploadComponent) fileUploadComponent!: FileUploadComponent;
  @ViewChild(ImportantInformationComponent) importantInformationComponent?: ImportantInformationComponent;

  requestSummary!: RequestSummary;
  fileCategories: FileUploadCategory[] = [];
  confirmActionLoading = false;
  selectedAction!: RequestSummaryAction;
  hasEvents = false;
  eventKeys: ActivityTypeIdentifierCommon[] = ['ClaimPosition'];
  hasPositions = false;
  showSecondaryEmail = this.configurationService.shouldSecondaryEmailAddressVisible;

  constructor(
    private configurationService: ConfigurationService,
    private requestService: RequestService,
    private alertService: AlertService,
    private translocoService: TranslocoService,
    private unsavedChangesStateStore: UnsavedChangesStateStore,
    private contextDataService: ContextDataService,
    public userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.loadRequestSummaryData();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get requestContent(): RequestContent {
    return this.requestSummary.requestContent;
  }

  get totalAmounts(): TotalAmounts {
    return <TotalAmounts>{
      totalNetAmount: this.requestSummary.totalNetAmount,
      totalGoodwill: this.requestSummary.totalGoodwill,
      totalDeductibles: this.requestSummary.totalDeductibles,
      expectedPayout: this.requestSummary.expectedPayout,
      expectedCustomerShare: this.requestSummary.requestContent.expectedCustomerShare,
      isDisplayTotalGoodwillValue: this.requestContent.isDisplayTotalGoodwillValue,
      isDisplayTotalDeductibleValue: this.requestContent.isDisplayTotalDeductibles,
      isDisplayExpectedPayoutValue: this.requestContent.isDisplayExpectedPayout,
      isDisplayPayableByCustomer: this.requestContent.isDisplayPayableByCustomer,
      displayCurrencyUnit: this.requestContent.requestProcess.displayCurrencyUnit
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private loadRequestSummaryData(): void {
    this.requestService.getRequestSummaryData(this.requestGuid, this.productType, this.vin).subscribe({
      next: (res: RequestSummary) => {
        this.requestSummary = res;
        this.fileCategories = this.requestSummary.fileUploadCategories;
        this.hasEvents = this.requestContent.requestPositions.some((position) =>
          this.eventKeys.includes(position.itemType)
        );
        this.hasPositions = this.requestContent.requestPositions.some(
          (position) => position.itemType !== 'ClaimPosition'
        );
      }
    });
  }

  private validateAndSave(action: RequestSummaryAction): boolean {
    if (this.hasNotUploadedFiles()) {
      return false;
    }

    // Do not validate other then `Confirm` action
    if (action !== 'Confirm') {
      return true;
    }

    // Since 'Confirm' button will not call the server API if contact form
    // is invalid, so in that case we have to show user validation messages
    // based on local validation rules
    this.contactDataComponent?.contactDataForm.markAllAsTouched();

    if (this.requestContent.requestEditOptions !== 'Limited' && !this.contactDataComponent?.contactDataForm.valid) {
      return false;
    }

    const formData = this.contactDataComponent?.contactDataForm.value;

    if (!formData) {
      return false;
    }
    // If contact data is valid save the values into global object
    this.requestContent.contactData.phoneNumber = formData.phoneNumber ?? this.requestContent.contactData.phoneNumber;
    if (formData.secondaryEmail) {
      this.requestContent.contactData.secondaryEmail = formData.secondaryEmail;
    }

    return true;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  executeAction(action: RequestSummaryAction): void {
    if (this.validateAndSave(action)) {
      this.selectedAction = action;
      this.confirmActionLoading = true;

      this.requestService.confirmAction(this.requestSummary, action).subscribe({
        next: () => {
          this.unsavedChangesStateStore.addEventMessage('Saved');

          if (action === 'Edit') {
            const information: ImportantInformation = {
              note: this.requestSummary.requestContent.userNote,
              id: this.requestSummary.requestContent.selectedTextModuleId
            };
            this.importantInformation.emit(information);
            // TODO: Check is it versionId different or it is enough to just go back to the edit page without changing versionId
            this.move.emit({ stepName: 'prev', requestGuid: this.requestGuid });
          }

          if (action === 'Confirm') {
            this.move.emit({ stepName: 'next', requestGuid: this.requestGuid });
          }
          this.validation.emit([]);
        },
        error: (res: HttpErrorResponse) => {
          this.confirmActionLoading = false;
          if (res.status !== HttpStatusCode.BadRequest) {
            return;
          }

          const validationReader = new ValidationReader(res.error);
          this.validation.emit(validationReader.values);
        },
        complete: () => {
          this.confirmActionLoading = false;
        }
      });
    }
  }

  save(): void {
    this.requestService.confirmAction(this.requestSummary, 'Save').subscribe();
  }

  hasNotUploadedFiles(): boolean {
    if (this.fileUploadComponent.hasNotUploadedFiles) {
      this.alertService.open({
        message: this.translocoService.translate('Request_Summary_FileUpload_UncommittedChanges_Warning')
      });
      return true;
    }
    return false;
  }

  onFilesChange(files: UploadFile[]): void {
    if (files.some((file) => !file.uploaded)) {
      this.unsavedChangesStateStore.addEventMessage('Dirty');
      return;
    }

    // In case all files are uploaded and the important information are not dirty, we reset the unsaved changes popup
    if (!this.importantInformationComponent?.importantInformationForm.dirty) {
      this.unsavedChangesStateStore.addEventMessage('Saved');
    }
  }

  activateUnsavedChangesPopup(): void {
    this.unsavedChangesStateStore.addEventMessage('Dirty');
  }
}
