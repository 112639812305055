export type RequestProcessStateCommon =
  | 'New'
  | 'Active'
  | 'Summary'
  | 'Pending'
  | 'Deleted'
  | 'InProgress'
  | 'NotApproved'
  | 'Approved'
  | 'Canceled'
  | 'Inactive'
  | 'InquiryCall'
  | 'Transmitted'
  | 'Unspecified';

export type RequestStateUsage = 'ListItem' | 'StatusDetails';

export const ConfirmedRequestProcessStates: RequestProcessStateCommon[] = [
  'InProgress',
  'NotApproved',
  'Approved',
  'Canceled',
  'Inactive',
  'InquiryCall',
  'Transmitted',
  'Unspecified'
];

export const DraftRequestProcessStates: RequestProcessStateCommon[] = [
  'New',
  'Active',
  'Summary',
  'Pending',
  'Deleted'
];
