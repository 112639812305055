import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { AlertModule, ErrorHandlingModule } from '@core/services';
import { InvoiceClarificationHistoryModule } from 'app/components/invoice-clarification-history/invoice-clarification-history.module';
import { InvoiceStateLabelModule } from 'app/components/invoice-state-label/invoice-state-label.module';
import { RequestStateLabelModule } from 'app/components/request-state-label/request-state-label.module';
import { RequestTotalAmountsModule } from 'app/components/request-total-amounts/request-total-amounts.module';
import { StatusDetailsAnswersComponent } from './answers/answers.component';
import { StatusDetailsAttachmentsComponent } from './attachments/attachments.component';
import { StatusDetailsDetailHeaderComponent } from './detail-header/detail-header.component';
import { StatusDetailsEventsComponent } from './events/events.component';
import { StatusDetailsInvoiceHistoryComponent } from './invoice-history/invoice-history.component';
import { StatusDetailsMetadataComponent } from './metadata/metadata.component';
import { StatusDetailsPositionsComponent } from './positions/positions.component';
import { StatusDetailsNotesComponent } from './positions/status-details-notes/status-details-notes.component';
import { StatusDetailsPrintComponent } from './print/print.component';
import { StatusDetailsDialogComponent } from './status-details-dialog.component';

@NgModule({
  imports: [
    RouterModule,
    MatIconModule,
    MatSelectModule,
    MatTabsModule,
    MatTooltipModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    MatTableModule,
    MatSortModule,
    RequestTotalAmountsModule,
    AlertModule,
    RequestStateLabelModule,
    InvoiceStateLabelModule,
    PipesModule,
    ErrorHandlingModule,
    SharedModule,
    InvoiceClarificationHistoryModule
  ],
  exports: [],
  declarations: [
    StatusDetailsDialogComponent,
    StatusDetailsMetadataComponent,
    StatusDetailsEventsComponent,
    StatusDetailsPositionsComponent,
    StatusDetailsAnswersComponent,
    StatusDetailsInvoiceHistoryComponent,
    StatusDetailsAttachmentsComponent,
    StatusDetailsPrintComponent,
    StatusDetailsDetailHeaderComponent,
    StatusDetailsNotesComponent
  ]
})
export class StatusDetailsDialogModule {}
