import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ConfigurationService, LocaleFormatService, RequestStateStore, TenantSettingsService } from '@core/services';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { UnsavedChangesStateStore } from 'app/pages/request';
import { RequestService } from 'app/pages/request/request.service';
import { WarrantyGenericPositionComponent } from '../warranty-generic-position.component';

@Component({
  selector: 'warranty-position',
  templateUrl: './warranty-position.component.html'
})
export class WarrantyPositionComponent extends WarrantyGenericPositionComponent {
  constructor(
    formBuilder: UntypedFormBuilder,
    positionConfigurationService: PositionConfigurationService,
    requestService: RequestService,
    requestStore: RequestStateStore,
    unsavedChangesStateStore: UnsavedChangesStateStore,
    localeFormatService: LocaleFormatService,
    tenantSettingsService: TenantSettingsService,
    configurationService: ConfigurationService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(
      formBuilder,
      positionConfigurationService,
      requestStore,
      requestService,
      unsavedChangesStateStore,
      localeFormatService,
      tenantSettingsService,
      configurationService,
      changeDetector,
      elementRef
    );
  }

  override get isActivityTypeClassDisabled(): boolean {
    return !this.isGermanWarrantyEditMode && super.isActivityTypeClassDisabled;
  }

  override editGoodwill(value?: string | number): void {
    super.editGoodwill(value);
    this.requestStore.addEventMessage({ eventName: 'GoodWillValueUpdated', payload: this.positionData.goodwill });
  }

  override updateActivityTypeClassKey(value?: number): void {
    super.updateActivityTypeClassKey();

    this.requestStore.addEventMessage({ eventName: 'ActivityClassTypeUpdated', payload: value });
  }
}
