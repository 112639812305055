<div *transloco="let t">
  <h2 mat-dialog-title class="ui-test--error-message-dialog-header-title">{{ title || t("Error_ServerError") }}</h2>

  <mat-dialog-content class="flex-col">
    <span *ngIf="!hideServerErrorInformationText" class="ui-test--error-contact-form-span-information-text">
      {{ t("Error_ServerError_InformationText") }}
    </span>
    <span class="pt-15 pb-35 fw-500 ui-test--error-contact-form-span-error-message">{{ errorMessage }}</span>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="ui-test--error-contact-form-button-cancel" mat-button mat-dialog-close>
      {{ t('Common_Cancel') }}
    </button>
    <button class="ui-test--error-contact-form-button-redirect" *ngIf="redirectUrl" mat-button (click)="redirect()">
      {{ redirectUrlText }}
    </button>
    <button
      class="ui-test--error-contact-form-button-report-problem"
      *ngIf="showContactButton"
      cdkFocusInitial
      mat-button
      color="primary"
      (click)="openErrorForm()">
      {{ t("Error_ServerError_ReportProblem") }}
    </button>
  </mat-dialog-actions>
</div>
