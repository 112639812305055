import { Pipe, PipeTransform } from '@angular/core';
import { LocaleFormatService } from '@core/services';

@Pipe({
  name: 'mileageFormat'
})
export class MileageFormatPipe implements PipeTransform {
  constructor(private localeFormatService: LocaleFormatService) {}

  transform(value?: number): string {
    if (!value || value === 0) {
      return '–';
    }

    return this.localeFormatService.formatNumericString(value.toString());
  }
}
