<ng-container *ngIf="entry">
  <mat-card *transloco="let t">
    <div class="flex justify-between">
      <mat-card-title> {{ entry.title }} </mat-card-title>
      <mat-chip-list>
        <mat-chip *ngIf="!entry.alreadyRead" color="primary" selected>{{ t('Common_New') }}</mat-chip>
        <mat-chip *ngIf="entry.isImportant" color="accent" selected>{{ t('Common_Important') }}</mat-chip>
      </mat-chip-list>
    </div>
    <mat-card-subtitle>{{ entry.createdDate | dateFormat:'DATE_SHORT' }}</mat-card-subtitle>
    <mat-card-content>
      <p [innerHTML]="entry.longText | safe:'html'"></p>
    </mat-card-content>
    <mat-card-actions *ngIf="!entry.alreadyRead" align="end">
      <button
        mat-raised-button
        [class.button-spinner]="markAsReadLoading"
        [disabled]="markAsReadLoading"
        (click)="onMarkAsReadClick()">
        <mat-icon>done</mat-icon> {{ t('Common_Read') }}
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>
