<div class="flex-row justify-between content-center align-center">
  <mat-paginator
    class="flex flex-1 ui-test--pagination-mat-paginator-paginator"
    [length]="dataLength"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="onPaginatorChange($event)"></mat-paginator>

  <mat-form-field *ngIf="pages.length > 1" class="go-to">
    <mat-select class="ui-test--pagination-mat-select-select" [(ngModel)]="goTo" (selectionChange)="goToChange()">
      <mat-select-trigger>{{ goTo }} / {{ pages[pages.length - 1].number }}</mat-select-trigger>
      <mat-option
        *ngFor="let page of pages; let i = index"
        [value]="page.number"
        [class]="'ui-test--pagination-mat-option-option-' + i">
        <mat-icon [class]="'ui-test--pagination-mat-icon-description-' + i">description</mat-icon>
        <span [class]="'ui-test--pagination-span-text-' + i">
          {{ page.number }} ({{ page.from }}{{ page.from !== page.to ? ' - ' + page.to : '' }})
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
