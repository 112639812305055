import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { ErrorContactFormComponent } from '../error-contact-form/error-contact-form.component';
import { ApiErrorDetails } from '../error-message.types';

@Component({
  selector: 'error-message-dialog',
  templateUrl: './error-message-dialog.component.html',
  styleUrls: ['./error-message-dialog.component.scss']
})
export class ErrorMessageDialogComponent implements OnInit {
  @Input() errorData!: HttpErrorResponse;
  @Input() showContactButton: boolean = true;
  /**
   * (Optional) Pass a custom error message
   */
  @Input() errorMessage: string = '';
  /**
   * (Optional) Override the default error title
   */
  @Input() title: string = '';
  /**
   * (Optional) Hide the default text.
   */
  @Input() hideServerErrorInformationText: boolean = false;

  @Input() redirectUrl: string = '';
  @Input() redirectUrlText: string = '';
  @Input() isRedirectUrlExternal: boolean = false;

  constructor(
    private _dialogRef: MatDialogRef<ErrorMessageDialogComponent>,
    private _dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.errorMessage === '' && this.errorData) {
      this.errorMessage = this.errorData?.error?.Message ?? '';
    }
  }

  public openErrorForm() {
    const errorDetails = this.createErrorDetails();
    const errorContractDetailsModal = this._dialog.open(ErrorContactFormComponent);
    errorContractDetailsModal.componentInstance.errorDetails = errorDetails;
    this._dialogRef.close();
  }

  public redirect() {
    if (this.isRedirectUrlExternal) {
      window.location.href = this.redirectUrl;
    } else {
      this.router.navigate([this.redirectUrl]);
    }
  }

  private createErrorDetails(): ApiErrorDetails {
    return {
      errorMessage: this.errorMessage,
      errorCode: this.errorData.status,
      timestamp: DateTime.now()
    };
  }
}
