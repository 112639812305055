import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ConfigurationService } from '@core/services';
import { VehicleInformation } from '../call-me-back.types';

@Component({
  selector: 'vehicle-information',
  templateUrl: './vehicle-information.component.html',
  styleUrls: ['./vehicle-information.component.scss']
})
export class VehicleInformationComponent implements OnInit {
  @Input() vehicle?: VehicleInformation;

  vehicleInformationForm = this._formBuilder.group({
    vehicleIdentificationNumber: ['', Validators.maxLength(17)],
    licensePlateNumber: '',
    actualMileage: [0, Validators.min(0)]
  });

  constructor(public configurationService: ConfigurationService, private _formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.prefillForm();
  }

  resetVehicle() {
    this.vehicle = undefined;
    this.vehicleInformationForm.reset();
    this.vehicleInformationForm.enable();
  }

  private prefillForm() {
    if (!this.vehicle?.vehicleIdentificationNumber || !this.vehicle?.licensePlateNumber) {
      return;
    }

    this.vehicleInformationForm.patchValue({
      vehicleIdentificationNumber: this.vehicle.vehicleIdentificationNumber,
      licensePlateNumber: this.vehicle.licensePlateNumber,
      actualMileage: this.vehicle.actualMileage
    });

    this.vehicleInformationForm.disable();
  }
}
