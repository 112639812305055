import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts new line to the br tag
 */
@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value A string that will be transformed to the break rule
   */
  transform(value: string): string {
    return value.replace(/(?:\r\n|\\r\\n|\\r|\\n|\r|\n)/g, '<br />');
  }
}
