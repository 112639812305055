<mat-card class="positions-card mb-15" [class.warning]="position.hasErrors" *transloco="let t">
  <mat-card-content>
    <div class="flex-row justify-between content-none gap-15">
      <div class="flex flex-grow-15">
        <div class="flex-col justify-start content-center align-center">
          <div
            class="position-index"
            [class]="'ui-test--positions-div-labor-position-index-' + position.laborPositionIndex">
            {{ position.laborPositionIndex }}
          </div>
          <strong [class]="'ui-test--positions-strong-labor-position-index-acronym' + position.laborPositionIndex">
            {{ acronym }}
          </strong>
        </div>
        <div class="flex-col flex-1 justify-start content-center align-center">
          <strong
            [class]="'ui-test--positions-strong-labor-position-index-abbreviation-' + position.laborPositionIndex">
            {{ abbreviation }}
          </strong>
          <ng-container
            [ngTemplateOutlet]="externalProductNumber"
            [ngTemplateOutletContext]="{ show: position.itemType !== 'TirePosition' }"></ng-container>
        </div>
      </div>

      <div
        class="activity-type flex flex-grow-15"
        [class]="'ui-test--positions-div-activity-type-description-' + position.laborPositionIndex">
        <ng-container *ngIf="isEnabled(position, 'ActivityTypeKey')">
          {{ position.activityTypeDescription }}
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="externalProductNumber"
          [ngTemplateOutletContext]="{ show: position.itemType === 'TirePosition' }"></ng-container>
      </div>

      <div class="flex-grow-25">
        <ng-container *ngIf="position.quantity || position.unitPrice">
          <div class="flex-row gap-15" *ngIf="isEnabled(position, 'Quantity')">
            <strong
              class="flex flex-grow-50 justify-end content-center"
              [class]="'ui-test--positions-strong-quantity-label-' + position.laborPositionIndex">
              {{ t(labelKeysMap.get('Quantity') ?? '') }}
            </strong>
            <span
              class="flex-nogrow quantity"
              [matTooltip]="position.errorMap.get('Quantity') ?? ''"
              matTooltipPosition="above"
              [class.has-errors]="position.errorMap.has('Quantity')"
              [class]="'ui-test--positions-span-quantity-' + position.laborPositionIndex">
              {{ (position.quantity | numericFormat) + ' ' + (displayQuantityUnit ? position.quantityUnitText : '') }}
            </span>
          </div>

          <div class="flex-row gap-15" *ngIf="isEnabled(position, 'UnitPrice')">
            <strong
              class="flex flex-grow-50 justify-end content-center"
              [class]="'ui-test--positions-strong-unit-price-label-' + position.laborPositionIndex">
              {{ t(labelKeysMap.get('UnitPrice') ?? '') }}
            </strong>
            <span
              class="flex-nogrow unit-price"
              [matTooltip]="position.errorMap.get('UnitPrice') ?? ''"
              matTooltipPosition="above"
              [class.has-errors]="position.errorMap.has('UnitPrice')"
              [class]="'ui-test--positions-span-unit-price-' + position.laborPositionIndex">
              {{ position.unitPrice | currencyOrDash }} {{ position.displayCurrencyUnit }}
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="position.netFixedPrice">
          <div class="flex-row gap-15" *ngIf="isEnabled(position, 'NetFixedPrice')">
            <strong
              class="flex flex-grow-50 justify-end content-center"
              [class]="'ui-test--positions-strong-net-fixed-price-label-' + position.laborPositionIndex">
              {{ t(labelKeysMap.get('NetFixedPrice') ?? '') }}
            </strong>
            <span
              class="flex-nogrow net-fixed-price"
              [matTooltip]="position.errorMap.get('NetFixedPrice') ?? ''"
              matTooltipPosition="above"
              [class.has-errors]="position.errorMap.has('NetFixedPrice')"
              [class]="'ui-test--positions-span-net-fixed-price-' + position.laborPositionIndex">
              {{ position.netFixedPrice | currencyOrDash }} {{ position.displayCurrencyUnit }}
            </span>
          </div>
        </ng-container>
      </div>

      <div class="flex-grow-20">
        <div class="flex-row gap-15" *ngIf="isEnabled(position, 'Discount')">
          <strong
            class="flex flex-grow-50 justify-end content-center"
            [class]="'ui-test--positions-strong-discount-label-' + position.laborPositionIndex">
            {{ t(labelKeysMap.get('Discount') ?? '') }}
          </strong>
          <span
            class="flex-nogrow discount"
            [matTooltip]="position.errorMap.get('Discount') ?? ''"
            matTooltipPosition="above"
            [class.has-errors]="position.errorMap.has('Discount')"
            [class]="'ui-test--positions-span-discount-' + position.laborPositionIndex">
            {{ (position.discount > 0 ? (position.discount | numericFormat) + ' %' : '–') }}
          </span>
        </div>

        <div class="flex-row gap-15" *ngIf="isEnabled(position, 'Goodwill')">
          <strong
            class="flex flex-grow-50 justify-end content-center"
            [class]="'ui-test--positions-strong-goodwill-label-' + position.laborPositionIndex">
            {{ t(labelKeysMap.get('Goodwill') ?? '') }}
          </strong>
          <span
            class="flex-nogrow goodwill"
            [matTooltip]="position.errorMap.get('Goodwill') ?? ''"
            matTooltipPosition="above"
            [class.has-errors]="position.errorMap.has('Goodwill')"
            [class]="'ui-test--positions-span-goodwill-' + position.laborPositionIndex">
            {{ (position.goodwill > 0 ? (position.goodwill | numericFormat) + ' %' : '–') }}
          </span>
        </div>

        <div class="flex-row gap-15" *ngIf="isEnabled(position, 'MaterialGoodwill')">
          <strong
            class="flex flex-grow-50 justify-end content-center"
            [class]="'ui-test--positions-strong-material-goodwill-label-' + position.laborPositionIndex">
            {{ t(labelKeysMap.get('MaterialGoodwill') ?? '') }}
          </strong>
          <span
            class="material-goodwill flex-nogrow"
            [class]="'ui-test--positions-span-material-goodwill-' + position.laborPositionIndex"
            [matTooltip]="position.errorMap.get('MaterialGoodwill') ?? ''"
            matTooltipPosition="above"
            [class.has-errors]="position.errorMap.has('MaterialGoodwill')">
            {{ position.materialGoodwill && position.materialGoodwill > 0 ? (position.materialGoodwill | numericFormat)
            + ' %' : '–' }}
          </span>
        </div>
      </div>

      <div class="flex-grow-25">
        <div class="flex-row gap-15">
          <strong
            class="flex flex-grow-50 justify-end content-center"
            [class]="'ui-test--positions-strong-net-amount-label-' + position.laborPositionIndex">
            {{ t(labelKeysMap.get('NetAmount') ?? '') }}
          </strong>
          <span
            class="flex-nogrow justify-end content-center net-amount"
            [class]="'ui-test--positions-span-net-amount-' + position.laborPositionIndex"
            [matTooltip]="position.errorMap.get('NetAmount') ?? ''"
            matTooltipPosition="above"
            [class.has-errors]="position.errorMap.has('NetAmount')">
            {{ position.netAmount | currencyOrDash }} {{ position.displayCurrencyUnit }}
          </span>
        </div>

        <ng-container
          [ngTemplateOutlet]="damageType"
          [ngTemplateOutletContext]="{ show: position.itemType !== 'TirePosition' }"></ng-container>
      </div>
    </div>

    <div class="flex-row mt-10" *ngIf="position.itemType === 'TirePosition'">
      <div class="fill">
        <ng-container [ngTemplateOutlet]="damageType" [ngTemplateOutletContext]="{ show: true }"></ng-container>
      </div>
    </div>

    <div class="flex-col justify-between content-none" *ngIf="position.itemType === 'TirePosition'">
      <mat-divider class="my-10"></mat-divider>

      <div class="flex-row justify-start content-center gap-15">
        <strong
          class="flex flex-grow-15"
          [class]="'ui-test--positions-strong-tire-selection-label-' + position.laborPositionIndex">
          {{ t('Common_TireSelection') }}
        </strong>
        <span class="flex flex-1" [class]="'ui-test--positions-span-tire-selection-' + position.laborPositionIndex">
          {{ position.activityTypeDescription }} {{ position.tire?.brand }} {{ position.tire?.tireName }}
          ({{position.tire?.size }} {{ position.tire?.speedIndex }}, {{ position.tire?.loadIndex }})
        </span>
      </div>

      <div class="flex-col justify-between content-strech" *ngIf="position.tireReplacements">
        <ng-container *ngIf="position.vehicleType === 'PassengerCar'">
          <div class="flex-row gap-15">
            <div class="flex flex-grow-50 gap-15" *ngIf="position.tireReplacements.frontLeft?.isChecked">
              <strong
                class="flex flex-grow-30"
                [class]="'ui-test--positions-strong-tire-front-left-label' + position.laborPositionIndex">
                {{ t('Common_Tire_FrontLeft') }}
              </strong>
              <span
                class="flex flex-1"
                *ngIf="position.tireReplacements.frontLeftTreadDepthDisplayText"
                [class]="'ui-test--positions-span-tire-front-left-' + position.laborPositionIndex">
                {{ position.tireReplacements.frontLeftTreadDepthDisplayText | numericFormat }} {{
                position.tireReplacements.tireTreadDepthMeasurementUnit }}
              </span>
            </div>

            <div class="flex flex-1" *ngIf="position.tireReplacements.frontRight?.isChecked">
              <strong
                class="flex flex-grow-30"
                [class]="'ui-test--positions-strong-tire-front-right-label-' + position.laborPositionIndex">
                {{ t('Common_Tire_FrontRight') }}
              </strong>
              <span
                class="flex flex-1"
                *ngIf="position.tireReplacements.frontRightTreadDepthDisplayText"
                [class]="'ui-test--positions-span-tire-front-right-' + position.laborPositionIndex">
                {{ position.tireReplacements.frontRightTreadDepthDisplayText | numericFormat }} {{
                position.tireReplacements.tireTreadDepthMeasurementUnit }}
              </span>
            </div>
          </div>

          <div class="flex-row gap-15">
            <div class="flex flex-grow-50 gap-15" *ngIf="position.tireReplacements.rearLeft?.isChecked">
              <strong
                class="flex flex-grow-30"
                [class]="'ui-test--positions-strong-tire-rear-left-label-' + position.laborPositionIndex">
                {{ t('Common_Tire_RearLeft') }}
              </strong>
              <span
                class="flex flex-1"
                *ngIf="position.tireReplacements.rearLeftTreadDepthDisplayText"
                [class]="'ui-test--positions-span-tire-rear-left-' + position.laborPositionIndex">
                {{ position.tireReplacements.rearLeftTreadDepthDisplayText | numericFormat }} {{
                position.tireReplacements.tireTreadDepthMeasurementUnit }}
              </span>
            </div>

            <div class="flex flex-1" *ngIf="position.tireReplacements.rearRight?.isChecked">
              <strong
                class="flex flex-grow-30"
                [class]="'ui-test--positions-strong-tire-rear-right-label-' + position.laborPositionIndex">
                {{ t('Common_Tire_RearRight') }}
              </strong>
              <span
                class="flex flex-1"
                *ngIf="position.tireReplacements.rearRightTreadDepthDisplayText"
                [class]="'ui-test--positions-span-tire-rear-right-' + position.laborPositionIndex">
                {{ position.tireReplacements.rearRightTreadDepthDisplayText | numericFormat }} {{
                position.tireReplacements.tireTreadDepthMeasurementUnit }}
              </span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="position.vehicleType === 'Motorcycle'">
          <div class="flex-row gap-15">
            <div class="flex flex-grow-50 gap-15">
              <strong
                class="flex flex-grow-30"
                [class]="'ui-test--positions-strong-tire-rear-label-' + position.laborPositionIndex">
                {{ t('Common_Tire_Rear') }}
              </strong>
              <span
                class="flex flex-1"
                *ngIf="position.tireReplacements.rearTreadDepthDisplayText"
                [class]="'ui-test--positions-span-tire-rear-' + position.laborPositionIndex">
                {{ position.tireReplacements.rearTreadDepthDisplayText | numericFormat }} {{
                position.tireReplacements.tireTreadDepthMeasurementUnit }}
              </span>
            </div>

            <div class="flex flex-1">
              <strong
                class="flex flex-grow-30"
                [class]="'ui-test--positions-strong-tire-front-label-' + position.laborPositionIndex">
                {{ t('Common_Tire_Front') }}
              </strong>
              <span
                class="flex flex-1"
                *ngIf="position.tireReplacements.frontTreadDepthDisplayText"
                [class]="'ui-test--positions-span-tire-front-' + position.laborPositionIndex">
                {{ position.tireReplacements.frontTreadDepthDisplayText | numericFormat }} {{
                position.tireReplacements.tireTreadDepthMeasurementUnit }}
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #externalProductNumber let-show="show">
  <div
    class="flex flex-1"
    *ngIf="show && isEnabled(position, 'ExternalProductNumber')"
    [class]="'ui-test--positions-div-external-product-number-' + position.laborPositionIndex">
    {{ position.externalProductNumber ? position.externalProductNumber : '-' }}
  </div>
</ng-template>

<ng-template #damageType let-show="show">
  <div class="flex-row gap-15" *ngIf="show && isEnabled(position, 'DamageType')">
    <strong
      class="flex flex-1 ui-test--positions-strong-damage-type-label"
      [ngClass]="position.itemType !== 'TirePosition' ? 'justify-end content-center align-center flex-grow-50' : 'flex-grow-15'">
      {{ (labelKeysMap.get('DamageType') ?? '') | transloco }}
    </strong>
    <span
      class="justify-end content-center flex-nogrow damage-type ui-test--positions-span-damage-type"
      [matTooltip]="position.errorMap.get('DamageType') ?? ''"
      matTooltipPosition="above"
      [class.has-errors]="position.errorMap.has('DamageType')">
      {{ position.damageType ? position.damageType.displayText : '–' }}
    </span>
  </div>
</ng-template>
