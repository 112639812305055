import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DashboardContractDetailsDialogComponent } from './contract-details-dialog.component';

@Injectable({ providedIn: 'root' })
export class DashboardContractDetailsDialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(): void {
    this.dialog.open(DashboardContractDetailsDialogComponent, { minHeight: '500px', minWidth: '900px' });
  }
}
