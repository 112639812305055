import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { SendingRestrictedModule } from 'app/components/sending-restricted/sending-restricted.module';
import { ProcessBarModule } from 'app/pages/request/shared/process-bar/process-bar.module';
import { InvoiceClarificationCancelComponent } from './cancel.component';
import { InvoiceClarificationCancelSummaryComponent } from './summary/summary.component';

@NgModule({
  imports: [
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    PageHeaderModule,
    ProcessBarModule,
    SendingRestrictedModule,
    PipesModule,
    SharedModule
  ],
  exports: [],
  declarations: [InvoiceClarificationCancelComponent, InvoiceClarificationCancelSummaryComponent],
  providers: []
})
export class InvoiceClarificationCancelModule {}
