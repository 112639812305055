import { SortKey } from 'app/components/sort/sort.types';

export interface Messages {
  messages: Message[];
}

export interface Message {
  [key: string]: string | number | boolean;

  correlationId: string;
  createdDate: string;
  id: number;
  indicatorText: string;
  isRead: boolean;
  licenseNumber: string;
  messageProcessType: string;
  requestDate: string;
  subject: string;
  text: string;
  show: boolean;
}

export const InboxSortKeys: SortKey[] = [
  ['indicatorText', 'Common_IndicatorText'],
  ['createdDate', 'Common_Created'],
  ['subject', 'Inbox_Index_Subject']
];

export const InboxFilterEntries: string[] = ['subject', 'indicatorText', 'text', 'requestDate', 'createdDate'];
