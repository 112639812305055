import { Injectable } from '@angular/core';
import { TranslocoHttpLoader } from '@core/transloco';
import { jsonPropertyNamesToLowerCase } from '@core/utils';
import * as signalR from '@microsoft/signalr';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'environments/environment';
import { ConfigurationKeys, ConfigurationService, ConfigurationValueCommon } from '../configuration';
import { ContextDataService } from '../context-data';
import { NotificationMessageCommon, NotificationService } from '../notification';
import { SnackBarStore } from '../snack-bar/snack-bar-store.service';
import { EventPayload, MethodName } from './event.types';

const RECONNECT_TIMEOUT = 5000;

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private hubConnection?: signalR.HubConnection;
  private connectionOpen = false;

  constructor(
    private contextDataService: ContextDataService,
    private translocoService: TranslocoService,
    private translocoHttpLoader: TranslocoHttpLoader,
    private configurationService: ConfigurationService,
    private snackBarStore: SnackBarStore,
    private notificationService: NotificationService
  ) {}

  public setupSignalRChangeListener() {
    this.setResourceChangeListener();
    this.setConfigurationChangeListener();
    this.setNotificationChangeListener();
  }

  public setHubConnectionChangeListener = (methodName: MethodName, callback: (eventPayload: EventPayload) => void) => {
    if (environment.isLocal) {
      return;
    }

    this.startConnection();
    if (!this.hubConnection) {
      return;
    }

    this.hubConnection.on(methodName, callback);
  };

  /**
   * Reload configuration on signalR async notification.
   */
  private setConfigurationChangeListener(): void {
    const callback = (eventPayload: EventPayload) => {
      const updatedItem = JSON.parse(eventPayload.data) as ConfigurationValueCommon;
      if (Object.keys(ConfigurationKeys).includes(updatedItem.keyName)) {
        this.configurationService.load(Object.values(ConfigurationKeys)).subscribe();
      }
    };
    this.setHubConnectionChangeListener('ConfigurationUpdate', callback);
  }

  /**
   * Reload translations on signalR async notification.
   */
  private setResourceChangeListener(): void {
    const callback = () => {
      const activeLang = this.translocoService.getActiveLang();
      this.translocoHttpLoader.getTranslation(activeLang).subscribe((translation) => {
        this.translocoService.setTranslation(translation);
      });
    };

    this.setHubConnectionChangeListener('ResourceUpdate', callback);
  }

  private setNotificationChangeListener() {
    const callback = (eventPayload: EventPayload) => {
      const notificationMessage = JSON.parse(
        jsonPropertyNamesToLowerCase(eventPayload.data)
      ) as NotificationMessageCommon;
      // Clear PWA cache and update pages content based on notification
      this.notificationService.processNotificationMessage(notificationMessage);
      this.snackBarStore.addSnackBarMessage(
        this.notificationService.translateNotificationMessage(notificationMessage.data.subject)
      );
    };

    this.setHubConnectionChangeListener('NotificationUpdate', callback);
  }

  private extractAccessToken(): string {
    const keys = Object.keys(sessionStorage).find((key) => key.endsWith('isc'));
    if (!keys) {
      return '';
    }
    const authObject = JSON.parse(sessionStorage.getItem(keys) ?? '');
    return authObject.authnResult.access_token;
  }

  private startConnection = () => {
    if (!this.hubConnection) {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Critical)
        .withUrl(environment.eventServiceUrl, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          accessTokenFactory: () => this.extractAccessToken()
        })
        .build();
    }

    if (!this.connectionOpen) {
      this.connectionOpen = true;
      this.hubConnection
        .start()
        .then(() => {
          if (!this.hubConnection) {
            return;
          }
          this.connectionOpen = true;
          // Register user to the dealer group to receive group notifications
          this.hubConnection.invoke(
            'AddToDealerGroup',
            this.contextDataService.userInfo.dealer.externalDealerNumber,
            this.contextDataService.userInfo.dealer.country,
            this.contextDataService.userInfo.dealer.fsRegionNumber
          );
        })
        .catch(() => {
          setTimeout(() => {
            this.connectionOpen = false;
            this.startConnection();
          }, RECONNECT_TIMEOUT);
        });
      this.hubConnection.onclose(() => {
        if (this.connectionOpen) {
          this.connectionOpen = false;
          console.error('Connection to messagehub dropped. Notifications disabled!. Reconnecting...');
          setTimeout(() => {
            this.connectionOpen = false;
            this.startConnection();
          }, RECONNECT_TIMEOUT);
        }
      });
      this.hubConnection.onreconnected(() => {
        this.connectionOpen = true;
        console.log('Connection to messagehub restored. Notifications enabled.');
      });
      this.hubConnection.onreconnecting(() => {
        this.connectionOpen = false;
        console.error('Connection to messagehub dropped. Notifications disabled!. Reconnecting...');
      });
    }
  };
}
