import { Subject } from 'rxjs';
import { SearchIndexFields } from './contract-search.enums';

export class ContractSearchConfigurator {
  isExternalSearch: boolean = false;
  ignoreDataProtection: boolean = false;
  isMissingSearchParameterPageChange: Subject<boolean> = new Subject<boolean>();

  private _enabledIndexFields: SearchIndexFields;
  private _enabledFieldsForMissingParameter: SearchIndexFields | undefined;
  readonly isAftersalesUser: boolean;

  constructor(enabledIndexFields: SearchIndexFields, isAftersalesUser: boolean) {
    this._enabledIndexFields = enabledIndexFields;
    this.isAftersalesUser = isAftersalesUser;
  }

  consolidate(enabledIndexFields: SearchIndexFields, enabledFieldsForMissingParameter: SearchIndexFields): void {
    this._enabledIndexFields = enabledIndexFields;
    this._enabledFieldsForMissingParameter = enabledFieldsForMissingParameter;

    this.isMissingSearchParameterPageChange.next(this.isMissingSearchParameterPage);
  }

  reset(enabledIndexFields: SearchIndexFields) {
    this._enabledIndexFields = enabledIndexFields;

    this._enabledFieldsForMissingParameter = undefined;
    this.isMissingSearchParameterPageChange.next(this.isMissingSearchParameterPage);
  }

  get enabledIndexFields(): SearchIndexFields {
    return this._enabledIndexFields;
  }

  get displayContractNumber(): boolean {
    return (this._enabledIndexFields & SearchIndexFields.ContractNumber) === SearchIndexFields.ContractNumber;
  }

  get displayDamageDate(): boolean {
    return (this._enabledIndexFields & SearchIndexFields.DamageDate) === SearchIndexFields.DamageDate;
  }

  get displayDateOfFirstRegistration(): boolean {
    return (
      (this._enabledIndexFields & SearchIndexFields.DateOfFirstRegistration) ===
      SearchIndexFields.DateOfFirstRegistration
    );
  }

  get displayLicensePlateNumber(): boolean {
    return (this._enabledIndexFields & SearchIndexFields.LicensePlateNumber) === SearchIndexFields.LicensePlateNumber;
  }

  get displayVehicleIdentificationNumber(): boolean {
    return (
      (this._enabledIndexFields & SearchIndexFields.VehicleIdentificationNumber) ===
      SearchIndexFields.VehicleIdentificationNumber
    );
  }

  get displayMileage(): boolean {
    return (this._enabledIndexFields & SearchIndexFields.Mileage) === SearchIndexFields.Mileage;
  }

  get isValid(): boolean {
    return this._enabledIndexFields >= 2;
  }

  get isMissingSearchParameterPage(): boolean {
    return this._enabledFieldsForMissingParameter !== undefined;
  }

  get isVehicleIdentificationNumberEnabled(): boolean {
    return (
      ((this._enabledFieldsForMissingParameter ?? 0) & SearchIndexFields.VehicleIdentificationNumber) ===
      SearchIndexFields.VehicleIdentificationNumber
    );
  }

  get isLicensePlateNumberEnabled(): boolean {
    return (
      ((this._enabledFieldsForMissingParameter ?? 0) & SearchIndexFields.LicensePlateNumber) ===
      SearchIndexFields.LicensePlateNumber
    );
  }

  get isContractNumberEnabled(): boolean {
    return (
      ((this._enabledFieldsForMissingParameter ?? 0) & SearchIndexFields.ContractNumber) ===
      SearchIndexFields.ContractNumber
    );
  }
}
