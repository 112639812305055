import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { SelectListItem } from '@core/types/mvc.types';
import { TranslocoService } from '@ngneat/transloco';
import { PaginationComponent } from 'app/components/pagination/pagination.component';
import { filterProperties, sort } from 'app/components/sort/sort.helper';
import { SortEvent } from 'app/components/sort/sort.types';
import { InboxService } from './inbox.service';
import { InboxFilterEntries, InboxSortKeys, Message } from './inbox.types';

@Component({
  selector: 'inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxComponent implements OnInit {
  @ViewChild('paginatorTop') paginatorTop!: PaginationComponent;
  @ViewChild('paginatorBottom') paginatorBottom!: PaginationComponent;

  public messages: Message[] = [];
  public allMessages: Message[] = [];
  public availableProductTypes: SelectListItem[] = [];
  public sortKeys = InboxSortKeys;
  loading = true;
  sortDirection: SortDirection = 'desc';
  sortKey = InboxSortKeys[0][0];
  filterEntries = InboxFilterEntries;

  constructor(private inboxService: InboxService, private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.loadMessages();
  }

  onPaginatorChange(event: PageEvent) {
    this.showPage(event.pageIndex, event.pageSize);
  }

  filterForProducts(selections: SelectListItem[]) {
    this.messages = this.allMessages.filter((message) => {
      return (
        selections.length == 0 ||
        selections.some((productType: SelectListItem) => productType.value == message.messageProcessType)
      );
    });
    this.showPage(0, this.paginatorTop.pageSize);
  }

  search(searchValue: string) {
    this.messages = filterProperties(this.allMessages, searchValue, {
      caseSensitive: false,
      ignoreBlanks: true,
      entries: this.filterEntries
    });
    this.showPage(0, this.paginatorTop.pageSize);
  }

  sort(event: SortEvent) {
    this.sortDirection = event.direction;
    this.sortKey = event.key;
    sort(this.messages, event.key, event.direction);
    this.showPage(this.paginatorTop?.pageIndex, this.paginatorTop?.pageSize);
  }

  setMessageRead(messageId: number) {
    this.inboxService.setMessageRead(messageId).subscribe((readMessage: Message) => {
      if (!readMessage) return;
      this.setLocalMessageAsRead(readMessage);
      this.inboxService.updateUnreadMessageCount();
    });
  }

  setAllMessagesRead() {
    this.inboxService.setAllMessageRead().subscribe(() => {
      this.allMessages.forEach((message) => (message.isRead = true));
      this.messages = this.allMessages;
      this.showPage(0, this.paginatorTop.pageSize);
      this.inboxService.updateUnreadMessageCount();
    });
  }

  dismissMessage(event: MouseEvent, messageId: number) {
    event.stopPropagation();
    this.inboxService.dismissMessage(messageId).subscribe(() => {
      this.removeLocalMessage(messageId);
      this.showPage(this.paginatorTop.pageIndex, this.paginatorTop.pageSize);
      this.inboxService.updateUnreadMessageCount();
    });
  }

  removeLocalMessage(messageId: number) {
    let index = this.allMessages.findIndex((message) => message.id == messageId);
    if (index === -1) return;
    this.allMessages.splice(index, 1);

    index = this.messages.findIndex((message) => message.id == messageId);
    if (index === -1) return;
    this.messages.splice(index, 1);
  }

  private loadMessages() {
    this.inboxService.getMessages().subscribe(({ messages }) => {
      this.allMessages = messages;
      this.messages = messages;
      sort(this.messages, this.sortKey, this.sortDirection);
      this.showPage(0, 25);
      this.loadAvailableProductTypesFromMessages();
      this.loading = false;
    });
  }

  private loadAvailableProductTypesFromMessages() {
    const productTypes = new Set<string>();
    this.allMessages.forEach((message) => {
      productTypes.add(message.messageProcessType);
    });
    const productTypesSelectListItem: SelectListItem[] = [];
    productTypes.forEach((productType) => {
      productTypesSelectListItem.push({
        selected: false,
        text: this.translocoService.translate(`Common_${productType}`),
        value: productType
      });
    });
    this.availableProductTypes = productTypesSelectListItem;
  }

  private setLocalMessageAsRead(readMessage: Message) {
    let index = this.allMessages.findIndex((message) => message.id == readMessage.id);
    if (index === -1) return;
    this.allMessages[index].isRead = true;

    index = this.messages.findIndex((message) => message.id == readMessage.id);
    if (index === -1) return;
    this.messages[index].isRead = true;
  }

  private showPage(pageIndex: number, pageSize: number) {
    if (this.paginatorBottom && this.paginatorTop) {
      this.paginatorBottom.pageIndex = this.paginatorTop.pageIndex = pageIndex;
      this.paginatorBottom.pageSize = this.paginatorTop.pageSize = pageSize;
    }

    const startPosition = pageIndex * pageSize;
    this.messages.forEach((message, index) => {
      message.show = index >= startPosition && index < startPosition + pageSize;
    });
  }
}
