import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TireCommon, TirePriceCommon, TireSelection } from './tire-position.types';

@Injectable({ providedIn: 'root' })
export class TirePositionService {
  constructor(private httpClient: HttpClient) {}

  loadTireOptions(epn: string, companyCode: string, activityTypeKeys: string): Observable<TireSelection[]> {
    return this.httpClient.get<TireSelection[]>(
      `${environment.isportCoreApiUrl}/api/tirerequest/tire?epn=${epn}&companyCode=${companyCode}&activityTypeKeys=${activityTypeKeys}`
    );
  }

  loadSpecificTire(epn: string, epnSource: string, companyCode: string): Observable<TireCommon> {
    return this.httpClient.get<TireCommon>(
      `${environment.isportCoreApiUrl}/api/tirerequest/tirespecific?epn=${epn}&src=${epnSource}&companyCode=${companyCode}`
    );
  }

  loadTirePrice(id: number): Observable<TirePriceCommon> {
    return this.httpClient.get<TirePriceCommon>(
      `${environment.isportCoreApiUrl}/api/tirerequest/tirespecific/price?id=${id}`
    );
  }
}
