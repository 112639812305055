import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, catchError, forkJoin, map, of, switchMap, tap, timer } from 'rxjs';
import {
  BrowserCompatibilityService,
  ChatbotService,
  ConfigurationKeys,
  ConfigurationService,
  ContextDataService,
  DateTimeService,
  EventService,
  InsightsService,
  PwaService,
  SessionSyncService,
  TenantSettingsService,
  UserJourneyService,
  UserService
} from './services';

@Injectable({ providedIn: 'root' })
export class InitializerService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private configurationService: ConfigurationService,
    private contextDataService: ContextDataService,
    private oidcSecurityService: OidcSecurityService,
    private tenantSettingsService: TenantSettingsService,
    private translocoService: TranslocoService,
    private userService: UserService,
    private dateTimeService: DateTimeService,
    private insightsService: InsightsService,
    private router: Router,
    private eventService: EventService,
    private chatbotService: ChatbotService,
    private pwaService: PwaService,
    private userJourneyService: UserJourneyService,
    private browserService: BrowserCompatibilityService,
    private sessionSyncService: SessionSyncService
  ) {}

  initialize(): Observable<Translation | void> {
    this.pwaService.initialize();
    this.sessionSyncService.initialize();

    // Wait 600ms until we are sure that no other (potentially existing) iSport tab will send us its session data
    return timer(600).pipe(
      switchMap(() => {
        this.sessionSyncService.unsubscribe();
        this.userJourneyService.subscribeToRouterEvents();
        this.contextDataService.initialize();

        return this.oidcSecurityService
          .checkAuth(undefined, this.contextDataService.identityProvider)
          .pipe(switchMap(({ isAuthenticated }) => this.authenticate(isAuthenticated)));
      })
    );
  }

  private authenticate(isAuthenticated: boolean): Observable<Translation | void> {
    if (!isAuthenticated) {
      // In case the user was already authenticated and the authentication is now expired,
      // we clear the old ContextData because we possible get new ContextData.
      this.contextDataService.resetContextData();
      // Redirect the user to the Security Token Service
      this.oidcSecurityService.authorize(this.contextDataService.identityProvider);

      return of();
    }

    return this.contextDataService.initialLogin().pipe(
      switchMap(() => this.successfulLogin()),
      catchError((error: Error) => this.failedLogin(error))
    );
  }

  private successfulLogin(): Observable<Translation | void> {
    return forkJoin(this.initializeAppData()).pipe(
      tap(() => {
        this.chatbotService.injectChatbot();
        this.browserService.checkBrowserCompatibility();
      })
    );
  }

  private failedLogin(error: Error): Observable<void> {
    this.router.navigate(['forbidden'], { queryParams: { message: error.message } });
    return of();
  }

  private initializeAppData(): Observable<Translation | void>[] {
    // Preload the default language before the app starts to prevent empty/jumping content
    const defaultLang = this.translocoService.getDefaultLang();
    this.translocoService.setActiveLang(defaultLang);
    const translocoObservable$ = this.translocoService.load(defaultLang);

    this.eventService.setupSignalRChangeListener();

    // Preload configuration service data before the app starts to prevent exceptions since
    // that configuration service can be used in all components
    const configurationObservable$ = this.configurationService.load(Object.values(ConfigurationKeys));
    const tenantSettingsObservable$ = this.tenantSettingsService.load(this.contextDataService.data.tenant).pipe(
      map(() => {
        // Configure date based on culture from tenant settings
        this.dateTimeService.configureCulture(this.tenantSettingsService.culture);
      })
    );
    const tenantEmptyPositionsObservable$ = this.tenantSettingsService.loadEmptyPositions();

    // Preload user settings
    const userSettingsObservable$ = this.userService.load().pipe(
      map(() => {
        // Set authenticated user context in application insights
        this.insightsService.userId = this.userService.userId;

        const userLanguage =
          this.userService.userLanguage != '' ? this.userService.userLanguage : this.userService.defaultLanguage;
        const language = userLanguage && userLanguage != '' ? userLanguage : defaultLang;
        this.translocoService.setActiveLang(language);
        this.document.documentElement.lang = language;

        // Configure date picker locale on application load
        this.dateTimeService.configureLocale(language);
        this.userService.updateBehaviorSubjects();
      })
    );

    return [
      translocoObservable$,
      configurationObservable$,
      tenantSettingsObservable$,
      tenantEmptyPositionsObservable$,
      userSettingsObservable$
    ];
  }
}
