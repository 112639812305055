import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { RequestStateLabelModule } from '../request-state-label/request-state-label.module';
import { FileUploadComponent } from './file-upload.component';

@NgModule({
  imports: [
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatIconModule,
    MatProgressBarModule,
    RequestStateLabelModule,
    SharedModule,
    PipesModule
  ],
  exports: [FileUploadComponent],
  declarations: [FileUploadComponent],
  providers: []
})
export class FileUploadModule {}
