<div *transloco="let t">
  <h2 class="subtitle ui-test--summary-questionnaire-header-position-header">
    {{ t('Request_Summary_' + requestContent.requestProcess.productType + '_Questionnaires') }}
  </h2>

  <ng-container
    *ngFor="let questionnaireGroup of requestContent.questionnaireGroupsWithDistinctQuestions; trackBy: questionnaireGroupIdentifier, let index = index">
    <ng-container *ngIf="questionnaireGroup.hasAnyQuestions">
      <h3 class="mt-20 section-title" [class]="'ui-test--summary-questionnaire-header-questionnaire-title-' + index">
        {{ t('Request_Questionnaires_QuestionnaireTitle_' + questionnaireGroup.keyName) }}
      </h3>

      <div
        *ngFor="let questionnaire of questionnaireGroup.questionnaires; let i = index; trackBy: questionnaireIdentifier"
        [ngClass]="{'mt-40': i > 0}"
        class="flex-col justify-start content-stretch gap-20">
        <ng-container
          *ngFor="let question of questionnaire.questions; trackBy: questionIdentifier, let questionIndex = index">
          <ng-container
            *ngIf="(!question.isVisibilityTarget || i === 0) && question.hasAnswer && question.options.dataType !== 'InfoText' && !question.options.hidden">
            <div class="flex-row justify-start content-center gap-15">
              <strong
                class="flex flex-grow-50 justify-end content-center"
                [class]="'ui-test--summary-questionnaire-strong-question-' + questionIndex + '-questionnaire-' + i + '-questionGroup-' + index">
                {{ t('Request_Questionnaires_Question_' + question.questionTypeKey + '_' + question.questionKey) }}
              </strong>
              <span
                class="flex flex-1"
                [class]="'ui-test--summary-questionnaire-span-question-' + questionIndex + '-questionnaire-' + i + '-questionGroup-' + index"
                [innerHtml]="getQuestionAnswerType(question) | answerRender"
                *ngIf="question.answer.hasValue; else noAnswer"></span>
              <ng-template #noAnswer>-</ng-template>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
