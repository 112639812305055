import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '@core/directives';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { MatDatepickerModule } from '@matheo/datepicker';
import { DatePickerModule } from 'app/components/datepicker';
import { OfferPanelModule } from 'app/components/offer-panel/offer-panel.module';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { SendingRestrictedModule } from 'app/components/sending-restricted/sending-restricted.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { ContractSearchComponent } from './contract-search.component';

@NgModule({
  imports: [
    DirectivesModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    OfferPanelModule,
    PageHeaderModule,
    PipesModule,
    SendingRestrictedModule,
    SharedModule,
    ValidationSummaryModule,
    DatePickerModule
  ],
  exports: [],
  declarations: [ContractSearchComponent],
  providers: []
})
export class ContractSearchModule {}
