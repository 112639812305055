import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BrowserWarningDialogComponent } from './browser-warning-dialog.component';

@Injectable({ providedIn: 'root' })
export class BrowserWarningDialogService {
  constructor(private matDialog: MatDialog) {}

  public openDialog() {
    this.matDialog.open(BrowserWarningDialogComponent);
  }
}
