import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BrowserWarningButtonService {
  private _showBrowserWarningButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public readonly showBrowserWarningButton$: Observable<boolean> = this._showBrowserWarningButtonSubject.asObservable();

  public showButton() {
    this._showBrowserWarningButtonSubject.next(true);
  }

  public hideButton() {
    this._showBrowserWarningButtonSubject.next(false);
  }
}
