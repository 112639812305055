export abstract class PositionsComponentServiceHelper {
  static ignoreProperties = ['extensionData'];

  static ignorePropertiesForNewPositions = PositionsComponentServiceHelper.ignoreProperties.concat([
    'displayPayableByCustomer',
    'displayUndoButton',
    'subitemClasses',
    'damageTypes',
    'getActivityTypesByExternalProductNumberDisplay',
    'getActivityTypeClassesByExternalProductNumberDisplay',
    'relevantOutstandingPaymentsByProductType',
    'paymentInformationData',
    'isDisplayPayableByCustomer',
    'displayUpdateMileageButton',
    'displayExternalProductNumberSourceHtml',
    'units',
    'specificAvailableActivityTypes',
    'availableActivityTypeClasses',
    'description',
    //'discountPercentage',
    //'externalProductNumbers',
    //'netAmount',
    'activityTypeCategory',
    'damageTypeRequired',
    'contactData',
    'licensePlateNumber',
    'excludePositionFromCalculating',
    'activityTypeKeyWithClass',
    'disablePriceEdit',
    'hasExternalProductNumberSource',
    'isActivityTypeClassVisible',
    'hasNoActivityTypeClass',
    //'generalInformation',
    'userNote',
    'versionId',
    'versionState',
    'paymentOption',
    'questionnairesWithDistinctQuestions',
    'answers',
    'externalModifiedDate',
    'displayExternalModifiedDate',
    'displayUndoButton',
    'outstandingPayment',
    'displayPayableByCustomer',
    'displayUpdateMileageButton',
    'paymentInformationData',
    'currentEpnInput',
    'databaseId',
    'displayStartMobilityRequestButton',
    'displayTotalGoodwillValue',
    'isActivityTypeClassesSortingAscending',
    'isActivityTypesSortingAscending',
    'isDamageTypesSortingAscending',
    'IsRentalReasonsSortingAscending',
    'isDateOfEventVisible',
    'requestIdForCustomer',
    'actualMileage'
  ]);

  static replacer(key: string, value: any) {
    if (PositionsComponentServiceHelper.ignorePropertiesForNewPositions.indexOf(key) > -1) {
      return undefined;
    }
    return value;
  }
}
