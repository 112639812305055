<ng-container *transloco="let t">
  <page-header [title]="t('InvoiceClarification_CancelInvoiceClarificationSummary_Title')"></page-header>

  <mat-card>
    <process-bar type="Cancel"></process-bar>

    <!-- Summary step -->
    <invoice-clarification-cancel-summary
      *ngIf="selectedStep === 'Summary'"
      [invoiceClarificationCancelSummary]="invoiceClarificationCancelSummary"
      (openStatusDetailsDialogClicked)="openStatusDetailsDialog()"
      (confirmClicked)="confirm()"></invoice-clarification-cancel-summary>

    <!-- Render for Confirmation step -->
    <ng-container *ngIf="selectedStep === 'TransmissionReport'">
      <h1>
        {{ t('Invoice_Clarification_Confirm_Headline') }}
        <small>{{ invoiceClarificationCancelSummary.invoiceClarificationNumber }}</small>
      </h1>

      <blockquote>
        <p>
          {{ t('Invoice_Clarification_Cancel_Confirm_Information') | stringFormat:
          invoiceClarificationCancelSummary.invoiceClarificationNumber }}
        </p>
      </blockquote>

      <mat-divider class="my-10"></mat-divider>

      <div *ngIf="invoiceClarificationCancelSummary.requestGuid" class="flex-row justify-end content-center">
        <button mat-stroked-button class="mr-5" [disabled]="confirming" (click)="openStatusDetailsDialog()">
          {{ t('Request_Summary_StatusOverview_Index') }}
        </button>
      </div>
    </ng-container>
  </mat-card>
</ng-container>
