import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { CallMeBackRequest } from './call-me-back.types';

@Injectable({ providedIn: 'root' })
export class CallMeBackService {
  constructor(private _httpClient: HttpClient) {}

  send(request: CallMeBackRequest): Observable<HttpResponse<void>> {
    const url = `${environment.isportCoreApiUrl}/api/callme-back`;
    return this._httpClient.post<HttpResponse<void>>(url, request);
  }

  getData(): Observable<CallMeBackRequest> {
    const url = `${environment.isportCoreApiUrl}/api/callme-back`;
    return this._httpClient.get<CallMeBackRequest>(url, {});
  }
}
