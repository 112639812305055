import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@core/modules';
import { StatusDetailsDialogModule } from 'app/pages/status-overview/status-details-dialog/status-details-dialog.module';
import { ConfirmationComponent } from './confirmation.component';

@NgModule({
  imports: [MatIconModule, MatButtonModule, MatDividerModule, StatusDetailsDialogModule, SharedModule],
  exports: [ConfirmationComponent],
  declarations: [ConfirmationComponent],
  providers: []
})
export class RequestConfirmationModule {}
