<ng-container *transloco="let t">
  <div class="flex-row justify-center content-end align-end gap-15">
    <dropdown-predictive-search
      class="flex-grow-50 select-work-position w-full ui-test--request-work-position-filter-dropdown-with-search-wpn-filter"
      labelResourceKey="Request_Edit_FilterWpn"
      placeholderResourceKey="Request_Edit_PlaceholderWpnFilter"
      [dropDownOptions]="groupedSelect"
      [isMultiSelect]="!(requestContent.requestProcess.editProductType === 'Warranty' && warrantyEditMode === 'GermanWarranty')"
      (valueChange)="filterChanged($event)"></dropdown-predictive-search>

    <button
      mat-stroked-button
      class="add-position ui-test--request-work-position-filter-button-default-position-type-key"
      [disabled]="isLoadingPositions"
      [class.button-spinner]="isLoadingPositions"
      *ngIf="availableTypes.length === 1 || isFiltered"
      (click)="addPosition()">
      {{ t(defaultPositionTypeKey) }}
    </button>

    <ng-container *ngIf="availableTypes.length > 1 && !isFiltered">
      <button
        mat-stroked-button
        class="add-position ui-test--request-work-position-filter-button-add-position"
        [matMenuTriggerFor]="menu">
        {{ t('Request_Edit_AddPosition') }}
        <mat-icon class="ui-test--request-work-position-filter-mat-icon-add-position">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          *ngFor="let itemType of availableTypes, let i = index"
          [class]="'ui-test--request-work-position-filter-button-item-type' + i"
          (click)="addPosition(itemType)">
          <span [class]="'ui-test--request-work-position-filter-span-item-time' + i">
            {{ t('Request_Edit_Add' + itemType) }}
          </span>
        </button>
      </mat-menu>
    </ng-container>
  </div>
</ng-container>
