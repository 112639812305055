import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContextDataService, CorrelationIdService, UserJourneyService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { ApiErrorDetails, ErrorMail } from '../error-message.types';
import { ErrorContactFormService } from './error-contact-form.service';

@Component({
  selector: 'error-contact-form',
  templateUrl: './error-contact-form.component.html',
  styleUrls: ['./error-contact-form.component.scss']
})
export class ErrorContactFormComponent implements OnInit {
  @Input() errorDetails!: ApiErrorDetails;

  errorContactForm: UntypedFormGroup = this.formBuilder.group({
    userJourneyDescription: [''],
    inputDataDescription: [''],
    dealerId: ['', [Validators.required, Validators.minLength(5)]],
    emailAddress: ['', [Validators.required, Validators.email]],
    phoneNumber: [''],
    userId: ['']
  });
  sendMailInProgress = false;
  mailSent = false;

  constructor(
    private dialogRef: MatDialogRef<ErrorContactFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private errorContactFormService: ErrorContactFormService,
    private contextDataService: ContextDataService,
    private correlationIdService: CorrelationIdService,
    private userJourneyService: UserJourneyService,
    private snackbar: MatSnackBar,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.populateForm();
  }

  sendErrorMail() {
    this.sendMailInProgress = true;
    const errorMail = this.createErrorMail();

    this.errorContactFormService.sendEmail(errorMail).subscribe({
      next: (res: boolean) => {
        this.snackbar.open(this.translocoService.translate('ErrorContactModal_MailSuccessfullySendText'));
        this.mailSent = res;
        this.sendMailInProgress = false;
        this.dialogRef.close();
      },
      error: () => {
        this.snackbar.open(this.translocoService.translate('Common_TechnicalError'));
        this.mailSent = false;
        this.sendMailInProgress = false;
      }
    });
  }

  private createErrorMail(): ErrorMail {
    const { userJourneyDescription, inputDataDescription, dealerId, emailAddress, phoneNumber, userId } =
      this.errorContactForm.value;
    const { contextId, processId } = this.contextDataService.data;
    const { errorMessage, errorCode, timestamp } = this.errorDetails;

    return {
      errorMessage,
      errorCode,
      contextId: contextId ?? '',
      correlationId: this.correlationIdService.correlationId,
      emailAddress,
      dealerId,
      timestamp,
      userJourneyDescription,
      phoneNumber,
      processId: processId ?? '',
      userId,
      userJourney: this.userJourneyService.userJourney.toString(),
      inputDataDescription
    };
  }

  private populateForm() {
    const { user } = this.contextDataService.data;

    this.errorContactForm.patchValue({
      dealerId: user.dealer.externalDealerNumber,
      emailAddress: user.mailAddress,
      phoneNumber: user.phoneNumber,
      userId: user.id
    });
  }
}
