import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AttachmentsService {
  constructor(private httpClient: HttpClient) {}

  getAttachment(requestGuid: string, attachmentGuid: string) {
    return this.httpClient.get(
      `${environment.isportCoreApiUrl}/api/statusoverview/attachment?requestGuid=${requestGuid}&attachmentGuid=${attachmentGuid}`,
      {
        responseType: 'blob'
      }
    );
  }
}
