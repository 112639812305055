import { MotorInsuranceProcessQuestionDataType } from '@core/pipes';
import { SelectListItem } from '@core/types/mvc.types';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { SourceApplication } from '@core/types/source-application.types';
import { ValidationResult } from '@core/validation-reader';
import { ActivityTypeIdentifierCommon, DamageType, VehicleType } from 'app/components/positions';
import { PaymentInformation } from './edit/payment-choose-panel/payment-choose-panel.types';
import {
  TireCommon,
  TireReplacements,
  TireRequestExtensionData
} from './edit/positions/tire-position/tire-position.types';

export enum RequestStep {
  None,
  Create,
  Edit,
  Questionnaires,
  Summary,
  Confirm
}

export type RequestEditOptions = 'Unknown' | 'Full' | 'Limited' | 'Denied' | 'External';

export interface RequestContentValidation {
  content: RequestContent;
  error: ValidationResult;
}

export interface RequestContent {
  // TODO: need to add the other parameters when implementing the rest of the request module
  insertTireSparepartsOnSubItem: boolean;
  activityTypesFilter: string[];
  availableRentalReasons: RentalReason[];
  actualMileage: number;
  totalDeductibles: number;
  leasingReturnUserAnswer?: boolean;
  answers: Answer[];
  availableActivityTypeClasses: ActivityTypeClass[];
  availableActivityTypes: ActivityType[];
  contactData: ContactData;
  currentEpnInput?: any;
  damageTypes: DamageType[];
  databaseId: number;
  displayPayableByCustomer: boolean;
  displayUndoButton: boolean;
  externalModifiedDate: string;
  isActivityTypeClassesSortingAscending: boolean;
  isActivityTypesSortingAscending: boolean;
  isDamageTypesSortingAscending: boolean;
  isRentalReasonsSortingAscending: boolean;
  showLeasingReturnWarning: boolean;
  showContractInformationInEditQuestionnaires: boolean;
  isDateOfEventVisible: boolean;
  isDisplayStartMobilityRequestButton: boolean;
  isDisplayTotalGoodwillValue: boolean;
  isDisplayExpectedPayout: boolean;
  isDisplayTotalDeductibles: boolean;
  questionnaireGroups: QuestionnaireGroup[];
  information: Information;
  questionnaireGroupsWithDistinctQuestions: QuestionnaireGroup[];
  requestIdForCustomer: string;
  requestPositions: RequestPosition[];
  totalNetAmountCalculated: number;
  totalGoodwillCalculated: number;
  expectedPayoutCalculated: number;
  requestProcess: RequestProcess;
  hasUnresolvedOutstandingPayment: boolean;
  hasUnresolvedOutstandingPaymentForProductType: boolean;
  hasUnresolvedBlockingPaymentOption: boolean;
  userNote: string;
  userNoteMandatory: boolean;
  selectedTextModuleId?: number;
  versionId: string; // idea: create a guid type?
  versionState: string;
  expectedCustomerShare: number;
  customerShareCurrency: string;
  isDisplayPayableByCustomer: boolean;
  displayUpdateMileageButton: boolean;
  requestEditOptions: RequestEditOptions;
  paymentInformationData: PaymentInformation;
  isImportantNotificationsAllowedByContract: boolean;
  isDMS: boolean;
}

export interface ImportantInformation {
  note: string;
  id?: number;
}

export interface RequestProcess {
  generalInformation: GeneralInformation;
  productType: ProductTypeIdentifier;
  editProductType: ProductTypeIdentifier;
  contractProvider: string;
  contractNumber: string;
  licensePlateNumber: string; // is there a reason why this is used twice in bl (in requestProcess and in GeneralInformation)?
  displayCurrencyUnit: string;
  contactData: ContactData;
  sourceApplication: SourceApplication;
  primaryNetWagePerHourSource: PrimaryNetWagePerHourSource;
  requestIndex: number;
}

export interface GeneralInformation {
  actualMileage: number;
  companyCode: string;
  contractNumber: string;
  dateOfEvent: string;
  dateOfFirstRegistration: string;
  insuranceReferenceNumber?: any;
  isInsuranceReferenceNumberActive: boolean;
  isPricelessRequestAllowed: boolean;
  licensePlateNumber: string;
  modelCode: string;
  modelName: string;
  vehicleAcceptance: string;
  vehicleIdentificationNumber: string;
  vehicleType: VehicleType;
  workshopOrderNumber: string;
  deductibleAmount: number;
  deductibleQuantity: number;
}

export interface StartRequestParameters {
  productType: ProductTypeIdentifier;
  contractDatabaseId: number;
  paymentOption: string | null;
  outstandingPayment: string | null;
  subtype: string | null;
  referencedRequestGuid: string | null;
  vin: string;
}

export interface RequestPosition {
  templateName: string;
  vehicleType: VehicleType;
  isOld: boolean;
  activityTypeClassKey?: number;
  activityTypeDescription: string;
  activityTypeDescriptionOriginal?: string;
  activityTypeKey?: number;
  canHaveSubitems: boolean;
  createdDate: Date; // luxon?
  damageType?: DamageType;
  damageTypeId: number;
  rentalReason?: RentalReason;
  rentalReasonId?: number;
  discount: number;
  displayCurrencyUnit: string;
  editDateTime: Date; // luxon
  externalParentPositionId?: string;
  externalPositionDescription?: string;
  externalPositionId?: string;
  externalProductNumber: string;
  externalProductNumberSource: string;
  goodwill: number;
  goodwillAmount: number;
  materialGoodwill?: number;
  materialGoodwillAmount?: number;
  hasExternalProductNumberExactlyMatched: boolean;
  validationErrors?: Set<string>;
  canEditActivityForWso: boolean;
  indentLevel: number;
  isDamageTypeRequired: boolean;
  isExpanded: boolean;
  isInnerPosition: boolean;
  isPositionMarkedAsDeleted: boolean;
  isPositionVisible: boolean;
  isPricelessRequestAllowed: boolean;
  sourceApplication: SourceApplication;
  itemType: ActivityTypeIdentifierCommon;
  itemTypeForDb: ActivityTypeIdentifierCommon;
  laborPositionIndex: number;
  netAmount: number;
  netFixedPrice: number;
  parentPositionId?: string; // guid
  positionId: string; // guid
  quantity: number;
  quantityUnit?: number;
  quantityUnitKey: string;
  quantityUnitText: string;
  sagaId?: string;
  subitems: RequestPosition[]; // Might be copies, only use ids to find the actual items!
  subitemClasses: any[];
  productTypeForDamageType: string;
  subitemType: ActivityTypeIdentifierCommon;
  unitPrice: number;
  // Rest needed for TirePositions
  tireDamageTypes: SelectListItem[];
  tireTypes: SelectListItem[];
  tireBrands: SelectListItem[];
  tireSize: SelectListItem[];
  speedIndex: SelectListItem[];
  loadIndex: SelectListItem[];
  tires: SelectListItem[];
  tireReplacements: TireReplacements;
  tire?: TireCommon;
  showContractInformationInTireRequest: boolean;
  extensionData: TireRequestExtensionData;
}

export interface ActivityTypeClass {
  id: number;
  name: string;
  description: string;
}

export interface Unit {
  $type: string;
  id: number;
  key: string;
  descriptionLong: string;
  descriptionShort: string;
}

export interface ActivityType {
  activityTypeCategory: string;
  activityTypeClasses: ActivityTypeClass[];
  damageTypeRequired: boolean;
  description: string;
  discountPercentage?: any;
  externalProductNumbers: ExternalProductNumber[];
  id: number;
  itemType: ActivityTypeIdentifierCommon;
  name: string;
  netAmount?: any;
  units: Unit[];
  isPricelessRequestAllowed: boolean;
  isSparePartDenied: boolean;
  damageTypes: DamageType[];
  activityTypeKeys: string[];
}

export interface ExternalProductNumber {
  $type: string;
  number: string;
  price?: number;
  source?: string;
}

export interface RentalReason {
  $type: string;
  activityTypes: string[];
  description: string;
  id: number;
  name: string;
}

export interface ContactData {
  firstName: string;
  surname: string;
  phoneNumber: string;
  email: string;
  secondaryEmail?: string;
}

export interface QuestionnaireOptions {
  displayOrder: number;
  isMultiple: boolean;
}

export interface QuestionOptions {
  dataType: MotorInsuranceProcessQuestionDataType;
  displayOrder: number;
  isAlternateLayout: boolean;
  isFutureDateSelectionEnabled: boolean;
  isMandatory: boolean;
  isDisplayOnceInGroup: boolean;
  isMultiline: boolean;
  maxLength: number;
  selectableOptions: string[];
  visibility: AnswerVisibility[][];
  preAllocationSourcePath?: string;
  labelHidden: boolean;
  answerHidden: boolean;
  hidden: boolean;
  eventRule: boolean;
  isDisabled: boolean;
  isPastDateSelectionDisabled: boolean;
  isWeekendDaysSelectionDisabled: boolean;
  additionalInformation: AdditionalInformation;
}

export interface Answer {
  groupId: number;
  hasValue: boolean;
  questionId: number;
  questionKey: string;
  questionTypeKey: string;
  value?: string;
}

export interface Question {
  answer: Answer;
  databaseId: number;
  hasAnswer: boolean;
  isVisibilityTarget: boolean;
  options: QuestionOptions;
  questionKey?: string;
  questionTypeKey?: string;
}

export interface Questionnaire {
  groupId: number;
  isPrimary: boolean;
  keyName: string;
  options: QuestionnaireOptions;
  questions: Question[];
}

export interface QuestionnaireGroup {
  displayOrder: number;
  firstGroupId: number;
  hasAnyQuestions: boolean;
  isAllowMultiple: boolean;
  keyName: string;
  lastGroupId: number;
  questionnaires: Questionnaire[];
}

export interface Information {
  headline: string;
  informationText: string;
  canContinue: boolean;
  showInformation: boolean;
  showQuestionnaires: boolean;
  versionId: string;
}

export interface AnswerVisibility {
  compareType: string;
  targetExpectedValue: string;
  targetQuestionKey: string;
}

export interface AdditionalInformation {
  type: string;
  subType: string;
  value: string;
}

export interface ClaimPositionFilter extends ActivityTypeClass {
  activityTypes: ActivityType[];
}

export type UnsavedChangesEventName = 'EventHubInitialized' | 'Dirty' | 'Saved' | 'Undo';

export enum PrimaryNetWagePerHourSource {
  Unknown = 0,
  DealerContract = 1,
  PriceList = 2
}
