import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { InsightsService } from '@core/services';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(@Inject(Injector) private injector: Injector) {}

  private get insightService(): InsightsService {
    return this.injector.get(InsightsService);
  }

  handleError(error: Error): void {
    // Skip angular NG errors since they are mostly shown in developer mode only and didnt proved to be breaking
    if (!error?.stack?.startsWith('Error: NG') && !error?.stack?.startsWith('Error: User is unauthorized')) {
      this.insightService.logException(error);
    }
  }
}
