import { Injectable } from '@angular/core';
import { EventPayload, EventService } from '@core/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { InformationBroadcast, InformationBubbleEvent } from './information-bubble.types';

@Injectable({
  providedIn: 'root'
})
export class InformationBubbleService {
  private _showInformationBubbleSubject: BehaviorSubject<InformationBubbleEvent> = new BehaviorSubject({
    show: false
  } as InformationBubbleEvent);

  constructor(private eventService: EventService) {
    this.subscribeToSignalrNotifications();
  }

  public readonly showInformationBubble$: Observable<InformationBubbleEvent> =
    this._showInformationBubbleSubject.asObservable();

  public hideBubble() {
    this._showInformationBubbleSubject.next({ show: false });
  }

  private showBubble(content: string) {
    this._showInformationBubbleSubject.next({ show: true, content });
  }

  private subscribeToSignalrNotifications() {
    this.eventService.setHubConnectionChangeListener('InformationUpdate', (payload: EventPayload) => {
      const information = JSON.parse(payload.data) as InformationBroadcast;

      if (!information.IsImportant) {
        return;
      }

      this.showBubble(information.ShortText);
    });
  }
}
