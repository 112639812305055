import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextDataService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { RequestService } from '../../request.service';
import { RequestEditOptions } from '../../request.types';
import { AssignedQuestionnaireProductType, ProcessBarItem, ProcessBarType } from './process-bar.types';

@Component({
  selector: 'process-bar',
  templateUrl: './process-bar.component.html',
  styleUrls: ['./process-bar.component.scss']
})
export class ProcessBarComponent implements OnInit {
  @Input() productType!: string;
  @Input() step!: string;
  @Input() type!: ProcessBarType;
  @Input() editOptions!: RequestEditOptions;
  @Output() stepChange: EventEmitter<string> = new EventEmitter<string>();

  steps: ProcessBarItem[] = [];

  constructor(
    private translocoService: TranslocoService,
    private requestService: RequestService,
    private contextDataService: ContextDataService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.setupSteps();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get activeStepIndex(): number {
    return this.steps?.find((step) => step.isActive)?.index ?? 0;
  }

  get lastStepIndex(): number {
    return this.steps.length - 1;
  }

  get activeKey(): string {
    return this.steps?.find((step) => step.isActive === true)?.key ?? '';
  }

  set activeKey(value: string) {
    this.steps?.forEach((step) => {
      step.isActive = step.key == value;
    });
    this.stepChange.emit(value);
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private setupSteps() {
    switch (this.type) {
      case 'General':
        this.requestService.getProductTypeQuestionnaire(this.contextDataService.data.tenant).subscribe({
          next: (questionnaire: AssignedQuestionnaireProductType[]) => {
            this.setupGeneralSteps(this.editOptions === 'Limited' ? 'Summary' : this.step, questionnaire);
          }
        });
        break;
      case 'Invoice':
        this.setupOnlineInvoiceSteps('Summary');
        break;
      case 'Cancel':
        this.setupCancelRequestSteps('Summary');
        break;
      case 'CallMeBack':
        this.setupCallMeBackSteps('CollectData');
        break;
    }
  }

  private setupGeneralSteps(step: string, questionnaire: AssignedQuestionnaireProductType[]) {
    this.steps = [];

    const isQuestionnairesVisible =
      this.productType === 'MotorInsurance' || questionnaire.some((q) => q.productType === this.productType);
    let stepNum = 0;

    this.steps.push({
      index: stepNum,
      key: this.productType,
      displayName: `${++stepNum}. ${this.translocoService.translate(
        'Request_ProcessBar_' + this.productType + '_Positions'
      )}`,
      isActive: false
    });

    if (isQuestionnairesVisible) {
      this.steps.push({
        index: stepNum,
        key: 'Questionnaires',
        displayName: `${++stepNum}. ${this.translocoService.translate(
          'Request_ProcessBar_' + this.productType + '_Questionnaires'
        )}`,
        isActive: false
      });
    }

    this.steps.push({
      index: stepNum,
      key: 'Summary',
      displayName: `${++stepNum}. ${this.translocoService.translate('Request_ProcessBar_Summary')}`,
      isActive: false
    });

    this.steps.push({
      index: stepNum,
      key: 'TransmissionReport',
      displayName: `${++stepNum}. ${this.translocoService.translate('Request_ProcessBar_TransmissionReport')}`,
      isActive: false
    });

    this.activeKey = step;
  }

  private setupOnlineInvoiceSteps(step: string) {
    this.steps = [];

    let stepNum = 0;

    this.steps.push({
      index: stepNum,
      key: 'Summary',
      displayName: `${++stepNum}. ${this.translocoService.translate('Request_OnlineInvoice_Summary')}`,
      isActive: false
    });

    this.steps.push({
      index: stepNum,
      key: 'TransmissionReport',
      displayName: `${++stepNum}. ${this.translocoService.translate('Request_ProcessBar_TransmissionReport')}`,
      isActive: false
    });

    this.activeKey = step;
  }

  private setupCancelRequestSteps(step: string) {
    this.steps = [];

    let stepNum = 0;

    this.steps.push({
      index: stepNum,
      key: 'Summary',
      displayName: `${++stepNum}. ${this.translocoService.translate('Request_CancelRequest_Summary')}`,
      isActive: false
    });

    this.steps.push({
      index: stepNum,
      key: 'TransmissionReport',
      displayName: `${++stepNum}. ${this.translocoService.translate('Request_ProcessBar_TransmissionReport')}`,
      isActive: false
    });

    this.activeKey = step;
  }

  private setupCallMeBackSteps(step: string) {
    this.steps = [];

    let stepNum = 0;

    this.steps.push({
      index: stepNum,
      key: 'CollectData',
      displayName: `${++stepNum}. ${this.translocoService.translate('CallMeBack_ProcessBar_CollectData')}`,
      isActive: false
    });

    this.steps.push({
      index: stepNum,
      key: 'TransmissionReport',
      displayName: `${++stepNum}. ${this.translocoService.translate('CallMeBack_ProcessBar_TransmissionReport')}`,
      isActive: false
    });

    this.activeKey = step;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  previousStep() {
    const previousStepIndex = this.activeStepIndex - 1;
    if (previousStepIndex >= 0) {
      this.activeKey = this.steps[previousStepIndex].key;
    }
  }

  nextStep() {
    const nextStepIndex = this.activeStepIndex + 1;
    if (nextStepIndex <= this.lastStepIndex) {
      this.activeKey = this.steps[nextStepIndex].key;
    }
  }

  processBarIdentifier(_index: number, item: ProcessBarItem) {
    return item.key;
  }

  getTabIcon(step: string) {
    switch (step.toLowerCase()) {
      case 'collectdata':
      case 'mobility':
      case 'tires':
        return 'car_crash';
      case 'warranty':
        return 'beach_access';
      case 'summary':
        return 'checklist_rtl';
      case 'transmissionreport':
        return 'summarize';
      default:
        return 'settings';
    }
  }
}
