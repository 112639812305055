/** Disclaimer: We have to import the Request component first. Otherwise we can get "access before initialization" issues */
import { RequestComponent } from 'app/pages/request/request.component';

import { UnsavedChangesGuard } from '@core/guards/unsaved-changes.guard';
import { RequestCancelComponent } from 'app/pages/request/cancel/cancel.component';
import { RequestOnlineInvoiceComponent } from 'app/pages/request/online-invoice/online-invoice.component';

export const REQUEST_ROUTES = [
  {
    path: 'create',
    component: RequestComponent,
    canDeactivate: [UnsavedChangesGuard],
    title: 'Common_RequestStateTitleLabel'
  },
  {
    path: 'edit',
    component: RequestComponent,
    canDeactivate: [UnsavedChangesGuard],
    title: 'Common_RequestStateTitleLabel'
  },
  {
    path: 'cancel',
    component: RequestCancelComponent,
    title: 'Request_CancelRequestSummary_Title'
  },
  {
    path: 'online-invoice',
    component: RequestOnlineInvoiceComponent,
    title: 'Request_OnlineInvoice_Summary_Title'
  }
];
