<ng-container *ngIf="loading || categorizedHourlyRates">
  <mat-card class="w-full" *transloco="let t">
    <mat-card-title
      id="CategorizedHourlyRatesTitle"
      class="ui-test--categorized-hourly-rates-mat-card-title-categorized-hourly-rates-title">
      {{ t('Account_UserProfile_Categorized_Hourly_Rates_Title') }}
    </mat-card-title>
    <mat-card-content *ngIf="categorizedHourlyRates; else componentLoading">
      <table
        aria-describedby="CategorizedHourlyRatesTitle"
        mat-table
        [dataSource]="categorizedHourlyRates.dealerPrices"
        class="w-full">
        <ng-container matColumnDef="dealer-number">
          <th
            mat-header-cell
            class="dealer-number ui-test--categorized-hourly-rates-tableHeader-dealer-number"
            *matHeaderCellDef>
            {{ t('Account_UserProfile_Hourly_Rates_DealerNumber') }}
          </th>
          <td
            [class]="'ui-test--categorized-hourly-rates-table-body-' + t('Common_DealerBrand_' + dealerPrice.dealerBrand)"
            mat-cell
            *matCellDef="let dealerPrice">
            {{ dealerPrice.dealerNumber }}{{ dealerPrice.dealerBrand }} / {{ t('Common_DealerBrand_' +
            dealerPrice.dealerBrand) }}
          </td>
        </ng-container>

        <ng-container *ngFor="let column of dynamicColumns" matColumnDef="{{ column }}">
          <th
            [class]="'ui-test--categorized-hourly-rates-table-header-' + t('Account_UserProfile_Categorized_Hourly_Rates_' + column)"
            mat-header-cell
            *matHeaderCellDef>
            <div class="flex-row justify-start content-center">
              <span
                class="mr-5"
                [class]="[column | className, 'ui-test--categorized-hourly-rates-span-' + t('Account_UserProfile_Categorized_Hourly_Rates_' + column)]">
                {{ t('Account_UserProfile_Categorized_Hourly_Rates_' + column) }}
              </span>
              <mat-icon
                *ngFor="let productType of getProductTypesByColumn(column)"
                [class]="'ui-test--categorized-hourly-rates-mat-icon-' + productType">
                {{ productType | productTypeIcon }}
              </mat-icon>
            </div>
          </th>
          <td mat-cell *matCellDef="let dealerPrice">
            <span
              *ngIf="getPrice(dealerPrice, column)?.price?.value !== 0; else noPrice"
              [class]="'ui-test--categorized-hourly-rates-mat-icon-' + t('Common_DealerBrand_' + dealerPrice.dealerBrand) + t('Account_UserProfile_Categorized_Hourly_Rates_' + column)">
              {{ getPrice(dealerPrice, column)?.price?.value | currencyOrDash }} {{ t('Common_CurrencySymbol_' +
              categorizedHourlyRates.currency) }}
            </span>
            <ng-template #noPrice>
              <span>–</span>
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
  <ng-template #componentLoading>
    <div *ngIf="loading" class="spinner spinner-smaller"></div>
  </ng-template>
</ng-container>
