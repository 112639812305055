import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthPartnerService {
  public parseGrpAdditionalContext(grpAdditionalContextString: string): Map<string, string> {
    const decodedAttributes = new Map<string, string>();
    decodeURIComponent(grpAdditionalContextString)
      .split('&')
      .map((x) => x.split('='))
      .slice(1)
      .forEach(([key, val]) => decodedAttributes.set(key, val));
    return decodedAttributes;
  }
}
