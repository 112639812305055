import { Injectable } from '@angular/core';
import { RoleCommon } from './role.enums';

@Injectable({ providedIn: 'root' })
export class RoleServiceService {
  static getUserRoleForFsPartner(roleId: string, appId: string): RoleCommon {
    switch (appId) {
      case '56':
        if (roleId == 'VK') {
          return RoleCommon.DealerMember;
        } else if (roleId == 'VW') {
          return RoleCommon.InternalMember;
        }
        return RoleCommon.None;
      case '50':
        return RoleCommon.WarrantyMember;
      case '53':
        return RoleCommon.TireMember;
      case '52':
        return RoleCommon.MobilityMember;
      case '90':
        return RoleCommon.ManagingMember;
    }

    return RoleCommon.None;
  }
}
