import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { ImportantInformationComponent } from './important-information.component';

@NgModule({
  imports: [
    MatInputModule,
    MatFormFieldModule,
    PipesModule,
    SharedModule,
    MatExpansionModule,
    MatIconModule,
    MatSelectModule
  ],
  exports: [ImportantInformationComponent],
  declarations: [ImportantInformationComponent],
  providers: []
})
export class ImportantInformationModule {}
