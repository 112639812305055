import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from '../../request.service';
import { Information } from '../../request.types';

@Component({
  selector: 'questionnaire-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent {
  @Input() information!: Information;

  abortLoading = false;

  constructor(private requestService: RequestService, private router: Router) {}

  chooseContinue() {
    this.information.showQuestionnaires = true;
    this.information.showInformation = false;
  }

  chooseAbort() {
    if (!this.information.versionId) {
      this.router.navigate(['/dashboard']);
      return;
    }

    this.abortLoading = true;
    this.requestService.deleteRequest(this.information.versionId).subscribe({
      next: () => {
        this.router.navigate(['/dashboard']);
        this.abortLoading = false;
      }
    });
  }
}
