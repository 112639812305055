import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'entries-counter',
  templateUrl: './entries-counter.component.html',
  styleUrls: ['./entries-counter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntriesCounterComponent {
  @Input()
  get totalEntriesCount() {
    return this.totalEntriesCountValue;
  }
  set totalEntriesCount(totalEntriesCount: number) {
    this.totalEntriesCountValue = totalEntriesCount;
    this.updateDisplayEntriesString();
  }
  @Input()
  get shownEntriesCount() {
    return this.shownEntriesCountValue;
  }
  set shownEntriesCount(shownEntriesCount: number) {
    this.shownEntriesCountValue = shownEntriesCount;
    this.updateDisplayEntriesString();
  }

  private totalEntriesCountValue: number = 0;
  private shownEntriesCountValue: number = 0;
  private translationString: string = 'Showing _FIRST_ to _LAST_ of _TOTAL_ entries';
  public displayEntriesString: string = '';

  constructor(translocoService: TranslocoService) {
    this.translationString = translocoService.translate('Common_TablePage_Info');
  }

  updateDisplayEntriesString(): void {
    this.displayEntriesString = this.translationString
      .replace('_FIRST_', '1')
      .replace('_LAST_', this.shownEntriesCount.toString())
      .replace('_TOTAL_', this.totalEntriesCount.toString());
  }
}
