import { Component, Input } from '@angular/core';
import { UnsavedChangesStateStore } from '../../unsaved-changes-state-store.service';
import { RequestContent } from './../../request.types';

@Component({
  selector: 'leasing-return-confirmation',
  templateUrl: './leasing-return-confirmation.component.html'
})
export class LeasingReturnConfirmationComponent {
  @Input() requestContent!: RequestContent;

  constructor(private unsavedChangesStateService: UnsavedChangesStateStore) {}

  activateUnsavedChangesPopup() {
    this.unsavedChangesStateService.addEventMessage('Dirty');
  }
}
