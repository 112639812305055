import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { LocaleFormatService } from '@core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'input-group',
  templateUrl: './input-group.component.html'
})
export class InputGroupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() sharedFormGroup!: UntypedFormGroup;
  @Input() viewModelName: string = '';
  @Input() name: string = '';
  @Input() inputSuffix: string = '';
  @Input() isDisabled = false;
  @Input() value?: string | number | null;
  @Input() validators: ValidatorFn[] | ValidatorFn = [];
  @Input() labelKey?: string;
  /**
   * -1 means no max length. 0 would prevent inputs.
   */
  @Input() maxLength?: number;
  /**
   * optional - allows to set the input element type (e.g. number / text / tel ...)
   */
  @Input() type: string = '';
  @Output() inputGroupValue = new EventEmitter<string | undefined>();
  @Output() triggerStatusCheck = new EventEmitter();

  formControl!: FormControl<string | undefined>;
  /**
   * Will be set from outside this component
   */
  backendValidationError: boolean = false;
  inputType: string = '';
  private controlSubscription$!: Subscription;

  constructor(protected localeFormatService: LocaleFormatService) {}

  ngOnInit(): void {
    this.initializeFormControl();
    this.initializeInputSettings();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.formControl) {
      return;
    }

    if (changes['value'] && this.formControl.value !== changes.value.currentValue) {
      // Track value changes in parent
      this.formatAndPatchNumberValue();
      this.patchNonNumberValue();
      this.triggerStatusCheck.emit();
    }

    // Track isDisabled changes in parent
    if (changes['isDisabled'] && this.formControl.disabled !== changes.isDisabled.currentValue) {
      if (changes.isDisabled.currentValue) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    }
  }

  ngOnDestroy(): void {
    this.controlSubscription$?.unsubscribe();
    if (this.sharedFormGroup) {
      this.sharedFormGroup.removeControl(this.name);
    }
  }

  emitStatusCheck() {
    this.triggerStatusCheck.emit();
  }

  private initializeFormControl() {
    if (!this.sharedFormGroup) {
      return;
    }

    this.formControl = new FormControl<string | undefined>(
      { value: undefined, disabled: this.isDisabled },
      { updateOn: 'blur', validators: this.validators, nonNullable: true }
    );
    this.sharedFormGroup.addControl(this.name, this.formControl);
    this.formatAndPatchNumberValue();
    this.patchNonNumberValue();

    this.controlSubscription$ = this.formControl.valueChanges.subscribe((value) => {
      this.inputGroupValue.emit(value);
      /**
       * Explanation:
       * We have to patch numeric values directly. Consider the following case:
       * Emit value 200 -> Parent sets `this.value` to 100 ->
       * `ngOnChanges` triggered -> We set the formControl's value to 100 ->
       * Emit value 200 again -> Parent sets `this.value` to 100 again -> Angular does not detect a change,
       * because the primitive number type has not change its value -> The formControl still shows 200.
       *
       * String values does not have to be patched, because changing a string reference always triggers `ngOnChanges`.
       */
      this.formatAndPatchNumberValue();
    });
  }

  private formatAndPatchNumberValue() {
    if (this.type == 'currency') {
      this.formControl.patchValue(
        this.localeFormatService.formatNumericString(this.value?.toString(), { fractionDigits: 2 }),
        {
          emitEvent: false
        }
      );
      return;
    }

    if (this.type == 'number' || this.type == 'percent') {
      this.formControl.patchValue(this.localeFormatService.formatNumericString(this.value?.toString()), {
        emitEvent: false
      });
    }
  }

  private patchNonNumberValue() {
    if (this.type == 'currency' || this.type == 'number' || this.type == 'percent') {
      return;
    }

    this.formControl.patchValue(this.value?.toString(), { emitEvent: false });
  }

  private initializeInputSettings() {
    // We need type `text` for numbers, because we accept a broad range of numeric inputs that are not allowed
    // by HTML5 `number` inputs
    switch (this.type) {
      case 'percent':
        this.inputSuffix = '%';
        this.maxLength ??= 5;
        this.inputType = 'text';
        break;
      case 'number':
        this.maxLength ??= 7;
        this.inputType = 'text';
        break;
      case 'currency':
        this.maxLength ??= 10;
        this.inputType = 'text';
        break;
      default:
        this.maxLength ??= -1;
        this.inputType = this.type;
    }
  }
}
