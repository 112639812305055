import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getBrowserLang, Translation, TranslocoLoader } from '@ngneat/transloco';
import { environment } from 'environments/environment';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private httpClient: HttpClient) {}

  getTranslation(language: string): Observable<Translation> {
    const url = `${environment.isportCoreApiUrl}/api/resources?request.languages=${language}&request.applicationName=ISPORT`;
    return this.httpClient.get<Translation>(url).pipe(
      map((result) => result.resources),
      catchError(() => {
        return this.loadLocalLangs(language);
      })
    );
  }

  /**
   * Load locally available translations as a fallback.
   */
  private loadLocalLangs(language: string): Observable<Translation> {
    const languages = new Set();
    languages.add(language);
    if (language.includes('-')) {
      // e.g., en-GB would add `en` and `en-GB` to the array
      languages.add(language.split('-')[0]);
    }

    const browserLanguage = getBrowserLang();
    if (browserLanguage) {
      languages.add(browserLanguage);
    }

    return this.loadLanguageFile([...languages].map((lang) => `/assets/i18n/${lang}.json`));
  }

  loadLanguageFile(files: string[]): Observable<Translation> {
    // Get the first file from the array
    const currentFile = files.shift() ?? '';

    return this.httpClient.get<Translation>(currentFile).pipe(
      catchError((error) => {
        if (files.length > 0) {
          // If there are more files in the array, recursively call the method again
          return this.loadLanguageFile(files);
        } else {
          return of(error);
        }
      })
    );
  }
}
