export class UserProfileConfigurator {
  constructor(
    userProfileAvailableFields: string[],
    categorizedHourlyRatesDisplayColumns: string,
    displayBaseProductHourlyRates: boolean
  ) {
    this.showGuidelinesPanel = [
      'WorkshopGuidelinesUrl',
      'ClearingGuidelinesWarrantyUrl',
      'ClearingGuidelinesWkmUrl',
      'IsWkmDealer'
    ].some((guidline) => {
      return userProfileAvailableFields.some((fields) => fields.includes(guidline));
    });
    this.showTimeZoneId = userProfileAvailableFields.includes('TimeZoneId');
    this.showShouldMailSendForStatusUpdate = userProfileAvailableFields.includes('ShouldMailSendForStatusUpdate');
    this.showServiceAdvisorNumber = userProfileAvailableFields.includes('ServiceAdvisorNumber');
    this.showSalesNumber = userProfileAvailableFields.includes('SalesNumber');
    this.showDmsUserName = userProfileAvailableFields.includes('DmsUserName');
    this.showPhoneNumber = userProfileAvailableFields.includes('PhoneNumber');

    this.userProfileCategorizedHourlyRatesDisplayColumns = categorizedHourlyRatesDisplayColumns;
    this.displayUserProfileBaseProductHourlyRates = displayBaseProductHourlyRates;
  }

  readonly showGuidelinesPanel: boolean = false;
  readonly showTimeZoneId: boolean = false;
  readonly showShouldMailSendForStatusUpdate: boolean = false;
  readonly showServiceAdvisorNumber: boolean = false;
  readonly showSalesNumber: boolean = false;
  readonly showDmsUserName: boolean = false;
  readonly showPhoneNumber: boolean = false;

  readonly userProfileCategorizedHourlyRatesDisplayColumns: string | null = null;
  readonly displayUserProfileBaseProductHourlyRates: boolean = false;
}
