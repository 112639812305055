import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from '@core/modules';

import { PageHeaderComponent } from './page-header.component';

@NgModule({
  imports: [MatDividerModule, SharedModule],
  exports: [PageHeaderComponent],
  declarations: [PageHeaderComponent],
  providers: []
})
export class PageHeaderModule {}
