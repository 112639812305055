import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a string to a css class name.
 * Replaces blanks by hyphens and converts to lower case.
 */
@Pipe({
  name: 'className'
})
export class ClassNamePipe implements PipeTransform {
  transform(value?: string): string {
    value = value?.replace(/([a-z0-9])([A-Z])/g, '$1-$2');
    return value?.replace(/\s/g, '-').toLowerCase() ?? '';
  }
}
