import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService, RequestStateStore, UserService } from '@core/services';
import { ExternalApplicationAssignResult } from '@core/types/application-assign.types';
import { TranslocoService } from '@ngneat/transloco';
import { OverviewComponent } from 'app/components/overview/overview.component';
import { AftersalesOverviewService } from './aftersales-overview.service';
import {
  AftersalesOverviewData,
  AftersalesOverviewFilterEntries,
  AftersalesOverviewSortKeys
} from './aftersales-overview.types';

@Component({
  selector: 'aftersales-overview',
  templateUrl: './aftersales-overview.component.html',
  styleUrls: ['./aftersales-overview.component.scss']
})
export class AftersalesOverviewComponent extends OverviewComponent {
  public override overviewData: AftersalesOverviewData[] = [];
  public override initialResult: AftersalesOverviewData[] = [];
  public sortKeys = AftersalesOverviewSortKeys;
  protected override sortKey = AftersalesOverviewSortKeys[0][0];
  protected override viewModeKey: string = 'aftersalesOverviewViewMode';
  protected override filterEntries: string[] = AftersalesOverviewFilterEntries;
  redirecting = false;

  constructor(
    private aftersalesOverviewService: AftersalesOverviewService,
    private translocoService: TranslocoService,
    private snackbar: MatSnackBar,
    private alertService: AlertService,
    userService: UserService,
    requestStateStore: RequestStateStore
  ) {
    super(aftersalesOverviewService, userService, requestStateStore);
  }

  postContinueUrl(data: AftersalesOverviewData): void {
    data.loading = true;
    this.aftersalesOverviewService.postContinueUrl(data).subscribe((result: ExternalApplicationAssignResult) => {
      if (result) {
        if (!result.isValid) {
          this.alertService.open({ message: result.errorMessage });
          data.loading = false;
          return;
        }
        window.open(result.targetUri, result.targetWindow);
      } else {
        this.snackbar.open(this.translocoService.translate('AftersalesOverview_Continue_MissingParameters'));
      }
      data.loading = false;
    });
  }
}
