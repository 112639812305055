import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '@core/modules';
import { ErrorHandlingModule } from '@core/services';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { UnauthorizedComponent } from './unauthorized.component';

@NgModule({
  imports: [ErrorHandlingModule, PageHeaderModule, MatButtonModule, SharedModule],
  exports: [],
  declarations: [UnauthorizedComponent],
  providers: []
})
export class UnauthorizedModule {}
