import { Injectable } from '@angular/core';
import { from, merge, mergeMap, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PwaCacheService {
  clearAll(): Observable<void> {
    return this.clear();
  }

  clearApiDataCache(
    endpointNames: string[] | undefined = undefined,
    dataCacheName: string = 'data:api-performance:cache'
  ): Observable<void> {
    return this.clear([dataCacheName], endpointNames);
  }

  clearAssetsCache(): Observable<void> {
    return this.clear(['assets:app:cache', 'assets:app:cache']);
  }

  clear(
    cacheEntryNames: string[] | undefined = undefined,
    cacheEndpointNames: string[] | undefined = undefined
  ): Observable<void> {
    const storage = this.getCacheStorage();
    if (!storage) {
      return of();
    }
    const promise = storage.keys().then((names) => {
      let namesToClear = names;
      if (cacheEntryNames) {
        namesToClear = names.filter((n) => cacheEntryNames.filter((ce) => n.includes(ce)).length > 0);
      }
      return namesToClear;
    });

    return from(promise).pipe(
      mergeMap((namesToClear) => this.deleteNamedEntries(storage, namesToClear, cacheEndpointNames))
    );
  }

  private getCacheStorage(): CacheStorage {
    return window.caches;
  }

  private deleteNamedEntries(
    storage: CacheStorage,
    entriesToDelete: string[],
    cacheEndpointNames: string[] | undefined = undefined
  ): Observable<void> {
    const promises = [];
    for (const name of entriesToDelete) {
      if (!cacheEndpointNames) {
        // Delete whole cache entry
        storage.delete(name);
        continue;
      }
      // Clear only specific entries from cache
      promises.push(
        storage.open(name).then((cacheEntry) => {
          return cacheEntry.keys().then((entries) => {
            const matchingEntries = entries.filter(
              (e) => cacheEndpointNames.filter((cen) => e.url.includes(cen)).length > 0
            );
            for (const entryToDelete of matchingEntries) {
              cacheEntry.delete(entryToDelete);
            }
          });
        })
      );
    }

    if (promises.length > 0) {
      return merge(...promises.map((p) => from(p)));
    }
    return of();
  }
}
