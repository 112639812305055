import { Component, OnInit } from '@angular/core';
import { InformationEntry } from './information-entry/information-entry.types';
import { InformationService } from './information.service';

@Component({
  templateUrl: './information.component.html'
})
export class InformationComponent implements OnInit {
  loading: boolean = true;
  entries: InformationEntry[] = [];
  constructor(private informationService: InformationService) {}

  ngOnInit(): void {
    this.informationService.updateUnreadCount();
    this.getInformationEntries();
  }

  private getInformationEntries(): void {
    this.informationService.getInformation().subscribe({
      next: (entries: InformationEntry[]) => {
        this.entries = entries;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }
}
