import { Component, Input } from '@angular/core';
import { Customer } from '../../dashboard.types';

@Component({
  selector: 'customer-information',
  templateUrl: './customer-information.component.html'
})
export class CustomerInformationComponent {
  @Input() customer?: Customer;
}
