import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '@core/directives';
import { SharedModule } from '@core/modules';
import { ContactDataModule } from 'app/components/contact-data/contact-data.module';
import { FileUploadModule } from 'app/components/file-upload/file-upload.module';
import { ImportantInformationModule } from 'app/components/important-information/important-information.module';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { SendingRestrictedModule } from 'app/components/sending-restricted/sending-restricted.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { WorkInstructionsModule } from 'app/components/work-instructions/work-instructions.module';
import { InvoiceClarificationEditComponent } from './edit.component';

@NgModule({
  imports: [
    DirectivesModule,
    MatIconModule,
    MatCardModule,
    MatCheckboxModule,
    MatDividerModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    PageHeaderModule,
    SendingRestrictedModule,
    FileUploadModule,
    ImportantInformationModule,
    ContactDataModule,
    ValidationSummaryModule,
    WorkInstructionsModule,
    SharedModule
  ],
  exports: [],
  declarations: [InvoiceClarificationEditComponent],
  providers: []
})
export class InvoiceClarificationEditModule {}
