<div class="flex-row justify-between content-center mt-15" *transloco="let t">
  <div class="flex-col flex-grow-50">
    <div class="flex-row justify-start content-center">
      <strong
        class="flex flex-grow-50 justify-end content-center pr-15 ui-test--sdd-metadata-strong-product-type-label">
        {{ t('Common_ProductType') }}
      </strong>
      <span class="flex flex-1 justify-start content-center ui-test--sdd-metadata-span-product-type">
        <mat-icon class="ui-test--sdd-metadata-mat-icon-product-type">
          {{ statusDetails.productType | productTypeIcon }}
        </mat-icon>
        {{ t('Common_' + statusDetails.productType) }}
      </span>
    </div>
    <div class="flex-row mt-5">
      <strong
        class="flex flex-grow-50 justify-end content-center pr-15 ui-test--sdd-metadata-strong-date-of-request-label">
        {{ t('Common_DateOfRequest') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-metadata-span-date-of-request">
        {{ statusDetails.createdDate | dateFormat:'DATETIME_SHORT_WITH_SECONDS' }}
      </span>
    </div>
    <div class="flex-row mt-5">
      <strong
        class="flex flex-grow-50 justify-end content-center pr-15 ui-test--sdd-metadata-strong-contract-number-label">
        {{ t('Common_ContractNumber') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-metadata-span-contract-number">{{ statusDetails.contractNumber }}</span>
    </div>
    <div class="flex-row mt-5">
      <strong class="flex flex-grow-50 justify-end content-center pr-15 ui-test--sdd-metadata-strong-mileage-label">
        {{ t('Common_Mileage') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-metadata-span-mileage">{{ statusDetails.mileage | mileageFormat }}</span>
    </div>
    <div class="flex-row mt-5" *ngIf="statusDetails.isDateOfEventVisible">
      <strong
        class="flex flex-grow-50 justify-end content-center pr-15 ui-test--sdd-metadata-strong-date-of-event-label">
        {{ t('Request_' + statusDetails.productType + '_DateOfEvent') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-metadata-span-date-of-event">
        {{ statusDetails.dateOfEvent | dateFormat }}
      </span>
    </div>
  </div>
  <div class="flex-col flex-grow-50">
    <div class="flex-row">
      <strong
        class="flex flex-grow-50 justify-end content-center pr-15 ui-test--sdd-metadata-strong-license-plate-number-label">
        {{ t('Common_LicensePlateNumber') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-metadata-span-license-plate-number">
        {{ statusDetails.licensePlateNumber }}
      </span>
    </div>
    <div class="flex-row mt-5">
      <strong class="flex flex-grow-50 justify-end content-center pr-15 ui-test--sdd-metadata-strong-vin-label">
        {{ t('Common_VIN') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-metadata-span-vin">{{ statusDetails.vehicleIdentificationNumber }}</span>
    </div>
    <div class="flex-row mt-5">
      <strong class="flex flex-grow-50 justify-end content-center pr-15 ui-test--sdd-metadata-strong-model-name-label">
        {{ t('Common_ModelName') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-metadata-span-model-name">{{ statusDetails.modelName }}</span>
    </div>
    <div class="flex-row mt-5">
      <strong
        class="flex flex-grow-50 justify-end content-center pr-15"
        [class]="'ui-test--sdd-metadata-strong-modelNumberLabel'">
        {{ t('Common_ModelNumber') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-metadata-span-model-number">{{ statusDetails.modelCode }}</span>
    </div>
    <div class="flex-row mt-5" *ngIf="statusDetails.isInsuranceReferenceNumberActive">
      <strong
        class="flex flex-grow-50 justify-end content-center pr-15 ui-test--sdd-metadata-strong-insurance-reference-number-label">
        {{ t('Common_InsuranceReferenceNumber') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-metadata-span-insurance-reference-number">
        {{ statusDetails.insuranceReferenceNumber }}
      </span>
    </div>
  </div>
</div>
