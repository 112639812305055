import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { SortComponents, SortEvent, SortKey } from './sort.types';

@Component({
  selector: 'sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SortComponent implements OnInit {
  /**
   * Controls which components should be shown.
   */
  @Input() visibleComponents: SortComponents[] = [];

  /**
   * The keys that are used for sorting.
   */
  @Input() sortKeys!: SortKey[];

  /**
   * (Optional) Pass the initially selected sort direction.
   */
  @Input() sortDirection: SortDirection = 'desc';

  /**
   * (Optional) State of the panels.
   */
  @Input() panelExpanded: boolean = true;

  @Input() searchTooltip: string = '';

  /**
   * Fires when a search string was entered.
   */
  @Output() search = new EventEmitter<string>();

  /**
   * Fires when the sort button was clicked.
   */
  @Output() sort = new EventEmitter<SortEvent>();

  /**
   * Fires when the toggle button was clicked.
   */
  @Output() panelExpandedChange = new EventEmitter<boolean>();

  public sortKey = '';

  ngOnInit(): void {
    // filter once when the user visits the page
    setTimeout(() => {
      this.onSort();
    });

    this.sortKey = this.sortKeys[0][0];
  }

  searchValue(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    this.search.emit(target.value);
  }

  onSort() {
    const orderByEvent: SortEvent = {
      direction: this.sortDirection,
      key: this.sortKey
    };
    this.sort.emit(orderByEvent);
  }

  togglePanels() {
    this.panelExpanded = !this.panelExpanded;
    this.panelExpandedChange.emit(this.panelExpanded);
  }
}
