<div *transloco="let t" class="request-confirmation">
  <ng-container *ngIf="requestConfirmation; else requestConfirmationLoading">
    <h2 class="subtitle ui-test--request-confirmation-header-title">
      {{ t('Request_Confirm_Headline') }}
      <small class="ui-test--request-confirmation-small-request-id">
        {{ requestConfirmation.requestIdForCustomer }}
      </small>
    </h2>

    <blockquote>
      <p class="ui-test--request-confirmation-paragraph-information">{{ t(informationTextKey) }}</p>
    </blockquote>

    <mat-divider class="my-10"></mat-divider>

    <div class="flex-row justify-end content-center">
      <div *ngIf="requestConfirmation.isMobilityAllowedForProductType && this.mode !== 'Canceled'">
        <button
          mat-stroked-button
          class="mr-5 ui-test--request-confirmation-button-mobility"
          (click)="newMobilityRequest()">
          <mat-icon class="ui-test--request-confirmation-mat-icon-mobility">transfer_within_a_station</mat-icon>
          {{ t('Common_RequestAddMobilityPositions') }}
        </button>
      </div>
      <button
        class="ui-test--request-confirmation-button-status-overview"
        mat-stroked-button
        (click)="openStatusDetailsDialog($event)">
        {{ t('Request_Confirm_StatusOverview_Index') }}
      </button>
    </div>
  </ng-container>
</div>

<ng-template #requestConfirmationLoading>
  <div class="spinner spinner-small"></div>
</ng-template>
