<ng-container *ngIf="breadcrumbService.breadcrumb.vehicleIdentificationNumber">
  <div class="flex-row justify-center content-start align-start breadcrumb" *transloco="let t">
    <div class="base-layout">
      <ul class="flex-row justify-start content-stretch align-stretch">
        <li
          class="flex-row justify-start content-center align-center ui-test--breadcrumb-list-breadcrumb-list"
          *ngIf="breadcrumbService.breadcrumb.vehicleIdentificationNumber">
          <mat-icon class="ui-test--breadcrumb-mat-icon-car">directions_car</mat-icon>
          <span class="ui-test--breadcrumb-span-vehicle-identification-number">
            {{ breadcrumbService.breadcrumb.vehicleIdentificationNumber }}
          </span>
          <span class="ui-test--breadcrumb-span-license-plate-number">
            &#160; {{breadcrumbService.breadcrumb.licensePlateNumber}}
          </span>
        </li>
        <li
          class="flex-row justify-start content-center align-center ui-test--breadcrumb-list-driver-file"
          *ngIf="breadcrumbService.breadcrumb.contractNumber">
          <mat-icon class="material-icons-outlined ui-test--breadcrumb-mat-icon-driver-file">
            insert_drive_file
          </mat-icon>
          <span class="ui-test--breadcrumb-span-contract-number">{{breadcrumbService.breadcrumb.contractNumber}}</span>
        </li>
        <li
          class="flex-row justify-start content-center align-center ui-test--breadcrumb-list-product-icon"
          *ngIf="breadcrumbService.breadcrumb.productType">
          <mat-icon class="ui-test--breadcrumb-mat-icon-product-icon">
            {{ breadcrumbService.breadcrumb.productType | productTypeIcon }}
          </mat-icon>
          <span class="ui-test--breadcrumb-span-request-id" *ngIf="breadcrumbService.breadcrumb.requestId">
            &#160;{{breadcrumbService.breadcrumb.requestId}}
          </span>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
