import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { RequestStateStore, UserService } from '@core/services';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { Subject, takeUntil } from 'rxjs';
import { FilterCriteria, FilterRequest } from '../filter/filter.types';
import { PaginationComponent } from '../pagination/pagination.component';
import { filterProperties, sort } from '../sort/sort.helper';
import { Filterable, SortEvent } from '../sort/sort.types';
import { OverviewService } from './overview.service';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewComponent implements OnInit, OnDestroy {
  @ViewChild('paginatorTop') paginatorTop!: PaginationComponent;
  @ViewChild('paginatorBottom') paginatorBottom!: PaginationComponent;

  public errorList!: string[];
  public loading = true;
  public filterCriteria!: FilterCriteria;
  public overviewData: Filterable[] = [];
  public initialResult: Filterable[] = [];
  private searchResult: Filterable[] = [];
  private lastSearchValue: string = '';
  public lastSearch!: FilterRequest;
  public scrollLoading: boolean = false;
  protected viewModeKey!: string;
  protected filterEntries: string[] = [];
  protected sortKey = '';
  panelExpanded!: boolean;
  sortDirection: SortDirection = 'desc';
  isManagingDealer = false;
  scrollItemsDisplayLimit: number = 25;
  scrollItemsExtendLimit = 10;
  shownEntriesCount: number = 0;
  totalEntriesCount: number = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected overviewService: OverviewService,
    protected userService: UserService,
    private requestStateStore: RequestStateStore
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.getFilterCriteria();
    this.getState();
    this.requestStateStore.requestEventMessages.pipe(takeUntil(this.destroy$)).subscribe((m) => {
      if (m.eventName === 'RequestStateUpdated') {
        this.getData(this.lastSearch);
      }
    });
  }

  onScroll(): void {
    const displayDataSet = this.searchResult.length > 0 ? this.searchResult : this.initialResult;
    this.scrollLoading = displayDataSet.length > this.overviewData.length + this.scrollItemsExtendLimit;
    this.displayScrollLimitedData(displayDataSet);
  }

  search(searchValue: string) {
    this.lastSearchValue = searchValue;
    this.searchResult = filterProperties(this.initialResult, searchValue, {
      caseSensitive: false,
      ignoreBlanks: true,
      entries: this.filterEntries
    });
    this.displayScrollLimitedData(this.searchResult, true);
  }

  sort(event: SortEvent) {
    this.sortDirection = event.direction;
    this.sortKey = event.key;
    sort(this.initialResult, event.key, event.direction);
    this.displayScrollLimitedData(this.initialResult, true);
  }

  displayScrollLimitedData(data: Filterable[], resetView: boolean = false) {
    let extendLimit = this.scrollItemsExtendLimit;
    if (resetView) {
      this.overviewData = [];
      extendLimit = this.scrollItemsDisplayLimit;
    }

    this.totalEntriesCount = data.length;
    const newDataToShow = data.slice(this.overviewData.length, this.overviewData.length + extendLimit);
    newDataToShow.forEach((entry) => {
      entry.show = true;
    });
    this.overviewData.push(...newDataToShow);
    this.shownEntriesCount = this.overviewData.length;
  }

  getData(filterRequest: FilterRequest) {
    if (!filterRequest) {
      return;
    }
    this.errorList = [];
    filterRequest.ticks = '';
    this.loading = true;
    this.lastSearch = filterRequest;
    this.searchResult = [];

    this.overviewService.getData(filterRequest).subscribe({
      next: (overviewData: Filterable[]) => {
        this.initialResult = overviewData;
        sort(this.initialResult, this.sortKey, this.sortDirection);
        this.search(this.lastSearchValue);

        this.loading = false;
      },
      error: (res: HttpErrorResponse) => {
        const validationReader = new ValidationReader(res.error);
        this.errorList = validationReader.values;
        this.overviewData = [];
        this.initialResult = [];
        this.loading = false;
      }
    });
  }

  onPanelExpandedChange(expanded: boolean) {
    this.panelExpanded = expanded;
    this.userService[this.viewModeKey] = expanded ? 'detail' : 'compact';
  }

  protected getState() {
    this.panelExpanded = this.userService[this.viewModeKey] != 'compact';
  }

  protected getFilterCriteria() {
    this.overviewService.getFilterCriteria().subscribe({
      next: (filterCriteria: FilterCriteria) => {
        this.isManagingDealer = filterCriteria.isManagingDealer;
        this.filterCriteria = filterCriteria;
        this.loading = false;
      },
      error: (res: HttpErrorResponse) => {
        const validationReader = new ValidationReader(res.error);
        this.errorList = validationReader.values;
        this.loading = false;
      }
    });
  }
}
