export enum UserSettingKeyCommon {
  None = 0,
  UserLanguage = 1,
  DefaultLanguage = 2,
  UserCulture = 3,
  UserTimeZone = 4,
  UserMailOption = 5,
  UserServiceAdvisorNumber = 6,
  UserSalesNumber = 7,
  UserPhoneNumber = 8,
  StatusOverviewViewMode = 60,
  StatusOverviewProductTypeFilter = 61,
  OpenRequestViewMode = 70,
  OpenRequestProductTypeFilter = 71,
  AftersalesOverviewViewMode = 80
}
