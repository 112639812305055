import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { DirectivesModule } from '@core/directives';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DropdownWithSearchComponent } from './dropdown-with-search.component';

@NgModule({
  imports: [
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NgxMatSelectSearchModule,
    DirectivesModule,
    PipesModule,
    SharedModule
  ],
  exports: [DropdownWithSearchComponent],
  declarations: [DropdownWithSearchComponent],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'custom-overlay-pane' }
    }
  ]
})
export class DropdownWithSearchModule {}
