<form *transloco="let t">
  <mat-form-field class="w-full">
    <mat-label class="'ui-test--request-dropdown-predictive-search-mat-label-option">
      {{ t(labelResourceKey) }}
    </mat-label>
    <mat-chip-list #chipGrid>
      <mat-chip
        *ngFor="let option of selectedOptions; let i = index"
        (removed)="remove(i)"
        [class]="'ui-test--request-dropdown-predictive-search-mat-chip-option-' + i">
        <strong *ngIf="option.epnText" [class]="'ui-test--request-dropdown-predictive-search-strong-epn-text-' + i">
          ({{ option.epnText }})
        </strong>
        {{ option.text }}
        <button matChipRemove class="ui-test--request-dropdown-predictive-search-button-remove">
          <mat-icon class="ui-test--request-dropdown-predictive-search-mat-icon-remove">cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-list>

    <input
      type="text"
      class="ui-test--request-dropdown-predictive-search-input-option"
      [placeholder]="t(placeholderResourceKey)"
      [formControl]="searchInputControl"
      (keydown)="onSearchInputKeyDown($event)"
      matInput
      #searchInput
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="autoComplete" />

    <mat-autocomplete
      autoActiveFirstOption
      #autoComplete="matAutocomplete"
      [displayWith]="displayOption"
      (optionSelected)="optionSelectedHandler($event)">
      <mat-optgroup
        *ngFor="let group of filteredDropdownOptions | async; trackBy: groupIdentifier; let index = index"
        [class]="'ui-test--request-dropdown-predictive-search-mat-opt-group-option-' + index"
        [label]="group.text">
        <mat-option
          style="white-space: pre"
          *ngFor="let option of group.options; trackBy: optionIdentifier"
          [class]="'ui-test--request-dropdown-predictive-search-mat-option-option-' + searchInput.value"
          [value]="option"
          [innerHTML]="option.text | highlight:searchInput.value"></mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</form>
