import { NgModule } from '@angular/core';
import { SharedModule } from '@core/modules';

import { EntriesCounterComponent } from './entries-counter.component';

@NgModule({
  imports: [SharedModule],
  exports: [EntriesCounterComponent],
  declarations: [EntriesCounterComponent],
  providers: []
})
export class EntriesCounterModule {}
