import { InvoiceClarificationCancelComponent } from 'app/pages/invoice-clarification/cancel/cancel.component';
import { InvoiceClarificationCancelResolver } from 'app/pages/invoice-clarification/cancel/cancel.resolver';
import { InvoiceClarificationConfirmationComponent } from 'app/pages/invoice-clarification/confirmation/confirmation.component';
import { InvoiceClarificationEditComponent } from 'app/pages/invoice-clarification/edit/edit.component';
import { InvoiceClarificationEditResolver } from 'app/pages/invoice-clarification/edit/edit.resolver';
import { InvoiceClarificationComponent } from 'app/pages/invoice-clarification/invoice-clarification.component';

export const INVOICE_CLARIFICATION_ROUTES = [
  {
    path: 'start',
    component: InvoiceClarificationEditComponent,
    resolve: [InvoiceClarificationEditResolver],
    title: 'PageTitle_InvoiceClarification'
  },
  {
    path: 'confirmation',
    component: InvoiceClarificationConfirmationComponent,
    title: 'PageTitle_InvoiceClarification'
  },
  {
    path: 'cancel',
    component: InvoiceClarificationCancelComponent,
    resolve: [InvoiceClarificationCancelResolver],
    title: 'InvoiceClarification_CancelInvoiceClarificationSummary_Title'
  },
  {
    path: '',
    component: InvoiceClarificationComponent,
    data: {
      reloadComponent: true
    },
    title: 'Menu_InvoiceClarification_Index'
  }
];
