import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigurationService } from '@core/services';
import { ActivityTypeIdentifierCommon } from 'app/components/positions';
import { sort } from 'app/components/sort/sort.helper';
import { RequestService } from '../../request.service';
import { ClaimPositionFilter, RequestContent } from '../../request.types';
import { UnsavedChangesStateStore } from '../../unsaved-changes-state-store.service';

@Component({
  selector: 'claim-position-filter',
  templateUrl: './claim-position-filter.component.html',
  styleUrls: ['./claim-position-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClaimPositionFilterComponent implements OnInit {
  @Input() requestContent!: RequestContent;

  groupedSelectObj: Map<number, ClaimPositionFilter> = new Map<number, ClaimPositionFilter>();
  private exclusiveActivityTypeClassIds: number[] = [];
  private isExclusiveActivityTypeClassSelected = false;
  private isNonExclusiveActivityTypeClassSelected = false;

  constructor(
    private requestService: RequestService,
    private configurationService: ConfigurationService,
    private unsavedChangesStateStore: UnsavedChangesStateStore
  ) {}

  ngOnInit(): void {
    this.selectGroupedActivityTypes();
    this.initializeDisabled();
  }

  selectGroupedActivityTypes() {
    this.requestContent.availableActivityTypes.forEach((activityType) => {
      activityType.activityTypeClasses.forEach((activityTypeClass) => {
        let existingObject = this.groupedSelectObj.get(activityTypeClass.id);
        if (!existingObject) {
          existingObject = <ClaimPositionFilter>{
            id: activityTypeClass.id,
            name: activityTypeClass.name,
            description: activityTypeClass.description,
            activityTypes: []
          };
          this.groupedSelectObj.set(activityTypeClass.id, existingObject);
        }

        existingObject.activityTypes.push(activityType);
      });
    });

    // Sort activity type class map object
    this.groupedSelectObj = new Map(
      [...this.groupedSelectObj].sort((a, b) =>
        this.requestContent.isActivityTypeClassesSortingAscending
          ? a[1].description.localeCompare(b[1].description)
          : b[1].description.localeCompare(a[1].description)
      )
    );

    // Sort the available ActivityTypes by description with given configuration.
    this.groupedSelectObj.forEach((item) => {
      sort(item.activityTypes, 'description', this.requestContent.isActivityTypesSortingAscending ? 'asc' : 'desc');
    });
  }

  isActivityTypeClassSelected(activityTypeClassId: number) {
    return this.requestContent.requestPositions.some(
      (positionItem) => positionItem.activityTypeClassKey === activityTypeClassId
    );
  }

  isActivityTypeSelectedInClass(activityTypeId: number, activityTypeClassId: number) {
    return this.requestContent.requestPositions.some(
      (positionItem) =>
        positionItem.activityTypeClassKey === activityTypeClassId && positionItem.activityTypeKey === activityTypeId
    );
  }

  toggleSelection(itemType: ActivityTypeIdentifierCommon, activityTypeId: number, activityTypeClassId: number) {
    this.requestService.addPosition(this.requestContent, itemType, activityTypeId, activityTypeClassId).subscribe({
      next: (res: RequestContent) => {
        this.unsavedChangesStateStore.addEventMessage('Dirty');
        this.requestContent.requestPositions = res.requestPositions;
        this.setSelectedStates();
      }
    });
  }

  isDisabled(id: number): boolean {
    // Never disable an already selected class. Also prevents breaking old invalid requests.
    if (this.isActivityTypeClassSelected(id)) {
      return false;
    }

    // Disable all activityTypeClasses if an exclusive activityTypeClass is selected,
    // or disable all exclusive activityTypeClasses if a non-exclusive activityTypeClass is selected.
    if (
      this.isExclusiveActivityTypeClassSelected ||
      (this.exclusiveActivityTypeClassIds.includes(id) && this.isNonExclusiveActivityTypeClassSelected)
    ) {
      return true;
    }

    return false;
  }

  asIsOrder() {
    return 1;
  }

  private initializeDisabled(): void {
    [...this.groupedSelectObj.values()].forEach((c) => {
      if (this.configurationService.exclusiveActivityTypeClasses.includes(c.name)) {
        this.exclusiveActivityTypeClassIds.push(c.id);
      }
    });
    this.setSelectedStates();
  }

  private setSelectedStates() {
    this.isExclusiveActivityTypeClassSelected = this.requestContent.requestPositions.some((positionItem) =>
      this.exclusiveActivityTypeClassIds.includes(positionItem.activityTypeClassKey ?? -1)
    );

    this.isNonExclusiveActivityTypeClassSelected = this.requestContent.requestPositions.some(
      (positionItem) => !this.exclusiveActivityTypeClassIds.includes(positionItem.activityTypeClassKey ?? -1)
    );
  }
}
