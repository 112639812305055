import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';

import { VehicleSelectionComponent } from './vehicle-selection.component';

@NgModule({
  imports: [MatTableModule, MatButtonModule, PageHeaderModule, PipesModule, ValidationSummaryModule, SharedModule],
  exports: [],
  declarations: [VehicleSelectionComponent],
  providers: []
})
export class VehicleSelectionModule {}
