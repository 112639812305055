import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '@core/directives';
import { SharedModule } from '@core/modules';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { InputWithValidationModule } from '../input-with-validation/input-with-validation.module';
import { PhoneNumberVerificationDialogComponent } from './phone-number-verification.component';

@NgModule({
  imports: [
    DirectivesModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule,
    ValidationSummaryModule,
    SharedModule,
    InputWithValidationModule
  ],
  exports: [PhoneNumberVerificationDialogComponent],
  declarations: [PhoneNumberVerificationDialogComponent],
  providers: []
})
export class PhoneNumberVerificationModule {}
