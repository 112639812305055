import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@core/modules';
import { ProcessBarComponent } from './process-bar.component';

@NgModule({
  imports: [MatIconModule, SharedModule],
  exports: [ProcessBarComponent],
  declarations: [ProcessBarComponent],
  providers: []
})
export class ProcessBarModule {}
