export enum SearchIndexFields {
  None = 0,
  ContractNumber = 1,
  DamageDate = 2,
  DateOfFirstRegistration = 4,
  LicensePlateNumber = 8,
  VehicleIdentificationNumber = 16,
  Standard = 27,
  Mileage = 32,
  All = 63
}
