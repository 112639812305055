import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ParseDatePipe } from '@core/pipes';
import { MatDatepicker } from '@matheo/datepicker';
import { DateTime } from 'luxon';

@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent {
  @ViewChild('datePicker') datePicker!: MatDatepicker<Date>;
  @Input() placeholderTextValue: string = '';
  @Input() name: string = '';
  @Input() hintTextValue?: string;
  @Input() minDate?: DateTime;
  @Input() maxDate?: DateTime;
  @Input() required: boolean = false;
  @Input() control!: FormControl;

  constructor(private parseDatePipe: ParseDatePipe) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onDatePickerBlur(event: FocusEvent) {
    const input = event.target as HTMLInputElement;
    this.control.setValue(this.parseDatePipe.transform(input.value));
  }

  onDatePickerKeydown(event: KeyboardEvent) {
    if (event.key === 'Tab' && this.datePicker.opened) {
      this.datePicker.close();
    }
  }

  onDatePickerFocused(event: FocusEvent) {
    this.datePicker.open();
    // The setTimeout is necessary for the input field date text to be selected
    setTimeout(() => {
      (event.target as HTMLInputElement).select();
    });
  }
}
