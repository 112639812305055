import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StatusDetailsDialogService } from 'app/pages/status-overview/status-details-dialog/status-details-dialog.service';
import { InvoiceClarificationConfirmation } from '../edit/edit.types';

@Component({
  selector: 'invoice-clarification-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class InvoiceClarificationConfirmationComponent {
  invoiceClarificationConfirmation?: InvoiceClarificationConfirmation;

  constructor(private statusDetailsDialogService: StatusDetailsDialogService, private router: Router) {
    this.invoiceClarificationConfirmation = this.router.getCurrentNavigation()?.extras.state?.confirmation;

    // in case someone directly navigates the component's URL
    if (!this.invoiceClarificationConfirmation) {
      this.router.navigate(['/not-found']);
    }
  }

  openStatusDetailsDialog(): void {
    // This is just for typescript type checking. This method is not callable when invoiceClarificationConfirmation
    // is undefined, because the related button will not be rendered.
    if (!this.invoiceClarificationConfirmation) {
      return;
    }

    this.statusDetailsDialogService.openDialog(
      this.invoiceClarificationConfirmation.requestGuid,
      this.invoiceClarificationConfirmation.productType,
      ''
    );
  }
}
