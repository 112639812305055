import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SnackBarData } from './snack-bar-store.types';

@Injectable({
  providedIn: 'root'
})
export class SnackBarStore {
  private snackBarMessagesSubject: Subject<SnackBarData> = new Subject();

  public readonly snackBarMessages: Observable<SnackBarData> = this.snackBarMessagesSubject.asObservable();

  public addSnackBarMessage(message: string, showInterval: number = 3000) {
    this.snackBarMessagesSubject.next({ message: message, showInterval: showInterval });
  }
}
