import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorContactFormComponent } from '@core/services';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {
  constructor(private dialog: MatDialog) {}

  openDialog() {
    this.dialog.open(ErrorContactFormComponent);
  }
}
