import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '@core/modules';
import { ErrorContactFormComponent } from './error-contact-form/error-contact-form.component';
import { ErrorContactFormService } from './error-contact-form/error-contact-form.service';
import { ErrorMessageDialogComponent } from './error-message-dialog/error-message-dialog.component';

@NgModule({
  imports: [MatButtonModule, MatDialogModule, MatInputModule, MatFormFieldModule, SharedModule],
  exports: [ErrorMessageDialogComponent, ErrorContactFormComponent],
  declarations: [ErrorMessageDialogComponent, ErrorContactFormComponent],
  providers: [ErrorContactFormService]
})
export class ErrorHandlingModule {}
