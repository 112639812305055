import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContextDataService, TenantSettingsService } from '@core/services';
import { ExternalApplicationAssignResult } from '@core/types/application-assign.types';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { TranslocoService } from '@ngneat/transloco';
import { OfferCriteriaChooseDialogComponent } from './offer-criteria-choose-dialog/offer-criteria-choose-dialog.component';
import { OfferPanelService } from './offer-panel.service';
import {
  ApplicationAssignData,
  ExtendedSearchData,
  ExtendedSearchParameter,
  GetOfferResponse,
  MissingParameter,
  Offer
} from './offer-panel.types';

@Component({
  selector: 'offer-panel',
  templateUrl: './offer-panel.component.html',
  styleUrls: ['./offer-panel.component.scss']
})
export class OfferPanelComponent implements OnInit {
  @Input() vin: string = '';

  public errorList: string[] = [];
  public offerSearchLoading: boolean = false;
  public offers: Offer[] = [];
  public potentialOffersLength: number = 0;
  public responseGuid: string = '';
  public visibleDashboardColumns: string[] = [];
  public visibleDetailsColumns: string[] = [];
  public isMinimumRequiredParameterMissing: boolean = false;

  private previousAdditionalSearchParams: ExtendedSearchParameter[] = [];

  constructor(
    private offerPanelService: OfferPanelService,
    private contextDataService: ContextDataService,
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    public tenantService: TenantSettingsService
  ) {}

  ngOnInit() {
    this.getOffersFor(this.contextDataService.data.processId ?? '', this.vin, {
      context: '',
      additionalSearchParams: []
    });
  }

  public editSearchCriteria(potentialOffer?: Offer) {
    let missingParams: MissingParameter[] = [];

    if (this.isMinimumRequiredParameterMissing) {
      missingParams = this.getMissingParameters();
    } else if (potentialOffer) {
      missingParams = potentialOffer.missingParams;
    }

    const offerChooseDialog = this.dialog.open(OfferCriteriaChooseDialogComponent);
    offerChooseDialog.componentInstance.missingParameters = missingParams;
    offerChooseDialog
      .afterClosed()
      .subscribe((missingParameters: MissingParameter[]) =>
        this.afterOfferCriteriaChooseDialogClose(missingParameters)
      );
  }

  public applyOffer(potentialOffer?: Offer) {
    const applicationRequest: ApplicationAssignData = {
      offerId: potentialOffer?.id,
      responseGuid: this.responseGuid,
      variantId: ''
    };

    this.errorList = [];
    this.offerPanelService.offerApplication(applicationRequest).subscribe({
      next: (result: ExternalApplicationAssignResult) => {
        if (!result.isValid) {
          this.errorList.push(
            result.errorMessage ?? this.translocoService.translate('OfferItemPanel_ErrorFor_ApplicationAssign')
          );
          return;
        }

        window.open(result.targetUri, '_blank');
      },
      error: (res: HttpErrorResponse) => {
        const validationReader = new ValidationReader(res.error);
        this.errorList = validationReader.values;
      }
    });
  }

  private getMissingParameters(): MissingParameter[] {
    const missingParameters = new Map<string, MissingParameter>();
    this.previousAdditionalSearchParams.forEach((previousParameter) => {
      const missingParameter: MissingParameter = {
        displayName: '',
        isMandatoryParameter: null,
        key: previousParameter.key,
        value: previousParameter.value,
        parameterType: previousParameter.type,
        enumValues: previousParameter.enumValues
      };
      missingParameters.set(previousParameter.key, missingParameter);
    });

    this.offers.forEach((offer) => {
      const mandatoryParameters = offer.missingParams?.filter(
        (missingParameter) => missingParameter.isMandatoryParameter
      );
      mandatoryParameters.forEach((parameter) => missingParameters.set(parameter.key, parameter));
    });

    return [...missingParameters.values()];
  }

  private afterOfferCriteriaChooseDialogClose(missingParameters: MissingParameter[]) {
    if (!missingParameters) {
      return;
    }

    const extendedSearchData: ExtendedSearchData = {
      context: 'offer',
      additionalSearchParams: []
    };

    missingParameters.forEach((missingParameter) => {
      const additionalSearchParameter: ExtendedSearchParameter = {
        isReadonly: false,
        key: missingParameter.key,
        label: missingParameter.displayName,
        value: missingParameter.value,
        type: missingParameter.parameterType,
        enumValues: missingParameter.enumValues
      };

      extendedSearchData.additionalSearchParams?.push(additionalSearchParameter);
    });

    if (!this.isMinimumRequiredParameterMissing) {
      this.previousAdditionalSearchParams.forEach((previousAdditionalSearchParameter) => {
        const additionalSearchParameter = {
          label: '',
          isReadonly: false,
          key: previousAdditionalSearchParameter.key,
          value: previousAdditionalSearchParameter.value,
          type: previousAdditionalSearchParameter.type,
          enumValues: previousAdditionalSearchParameter.enumValues
        };
        extendedSearchData.additionalSearchParams?.push(additionalSearchParameter);
      });
    }

    this.getOffersFor(this.contextDataService.data.processId ?? '', this.vin, extendedSearchData);
  }

  private getOffersFor(processId: string, vin: string, extendedSearchData: ExtendedSearchData) {
    this.offerSearchLoading = true;
    this.errorList = [];

    this.offerPanelService.getOffersFor(processId, vin, extendedSearchData).subscribe({
      next: (response: GetOfferResponse) => {
        this.offers = response.result.items;
        this.previousAdditionalSearchParams = response.result.additionalSearchParams;
        this.isMinimumRequiredParameterMissing = response.result.isMinimumRequiredParameterMissing;
        this.responseGuid = response.result.responseGuid;
        this.visibleDashboardColumns = response.result.visibleDashboardColumns;
        this.visibleDetailsColumns = response.result.visibleDetailColumns;

        this.potentialOffersLength = this.offers.filter((offer) => offer.isPotentialOffer).length;
        this.offerSearchLoading = false;
      },
      error: (res: HttpErrorResponse) => {
        const validationReader = new ValidationReader(res.error);
        this.errorList = validationReader.values;
        this.offerSearchLoading = false;
      }
    });
  }
}
