export enum RoleCommon {
  None = 0,
  General = 1,
  ServiceAndMaintenance = 2,
  Tire = 4,
  Mobility = 8,
  Warranty = 16,
  MotorInsurance = 32,
  Aftersales = 64,
  ReadOnly = 128,
  InsuranceRepair = 256,
  DamageReport = 512,
  AudiOnly = 1024,
  SendingRestricted = 2048,
  DealerMember = General |
    ServiceAndMaintenance |
    Tire |
    Mobility |
    Warranty |
    MotorInsurance |
    Aftersales |
    InsuranceRepair,
  ManagingMember = General | MotorInsurance | DamageReport,
  AudiDamageManagingMember = General | MotorInsurance | DamageReport | AudiOnly,
  FsToolsDucati = General | ServiceAndMaintenance | Tire | Mobility | InsuranceRepair,
  TireMember = General | Tire,
  WarrantyMember = General | Warranty,
  MobilityMember = General | Mobility,
  InternalMember = DealerMember | ReadOnly
}
