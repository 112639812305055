<ng-container *transloco="let t">
  <div *ngFor="let event of statusDetails.events; let i = index" class="mt-20">
    <div class="flex-row justify-center content-start">
      <strong
        class="flex flex-1 justify-end content-center pr-15"
        [class]="'ui-test--sdd-events-strong-event-name-label-' + i">
        {{ event.eventName }}
      </strong>
      <span class="flex-grow-50" [class]="'ui-test--sdd-events-span-event-name-' + i">{{ event.eventClassName }}</span>
    </div>
  </div>
  <div *ngIf="statusDetails.responseNotesByTimestamp?.length">
    <mat-divider class="my-20"></mat-divider>
    <h3 class="mb-10 section-title ui-test--sdd-events-header-response-notes">
      {{ t('StatusDetailsViewModel_ResponseNotes') }}
    </h3>
    <mat-card
      *ngFor="let responseNote of statusDetails.responseNotesByTimestamp; let i = index"
      class="note-card mb-15">
      <mat-card-content>
        <div class="flex-row justify-start content-center">
          <mat-icon [class]="'ui-test--sdd-events-mat-icon-today-' + i">today</mat-icon>
          {{ responseNote.timestamp | dateFormat:'DATETIME_SHORT_WITH_SECONDS' }}
        </div>
        <p
          class="mt-5"
          [class]="'ui-test--sdd-events-paragraph-response-note-' + i"
          [innerHtml]="responseNote.text | nl2br"></p>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
