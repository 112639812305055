import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RequestEventMessage } from './request-state-store.types';

@Injectable({
  providedIn: 'root'
})
export class RequestStateStore {
  private _requestEventMessages: BehaviorSubject<RequestEventMessage> = new BehaviorSubject({
    eventName: 'EventHubInitialized',
    payload: {}
  } as RequestEventMessage);

  public readonly requestEventMessages: Observable<RequestEventMessage> = this._requestEventMessages.asObservable();

  public addEventMessage(eventMessage: RequestEventMessage) {
    this._requestEventMessages.next(eventMessage);
  }
}
