<ng-container *transloco="let t">
  <page-header [title]="t('Error_PageNotFound')"></page-header>
  <h2 class="ui-test--forbidden-header-message">
    {{ t('Error_PageNotFound_Message1') + ' ' + t('Error_PageNotFound_Message2') }}
  </h2>

  <h3 class="ui-test--forbidden-header-report">
    {{ t('Error_PageNotFound_InfoGoHome') + '. ' + t('Error_PageNotFound_ReportAProblem') }}
  </h3>

  <div class="flex-row gap-10">
    <button class="ui-test--forbidden-button-go-home" mat-raised-button routerLink="/">
      {{ t('Error_PageNotFound_GoHome') }}
    </button>
    <button class="ui-test--forbidden-header-report-problem" mat-raised-button (click)="openDialog()">
      {{ t('Error_ServerError_ReportProblem') }}
    </button>
  </div>
</ng-container>
