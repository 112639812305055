<div *transloco="let t">
  <div class="flex-row justify-between content-start">
    <form [formGroup]="changeAccountForm" novalidate (ngSubmit)="onSubmit()" class="flex-grow-50 mt-20 mx-15">
      <mat-card>
        <mat-card-title>{{ t('Account_ChangeAccountInfo_Title') }}</mat-card-title>
        <mat-card-content class="mt-20">
          <div class="flex-col flex-grow-100 justify-around content-center">
            <mat-form-field>
              <mat-label>{{ t('AccountChangeAccountInfoViewModel_Tenant') }}</mat-label>
              <mat-select formControlName="tenant">
                <mat-option value="">{{ t('Common_None') }}</mat-option>
                <mat-option [value]="option.value" *ngFor="let option of availableTenants">
                  {{ option.text }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ t('AccountChangeAccountInfoViewModel_AppId') }}</mat-label>
              <mat-select formControlName="appId">
                <mat-option [value]="option.value" *ngFor="let option of availableAppIds">{{ option.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-actions align="end">
          <button mat-raised-button color="primary">{{ t('Common_Button_SaveChanges') }}</button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>
