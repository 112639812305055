import { HttpParams } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { SelectListItem } from '@core/types/mvc.types';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { DateTime } from 'luxon';

export type FilterParameters =
  | 'datePickerFrom'
  | 'datePickerTo'
  | 'productList'
  | 'requestStatusList'
  | 'invoiceStatusList'
  | 'clarificationStatusList'
  | 'advisorList'
  | 'searchButton';

export type FilterNames = 'statuses' | 'invoiceStatuses' | 'clarificationStatuses' | 'products' | 'advisors';

export interface FilterForm {
  dateFrom: FormControl<DateTime | null>;
  dateTo: FormControl<DateTime | null>;
  products: FormControl<SelectListItem[]>;
  statuses: FormControl<SelectListItem[]>;
  invoiceStatuses: FormControl<SelectListItem[]>;
  clarificationStatuses: FormControl<SelectListItem[]>;
  advisors: FormControl<string[]>;
}

export interface FilterCriteria {
  $type: string;
  availableInvoiceStates: SelectListItem[];
  availableClarificationStates: SelectListItem[];
  availableProductTypes: SelectListItem[];
  availableServiceAdvisors: SelectListItem[];
  availableStates: SelectListItem[];
  from: string;
  to: string;
  isManagingDealer: boolean;
  selectedDealer?: string;
  selectedProductTypes?: ProductTypeIdentifier[];
  selectedServiceAdvisor?: string;
}

export class FilterRequest {
  public from: string;
  public to: string;
  public selectedProductTypes?: ProductTypeIdentifier[];
  public selectedStatus?: string[];
  public selectedInvoiceStatus?: string[];
  public selectedClarificationStatus?: string[];
  public selectedServiceAdvisor?: string[];
  public selectedDealer?: string;
  public isManagingDealer?: boolean;
  public ticks?: string;

  constructor(
    from: string,
    to: string,
    products?: SelectListItem[],
    statuses?: SelectListItem[],
    invoiceStatuses?: SelectListItem[],
    clarificationStatuses?: SelectListItem[],
    advisors?: string[],
    selectedDealer?: string,
    isManagingDealer?: boolean,
    ticks?: string
  ) {
    this.from = from;
    this.to = to;
    this.selectedProductTypes = products?.map<ProductTypeIdentifier>(
      (product) => product.value as ProductTypeIdentifier
    );
    this.selectedStatus = statuses?.map<string>((status) => status.text);
    this.selectedInvoiceStatus = invoiceStatuses?.map<string>((status) => status.text);
    this.selectedClarificationStatus = clarificationStatuses?.map<string>((status) => status.text);
    this.selectedServiceAdvisor = advisors;
    this.selectedDealer = selectedDealer;
    this.isManagingDealer = isManagingDealer;
    this.ticks = ticks;
  }

  toHttpParams() {
    let httpParams = new HttpParams();

    if (this.from) {
      httpParams = httpParams.append('from', this.from);
    }

    if (this.to) {
      httpParams = httpParams.append('to', this.to);
    }

    this.selectedProductTypes?.forEach((product) => {
      httpParams = httpParams.append('selectedProductType', product);
    });

    this.selectedStatus?.forEach((status) => {
      httpParams = httpParams.append('selectedStatus', status);
    });

    this.selectedInvoiceStatus?.forEach((status) => {
      httpParams = httpParams.append('selectedInvoiceStatus', status);
    });

    this.selectedClarificationStatus?.forEach((status) => {
      httpParams = httpParams.append('selectedClarificationStatus', status);
    });

    this.selectedServiceAdvisor?.forEach((advisor) => {
      httpParams = httpParams.append('selectedServiceAdvisor', advisor);
    });

    if (this.selectedDealer) {
      httpParams = httpParams.append('selectedDealer', this.selectedDealer);
    }

    if (this.isManagingDealer) {
      httpParams = httpParams.append('isManagingDealer', this.isManagingDealer);
    }
    if (this.ticks) {
      httpParams = httpParams.append('ticks', this.ticks);
    }

    return httpParams;
  }
}
