import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ParseDatePipe } from '@core/pipes';
import { SelectListItem } from '@core/types/mvc.types';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { DateTime } from 'luxon';
import { DropdownOption } from '../dropdown-with-search/dropdown-with-search.types';
import { FilterCriteria, FilterForm, FilterNames, FilterParameters, FilterRequest } from './filter.types';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterComponent implements OnInit {
  /**
   * (Optional) Filter criteria.
   */
  @Input() filterCriteria!: FilterCriteria;

  /**
   * A list of Filter names that should be shown.
   */
  @Input() visibleFilterParameters!: FilterParameters[];

  /**
   * (Optional) A list of products for the product select, if they are not passed via filterCriteria.
   */
  @Input() availableProducts!: SelectListItem[];

  /**
   * (Optional) Maximum number of days that the date picker is allowed to be in the past.
   */
  @Input() datesMaximumDaysInPastCount: number = 300;

  /**
   * Fires when the Filter button was clicked.
   */
  @Output() submitFilter = new EventEmitter<FilterRequest>();

  /**
   * Fires when the product selection changed.
   */
  @Output() productSelectChange = new EventEmitter<SelectListItem[]>();

  public filterForm: FormGroup<FilterForm> = new FormGroup<FilterForm>({
    dateFrom: new FormControl(),
    dateTo: new FormControl(),
    products: new FormControl(),
    statuses: new FormControl(),
    invoiceStatuses: new FormControl(),
    clarificationStatuses: new FormControl(),
    advisors: new FormControl()
  });
  public today: DateTime = DateTime.now();
  public fromDateMinimum?: DateTime;
  public isManagingDealer = false;
  // the following are used as available values for the selects
  public productsList: SelectListItem[] = [];
  public invoiceStatusesList: SelectListItem[] = [];
  public clarificationStatusesList: SelectListItem[] = [];
  public statusesList: SelectListItem[] = [];
  public advisorsList: DropdownOption[] = [];

  constructor(private parseDatePipe: ParseDatePipe) {}

  get minDate(): DateTime {
    return (this.filterForm.value.dateFrom ?? DateTime.now()).minus({ days: 1 });
  }

  get maxDate(): DateTime {
    return DateTime.min(DateTime.now(), this.filterForm.value.dateTo ?? DateTime.now());
  }

  ngOnInit(): void {
    this.setFilterCriteria();
    // filter once when the user visits the page
    setTimeout(() => {
      this.onFilter();
    });
  }

  onFilter(): void {
    const filterRequest: FilterRequest = new FilterRequest(
      this.filterForm.value.dateFrom?.toISODate() ?? '',
      this.filterForm.value.dateTo?.toISODate() ?? '',
      this.filterForm.value.products,
      this.filterForm.value.statuses,
      this.filterForm.value.invoiceStatuses,
      this.filterForm.value.clarificationStatuses,
      this.filterForm.value.advisors,
      this.isManagingDealer ? this.filterCriteria?.selectedDealer : undefined,
      this.isManagingDealer,
      ''
    );
    this.submitFilter.emit(filterRequest);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const overElement = window.document.elementFromPoint(event.clientX, event.clientY);
    if (
      overElement?.id == 'filter-button' ||
      overElement?.parentElement?.id === 'filter-button' ||
      overElement?.parentElement?.parentElement?.id == 'filter-button'
    ) {
      // Trigger the button click event
      this.onFilter();
    }
  }

  productSelect(event: MatSelectChange) {
    this.productSelectChange.emit(event.value);
  }

  isSelectedElement(element: SelectListItem, filterName: FilterNames) {
    const elements = this.filterForm.get(filterName)?.value as SelectListItem[];
    if (!elements) return false;
    return elements.findIndex((x) => x.text === element.text) != -1;
  }

  removeFromSelectedElements(clickedElement: SelectListItem, filterName: FilterNames) {
    const elements = this.filterForm.get(filterName)?.value as SelectListItem[];
    const index = elements.findIndex((element) => element.text === clickedElement.text);
    elements.splice(index, 1);
    this.filterForm.get(filterName)?.setValue(elements);

    if (filterName === 'products') this.productSelectChange.emit(elements);
  }

  private setFilterCriteria() {
    this.isManagingDealer = this.filterCriteria?.isManagingDealer;

    if (this.availableProducts) {
      this.productsList = this.availableProducts;
    } else {
      this.productsList = this.filterCriteria?.availableProductTypes;
    }
    this.statusesList = this.filterCriteria?.availableStates;
    this.invoiceStatusesList = this.filterCriteria?.availableInvoiceStates;
    this.clarificationStatusesList = this.filterCriteria?.availableClarificationStates;
    this.advisorsList = this.filterCriteria?.availableServiceAdvisors;
    this.advisorsList?.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });

    this.fromDateMinimum = this.today.minus({ days: this.datesMaximumDaysInPastCount + 1 });

    if (this.filterCriteria?.from) {
      this.filterForm.patchValue({ dateFrom: DateTime.fromISO(this.filterCriteria.from) });
    } else {
      this.filterForm.patchValue({ dateFrom: DateTime.max(this.fromDateMinimum, this.today.minus({ month: 1 })) });
    }

    if (this.filterCriteria?.to) {
      this.filterForm.patchValue({ dateTo: DateTime.fromISO(this.filterCriteria.to) });
    } else {
      this.filterForm.patchValue({ dateTo: DateTime.now() });
    }

    if (this.filterCriteria?.selectedProductTypes) {
      this.setSelectedProducts(this.filterCriteria.selectedProductTypes);
    }

    if (this.filterCriteria?.selectedServiceAdvisor) {
      this.setSelectedServiceAdvisors([this.filterCriteria.selectedServiceAdvisor]);
    }
  }

  private setSelectedProducts(products: ProductTypeIdentifier[]) {
    const selectedProducts = this.productsList.filter((product) =>
      products.includes(product.value as ProductTypeIdentifier)
    );
    this.filterForm.patchValue({ products: selectedProducts });
  }

  private setSelectedServiceAdvisors(advisors: string[]) {
    this.filterForm.patchValue({ advisors: advisors });
  }
}
