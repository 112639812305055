import { Component } from '@angular/core';
import { UserService } from '@core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'sending-restricted',
  templateUrl: './sending-restricted.component.html',
  styleUrls: ['./sending-restricted.component.scss']
})
export class SendingRestrictedComponent {
  isSendingRestricted: Observable<boolean>;

  constructor(private userService: UserService) {
    this.isSendingRestricted = this.userService.isSendingRestricted;
  }
}
