import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { MatDatepickerModule } from '@matheo/datepicker';
import { DatePickerComponent } from './datepicker.component';

@NgModule({
  imports: [MatDatepickerModule, PipesModule, SharedModule, MatFormFieldModule, MatInputModule],
  exports: [DatePickerComponent],
  declarations: [DatePickerComponent],
  providers: []
})
export class DatePickerModule {}
