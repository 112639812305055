import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ContextDataService } from '@core/services';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { UserProfileService } from '../user-profile.service';
import { DealerCommunicationData, DealerCommunicationFormData } from './self-service.types';

@Component({
  selector: 'self-service',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.scss']
})
export class SelfServiceComponent implements OnInit, OnDestroy {
  data: DealerCommunicationData[] = [];
  formGroup!: FormGroup;
  allChecked: boolean = false;
  canSend: boolean = false;
  loading: boolean = true;
  private rowSubscriptions: Subscription[] = [];
  displayedColumns: string[] = [
    'IsActive',
    'DealerBrand',
    'Email',
    'CommunicationType',
    'Status',
    'ModifyAll',
    'LastModified',
    'ModifiedBy'
  ];

  constructor(
    private userService: UserProfileService,
    private formBuilder: FormBuilder,
    private contextDataService: ContextDataService
  ) {}

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  ngOnInit(): void {
    this.loadData();
  }

  get rows(): FormArray<FormGroup<DealerCommunicationFormData>> {
    return this.formGroup.controls['rows'] as FormArray<FormGroup<DealerCommunicationFormData>>;
  }

  applyToAll(index: number): void {
    const source = this.rows.controls[index];
    this.rows.controls.forEach((control, idx) => {
      if (this.data[idx].canChange) {
        control.patchValue(source.value);
      }
    });
  }

  toggleAll(checked: boolean): void {
    this.rows.controls.forEach((group, idx) => {
      if (this.data[idx].canChange) {
        group.controls.updateEntry.setValue(checked);
      }
    });
  }

  sendChanges(): void {
    const payload: DealerCommunicationData[] = [];
    this.loading = true;
    this.data.forEach((element, index) => {
      // cannot do this.rows.value[index] because formArray.value only contains non-disabled elements, and it would destroy the index-based match.
      const form = this.rows.controls[index].value;
      if (form.updateEntry) {
        element.requestedEmail = form.email ?? '';
        element.requestedCommunicationType = form.isEmailPreferred ? 'Email' : 'Post';
        element.modifiedDate = DateTime.utc().toISO();
        element.modifiedBy = this.contextDataService.userInfo.id;
        element.status = 'Sent';
        element.canChange = false;

        payload.push(element);
      }
    });

    this.userService.sendSelfServiceData(payload).subscribe({
      next: () => {
        this.loadData();
      },
      error: () => {
        // consider showing error message
        this.loadData();
      }
    });
  }

  private loadData(): void {
    this.userService.loadSelfServiceData().subscribe({
      next: (result) => {
        this.data = result;
        this.initializeForm();
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  private initializeForm(): void {
    this.clearSubscriptions();
    this.formGroup = this.formBuilder.group({ rows: this.formBuilder.array([]) });
    this.allChecked = false;
    for (const row of this.data) {
      const isEmailPreferred = row.requestedCommunicationType === 'Email';

      const formRow = this.formBuilder.group<DealerCommunicationFormData>({
        email: new FormControl<string | null>({ value: row.requestedEmail, disabled: true }, { updateOn: 'blur' }),
        isEmailPreferred: new FormControl<boolean | null>({ value: isEmailPreferred, disabled: true }),
        updateEntry: new FormControl<boolean | null>({ value: false, disabled: !row.canChange })
      });

      if (row.canChange) {
        this.addFormRowSubscriptions(formRow);
      }

      this.rows.push(formRow);
    }
    this.updateCanSend();
    this.checkForBrands();
  }

  /**
   * if the dealer has no brands, then we hide the brands-column
   */
  private checkForBrands(): void {
    if (this.data.every((x) => x.brands.every((b) => !b?.length))) {
      const index = this.displayedColumns.indexOf('DealerBrand');
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
  }

  private addFormRowSubscriptions(formRow: FormGroup<DealerCommunicationFormData>) {
    this.rowSubscriptions.push(
      formRow.controls.isEmailPreferred.valueChanges.subscribe((isEmailPreferred) => {
        if (isEmailPreferred && formRow.value.updateEntry) {
          formRow.controls.email.enable();
        } else {
          formRow.controls.email.disable();
        }
      })
    );

    this.rowSubscriptions.push(
      formRow.controls.updateEntry.valueChanges.subscribe((updateEntry) => {
        if (updateEntry) {
          formRow.controls.isEmailPreferred.enable();
          if (formRow.value.isEmailPreferred) {
            formRow.controls.email.enable();
          }
        } else {
          formRow.controls.email.disable();
          formRow.controls.isEmailPreferred.disable();
        }
        this.updateAllChecked();
        this.updateCanSend();
      })
    );
  }

  private updateAllChecked() {
    this.allChecked = this.rows.controls.every(
      (group) => group.value.updateEntry || group.controls.updateEntry.disabled
    );
  }

  private updateCanSend() {
    this.canSend = this.rows.controls.some((group) => !!group.value.updateEntry);
  }

  private clearSubscriptions(): void {
    this.rowSubscriptions.forEach((x) => x.unsubscribe());
  }
}
