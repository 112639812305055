import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import {
  MATERIAL_SANITY_CHECKS,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter as MaterialDateAdapter
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { RouteReuseStrategy, TitleStrategy } from '@angular/router';
import { DateAdapter } from '@matheo/datepicker/core';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@matheo/datepicker/luxon';
import { AuthConfigModule } from './auth';
import { InsightsErrorHandler } from './handlers/insights.handler';
import { InitializerService } from './initializer.service';
import { AddHeaderInterceptor } from './interceptors/add-header.interceptor';
import { AuthHeaderInterceptor } from './interceptors/auth-header.interceptor';
import { ErrorHandlingInterceptor } from './interceptors/error.interceptor';
import { HeaderSessionResponseInterceptor } from './interceptors/store-header.interceptor';
import { LuxonDateAdapterCustom } from './luxon/luxon-date-adapter.override';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { ReloadRouteReuseStrategy, TranslatedTitleStrategy } from './router';
import { DateTimeService } from './services/date-time/date-time.service';
import { TranslocoCoreModule } from './transloco/transloco.module';

export function InitializerServiceFactory(initializerService: InitializerService) {
  return () => initializerService.initialize();
}

@NgModule({
  imports: [AuthConfigModule, TranslocoCoreModule],
  exports: [AuthConfigModule, TranslocoCoreModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: ReloadRouteReuseStrategy },
    { provide: TitleStrategy, useClass: TranslatedTitleStrategy },
    { provide: ErrorHandler, useClass: InsightsErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderSessionResponseInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true
    },
    { provide: DateAdapter, useClass: LuxonDateAdapterCustom, deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS] },
    {
      provide: MaterialDateAdapter,
      useClass: LuxonDateAdapterCustom,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: DateTimeService.CUSTOM_MAT_DATE_FORMATS },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { disableTooltipInteractivity: true } },
    {
      // Disable 'theme' sanity check
      provide: MATERIAL_SANITY_CHECKS,
      useValue: {
        doctype: true,
        theme: false,
        version: true
      }
    },
    {
      // Use the 'outline' appearance on Angular Material form fields by default
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
        //hideRequiredMarker: 'true'
      }
    },
    {
      provide: APP_INITIALIZER,
      deps: [InitializerService],
      useFactory: InitializerServiceFactory,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
