import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AlertDialogResult, AlertService } from '@core/services';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { TranslocoService } from '@ngneat/transloco';
import { DashboardContractDetailsDialogService } from 'app/pages/dashboard';
import { cloneDeep, remove } from 'lodash';
import { RequestService } from '../request.service';
import {
  Question,
  Questionnaire,
  QuestionnaireGroup,
  RequestContent,
  RequestContentValidation,
  RequestStep
} from '../request.types';
import { ProcessBarStepData } from '../shared/process-bar/process-bar.types';
import { UnsavedChangesStateStore } from '../unsaved-changes-state-store.service';
import { QuestionComponent } from './question/question.component';
import { QuestionnaireData, RequestQuestionnaireAction } from './questionnaire.types';

@Component({
  selector: 'request-questionnaire',
  templateUrl: './questionnaire.component.html'
})
export class QuestionnaireComponent implements OnInit {
  @Input() requestGuid!: string;
  @Input() productType!: ProductTypeIdentifier | undefined;
  @Output() move = new EventEmitter<ProcessBarStepData>();
  @Output() validation: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() questionnaireDataChange: EventEmitter<QuestionnaireData> = new EventEmitter<QuestionnaireData>();

  @ViewChildren('questionnaireQuestion') private _questionnaireQuestions!: QuestionComponent[] | undefined;

  requestContent!: RequestContent;
  questionsForm: UntypedFormGroup = this.formBuilder.group({});
  confirmActionLoading = false;
  selectedAction!: RequestQuestionnaireAction;
  isDataLoading = true;
  private maxQuestionnaireInstance = 10;
  validationQuestionIndexRegex = /\[(\d+)\]/;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private requestService: RequestService,
    private alertService: AlertService,
    private translocoService: TranslocoService,
    private dashboardContractDetailsDialogService: DashboardContractDetailsDialogService,
    private unsavedChangesStateStore: UnsavedChangesStateStore
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.loadQuestionnaireData();
    this.questionsForm.valueChanges.subscribe(() => {
      if (this.questionsForm.dirty) {
        this.unsavedChangesStateStore.addEventMessage('Dirty');
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  showContractInformation() {
    this.dashboardContractDetailsDialogService.openDialog();
  }

  undo() {
    const confirmUndoRequestDialogRef = this.alertService.open({
      message: this.translocoService.translate('Request_Edit_UndoChanges'),
      actions: {
        confirm: {
          label: this.translocoService.translate('Common_Yes')
        },
        cancel: {
          show: true,
          label: this.translocoService.translate('Common_No')
        }
      }
    });

    confirmUndoRequestDialogRef.afterClosed().subscribe((result: AlertDialogResult) => {
      if (result === 'confirmed') {
        this.unsavedChangesStateStore.addEventMessage('Undo');
        // Reload data from API again
        this.loadQuestionnaireData();
      }
    });
  }

  save() {
    this.requestService.save(this.requestContent, RequestStep.Questionnaires, 'Save').subscribe();
  }

  executeAction(action: RequestQuestionnaireAction) {
    this.selectedAction = action;
    this.confirmActionLoading = true;
    this.clearBackendValidations();

    this.requestService.save(this.requestContent, RequestStep.Questionnaires, action).subscribe({
      next: (requestContent: RequestContent) => {
        this.unsavedChangesStateStore.addEventMessage('Saved');

        this.questionnaireDataChange.emit({
          answers: requestContent.answers,
          questionnaireGroups: requestContent.questionnaireGroups
        });

        if (action === 'Edit') {
          this.move.emit({ stepName: 'prev', requestGuid: this.requestGuid });
        }

        if (action === 'Continue') {
          this.move.emit({ stepName: 'next', requestGuid: this.requestGuid });
        }

        this.validation.emit([]);
        this.confirmActionLoading = false;
      },
      error: (res: HttpErrorResponse) => {
        this.confirmActionLoading = false;
        if (res.status !== HttpStatusCode.UnprocessableEntity) {
          return;
        }

        const response: RequestContentValidation = res.error;
        this.requestContent = response.content;

        const validationReader = new ValidationReader(response.error);
        this.validation.emit(validationReader.values);

        // Extract the index from backend validation error message which is for example: QuestionnaireAnswer[53] and assign the error message identified by (idx) to question (this._questionnaireQuestions) at extracted index (questionIndex)
        validationReader.keys.forEach((validationError, idx) => {
          const questionIndexMatch = validationError.match(this.validationQuestionIndexRegex);
          const questionIndex = questionIndexMatch ? parseInt(questionIndexMatch[1]) : undefined;
          if (questionIndex && this._questionnaireQuestions && this._questionnaireQuestions?.length > questionIndex) {
            const failedQuestion = this._questionnaireQuestions?.find((element, index) => index == questionIndex);
            if (failedQuestion) {
              failedQuestion.setBackEndValidationError(validationReader.values[idx]);
            }
          }
        });
      }
    });
  }

  clearBackendValidations() {
    this._questionnaireQuestions?.forEach((q) => q.clearBackEndValidationError());
  }

  questionnaireGroupIdentifier(_index: number, item: QuestionnaireGroup) {
    return item.keyName;
  }

  questionnaireIdentifier(_index: number, item: Questionnaire) {
    return item.keyName + '_' + item.groupId;
  }

  questionIdentifier(index: number, item: Question) {
    return item.databaseId + '_' + index;
  }

  isDisplayAddButton(questionnaireGroup: QuestionnaireGroup) {
    let showAddButton =
      questionnaireGroup.isAllowMultiple && questionnaireGroup.questionnaires.length < this.maxQuestionnaireInstance;

    if (!showAddButton) {
      return false;
    }

    const primaryQuestionnaire = questionnaireGroup.questionnaires.find((item) => item.isPrimary === true);
    if (!primaryQuestionnaire) {
      return true;
    }

    let totalQuestions = 0;
    let visibleQuestions = 0;
    primaryQuestionnaire.questions.forEach((question) => {
      const result = this._questionnaireQuestions?.find(
        (q) =>
          q.question.questionKey === question.questionKey && q.question.questionTypeKey === question.questionTypeKey
      );
      if (result) {
        totalQuestions++;
        visibleQuestions += result.isVisible ? 1 : 0;
      }
    });

    if (visibleQuestions < totalQuestions - 1) {
      showAddButton = false;
    }

    return showAddButton;
  }

  addQuestionnaireGroupItem(questionnaireGroupIndex: number) {
    const questionnaires =
      this.requestContent.questionnaireGroupsWithDistinctQuestions[questionnaireGroupIndex].questionnaires;

    const questionnaire = cloneDeep(questionnaires.find((q) => q.isPrimary));

    if (!questionnaire) {
      return;
    }
    // We always clone from the primary marked questionnaire and push the new one to the end of our list
    const groupIdOfPrimary = questionnaire.groupId;
    questionnaire.groupId = questionnaires[questionnaires.length - 1].groupId + 1;
    questionnaire.isPrimary = false;

    questionnaire.questions?.forEach((question) => {
      question.answer.groupId = questionnaire.groupId;
      /*
       During the process of adding a question we actually reset all answers/values of the original one.
       But in case a question is marked as keepValueOnClonding, we want to keep the answer value of the original question. This can for example be the case, when a question is important for the visibility of others.
       */
      if (
        question.options.additionalInformation?.subType === 'keepValueOnCloning' &&
        question.options.additionalInformation?.value === 'true'
      ) {
        // To keep the answer of the question which is marked as keepValueOnCloning, we need also to clone and related them to the new question
        this.requestContent.answers.forEach((answer) => {
          if (answer.questionId === question.databaseId && answer.groupId === groupIdOfPrimary) {
            const answerCopy = cloneDeep(answer);
            answerCopy.groupId = questionnaire.groupId;
            this.requestContent.answers.push(answerCopy);
          }
        });
        return;
      }
      question.hasAnswer = false;
      question.answer.hasValue = false;
      question.answer.value = '';
    });

    questionnaires.push(questionnaire);

    this.unsavedChangesStateStore.addEventMessage('Dirty');
  }

  removeQuestionnaireGroupItem(questionnaireGroupIndex: number, questionnaireIndex: number) {
    const questionnaires =
      this.requestContent.questionnaireGroupsWithDistinctQuestions[questionnaireGroupIndex].questionnaires;

    const removedItems = remove(questionnaires, (_item, index) => index === questionnaireIndex);

    // cleanup answers for this questionnaire group item
    removedItems.forEach((removedQuestionnaire) => {
      removedQuestionnaire.questions.forEach((question) => {
        remove(this.requestContent.answers, (answer) => {
          if (answer.questionId === question.databaseId && answer.groupId === removedQuestionnaire.groupId) {
            return true;
          }

          return false;
        });
      });
    });

    const isPrimaryRemoved = removedItems.some((item) => item.isPrimary);
    if (isPrimaryRemoved && questionnaires.length > 0) {
      questionnaires[0].isPrimary = true;
    }

    this.unsavedChangesStateStore.addEventMessage('Dirty');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private loadQuestionnaireData() {
    this.isDataLoading = true;
    this.requestService.getRequestEditData(this.requestGuid).subscribe({
      next: (res: RequestContent) => {
        this.requestContent = res;
        this.requestGuid = res.versionId;
        this.isDataLoading = false;
      }
    });
  }
}
