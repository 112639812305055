<form [formGroup]="sharedFormGroup">
  <mat-form-field
    class="w-full"
    [class.mat-form-field-invalid]="backendValidationError || formControl.errors?.length"
    *transloco="let t">
    <mat-label class="ui-test--request-input-group-mat-label-model-name">
      {{ t(labelKey || viewModelName + '_' + name) }}
    </mat-label>
    <input
      matInput
      class="ui-test--request-input-group-input-model-name"
      [class]="name | className"
      [formControlName]="name"
      [type]="inputType"
      (focus)="inputElem.select()"
      (blur)="emitStatusCheck()"
      [maxLength]="maxLength"
      #inputElem />
    <span matSuffix *ngIf="inputSuffix" class="ui-test--request-input-group-span-input-suffix">{{ inputSuffix }}</span>
    <mat-error *ngIf="formControl.errors?.['max']" class="ui-test--request-input-group-mat-error-max">
      {{t('Common_FormValidator_Max') | stringFormat: formControl.errors?.['max'].max}}
    </mat-error>
    <mat-error *ngIf="formControl.errors?.['min']" class="ui-test--request-input-group-mat-error-min">
      {{t('Common_FormValidator_Min') | stringFormat:formControl.errors?.['min'].min}}
    </mat-error>
  </mat-form-field>
</form>
