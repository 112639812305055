import { Component, Input } from '@angular/core';
import { Vehicle } from '../../dashboard.types';

@Component({
  selector: 'vehicle-information',
  templateUrl: './vehicle-information.component.html'
})
export class VehicleInformationComponent {
  @Input() vehicle?: Vehicle;
}
