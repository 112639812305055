import { Component, Input } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { Contract, Dashboard } from '../dashboard.types';

@Component({
  selector: 'dashboard-contract-details-dialog',
  templateUrl: './contract-details-dialog.component.html'
})
export class DashboardContractDetailsDialogComponent {
  @Input() dashboardData!: Dashboard;
  @Input() contract!: Contract;

  loading = true;

  constructor(private dashboardService: DashboardService) {
    this.loadDashboardData();
  }

  private loadDashboardData(): void {
    this.dashboardService.load().subscribe({
      next: (dashboardData: Dashboard) => {
        // prevent the user from creating a request in the Dialog
        dashboardData.isReadOnly = true;
        this.dashboardData = dashboardData;
        this.contract = dashboardData.contracts[dashboardData.selectedContract];
        this.loading = false;
      }
    });
  }
}
