import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventService } from '@core/services';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { InformationEntry } from './information-entry/information-entry.types';

@Injectable({
  providedIn: 'root'
})
export class InformationService {
  private _unreadCount = -1;

  constructor(private httpClient: HttpClient, private eventService: EventService) {
    this.subscribeToSignalrNotifications();
  }

  public get unreadCount() {
    if (this._unreadCount === -1) {
      this.updateUnreadCount();
      // temporarily set it to `0` to prevent further API calls
      this._unreadCount = 0;
    }
    return this._unreadCount;
  }

  public getInformation(): Observable<InformationEntry[]> {
    return this.httpClient.get<InformationEntry[]>(`${environment.isportCoreApiUrl}/api/information`);
  }

  public setInformationAsRead(informationId: number) {
    return this.httpClient.put<void>(`${environment.isportCoreApiUrl}/api/information/${informationId}/read`, null);
  }

  public getUnreadCount() {
    return this.httpClient.get<number>(`${environment.isportCoreApiUrl}/api/information/unreadCount`);
  }

  public updateUnreadCount(): void {
    this.getUnreadCount().subscribe((count: number) => (this._unreadCount = count));
  }

  private subscribeToSignalrNotifications() {
    this.eventService.setHubConnectionChangeListener('InformationUpdate', () => {
      this.updateUnreadCount();
    });
  }
}
