<div *transloco="let t">
  <img class="logo" alt="Logo" src="assets/images/wortmarke.svg" />

  <h1>{{ t('StatusOverview_Index_DetailTitle') }}</h1>
  <status-details-detail-header [statusDetails]="statusDetails"></status-details-detail-header>

  <div class="mt-15">
    <h1>{{ t('StatusDetails_Details_Title') }}</h1>
    <status-details-metadata [statusDetails]="statusDetails"></status-details-metadata>
  </div>

  <div class="mt-15" *ngIf="statusDetails.displayEventPane && printTabs.includes('events')">
    <h1>{{ t('StatusDetails_Events_Title') }}</h1>
    <status-details-events [statusDetails]="statusDetails"></status-details-events>
  </div>

  <div class="mt-15" *ngIf="statusDetails.displayPositionsPane && printTabs.includes('positions')">
    <h1>{{ t('StatusDetails_Positions_Title') }}</h1>
    <status-details-positions [statusDetails]="statusDetails"></status-details-positions>
  </div>

  <div class="mt-15" *ngIf="statusDetails.displayAnswerPane && printTabs.includes('answer')">
    <h1>{{ t('StatusDetails_QuestionAnswers_Title') }}</h1>
    <status-details-answers [statusDetails]="statusDetails"></status-details-answers>
  </div>

  <div class="mt-15" *ngIf="statusDetails.displayInvoiceHistoryPane && printTabs.includes('invoiceHistory')">
    <h1>{{ t('StatusDetails_InvoiceHistory_Title') }}</h1>
    <status-details-invoice-history [statusDetails]="statusDetails"></status-details-invoice-history>
  </div>

  <div
    class="mt-15"
    *ngIf="statusDetails.invoiceClarificationHistoryData.invoiceClarificationHistory.length > 0 && printTabs.includes('invoiceClarificationHistory')">
    <h1>{{ t('StatusDetails_InvoiceClarificationHistory_Title') }}</h1>
    <div class="mt-20">
      <h3 class="mb-10 section-title">{{ t('StatusDetails_InvoiceClarificationHistory_Title') }}</h3>
      <invoice-clarification-history
        [invoiceClarificationHistoryData]="statusDetails.invoiceClarificationHistoryData"></invoice-clarification-history>
    </div>
  </div>
</div>
