import { OverviewData } from 'app/components/overview/overview-panel/overview-panel.types';
import { SortKey } from 'app/components/sort/sort.types';

export interface OpenProcessesData extends OverviewData {
  modifiedDate: string;
  validUntil: string;
  isHighlighted: boolean;
}

export const OpenProcessesSortKeys: SortKey[] = [
  ['validUntil', 'Common_ValidUntil'],
  ['requestIdForCustomer', 'Common_RequestId'],
  ['licensePlateNumber', 'Common_LicensePlateNumber'],
  ['vehicleIdentificationNumber', 'Common_VehicleIdentificationNumber'],
  ['modelName', 'Common_ModelName'],
  ['creationDate', 'Common_Created'],
  ['serviceAdvisor', 'Common_ServiceAdvisor']
];

export const OpenProcessesFilterEntries: string[] = [
  'validUntil',
  'requestId',
  'contractNumber',
  'requestIdForCustomer',
  'customerName',
  'customerPhoneNumber',
  'licensePlateNumber',
  'vehicleIdentificationNumber',
  'modelName',
  'creationDate',
  'serviceAdvisor',
  'productType',
  'dealerName',
  'insuranceReferenceNumber',
  'workshopOrderNumber',
  'modifiedDate'
];
