import { Component, OnInit } from '@angular/core';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { UserProfileService } from '../user-profile.service';
import { CategorizedHourlyRate, DealerPrices, ProductTypesByColumn } from './categorized-hourly-rates.types';

@Component({
  selector: 'categorized-hourly-rates',
  templateUrl: './categorized-hourly-rates.component.html'
})
export class CategorizedHourlyRatesComponent implements OnInit {
  categorizedHourlyRates!: CategorizedHourlyRate;
  loading: boolean = true;
  displayedColumns: string[] = [];
  get dynamicColumns() {
    return this.displayedColumns.filter((column) => column !== 'dealer-number');
  }

  constructor(private _userProfileService: UserProfileService) {}

  ngOnInit() {
    this.loadCategorizedHourlyRates();
  }

  loadCategorizedHourlyRates() {
    this._userProfileService.loadCategorizedHourlyRate().subscribe({
      next: (res: CategorizedHourlyRate) => {
        this.categorizedHourlyRates = res;
        this.prepareDisplayColumns();
      },
      complete: () => {
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  prepareDisplayColumns() {
    this.displayedColumns = [];
    this.displayedColumns.push('dealer-number');
    this.categorizedHourlyRates.columnNames.forEach((columnName) => {
      this.displayedColumns.push(columnName);
    });
  }

  getProductTypesByColumn(columnName: string) {
    return this.categorizedHourlyRates.productTypesByColumn[
      columnName as keyof ProductTypesByColumn
    ] as ProductTypeIdentifier[];
  }

  getPrice(dealerPrice: DealerPrices, columnName: string) {
    return dealerPrice.prices.find((price) => price.priceType == columnName);
  }
}
