import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { InvoiceClarificationDetails } from './invoice-clarification.types';

@Injectable({
  providedIn: 'root'
})
export class InvoiceClarificationService {
  constructor(private httpClient: HttpClient) {}

  getInvoiceClarificationDetails(internalInvoiceNumber: string, vehicleIdentificationNumber: string) {
    return this.httpClient.get<InvoiceClarificationDetails>(
      `${environment.isportCoreApiUrl}/api/invoiceclarification/details?internalInvoiceNumber=${encodeURIComponent(
        internalInvoiceNumber
      )}&vehicleIdentificationNumber=${encodeURIComponent(vehicleIdentificationNumber)}`
    );
  }
}
