import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  ConfigurationService,
  LocaleFormatService,
  RequestEventMessage,
  RequestStateStore,
  TenantSettingsService
} from '@core/services';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { UnsavedChangesStateStore } from 'app/pages/request';
import { RequestService } from 'app/pages/request/request.service';
import { ActivityType } from 'app/pages/request/request.types';
import { WarrantyGenericPositionComponent } from '../warranty-generic-position.component';

@Component({
  selector: 'warranty-spare-part-position',
  templateUrl: './warranty-spare-part-position.component.html'
})
export class WarrantySparePartPositionComponent extends WarrantyGenericPositionComponent implements OnInit {
  constructor(
    formBuilder: UntypedFormBuilder,
    positionConfigurationService: PositionConfigurationService,
    requestService: RequestService,
    requestStore: RequestStateStore,
    unsavedChangesStateStore: UnsavedChangesStateStore,
    localeFormatService: LocaleFormatService,
    tenantSettingsService: TenantSettingsService,
    configurationService: ConfigurationService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(
      formBuilder,
      positionConfigurationService,
      requestStore,
      requestService,
      unsavedChangesStateStore,
      localeFormatService,
      tenantSettingsService,
      configurationService,
      changeDetector,
      elementRef
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.eventSubscription = this.requestStore.requestEventMessages.subscribe({
      next: (eventMessage: RequestEventMessage) => {
        if (eventMessage.eventName == 'ActivityClassTypeUpdated') {
          this.positionData.activityTypeClassKey = eventMessage.payload;
          super.updateActivityTypeClassKey();
        } else if (eventMessage.eventName == 'ActivityTypeUpdated') {
          const sparePartAt = this.findWarrantySparePartActivityType(eventMessage.payload.activityType);
          this.positionData.activityTypeKey = sparePartAt?.id as number;
          this.assignPositionUnits();
        }
      }
    });
  }

  findWarrantySparePartActivityType(parentId: number): ActivityType | undefined {
    const parentAtName = this.requestAvailableActivityTypes.find((at) => at.id === parentId)?.name;
    return this.activityTypes.find((at) => at.name == parentAtName);
  }
}
