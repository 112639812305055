<div *transloco="let t">
  <h2 class="subtitle ui-test--request-position-preview-header-position">{{ t('Common_Positions') }}</h2>

  <mat-card class="message-state warning" *ngIf="errorList && errorList.length > 0">
    <mat-card-title class="ui-test--request-position-preview-mat-card-title-plausibility">
      {{ t('Common_ValidationSummaryPlausibilityHeader') }}
    </mat-card-title>
    <mat-card-content>
      <ul>
        <li
          *ngFor="let errorItem of errorList, let i = index"
          [class]="'ui-test--request-position-preview-list-error-item-' + i">
          {{ errorItem | numericFormat }}
        </li>
      </ul>
    </mat-card-content>
  </mat-card>

  <ng-container *ngFor="let positionMap of positionsMap | keyvalue:asIsOrder, let i = index">
    <ng-container *ngIf="positionMap.value.length > 0">
      <h3 class="section-title" [class]="'ui-test--request-position-preview-header-request-position-' + i">
        {{ t('Request_Positions_' + positionMap.key + '_Headline') }}
      </h3>

      <div *ngFor="let position of positionMap.value; trackBy: positionIdentifier, let index = index">
        <positions
          [position]="position"
          [class]="'ui-test--request-position-preview-positions-position-' + index + '-requestPosition-' + i"></positions>
      </div>
    </ng-container>
  </ng-container>
</div>
