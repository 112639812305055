import { Component, ViewEncapsulation } from '@angular/core';
import { BrowserWarningDialogService } from '../browser-warning-dialog';

@Component({
  selector: 'browser-warning-button',
  templateUrl: './browser-warning-button.component.html',
  styleUrls: ['./browser-warning-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BrowserWarningButtonComponent {
  constructor(private browserWarningDialogService: BrowserWarningDialogService) {}

  onButtonClick() {
    this.browserWarningDialogService.openDialog();
  }
}
