import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ExternalApplicationAssignResult } from '@core/types';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { StatusDetailsDialogComponent } from './status-details-dialog.component';
import { StatusDetails } from './status-details-dialog.types';

@Injectable({ providedIn: 'root' })
export class StatusDetailsDialogService {
  constructor(private dialog: MatDialog, private httpClient: HttpClient) {}

  getDetails(requestGuid: string, productType: ProductTypeIdentifier, mode: string) {
    const searchParams: URLSearchParams = new URLSearchParams();
    searchParams.append('requestGuid', requestGuid);
    searchParams.append('productType', productType);
    searchParams.append('mode', mode);

    return this.httpClient.get<StatusDetails>(
      `${environment.isportCoreApiUrl}/api/statusoverview/details?${searchParams.toString()}`
    );
  }

  editExternalRequest(
    correlationId: string,
    productType: ProductTypeIdentifier
  ): Observable<ExternalApplicationAssignResult> {
    return this.httpClient.put<ExternalApplicationAssignResult>(`${environment.isportCoreApiUrl}/api/externalrequest`, {
      correlationId: correlationId,
      productType: productType
    });
  }

  openDialog(
    requestGuid: string,
    productType: ProductTypeIdentifier,
    mode: string
  ): MatDialogRef<StatusDetailsDialogComponent> {
    const statusOverviewDialog = this.dialog.open(StatusDetailsDialogComponent, {
      width: '900px',
      minHeight: '400px'
    });

    statusOverviewDialog.componentInstance.isLoading = true;
    this.getDetails(requestGuid, productType, mode).subscribe({
      next: (res: StatusDetails) => {
        statusOverviewDialog.componentInstance.statusDetails = res;
        statusOverviewDialog.componentInstance.isLoading = false;
        statusOverviewDialog.componentInstance.initializeVersionDropdown();
      }
    });
    return statusOverviewDialog;
  }
}
