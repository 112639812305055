import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '@core/directives';
import { SharedModule } from '@core/modules';
import { ScrollTopButtonComponent } from './scroll-top-button.component';

@NgModule({
  imports: [DirectivesModule, MatIconModule, MatInputModule, MatButtonModule, SharedModule],
  exports: [ScrollTopButtonComponent],
  declarations: [ScrollTopButtonComponent],
  providers: []
})
export class ScrollTopButtonModule {}
