import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { PageHeaderModule } from 'app/components/page-header';
import { InformationEntryComponent } from './information-entry/information-entry.component';
import { InformationComponent } from './information.component';

@NgModule({
  imports: [MatButtonModule, MatCardModule, MatChipsModule, MatIconModule, PageHeaderModule, PipesModule, SharedModule],
  declarations: [InformationComponent, InformationEntryComponent]
})
export class InformationModule {}
