import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExternalApplicationAssignResult } from '@core/types/application-assign.types';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ApplicationAssignData, ExtendedSearchData, GetOfferResponse } from './offer-panel.types';

@Injectable({ providedIn: 'root' })
export class OfferPanelService {
  constructor(private httpClient: HttpClient) {}

  getOffersFor(processId: string, vin: string, extendedSearchData: ExtendedSearchData): Observable<GetOfferResponse> {
    return this.httpClient.post<GetOfferResponse>(
      `${environment.isportCoreApiUrl}/api/offers/${processId}/${vin}`,
      extendedSearchData
    );
  }

  offerApplication(applicationAssignData: ApplicationAssignData): Observable<ExternalApplicationAssignResult> {
    const httpParams = this.setFilterRequestParams(applicationAssignData);

    return this.httpClient.get<ExternalApplicationAssignResult>(
      `${environment.isportCoreApiUrl}/api/offer-applications`,
      {
        params: httpParams
      }
    );
  }

  private setFilterRequestParams(applicationAssignData: ApplicationAssignData): HttpParams {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offerId', applicationAssignData.offerId ?? '');
    httpParams = httpParams.append('variantId', applicationAssignData.variantId ?? '');
    httpParams = httpParams.append('responseGuid', applicationAssignData.responseGuid ?? '');
    return httpParams;
  }
}
