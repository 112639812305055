import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

/**
 * Reference: https://moment.github.io/luxon/#/parsing?id=table-of-tokens
 */
const VALID_FORMATS: string[] = ['dd.MM.yyyy', 'd.M.yyyy', 'd.MM.yyyy', 'dd.M.yyyy'];

/**
 * Try parsing the string to a DateTime object using different valid formats.
 */
@Pipe({
  name: 'parseDate'
})
export class ParseDatePipe implements PipeTransform {
  transform(value?: string): DateTime | null {
    if (!value) {
      return null;
    }

    const parsed = DateTime.fromISO(value);

    if (parsed.isValid) {
      return parsed;
    }

    for (const format of VALID_FORMATS) {
      const dateString = value.trim().replaceAll(/\D/g, '.');
      const parsed = DateTime.fromFormat(dateString, format, { zone: 'utc' });

      if (parsed.isValid) {
        return parsed;
      }
    }

    return null;
  }
}
