import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { catchError, forkJoin, of } from 'rxjs';
import { InvoiceClarificationEditService } from './edit.service';
import { InvoiceClarification } from './edit.types';

@Injectable({ providedIn: 'root' })
export class InvoiceClarificationEditResolver
  implements Resolve<{ searchParams: URLSearchParams; invoiceClarification: InvoiceClarification }>
{
  constructor(private invoiceClarificationEditService: InvoiceClarificationEditService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot) {
    const searchParams = new URLSearchParams();
    route.queryParamMap.keys.forEach((key) => {
      searchParams.append(key, route.queryParamMap.get(key) ?? '');
    });
    const searchParams$ = of(searchParams);

    const invoiceClarification$ = this.invoiceClarificationEditService
      .getInvoiceClarification(
        searchParams.get('internalInvoiceNumber'),
        searchParams.get('productType'),
        searchParams.get('requestGuid')
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.router.navigate(['/invoice-clarification']);
          return of();
        })
      );

    return forkJoin({ searchParams: searchParams$, invoiceClarification: invoiceClarification$ });
  }
}
