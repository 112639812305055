import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserWarningButtonService } from '@core/services';
import { SnackBarStore } from '@core/services/snack-bar/snack-bar-store.service';
import { PhoneNumberVerificationDialogService } from '../phone-number-verification/phone-number-verification-dialog.service';
import { BaseLayoutComponent } from './base-layout/base-layout.component';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends BaseLayoutComponent implements OnInit {
  constructor(
    private phoneNumberVerificationDialogService: PhoneNumberVerificationDialogService,
    private snackBarStore: SnackBarStore,
    private snackBar: MatSnackBar,
    public browserWarningButtonService: BrowserWarningButtonService
  ) {
    super();
  }

  ngOnInit(): void {
    this.phoneNumberVerificationDialogService.open();

    // Snackbar messages subscriptions
    this.snackBarStore.snackBarMessages.subscribe((message) => {
      this.snackBar.open(message.message, 'X', { duration: message.showInterval });
    });
  }
}
