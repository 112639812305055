<div *transloco="let t">
  <page-header [title]="t('VehicleSelection_Index_Title')"></page-header>
  <validation-summary [errorList]="errorList"></validation-summary>

  <p>{{ t('VehicleSelection_Index_WorkInstructions') }}</p>

  <table aria-hidden="true" mat-table [dataSource]="availableVehicles" class="mat-elevation-z8 vehicle-selection-table">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ t('VehicleSelection_Index_Vehicle') }}</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="licensePlateNumbers">
      <th mat-header-cell *matHeaderCellDef>{{ t('Common_LicensePlateNumber') }}</th>
      <td mat-cell *matCellDef="let element">{{ element.licensePlateNumbers }}</td>
    </ng-container>

    <ng-container matColumnDef="dateOfFirstRegistration">
      <th mat-header-cell *matHeaderCellDef>{{ t('Common_DateOfFirstRegistration') }}</th>
      <td mat-cell *matCellDef="let element">{{ element.dateOfFirstRegistration | dateFormat }}</td>
    </ng-container>

    <ng-container matColumnDef="vehicleIdentificationNumber">
      <th mat-header-cell *matHeaderCellDef>{{ t('Common_VehicleIdentificationNumber') }}</th>
      <td mat-cell *matCellDef="let element">{{ element.vehicleIdentificationNumber }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      (click)="vehicleIdentificationNumberSelected = row.vehicleIdentificationNumber"
      [class.vehicle-selected]="vehicleIdentificationNumberSelected === row.vehicleIdentificationNumber"
      *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <button
    mat-raised-button
    color="primary"
    class="continue-button"
    [disabled]="!vehicleIdentificationNumberSelected || continueLoading"
    [class.button-spinner]="continueLoading"
    (click)="onContinue()">
    {{ t('Common_Continue') }}
  </button>
</div>
