<div class="flex-row center-center navigation" *transloco="let t">
  <div class="base-layout">
    <div class="hide-gt-xs flex-align-end-xs">
      <ul>
        <li>
          <a (click)="toggleMenu()" class="hamburger-menu ui-test--navigation-menu-link-hamburger-link">
            <mat-icon
              class="ui-test--navigation-menu-mat-icon-hamburger-link"
              aria-hidden="false"
              aria-label="Hamburger menu">
              menu_open
            </mat-icon>
          </a>
        </li>
      </ul>
    </div>
    <div class="flex-row flex-wrap" [ngClass]="isMenuOpen ? 'menu-open-xs' : 'menu-closed-xs'">
      <div class="flex flex-noshrink flex-start-gt-xs">
        <ul>
          <li>
            <a
              class="button ui-test--navigation-menu-link-contract-search"
              routerLink="/contract-search"
              routerLinkActive="active-link"
              (click)="onNavClick()">
              <mat-icon
                class="ui-test--navigation-menu-mat-icon-contract-search"
                aria-hidden="false"
                aria-label="Search icon">
                search
              </mat-icon>
              <span class="hide-sm ui-test--navigation-menu-span-contract-search-text">
                {{ t('Menu_Search_Index') }}
              </span>
            </a>
          </li>
          <li [class.disabled]="!isDashboardAccessible">
            <a
              class="button ui-test--navigation-menu-link-dashboard"
              routerLink="/dashboard"
              routerLinkActive="active-link"
              (click)="onNavClick()">
              <mat-icon
                class="ui-test--navigation-menu-mat-icon-dashboard"
                aria-hidden="false"
                aria-label="Dashboard icon">
                dashboard
              </mat-icon>
              <span class="hide-sm ui-test--navigation-menu-span-dashboard-text">{{ t('Menu_Dashboard_Index') }}</span>
            </a>
          </li>
          <li *ngIf="showStatusOverviewMenuItem">
            <a
              class="button ui-test--navigation-menu-link-status-overview"
              routerLink="/status-overview"
              routerLinkActive="active-link"
              (click)="onNavClick()">
              <mat-icon
                class="ui-test--navigation-menu-mat-icon-status-overview"
                aria-hidden="false"
                aria-label="Status icon">
                check_circle
              </mat-icon>
              <span class="hide-sm ui-test--navigation-menu-span-status-overview-text">
                {{ t('Menu_StatusOverview_Index') }}
              </span>
            </a>
          </li>
          <li>
            <a
              class="button ui-test--navigation-menu-link-open-requests"
              routerLink="/open-processes"
              routerLinkActive="active-link"
              (click)="onNavClick()">
              <mat-icon
                class="ui-test--navigation-menu-mat-icon-open-requests"
                aria-hidden="false"
                aria-label="Open processes icon">
                shopping_cart
              </mat-icon>
              <span class="hide-sm ui-test--navigation-menu-span-open-requests-text">
                {{ t('Menu_OpenRequest_Index') }}
              </span>
            </a>
          </li>
          <li *ngIf="showAftersalesMenuItem">
            <a
              class="button ui-test--navigation-menu-link-aftersales"
              routerLink="/aftersales-overview"
              routerLinkActive="active-link"
              (click)="onNavClick()">
              <mat-icon
                class="ui-test--navigation-menu-mat-icon-aftersales"
                aria-hidden="false"
                aria-label="Aftersales icon">
                euro
              </mat-icon>
              <span class="hide-sm ui-test--navigation-menu-span-aftersales-text">
                {{ t('Menu_AftersalesOverview_Index') }}
              </span>
            </a>
          </li>
          <li *ngIf="showCallMeBackMenuItem">
            <a
              class="button ui-test--navigation-menu-link-call-me-back"
              routerLink="/call-me-back"
              routerLinkActive="active-link"
              (click)="onNavClick()">
              <mat-icon
                class="ui-test--navigation-menu-mat-icon-call-me-back"
                aria-hidden="false"
                aria-label="Phone icon">
                phone
              </mat-icon>
              <span class="hide-sm ui-test--navigation-menu-span-call-me-back-text">
                {{ t('Menu_CallMeBack_Index') }}
              </span>
            </a>
          </li>
          <li *ngIf="showInvoiceClarificationMenuItem">
            <a
              class="button ui-test--navigation-menu-link-invoice-clarification"
              routerLink="/invoice-clarification"
              routerLinkActive="active-link"
              (click)="onNavClick()">
              <mat-icon
                class="ui-test--navigation-menu-mat-icon-invoice-clarification"
                aria-hidden="false"
                aria-label="Invoice icon">
                payment
              </mat-icon>
              <span class="hide-sm ui-test--navigation-menu-span-invoice-clarification-text">
                {{ t('Menu_InvoiceClarification_Index') }}
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="flex flex-noshrink flex-align-end-gt-xs">
        <ul>
          <li>
            <a
              class="button justify-center align-center ui-test--navigation-menu-link-information"
              routerLink="/information"
              routerLinkActive="active-link"
              (click)="onNavClick()">
              <mat-icon
                class="ui-test--navigation-menu-mat-icon-information"
                aria-hidden="false"
                [matBadge]="unreadInformationCount > 0 ? unreadInformationCount : null"
                aria-label="Information">
                newspaper
              </mat-icon>
              <information-bubble
                *ngIf="(informationBubbleService.showInformationBubble$ | async)?.show"
                [content]="(informationBubbleService.showInformationBubble$ | async)?.content"></information-bubble>
            </a>
          </li>
          <li>
            <a
              class="button ui-test--navigation-menu-link-inbox"
              routerLink="/inbox"
              routerLinkActive="active-link"
              (click)="onNavClick()">
              <mat-icon
                class="ui-test--navigation-menu-mat-icon-inbox"
                aria-hidden="false"
                [matBadge]="unreadMessageCount > 0 ? unreadMessageCount : null"
                aria-label="Notifications icon">
                notifications
              </mat-icon>
              <span class="hide-gt-xs ui-test--navigation-menu-span-inbox-text">{{ t('Menu_Inbox_Index') }}</span>
            </a>
          </li>
          <li>
            <a
              class="button user-navigation ui-test--navigation-menu-link-user-profile"
              routerLink="/user-profile"
              routerLinkActive="active-link"
              (click)="onNavClick()">
              <mat-icon
                class="ui-test--navigation-menu-mat-icon-user-profile"
                aria-hidden="false"
                aria-label="User info">
                person
              </mat-icon>
              <span class="ui-test--navigation-menu-span-user-id">
                {{ userId }}
                <small class="ui-test--navigation-menu-small-dealer-id">{{ dealerId }}</small>
              </span>
            </a>
          </li>
          <li>
            <a
              class="ui-test--navigation-menu-link-important-information"
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="More menu">
              <mat-icon
                class="ui-test--navigation-menu-mat-icon-important-information"
                aria-hidden="false"
                aria-label="Important information">
                more_vert
              </mat-icon>
            </a>
            <mat-menu #menu="matMenu" class="mat-menu-custom">
              <span class="important-info ui-test--navigation-menu-span-important-information">
                {{ t('Common_ImportantInformation') }}
              </span>
              <a
                class="ui-test--navigation-menu-link-legal-notice"
                mat-menu-item
                [disabled]="!legalNoticeUrl"
                [href]="legalNoticeUrl ? legalNoticeUrl : ''"
                target="_blank"
                (click)="onNavClick()">
                {{ t('Common_LegalNotice') }}
              </a>
              <a
                class="ui-test--navigation-menu-link-data-privacy-policy"
                mat-menu-item
                [disabled]="!dataPrivacyPolicyUrl"
                [href]="dataPrivacyPolicyUrl ? dataPrivacyPolicyUrl : ''"
                target="_blank"
                (click)="onNavClick()">
                {{ t('Common_DataPrivacyPolicy') }}
              </a>
              <a
                class="ui-test--navigation-menu-link-user-guide"
                mat-menu-item
                [disabled]="!userGuideUrl"
                [href]="userGuideUrl ? userGuideUrl : ''"
                target="_blank"
                (click)="onNavClick()">
                {{ t('Common_UserGuide') }}
              </a>
              <a
                class="ui-test--navigation-menu-link-common-licence"
                routerLink="/license"
                mat-menu-item
                (click)="onNavClick()">
                {{ t('CommonLicence') }}
              </a>
              <a
                class="ui-test--navigation-menu-link-dealer-damage"
                mat-menu-item
                *ngIf="dealerDamageUrl"
                [href]="dealerDamageUrl"
                target="_blank"
                (click)="onNavClick()">
                {{ t('Common_DealerDamage') }}
              </a>
              <a
                class="ui-test--navigation-menu-link-infocenter"
                mat-menu-item
                *ngIf="infocenterUrl"
                [href]="infocenterUrl"
                target="_blank"
                (click)="onNavClick()">
                {{ t('Common_Infocenter') }}
              </a>
              <a
                class="credi-2 ui-test--navigation-menu-link-credit"
                mat-menu-item
                *ngIf="credi2Url"
                [href]="credi2Url"
                target="_blank"
                (click)="onNavClick()">
                {{ t('Common_Credi2') }}
              </a>
              <a
                class="ui-test--navigation-menu-link-developer-mode"
                mat-menu-item
                routerLink="/developer-mode"
                *ngIf="isLogOnPageEnabled"
                (click)="onNavClick()">
                <span class="hide-sm ui-test--navigation-menu-span-developer-mode">Developer Mode</span>
              </a>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
