<div *transloco="let t">
  <page-header [title]="t('Menu_InvoiceClarification_Index')"></page-header>

  <div *ngIf="!submitLoading">
    <validation-summary [errorList]="errorList"></validation-summary>

    <div [class]="validationBoxClass" *ngIf="validationStatusText">
      <mat-card class="ui-test--invoice-clarification-mat-card-validation-status-text">
        {{ validationStatusText }}
      </mat-card>
    </div>
  </div>

  <div class="flex-col">
    <form
      [formGroup]="invoiceClarificationForm"
      class="flex-grow-100 flex-col justify-around content-center align-center">
      <mat-form-field>
        <mat-label class="ui-test--invoice-clarification-mat-label-internal-invoice-number">
          {{ t('InvoiceClarificationSearchViewModel_InternalInvoiceNumber') }}
        </mat-label>
        <input
          class="ui-test--invoice-clarification-input-internal-invoice-number"
          matInput
          trim="blur"
          formControlName="internalInvoiceNumber"
          name="internalInvoiceNumber" />
        <mat-hint class="ui-test--invoice-clarification-mat-hint-internal-invoice-number"></mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label class="ui-test--invoice-clarification-mat-label-vehicle-identification-number">
          {{ t('InvoiceClarificationSearchViewModel_VehicleIdentificationNumber') }}
        </mat-label>
        <input
          class="ui-test--invoice-clarification-input-vehicle-identification-number"
          matInput
          trim="blur"
          #vehicleIdentificationNumber
          formControlName="vehicleIdentificationNumber"
          name="vehicleIdentificationNumber" />
        <mat-hint align="end" class="ui-test--invoice-clarification-mat-hint-vehicle-identification-number">
          {{ vehicleIdentificationNumber.value.length || 0 }}/17
        </mat-hint>
      </mat-form-field>

      <button
        class="search-button ui-test--invoice-clarification-button-search"
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="submitLoading || invoiceClarificationForm.invalid || invoiceClarificationForm.pristine"
        [class.button-spinner]="submitLoading"
        (click)="getData()">
        <mat-icon aria-label="Search icon" class="ui-test--invoice-clarification-mat-icon-search">search</mat-icon>
        <span class="ui-test--invoice-clarification-span-search">{{ t('Search_Index_SearchButton') }}</span>
      </button>
    </form>
  </div>

  <div *ngIf="invoiceClarificationData && invoiceClarificationData.canStartClarification && showButtons" class="mt-10">
    <div class="flex-col jistify-around content-start flex-grow-100 gap-10">
      <strong class="ui-test--invoice-clarification-strong-found-invoice-clarification">
        {{ t('InvoiceClarification_FoundInvoiceClarification') }}
      </strong>
      <div class="flex-row gap-10">
        <button
          class="navigation-button delete ui-test--invoice-clarification-button-delete"
          mat-raised-button
          color="basic"
          [disabled]="submitLoading"
          [class.button-spinner]="submitLoading"
          (click)="cancelInvoiceClarification()">
          <mat-icon aria-label="Delete icon" class="ui-test--invoice-clarification-mat-icon-delete">delete</mat-icon>
          <span class="ui-test--invoice-clarification-span-cancel">
            {{ t('StatusDetails_CancelInvoiceClarification') }}
          </span>
        </button>
        <button
          class="navigation-button ui-test--invoice-clarification-button-continue"
          mat-raised-button
          color="basic"
          [disabled]="submitLoading"
          [class.button-spinner]="submitLoading"
          (click)="continueInvoiceClarification()">
          <mat-icon aria-label="Forward icon" class="ui-test--invoice-clarification-button-continue">forward</mat-icon>
          <span class="ui-test--invoice-clarification-span-continue">
            {{ t('InvoiceClarification_ContinueInvoiceClarification') }}
          </span>
        </button>
      </div>
    </div>
  </div>

  <div class="mt-20" *ngIf="invoiceClarificationData && showInvoiceClarificationHistory">
    <h2 class="mb-10 subtitle ui-test--invoice-clarification-header-invoice-clarification-history">
      {{ t('StatusDetails_InvoiceClarificationHistory_Title') }}
    </h2>
    <invoice-clarification-history
      [invoiceClarificationHistoryData]="invoiceClarificationData.invoiceClarificationHistoryData"></invoice-clarification-history>
  </div>
</div>
