import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ContextDataService } from '@core/services/context-data';
import { CorrelationIdService } from '@core/services/correlation-id';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class InsightsService {
  private appInsights: ApplicationInsights;

  constructor(
    private router: Router,
    private correlationIdService: CorrelationIdService,
    private contextDataService: ContextDataService
  ) {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router }
        },
        enableAutoRouteTracking: true
      }
    });

    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set application insights information about authenticated user context
   */
  set userId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  /**
   * Clear application insights information about authenticated user context
   */
  public clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  /**
   * Log page view into application insights
   */
  public logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  /**
   * Log exception into application insights
   */
  public logException(error: Error) {
    this.appInsights.trackException(<IExceptionTelemetry>{
      exception: error
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * The telemetry initializer with additional properties
   */
  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer((envelope) => {
      const item = envelope.baseData;
      if (item) {
        item.properties = item.properties || {};

        item.properties['Application platform'] = 'Web';
        item.properties['Correlation identifier'] = this.correlationIdService.correlationId;
        item.properties['Application identifier'] = this.contextDataService.data.appId;
        item.properties['Application name'] = 'ISPORT';
        item.properties['Tenant'] = this.contextDataService.data.tenant;
      }
    });
  }
}
