import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

/**
 * To mimic the behavior of the MVC, we want to reload components when we navigate
 * to the same URL.
 * Add the `reloadComponent` flag to every route that should be reloaded every time.
 *
 * Example:
 *   const routes: Routes = [
 *   {
 *     path: 'app',
 *     component: MyComponent,
 *     data: {
 *       reloadComponent: true
 *     }
 *   }
 *   ];
 */
export class ReloadRouteReuseStrategy extends BaseRouteReuseStrategy {
  /**
   * @returns true: Component will not be reloaded, false: Component will be reloaded
   */
  public override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig && !future.data?.reloadComponent;
  }
}
