import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { GeneralInformationComponent } from './general-information.component';
import { MileageDialogComponent } from './mileage-dialog/mileage-dialog.component';
import { MileageUpdateLoadingComponent } from './mileage-update-loading/mileage-update-loading.component';

@NgModule({
  imports: [
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatTooltipModule,
    PipesModule,
    SharedModule
  ],
  exports: [GeneralInformationComponent],
  declarations: [GeneralInformationComponent, MileageDialogComponent, MileageUpdateLoadingComponent],
  providers: []
})
export class GeneralInformationModule {}
