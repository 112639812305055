import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '@core/services';
import { InvoiceClarificationCancelSummary } from '../cancel.types';

@Component({
  selector: 'invoice-clarification-cancel-summary',
  templateUrl: './summary.component.html'
})
export class InvoiceClarificationCancelSummaryComponent {
  @Input() invoiceClarificationCancelSummary!: InvoiceClarificationCancelSummary;
  @Output() openStatusDetailsDialogClicked = new EventEmitter<void>();
  @Output() confirmClicked = new EventEmitter<void>();

  confirming = false;

  constructor(public userService: UserService) {}

  openStatusDetailsDialog(): void {
    this.openStatusDetailsDialogClicked.emit();
  }

  confirm(): void {
    this.confirming = true;
    this.confirmClicked.emit();
  }
}
