import { Component, Input } from '@angular/core';
import { QuestionAnswer } from '@core/pipes';
import { Question, Questionnaire, QuestionnaireGroup, RequestContent } from '../../request.types';

@Component({
  selector: 'request-summary-questionnaire',
  templateUrl: './questionnaire.component.html'
})
export class RequestSummaryQuestionnaireComponent {
  @Input() requestContent!: RequestContent;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getQuestionAnswerType(question: Question) {
    return <QuestionAnswer>{
      key: question.questionKey,
      typeKey: question.questionTypeKey,
      dataType: question.options.dataType,
      isMultiline: question.options.isMultiline,
      value: question.answer.value
    };
  }

  questionnaireGroupIdentifier(_index: number, item: QuestionnaireGroup) {
    return item.keyName;
  }

  questionnaireIdentifier(_index: number, item: Questionnaire) {
    return item.keyName;
  }

  questionIdentifier(_index: number, item: Question) {
    return item.databaseId;
  }
}
