import { NgModule } from '@angular/core';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { RequestTotalAmountsComponent } from './request-total-amounts.component';

@NgModule({
  imports: [SharedModule, PipesModule],
  exports: [RequestTotalAmountsComponent],
  declarations: [RequestTotalAmountsComponent],
  providers: []
})
export class RequestTotalAmountsModule {}
