<div class="contact-data mb-40" *transloco="let t">
  <h2 class="subtitle ui-test--contact-data-header-edit-contract-data">{{ t('Request_Edit_ContactData') }}</h2>

  <form [formGroup]="contactDataForm" class="flex-row justify-between gap-15">
    <div class="flex-col flex-grow-50">
      <!-- Service Advisor -->
      <div class="flex-row justify-between">
        <mat-form-field class="flex-grow-50 mr-10">
          <mat-label class="ui-test--contact-data-mat-label-first-name">
            {{ t(resourceKeyPrefix + '_FirstName') }}
          </mat-label>
          <input
            class="ui-test--contact-data-input-firstName"
            matInput
            name="firstName"
            formControlName="firstName"
            autocomplete="given-name" />
          <mat-error class="ui-test--contact-data-mat-error-first-name">
            {{ t('Common_ErrorMessageFor_NotEmpty') | hintReplacer:t(resourceKeyPrefix + '_FirstName') }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="flex flex-1">
          <mat-label class="ui-test--contact-data-mat-label-surname">{{ t(resourceKeyPrefix + '_Surname') }}</mat-label>
          <input
            class="ui-test--contact-data-input-surname"
            matInput
            name="surname"
            formControlName="surname"
            autocomplete="family-name" />
          <mat-error class="ui-test--contact-data-mat-error-surname">
            {{ t('Common_ErrorMessageFor_NotEmpty') | hintReplacer:t(resourceKeyPrefix + '_Surname') }}
          </mat-error>
        </mat-form-field>
      </div>
      <input-with-validation
        class="ui-test--contact-data-input-phone-number"
        *ngIf="contactDataForm.controls.phoneNumber"
        resourceKeyPrefix="ContactDataViewModel"
        name="phoneNumber"
        type="tel"
        [control]="contactDataForm.controls.phoneNumber"></input-with-validation>
    </div>

    <div class="flex-col flex flex-1">
      <!-- 1st Email Address -->
      <input-with-validation
        class="ui-test--contact-data-input-with-validation-first-email"
        *ngIf="contactDataForm.controls.email"
        resourceKeyPrefix="ContactDataViewModel"
        name="email"
        type="email"
        [control]="contactDataForm.controls.email"></input-with-validation>

      <!-- 2nd Email Address -->
      <input-with-validation
        class="ui-test--contact-data-input-with-validation--second-email"
        *ngIf="showSecondaryEmail && contactDataForm.controls.secondaryEmail"
        resourceKeyPrefix="ContactDataViewModel"
        name="secondaryEmail"
        type="email"
        [control]="contactDataForm.controls.secondaryEmail"
        [showHint]="true"
        [hintResource]="'SecondaryEmail_Popover_Content'"></input-with-validation>
    </div>
  </form>
</div>
