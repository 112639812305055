<div *ngIf="data" class="content">
  <mat-expansion-panel hideToggle [expanded]="isPanelExpanded" *transloco="let t">
    <mat-expansion-panel-header
      [collapsedHeight]="'100px'"
      [expandedHeight]="'100px'"
      [class.mat-expansion-panel-highlighted]="data.isHighlighted"
      class="disable_ripple">
      <mat-panel-title class="flex-grow-25">
        <div class="flex-grow-100">
          <div>
            <mat-icon class="ui-test--overview-panel-mat-icon-product-icon">
              {{ data.productType | productTypeIcon }}
            </mat-icon>
            <mat-label class="dms-label ui-test--overview-panel-mat-label-dms-label" *ngIf="isDMS">
              {{ t('Common_DmsLabel') }}
            </mat-label>
            <span *ngIf="data.requestIdForCustomer !== ''" class="request-id ui-test--overview-panel-span-request-id">
              {{ data.requestIdForCustomer }}
            </span>
            <span *ngIf="!data.requestIdForCustomer" class="request-id ui-test--overview-panel-span-request-id-empty">
              &#8211;
            </span>
          </div>
          <request-state-label
            [state]="data.requestState"
            [stateText]="data.requestStateText"
            class="status-overview-box ui-test--overview-panel-request-state-label-state"></request-state-label>
          <div class="flex-row">
            <invoice-state-label
              *ngIf="hasInvoiceState"
              class="flex-grow-50 invoice-label ui-test--overview-panel-invoice-state-label-state-left"
              [invoiceState]="invoiceState"
              [stateText]="invoiceStateText"
              [titleLabelKey]="'Common_InvoiceStateTitleLabel'"></invoice-state-label>
            <invoice-state-label
              *ngIf="hasRightInvoiceState"
              class="flex-grow-50 invoice-label ui-test--overview-panel-invoice-state-label-state-right"
              [invoiceState]="rightInvoiceState"
              [stateText]="rightInvoiceStateText"
              [titleLabelKey]="rightInvoiceStateTitleLabelKey"></invoice-state-label>
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-title class="flex-col justify-center content-between align-stretch flex-grow-17">
        <div class="flex-row justify-center content-evenly flex-grow-100">
          <strong class="flex flex-grow-50 ui-test--overview-panel-strong-created-label">
            {{ t('Common_Created') }}:
          </strong>
          <div class="flex-grow-50 ui-test--overview-panel-div-created-date">{{ data.creationDate | dateFormat }}</div>
        </div>
        <div class="flex-row justify-center content-evenly flex-grow-100" *ngIf="hasDateOfUpdate">
          <strong class="flex flex-grow-50 ui-test--overview-panel-strong-update-label">
            {{ t('Common_Updated') }}:
          </strong>
          <div class="flex-grow-50 ui-test--overview-panel-div-update-date">{{ dateOfUpdate | dateFormat }}</div>
        </div>
        <div class="flex-row justify-center content-evenly flex-grow-100" *ngIf="hasValidUntil">
          <strong class="flex flex-grow-50 ui-test--overview-panel-strong-validLabel">
            {{ t('Common_ValidUntil') }}:
          </strong>
          <div class="flex-grow-50 ui-test--overview-panel-div-valid-date">{{ validUntil | dateFormat }}</div>
        </div>
        <div class="flex-row justify-center content-evenly flex-grow-100">
          <div class="flex-grow-50"></div>
          <div class="flex-grow-50"></div>
        </div>
      </mat-panel-title>
      <mat-panel-title class="flex-col justify-center content-between align-stretch flex-grow-41">
        <div class="flex-row justify-center content-evenly flex-grow-100">
          <strong class="flex flex-grow-50 ui-test--overview-panel-strong-service-advisor-label">
            {{ t('Common_ServiceAdvisor') }}:
          </strong>
          <div class="flex-grow-50 service-advisor ui-test--overview-panel-div-service-advisor">
            {{ data.serviceAdvisor }}
          </div>
        </div>
        <div class="flex-row justify-center content-evenly flex-grow-100">
          <strong class="flex flex-grow-50 ui-test--overview-panel-strong-license-plate-number-label">
            {{ t('Common_LicensePlateNumber') }}:
          </strong>
          <div class="flex-grow-50 ui-test--overview-panel-div-license-plate-number">{{ data.licensePlateNumber }}</div>
        </div>
        <div class="flex-row justify-center content-evenly flex-grow-100">
          <strong class="flex flex-grow-50 ui-test--overview-panel-strong-workshop-order-number-label">
            {{ t('Common_WorkshopOrderNumber') }}:
          </strong>
          <div class="flex-grow-50 ui-test--overview-panel-div-workshop-order-number">
            {{ data.workshopOrderNumber }}
          </div>
        </div>
        <div *ngIf="isManagingDealer" class="flex-row justify-center content-evenly flex-grow-100">
          <strong class="flex flex-grow-50 ui-test--overview-panel-strong-dealer-number-label">
            {{ t('AccountLogOnViewModel_Dealer') }}:
          </strong>
          <div class="flex-grow-50 ui-test--overview-panel-div-dealer-number">{{ data.dealerName }}</div>
        </div>
      </mat-panel-title>
      <mat-panel-title class="flex-row flex-grow-17">
        <div class="flex-row flex-grow-100">
          <div class="flex-grow-25">
            <mat-icon
              class="ui-test--overview-panel-mat-icon-new-attachment"
              *ngIf="data.hasNewDocument"
              aria-hidden="false"
              aria-label="attachments icon">
              attach_file
            </mat-icon>
          </div>
          <div class="flex-grow-25">
            <mat-icon
              class="isport-mail-icon ui-test--overview-panel-mat-icon-new-message"
              *ngIf="data.hasNewMessages"
              aria-hidden="false"
              aria-label="new messages icon">
              mail
            </mat-icon>
          </div>
          <div class="flex-grow-50">
            <button
              mat-stroked-button
              class="ui-test--overview-panel-button-status-details"
              color="primary"
              (click)="openStatusDetailsDialog($event, data.requestGuid, data.productType)">
              <mat-icon
                class="ui-test--overview-panel-mat-icon-status-details"
                aria-hidden="false"
                aria-label="Status icon">
                assignment
              </mat-icon>
            </button>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex justify-center content-between flex-grow-100 align-stretch pt-10">
      <div class="flex-col flex-grow-50 gap-10">
        <div class="flex-row justify-center content-stretch align-stretch flex-grow-100">
          <strong class="flex flex-grow-30 ui-test--overview-panel-strong-license-plate-number-label">
            {{ t('Common_LicensePlateNumber') }}:
          </strong>
          <div class="flex-grow-70 ui-test--overview-panel-div-license-plate-number">{{ data.licensePlateNumber }}</div>
        </div>
        <div class="flex-row justify-center content-evenly align-stretch flex-grow-100">
          <strong class="flex flex-grow-30 ui-test--overview-panel-strong-vehicle-identification-number-label">
            {{ t('Common_VehicleIdentificationNumber') }}:
          </strong>
          <div class="flex-grow-70 ui-test--overview-panel-div-vehicle-identification-number">
            {{ data.vehicleIdentificationNumber }}
          </div>
        </div>
        <div class="flex-row justify-center content-evenly align-stretch flex-grow-100">
          <strong class="flex flex-grow-30 ui-test--overview-panel-strong-model-name-label">
            {{ t('Common_ModelName') }}:
          </strong>
          <div class="flex-grow-70 ui-test--overview-panel-div-model-name">{{ data.modelName }}</div>
        </div>
      </div>
      <div class="flex-col flex-grow-50">
        <div class="flex-row justify-center content-evenly align-stretch flex-grow-100">
          <strong class="flex flex-grow-30 ui-test--overview-panel-strong-customer-label">
            {{ t('Common_Customer') }}:
          </strong>
          <div class="flex-grow-70 ui-test--overview-panel-div-customer">{{ data.customerName }}</div>
        </div>
        <div class="flex-row justify-center content-evenly align-stretch flex-grow-100">
          <strong class="flex flex-grow-30 ui-test--overview-panel-strong-contract-number-label">
            {{ t('Common_ContractNumber') }}:
          </strong>
          <div class="flex-grow-70 ui-test--overview-panel-div-contract-number">{{ data.contractNumber }}</div>
        </div>
        <div class="flex-row justify-center content-evenly align-stretch flex-grow-100">
          <strong class="flex flex-grow-30 ui-test--overview-panel-strong-phone-number-label">
            {{ t('Common_PhoneNumber') }}:
          </strong>
          <div class="flex-grow-70 ui-test--overview-panel-div-phone-number">{{ data.customerPhoneNumber }}</div>
        </div>
        <div
          class="flex-row justify-center content-evenly align-stretch flex-grow-100"
          *ngIf="data.isInsuranceReferenceNumberActive">
          <strong class="flex flex-grow-30 ui-test--overview-panel-strong-insurance-reference-number-label">
            {{ t('Common_InsuranceReferenceNumber') }}:
          </strong>
          <div class="flex-grow-70 ui-test--overview-panel-div-insurance-reference-number">
            {{ data.insuranceReferenceNumber }}
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
