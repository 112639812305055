import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { PHONE_NUMBER_REGEX } from '@core/constants';
import { ContactData, RequestEditOptions } from '../../pages/request/request.types';
import { ContactDataForm } from './contract-data.types';

@Component({
  selector: 'contact-data',
  templateUrl: './contact-data.component.html'
})
export class ContactDataComponent implements OnInit {
  @Input() contactData!: ContactData;
  @Input() editOptions: RequestEditOptions = 'Full';
  @Input() showSecondaryEmail: boolean = false;
  @Input() disableSecondaryEmail: boolean = false;
  @Input() resourceKeyPrefix: string = 'ContactDataViewModel';

  contactDataForm = this.formBuilder.group<ContactDataForm>({
    firstName: new FormControl({ value: '', disabled: true }, Validators.required),
    surname: new FormControl({ value: '', disabled: true }, Validators.required),
    phoneNumber: new FormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.maxLength(20),
      Validators.pattern(PHONE_NUMBER_REGEX)
    ]),
    email: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
    secondaryEmail: new FormControl({ value: '', disabled: false }, [Validators.email, Validators.maxLength(255)])
  });

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    // Populate form values
    this.contactDataForm.patchValue({
      firstName: this.contactData.firstName,
      surname: this.contactData.surname,
      phoneNumber: this.contactData.phoneNumber,
      email: this.contactData.email,
      secondaryEmail: this.contactData.secondaryEmail
    });
    if (this.editOptions == 'Full') {
      this.contactDataForm.get('phoneNumber')?.enable();

      if (!this.disableSecondaryEmail) {
        this.contactDataForm.get('secondaryEmail')?.enable();
      }
    }
  }
}
