<ng-container *transloco="let t">
  <!-- Work instructions component -->
  <work-instructions
    step="Request_Edit"
    [currentProductType]="requestContent.requestProcess.editProductType !== 'Unknown' ? requestContent.requestProcess.editProductType : requestContent.requestProcess.productType"></work-instructions>

  <!-- General information component -->
  <mat-divider class="my-10"></mat-divider>
  <request-general-information
    [requestContent]="requestContent"
    [isDisplayOnly]="false"
    (mileageUpdate)="mileageUpdate.emit($event)"
    (deductibleAmountUpdate)="updateDeductibleAmount()"></request-general-information>

  <!-- Payment component -->
  <ng-container
    *ngIf="requestContent.paymentInformationData && requestContent.paymentInformationData.outstandingPayments && requestContent.paymentInformationData.outstandingPayments.length > 0">
    <payment-choose-panel
      [paymentInformation]="requestContent.paymentInformationData"
      [productType]="requestContent.requestProcess.editProductType"
      (sendPayment)="sendPaymentInformation()"
      (updatePayment)="checkShowPositions()"></payment-choose-panel>
  </ng-container>

  <!-- Positions component -->
  <div class="mb-20" *ngIf="shouldShowPositions">
    <mat-divider class="my-10"></mat-divider>
    <positions-edit [requestContent]="requestContent"></positions-edit>

    <!-- Filters -->
    <ng-container *ngIf="requestContent.requestProcess.editProductType === 'MotorInsurance'">
      <claim-position-filter [requestContent]="requestContent"></claim-position-filter>
    </ng-container>
  </div>

  <!-- Leasing return confirmation -->
  <ng-container *ngIf="requestContent.showLeasingReturnWarning">
    <mat-divider class="my-15"></mat-divider>
    <leasing-return-confirmation [requestContent]="requestContent"></leasing-return-confirmation>
  </ng-container>

  <scroll-top-button></scroll-top-button>

  <!-- Footer actions-->
  <mat-divider class="my-10"></mat-divider>
  <div class="flex-row justify-end content-center">
    <button
      mat-stroked-button
      class="mr-5 ui-test--request-edit-button-undo"
      *ngIf="requestContent.displayUndoButton"
      (click)="processEditUndo()"
      [disabled]="actionLoading || isUnresolvedOutstandingPayment"
      [class.button-spinner]="actionLoading && selectedAction === 'Undo'">
      <mat-icon class="ui-test--request-edit-mat-icon-undo">undo</mat-icon>
      {{ t('Request_Edit_Undo') }}
    </button>

    <ng-container *ngIf="requestContent.isDisplayStartMobilityRequestButton">
      <button
        mat-stroked-button
        class="mr-5 ui-test--request-edit-button-mobility"
        *ngIf="requestContent.requestProcess.editProductType !== 'Mobility'; else isMobilityIdentifier"
        [disabled]="actionLoading || isUnresolvedOutstandingPayment"
        [class.button-spinner]="actionLoading && selectedAction === 'Add'"
        (click)="addPositions('Mobility')">
        <mat-icon class="ui-test--request-edit-mat-icon-mobility">{{ 'Mobility' | productTypeIcon }}</mat-icon>
        {{ t('Common_RequestAddMobilityPositions') }}
      </button>

      <ng-template #isMobilityIdentifier>
        <button
          mat-stroked-button
          class="mr-5 ui-test--request-edit-button-product-type"
          [disabled]="actionLoading || isUnresolvedOutstandingPayment"
          [class.button-spinner]="actionLoading && selectedAction === 'Add'"
          (click)="addPositions(requestContent.requestProcess.productType)">
          <mat-icon class="ui-test--request-edit-mat-icon-product-type">
            {{ requestContent.requestProcess.productType | productTypeIcon }}
          </mat-icon>
          {{ t('Common_RequestAdd' + requestContent.requestProcess.productType + 'Positions') }}
        </button>
      </ng-template>
    </ng-container>

    <button
      mat-stroked-button
      class="mr-5 ui-test--request-edit-button-save"
      [disabled]="actionLoading || isSaveDisabled()"
      [class.button-spinner]="actionLoading && selectedAction === 'Save'"
      (click)="save()">
      <mat-icon class="ui-test--request-edit-mat-icon-save">save</mat-icon>
      {{ t('Request_Edit_Save') }}
    </button>

    <button
      mat-raised-button
      class="continue-button ui-test--request-edit-button-continue"
      color="primary"
      [disabled]="actionLoading || isContinueDisabled()"
      [class.button-spinner]="actionLoading && selectedAction === 'Continue'"
      (click)="continue()">
      <mat-icon class="ui-test--request-edit-mat-icon-continue">skip_next</mat-icon>
      {{ t('Request_Edit_Continue') }}
    </button>
  </div>
</ng-container>
