<ng-container *transloco="let t">
  <ul class="timeline timeline-centered">
    <li
      *ngFor="let invoiceClarification of invoiceClarificationHistoryData.invoiceClarificationHistory; let i = index"
      [ngClass]="invoiceClarification.isFromResponse ? 'timeline-inverted' : ''">
      <mat-icon class="timeline-badge" [class]="'ui-test-invoice-clarification-history-mat-icon-icon' + i">
        {{ invoiceClarification.isFromResponse ? 'support_agent' : 'person' }}
      </mat-icon>
      <mat-card
        class="invoice-clarification-history-card mb-10 timeline-panel"
        [class]="'ui-test-invoice-clarification-history-mat-card-invoice-clarification' + i">
        <mat-card-title
          [class]="'ui-test-invoice-clarification-history-mat-card-title-title-invoice-clarification-number' + i">
          {{ invoiceClarification.invoiceClarificationNumber }}
        </mat-card-title>
        <mat-card-subtitle
          class="flex-row justify-start content-center"
          [class]="'ui-test-invoice-clarification-history-mat-card-subtitle-title-invoice-clarification-number' + i">
          <mat-icon [class]="'ui-test-invoice-clarification-history-mat-icon-today' + i">today</mat-icon>
          {{ invoiceClarification.transmissionDate | dateFormat:'DATETIME_SHORT_WITH_SECONDS' }}
        </mat-card-subtitle>
        <mat-card-content
          class="timeline-body"
          [class]="'ui-test-invoice-clarification-history-mat-card-content-content' + i">
          <invoice-state-label
            [invoiceState]="invoiceClarification.invoiceClarificationState"
            [class]="'ui-test-invoice-clarification-history-invoice-state-label-invoice-state-label' + i"></invoice-state-label>
          <ul>
            <li
              *ngFor="let invoiceClarificationNote of invoiceClarification.invoiceClarificationNotes; let ii = index"
              [innerHtml]="invoiceClarificationNote"
              [class]="'ui-test-invoice-clarification-history-list' + i + '-listElement' + ii"></li>
          </ul>
        </mat-card-content>
      </mat-card>
    </li>
  </ul>
</ng-container>
