import { Pipe, PipeTransform } from '@angular/core';
import { LocaleFormatService } from '@core/services';
import { stringFormat } from '@core/utils';
import { TranslocoService } from '@ngneat/transloco';
import { QuestionAnswer } from './answer-render.types';
import { DateFormatPipe } from './luxon-date.pipe';
import { Nl2brPipe } from './nl2br.pipe';

@Pipe({
  name: 'answerRender'
})
export class AnswerRenderPipe implements PipeTransform {
  constructor(
    private translocoService: TranslocoService,
    private nl2brPipe: Nl2brPipe,
    private luxonDatePipe: DateFormatPipe,
    private localeFormatService: LocaleFormatService
  ) {}

  transform(questionAnswer: QuestionAnswer): string {
    switch (questionAnswer.dataType) {
      case 'Boolean':
        if (questionAnswer.value === 'true' || questionAnswer.value === 'false') {
          return this.translocoService.translate(questionAnswer.value === 'true' ? 'Common_Yes' : 'Common_No');
        }
        break;
      case 'Numeric': {
        return this.localeFormatService.formatNumericString(questionAnswer.value, {
          fractionDigits: 2
        });
      }
      case 'VehicleDamageParts':
      case 'MultiSelectionBox': {
        const keyValue = questionAnswer.value
          .split(',')
          .map((item) =>
            this.translocoService.translate(
              stringFormat(
                'Request_Questionnaires_Answer_{0}_{1}_{2}',
                questionAnswer.typeKey,
                questionAnswer.key,
                item
              )
            )
          );
        return keyValue.join('<br />');
      }
      case 'SelectionBox':
        return this.translocoService.translate(
          stringFormat(
            'Request_Questionnaires_Answer_{0}_{1}_{2}',
            questionAnswer.typeKey,
            questionAnswer.key,
            questionAnswer.value
          )
        );
      case 'DateTime':
        return this.luxonDatePipe.transform(questionAnswer.value, 'DATE_SHORT', false);
      case 'Time':
        return this.luxonDatePipe.transform(questionAnswer.value, 'TIME_24_SIMPLE', false);
      case 'Text':
      default:
        if (questionAnswer.isMultiline) {
          return this.nl2brPipe.transform(questionAnswer.value);
        }
        return questionAnswer.value;
    }
    return '';
  }
}
