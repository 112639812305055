import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { ActivityTypeIdentifierCommon } from 'app/components/positions';
import { RequestProcessStateCommon } from 'app/components/request-state-label/request-state-label.types';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { CancelRequestSummary } from './cancel/cancel.types';
import { RequestConfirmation } from './confirmation/confirmation.types';
import { PaymentDecision } from './edit';
import { PositionsComponentServiceHelper } from './edit/positions/positions.types';
import { OnlineInvoiceConfirmation, RequestInvoiceSummary } from './online-invoice/online-invoice.types';
import { RequestContent, RequestStep, StartRequestParameters } from './request.types';
import { AssignedQuestionnaireProductType } from './shared/process-bar/process-bar.types';
import { RequestSummary } from './summary/summary.types';

@Injectable({ providedIn: 'root' })
export class RequestService {
  constructor(private httpClient: HttpClient) {}

  startNewRequest(params: ParamMap) {
    const startParams: StartRequestParameters = {
      contractDatabaseId: (params.get('contractDatabaseId') ?? -1) as number,
      outstandingPayment: params.get('outstandingPayment'),
      paymentOption: params.get('paymentOption'),
      productType: (params.get('productType') as ProductTypeIdentifier) ?? 'Unknown',
      referencedRequestGuid: params.get('referencedRequestGuid'),
      subtype: params.get('subtype'),
      vin: params.get('vin') ?? ''
    };

    return this.httpClient.post<RequestContent>(`${environment.isportCoreApiUrl}/api/request/create`, startParams);
  }

  confirmAction(requestSummary: RequestSummary, action: string) {
    const searchParams = new URLSearchParams();
    searchParams.append('confirmAction', action);

    return this.httpClient.post(
      `${environment.isportCoreApiUrl}/api/request/summary?${searchParams.toString()}`,
      requestSummary
    );
  }

  getRequestEditData(requestGuid: string, editProductType: ProductTypeIdentifier | undefined = undefined) {
    const searchParams = new URLSearchParams();
    searchParams.append('requestGuid', requestGuid);

    if (editProductType) {
      searchParams.append('editProductType', editProductType);
    }

    return this.httpClient.get<RequestContent>(
      `${environment.isportCoreApiUrl}/api/request/details?${searchParams.toString()}`
    );
  }

  getRequestSummaryData(requestGuid: string, productType: ProductTypeIdentifier | undefined, vin: string | undefined) {
    const searchParams = new URLSearchParams();
    searchParams.append('requestGuid', requestGuid);

    if (productType) {
      searchParams.append('editProductType', productType);
    }
    if (vin) {
      searchParams.append('vin', vin);
    }

    return this.httpClient.get<RequestSummary>(
      `${environment.isportCoreApiUrl}/api/request/summary?${searchParams.toString()}`
    );
  }

  cancelRequest(requestGuid: string) {
    const searchParams = new URLSearchParams();
    searchParams.append('requestGuid', requestGuid);

    return this.httpClient.delete(`${environment.isportCoreApiUrl}/api/request/cancel?${searchParams.toString()}`);
  }

  getCancelRequestData(requestGuid: string) {
    const searchParams = new URLSearchParams();
    searchParams.append('requestGuid', requestGuid);

    return this.httpClient.get<CancelRequestSummary>(
      `${environment.isportCoreApiUrl}/api/request/cancel?${searchParams.toString()}`
    );
  }

  getRequestInvoiceData(requestGuid: string) {
    const searchParams = new URLSearchParams();
    searchParams.append('requestGuid', requestGuid);

    return this.httpClient.get<RequestInvoiceSummary>(
      `${environment.isportCoreApiUrl}/api/onlineinvoice?${searchParams.toString()}`
    );
  }

  confirmRequestInvoice(requestInvoiceSummary: RequestInvoiceSummary): Observable<OnlineInvoiceConfirmation> {
    return this.httpClient.post<OnlineInvoiceConfirmation>(
      `${environment.isportCoreApiUrl}/api/onlineinvoice`,
      requestInvoiceSummary
    );
  }

  getConfirmationData(requestGuid: string, mode?: RequestProcessStateCommon) {
    const searchParams = new URLSearchParams();
    searchParams.append('requestGuid', requestGuid);

    if (mode) {
      searchParams.append('mode', mode);
    }

    return this.httpClient.get<RequestConfirmation>(
      `${environment.isportCoreApiUrl}/api/request/confirmation?${searchParams.toString()}`
    );
  }

  sendPaymentInformation(paymentDecision: PaymentDecision): Observable<void> {
    return this.httpClient.post<void>(`${environment.isportCoreApiUrl}/api/payments`, paymentDecision);
  }

  getProductTypeQuestionnaire(tenantName: string) {
    return this.httpClient.get<AssignedQuestionnaireProductType[]>(
      `${environment.isportCoreApiUrl}/api/settings/questionnaire?tenantName=${tenantName}`
    );
  }

  triggerContractSearch(requestContent: RequestContent) {
    return this.httpClient.post<any>(
      `${environment.isportCoreApiUrl}/api/process/request/context`,
      JSON.stringify(requestContent)
    );
  }

  deleteRequest(requestGuid: string) {
    return this.httpClient.delete(`${environment.isportCoreApiUrl}/api/openrequest/request/${requestGuid}`);
  }

  addPosition(
    requestContent: RequestContent,
    type: ActivityTypeIdentifierCommon | undefined = undefined,
    activityTypeId: number | undefined = undefined,
    activityTypeClassId: number | undefined = undefined
  ) {
    // TODO: Review this logic once more time and compare data sent to the backend
    const currentObject = { ...requestContent };
    currentObject.requestPositions.forEach((position) => {
      position.isOld = true;
    });

    const params = new URLSearchParams();
    if (type) {
      params.append('type', type);
    }

    if (activityTypeId && type === 'ClaimPosition') {
      params.append('id', activityTypeId.toString());
    }

    if (activityTypeClassId && type === 'ClaimPosition') {
      params.append('atcId', activityTypeClassId.toString());
    }

    return this.httpClient.post<any>(
      `${environment.isportCoreApiUrl}/api/process/request/position/add?${params.toString()}`,
      JSON.stringify(currentObject, PositionsComponentServiceHelper.replacer)
    );
  }

  addPositionTo(
    requestContent: RequestContent,
    index: number,
    type: ActivityTypeIdentifierCommon | undefined = undefined
  ) {
    // TODO: Review this logic once more time and compare data sent to the backend
    const currentObject = { ...requestContent };
    currentObject.requestPositions.forEach((position) => {
      position.isOld = true;
    });
    currentObject.activityTypesFilter = [];

    const params = new URLSearchParams();
    if (type) {
      params.append('type', type);
    }

    return this.httpClient.post<any>(
      `${environment.isportCoreApiUrl}/api/process/request/position/add/${index}?${params.toString()}`,
      JSON.stringify(currentObject, PositionsComponentServiceHelper.replacer)
    );
  }

  enrichPosition(requestContent: RequestContent) {
    const currentObject = { ...requestContent };
    currentObject.requestPositions.forEach((position) => {
      position.isOld = true;
    });

    return this.httpClient.post<any>(
      `${environment.isportCoreApiUrl}/api/process/request/position/positions`,
      JSON.stringify(currentObject, PositionsComponentServiceHelper.replacer)
    );
  }

  save(requestContent: RequestContent, stepName: RequestStep = RequestStep.None, continueAction: string = 'Continue') {
    let url = `${environment.isportCoreApiUrl}/api/request/save`;
    let queryParams = new HttpParams();
    if (stepName != RequestStep.None) {
      queryParams = queryParams.append('stepName', RequestStep[stepName]);
    }
    if (continueAction != '') {
      queryParams = queryParams.append('continueAction', continueAction);
    }

    if (queryParams.toString() != '') {
      url += '?' + queryParams.toString();
    }
    return this.httpClient.post<RequestContent>(url, requestContent);
  }

  // TODO: Use when PATCH support is implemented in backend.
  // update(
  //   originalRequestContent: RequestContent,
  //   updatedRequestContent: RequestContent,
  //   stepName: RequestStep = RequestStep.None
  // ) {
  //   let url = `${environment.isportCoreApiUrl}/api/request/update?requestVersionId=${updatedRequestContent.versionId}`;
  //   if (stepName != RequestStep.None) {
  //     url += `?stepName=${RequestStep[stepName]}`;
  //   }
  //   const patchContent = createPatch(originalRequestContent, updatedRequestContent);
  //   return this._httpClient.patch<RequestContent>(url, patchContent);
  // }
}
