import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterCriteria, FilterRequest } from 'app/components/filter/filter.types';
import { OverviewService } from 'app/components/overview/overview.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { StatusOverviewData } from './status-overview.types';

@Injectable({ providedIn: 'root' })
export class StatusOverviewService extends OverviewService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getData(request: FilterRequest): Observable<StatusOverviewData[]> {
    const url = `${environment.isportCoreApiUrl}/api/statusoverview/overview?`;
    return this.httpClient.get<StatusOverviewData[]>(url, { params: request.toHttpParams() });
  }

  getFilterCriteria(): Observable<FilterCriteria> {
    const url = `${environment.isportCoreApiUrl}/api/statusoverview/filtercriteria?`;
    return this.httpClient.get<FilterCriteria>(url);
  }
}
