import { Route } from '@angular/router';
import { RedirectOnParamsGuard } from '@core/guards';
import { AuthGuard } from '@core/guards/auth.guard';
import { BaseLayoutComponent } from './components/layout/base-layout/base-layout.component';
import { LayoutComponent } from './components/layout/layout.component';
import { AftersalesOverviewComponent } from './pages/aftersales-overview/aftersales-overview.component';
import { CallMeBackComponent } from './pages/call-me-back/call-me-back.component';
import { ContractSearchComponent } from './pages/contract-search/contract-search.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DeveloperModeComponent } from './pages/developer-mode/developer-mode.component';
import { ForbiddenComponent } from './pages/error/forbidden/forbidden.component';
import { PageNotFoundComponent } from './pages/error/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './pages/error/unauthorized/unauthorized.component';
import { InboxComponent } from './pages/inbox/inbox.component';
import { InformationComponent } from './pages/information/information.component';
import { LicenseComponent } from './pages/license/license.component';
import { OpenProcessesComponent } from './pages/open-processes/open-processes.component';
import { RequestDuplicatesComponent } from './pages/request-duplicates/request-duplicates.component';
import { RequestDuplicatesResolver } from './pages/request-duplicates/request-duplicates.resolver';
import { StatusOverviewComponent } from './pages/status-overview/status-overview.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { VehicleSelectionComponent } from './pages/vehicle-selection/vehicle-selection.component';
import { INVOICE_CLARIFICATION_ROUTES } from './routing/invoice-clarification.routes';
import { REQUEST_ROUTES } from './routing/request.routes';

export const appRoutes: Route[] = [
  // Redirect empty path to '/contract-search'
  { path: '', pathMatch: 'full', redirectTo: 'contract-search' },

  // Public routes
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      { path: 'Error/Forbidden', redirectTo: 'forbidden' },
      {
        path: 'forbidden',
        component: ForbiddenComponent
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent
      },
      // TODO: Redirect Route from IBA. Consider changing this in the future
      { path: 'iSportCoreFE/Error/Forbidden', redirectTo: 'forbidden' }
    ]
  },

  // Protected routes
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'contract-search' },
      { path: 'Account/GenerateSession', pathMatch: 'full', redirectTo: 'contract-search' },
      {
        path: 'contract-search',
        component: ContractSearchComponent,
        canActivate: [RedirectOnParamsGuard],
        data: {
          reloadComponent: true
        },
        title: 'Search_Index_Title'
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          reloadComponent: true
        },
        title: 'PageTitle_Dashboard'
      },
      {
        path: 'request-duplicates',
        component: RequestDuplicatesComponent,
        resolve: [RequestDuplicatesResolver]
      },
      {
        path: 'vehicle-selection',
        component: VehicleSelectionComponent,
        title: 'VehicleSelection_Index_Title'
      },
      {
        path: 'request',
        children: REQUEST_ROUTES
      },
      {
        path: 'status-overview',
        component: StatusOverviewComponent,
        data: {
          reloadComponent: true
        },
        title: 'Menu_StatusOverview_Index'
      },
      {
        path: 'open-processes',
        component: OpenProcessesComponent,
        data: {
          reloadComponent: true
        },
        title: 'Menu_OpenRequest_Index'
      },
      {
        path: 'aftersales-overview',
        component: AftersalesOverviewComponent,
        data: {
          reloadComponent: true
        },
        title: 'Menu_AftersalesOverview_Index'
      },
      {
        path: 'invoice-clarification',
        children: INVOICE_CLARIFICATION_ROUTES
      },
      {
        path: 'call-me-back',
        component: CallMeBackComponent,
        data: {
          reloadComponent: true
        },
        title: 'PageTitle_CallMeBack'
      },
      {
        path: 'information',
        component: InformationComponent,
        data: {
          reloadComponent: true
        },
        title: 'Menu_Information_Index'
      },
      {
        path: 'inbox',
        component: InboxComponent,
        data: {
          reloadComponent: true
        },
        title: 'Menu_Inbox_Index'
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        data: {
          reloadComponent: true
        },
        title: 'Account_UserProfile_Title'
      },
      {
        path: 'license',
        component: LicenseComponent,
        title: 'CommonLicence'
      },
      {
        path: 'developer-mode',
        component: DeveloperModeComponent
      },
      {
        path: 'not-found',
        component: PageNotFoundComponent
      }
    ]
  },

  { path: '**', pathMatch: 'full', redirectTo: '/not-found' }
];
