import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { InputWithValidationComponent } from './input-with-validation.component';

@NgModule({
  imports: [MatInputModule, MatFormFieldModule, PipesModule, SharedModule],
  exports: [InputWithValidationComponent],
  declarations: [InputWithValidationComponent],
  providers: []
})
export class InputWithValidationModule {}
