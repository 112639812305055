import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  ConfirmedRequestProcessStates,
  DraftRequestProcessStates,
  RequestProcessStateCommon,
  RequestStateUsage
} from './request-state-label.types';

@Component({
  selector: 'request-state-label',
  templateUrl: './request-state-label.component.html',
  styleUrls: ['./request-state-label.component.scss']
})
export class RequestStateLabelComponent implements OnInit {
  @Input() state!: RequestProcessStateCommon;
  @Input() stateText!: string;
  @Input() stateUsage: RequestStateUsage = 'ListItem';

  tooltip: string = '';
  doNotShowLabel: boolean = false;

  constructor(private _translocoService: TranslocoService) {}

  ngOnInit() {
    if (this.stateUsage === 'StatusDetails' && !(this.state && ConfirmedRequestProcessStates.includes(this.state))) {
      this.doNotShowLabel = true;
      return;
    }

    if (DraftRequestProcessStates.includes(this.state)) {
      this.stateText = this._translocoService.translate('Common_RequestProcessStateCommon_Active');
    }

    const titleLabel = this._translocoService.translate('Common_RequestStateTitleLabel') + ':';
    if (titleLabel) {
      this.tooltip = titleLabel + ' ' + this.stateText;
    }
  }
}
