<ng-container *ngIf="cancelRequestSummary">
  <div *transloco="let t" class="cancel-request-page">
    <page-header [title]="t('Request_CancelRequestSummary_Title')"></page-header>

    <sending-restricted></sending-restricted>

    <mat-card class="main mb-40">
      <mat-card-content>
        <process-bar type="Cancel"></process-bar>

        <!-- Render for Summary step -->
        <ng-container *ngIf="selectedStep === 'Summary'">
          <div>
            <h2 class="subtitle ui-test--request-cancel-header-title">
              {{ t('Request_CancelRequestSummary_Headline') }}
            </h2>
            <small class="ui-test--requestCancel-small-hint">
              {{ t('Request_CancelRequestSummary_InformationText') }}
            </small>
          </div>

          <!-- General information component -->
          <mat-divider class="my-10"></mat-divider>
          <request-general-information
            [requestContent]="requestContent"
            [isDisplayOnly]="true"></request-general-information>

          <!-- Positions component -->
          <mat-divider class="my-10"></mat-divider>
          <request-positions-preview [requestContent]="requestContent"></request-positions-preview>

          <!-- Position amounts -->
          <request-total-amounts [totalAmounts]="totalAmounts"></request-total-amounts>

          <!-- Footer actions-->
          <mat-divider class="my-10"></mat-divider>
          <div class="flex-row justify-end content-center">
            <button
              mat-stroked-button
              class="mr-5 ui-test--request-cancel-button-detail-view"
              [disabled]="confirmActionLoading"
              (click)="openStatusDetailsDialog($event)">
              {{ t('Request_Summary_StatusOverview_Index') }}
            </button>
            <button
              mat-raised-button
              class="continue-button ui-test--request-cancel-button-confirm"
              color="primary"
              [disabled]="confirmActionLoading || (userService.isSendingRestricted | async)"
              [class.button-spinner]="confirmActionLoading"
              (click)="continue()">
              <mat-icon class="ui-test--request-cancel-mat-icon-confirm">skip_next</mat-icon>
              {{ t('Request_Summary_Confirm') }}
            </button>
          </div>
        </ng-container>

        <!-- Render for Confirmation step -->
        <ng-container *ngIf="selectedStep === 'TransmissionReport'">
          <request-confirmation [requestGuid]="requestGuid" mode="Canceled"></request-confirmation>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
