<div
  *transloco="let t"
  [style.display]="positionData.isPositionMarkedAsDeleted ? 'none': ''"
  [ngClass]="['indent-level-' + positionData.indentLevel]">
  <mat-expansion-panel hideToggle #positionsPanel>
    <mat-icon
      class="request-position-icon ui-test--request-tire-position-mat-icon-tire"
      [ngClass]="['zoom-level-' + visibleRows]">
      {{ positionData.itemType | positionIcon }}
    </mat-icon>

    <mat-expansion-panel-header
      #positionsHeader
      (click)="positionsHeader._toggle()"
      [class.validation-error]="hasValidationError || positionData.validationErrors?.size">
      <mat-panel-title>
        <div class="flex-row flex-grow-100 justify-between content-center align-center">
          <div class="flex-grow-10">
            <small class="mr-10 ui-test--request-tire-position-small-labor-position-index">
              {{ positionData.laborPositionIndex }}
            </small>
            <small class="ui-test--request-tire-position-small-abrreviation">
              {{ t('Common_TirePositionAbbreviation') }}
            </small>
          </div>

          <small class="flex-grow-20 ui-test--request-tire-position-small-external-product-number">
            {{ positionData.externalProductNumber }}
          </small>

          <small class="flex-grow-20 ui-test--request-tire-position-small-damage-type">{{ displayDamageType }}</small>

          <small
            class="flex-grow-10 ui-test--request-tire-position-small-quantity"
            *ngIf="isEnabled(positionData, 'Quantity')">
            {{ t('Common_QuantityAbbreviation') }}: {{ getTireQuantity }}
          </small>

          <div class="flex-grow-10">
            <ng-container *ngIf="isEnabled(positionData, 'Discount')">
              <small class="ui-test--request-tire-position-small-discount">
                {{ t('Common_DiscountAbbreviation') }}: {{ displayDiscount }}
              </small>
            </ng-container>
            <ng-container *ngIf="isEnabled(positionData, 'Goodwill')">
              <small class="ui-test--request-tire-position-small-goodwill">
                {{ t('Common_GoodwillAbbreviation') }}: {{ displayGoodwill }}
              </small>
            </ng-container>
            <ng-container *ngIf="isEnabled(positionData, 'MaterialGoodwill')">
              <small class="ui-test--request-tire-position-small-material-goodwill">
                {{ t('Common_MaterialGoodwillAbbreviation') }}: {{ displayMaterialGoodwill }}
              </small>
            </ng-container>
          </div>

          <small
            class="flex-grow-10 justify-end content-center align-center ui-test--request-tire-position-small-amount"
            *ngIf="isEnabled(positionData, 'UnitPrice') || isEnabled(positionData, 'NetFixedPrice')">
            <strong class="ui-test--request-tire-position-strong-amount">
              {{ t('Common_Total') }}: {{ netAmount | currencyOrDash }}
            </strong>
          </small>

          <mat-action-row class="justify-end content-center">
            <button class="ui-test--request-tire-position-button-save" mat-raised-button (click)="savePosition()">
              <mat-icon class="'ui-test--request-tire-position-mat-icon-save'">save</mat-icon>
            </button>
            <button
              mat-raised-button
              class="delete ui-test--request-tire-position-button-delete"
              [disabled]="positionData.isInnerPosition"
              (click)="removePosition()"
              *ngIf="!isDMS">
              <mat-icon class="ui-test--request-tire-position-mat-icon-delete">delete</mat-icon>
            </button>
            <button
              class="ui-test--request-tire-position-button-expand"
              mat-raised-button
              click-stop-propagation
              (click)="togglePosition(true)">
              <mat-icon class="ui-test--request-tire-position-mat-icon-expand">
                {{ positionsPanel.expanded ? 'close_fullscreen' : 'open_in_full' }}
              </mat-icon>
            </button>
          </mat-action-row>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex-row justify-end content-center align-start">
      <div class="flex-col flex-grow-60 justify-between content-center align-center">
        <!-- Type of Replacement Dropdown -->
        <div class="flex-row">
          <dropdown-with-search
            class="type-of-replacement-dropdown ui-test--request-tire-position-dropdown-with-search-replacement"
            [validators]="[Validators.required]"
            [labelResourceKey]="positionData.templateName + '_TypeOfReplacement'"
            formControlName="TypeOfReplacement"
            [dropDownOptions]="{
            options: getFilteredDamageTypes(),
            optionsValue: 'id',
            optionsText: 'displayText'
          }"
            [(value)]="positionData.damageTypeId"
            [sharedFormGroup]="positionFormGroup"
            (valueChange)="typeOfReplacementChange($event)"></dropdown-with-search>
        </div>

        <!-- Car with Text- and Checkboxes -->
        <h4 class="flex-row ui-test--request-tire-position-header-affected-tires">
          {{ t('TirePositionViewModel_DisplayAffectedTiresText') }}
        </h4>
        <div *ngIf="vehicleType === 'PassengerCar'" class="flex-row justify-center">
          <div class="flex-col justify-between content-center">
            <ng-container
              *ngFor="let position of ['frontLeft', 'rearLeft']"
              [ngTemplateOutlet]="tireTextCheckbox"
              [ngTemplateOutletContext]="{position: position}"></ng-container>
          </div>

          <div class="flex-col justify-center">
            <img class="car-image ui-test--request-tire-position-img-car" src="assets/images/car_top.png" alt="Car" />
          </div>

          <div class="flex-col justify-between content-center align-center">
            <ng-container
              *ngFor="let position of ['frontRight', 'rearRight']"
              [ngTemplateOutlet]="tireTextCheckbox"
              [ngTemplateOutletContext]="{position: position}"></ng-container>
          </div>
        </div>

        <!-- Tire Dual Selection -->
        <div class="flex-row" *ngIf="tireReplacements.isTireDualApplicationEnabled && vehicleType === 'PassengerCar'">
          <mat-checkbox
            class="ui-test--request-tire-position-mat-check-box-dualTire"
            color="primary"
            [title]="t('TireReplacementsViewModel_IsDualApplication')"
            [(ngModel)]="tireReplacements.isDualApplication"
            (ngModelChange)="activateUnsavedChangesPopup()"
            [disabled]="positionData.damageTypeId === null || positionData.damageTypeId === undefined || isDMS">
            {{ t('TireReplacementsViewModel_IsDualApplication') }}
          </mat-checkbox>
        </div>

        <!-- Motorcycle with Text- and Checkboxes -->
        <div *ngIf="vehicleType === 'Motorcycle'" class="flex-row justify-center">
          <div class="flex-col justify-between content-center align-center">
            <ng-container
              [ngTemplateOutlet]="tireTextCheckbox"
              [ngTemplateOutletContext]="{position: 'rear'}"></ng-container>
          </div>

          <div class="flex-col justify-center">
            <img
              class="motorcycle-image ui-test--request-tire-position-img-motorcycle"
              src="assets/images/motorcycle.png"
              alt="Motorcycle" />
          </div>

          <div class="flex-col justify-between content-center align-center">
            <ng-container
              [ngTemplateOutlet]="tireTextCheckbox"
              [ngTemplateOutletContext]="{position: 'front'}"></ng-container>
          </div>
        </div>

        <!-- Tire Selection -->
        <div class="flex-row gap-10" *ngIf="positionData.damageTypeId">
          <mat-form-field class="tire-dropdown" appearance="fill">
            <mat-label class="ui-test--request-tire-position-mat-label-tireRequest">
              {{ t('TireRequest_Tires') }}
            </mat-label>
            <input
              class="ui-test--request-tire-position-input-tireRequest"
              type="text"
              [placeholder]="t('TireRequest_SearchByProductNumber')"
              matInput
              [(ngModel)]="selectedTire"
              (ngModelChange)="activateUnsavedChangesPopup()"
              (keyup)="tireDropdownKeyup($event)"
              [matAutocomplete]="tireSelectAuto"
              [disabled]="!!this.positionData.tire?.databaseId || (hasExternalProductNumber && isDMS)"
              #tireProductNumber />
            <div
              *ngIf="loadingTireOptions"
              class="spinner ui-test--request-tire-position-div-mat-suffix"
              matSuffix></div>
            <button
              matSuffix
              mat-icon-button
              class="clear-input ui-test--request-tire-position-button-clear-input"
              (click)="resetTire()"
              *ngIf="tireProductNumber.disabled"
              click-stop-propagation>
              <mat-icon class="ui-test--request-tire-position-mat-icon-clear-input">clear</mat-icon>
            </button>
            <mat-autocomplete
              autoActiveFirstOption
              #tireSelectAuto="matAutocomplete"
              [displayWith]="tireSelectDisplay"
              (optionSelected)="selectedSpecificTire($event)">
              <mat-option *ngFor="let option of tireOptions" [value]="option">{{option.displayText}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <button
            class="tire-search-dialog-button ui-test--request-tire-position-button-open-tire-search-dialog"
            mat-raised-button
            (click)="openTireSearchDialog()"
            [disabled]="hasExternalProductNumber && isDMS">
            <mat-icon class="ui-test--request-tire-position-mat-icon-open-tire-search-dialog">filter_alt</mat-icon>
          </button>
        </div>
      </div>
      <div class="flex-col flex-grow-25">
        <form [formGroup]="positionFormGroup">
          <div class="flex-col">
            <div class="flex flex-grow-25">
              <ng-container [ngTemplateOutlet]="positionParameterConfiguration[0]"></ng-container>
            </div>
            <div class="flex flex-grow-25">
              <ng-container [ngTemplateOutlet]="positionParameterConfiguration[1]"></ng-container>
            </div>
            <div class="flex flex-grow-25">
              <ng-container [ngTemplateOutlet]="positionParameterConfiguration[2]"></ng-container>
            </div>
            <div class="flex flex-grow-25">
              <ng-container [ngTemplateOutlet]="positionParameterConfiguration[3]"></ng-container>
            </div>
            <div class="flex flex-grow-25">
              <ng-container [ngTemplateOutlet]="positionParameterConfiguration[4]"></ng-container>
            </div>
          </div>
          <div class="flex-row" *ngIf="isDMS">
            <div class="flex flex-grow-55 flex-offset-15">
              <mat-form-field class="w-full">
                <mat-label class="ui-test--request-tire-position-mat-label-external-position-description">
                  {{ t(positionData.templateName + '_ExternalPositionDescription') }}
                </mat-label>
                <input
                  class="ui-test--request-tire-position-input-external-position-description"
                  matInput
                  [value]="positionData.externalPositionDescription ?? ''"
                  disabled />
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
    <mat-action-row *ngIf="canAddSparePartsOnSubItem()" class="justify-start content-center align-center">
      <button
        class="ui-test--request-tire-position-button-addSparePart"
        mat-stroked-button
        color="primary"
        [disabled]="hasNoComponent"
        (click)="addSparePart()">
        {{ t('Request_Edit_AddSparePart') }}
      </button>
    </mat-action-row>
  </mat-expansion-panel>

  <ng-template #tireTextCheckbox let-position="position">
    <div
      [ngClass]="position === 'frontRight' || position === 'rearRight' || position === 'front' ? 'flex-row-reverse' : 'flex-row'"
      class="justify-between content-center align-center gap-10">
      <input-group
        type="number"
        class="ui-test--request-tire-position-input-group-position"
        [name]="position"
        [value]="tireReplacements[asVehicleTirePosition(position)].treadDepth | numericFormat"
        [inputSuffix]="tireReplacements.tireTreadDepthMeasurementUnit"
        [sharedFormGroup]="positionFormGroup"
        labelKey="TireRequest_TreadDepth"
        [isDisabled]="threadDepthDisabled(position)"
        [validators]="{
              required: true,
              min: this.configurationService.tireTreadDepthFrom,
              max: this.configurationService.tireTreadDepthTo
            } | validators"
        (inputGroupValue)="tireDepthValueChange($event, position)"
        (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
      <!-- Title has a fallback for motorcycles -->
      <mat-checkbox
        color="primary"
        class="tire-tread-depth-checkbox ui-test--request-tire-position-mat-check-box-tire-tread-depth"
        [title]="position === 'front' || position === 'rear' ?
          t('TireRequest_TreadDepth') :
          t('TireRequest_' + position.charAt(0).toUpperCase() + position.substring(1))"
        [checked]="tireReplacements[asVehicleTirePosition(position)].isChecked"
        (change)="checkboxTireDepthChange($event, position)"
        [disabled]="!isTireSelectionEnabled()"></mat-checkbox>
    </div>
  </ng-template>
</div>

<ng-template name="unitPrice">
  <input-group
    class="ui-test--request-tire-position-input-group-unit-price"
    [viewModelName]="positionData.templateName"
    name="UnitPrice"
    type="currency"
    [inputSuffix]="requestContent.requestProcess.displayCurrencyUnit"
    [validators]="{required: true, min: configurationService.positionUnitPriceFrom, max: configurationService.positionUnitPriceTo} | validators"
    [isDisabled]="isUnitPriceDisabled"
    [value]="positionData.unitPrice"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editUnitPrice($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="netFixedPrice">
  <input-group
    class="ui-test--request-tire-position-input-group-net-fixed-price"
    [viewModelName]="positionData.templateName"
    name="NetFixedPrice"
    type="currency"
    [inputSuffix]="requestContent.requestProcess.displayCurrencyUnit"
    [validators]="{required: true, min: configurationService.positionNetFixedPriceFrom, max: configurationService.positionNetFixedPriceTo} | validators"
    [isDisabled]="isNetFixedPriceDisabled"
    [value]="positionData.netFixedPrice"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editNetFixedPrice($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="discount">
  <input-group
    class="ui-test--request-tire-position-input-group-discount"
    [viewModelName]="positionData.templateName"
    name="Discount"
    type="percent"
    [isDisabled]="isDiscountDisabled"
    [validators]="{min: configurationService.positionDiscountFrom, max: configurationService.positionDiscountTo} | validators"
    [value]="positionData.discount"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editDiscount($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="goodwill">
  <input-group
    class="ui-test--request-tire-position-input-group-goodwill"
    [viewModelName]="positionData.templateName"
    name="Goodwill"
    type="percent"
    [isDisabled]="isGoodWillDisabled"
    [validators]="{min: configurationService.positionGoodwillFrom, max: configurationService.positionGoodwillTo} | validators"
    [value]="positionData.goodwill"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editGoodwill($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="sagaId">
  <input-group
    *ngIf="enableSagaId"
    class="ui-test--request-tire-position-input-group-saga-id"
    [viewModelName]="positionData.templateName"
    name="SagaId"
    [maxLength]="32"
    [value]="positionData.sagaId"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editSagaId($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="none"></ng-template>
