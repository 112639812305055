import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@core/modules';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { LicenseComponent } from './license.component';

@NgModule({
  imports: [PageHeaderModule, MatIconModule, MatTableModule, SharedModule],
  declarations: [LicenseComponent]
})
export class LicenseModule {}
