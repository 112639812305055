import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContextDataService, TenantSettingsService, UserService } from '@core/services';
import { DateTime } from 'luxon';
import { PhoneNumberVerificationDialogComponent } from './phone-number-verification.component';

@Injectable({ providedIn: 'root' })
export class PhoneNumberVerificationDialogService {
  constructor(
    private tenantSettingsService: TenantSettingsService,
    private userService: UserService,
    private dialog: MatDialog,
    private contextDataService: ContextDataService
  ) {}

  open(): MatDialogRef<PhoneNumberVerificationDialogComponent> | undefined {
    // We do not want phone number verification for FSPartner
    if (this.contextDataService.identityProvider == 'fspartner') {
      return;
    }

    const lastModifiedDate = this.userService.userPhoneNumberModifiedDate;
    if (lastModifiedDate) {
      const waitPeriod = this.tenantSettingsService.phoneNumberWaitPeriod;
      const renewalPeriod = this.tenantSettingsService.phoneNumberRenewalPeriod;

      const shouldDialogDisplayed =
        DateTime.fromISO(lastModifiedDate).plus({ day: renewalPeriod }) <= DateTime.now() &&
        DateTime.now() <= DateTime.fromISO(lastModifiedDate).plus({ day: renewalPeriod + waitPeriod });
      if (this.userService.userPhoneNumber && !shouldDialogDisplayed) {
        // Since dialog is not opened and there is no reference, return undefined
        return undefined;
      }
    }

    // Open the dialog and return reference
    return this.dialog.open(PhoneNumberVerificationDialogComponent, { disableClose: true, width: '500px' });
  }
}
