import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProcessBarComponent } from 'app/pages/request/shared/process-bar/process-bar.component';
import { StatusDetailsDialogService } from 'app/pages/status-overview/status-details-dialog/status-details-dialog.service';
import { InvoiceClarificationCancelService } from './cancel.service';
import { InvoiceClarificationCancelSummary } from './cancel.types';

@Component({
  selector: 'invoice-clarification-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceClarificationCancelComponent {
  @ViewChild(ProcessBarComponent) processBarComponent!: ProcessBarComponent;

  invoiceClarificationCancelSummary!: InvoiceClarificationCancelSummary;
  confirming: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private statusDetailsDialogService: StatusDetailsDialogService,
    private invoiceClarificationCancelService: InvoiceClarificationCancelService
  ) {
    this.invoiceClarificationCancelSummary = this.route.snapshot.data[0]['invoiceClarificationCancelSummary'];
  }

  get selectedStep(): string {
    return this.processBarComponent?.steps?.find((step) => step.isActive === true)?.key ?? '';
  }

  openStatusDetailsDialog(): void {
    const { requestGuid } = this.invoiceClarificationCancelSummary;
    if (!requestGuid) {
      return;
    }
    this.statusDetailsDialogService.openDialog(
      requestGuid,
      this.invoiceClarificationCancelSummary.productType,
      'readonly'
    );
  }

  confirm(): void {
    this.confirming = true;
    this.invoiceClarificationCancelService
      .confirmCancelInvoiceClarification(this.invoiceClarificationCancelSummary)
      .subscribe({
        next: (response: InvoiceClarificationCancelSummary) => {
          this.invoiceClarificationCancelSummary = response;
          this.processBarComponent.nextStep();
          this.confirming = false;
        }
      });
  }
}
