import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export function formattedMinValidator(min: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return formatNumericInputAndExecuteOriginalValidator(control, Validators.min(min));
  };
}

export function formattedMaxValidator(max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return formatNumericInputAndExecuteOriginalValidator(control, Validators.max(max));
  };
}

function formatNumericInputAndExecuteOriginalValidator(
  control: AbstractControl,
  originalValidator: ValidatorFn
): ValidationErrors | null {
  if (control.value == null || control.value == '') {
    return null;
  }

  const formattedValue = parseFloat(
    typeof control.value === 'string' ? control.value.replace(',', '.') : control.value
  );
  if (isNaN(formattedValue)) {
    return { invalidNumber: true };
  }

  return originalValidator({ ...control, value: formattedValue } as AbstractControl);
}
