import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExternalApplicationAssignResult } from '@core/types/application-assign.types';
import { FilterCriteria, FilterRequest } from 'app/components/filter/filter.types';
import { OverviewService } from 'app/components/overview/overview.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AftersalesOverviewData } from './aftersales-overview.types';

@Injectable({ providedIn: 'root' })
export class AftersalesOverviewService extends OverviewService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getFilterCriteria() {
    const url = `${environment.isportCoreApiUrl}/api/aftersalesoverview/filtercriteria?`;
    return this.httpClient.get<FilterCriteria>(url);
  }

  getData(request: FilterRequest) {
    const url = `${environment.isportCoreApiUrl}/api/aftersalesoverview/overview?`;
    return this.httpClient.get<AftersalesOverviewData[]>(url, {
      params: request.toHttpParams()
    });
  }

  postContinueUrl(data: AftersalesOverviewData): Observable<ExternalApplicationAssignResult> {
    return this.httpClient.post<ExternalApplicationAssignResult>(
      `${environment.isportCoreApiUrl}/api/aftersalesoverview/continue`,
      {
        productType: data.productType,
        continueToken: data.continueToken,
        vehicleBrand: data.vehicleBrand,
        mileage: data.mileage,
        firstTimeRegistrationDate: data.firstTimeRegistrationDate,
        modelCode: data.modelCode,
        vehicleIdentificationNumber: data.chassisNumber,
        dealerIdentifier: data.dealerIdentifier,
        brokerNumber: data.brokerNumber
      }
    );
  }
}
