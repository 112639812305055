<div *transloco="let t">
  <page-header [title]="t('Search_Index_Title')"></page-header>
  <sending-restricted></sending-restricted>

  <div *ngIf="!submitLoading">
    <validation-summary [errorList]="errorList"></validation-summary>

    <!-- SEARCH INDEX WARNING BOX -->
    <mat-card
      class="message-state warning"
      *ngIf="hasNoContractsFound && errorList.length === 0 && !isInvalidSearchRequest && !businessError">
      <mat-card-content class="ui-test--contract-search-card-no-contracts-found">
        {{ t('Search_Index_NoContractsFound') }}
      </mat-card-content>
    </mat-card>
    <!-- END SEARCH INDEX WARNING BOX -->

    <!-- MISSING PARAMETERS INFO BOX -->
    <ng-container *ngIf="formConfigurator.isMissingSearchParameterPage && !hasNoContractsFound">
      <ng-container *ngIf="failedSearchCount > 0 && !isInvalidSearchRequest; else noFailedSearchCount">
        <mat-card class="message-state info">
          <mat-card-content class="ui-test--contract-search-card-failed-count-message">
            {{ t('Search_MissingSearchParameter_FailedCount_Message') }}
          </mat-card-content>
        </mat-card>
      </ng-container>

      <ng-template #noFailedSearchCount>
        <mat-card class="message-state info">
          <mat-card-content *ngIf="formConfigurator.isExternalSearch">
            <span
              class="ui-test--contract-search-span-ignore-data-protection"
              *ngIf="formConfigurator.ignoreDataProtection">
              {{ t('Search_Index_ParametersMissing_Hint_ExternalSearch_IgnoreDataProtection') }}
            </span>
            <span class="ui-test--contract-search-span-external-search" *ngIf="!formConfigurator.ignoreDataProtection">
              {{ t('Search_Index_ParametersMissing_Hint_ExternalSearch') }}
            </span>
          </mat-card-content>
          <mat-card-content
            class="ui-test--contract-search-mat-card-content-hint"
            *ngIf="!formConfigurator.isExternalSearch">
            {{ t('Search_Index_ParametersMissing_Hint') }}
          </mat-card-content>
        </mat-card>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="failedSearchCount >= 3 && !isInvalidSearchRequest">
      <mat-card class="message-state info">
        <mat-card-title class="ui-test--contract-search-card-failed-count-max-header">
          {{ t('Search_MissingSearchParameter_FailedCountMax_Header') }}
        </mat-card-title>
        <mat-card-content class="ui-test--contract-search-card-failed-count-max-message">
          {{ t('Search_MissingSearchParameter_FailedCountMax_Message') }}
        </mat-card-content>
      </mat-card>
    </ng-container>
    <!-- END MISSING PARAMETERS INFO BOX -->

    <!-- SEARCH INDEX INFO BOX -->
    <ng-container
      *ngIf="!formConfigurator.isMissingSearchParameterPage && hasNoContractsFound && !isInvalidSearchRequest && !isVehicleIdentificationNumberUsedInPreviousSearch">
      <mat-card class="message-state info">
        <mat-card-content class="ui-test--contract-search-mat-card-content-no-contracts-found-hint-use-vin">
          {{ t('Search_Index_NoContractsFound_HintUseVin') }}
        </mat-card-content>
      </mat-card>
    </ng-container>
    <!-- END SEARCH INDEX INFO BOX -->

    <!-- SEARCH BUSINESS ERROR BOX -->
    <ng-container *ngIf="showBusinessError">
      <mat-card class="message-state error">
        <mat-card-content class="ui-test--contract-search-mat-card-content-business-error">
          {{ businessError }}
        </mat-card-content>
      </mat-card>
    </ng-container>
    <!-- END SEARCH INDEX INFO BOX -->
  </div>

  <div class="flex-col justify-center content-between">
    <div class="flex flex-grow-100">
      <div class="flex-grow-25"></div>
      <div class="flex-grow-50">
        <form
          [formGroup]="contractSearchForm"
          novalidate
          (ngSubmit)="onSubmit()"
          class="flex-col flex-grow-75 justify-around content-center align-center">
          <mat-form-field *ngIf="formConfigurator.displayVehicleIdentificationNumber">
            <mat-label class="ui-test--contract-search-mat-label-common-vehicle-identification-number">
              {{ t('Common_VehicleIdentificationNumber') }}
            </mat-label>
            <input
              class="ui-test--contract-search-input-vehicle-identification-number"
              matInput
              [required]="!vehicleIdentificationNumber.disabled"
              #vehicleIdentificationNumber
              trim="blur"
              formControlName="vehicleIdentificationNumber"
              name="vehicleIdentificationNumber" />
            <mat-hint align="end" class="ui-test--contract-search-input-vehicle-identification-number-length">
              {{ vehicleIdentificationNumber.value.length || 0 }}/17
            </mat-hint>
          </mat-form-field>

          <mat-form-field *ngIf="formConfigurator.displayLicensePlateNumber">
            <mat-label class="ui-test--contract-search-mat-label-common-license-plate-number">
              {{ t('Common_LicensePlateNumber') }}
            </mat-label>
            <input
              class="ui-test--contract-search-input-license-plate-number"
              matInput
              [required]="!licensePlateNumber.disabled"
              #licensePlateNumber
              trim="blur"
              name="licensePlateNumber"
              formControlName="licensePlateNumber" />
          </mat-form-field>

          <mat-form-field *ngIf="formConfigurator.displayContractNumber">
            <mat-label class="ui-test--contract-search-mat-label-common-contract-number">
              {{ t('Common_ContractNumber') }}
            </mat-label>
            <input
              class="ui-test--contract-search-input-contract-number"
              matInput
              [required]="!contractNumber.disabled"
              #contractNumber
              trim="blur"
              name="contractNumber"
              formControlName="contractNumber" />
          </mat-form-field>

          <mat-form-field *ngIf="formConfigurator.displayMileage">
            <mat-label class="ui-test--contract-search-mat-label-common-mileage">{{ t('Common_Mileage') }}</mat-label>
            <input
              class="ui-test--contract-search-input-mileage"
              matInput
              [required]="configurationService.isMileageMandatoryInSearch && !mileage.disabled"
              #mileage
              trim="blur"
              formControlName="mileage"
              name="mileage"
              autocomplete="off"
              type="number" />
          </mat-form-field>

          <datepicker
            class="ui-test--contract-search-datepicker-date-of-first-registration"
            *ngIf="formConfigurator.displayDateOfFirstRegistration"
            [control]="contractSearchForm.controls.dateOfFirstRegistration"
            [maxDate]="maxDate"
            [required]="!contractSearchForm.controls.dateOfFirstRegistration.disabled"
            [placeholderTextValue]="t('Common_DateOfFirstRegistration')"
            name="dateOfFirstRegistration"></datepicker>

          <datepicker
            class="long-hint ui-test--contract-search-datepicker-damage-date"
            *ngIf="formConfigurator.displayDamageDate"
            [control]="contractSearchForm.controls.damageDate"
            [maxDate]="maxDate"
            [required]="!contractSearchForm.controls.damageDate.disabled"
            [placeholderTextValue]="t('Common_DamageDate')"
            [hintTextValue]="t('SearchIndexViewModel_DamageDate_Popover_Content')"
            name="damageDate"></datepicker>

          <button
            class="search-button ui-test--contract-search-button-search"
            mat-raised-button
            type="submit"
            color="primary"
            [disabled]="submitLoading || contractSearchForm.invalid"
            [class.button-spinner]="submitLoading">
            <mat-icon class="ui-test--contract-search-mat-icon-search" aria-label="Search icon">search</mat-icon>
            <span class="ui-test--contract-search-span-search">{{ t('Search_Index_SearchButton') }}</span>
          </button>
        </form>
      </div>

      <div class="flex-grow-25 max-height" *ngIf="showOfferPanel && configurationService.enableOfferManagement">
        <offer-panel [vin]="vin"></offer-panel>
      </div>
    </div>
  </div>
</div>
