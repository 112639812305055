import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RequestEventMessage, RequestStateStore, TenantSettingsService } from '@core/services';
import { DropdownOption } from 'app/components/dropdown-with-search/dropdown-with-search.types';
import { ActivityTypeIdentifierCommon } from 'app/components/positions';
import { sort } from 'app/components/sort/sort.helper';
import { RequestService } from 'app/pages/request/request.service';
import { RequestContent } from 'app/pages/request/request.types';
import { UnsavedChangesStateStore } from 'app/pages/request/unsaved-changes-state-store.service';
import { DropdownPredictiveSearchComponent } from '../../dropdown-predictive-search/dropdown-predictive-search.component';
import { DropdownGroupOption } from '../../dropdown-predictive-search/dropdown-predictive-search.types';

@Component({
  selector: 'work-position-filter',
  templateUrl: './work-position-filter.component.html',
  styleUrls: ['./work-position-filter.component.scss']
})
export class WorkPositionFilterComponent implements OnInit {
  @Input() requestContent!: RequestContent;

  @ViewChild(DropdownPredictiveSearchComponent) dropdownPredictiveSearchComponent!: DropdownPredictiveSearchComponent;

  groupedSelect: DropdownGroupOption[] = [];
  availableTypes: ActivityTypeIdentifierCommon[] = [];
  isFiltered: boolean = false;
  defaultPositionTypeKey: string = '';
  isLoadingPositions = false;
  private activityTypesFilter: string[] = [];
  warrantyEditMode = this.tenantSettingsService.warrantyEditMode;

  /**
   * Constructor
   */
  constructor(
    private requestService: RequestService,
    private requestStore: RequestStateStore,
    private unsavedChangesStateStore: UnsavedChangesStateStore,
    private tenantSettingsService: TenantSettingsService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.addPositionTypes();
    this.updateDefaultPositionType();
    this.selectGroupedActivityTypes();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private addPositionTypes() {
    const rootTypes: ActivityTypeIdentifierCommon[] = [
      'WorkPosition',
      'MaintenancePackagePosition',
      'WarrantyPosition'
    ];

    rootTypes.forEach((type) => {
      if (this.requestContent.availableActivityTypes.some((at) => at.itemType === type)) {
        this.availableTypes.push(type);
      }
    });
  }

  private selectGroupedActivityTypes() {
    this.requestContent.availableActivityTypes
      .filter((activityType) => this.availableTypes.includes(activityType.itemType))
      .filter((at) => !this.tenantSettingsService.emptyPositions.includes(at.name))
      .forEach((activityType) => {
        activityType.activityTypeClasses.forEach((activityTypeClass) => {
          let existingObject = this.groupedSelect.find((x) => x.value === activityTypeClass.id.toString());

          if (!existingObject) {
            existingObject = <DropdownGroupOption>{
              value: activityTypeClass.id.toString(),
              text: activityTypeClass.description,
              options: []
            };

            this.groupedSelect.push(existingObject);
          }

          existingObject.options.push(<DropdownOption>{
            value:
              existingObject.value +
              '+' +
              activityType.id +
              '|' +
              activityType.externalProductNumbers.map((item) => item.number).join('|'),
            text: activityType.description
          });
        });
      });

    // Sort activity type class map object
    sort(this.groupedSelect, 'text', this.requestContent.isActivityTypeClassesSortingAscending ? 'asc' : 'desc');

    // Sort the available ActivityTypes by description with given configuration.
    this.groupedSelect.forEach((item) => {
      sort(item.options, 'text', this.requestContent.isActivityTypesSortingAscending ? 'asc' : 'desc');
    });
  }

  private updateDefaultPositionType() {
    if (this.availableTypes.length > 1 && this.activityTypesFilter.length) {
      const addedPositionTypes: ActivityTypeIdentifierCommon[] = [];

      this.activityTypesFilter.forEach((filter) => {
        const activityTypeId = Number(filter.split('|')[0].split('+')[1]);
        const activityType = this.requestContent.availableActivityTypes.find((at) => at.id == activityTypeId);

        if (activityType && !addedPositionTypes.some((apt) => apt === activityType.itemType)) {
          addedPositionTypes.push(activityType.itemType);
        }
      });

      const filteredTypes = this.availableTypes.filter((apt) => addedPositionTypes.includes(apt));

      if (filteredTypes.length > 1) {
        this.defaultPositionTypeKey = 'Request_Edit_AddPositions';
      } else if (filteredTypes.length > 0) {
        this.defaultPositionTypeKey = 'Request_Edit_Add' + filteredTypes[0];
      } else {
        this.defaultPositionTypeKey = 'Request_Edit_AddPosition';
      }
      this.isFiltered = true;
      return;
    }

    this.defaultPositionTypeKey =
      this.availableTypes.length > 0 ? 'Request_Edit_Add' + this.availableTypes[0] : 'Request_Edit_AddPosition';
    this.isFiltered = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  filterChanged(values: string[]) {
    this.activityTypesFilter = values;
    this.updateDefaultPositionType();
  }

  addPosition(itemType: ActivityTypeIdentifierCommon | undefined = undefined) {
    this.requestContent.activityTypesFilter = this.activityTypesFilter;

    this.isLoadingPositions = true;
    this.requestService.addPosition(this.requestContent, itemType).subscribe({
      next: (res: RequestContent) => {
        this.activityTypesFilter = [];
        this.dropdownPredictiveSearchComponent.reset();
        this.updateDefaultPositionType();
        this.requestContent.requestPositions = res.requestPositions;
        this.requestStore.addEventMessage({ eventName: 'AddNewPosition' } as RequestEventMessage);
        this.unsavedChangesStateStore.addEventMessage('Dirty');
      },
      error: () => {
        this.isLoadingPositions = false;
      },
      complete: () => {
        this.isLoadingPositions = false;
      }
    });
  }
}
