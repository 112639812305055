import { Component, Input } from '@angular/core';
import { QuestionAnswer } from '@core/pipes';
import { Question, StatusDetails } from '../status-details-dialog.types';

@Component({
  selector: 'status-details-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.scss']
})
export class StatusDetailsAnswersComponent {
  @Input() statusDetails!: StatusDetails;

  getQuestionAnswerType(question: Question) {
    return <QuestionAnswer>{
      isMultiline: question.isMultiline,
      key: question.keyName,
      typeKey: question.typeKeyName,
      value: question.value,
      dataType: question.dataType
    };
  }
}
