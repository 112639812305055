import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DropdownOption } from 'app/components/dropdown-with-search/dropdown-with-search.types';
import { Observable, debounceTime, map, startWith } from 'rxjs';
import { ChipSelectedOption, DropdownGroupOption } from './dropdown-predictive-search.types';

@Component({
  selector: 'dropdown-predictive-search',
  templateUrl: './dropdown-predictive-search.component.html',
  styleUrls: ['./dropdown-predictive-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownPredictiveSearchComponent implements OnInit {
  @Input() labelResourceKey: string = '';
  @Input() placeholderResourceKey: string = 'Common_PleaseChoose';
  @Input() isMultiSelect: boolean = false;
  @Input() dropDownOptions!: DropdownGroupOption[];
  @Output() valueChange = new EventEmitter<string[]>();

  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autoTrigger!: MatAutocompleteTrigger;

  filteredDropdownOptions!: Observable<DropdownGroupOption[]>;
  searchInputControl = new FormControl('');
  selectedOptions: ChipSelectedOption[] = [];

  ngOnInit(): void {
    this.filteredDropdownOptions = this.searchInputControl.valueChanges.pipe(
      debounceTime(50),
      startWith(''),
      map((value: string | null) => this.filter(value))
    );
  }

  private filter(value: string | null) {
    // Reopening panel after selection produces bug if you double click
    // on option. So, in that case input sends object instead of string
    // and below is solution for that bug.
    if (typeof value !== 'string') {
      value = '';
    }

    const words: string[] = (value || '')
      .split(' ')
      .filter((t: string) => t.length > 0)
      .map((word: string) => {
        return (
          word
            .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
            // replace diacritics
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        );
      });

    return this.dropDownOptions.reduce((groups: DropdownGroupOption[], group: DropdownGroupOption) => {
      // Creates a shallow copy
      const elem = Object.assign({}, group);

      // Exclude selected options from autocomplete
      elem.options = group.options.filter((option) => !this.selectedOptions.some((so) => so.value === option.value));

      elem.options = elem.options.filter(function (option) {
        // Read EPNs from option value and create array to be searched
        const epns = option.value.split('|');
        epns.shift();

        // Check search input text by regex and search by EPN
        const isTextMatch = words.every((word) =>
          option.text
            // replace diacritics
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .match(new RegExp(`${word}`, 'gi'))
        );
        if (isTextMatch || epns.some((epn) => epn.includes(value ?? ''))) {
          return true;
        }

        return false;
      });

      // If options are not found, do not create group for them
      if (elem.options.length != 0) {
        groups.push(elem);
      }

      return groups;
    }, []);
  }

  sendValuesToParent() {
    this.valueChange.emit(
      this.selectedOptions.map((option) => option.value + (option.epnText ? '=' + option.epnText : ''))
    );
  }

  optionSelectedHandler(event: MatAutocompleteSelectedEvent) {
    const option = event.option.value as DropdownOption;

    // Reset list on every selection if it is not multiselect
    if (!this.isMultiSelect) {
      this.selectedOptions = [];
    }

    // Read EPNs from option value and create array to be searched
    const epns = option.value.split('|');
    epns.shift();

    // If option is valid and if does not exist already in array
    if (option && !this.selectedOptions.some((so) => so.value === option.value)) {
      this.selectedOptions.push(<ChipSelectedOption>{
        text: option.text,
        value: option.value,
        epnText: epns?.find((epn) => epn === this.searchInput.nativeElement.value)
      });

      // If option is successfully added then reset input
      // and inform parent about changes
      this.searchInput.nativeElement.value = '';
      this.searchInputControl.setValue(null);
      this.sendValuesToParent();
    }

    if (this.isMultiSelect) {
      // Since the autocomplete does not have support for keeping
      // panel open after selection this is hack to reopen it again
      requestAnimationFrame(() => this.autoTrigger.openPanel());
    }
  }

  onSearchInputKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      this.optionSelectedHandler({ option: this.autoTrigger.activeOption } as MatAutocompleteSelectedEvent);
      event.preventDefault(); // Prevent tab key press to jump away from the input element
    }
  }

  groupIdentifier(_index: number, group: DropdownGroupOption) {
    return group.value;
  }

  optionIdentifier(_index: number, option: DropdownOption) {
    return option.value;
  }

  displayOption(option: DropdownOption) {
    return option?.text;
  }

  remove(index: number): void {
    // Remove option by index and inform parent about changes
    this.selectedOptions.splice(index, 1);
    this.sendValuesToParent();
  }

  reset() {
    // Reset component calling this method outside
    this.selectedOptions = [];
    this.searchInput.nativeElement.value = '';
    this.searchInputControl.setValue(null);
  }
}
