<div class="spinner" *ngIf="loading"></div>

<div *transloco="let t">
  <page-header [title]="t('Menu_Inbox_Index')"></page-header>

  <div *ngIf="!loading">
    <div class="mb-15">
      <filter
        [visibleFilterParameters]="['productList']"
        [availableProducts]="availableProductTypes"
        (productSelectChange)="filterForProducts($event)"
        class="inbox-filter">
        <button
          mat-raised-button
          class="mark-as-read flex-grow-30 ui-test--inbox-button-set-to-read"
          color="default"
          [class.button-spinner]="false"
          (click)="setAllMessagesRead()">
          {{ t('Inbox_Index_SetAllMessagesToRead') }}
          <mat-icon class="ui-test--inbox-mat-icon-done-all">done_all</mat-icon>
        </button>
      </filter>
    </div>

    <sort [sortKeys]="sortKeys" [visibleComponents]="['search']" (search)="search($event)" (sort)="sort($event)"></sort>
  </div>

  <pagination
    *ngIf="messages && messages.length > 0 && !loading"
    #paginatorTop
    [dataLength]="messages.length"
    (page)="onPaginatorChange($event)"></pagination>

  <div *ngFor="let message of messages; let i = index">
    <mat-card
      class="message"
      [class]="'ui-test--inbox-mat-card-' + i"
      *ngIf="message.show"
      [ngClass]="{'unread': !message.isRead}"
      (click)="!message.isRead ? setMessageRead(message.id) : ''">
      <mat-card-content>
        <div class="flex justify-between content-center flex-grow-100">
          <div class="flex-row flex-grow-20">
            <mat-icon [class]="'ui-test--inbox-mat-icon-' + message.messageProcessType + '-'+ i">
              {{ message.messageProcessType | productTypeIcon }}
            </mat-icon>
            <span class="indicator-text ml-15" [class]="'ui-test--inbox-mat-icon-indicator-text-' + i">
              {{ message.indicatorText }}
            </span>
          </div>
          <div class="flex-row flex-grow-20">
            <span [class]="'ui-test--inbox-span-created-label-' + i">{{t('Common_Created')}}:</span>
            <span class="fw-700 ml-15" [class]="'ui-test--inbox-span-created-' + i">
              {{ message.createdDate | dateFormat }}
            </span>
          </div>
          <div class="flex-row flex-grow-50">
            <span [class]="'ui-test--inbox-span-subject-label-' + i">{{t('Inbox_Index_Subject')}}:</span>
            <span class="fw-700 ml-15" [class]="'ui-test--inbox-span-subject-' + i">{{ message.subject }}</span>
          </div>
          <div class="flex-col justify-center flex-grow-10">
            <button
              mat-raised-button
              class="dismiss-button delete"
              color="default"
              [class]="'ui-test--inbox-button-delete-' + i"
              [title]="t('Common_MessageDismiss')"
              [class.button-spinner]="false"
              (click)="dismissMessage($event, message.id)">
              <mat-icon [class]="'ui-test--inbox-mat-icon-delete-' + i">delete</mat-icon>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <pagination
    *ngIf="messages && messages.length > 0 && !loading"
    #paginatorBottom
    [dataLength]="messages.length"
    (page)="onPaginatorChange($event)"></pagination>
</div>
