<div *transloco="let t">
  <h2 class="subtitle ui-test--request-events-header-event-title">{{ t('Common_Events') }}</h2>

  <div
    *ngFor="let position of eventPositions; trackBy: eventIdentifier, let i = index"
    class="flex-row justify-center content-start gap-15 mb-20">
    <strong
      class="flex flex-grow-50 justify-end content-center"
      [class]="'ui-test--request-events-strong-activity-type-class-' + i">
      {{ position.activityTypeClass }}
    </strong>
    <span class="flex flex-1" [class]="'ui-test--request-events-span-activity-type-description-' + i">
      {{ position.activityTypeDescription }}
    </span>
  </div>
</div>
