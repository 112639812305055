import { UserInfo } from '@core/services/user/user.types';

export interface ContextData {
  tenant: string;
  user: UserInfo;
  dealerDatabaseId: number;
  contractDatabaseId?: number | null;
  contextId?: string | null;
  processId?: string;
  appId: string;
  languageInformation: LanguageInformation;
  authProvider: string;
  timeStamp: number;
}

export interface LanguageInformation {
  userLanguage?: string;
  defaultLanguage: string;
}

export interface PartnerClaim {
  kvpsid: string;
  email: string;
}

export type IdentityProvider = 'grpisc' | 'fspartner' | 'fstoolsisc' | '';

export const IdentityProviders: IdentityProvider[] = ['grpisc', 'fspartner', 'fstoolsisc'];
