import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { TranslocoService } from '@ngneat/transloco';
import { InvoiceClarificationService } from './invoice-clarification.service';
import { InvoiceClarificationDetails } from './invoice-clarification.types';

@Component({
  selector: 'invoice-clarification',
  templateUrl: './invoice-clarification.component.html',
  styleUrls: ['./invoice-clarification.component.scss']
})
export class InvoiceClarificationComponent {
  submitLoading = false;
  errorList: string[] = [];
  invoiceClarificationData!: InvoiceClarificationDetails | null;
  showButtons: boolean = false;
  showInvoiceClarificationHistory: boolean = false;
  validationBoxClass: string = '';
  validationStatusText: string = '';
  invoiceClarificationForm = this.formBuilder.group({
    internalInvoiceNumber: new FormControl<string | null>(null, Validators.required),
    vehicleIdentificationNumber: new FormControl<string | null>(null, [Validators.maxLength(17), Validators.required])
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private invoiceClarificationService: InvoiceClarificationService,
    private translocoService: TranslocoService
  ) {}

  getData(): void {
    this.errorList = [];
    this.submitLoading = true;

    const internalInvoiceNumber = this.invoiceClarificationForm.value.internalInvoiceNumber ?? '';
    const vehicleIdentificationNumber = this.invoiceClarificationForm.value.vehicleIdentificationNumber ?? '';

    this.invoiceClarificationService
      .getInvoiceClarificationDetails(internalInvoiceNumber, vehicleIdentificationNumber)
      .subscribe({
        next: (result: InvoiceClarificationDetails) => {
          this.invoiceClarificationData = result;
          this.checkShowInvoiceClarificationHistory();
          if (
            this.invoiceClarificationData.canStartClarification &&
            !this.showInvoiceClarificationHistory &&
            !this.invoiceClarificationData.canCancelClarification
          ) {
            this.continueInvoiceClarification();
            return;
          }
          // prevent flashing of buttons in the redirect case
          this.showButtons = true;
        },
        error: (result: HttpErrorResponse) => {
          if (result.status === HttpStatusCode.BadRequest) {
            const validationReader = new ValidationReader(result.error);
            this.errorList = validationReader.values;
            this.invoiceClarificationData = null;
          }
          this.submitLoading = false;
        },
        complete: () => {
          this.submitLoading = false;
          this.getValidationBoxData();
        }
      });
  }

  private getValidationBoxData(): void {
    this.validationBoxClass = '';

    let resourceKey = '';
    switch (this.invoiceClarificationData?.statusCode) {
      case 'NotFound':
        resourceKey = 'InvoiceClarification_NoInvoiceFound';
        this.validationBoxClass = 'warning-box';
        break;
      case 'ReadOnly':
        resourceKey = 'InvoiceClarification_ReadOnly';
        this.validationBoxClass = 'warning-box';
        break;
      case 'DifferentDealer':
        resourceKey = 'InvoiceClarification_DifferentDealer';
        this.validationBoxClass = 'warning-box';
        break;
      case 'NotCurrentInvoice':
        resourceKey = 'InvoiceClarification_NotCurrentInvoice';
        this.validationBoxClass = 'warning-box';
        break;
      case 'NoPermission':
        resourceKey = 'InvoiceClarification_NoPermission';
        this.validationBoxClass = 'error-box';
        break;
      case 'Success':
        if (this.invoiceClarificationData?.editOptions === 'Denied') {
          resourceKey = 'InvoiceClarification_Denied';
          this.validationBoxClass = 'warning-box';
        }
        break;
    }

    this.validationStatusText = this.translocoService.translate(resourceKey);
  }

  continueInvoiceClarification(): void {
    this.submitLoading = true;
    this.router.navigate(['/invoice-clarification/start'], {
      queryParams: {
        internalInvoiceNumber: this.invoiceClarificationData?.internalInvoiceNumber,
        productType: this.invoiceClarificationData?.productType,
        requestGuid: this.invoiceClarificationData?.requestGuid
      }
    });
  }

  cancelInvoiceClarification(): void {
    this.submitLoading = true;
    this.router.navigate(['/invoice-clarification/cancel'], {
      queryParams: {
        invoiceClarificationId: this.invoiceClarificationData?.currentInvoiceClarificationId,
        productType: this.invoiceClarificationData?.productType,
        editOptions: this.invoiceClarificationData?.editOptions,
        requestGuid: this.invoiceClarificationData?.requestGuid
      }
    });
  }

  checkShowInvoiceClarificationHistory(): void {
    const notesCount =
      this.invoiceClarificationData?.invoiceClarificationHistoryData?.invoiceClarificationHistory?.length;
    this.showInvoiceClarificationHistory = notesCount && notesCount > 0 ? true : false;
  }
}
