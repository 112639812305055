import { Pipe, PipeTransform } from '@angular/core';
import { toRegex } from '@core/utils';

/**
 * Highlight text based on search input value
 */
@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string): string {
    const pattern = search
      .split(' ')
      .filter((t) => t.length > 0)
      .join('|');

    // Create a regex that is diacritic insensitive
    const regex = toRegex({ flags: 'gi' })(pattern);

    return search
      ? '<span>' + text.replace(regex, (match) => `<span class='highlight'>${match}</span>`) + '</span>'
      : text;
  }
}
