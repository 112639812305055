import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ConfigurationService } from '../configuration';
import { ContextDataService } from '../context-data';

@Injectable({ providedIn: 'root' })
export class ChatbotService {
  private excludedBrowsers: string[] = ['MSIE', 'Trident/7.0;'];

  constructor(private configuration: ConfigurationService, private contextDataService: ContextDataService) {}

  public injectChatbot(): void {
    const excludedPartnerIds = this.configuration.chatbotExcludedBusinessPartnerIds;
    const currentUserPartnerId = environment.isLocal
      ? '123'
      : this.contextDataService.userInfo.dealer.dealerExtension.businessPartnerId;

    if (
      this.contextDataService.data.tenant != 'VolkswagenFinanzdienstleistungen' ||
      this.contextDataService.identityProvider != 'grpisc' ||
      this.excludedBrowsers.some((b) => window.navigator.userAgent.includes(b)) ||
      excludedPartnerIds.includes(currentUserPartnerId)
    ) {
      return;
    }

    const headElement = document.getElementsByTagName('head')[0];

    const userIdContent =
      'const UserID = (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295).toString(36).substring(2, 15) + (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295).toString(36).substring(2, 15); var mercuryUser = { userId: UserID, context: {},};';

    // Add userId variable
    headElement.appendChild(this.createJsElement(undefined, undefined, undefined, userIdContent, false));

    // Add .js script to head
    headElement.appendChild(
      this.createJsElement(
        'https://cdn.mercury.ai/widget/v4/widget.js',
        'mercury-widget-snippet',
        new Map<string, string>([['data-key', environment.chatbotDataKey]])
      )
    );

    // Add css styles
    headElement.appendChild(
      this.createCssStyleElement(`https://cdn.mercury.ai/channels/widget/${environment.chatbotDataKey}/style.css`)
    );
    headElement.appendChild(
      this.createCssStyleElement(
        'https://cdn.bronson.vwfs.tools/3rd-party-ui-extensions/chatbot/latest/v4/bluelabel/css/widget.css'
      )
    );
  }

  private createCssStyleElement(href: string): HTMLLinkElement {
    const node = document.createElement('link');
    node.href = href;
    node.rel = 'stylesheet';
    node.type = 'text/css';

    return node;
  }

  private createJsElement(
    src: string | undefined = undefined,
    id: string | undefined = undefined,
    additionalAttributes: Map<string, string> | undefined = undefined,
    innerContentText: string | undefined = undefined,
    includeType: boolean = true
  ): HTMLScriptElement {
    const node = document.createElement('script');
    if (src) {
      node.src = src;
    }
    if (includeType) {
      node.type = 'application/javascript';
    }
    node.async = true;
    if (id) {
      node.id = id;
    }
    if (additionalAttributes) {
      additionalAttributes.forEach((attrValue: string, attrName: string) => {
        node.setAttribute(attrName, attrValue);
      });
    }
    node.text = innerContentText ?? '';

    return node;
  }
}
