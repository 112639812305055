import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ConfigurationService, LocaleFormatService, RequestStateStore, TenantSettingsService } from '@core/services';
import { PositionConfigurationService } from 'app/components/positions';
import { sort } from 'app/components/sort';
import { RequestService } from 'app/pages/request/request.service';
import { ActivityType } from 'app/pages/request/request.types';
import { UnsavedChangesStateStore } from 'app/pages/request/unsaved-changes-state-store.service';
import { GenericPositionComponent } from '../generic-position';

@Component({
  selector: 'warranty-generic-position',
  template: ''
})
export class WarrantyGenericPositionComponent extends GenericPositionComponent {
  isGermanWarrantyEditMode: boolean = this.tenantSettingsService.warrantyEditMode === 'GermanWarranty';
  showDeleteButton: boolean = !this.isGermanWarrantyEditMode;

  constructor(
    formBuilder: UntypedFormBuilder,
    positionConfigurationService: PositionConfigurationService,
    requestStore: RequestStateStore,
    requestService: RequestService,
    unsavedChangesStateStore: UnsavedChangesStateStore,
    localeFormatService: LocaleFormatService,
    tenantSettingsService: TenantSettingsService,
    configurationService: ConfigurationService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(
      formBuilder,
      positionConfigurationService,
      12,
      requestStore,
      unsavedChangesStateStore,
      requestService,
      localeFormatService,
      configurationService,
      tenantSettingsService,
      changeDetector,
      elementRef
    );
  }

  override get isSparePartDenied(): boolean {
    return this.isGermanWarrantyEditMode || super.isSparePartDenied;
  }

  override get isActivityTypeDisabled() {
    if (this.positionData.templateName === 'WarrantyPositionViewModel') {
      return !this.isGermanWarrantyEditMode && super.isActivityTypeDisabled;
    }
    return this.isGermanWarrantyEditMode || super.isActivityTypeDisabled;
  }

  override get isGoodWillDisabled() {
    return this.isGermanWarrantyEditMode
      ? this.positionData.templateName === 'WarrantyDiagnosticPositionViewModel'
      : super.isGoodWillDisabled;
  }

  updateActivityTypeKey(value: number) {
    this.requestStore.addEventMessage({
      eventName: 'ActivityTypeUpdated',
      payload: { activityType: value, positionId: this.positionData.positionId }
    });
  }

  protected assignPositionUnits(): void {
    const units = this.getPositionUnits();
    if (units.length > 0) {
      this.positionData.quantityUnit = units[0].id;
    }
  }

  override selectActivityTypes() {
    if (!this.isGermanWarrantyEditMode) {
      super.selectActivityTypes();
      return;
    }
    const selectedItems: ActivityType[] = [];
    const classKey = this.positionData.activityTypeClassKey;

    this.availableActivityTypes.forEach((activityType) => {
      if (!this.isMatchingItemType(activityType.itemType, this.positionData.itemType)) {
        return;
      }

      const isInArray = activityType.activityTypeClasses.some((activitTypeClass) => activitTypeClass.id === classKey);
      if (!this.hasExternalProductNumber && !isInArray) {
        return;
      }

      selectedItems.push(activityType);
    });

    this.activityTypes = sort(
      selectedItems,
      'description',
      this.requestContent.isActivityTypesSortingAscending ? 'asc' : 'desc'
    );
  }
}
