import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getUrlParamsFromObject } from '@core/utils';
import { environment } from 'environments/environment';
import { InvoiceClarificationCancelSummary } from './cancel.types';

@Injectable({
  providedIn: 'root'
})
export class InvoiceClarificationCancelService {
  constructor(private httpClient: HttpClient) {}

  getInvoiceClarificationCancelSummary(
    invoiceClarificationId: string | null,
    productType: string | null,
    editOptions: string | null,
    requestGuid: string | null
  ) {
    const urlParameters = getUrlParamsFromObject({
      invoiceClarificationId: invoiceClarificationId,
      productType: productType,
      editOptions: editOptions,
      requestGuid: requestGuid
    });

    return this.httpClient.get<InvoiceClarificationCancelSummary>(
      `${environment.isportCoreApiUrl}/api/invoiceclarification/cancel?${urlParameters}`
    );
  }

  confirmCancelInvoiceClarification(invoiceClarificationCancelSummary: InvoiceClarificationCancelSummary) {
    return this.httpClient.delete<InvoiceClarificationCancelSummary>(
      `${environment.isportCoreApiUrl}/api/invoiceclarification`,
      { body: invoiceClarificationCancelSummary }
    );
  }
}
