import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { SendingRestrictedModule } from 'app/components/sending-restricted/sending-restricted.module';
import { ValidationSummaryModule } from 'app/components/validation-summary';
import { RequestConfirmationModule } from './confirmation/confirmation.module';
import { RequestEditModule } from './edit/edit.module';
import { RequestQuestionnaireModule } from './questionnaire/questionnaire.module';
import { RequestComponent } from './request.component';
import { ProcessBarModule } from './shared/process-bar/process-bar.module';
import { RequestSummaryModule } from './summary/summary.module';

@NgModule({
  imports: [
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    PageHeaderModule,
    PipesModule,
    ProcessBarModule,
    RequestEditModule,
    RequestQuestionnaireModule,
    RequestSummaryModule,
    RequestConfirmationModule,
    ValidationSummaryModule,
    SendingRestrictedModule,
    SharedModule
  ],
  exports: [],
  declarations: [RequestComponent],
  providers: []
})
export class RequestModule {}
