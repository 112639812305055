import { DateTime } from 'luxon';

export function isNullUndefinedEmpty(value?: string | null) {
  return value === undefined || value === null || value === '';
}

export function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getUrlParamsFromObject(model: any): URLSearchParams {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries<string>(model)) {
    if (!value) {
      continue;
    }

    let val = value;
    if (DateTime.isDateTime(value)) {
      val = (value as DateTime).toISODate();
    }

    searchParams.append(key, val);
  }

  return searchParams;
}

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function deCapitalizeFirstLetter(value: string) {
  return value.charAt(0).toLowerCase() + value.slice(1);
}

export function groupBy(list: any, keyGetter: any) {
  const map = new Map();
  list.forEach((item: any) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

/**
 * formats a string to replace params c# style (stringFormat("hello{0}{1}","World","!")) would return helloWorld!
 * @param str the string to be formatted
 * @param args the arguments in order of the index that they will replace
 */
export function stringFormat(str: string, ...args: string[]) {
  return str.replace(/{(\d+)}/g, (_, index) => args[index] || '');
}

export function jsonPropertyNamesToLowerCase(jsonData: string): string {
  return jsonData.replace(/"([^"]+)":/g, function (_$0, $1) {
    return '"' + $1.toLowerCase() + '":';
  });
}

export function hintReplace(value: string, propertyName: string): string {
  return value.replace('{PropertyName}', propertyName);
}

export function ensureInclusiveBetween(value: number, min: number, max?: number, throwException?: boolean) {
  // ignore userCulture here and use international culture to ensure consistent numbers
  value = Math.round((value + Number.EPSILON) * 100) / 100;
  min = Number(min);
  max = Number(max);

  throwException = throwException === true;

  min = !isNaN(min) ? min : Number.MIN_VALUE;
  // defaulting to less than 1e20 prevents js converting to e-notation and causing errors in BL. 1e9 should be a reasonable max assumption.
  max = !isNaN(max) ? max : 1e9;

  if (isNaN(value)) {
    if (throwException) {
      throw Error("'" + value + "' is not a number.");
    }
    value = 0;
  }

  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }

  return value;
}

/**
 * Improved unicode base64 encoding:
 * See https://stackoverflow.com/a/30106551
 */
export function base64EncodeUnicode(value: string) {
  return window.btoa(
    encodeURIComponent(value).replace(/%([0-9A-F]{2})/g, function (_match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
}

/**
 * Improved unicode base64 decoding:
 * See https://stackoverflow.com/a/30106551
 */
export function base64DecodeUnicode(value: string) {
  return decodeURIComponent(
    Array.prototype.map
      .call(window.atob(value), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

export function generateRandomString(): string {
  return (
    (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295).toString(36).substring(2, 15) +
    (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295).toString(36).substring(2, 15)
  );
}
