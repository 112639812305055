import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { PwaCacheService } from '../pwa/pwa-cache.service';
import { BriefInboxMessageCommon, NotificationMessageCommon } from './notification.types';
import { RequestStateStore } from '../request-state-store';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private httpClient: HttpClient,
    private translocoService: TranslocoService,
    private pwaCacheService: PwaCacheService,
    private requestStore: RequestStateStore
  ) {}

  getNotifications(tenantName: string, dealerDbId: number, userId: string): Observable<BriefInboxMessageCommon[]> {
    const url = `${environment.isportCoreApiUrl}/api/notifications`;
    const httpParams = new HttpParams()
      .set('tenantName', tenantName)
      .set('dealerDbId', dealerDbId)
      .set('userId', userId);

    return this.httpClient.get<BriefInboxMessageCommon[]>(url, { params: httpParams });
  }

  processNotificationMessage(notificationMessage: NotificationMessageCommon): void {
    // Refresh data based on notification content
    this.pwaCacheService
      .clearApiDataCache(['statusoverview', 'invoiceclarification', 'aftersales', 'openrequest'])
      .subscribe({
        complete: () => {
          this.requestStore.addEventMessage({ eventName: 'RequestStateUpdated', payload: notificationMessage });
        }
      });
  }

  translateNotificationMessage(notificationMessage: string): string {
    return this.findAndTranslateResource(notificationMessage);
  }

  private findAndTranslateResource(message: string): string {
    // find every rr() and save it as key in a dictionary/map
    const foundResources = this.getMapOfResources(message);
    for (const key of foundResources.keys()) {
      let currentRes = foundResources.get(key);
      currentRes = currentRes.includes('{') ? currentRes.replace('{', '${') : currentRes;
      const translatedMessagePart = this.translocoService.translate(currentRes);
      //  replace each resource reference with the translation
      message = message.replace(key, translatedMessagePart);
    }
    return message;
  }

  getMapOfResources(str: string) {
    const map = new Map();
    const findRes = str.split(')');
    for (const substr of findRes) {
      if (substr.includes('rr(')) {
        const res = substr.substring(substr.indexOf('rr('));
        map.set(res + ')', res.replace('rr(', '').replace(')', ''));
      }
    }
    return map;
  }
}
