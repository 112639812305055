<ng-container *ngIf="baseProductHourlyRates">
  <mat-card class="w-full" *transloco="let t">
    <mat-card-title id="CategorizedHourlyRatesTitle" class="ui-test--base-product-hourly-rates-mat-card-title-title">
      {{ t('Account_UserProfile_Categorized_Hourly_Rates_Title') }}
    </mat-card-title>
    <mat-card-content>
      <table
        aria-describedby="CategorizedHourlyRatesTitle"
        [dataSource]="baseProductHourlyRates.entries"
        mat-table
        class="w-full">
        <ng-container matColumnDef="dealerNumber">
          <th class="ui-test--base-product-hourly-rates-table-header-dealer-number" mat-header-cell *matHeaderCellDef>
            {{ t('Account_UserProfile_Hourly_Rates_DealerNumber') }}
          </th>
          <td
            [class]="'ui-test--base-product-hourly-rates-table-body-' + element.dealerBrand"
            mat-cell
            *matCellDef="let element">
            {{ t('Common_DealerBrand_' + element.dealerBrand) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="netWagePerHourPrice">
          <th class="ui-test--base-product-hourly-rates-table-head-standard-rate" mat-header-cell *matHeaderCellDef>
            {{ t('Account_UserProfile_Hourly_Rates_DefaultDealerNetWagePerHourPrice') }}
          </th>
          <td
            [class]="'ui-test--base-product-hourly-rates-table-body-standard-rate-' + element.dealerBrand"
            mat-cell
            *matCellDef="let element">
            {{ element.defaultDealerNetWagePerHourPrice.value | currencyOrDash }} {{ t('Common_CurrencySymbol_' +
            baseProductHourlyRates.currency) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="dropdownChoose">
          <th class="ui-test--base-product-hourly-rates-table-head-dropdown" mat-header-cell *matHeaderCellDef>
            <mat-select [(value)]="selectedBaseProduct" (selectionChange)="selectedBaseProduct = $event.value">
              <mat-option
                [value]="option.value"
                *ngFor="let option of baseProductHourlyRates.baseProductNames"
                [class]="'ui-test--base-product-hourly-rates-mat-option-' + option.text">
                {{ option.text }}
              </mat-option>
            </mat-select>
          </th>
          <td
            [class]="'ui-test--base-product-hourly-rates-mat-option-option-' + element.dealerBrand"
            mat-cell
            *matCellDef="let element">
            {{ getBaseProductHourlyRate(element.dealerNumber, element.dealerBrand, t('Common_CurrencySymbol_' +
            baseProductHourlyRates.currency)) }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</ng-container>
