import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { InsightsService } from '../services/insights/insights.service';

@Injectable({ providedIn: 'root' })
export class InsightsErrorHandler implements ErrorHandler {
  constructor(@Inject(Injector) private injector: Injector) {}

  /**
   * To avoid circular dependency we need to resolve via injector
   */
  private get insightService(): InsightsService {
    return this.injector.get(InsightsService);
  }

  /**
   * Implemented inherited method from global error handler to log into app insights.
   * Any is provided by the `ErrorHandler` interface. Can not change this.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any): void {
    this.insightService.logException(error);
  }
}
