import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ParseDatePipe } from '@core/pipes';
import { DateTime } from 'luxon';
import { MissingParameter } from '../offer-panel.types';
import { ParameterTypes } from './offer-panel-choose-dialog.types';

@Component({
  selector: 'offer-criteria-choose-dialog',
  templateUrl: './offer-criteria-choose-dialog.component.html'
})
export class OfferCriteriaChooseDialogComponent implements OnInit {
  @Input() missingParameters: MissingParameter[] = [];

  public today: DateTime = DateTime.now();
  public offerCriteriaSearchForm: FormGroup = this.formBuilder.group({});
  public parameterTypes: ParameterTypes = {
    string: 'String',
    dateTime: 'DateTime',
    boolean: 'Boolean',
    enum: 'Enum'
  };

  constructor(
    private dialogRef: MatDialogRef<OfferCriteriaChooseDialogComponent>,
    private formBuilder: FormBuilder,
    private parseDatePipe: ParseDatePipe
  ) {}

  ngOnInit() {
    this.buildDynamicallyControlsAndValidators();
  }

  public onSearch() {
    Object.keys(this.offerCriteriaSearchForm.controls).forEach((controlName: string) => {
      const control: AbstractControl = this.offerCriteriaSearchForm.controls[controlName];
      const index = this.missingParameters?.findIndex((item) => item.key == controlName);
      if (index > -1) {
        this.missingParameters[index].value = control.value;
      }
    });
    this.dialogRef.close(this.missingParameters);
  }

  /**
   * Used as a workaround to trim the value of the "ModelName" Input after the user
   * left the input field.
   */
  public onBlur(name: string | null) {
    if (name !== 'ModelName') {
      return;
    }

    const value: string = this.offerCriteriaSearchForm.get('ModelName')?.value;
    this.offerCriteriaSearchForm.patchValue({
      ModelName: value.trim().substring(0, 20)
    });
  }

  updateDate(event: FocusEvent, controlName: string) {
    const input = event.target as HTMLInputElement;
    this.offerCriteriaSearchForm.get(controlName)?.setValue(this.parseDatePipe.transform(input.value));
  }

  private buildDynamicallyControlsAndValidators() {
    if (!this.offerCriteriaSearchForm) {
      return;
    }
    const licensePlateRegExp = /[a-zäöüA-ZÄÖÜ\d\s-]+/;
    const serviceAdvisorRegexExp = /^[A-Za-z]\d{4}/;
    const modelCaseRegexExp = '^[a-zA-Z0-9]{6}$';
    const vendorRegexExp = '^[0-9]{5}$';

    this.missingParameters.forEach((control) => {
      switch (control.key) {
        case 'ServiceAdvisorNumber':
          this.offerCriteriaSearchForm.addControl(
            control.key,
            new FormControl('', [
              Validators.pattern(serviceAdvisorRegexExp),
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(5)
            ])
          );
          break;
        case 'ModelCode':
          this.offerCriteriaSearchForm.addControl(
            control.key,
            new FormControl('', [Validators.pattern(modelCaseRegexExp), Validators.required])
          );
          break;
        case 'LicensePlateNumber':
          this.offerCriteriaSearchForm.addControl(
            control.key,
            new FormControl('', [
              Validators.minLength(1),
              Validators.maxLength(10),
              Validators.pattern(licensePlateRegExp),
              Validators.required
            ])
          );
          break;
        case 'VendorNumber':
          this.offerCriteriaSearchForm.addControl(
            control.key,
            new FormControl('', [Validators.pattern(vendorRegexExp), Validators.required])
          );
          break;
        default:
          if (control.parameterType == this.parameterTypes.string) {
            this.offerCriteriaSearchForm.addControl(control.key, this.formBuilder.control('', Validators.required));
          } else {
            this.offerCriteriaSearchForm.addControl(
              control.key,
              this.formBuilder.control(control.value, Validators.required)
            );
          }
      }
      // Prefill values from last session
      if (control.value) {
        this.offerCriteriaSearchForm.get(control.key)?.setValue(control.value);
      }
    });
  }
}
