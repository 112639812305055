import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageHeaderComponent {
  @Input() title!: string;
  @Input() hint!: string;
}
