<div
  *transloco="let t"
  [style.display]="positionData.isPositionMarkedAsDeleted ? 'none': ''"
  [ngClass]="['indent-level-' + positionData.indentLevel]">
  <mat-expansion-panel hideToggle #positionsPanel *transloco="let t">
    <mat-icon
      class="request-position-icon ui-test--request-warranty-spare-part-position-mat-icon-warranty-spare-part-position"
      [ngClass]="['zoom-level-' + visibleRows]">
      {{ positionData.itemType | positionIcon }}
    </mat-icon>

    <mat-expansion-panel-header
      #positionsHeader
      (click)="positionsHeader._toggle()"
      [class.validation-error]="hasValidationError || positionData.validationErrors?.size">
      <mat-panel-title>
        <div class="flex-row flex-grow-100 justify-between content-center align-center">
          <div class="flex-grow-10">
            <small class="mr-10 ui-test--request-warranty-spare-part-position-small-labor-position-index">
              {{ positionData.laborPositionIndex }}
            </small>
            <small class="ui-test--request-warranty-spare-part-position-small-abbreviation">
              {{ t('Common_WarrantySparePartAbbreviation') }}
            </small>
          </div>

          <small class="flex-grow-10 ui-test--request-warranty-spare-part-position-small-external-product-number">
            {{ positionData.externalProductNumber }}
          </small>

          <small class="flex-grow-20 ui-test--request-warranty-spare-part-position-small-description">
            {{ displayDescription() }}
          </small>

          <small
            class="flex-grow-10 ui-test--request-warranty-spare-part-position-small-quantity"
            *ngIf="isEnabled(positionData, 'Quantity')">
            {{ t('Common_QuantityAbbreviation') }}: {{ displayQuantityWithUnit }}
          </small>

          <div class="flex-grow-10">
            <ng-container *ngIf="isEnabled(positionData, 'Discount')">
              <small class="ui-test--request-warranty-spare-part-position-small-discount">
                {{ t('Common_DiscountAbbreviation') }}: {{ displayDiscount }}
              </small>
            </ng-container>
            <ng-container *ngIf="isEnabled(positionData, 'Goodwill')">
              <small class="ui-test--request-warranty-spare-part-position-small-goodwill">
                {{ t('Common_GoodwillAbbreviation') }}: {{ displayGoodwill }}
              </small>
            </ng-container>
            <ng-container *ngIf="isEnabled(positionData, 'MaterialGoodwill')">
              <small class="ui-test--request-warranty-spare-part-position-small-material-goowill">
                {{ t('Common_MaterialGoodwillAbbreviation') }}: {{ displayMaterialGoodwill }}
              </small>
            </ng-container>
          </div>

          <small
            class="flex-grow-10 justify-end content-center align-center ui-test--request-warranty-spare-part-position-small-amount"
            *ngIf="isEnabled(positionData, 'UnitPrice') || isEnabled(positionData, 'NetFixedPrice')">
            <strong class="ui-test--request-warranty-spare-part-position-strong-amount">
              {{ t('Common_Total') }}: {{ netAmount | currencyOrDash }}
            </strong>
          </small>

          <mat-action-row class="justify-end content-center">
            <button
              class="ui-test--request-warranty-spare-part-position-button-save"
              mat-raised-button
              (click)="savePosition()">
              <mat-icon class="ui-test--request-warranty-spare-part-position-mat-icon-save">save</mat-icon>
            </button>
            <button
              mat-raised-button
              class="delete ui-test--request-warranty-spare-part-position-button-delete"
              [disabled]="positionData.isInnerPosition"
              (click)="removePosition()"
              *ngIf="showDeleteButton">
              <mat-icon class="ui-test--request-warranty-spare-part-position-mat-icon-delete">delete</mat-icon>
            </button>
            <button
              class="ui-test--request-warranty-spare-part-position-button-expand"
              mat-raised-button
              click-stop-propagation
              (click)="togglePosition(true)">
              <mat-icon class="ui-test--request-warranty-spare-part-position-mat-icon-expand">
                {{ positionsPanel.expanded ? 'close_fullscreen' : 'open_in_full' }}
              </mat-icon>
            </button>
          </mat-action-row>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <form [formGroup]="positionFormGroup">
      <div class="flex-row justify-between content-center">
        <div class="flex-grow-25 flex-offset-15">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[0]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[1]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[2]"></ng-container>
        </div>
      </div>
      <div class="flex-row justify-between content-center">
        <div class="flex-grow-25 flex-offset-15">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[3]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[4]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[5]"></ng-container>
        </div>
      </div>
      <div class="flex-row justify-between content-center">
        <div class="flex-grow-25 flex-offset-15">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[6]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[7]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[8]"></ng-container>
        </div>
      </div>
      <div class="flex-row justify-between content-center">
        <div class="flex-grow-25 flex-offset-15">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[9]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[10]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[11]"></ng-container>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
</div>

<ng-template name="activityTypeKey">
  <dropdown-with-search
    class="ui-test--request-warranty-spare-part-position-dropdown-with-search-activity"
    [labelResourceKey]="positionData.templateName + '_ActivityTypeKey'"
    formControlName="ActivityTypeKey"
    [dropDownOptions]="{
      options: activityTypes,
      optionsValue: 'id',
      optionsText: 'description'
    }"
    [isDisabled]="isActivityTypeDisabled"
    [(value)]="positionData.activityTypeKey"
    [validators]="[Validators.required]"
    [sharedFormGroup]="positionFormGroup"
    (valueChange)="updateActivityTypeKey($event)"></dropdown-with-search>
</ng-template>

<ng-template name="externalProductNumber">
  <input-group
    class="ui-test--request-warranty-spare-part-position-input-group-external-product-number"
    [viewModelName]="positionData.templateName"
    name="ExternalProductNumber"
    [maxLength]="30"
    [value]="positionData.externalProductNumber"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editEpn($event, ['WarrantyPosition'])"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="quantity">
  <input-group
    class="ui-test--request-warranty-spare-part-position-input-group-quantity"
    [viewModelName]="positionData.templateName"
    name="Quantity"
    type="number"
    [inputSuffix]="displayQuantityUnit()"
    [validators]="{required: true, min: configurationService.sparePartQuantityFrom, max: configurationService.sparePartQuantityTo} | validators"
    [isDisabled]="hasNoComponent || hasNetFixedPrice"
    [value]="positionData.quantity"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editQuantity($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="unitPrice">
  <input-group
    class="ui-test--request-warranty-spare-part-position-input-group-unity-price"
    [viewModelName]="positionData.templateName"
    name="UnitPrice"
    type="currency"
    [inputSuffix]="requestContent.requestProcess.displayCurrencyUnit"
    [validators]="{required: true, min: configurationService.positionUnitPriceFrom, max: configurationService.positionUnitPriceTo} | validators"
    [isDisabled]="disablePriceEdit || hasNoComponent || hasNetFixedPrice"
    [value]="positionData.unitPrice"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editUnitPrice($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="netFixedPrice">
  <input-group
    class="ui-test--request-warranty-spare-part-position-input-group-net-fixed-price"
    [viewModelName]="positionData.templateName"
    name="NetFixedPrice"
    type="currency"
    [inputSuffix]="requestContent.requestProcess.displayCurrencyUnit"
    [validators]="{required: true, min: configurationService.warrantySparePartPositionNetFixedPriceFrom, max: configurationService.warrantySparePartPositionNetFixedPriceTo} | validators"
    [isDisabled]="isNetFixedPriceDisabled"
    [value]="positionData.netFixedPrice"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editNetFixedPrice($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="discount">
  <input-group
    class="ui-test--request-warranty-spare-part-position-input-group-discount"
    [viewModelName]="positionData.templateName"
    name="Discount"
    type="percent"
    [isDisabled]="hasNoComponent"
    [validators]="{min: configurationService.positionDiscountFrom, max: configurationService.positionDiscountTo} | validators"
    [value]="positionData.discount"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editDiscount($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="goodwill">
  <input-group
    class="ui-test--request-warranty-spare-part-position-input-group-goodwill"
    [viewModelName]="positionData.templateName"
    name="Goodwill"
    type="percent"
    [isDisabled]="hasNoComponent"
    [validators]="{min: configurationService.positionGoodwillFrom, max: configurationService.positionGoodwillTo} | validators"
    [value]="positionData.goodwill"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editGoodwill($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="sagaId">
  <input-group
    class="ui-test--request-warranty-spare-part-position-input-group-saga-id"
    [viewModelName]="positionData.templateName"
    name="SagaId"
    [maxLength]="32"
    [value]="positionData.sagaId"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editSagaId($event)"
    *ngIf="enableSagaId"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="damageType">
  <dropdown-with-search
    class="ui-test--request-warranty-spare-part-position-dropdown-with-search-damage-type"
    [labelResourceKey]="positionData.templateName + '_DamageType'"
    formControlName="DamageType"
    [isDisabled]="hasNoComponent"
    [(value)]="positionData.damageTypeId"
    [validators]="[Validators.required]"
    [dropDownOptions]="{
      options: sortedDamageTypes(),
      optionsText: 'displayText',
      optionsValue: 'id'
    }"
    [sharedFormGroup]="positionFormGroup"
    *ngIf="isDamageTypeRequired"></dropdown-with-search>
</ng-template>

<ng-template name="none"></ng-template>
