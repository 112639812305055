import { Component, Input, OnInit } from '@angular/core';
import { ActivityTypeIdentifierCommon } from 'app/components/positions';
import { RequestContent } from '../../request.types';
import { EventPosition } from './events.types';

@Component({
  selector: 'request-events',
  templateUrl: './events.component.html'
})
export class RequestSummaryEventsComponent implements OnInit {
  @Input() requestContent!: RequestContent;
  @Input() eventKeys: ActivityTypeIdentifierCommon[] = [];

  eventPositions: EventPosition[] = [];

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.setupEventPositions();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private setupEventPositions() {
    // Go through event keys and prepare all event positions into template array
    this.eventKeys.forEach((key) => {
      const foundPositions = this.requestContent.requestPositions
        .filter((position) => position.itemType === key && position.activityTypeKey)
        .map(
          (position) =>
            <EventPosition>{
              positionId: position.positionId,
              activityTypeClassKey: position.activityTypeClassKey,
              activityTypeDescription: position.activityTypeDescription,
              laborPositionIndex: position.laborPositionIndex
            }
        );

      if (foundPositions.length > 0) {
        // If there are found positions sort them based on laborPositionIndex
        foundPositions.sort((a: EventPosition, b: EventPosition) => a.laborPositionIndex - b.laborPositionIndex);

        // Copy found positions into template array
        this.eventPositions = [...this.eventPositions, ...foundPositions];
      }
    });

    // Apply activity type class to all found positions based on availableActivityTypeClasses
    const availableClasses = this.requestContent.availableActivityTypeClasses ?? [];
    this.eventPositions.forEach((eventPosition) => {
      const eventActivityTypeClass = availableClasses.find(
        (activityType) => activityType.id === eventPosition.activityTypeClassKey
      );

      eventPosition.activityTypeClass = eventActivityTypeClass
        ? eventActivityTypeClass.description
        : eventPosition.activityTypeClassKey.toString();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  eventIdentifier(_index: number, item: EventPosition) {
    return item.positionId;
  }
}
