import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestStateStore, TenantSettingsService } from '@core/services';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { PaginationComponent } from 'app/components/pagination/pagination.component';
import { sort } from 'app/components/sort/sort.helper';
import { SortEvent } from 'app/components/sort/sort.types';
import { StatusDetailsDialogService } from 'app/pages/status-overview/status-details-dialog/status-details-dialog.service';
import { Subject, takeUntil } from 'rxjs';
import { StatusDetailsCloseStatus } from '../status-overview';
import { RequestDuplicatesService } from './request-duplicates.service';
import { RequestDuplicate, RequestDuplicateData, RequestDuplicateSortKeys } from './request-duplicates.types';

@Component({
  selector: 'request-duplicates',
  templateUrl: './request-duplicates.component.html',
  styleUrls: ['./request-duplicates.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RequestDuplicatesComponent implements OnInit, OnDestroy {
  @ViewChild('paginatorTop') paginatorTop!: PaginationComponent;
  @ViewChild('paginatorBottom') paginatorBottom!: PaginationComponent;

  errorList!: string[];
  requestDuplicateData: RequestDuplicateData[] = [];
  period: number;
  searchParams: URLSearchParams;
  createRequestLoading = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  sortKeys = RequestDuplicateSortKeys;
  sortDirection: SortDirection = 'desc';
  sortKey = RequestDuplicateSortKeys[0][0];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tenantSettingsService: TenantSettingsService,
    private statusDetailsDialogService: StatusDetailsDialogService,
    private requestStateStore: RequestStateStore,
    private requestDuplicateService: RequestDuplicatesService
  ) {
    this.searchParams = this.route.snapshot.data[0]['searchParams'];
    this.requestDuplicateData = this.route.snapshot.data[0]['requestDuplicates'].map(
      (requestDuplicate: RequestDuplicate) => new RequestDuplicateData(requestDuplicate)
    );
    this.period = this.tenantSettingsService.requestDoubletCheckingPeriod;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    if (this.requestDuplicateData.length === 0) {
      this.newRequest();
    }
    this.requestStateStore.requestEventMessages.pipe(takeUntil(this.destroy$)).subscribe((msg) => {
      if (msg.eventName == 'RequestStateUpdated') {
        this.refreshData();
      }
    });
    if (!this.paginatorTop) {
      return;
    }
    this.showPage(0, this.paginatorTop.pageSize);
  }

  newRequest(): void {
    this.router.navigateByUrl(`/request/create?${this.searchParams.toString()}`);
  }

  openStatusDetailsDialog(requestGuid: string, productType: ProductTypeIdentifier): void {
    const statusDetailsDialogRef = this.statusDetailsDialogService.openDialog(requestGuid, productType, '');

    statusDetailsDialogRef.afterClosed().subscribe((result: StatusDetailsCloseStatus) => {
      if (result === StatusDetailsCloseStatus.DeleteFromOpenProcesses) {
        this.refreshData();
      }
    });
  }

  sort(event: SortEvent): void {
    this.sortDirection = event.direction;
    this.sortKey = event.key;
    sort(this.requestDuplicateData, event.key, event.direction);
    this.showPage(this.paginatorTop.pageIndex, this.paginatorTop.pageSize);
  }

  onPaginatorChange(event: PageEvent) {
    this.showPage(event.pageIndex, event.pageSize);
  }

  private showPage(pageIndex: number, pageSize: number): void {
    if (this.paginatorBottom && this.paginatorTop) {
      this.paginatorBottom.pageIndex = this.paginatorTop.pageIndex = pageIndex;
      this.paginatorBottom.pageSize = this.paginatorTop.pageSize = pageSize;
    }

    const startPosition = pageIndex * pageSize;
    this.requestDuplicateData.forEach((entry, index) => {
      entry.show = index >= startPosition && index < startPosition + pageSize;
    });
  }

  private refreshData(): void {
    this.requestDuplicateService.getRequestDuplicates(this.searchParams).subscribe((res: RequestDuplicate[]) => {
      if (res.length === 0) {
        this.newRequest();
        return;
      }
      this.requestDuplicateData = res.map(
        (requestDuplicate: RequestDuplicate) => new RequestDuplicateData(requestDuplicate)
      );
      this.sort({ direction: this.sortDirection, key: this.sortKey });
    });
  }
}
