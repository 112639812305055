import { Component, Input } from '@angular/core';
import { StatusDetails } from '../status-details-dialog.types';

@Component({
  selector: 'status-details-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class StatusDetailsEventsComponent {
  @Input() statusDetails!: StatusDetails;
}
