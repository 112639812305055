<form class="flex wrapper" [formGroup]="filterForm" *transloco="let t">
  <div class="flex-row justify-start align-start content-start flex-wrap w-full gap-10">
    <datepicker
      class="flex flex-base-135 flex-grow date-picker-from ui-test--filter-datepicker-from"
      *ngIf="visibleFilterParameters.includes('datePickerFrom')"
      [control]="filterForm.controls.dateFrom"
      [maxDate]="maxDate"
      [minDate]="fromDateMinimum"
      [placeholderTextValue]="t('Common_From')"></datepicker>

    <datepicker
      class="flex flex-base-135 flex-grow date-picker-to ui-test--filter-datepicker-to"
      *ngIf="visibleFilterParameters.includes('datePickerTo')"
      [control]="filterForm.controls.dateTo"
      [maxDate]="today"
      [minDate]="minDate"
      [placeholderTextValue]="t('Common_To')"></datepicker>
    <div class="flex-row flex-wrap flex-noshrink align-start content-start justify-start gap-15">
      <mat-form-field
        class="flex flex-grow-fixed-177 w-min-fixed-150 select-product-type"
        *ngIf="visibleFilterParameters.includes('productList') && productsList?.length">
        <mat-label class="ui-test--filter-mat-label-all-products">{{t('Common_SelectedProductType')}}</mat-label>
        <mat-select
          class="ui-test--filter-mat-select-products"
          formControlName="products"
          disableOptionCentering
          multiple
          (selectionChange)="productSelect($event)">
          <mat-select-trigger class="flex-row flex-wrap">
            <div
              *ngFor="let product of filterForm.get('products')?.value"
              click-stop-propagation
              class="chip-element gap-3"
              [class]="'ui-test--filter-div-' + product.value">
              <mat-icon click-stop-propagation [class]="'ui-test--filter-mat-icon-' + product.value">
                {{product.value | productTypeIcon}}
              </mat-icon>
              <mat-icon
                class="remove-product-type-filter cursor-pointer"
                click-stop-propagation
                [class]="'ui-test--filter-mat-icon-' + product.value + '-delete'"
                (click)="removeFromSelectedElements(product, 'products')">
                clear
              </mat-icon>
            </div>
          </mat-select-trigger>
          <mat-option
            *ngFor="let product of productsList"
            [class]="[product.text | className, 'ui-test--filter-mat-option-' + product.value + '-option']"
            class="hidden-checkbox"
            [class.display-none]="isSelectedElement(product, 'products')"
            [value]="product">
            <mat-icon [class]="'ui-test--filter-mat-icon-' + product.value">
              {{ product.value | productTypeIcon }}
            </mat-icon>
            <span [class]="'ui-test--filter-span-' + product.value">{{product.text}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="flex flex-grow-fixed-177 w-min-fixed-150 select-request-status"
        *ngIf="visibleFilterParameters.includes('requestStatusList') && statusesList?.length">
        <mat-label class="ui-test--filter-mat-label-all-states">{{t('Common_SelectedStatus')}}</mat-label>
        <mat-select
          class="ui-test--filter-mat-select-states"
          formControlName="statuses"
          disableOptionCentering
          multiple>
          <mat-select-trigger class="flex-row flex-wrap">
            <div
              *ngFor="let status of filterForm.get('statuses')?.value"
              click-stop-propagation
              class="chip-element gap-3"
              [class]="'ui-test--filter-div-' + status.text.toLowerCase()">
              <span
                class="box-select-trigger"
                [ngClass]="[status.text.toLowerCase(), 'ui-test--filter-span-' + status.text.toLowerCase() + '-text']"
                click-stop-propagation></span>
              <mat-icon
                class="remove-request-status-filter cursor-pointer"
                click-stop-propagation
                [class]="'ui-test--filter-mat-icon-' + status.text.toLowerCase() + '-delete'"
                (click)="removeFromSelectedElements(status, 'statuses')">
                clear
              </mat-icon>
            </div>
          </mat-select-trigger>
          <mat-option
            *ngFor="let status of statusesList"
            [class]="[status.value | className, 'ui-test--filter-mat-option-' + status.text.toLowerCase() + '-option']"
            class="hidden-checkbox"
            [class.display-none]="isSelectedElement(status, 'statuses')"
            [value]="status">
            <span
              class="box-option"
              [ngClass]="[status.text.toLowerCase(), 'ui-test--filter-span-' + status.text.toLowerCase() + '-status']"></span>
            {{status.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="flex flex-grow-fixed-177 w-min-fixed-150 select-invoice-status"
        *ngIf="visibleFilterParameters.includes('invoiceStatusList') && invoiceStatusesList?.length">
        <mat-label class="ui-test--filter-mat-label-all-invoice-states">
          {{t('Common_SelectedInvoiceStatus')}}
        </mat-label>
        <mat-select
          class="ui-test--filter-mat-select-invoice-states"
          disableOptionCentering
          formControlName="invoiceStatuses"
          multiple>
          <mat-select-trigger class="flex-row flex-wrap">
            <div
              *ngFor="let invoiceStatus of filterForm.get('invoiceStatuses')?.value"
              click-stop-propagation
              class="chip-element gap-3"
              [class]="'ui-test--filter-div-' + invoiceStatus.text.toLowerCase() + '-invoice'">
              <span
                class="box-select-trigger"
                click-stop-propagation
                [ngClass]="[invoiceStatus.text.toLowerCase(), 'ui-test--filter-span-' + invoiceStatus.text.toLowerCase() + '-text-invoice']"></span>
              <mat-icon
                class="remove-invoice-status-filter cursor-pointer"
                click-stop-propagation
                [class]="'ui-test--filter-mat-icon-' + invoiceStatus.text.toLowerCase() + '-delete-invoice'"
                (click)="removeFromSelectedElements(invoiceStatus, 'invoiceStatuses')">
                clear
              </mat-icon>
            </div>
          </mat-select-trigger>
          <mat-option
            *ngFor="let invoiceStatus of invoiceStatusesList"
            [class]="[invoiceStatus.value | className, 'ui-test--filter-mat-option-' + invoiceStatus.text.toLowerCase() + '-option-invoice']"
            class="hidden-checkbox"
            [class.display-none]="isSelectedElement(invoiceStatus, 'invoiceStatuses')"
            [value]="invoiceStatus">
            <span
              class="box-option"
              [ngClass]="[invoiceStatus.text.toLowerCase(), 'ui-test--filter-span-' + invoiceStatus.text.toLowerCase() + '-status-invoice']"></span>
            {{invoiceStatus.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="flex flex-grow-fixed-250 w-min-fixed-200 select-clarification-status"
        *ngIf="visibleFilterParameters.includes('clarificationStatusList') && clarificationStatusesList?.length">
        <mat-label>{{t('Common_SelectedClarificationStatus')}}</mat-label>
        <mat-select disableOptionCentering formControlName="clarificationStatuses" multiple>
          <mat-select-trigger class="flex-row flex-wrap">
            <div
              *ngFor="let clarificationStatus of filterForm.get('clarificationStatuses')?.value"
              click-stop-propagation
              class="chip-element gap-3">
              <span
                class="box-select-trigger"
                click-stop-propagation
                [ngClass]="clarificationStatus.text.toLowerCase()"></span>
              <mat-icon
                class="remove-clarification-status-filter cursor-pointer"
                click-stop-propagation
                (click)="removeFromSelectedElements(clarificationStatus, 'clarificationStatuses')">
                clear
              </mat-icon>
            </div>
          </mat-select-trigger>
          <mat-option
            *ngFor="let clarificationStatus of clarificationStatusesList"
            [class]="clarificationStatus.value | className"
            class="hidden-checkbox"
            [class.display-none]="isSelectedElement(clarificationStatus, 'clarificationStatuses')"
            [value]="clarificationStatus">
            <span class="box-option" [ngClass]="clarificationStatus.text.toLowerCase()"></span>
            {{clarificationStatus.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <dropdown-with-search
        ngDefaultControl
        class="flex flex-grow-fixed-177 w-min-fixed-150 select-service-advisor ui-test--filter-dropdown-with-search-service-advisor"
        *ngIf="visibleFilterParameters.includes('advisorList') && !isManagingDealer"
        labelResourceKey="Common_SelectedServiceAdvisor"
        formControlName="advisors"
        [dropDownOptions]="advisorsList"
        [highlightText]="true"
        [isMultiSelect]="true"
        [sharedFormGroup]="filterForm"></dropdown-with-search>
      <button
        id="filter-button"
        *ngIf="visibleFilterParameters.includes('searchButton')"
        class="flex-base-100 justify-end search-button ui-test--filter-button-filter"
        mat-raised-button
        color="primary"
        [class.button-spinner]="false">
        <span class="ui-test--filter-span-filter-button">{{ t('Common_Filter') }}</span>
      </button>
    </div>
  </div>
  <ng-content></ng-content>
</form>
