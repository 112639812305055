import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { RequestDuplicate } from './request-duplicates.types';

@Injectable({ providedIn: 'root' })
export class RequestDuplicatesService {
  constructor(private _httpClient: HttpClient) {}

  getRequestDuplicates(searchParams: URLSearchParams) {
    return this._httpClient.get<RequestDuplicate[]>(
      `${environment.isportCoreApiUrl}/api/requestduplicates?${searchParams.toString()}`
    );
  }
}
