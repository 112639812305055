import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.removeVersionAttributes();
    this.overrideFetch();
  }

  private overrideFetch() {
    window.addEventListener('fetch', (event: any) => {
      // Prevent the default, and handle the request ourselves.
      event.respondWith(
        (async () => {
          // Try to get the response from a cache.
          const cache = await caches.open('dynamic-v1');
          const cachedResponse = await cache.match(event.request);

          if (cachedResponse) {
            // If we found a match in the cache, return it, but also
            // update the entry in the cache in the background.
            event.waitUntil(cache.add(event.request));
            return cachedResponse;
          }

          // If we didn't find a match in the cache, use the network.
          return fetch(event.request);
        })()
      );
    });
  }

  private removeVersionAttributes() {
    this.elementRef.nativeElement.removeAttribute('ng-version');
  }
}
