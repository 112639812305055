import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  ConfigurationService,
  LocaleFormatService,
  RequestEventMessage,
  RequestStateStore,
  TenantSettingsService
} from '@core/services';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { UnsavedChangesStateStore } from 'app/pages/request';
import { RequestService } from 'app/pages/request/request.service';
import { WarrantyGenericPositionComponent } from '../warranty-generic-position.component';

@Component({
  selector: 'warranty-diagnostic-position',
  templateUrl: './warranty-diagnostic-position.component.html'
})
export class WarrantyDiagnosticPositionComponent extends WarrantyGenericPositionComponent {
  constructor(
    formBuilder: UntypedFormBuilder,
    positionConfigurationService: PositionConfigurationService,
    requestService: RequestService,
    requestStore: RequestStateStore,
    unsavedChangesStateStore: UnsavedChangesStateStore,
    localeFormatService: LocaleFormatService,
    tenantSettingsService: TenantSettingsService,
    configurationService: ConfigurationService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(
      formBuilder,
      positionConfigurationService,
      requestStore,
      requestService,
      unsavedChangesStateStore,
      localeFormatService,
      tenantSettingsService,
      configurationService,
      changeDetector,
      elementRef
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.eventSubscription = this.requestStore.requestEventMessages.subscribe({
      next: (eventMessage: RequestEventMessage) => {
        switch (eventMessage.eventName) {
          case 'ActivityClassTypeUpdated':
            this.positionData.activityTypeClassKey = eventMessage.payload;
            super.updateActivityTypeClassKey();
            break;
          case 'ActivityTypeUpdated':
            this.positionData.activityTypeKey = eventMessage.payload.activityType;
            this.assignPositionUnits();
            break;
          case 'GoodWillValueUpdated':
            this.positionData.goodwill = eventMessage.payload;
            break;
        }
      }
    });
  }

  override ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }
}
