<ng-container *transloco="let t">
  <ng-container *ngIf="requestSummary; else requestSummaryLoading">
    <!-- Work instructions component -->
    <work-instructions
      step="Request_Summary"
      [currentProductType]="requestContent.requestProcess.editProductType !== 'Unknown' ? requestContent.requestProcess.editProductType : requestContent.requestProcess.productType"></work-instructions>

    <!-- General information component -->
    <mat-divider class="my-10"></mat-divider>
    <request-general-information [requestContent]="requestContent" [isDisplayOnly]="true"></request-general-information>

    <!-- Events component -->
    <ng-container *ngIf="hasEvents">
      <mat-divider class="my-10"></mat-divider>
      <request-events [requestContent]="requestContent" [eventKeys]="eventKeys"></request-events>
    </ng-container>

    <!-- Positions component -->
    <ng-container *ngIf="hasPositions">
      <mat-divider class="my-10"></mat-divider>
      <request-positions-preview
        [requestContent]="requestContent"
        [plausibilitiesResult]="requestSummary.plausibilitiesResult"></request-positions-preview>

      <!-- Position amounts -->
      <request-total-amounts [totalAmounts]="totalAmounts"></request-total-amounts>
    </ng-container>

    <!-- Questionnaire component -->
    <ng-container *ngIf="requestContent.questionnaireGroups.length > 0">
      <mat-divider class="my-10"></mat-divider>
      <request-summary-questionnaire [requestContent]="requestContent"></request-summary-questionnaire>
    </ng-container>

    <!-- Dealer assignment component -->
    <ng-container *ngIf="requestSummary.shouldDealerAssignmentVisible">
      <mat-divider class="my-10"></mat-divider>
      <mat-form-field>
        <mat-label class="ui-test--request-summary-mat-label-dealer-number-bnr-label">
          {{ t('AccountLogOnViewModel_Dealer') }}
        </mat-label>
        <input
          class="ui-test--request-summary-input-dealer-number-bnr"
          matInput
          [(ngModel)]="requestSummary.dealerNumberBnr"
          maxlength="5" />
      </mat-form-field>
    </ng-container>

    <!-- Attachments -->
    <mat-divider class="my-10"></mat-divider>
    <file-upload
      [uploadUrl]="'api/process/request/attachments/' + requestContent.databaseId"
      [fileCategories]="fileCategories"
      [acceptedFileTypes]="requestSummary.acceptedFiles"
      [existingFiles]="requestSummary.attachments"
      (filesChange)="onFilesChange($event)"
      [maxFileSize]="requestSummary.maximumFileUploadSize"
      [isAttachmentUploadActive]="requestSummary.isAttachmentUploadActive"></file-upload>

    <!-- Important information component -->
    <ng-container *ngIf="requestSummary.isImportantNotificationActive">
      <mat-divider class="my-10"></mat-divider>
      <important-information
        [requestEditOptions]="requestSummary.requestContent.requestEditOptions"
        [(userNote)]="requestSummary.requestContent.userNote"
        [(selectedTextModuleId)]="requestSummary.requestContent.selectedTextModuleId"
        (userNoteChange)="activateUnsavedChangesPopup()"
        (selectedTextModuleIdChange)="activateUnsavedChangesPopup()"
        [showTextModule]="requestSummary.showTextModule"
        [textModules]="requestSummary.textModules"></important-information>
    </ng-container>

    <!-- Contract data component -->
    <mat-divider class="my-10"></mat-divider>
    <contact-data
      [contactData]="requestContent.contactData"
      [showSecondaryEmail]="showSecondaryEmail"
      [disableSecondaryEmail]="requestContent.requestEditOptions !== 'Full'"
      [editOptions]="requestContent.requestEditOptions"></contact-data>

    <!-- Footer actions-->
    <mat-divider class="my-10"></mat-divider>
    <div class="flex-row justify-between content-center mt-20">
      <div class="flex-col">
        <div class="flex justify-start content-center">
          <mat-checkbox
            [(ngModel)]="requestSummary.hasAcceptedGuidelines"
            color="primary"
            class="justify-start content-center mr-10 ui-test--request-summary-mat-check-box-accept-guidelines"
            *ngIf="requestSummary.displayWorkshopGuidelinesCheckbox; else hideWorkshopGuidelinesCheckbox">
            {{ t('RequestSummaryViewModel_HasAcceptedGuidelines') }}
          </mat-checkbox>
          <ng-template #hideWorkshopGuidelinesCheckbox>
            <span class="mr-10 ui-test--request-summary-span-accept-guidelines">
              {{ requestSummary.workshopGuidelinesDescription }}
            </span>
          </ng-template>

          <a
            class="ui-test--request-summary-link-accept-guidelines"
            [href]="requestSummary.workshopGuidelinesUrl"
            class="flex justify-start content-center external-url"
            target="_blank"
            rel="noopener"
            *ngIf="requestSummary.displayWorkshopGuidelinesUrlButton">
            <mat-icon color="primary" class="ui-test--request-summary-mat-icon-accept-guidelines">info</mat-icon>
          </a>
        </div>

        <div *ngIf="requestSummary.displayCodeOfConductUrlButton" class="flex justify-start content-center mt-10">
          <span class="mr-10 ui-test--request-summary-span-accept-code-of-conduct">
            {{ requestSummary.codeOfConductDescription }}
          </span>

          <a
            class="ui-test--request-summary-link-accept-code-of-conduct"
            [href]="requestSummary.codeOfConductUrl"
            class="flex justify-start content-center external-url"
            target="_blank"
            rel="noopener">
            <mat-icon color="primary" class="ui-test--request-summary-mat-icon-accept-code-of-conduct">info</mat-icon>
          </a>
        </div>
      </div>

      <div class="flex-row justify-end align-center">
        <button
          mat-stroked-button
          class="mr-5 ui-test--request-summary-button-edit"
          *ngIf="requestContent.requestEditOptions === 'Full'"
          [disabled]="confirmActionLoading"
          [class.button-spinner]="confirmActionLoading && selectedAction === 'Edit'"
          (click)="executeAction('Edit')">
          <mat-icon class="ui-test--request-summary-mat-icon-edit">skip_previous</mat-icon>
          {{ t('Request_Summary_Edit') }}
        </button>

        <button
          mat-stroked-button
          class="mr-5 ui-test--request-summary-button-save"
          [disabled]="confirmActionLoading"
          [class.button-spinner]="confirmActionLoading && selectedAction === 'Save'"
          (click)="executeAction('Save')">
          <mat-icon [class]="'ui-test--request-summary-mat-icon-save'">save</mat-icon>
          {{ t('Request_Edit_Save') }}
        </button>

        <button
          mat-raised-button
          class="continue-button ui-test--request-summary-button-confirm"
          color="primary"
          [disabled]="(userService.isSendingRestricted | async) || confirmActionLoading"
          [class.button-spinner]="confirmActionLoading && selectedAction === 'Confirm'"
          (click)="executeAction('Confirm')">
          <mat-icon class="ui-test--request-summary-mat-icon-confirm">skip_next</mat-icon>
          {{ t('Request_Summary_Confirm') }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #requestSummaryLoading>
  <div class="spinner spinner-small"></div>
</ng-template>
