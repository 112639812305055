import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ConfigurationService, UserService } from '@core/services';
import { deCapitalizeFirstLetter } from '@core/utils';
import { ValidationReader } from '@core/validation-reader';
import { TranslocoService } from '@ngneat/transloco';
import { ContactDataComponent } from 'app/components/contact-data';
import { FileUploadComponent } from 'app/components/file-upload/file-upload.component';
import { ImportantInformationComponent } from 'app/components/important-information/important-information.component';
import { InvoiceClarificationEditService } from './edit.service';
import { EditForm, InvoiceClarification, InvoiceClarificationConfirmation } from './edit.types';

@Component({
  selector: 'invoice-clarification-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class InvoiceClarificationEditComponent {
  @ViewChild(FileUploadComponent) fileUploadComponent?: FileUploadComponent;
  @ViewChild(ImportantInformationComponent) importantInformationComponent!: ImportantInformationComponent;
  @ViewChild(ContactDataComponent) contactDataComponent?: ContactDataComponent;

  showSecondaryEmail = this.configurationService.shouldSecondaryEmailAddressVisible;
  invoiceClarification!: InvoiceClarification;
  saving = false;
  controlsHidden = false;
  errorList: string[] = [];

  isPartialCreditNoteCheckedControl = new FormControl(false);
  isNewInvoiceCheckedControl = new FormControl(false);
  form: FormGroup<EditForm> = this.formBuilder.group<EditForm>({
    internalInvoiceNumber: new FormControl({ value: '', disabled: true }, Validators.required),
    mileage: new FormControl(null, [Validators.min(1), Validators.max(9999999)]),
    licensePlateNumber: new FormControl(''),
    vehicleIdentificationNumber: new FormControl('', Validators.maxLength(17)),
    contractNumber: new FormControl('', Validators.maxLength(100)),
    isPartialCreditNoteChecked: this.isPartialCreditNoteCheckedControl,
    isNewInvoiceChecked: this.isNewInvoiceCheckedControl
  });

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private configurationService: ConfigurationService,
    private invoiceClarificationEditService: InvoiceClarificationEditService,
    private alertService: AlertService,
    private translocoService: TranslocoService,
    private scroller: ViewportScroller,
    public userService: UserService
  ) {
    this.invoiceClarification = this.route.snapshot.data[0]['invoiceClarification'];
    this.setupForm();
    this.updateControlsHidden();
  }

  checkboxChange(event: MatCheckboxChange, controlName: string): void {
    if (event.checked) {
      // make checkboxes mutually exclusive
      this.toggleExclusiveCheckboxesOnChecked(controlName);

      // check if the important information + attachments should be hidden
      this.controlsHidden = this.checkToggleInputComponents();
    }

    if (
      !this.isPartialCreditNoteCheckedControl.value &&
      !this.isNewInvoiceCheckedControl.value &&
      this.controlsHidden
    ) {
      // show the controls
      this.controlsHidden = false;
    }
  }

  save(): void {
    if (this.fileUploadComponent?.hasNotUploadedFiles) {
      this.handleUncommittedFiles();
      return;
    }

    this.saving = true;
    this.updateInvoiceClarificationData();

    this.invoiceClarificationEditService.saveInvoiceClarification(this.invoiceClarification).subscribe({
      next: (response: InvoiceClarification) => {
        this.invoiceClarification = response;
        this.saving = false;

        this.setupForm();
      },
      error: (errorResponse: HttpErrorResponse) => this.handleErrorResponse(errorResponse)
    });
  }

  confirm(): void {
    this.contactDataComponent?.contactDataForm.markAllAsTouched();
    const formData = this.contactDataComponent?.contactDataForm.value;

    if (!this.contactDataComponent?.contactDataForm.valid || !formData) {
      return;
    }

    // If contact data is valid save the values into global object
    this.invoiceClarification.contactData.phoneNumber =
      formData.phoneNumber ?? this.invoiceClarification.contactData.phoneNumber;
    if (formData.secondaryEmail) {
      this.invoiceClarification.contactData.secondaryEmail = formData.secondaryEmail;
    }

    if (this.fileUploadComponent?.hasNotUploadedFiles) {
      this.handleUncommittedFiles();
      return;
    }

    this.saving = true;
    this.updateInvoiceClarificationData();

    this.invoiceClarificationEditService.confirmInvoiceClarification(this.invoiceClarification).subscribe({
      next: (response: InvoiceClarificationConfirmation) => {
        this.router.navigate(['/invoice-clarification/confirmation'], { state: { confirmation: response } });
      },
      error: (errorResponse: HttpErrorResponse) => this.handleErrorResponse(errorResponse)
    });
  }

  handleImportantInformationCloseDialogDecision(closed: boolean) {
    if (!closed) {
      this.isNewInvoiceCheckedControl.setValue(false);
      this.isPartialCreditNoteCheckedControl.setValue(false);
    }
    this.updateControlsHidden();
  }

  private handleErrorResponse(errorResponse: HttpErrorResponse) {
    this.saving = false;
    const validationReader = new ValidationReader(errorResponse.error);
    this.errorList = validationReader.values;

    validationReader.keys.forEach((key) => {
      const control = this.form.get(deCapitalizeFirstLetter(key));

      if (!control) {
        return;
      }
      control.markAsTouched();
      control.setErrors({ incorrect: true });
    });

    this.scroller.scrollToPosition([0, 0]);
  }

  private toggleExclusiveCheckboxesOnChecked(controlName: string) {
    if (controlName === 'isPartialCreditNoteChecked') {
      this.isNewInvoiceCheckedControl.setValue(false);
      return;
    }

    if (controlName === 'isNewInvoiceChecked') {
      this.isPartialCreditNoteCheckedControl.setValue(false);
    }
  }

  private checkToggleInputComponents(): boolean {
    if (this.controlsHidden) {
      return true;
    }

    let successfulHidden = true;
    if (this.fileUploadComponent?.fileUploadPanel.expanded) {
      successfulHidden = this.fileUploadComponent.toggle();
    }

    if (!successfulHidden) {
      this.isNewInvoiceCheckedControl.setValue(false);
      this.isPartialCreditNoteCheckedControl.setValue(false);
      return false;
    }

    // only try to hide the important information, if the file Upload can also be hidden
    if (this.importantInformationComponent.importantInformationPanel.expanded) {
      return this.importantInformationComponent.toggle();
    }

    return true;
  }

  private updateInvoiceClarificationData(): void {
    this.invoiceClarification.mileage = this.form.value.mileage?.toString() ?? '';
    this.invoiceClarification.licensePlateNumber = this.form.value.licensePlateNumber ?? '';
    this.invoiceClarification.vehicleIdentificationNumber = this.form.value.vehicleIdentificationNumber ?? '';
    this.invoiceClarification.contractNumber = this.form.value.contractNumber ?? '';
    this.invoiceClarification.isPartialCreditNoteChecked = this.form.value.isPartialCreditNoteChecked ?? false;
    this.invoiceClarification.isNewInvoiceChecked = this.form.value.isNewInvoiceChecked ?? false;
  }

  private setupForm(): void {
    this.form.patchValue({
      internalInvoiceNumber: this.invoiceClarification.internalInvoiceNumber,
      mileage: parseInt(this.invoiceClarification.mileage),
      licensePlateNumber: this.invoiceClarification.licensePlateNumber,
      vehicleIdentificationNumber: this.invoiceClarification.vehicleIdentificationNumber,
      contractNumber: this.invoiceClarification.contractNumber,
      isPartialCreditNoteChecked: this.invoiceClarification.isPartialCreditNoteChecked,
      isNewInvoiceChecked: this.invoiceClarification.isNewInvoiceChecked
    });

    this.form.markAllAsTouched();
  }

  private updateControlsHidden(): void {
    if (this.isPartialCreditNoteCheckedControl.value || this.isNewInvoiceCheckedControl.value) {
      this.controlsHidden = true;
    }
  }

  private handleUncommittedFiles() {
    this.alertService.open({
      message: this.translocoService.translate('Invoice_Clarification_FileUpload_UncommittedChanges_Warning')
    });
  }
}
