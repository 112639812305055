import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DashboardService } from '../dashboard/dashboard.service';
import { VehicleSelectionService } from './vehicle-selection.service';
import { VehicleInfo } from './vehicle-selection.types';

@Component({
  selector: 'vehicle-selection',
  templateUrl: './vehicle-selection.component.html',
  styleUrls: ['./vehicle-selection.component.scss']
})
export class VehicleSelectionComponent implements OnInit {
  availableVehicles: VehicleInfo[] = [];
  displayedColumns: string[] = [
    'name',
    'licensePlateNumbers',
    'dateOfFirstRegistration',
    'vehicleIdentificationNumber'
  ];
  vehicleIdentificationNumberSelected!: string;
  continueLoading: boolean = false;
  errorList: string[] = [];

  constructor(
    private translocoService: TranslocoService,
    private vehicleSelectionService: VehicleSelectionService,
    private dashboardService: DashboardService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.availableVehicles = this.vehicleSelectionService.availableVehicles;

    if (!(this.availableVehicles && this.availableVehicles.length > 0)) {
      this.router.navigate(['/contract-search']);
    }
  }

  onContinue(): void {
    if (!this.vehicleIdentificationNumberSelected) {
      this.errorList.push(this.translocoService.translate('VehicleSelection_Index_NoSelectionError'));
      return;
    }

    this.continueLoading = true;

    // The vehicle is selected and user can be redirected to dashboard
    this.dashboardService.create(this.vehicleIdentificationNumberSelected).subscribe({
      next: () => {
        this.router.navigate(['/dashboard']);
        this.continueLoading = false;
      }
    });
  }
}
