<div class="flex-row justify-between content-center align-center">
  <ng-container *ngFor="let step of steps; trackBy: processBarIdentifier; let last = last, let i = index">
    <div
      class="navigation-item center-center"
      [class.active]="step.isActive"
      [class]="'ui-test--request-process-bar-div-position-' + i">
      <div class="icon-wrapper">
        <div class="icon-content">
          <mat-icon [class]="'ui-test--request-process-bar-mat-icon-position-' + i">{{ getTabIcon(step.key) }}</mat-icon>
        </div>
      </div>
      {{ step.displayName }}
    </div>
    <div class="horizontal-line" *ngIf="!last"></div>
  </ng-container>
</div>
