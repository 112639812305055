import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertConfig } from '../alert.types';

@Component({
  selector: 'alert-dialog',
  templateUrl: './dialog.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AlertDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertConfig) {}
}
