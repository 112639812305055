<ng-container *transloco="let t">
  <page-header [title]="t(titleKey)"></page-header>
  <h2 class="ui-test--forbidden-header-subtitle-key">{{ t(subtitleKey) }}</h2>

  <h3 class="ui-test--forbidden-header-message-1">{{ t(message1Key) }}</h3>
  <h3 class="ui-test--forbidden-header-message-2">{{ t(message2Key) }}</h3>

  <div class="flex-row">
    <button class="ui-test--forbidden-button-login" mat-raised-button (click)="redirect()">Login</button>
  </div>
</ng-container>
