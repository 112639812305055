import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextDataService } from '@core/services';
import { tap } from 'rxjs/operators';

@Injectable()
export class HeaderSessionResponseInterceptor implements HttpInterceptor {
  constructor(private contextDataService: ContextDataService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(req).pipe(tap(this.extractContextData.bind(this)));
  }

  extractContextData(httpEvent: HttpEvent<unknown>) {
    if (!(httpEvent instanceof HttpResponse)) {
      return;
    }

    const contextDataHeader = httpEvent.headers.get('X-ISPORT-REQUEST-CONTEXT-DATA');

    this.contextDataService.extractContextDataFromRequestHeader(contextDataHeader);
  }
}
