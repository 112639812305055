import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Page } from './pagination.types';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaginationComponent implements OnInit {
  /**
   * The data length is by default zero.
   */
  @Input()
  get dataLength(): number {
    return this._length;
  }
  set dataLength(length: number) {
    this._length = length;
    this.updateGoto();
  }

  /**
   * The default page size options are the same on all pages.
   * If you pass empty array it will be shown as label instead of select element.
   */
  @Input() pageSizeOptions: number[] = [5, 10, 25, 50, 100, 250, 500];

  /**
   * The default page size is 25 unless you specify the value.
   */
  @Input()
  get pageSize() {
    return this._pageSize;
  }
  set pageSize(pageSize: number) {
    this._pageSize = pageSize;
    this.updateGoto();
  }

  /**
   * The pagination always starts with first page unless is specified.
   */
  @Input() pageIndex = 0;

  /**
   * The event that is responsible to inform you when something
   * like page size or previous and next page has been changed.
   */
  @Output() page = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  goTo: number = 0;
  pages: Page[] = [];
  private _length: number = 0;
  private _pageSize = 25;

  ngOnInit() {
    this.updateGoto();
  }

  onPaginatorChange(event: PageEvent) {
    this.dataLength = event.length;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateGoto();
    this.page.emit(event);
  }

  updateGoto() {
    this.goTo = (this.pageIndex || 0) + 1;
    this.pages = [];
    for (let i = 1; i <= Math.ceil(this.dataLength / this.pageSize); i++) {
      this.pages.push({
        from: (i - 1) * this.pageSize + 1,
        to: Math.min(i * this.pageSize, this.dataLength),
        number: i
      });
    }
  }

  goToChange() {
    this.paginator.pageIndex = this.goTo - 1;
    const event: PageEvent = {
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize
    };
    this.paginator.page.next(event);
    this.emitPageEvent(event);
  }

  private emitPageEvent(pageEvent: PageEvent) {
    this.page.next(pageEvent);
  }
}
