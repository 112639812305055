<mat-dialog-content>
  <div class="flex-row justify-center content-between">
    <!-- Icon -->
    <ng-container *ngIf="data.icon?.show">
      <div class="flex-grow-10 justify-center content-start">
        <mat-icon
          class="mt-5 ui-test--dialog-component-mat-icon-icon"
          *ngIf="data.icon?.name"
          [color]="data.icon?.color">
          {{ data.icon?.name }}
        </mat-icon>
      </div>
    </ng-container>

    <ng-container *ngIf="data.title || data.message">
      <div class="flex-grow-90 mb-10">
        <!-- Title -->
        <ng-container *ngIf="data.title">
          <h2 class="mb-0 ui-test--dialog-component-header-title" [innerHTML]="data.title"></h2>
        </ng-container>

        <!-- Message -->
        <ng-container *ngIf="data.message">
          <div class="ui-test--dialog-component-div-message" [innerHTML]="data.message"></div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>

<!-- Actions -->
<mat-dialog-actions align="end" *ngIf="data.actions?.confirm?.show || data.actions?.cancel?.show">
  <!-- Cancel -->
  <ng-container *ngIf="data.actions?.cancel?.show">
    <button class="ui-test--dialog-component-button-canceled" mat-stroked-button [matDialogClose]="'cancelled'">
      {{data.actions?.cancel?.label}}
    </button>
  </ng-container>

  <!-- Additional Button -->
  <ng-container *ngIf="data.actions?.additionalButton?.show">
    <button class="ui-test--dialog-component-button-additional" mat-stroked-button [matDialogClose]="'additional'">
      {{data.actions?.additionalButton?.label}}
    </button>
  </ng-container>

  <!-- Confirm -->
  <ng-container *ngIf="data.actions?.confirm?.show">
    <button
      class="ui-test--dialog-component-button-confirmed"
      mat-flat-button
      [color]="data.actions?.confirm?.color"
      [matDialogClose]="'confirmed'">
      <mat-icon class="ui-test--dialog-component-mat-icon-confirmed" *ngIf="data.actions?.confirm?.icon">
        {{ data.actions?.confirm?.icon }}
      </mat-icon>
      {{data.actions?.confirm?.label}}
    </button>
  </ng-container>
</mat-dialog-actions>
