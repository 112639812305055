import { Injectable } from '@angular/core';
import { Breadcrumb } from './breadcrumb.types';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  private _breadcrumb: Breadcrumb = {} as Breadcrumb;

  get breadcrumb() {
    return this._breadcrumb;
  }

  set breadcrumb(breadcrumb: Breadcrumb) {
    this._breadcrumb = breadcrumb;
  }

  resetToVin() {
    this._breadcrumb = { vehicleIdentificationNumber: this._breadcrumb.vehicleIdentificationNumber } as Breadcrumb;
  }

  clearBreadcrumb() {
    this.breadcrumb = {} as Breadcrumb;
  }
}
