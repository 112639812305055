import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ReplaySubject, Subject, debounceTime, startWith, takeUntil } from 'rxjs';
import { DropdownOption, DynamicDropdownOptions, FilterableDropdownOption } from './dropdown-with-search.types';

@Component({
  selector: 'dropdown-with-search',
  templateUrl: './dropdown-with-search.component.html',
  styleUrls: ['./dropdown-with-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownWithSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() labelResourceKey: string = '';
  @Input() placeholderResourceKey: string = 'Common_PleaseChoose';
  @Input() errorResourceKey: string = '';
  @Input() formControlName!: string;
  @Input() dropDownOptions!: DynamicDropdownOptions | DropdownOption[];
  @Input() value!: any;
  @Input() isDisabled: boolean = false;
  @Input() validators?: Validators;
  @Input() sharedFormGroup!: FormGroup;
  @Input() isMultiSelect: boolean = false;
  @Input() highlightText: boolean = false;
  @Input() debounceTime: number = 0;
  @Input() hideClearButton: boolean = false;
  @Output() valueChange = new EventEmitter();

  /** Main form control configured by input values and used in final form */
  formControl!: FormControl;

  /** Prepared options based on input dropDownOptions to be used as full list of options */
  private options: FilterableDropdownOption[] = [];

  /** Control for the MatSelect filter keyword */
  public searchInputControl: FormControl = new FormControl<string>('');

  /** List of options filtered by search keyword */
  public filteredOptions: ReplaySubject<FilterableDropdownOption[]> = new ReplaySubject<FilterableDropdownOption[]>(1);

  /** List of selected options - only used if multiselect */
  public selectedOptions: ReplaySubject<DropdownOption[]> = new ReplaySubject<DropdownOption[]>(1);

  /** Subject that emits when the component has been destroyed. */
  private onDestroy = new Subject<void>();

  backendValidationError: boolean = false;

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    if (this.sharedFormGroup && !this.sharedFormGroup.contains(this.formControlName)) {
      this.formControl = new FormControl({ value: this.value, disabled: this.isDisabled }, this.validators);
      this.sharedFormGroup.addControl(this.formControlName, this.formControl);
    } else {
      this.formControl = this.sharedFormGroup.get(this.formControlName) as FormControl;
    }

    // Prepare options based on input which can be DynamicDropdownOptions or DropdownOption array
    this.options = this.getDropDownOptions();

    // Preload selected options for multiselect based on initial formControl value
    if (this.isMultiSelect && this.formControl.value) {
      this.loadSelectedOptions(this.formControl.value);
    }

    // listen for search field value changes
    this.searchInputControl.valueChanges
      .pipe(takeUntil(this.onDestroy), startWith(''), debounceTime(this.debounceTime))
      .subscribe(() => {
        this.filterOptions();
      });

    // on selection emit value change
    this.formControl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((value) => {
      if (this.isMultiSelect) {
        this.loadSelectedOptions(value);
      }

      this.valueChange.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // When ngOnChanges is called before ngOnInit do not check anything else
    if (!this.formControl) {
      return;
    }

    // Track value changes in parent
    if (changes['value'] && this.formControl.value !== changes.value.currentValue) {
      this.formControl.setValue(changes.value.currentValue, { emitEvent: false });
    }

    // Track isDisabled changes in parent
    if (changes['isDisabled'] && this.formControl.disabled !== changes.isDisabled.currentValue) {
      if (changes.isDisabled.currentValue) {
        this.formControl.disable({ emitEvent: false });
      } else {
        this.formControl.enable({ emitEvent: false });
      }
    }

    // Track dropDownOptions changes in parent
    if (
      changes['dropDownOptions'] &&
      !_.isEqual(changes['dropDownOptions'].currentValue, changes['dropDownOptions'].previousValue)
    ) {
      // Reset options based on new input of dropDownOptions
      this.options = this.getDropDownOptions();
      this.filterOptions();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * The method triggered on mat selection changes
   */
  selectedValue(): void {
    if (this.isMultiSelect) {
      this.filterOptions();
    }
  }

  /**
   * Remove an item from selected options
   */
  remove(value: string): void {
    // Remove option by value and inform parent about changes
    this.formControl.markAsDirty();
    this.formControl.setValue((<string[]>this.formControl.value).filter((option) => !option.includes(value)));
  }

  /**
   * The method used by foreach tracking to prevent unnecessary code rendering
   */
  optionIdentifier(_index: number, option: DropdownOption) {
    return option.value;
  }

  reset() {
    this.formControl.markAsDirty();
    this.formControl.setValue(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Provide check of dropDownOptions input field in this component
   * @param object can be DynamicDropdownOptions or DropdownOption[]
   * @returns true or false as result
   */
  private isDynamicDropdown(object: DynamicDropdownOptions | DropdownOption[]): object is DynamicDropdownOptions {
    return 'options' in object && 'optionsText' in object && 'optionsValue' in object;
  }

  /**
   * Map input dropDownOptions into strongly typed array
   * @returns array of FilterableDropdownOption interface
   */
  private getDropDownOptions(): FilterableDropdownOption[] {
    if (this.isDynamicDropdown(this.dropDownOptions)) {
      // If it is dynamic dropdown then do mapping
      return this.dropDownOptions.options.map(
        (option) =>
          <FilterableDropdownOption>{
            text: option[(<DynamicDropdownOptions>this.dropDownOptions).optionsText],
            value: option[(<DynamicDropdownOptions>this.dropDownOptions).optionsValue],
            shown: true
          }
      );
    }

    // If it is not dynamic mapping, just return same object since it should be already interface of DropdownOption[]
    return this.dropDownOptions.map((option) => <FilterableDropdownOption>{ ...option, shown: true });
  }

  /**
   * The loading selected options and use them to show in mat-select-trigger, only for multiselect
   */
  private loadSelectedOptions(value: string) {
    this.selectedOptions.next(this.options.filter((option) => value.includes(option.value)));
  }

  /**
   * The method responsible for filtering options based on input search value
   */
  private filterOptions(): void {
    if (!this.options) {
      return;
    }

    // Get the search input keyword
    const search = this.searchInputControl.value ?? '';
    if (!search) {
      // If search input is empty, show all option items
      this.options.forEach((option) => {
        option.shown = true;
      });
      this.filteredOptions.next(this.options);
      return;
    }

    const words: string[] = (search || '')
      .split(' ')
      .filter((t: string) => t.length > 0)
      .map((word: string) => {
        return (
          word
            .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
            // replace diacritics
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        );
      });

    // Filter the options based on search input
    this.options.forEach((option) => {
      const optionTextWithoutDiacritics = option.text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      option.shown = words.every((word) => optionTextWithoutDiacritics.match(new RegExp(`${word}`, 'gi')));
    });
    this.filteredOptions.next(this.options);
  }
}
