<div *transloco="let t" class="w-full">
  <mat-card class="w-full" [class.no-data-text]="!this.data.length && !this.loading">
    <mat-card-title class="ui-test--self-service-mat-card-title-self-service-title">
      {{ t('Account_UserProfile_SelfServicePanel_Title') }}
    </mat-card-title>
    <mat-card-content *ngIf="this.loading; else loadingDone">
      <div class="spinner spinner-small mb-40"></div>
    </mat-card-content>
    <ng-template #loadingDone>
      <ng-container *ngIf="this.data.length; else noData">
        <p id="self-service-subtitle" class="ui-test--self-service-paragraph-subtitle">
          {{t('UserProfile_SelfService_Subtitle')}}
        </p>
        <form [formGroup]="formGroup">
          <mat-card-content class="mt-20 mb-5">
            <mat-table
              [dataSource]="this.data"
              formArrayName="rows"
              aria-describedby="self-service-subtitle"
              class="w-full">
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row
                *matRowDef="let row; let i=index; columns: displayedColumns;"
                [class.deactivated]="!rows.controls[i].value.updateEntry"></mat-row>
              <ng-container matColumnDef="IsActive">
                <mat-header-cell *matHeaderCellDef class="flex-grow-5 justify-center">
                  <mat-checkbox [checked]="allChecked" (change)="toggleAll($event.checked)"></mat-checkbox>
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let index = index"
                  [formGroupName]="index"
                  class="flex-grow-5 justify-center">
                  <mat-checkbox formControlName="updateEntry"></mat-checkbox>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="DealerBrand">
                <mat-header-cell *matHeaderCellDef class="flex-grow-10 justify-center">
                  {{ t('Common_DealerBrand')}}
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let index = index;" class="flex-grow-10 justify-center">
                  <div class="flex-col gap-10 align-center">
                    <span *ngFor="let brand of row.brands">
                      {{brand?.length ? t("Common_DealerBrand_" + brand) : '-'}}
                    </span>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Email">
                <mat-header-cell *matHeaderCellDef class="flex-grow-25 justify-center">
                  {{ t('Common_Email') }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let index = index;" class="flex-grow-25 justify-center">
                  <form [formGroupName]="index" class="flex-grow-90 mt-5">
                    <mat-form-field class="w-full">
                      <mat-label>{{ t('Common_Email') }}</mat-label>
                      <input matInput formControlName="email" />
                    </mat-form-field>
                  </form>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="CommunicationType">
                <mat-header-cell *matHeaderCellDef class="flex-grow-15 justify-center">
                  {{ t('UserProfile_SelfService_CommunicationType') }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let index = index;" class="flex-grow-15 justify-center">
                  <form [formGroupName]="index">
                    <div>
                      <mat-label class="mat-slide-toggle-content slide-toggle-left">
                        {{t('UserProfile_SelfService_CommunicationType_Post')}}
                      </mat-label>
                      <mat-slide-toggle formControlName="isEmailPreferred">
                        {{t('UserProfile_SelfService_CommunicationType_Email')}}
                      </mat-slide-toggle>
                    </div>
                  </form>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Status">
                <mat-header-cell *matHeaderCellDef class="flex-grow-10 justify-center">
                  {{ t('UserProfile_SelfService_Status') }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let index = index;" class="flex-grow-10 justify-center">
                  <div class="flex-row gap-20">
                    <self-service-status-label class="w-full" [status]="row.status"></self-service-status-label>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="ModifyAll">
                <mat-header-cell *matHeaderCellDef class="flex-grow-10 justify-center">
                  {{ t('UserProfile_SelfService_ModifyAll') }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let index = index;" class="flex-grow-10 justify-center">
                  <button
                    mat-icon-button
                    color="default"
                    (click)="applyToAll(index)"
                    [disabled]="!rows.controls[index].value.updateEntry">
                    <mat-icon>link</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="LastModified">
                <mat-header-cell *matHeaderCellDef class="flex-grow-15 justify-center">
                  {{ t('Common_LastModified') }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let index = index;" class="flex-grow-15 justify-center">
                  <span>{{row.modifiedDate | dateFormat:'DATETIME_SHORT_WITH_SECONDS' }}</span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="ModifiedBy">
                <mat-header-cell *matHeaderCellDef class="flex-grow-10 justify-center">
                  {{ t('UserProfile_SelfService_ModifiedBy') }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let index = index;" class="flex-grow-10 justify-center">
                  <span>{{row.modifiedBy }}</span>
                </mat-cell>
              </ng-container>
            </mat-table>
          </mat-card-content>
          <mat-card-footer>
            <mat-card-actions align="end" class="flex-row mr-10 gap-10 mb-5">
              <button
                class="ui-test--self-service-button-send"
                mat-raised-button
                color="primary"
                type="submit"
                (click)="sendChanges()"
                [disabled]="!canSend">
                {{ t('Common_Send') }}
              </button>
            </mat-card-actions>
          </mat-card-footer>
        </form>
      </ng-container>
      <ng-template #noData>
        <mat-card-content>
          <p
            class="ui-test--self-service-paragraph-no-data"
            [innerHTML]="t('UserProfile_SelfService_NoData') | nl2br"></p>
        </mat-card-content>
      </ng-template>
    </ng-template>
  </mat-card>
</div>
