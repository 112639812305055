import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { VoidFn } from './scheduler.types';

@Injectable({ providedIn: 'root' })
export class TimedSchedulerService {
  private runningSchedules: number[] = [];
  Int32Max: number = 2147483647;

  public scheduleExecution(
    executionTime: DateTime,
    triggerThis: VoidFn,
    clearExistingSchedules: boolean = false
  ): void {
    const timeDiffMs = executionTime.diffNow();
    // if schedule was in past return
    if (timeDiffMs.milliseconds < 0) {
      return;
    }

    if (clearExistingSchedules) {
      this.runningSchedules.forEach((scheduleId) => window.clearTimeout(scheduleId));
      this.runningSchedules = [];
    }

    // scheduler timeout with function execution for the given time
    this.runningSchedules.push(window.setTimeout(triggerThis, Math.min(timeDiffMs.milliseconds, this.Int32Max)));
  }
}
