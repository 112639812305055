import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DirectivesModule } from '@core/directives';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { EntriesCounterModule } from 'app/components/entries-counter';
import { FilterModule } from 'app/components/filter/filter.module';
import { OverviewModule } from 'app/components/overview/overview.module';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { RequestStateLabelModule } from 'app/components/request-state-label/request-state-label.module';
import { SortModule } from 'app/components/sort/sort.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ScrollTopButtonModule } from 'app/components/scroll-top-button';
import { AftersalesOverviewComponent } from './aftersales-overview.component';

@NgModule({
  imports: [
    DirectivesModule,
    MatIconModule,
    MatExpansionModule,
    RequestStateLabelModule,
    PageHeaderModule,
    PipesModule,
    ValidationSummaryModule,
    FilterModule,
    OverviewModule,
    SortModule,
    SharedModule,
    MatButtonModule,
    EntriesCounterModule,
    MatProgressBarModule,
    InfiniteScrollModule,
    ScrollTopButtonModule
  ],
  exports: [],
  declarations: [AftersalesOverviewComponent],
  providers: []
})
export class AftersalesOverviewModule {}
