import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@core/modules';
import { BrowserCompatibilityService } from './browser-compatibility.service';
import { BrowserWarningButtonComponent, BrowserWarningButtonService } from './browser-warning-button';
import { BrowserWarningDialogService } from './browser-warning-dialog';
import { BrowserWarningDialogComponent } from './browser-warning-dialog/browser-warning-dialog.component';

@NgModule({
  imports: [MatIconModule, MatButtonModule, MatDialogModule, MatTooltipModule, SharedModule],
  exports: [BrowserWarningDialogComponent, BrowserWarningButtonComponent],
  declarations: [BrowserWarningDialogComponent, BrowserWarningButtonComponent],
  providers: [BrowserCompatibilityService, BrowserWarningButtonService, BrowserWarningDialogService]
})
export class BrowserCompatibilityModule {}
