import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslocoService } from '@ngneat/transloco';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PwaPromptUpdateService {
  constructor(private swUpdate: SwUpdate, private translocoService: TranslocoService) {}

  /**
   * If there is an update available, ask the user to update to the newest version.
   */
  subscribeToVersionUpdates() {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.swUpdate.versionUpdates
      .pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'))
      .subscribe((event) => {
        const message = this.translocoService.translate('PWA_Version_Update_Available', {
          oldVersion: event.currentVersion.hash,
          newVersion: event.latestVersion.hash
        });

        const shouldReload = confirm(message);
        if (shouldReload) {
          document.location.reload();
        }
      });
  }
}
