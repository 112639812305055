<form [formGroup]="sharedFormGroup" *transloco="let t">
  <div
    class="flex-row justify-start content-center align-center"
    [class]="question.options.dataType === 'Boolean' || question.options.dataType === 'VehicleDamageParts' ? 'mb-20' : ''"
    [class.form-field-error]="backendValidationError"
    *ngIf="shouldQuestionBeDisplayed()">
    <!-- No label, it will stay empty just because of grid preview -->
    <strong *ngIf="question.options.labelHidden && !question.options.isAlternateLayout" class="flex-grow-50"></strong>

    <!-- When alternate layout is false then show label left to control otherwise hide it -->
    <strong
      *ngIf="!question.options.labelHidden && !question.options.isAlternateLayout"
      class="flex-grow-50 pr-15 ui-test--request-question-strong-question-label"
      style="text-align: right"
      [class.mb-10]="question.options.dataType !== 'Boolean'">
      <!-- Some boolean questions do have the option isAlternativeLayout false and wont get the mandatory flag on the ode part below (line 27) -->
      {{ questionTitle }} {{ !this.question.options.isDisabled && question.options.isMandatory ||
      question.options.dataType === 'Boolean' ? '*' : '' }}
    </strong>

    <div
      class="flex flex-1"
      [ngClass]="!question.options.labelHidden && question.options.isAlternateLayout ? 'flex-grow-100' : 'flex-grow-50'"
      *ngIf="!question.options.answerHidden">
      <ng-container *ngIf="question.options.dataType === 'Boolean'">
        <div class="flex-col">
          <mat-label
            *ngIf="!question.options.labelHidden && question.options.isAlternateLayout"
            class="mb-10 ui-test--request-question-mat-label-radio-button">
            <!-- Questions with data type Boolean are always mandatory-->
            {{ questionTitle }} *
          </mat-label>
          <mat-radio-group
            color="primary"
            [formControlName]="question.questionTypeKey + '_' + question.questionKey  + '_' + groupId">
            <mat-radio-button value="true" class="mr-20 ui-test--request-question-mat-radio-button-yes">
              {{ t('Common_Yes') }}
            </mat-radio-button>
            <mat-radio-button class="ui-test--request-question-mat-radio-button-no" value="false">
              {{ t('Common_No') }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>

      <ng-container *ngIf="question.options.dataType === 'DateTime'">
        <mat-form-field class="w-full">
          <mat-label
            *ngIf="!question.options.labelHidden && question.options.isAlternateLayout"
            class="ui-test--request-question-mat-label-date-time">
            {{ questionTitle }}
          </mat-label>
          <input
            matInput
            class="ui-test--request-question-input-date-time"
            [matDatepicker]="dateTimePicker"
            [matDatepickerFilter]="dateTimePickerFilterBounded"
            [max]="!question.options.isFutureDateSelectionEnabled ? today : null"
            [formControlName]="question.questionTypeKey + '_' + question.questionKey  + '_' + groupId"
            (dateChange)="setValidDateTime($event.value)" />
          <mat-datepicker-toggle
            class="ui-test--request-question-mat-datepicker-toogle-date-time"
            matSuffix
            [for]="dateTimePicker"></mat-datepicker-toggle>
          <mat-datepicker #dateTimePicker class="ui-test--request-question-mat-datepicker-date-time"></mat-datepicker>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="question.options.dataType === 'Time'">
        <mat-form-field class="w-full">
          <mat-label
            *ngIf="!question.options.labelHidden && question.options.isAlternateLayout"
            class="ui-test--request-question-mat-label-time">
            {{ questionTitle }}
          </mat-label>
          <input
            matInput
            class="ui-test--request-question-input-time"
            [matDatepicker]="timePicker"
            [formControlName]="question.questionTypeKey + '_' + question.questionKey  + '_' + groupId" />
          <mat-datepicker-toggle
            class="ui-test--request-question-mat-datepicker-toogle-time"
            matSuffix
            [for]="timePicker">
            <mat-icon matDatepickerToggleIcon class="ui-test--request-question-mat-icon-time">timer</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker
            class="ui-test--request-question-mat-datepicker-time"
            type="time"
            [twelveHour]="false"
            #timePicker></mat-datepicker>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="question.options.dataType === 'Numeric'">
        <mat-form-field class="w-full">
          <mat-label
            *ngIf="!question.options.labelHidden && question.options.isAlternateLayout"
            class="ui-test--request-question-mat-label-numeric">
            {{ questionTitle }}
          </mat-label>
          <input
            class="ui-test--requestQuestion-input-numeric"
            matInput
            maxlength="9"
            [formControlName]="question.questionTypeKey + '_' + question.questionKey  + '_' + groupId" />
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="question.options.dataType === 'Text'">
        <mat-form-field class="w-full">
          <mat-label
            *ngIf="!question.options.labelHidden && question.options.isAlternateLayout"
            class="ui-test--request-question-mat-label-text">
            {{ questionTitle }}
          </mat-label>

          <textarea
            class="ui-test--request-question-textarea-text"
            *ngIf="question.options.isMultiline"
            matInput
            trim="blur"
            rows="5"
            autocomplete="off"
            style="resize: vertical"
            [formControlName]="question.questionTypeKey + '_' + question.questionKey  + '_' + groupId"></textarea>

          <input
            class="ui-test--request-question-input-text"
            matInput
            trim="blur"
            autocomplete="off"
            *ngIf="!question.options.isMultiline"
            [formControlName]="question.questionTypeKey + '_' + question.questionKey  + '_' + groupId" />
        </mat-form-field>
      </ng-container>

      <ng-container
        *ngIf="question.options.dataType === 'SelectionBox' || question.options.dataType === 'MultiSelectionBox'">
        <mat-form-field class="w-full">
          <mat-label
            *ngIf="!question.options.labelHidden && question.options.isAlternateLayout"
            class="ui-test--request-question-mat-label-multi-selction-box">
            {{ questionTitle }}
          </mat-label>
          <mat-select
            class="ui-test--request-question-mat-select-multi-selection-box"
            [formControlName]="question.questionTypeKey + '_' + question.questionKey  + '_' + groupId"
            [multiple]="question.options.dataType === 'MultiSelectionBox'"
            [placeholder]="t('Common_PleaseChoose')">
            <mat-option
              [value]="option"
              *ngFor="let option of question.options.selectableOptions ?? [], let i = index"
              [class]="'ui-test--request-question-mat-option-multi-selction-box-' + i">
              {{ option && option.length > 0 ? t('Request_Questionnaires_Answer_' + question.questionTypeKey + '_' +
              question.questionKey + '_' + option) : '' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="question.options.dataType === 'VehicleDamageParts'">
        <mat-label
          *ngIf="!question.options.labelHidden && question.options.isAlternateLayout"
          class="mb-10 ui-test--request-question-mat-label-vehicle-damage-parts">
          {{ questionTitle }} {{ !this.question.options.isDisabled && question.options.isMandatory ? '*' : '' }}
        </mat-label>

        <div class="flex-row justify-between content-strech w-full">
          <div class="flex-col justify-between content-center">
            <div
              *ngFor="let position of ['FrontLeft', 'MiddleLeft', 'RearLeft']"
              [class]="'ui-test--request-question-div-vehicle-damage-parts-' + position">
              <ng-container
                [ngTemplateOutlet]="damagePositionCheckbox"
                [ngTemplateOutletContext]="{position:position}"></ng-container>
            </div>
          </div>

          <div class="flex-col justify-between content-center">
            <div
              *ngFor="let position of ['FrontMiddle', 'X', 'RearMiddle']"
              [class]="'ui-test--request-question-div-vehicle-damage-parts-' + position">
              <!-- X is fake value just to show car in the middle -->
              <ng-container
                *ngIf="position !== 'X'; else showCarImage"
                [ngTemplateOutlet]="damagePositionCheckbox"
                [ngTemplateOutletContext]="{position:position}"></ng-container>
              <ng-template #showCarImage>
                <img
                  class="ui-test--request-question-img-vehicle-damage-parts"
                  alt="Car"
                  src="assets/images/car_top.png" />
              </ng-template>
            </div>
          </div>

          <div class="flex-col justify-between content-center">
            <div
              *ngFor="let position of ['FrontRight', 'MiddleRight', 'RearRight']"
              [class]="'ui-test--request-question-div-vehicle-damage-parts-' + position">
              <ng-container
                [ngTemplateOutlet]="damagePositionCheckbox"
                [ngTemplateOutletContext]="{position:position}"></ng-container>
            </div>
          </div>

          <div class="flex-col justify-between content-center">
            <div
              *ngFor="let position of ['Hood', 'Windshield', 'RoofTop', 'RearWindow', 'Tailgate', 'SideMirrorLeft', 'SideMirrorRight', 'Body', 'Underbody']"
              [class]="'ui-test--request-question-div-vehicle-damage-parts-' + position">
              <ng-container
                [ngTemplateOutlet]="damagePositionCheckbox"
                [ngTemplateOutletContext]="{position:position}"></ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="question.options.dataType === 'InfoText'">
        <p
          class="mb-15 ui-test--request-question-paragraph-vehicle-damage-parts"
          [innerHtml]="t(infoTextResourceByPreAllocationSourcePath) | nl2br"></p>
      </ng-container>
    </div>
  </div>
  <div *ngIf="configUrl && shouldQuestionBeDisplayed()" class="flex-offset-50" style="text-align: end">
    <a
      class="ui-test--request-question-link-automotiv-expert-office-list"
      mat-menu-item
      [href]="configUrl ? configUrl : ''"
      target="_blank">
      {{ t('Common_AutomotivExpertOfficeList') }}
    </a>
  </div>
</form>

<ng-template #damagePositionCheckbox let-position="position">
  <div *transloco="let t">
    <mat-checkbox
      color="primary"
      [class]="'ui-test--request-question-mat-check-box-position-' + position"
      *ngIf="question.options.selectableOptions.indexOf(position) >= 0"
      [checked]="isVehicleDamageSelected(position)"
      (change)="onVehicleDamageSelection($event, position)"
      [value]="position">
      {{ t('Request_Questionnaires_Answer_' + question.questionTypeKey +'_' + question.questionKey + '_' + position) }}
    </mat-checkbox>
  </div>
</ng-template>
