<div *ngFor="let contract of dashboardData.contracts; let i = index">
  <mat-card
    class="services-box"
    *ngIf="!contract.isInternal"
    [class]="'ui-test--contract-panel-mat-card-card-' + i"
    [class.contract-selected]="dashboardData.selectedContract === i">
    <mat-card-title (click)="contractSelected(i)" [class]="'ui-test--contract-panel-mat-card-title-title-' + i">
      <span [class]="'ui-test--contract-panel-span-sales-product-name-' + i">{{ contract.salesProductName }}</span>
      <span class="contract-number" [class]="'ui-test--contract-panel-span-contract-number-' + i">
        {{ contract.contractNumber }}
      </span>
    </mat-card-title>
    <mat-card-content *transloco="let t">
      <!-- customer data -->
      <!-- prettier-ignore -->
      <span *ngFor="let customer of contract.customer?.sortedAbstractDisplayProperties">
        {{ customer.value }}
      </span>

      <!-- contract / vehicle data -->
      <p class="license-plate" [class]="'ui-test--contract-panel-paragraph-license-plate-number-' + i">
        {{ contract.vehicle.licensePlateNumber }}
      </p>

      <!-- base product data -->
      <p *ngFor="let baseProduct of contract.baseProducts; let baseProductindex = index">
        <span
          class="dashboard-product-name"
          [class]="'ui-test--contract-panel-span-contract-' + i + '-base-product-type-' + baseProductindex">
          {{ baseProduct.name }}
        </span>

        <span>
          <!-- Create new when no request exists -->
          <mat-icon
            *ngFor="let productType of productTypesFromProduct(baseProduct)"
            matTooltip="{{ dashboardService.getProductTypeText(productType.displayText) }}"
            matTooltipPosition="right"
            [class]="[productType.displayText | className, 'ui-test--contract-panel-mat-icon-contract-' + i + '-base-product-type-' + baseProductindex]"
            (click)="contractSelected(i); startRequestForProductType(productType)">
            {{ productType.displayText | productTypeIcon }}
          </mat-icon>
        </span>
      </p>
    </mat-card-content>
  </mat-card>
</div>
