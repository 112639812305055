<mat-label
  class="request-state-label ui-test--request-state-label-mat-label-state"
  [class]="state | className"
  [matTooltip]="tooltip"
  matTooltipPosition="above"
  *ngIf="!doNotShowLabel; else doNotShowRequestState">
  {{ stateText }}
</mat-label>
<ng-template #doNotShowRequestState>
  <span>–</span>
</ng-template>
