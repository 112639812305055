<div class="header-image-wrapper">
  <img class="ui-test--base-layout-image-logo" alt="Logo" src="assets/images/wortmarke.svg" />
</div>
<ng-content></ng-content>
<section class="flex-row center-center">
  <div class="base-layout">
    <div class="flex-col flex-grow-100">
      <announcement></announcement>
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
