import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ConfigurationService, LocaleFormatService, RequestStateStore, TenantSettingsService } from '@core/services';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { UnsavedChangesStateStore } from 'app/pages/request';
import { RequestService } from 'app/pages/request/request.service';
import { GenericPositionComponent } from '../generic-position/generic-position.component';

@Component({
  selector: 'tire-spare-part-position',
  templateUrl: './tire-spare-part-position.component.html'
})
export class TireSparePartPositionComponent extends GenericPositionComponent {
  constructor(
    formBuilder: UntypedFormBuilder,
    positionConfigurationService: PositionConfigurationService,
    requestService: RequestService,
    requestStore: RequestStateStore,
    unsavedChangesStateStore: UnsavedChangesStateStore,
    localeFormatService: LocaleFormatService,
    configurationService: ConfigurationService,
    tenantSettingsService: TenantSettingsService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(
      formBuilder,
      positionConfigurationService,
      12,
      requestStore,
      unsavedChangesStateStore,
      requestService,
      localeFormatService,
      configurationService,
      tenantSettingsService,
      changeDetector,
      elementRef
    );
  }
}
