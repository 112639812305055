<ng-container *transloco="let t">
  <div class="positions-edit">
    <h2 class="subtitle ui-test--position-component-header-position">
      {{ t('Request_Edit_' + requestContent.requestProcess.productType + '_Positions') }}
    </h2>

    <mat-accordion multi>
      <div
        *ngFor="let positionData of requestContent.requestPositions; let i = index; trackBy: positionIdentifier"
        [class]="'ui-test--position-component-div-component-' + i">
        <package-position
          #position
          [class]="'ui-test--position-component-package-position-position-' + i"
          [positionData]="positionData"
          [productType]="requestContent.requestProcess.productType"
          [(requestContent)]="requestContent"
          [availableActivityTypes]="requestContent.availableActivityTypes"
          [damageTypes]="requestContent.damageTypes"
          [index]="i"
          (removedPosition)="removePosition($event)"
          (savedPosition)="savePosition()"
          *ngIf="positionData.templateName === 'PackagePositionViewModel'"></package-position>

        <mobility-position
          #position
          [class]="'ui-test--position-component-mobility-position-position-' + i"
          [positionData]="positionData"
          [productType]="requestContent.requestProcess.productType"
          [(requestContent)]="requestContent"
          [availableActivityTypes]="requestContent.availableActivityTypes"
          [index]="i"
          (removedPosition)="removePosition($event)"
          (savedPosition)="savePosition()"
          *ngIf="positionData.templateName === 'MobilityPositionViewModel'"></mobility-position>

        <mobility-spare-part-position
          #position
          [class]="'ui-test--position-component-mobility-spare-part-position-position-' + i"
          [positionData]="positionData"
          [productType]="requestContent.requestProcess.productType"
          [(requestContent)]="requestContent"
          [availableActivityTypes]="requestContent.availableActivityTypes"
          [index]="i"
          (removedPosition)="removePosition($event)"
          (savedPosition)="savePosition()"
          *ngIf="positionData.templateName === 'MobilitySparePartPositionViewModel'"></mobility-spare-part-position>

        <work-position
          #position
          [class]="'ui-test--position-component-work-position-position-' + i"
          [positionData]="positionData"
          [productType]="requestContent.requestProcess.productType"
          [(requestContent)]="requestContent"
          [availableActivityTypes]="requestContent.availableActivityTypes"
          [damageTypes]="requestContent.damageTypes"
          [index]="i"
          (removedPosition)="removePosition($event)"
          (savedPosition)="savePosition()"
          *ngIf="positionData.templateName === 'WorkPositionViewModel'"></work-position>

        <warranty-position
          #position
          [class]="'ui-test--position-component-warranty-position-position-' + i"
          [positionData]="positionData"
          [productType]="requestContent.requestProcess.productType"
          [(requestContent)]="requestContent"
          [availableActivityTypes]="requestContent.availableActivityTypes"
          [damageTypes]="requestContent.damageTypes"
          [index]="i"
          (removedPosition)="removePosition($event)"
          (savedPosition)="savePosition()"
          *ngIf="positionData.templateName === 'WarrantyPositionViewModel'"></warranty-position>

        <warranty-spare-part-position
          #position
          [class]="'ui-test--position-component-warranty-spare-part-position-position' + i"
          [positionData]="positionData"
          [productType]="requestContent.requestProcess.productType"
          [(requestContent)]="requestContent"
          [availableActivityTypes]="requestContent.availableActivityTypes"
          [damageTypes]="requestContent.damageTypes"
          [index]="i"
          (removedPosition)="removePosition($event)"
          (savedPosition)="savePosition()"
          *ngIf="positionData.templateName === 'WarrantySparePartPositionViewModel'"></warranty-spare-part-position>

        <warranty-diagnostic-position
          #position
          [class]="'ui-test--position-component-warranty-diagnostic-position-position' + i"
          [positionData]="positionData"
          [productType]="requestContent.requestProcess.productType"
          [(requestContent)]="requestContent"
          [availableActivityTypes]="requestContent.availableActivityTypes"
          [damageTypes]="requestContent.damageTypes"
          [index]="i"
          (removedPosition)="removePosition($event)"
          (savedPosition)="savePosition()"
          *ngIf="positionData.templateName === 'WarrantyDiagnosticPositionViewModel'"></warranty-diagnostic-position>

        <tire-service-position
          #position
          [class]="'ui-test--position-component-tire-service-position-position' + i"
          [positionData]="positionData"
          [productType]="requestContent.requestProcess.productType"
          [(requestContent)]="requestContent"
          [availableActivityTypes]="requestContent.availableActivityTypes"
          [damageTypes]="requestContent.damageTypes"
          [index]="i"
          (removedPosition)="removePosition($event)"
          (savedPosition)="savePosition()"
          *ngIf="positionData.templateName === 'TireServicePositionViewModel'"></tire-service-position>

        <tire-position
          #position
          [class]="'ui-test--position-component-tire-position-position' + i"
          [positionData]="positionData"
          [productType]="requestContent.requestProcess.productType"
          [(requestContent)]="requestContent"
          [availableActivityTypes]="requestContent.availableActivityTypes"
          [damageTypes]="requestContent.damageTypes"
          [index]="i"
          (removedPosition)="removePosition($event)"
          (savedPosition)="savePosition()"
          *ngIf="positionData.templateName === 'TirePositionViewModel'"></tire-position>

        <tire-spare-part-position
          #position
          [class]="'ui-test--position-component-tire-spare-part-position-position' + i"
          [positionData]="positionData"
          [productType]="requestContent.requestProcess.productType"
          [(requestContent)]="requestContent"
          [availableActivityTypes]="requestContent.availableActivityTypes"
          [damageTypes]="requestContent.damageTypes"
          [index]="i"
          (removedPosition)="removePosition($event)"
          (savedPosition)="savePosition()"
          *ngIf="positionData.templateName === 'TireSparePartPositionViewModel'"></tire-spare-part-position>

        <spare-part-position
          #position
          [class]="'ui-test--position-component-spare-part-position-position' + i"
          [positionData]="positionData"
          [productType]="requestContent.requestProcess.productType"
          [(requestContent)]="requestContent"
          [availableActivityTypes]="requestContent.availableActivityTypes"
          [damageTypes]="requestContent.damageTypes"
          [index]="i"
          (removedPosition)="removePosition($event)"
          (savedPosition)="savePosition()"
          *ngIf="positionData.templateName === 'SparePartPositionViewModel'"></spare-part-position>

        <ng-container
          [ngTemplateOutlet]="positionDeleted"
          [ngTemplateOutletContext]="{positionData: positionData}"
          *ngIf="positionData.isPositionMarkedAsDeleted &&
            !getGenericPositionComponent(positionData.positionId)?.isParentDeleted"></ng-container>
      </div>
    </mat-accordion>

    <ng-container
      *ngIf="(requestContent.requestProcess.editProductType === 'ServiceAndMaintenance' ||
              requestContent.requestProcess.editProductType === 'InsuranceRepair' ||
              requestContent.requestProcess.editProductType === 'Warranty' &&
              (!isGermanWarrantyEditMode || requestContent.requestPositions.length === 0))
            && requestContent.requestProcess.sourceApplication !== 'DMS'">
      <work-position-filter [requestContent]="requestContent"></work-position-filter>
    </ng-container>

    <ng-container *ngIf="requestContent.requestProcess.editProductType === 'Mobility'">
      <button class="ui-test--position-component-button-mobility" mat-stroked-button (click)="addPosition()">
        {{ t('Request_Edit_AddMobilityposition') }}
      </button>
    </ng-container>

    <ng-container
      *ngIf="requestContent.requestProcess.editProductType === 'Tires' && requestContent.requestProcess.sourceApplication !== 'DMS'">
      <button
        *ngIf="tirePositionAvailableActivityTypes.length === 1"
        class="ui-test--position-component-button-tire"
        mat-stroked-button
        (click)="addPosition()">
        {{ t('Request_Edit_AddTireServicePosition') }}
      </button>
      <ng-container *ngIf="tirePositionAvailableActivityTypes.length > 1">
        <button class="ui-test--position-component-button-position" mat-stroked-button [matMenuTriggerFor]="posmenu">
          {{ t('Request_Edit_AddPosition') }}
        </button>
        <mat-menu #posmenu="matMenu">
          <ng-container *ngFor="let tireActivityType of tirePositionAvailableActivityTypes, let i = index">
            <button
              mat-menu-item
              (click)="addPosition(tireActivityType)"
              [class]="'ui-test--position-component-button-tire-type-' + i">
              {{t('Request_Edit_Add' + tireActivityType)}}
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>

    <!-- Amount rows -->
    <ng-container
      *ngIf="requestContent.requestProcess.editProductType !== 'MotorInsurance' && requestContent.requestPositions.length > 0">
      <request-total-amounts [totalAmounts]="totalAmounts" [showDashAsNoValue]="true"></request-total-amounts>
    </ng-container>
  </div>

  <ng-template #positionDeleted let-positionData="positionData">
    <figure class="fieldset flex-row" *ngIf="!positionData.isParentDeleted">
      <figcaption class="flex flex-1">
        <div>
          <span class="ui-test--position-component-span-deleted">
            {{ displayDescription(positionData.positionId) }} {{ t('Common_Deleted') }}
          </span>
          <a (click)="restorePosition(positionData)" class="ui-test--position-component-link-restore">
            <mat-icon class="ui-test--position-component-mat-icon-restore">undo</mat-icon>
            <span class="ui-test--position-component-span-restore">{{ t('Common_Restore') }}</span>
          </a>
        </div>
      </figcaption>
    </figure>
  </ng-template>
</ng-container>
