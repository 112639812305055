import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { StatusDetails } from '../status-details-dialog.types';
import { PrintService } from './print.service';

@Component({
  selector: 'status-details-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatusDetailsPrintComponent implements OnInit, OnDestroy {
  @Input() statusDetails!: StatusDetails;

  @Input() printTabs: string[] = [];

  constructor(private printService: PrintService) {}

  ngOnInit(): void {
    this.printService.addPrintStyle();
    this.printService.movePrintComponentToBody();
  }

  ngOnDestroy(): void {
    this.printService.removePrintStyle();
    this.printService.removePrintComponent();
  }
}
