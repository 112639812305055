<div class="flex-row justify-end content-center align-center sort" *transloco="let t">
  <mat-form-field class="sort-dropdown">
    <mat-label class="ui-test--sort-mat-label-order-by-label">{{ t('Common_OrderBy') }}</mat-label>
    <mat-select class="ui-test--sort-mat-select-options" [(value)]="sortKey" (selectionChange)="onSort()">
      <mat-option
        *ngFor="let sortKey of sortKeys"
        [value]="sortKey[0]"
        [class]="'ui-test--sort-mat-option-options-' + sortKey[0]">
        {{ t(sortKey[1]) }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-button-toggle-group class="sort-buttons" [(value)]="sortDirection">
    <mat-button-toggle
      class="ui-test--sort-mat-button-toggle-ascending"
      (click)="onSort()"
      value="asc"
      [matTooltip]="t('Common_Ascending')"
      matTooltipPosition="above">
      <mat-icon class="ui-test--sort-mat-icon-ascending-sort">sort</mat-icon>
      <mat-icon class="ui-test--sort-mat-icon-arrow-downward">arrow_downward</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      class="ui-test--sort-mat-button-toggle-descending"
      (click)="onSort()"
      value="desc"
      [matTooltip]="t('Common_Descending')"
      matTooltipPosition="above">
      <mat-icon class="ui-test--sort-mat-icon-descending-sort">sort</mat-icon>
      <mat-icon class="ui-test--sort-mat-icon-arrow-upward">arrow_upward</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <mat-form-field *ngIf="visibleComponents.includes('search')" class="search">
    <mat-label class="ui-test--sort-mat-label-search-label">{{t('Common_Search')}}</mat-label>
    <input
      class="search-field ui-test--sort-input-search-input"
      (keyup)="searchValue($event)"
      matInput
      [matTooltip]="searchTooltip"
      matTooltipPosition="above" />
  </mat-form-field>

  <div *ngIf="visibleComponents.includes('panelExpandButton')">
    <mat-button-toggle class="toggle-expand-button ui-test--sort-mat-button-toggle-expand" (click)="togglePanels()">
      <mat-icon [class]="'ui-test--sort-mat-icon-expand-' + panelExpanded">
        {{ panelExpanded ? 'list' : 'view_list' }}
      </mat-icon>
    </mat-button-toggle>
  </div>
</div>
