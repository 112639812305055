import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@core/modules';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { RequestTotalAmountsModule } from 'app/components/request-total-amounts/request-total-amounts.module';
import { SendingRestrictedModule } from 'app/components/sending-restricted/sending-restricted.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { ContactDataModule } from '../../../components/contact-data/contact-data.module';
import { RequestConfirmationModule } from '../confirmation/confirmation.module';
import { GeneralInformationModule } from '../shared/general-information/general-information.module';
import { PositionsPreviewModule } from '../shared/positions-preview/positions-preview.module';
import { ProcessBarModule } from '../shared/process-bar/process-bar.module';
import { RequestOnlineInvoiceComponent } from './online-invoice.component';

@NgModule({
  imports: [
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    MatCheckboxModule,
    MatDividerModule,
    GeneralInformationModule,
    ContactDataModule,
    RequestConfirmationModule,
    PositionsPreviewModule,
    RequestTotalAmountsModule,
    SendingRestrictedModule,
    ValidationSummaryModule,
    ProcessBarModule,
    PageHeaderModule,
    SharedModule
  ],
  exports: [RequestOnlineInvoiceComponent],
  declarations: [RequestOnlineInvoiceComponent],
  providers: []
})
export class RequestOnlineInvoiceModule {}
