import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '@core/modules';
import { SendingRestrictedComponent } from './sending-restricted.component';

@NgModule({
  imports: [SharedModule, MatCardModule],
  exports: [SendingRestrictedComponent],
  declarations: [SendingRestrictedComponent],
  providers: []
})
export class SendingRestrictedModule {}
