import { SortDirection } from '@angular/material/sort';
import { DateTime } from 'luxon';
import { SortOptions } from './sort.types';

/**
 * Filter an array of objects for all objects that have a property that contains the passed
 * string.
 * @param target Indexable array of objects of type T
 * @param filterValue Search string
 * @param options Options for configuration
 * @param options.caseSensitive Toggle case sensitivity
 * @param options.ignoreBlanks Toggle the ignorance of blanks in the passed string
 * @param options.entries A list of entries of T that are used for filtering
 * @returns Filtered array
 */
export function filterProperties<T extends { [s: string]: string | number | boolean }>(
  target: Array<T>,
  filterValue: string,
  options: SortOptions = { caseSensitive: false, ignoreBlanks: true, entries: [] }
) {
  filterValue = options.caseSensitive ? filterValue : filterValue.toLowerCase();
  const filterValueNoBlanks = options.ignoreBlanks ? filterValue.replace(' ', '') : filterValue;

  return target.filter((entry: T) => {
    for (const [key, value] of Object.entries(entry)) {
      if (filterObjectEntry(filterValue, filterValueNoBlanks, options.entries, key, value)) {
        return true;
      }
    }
    return false;
  });
}

function filterObjectEntry(
  filterValue: string,
  filterValueNoBlanks: string,
  entries: string[] | undefined,
  key: string,
  value: string | number | boolean
) {
  if (entries && !entries.includes(key)) {
    return false;
  }

  if (value === null || typeof value === 'undefined' || typeof value == 'boolean' || typeof value == 'object') {
    return false;
  }

  let entryValue = value;
  if (typeof entryValue == 'number') {
    entryValue = String(entryValue);
  }

  const dateTimeToUse = DateTime.fromISO(entryValue);
  if (dateTimeToUse.isValid) {
    entryValue = dateTimeToUse.toLocaleString(DateTime.DATE_SHORT);
  }
  entryValue = entryValue.toLowerCase();

  if (entryValue.includes(filterValue) || entryValue.includes(filterValueNoBlanks)) {
    return true;
  }

  return false;
}

/**
 * Sorts the passed array of objects using the passed key and the direction.
 * @param arr Array that is sorted in place.
 * @param key String Property of the objects that is used for sorting.
 * @param direction Direction that is used.
 */
export function sort<T>(arr: Array<T>, key: string, direction: SortDirection) {
  if (!arr || arr.length === 0 || key === '') {
    return arr;
  }

  arr.sort((a, b) => {
    const aValue = (a[key as keyof typeof a] as unknown as string) ?? '';
    const bValue = (b[key as keyof typeof b] as unknown as string) ?? '';
    if (direction === 'asc') {
      return aValue.localeCompare(bValue);
    }
    return bValue.localeCompare(aValue);
  });

  return arr;
}
