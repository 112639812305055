import { Component } from '@angular/core';
import { RequestStateStore, TenantSettingsService, UserService } from '@core/services';
import { OverviewComponent } from 'app/components/overview/overview.component';
import { OpenProcessesService } from './open-processes.service';
import { OpenProcessesData, OpenProcessesFilterEntries, OpenProcessesSortKeys } from './open-processes.types';

@Component({
  selector: 'open-processes',
  templateUrl: './open-processes.component.html'
})
export class OpenProcessesComponent extends OverviewComponent {
  public override initialResult: OpenProcessesData[] = [];
  public override overviewData: OpenProcessesData[] = [];
  public sortKeys = OpenProcessesSortKeys;
  protected override sortKey = OpenProcessesSortKeys[0][0];
  protected override viewModeKey: string = 'openRequestViewMode';
  protected override filterEntries: string[] = OpenProcessesFilterEntries;

  constructor(
    public tenantSettingsService: TenantSettingsService,
    openProcessesService: OpenProcessesService,
    userService: UserService,
    requestStateStore: RequestStateStore
  ) {
    super(openProcessesService, userService, requestStateStore);
  }

  refreshData() {
    this.getData(this.lastSearch);
  }
}
