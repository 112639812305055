<ng-container *ngIf="requestContent && !isDataLoading; else requestQuestionnaireLoading">
  <ng-container *transloco="let t">
    <!-- Work instructions component -->
    <work-instructions step="Request_EditQuestionnaires" [currentProductType]="productType"></work-instructions>

    <!-- Information component -->
    <ng-container *ngIf="requestContent.information.showInformation">
      <mat-divider class="my-10"></mat-divider>
      <questionnaire-information [information]="requestContent.information"></questionnaire-information>
    </ng-container>

    <ng-container *ngIf="requestContent.information.showQuestionnaires">
      <mat-divider class="my-10"></mat-divider>
      <h2 class="subtitle ui-test--request-questionnaire-header-title">
        {{ t('Request_Edit_' + requestContent.requestProcess.editProductType + '_Questionnaires') }}
      </h2>

      <form [formGroup]="questionsForm" novalidate>
        <!-- Questionnaire Collection -->
        <div
          *ngFor="let questionnaireGroup of requestContent.questionnaireGroupsWithDistinctQuestions; let questionnaireGroupIndex = index; trackBy: questionnaireGroupIdentifier">
          <ng-container *ngIf="questionnaireGroup.hasAnyQuestions">
            <h3
              class="section-title"
              [class]="'ui-test--request-questionnaire-header-question-group-' + questionnaireGroupIndex">
              {{ t('Request_Questionnaires_QuestionnaireTitle_' + questionnaireGroup.keyName) }}
            </h3>

            <!-- Questionnaire Group -->
            <div
              *ngFor="let questionnaire of questionnaireGroup.questionnaires; let questionnaireIndex = index; trackBy: questionnaireIdentifier"
              class="flex-col justify-start content-stretch">
              <!-- Questionnaire -->
              <ng-container
                *ngFor="let question of questionnaire.questions; trackBy: questionIdentifier, let i = index">
                <questionnaire-question
                  #questionnaireQuestion
                  [class]="'ui-test--request-questionnaire-questionnaire-question-question-' + i + '-questionnaire-' + questionnaireIndex + -'group-' + questionnaireGroupIndex"
                  [groupId]="questionnaire.groupId"
                  [question]="question"
                  [answerCollection]="requestContent.answers"
                  [isPrimaryQuestionnaire]="questionnaire.isPrimary"
                  [hasMultipleAnswersInGroup]="questionnaireGroup.questionnaires.length > 1"></questionnaire-question>
              </ng-container>

              <div
                class="flex-row justify-end content-center"
                *ngIf="questionnaireGroup.isAllowMultiple && questionnaireGroup.questionnaires.length > 1 && !questionnaire.isPrimary">
                <button
                  mat-stroked-button
                  class="delete ui-test--request-questionnaire-button-remove-group"
                  color="warn"
                  (click)="removeQuestionnaireGroupItem(questionnaireGroupIndex, questionnaireIndex)">
                  <mat-icon class="ui-test--request-questionnaire-mat-icon-remove-group">delete</mat-icon>
                  {{ t('Request_Questionnaires_Common_RemoveGroup') }}
                </button>
              </div>

              <div class="flex-row center-center">
                <mat-divider
                  class="flex-grow-80 ml-10 my-20"
                  *ngIf="questionnaireIndex < questionnaireGroup.questionnaires.length - 1"></mat-divider>
              </div>
            </div>

            <div class="flex-row justify-end content-center" *ngIf="isDisplayAddButton(questionnaireGroup)">
              <button
                class="mt-10 'ui-test--request-questionnaire-button-add-group"
                mat-stroked-button
                color="primary"
                (click)="addQuestionnaireGroupItem(questionnaireGroupIndex)">
                <mat-icon class="ui-test--request-questionnaire-mat-icon-add-group">playlist_add</mat-icon>
                {{ t('Request_Questionnaires_Common_AddGroup') }}
              </button>
            </div>

            <mat-divider
              class="my-20"
              *ngIf="questionnaireGroupIndex < requestContent.questionnaireGroupsWithDistinctQuestions.length - 1"></mat-divider>
          </ng-container>
        </div>
      </form>
    </ng-container>

    <!-- Footer actions-->
    <mat-divider class="my-10"></mat-divider>
    <div class="flex-row justify-end content-center mt-20">
      <button
        mat-stroked-button
        class="mr-5 ui-test--request-questionnaire-button-dashboard"
        *ngIf="requestContent.showContractInformationInEditQuestionnaires"
        (click)="showContractInformation()">
        <mat-icon class="ui-test--request-questionnaire-mat-icon-dashboard">dashboard</mat-icon>
        {{ t('PageTitle_Dashboard') }}
      </button>

      <button
        *ngIf="requestContent.displayUndoButton && requestContent.information.showQuestionnaires"
        mat-stroked-button
        class="mr-5 ui-test--request-questionnaire-button-undo"
        [disabled]="confirmActionLoading"
        (click)="undo()">
        <mat-icon class="ui-test--request-questionnaire-mat-icon-undo">undo</mat-icon>
        {{ t('Request_Edit_Undo') }}
      </button>

      <button
        mat-stroked-button
        class="mr-5 ui-test--request-questionnaire-button-edit-position"
        [disabled]="confirmActionLoading"
        [class.button-spinner]="confirmActionLoading && selectedAction === 'Edit'"
        (click)="executeAction('Edit')">
        <mat-icon class="ui-test--request-questionnaire-mat-icon-edit-position">skip_previous</mat-icon>
        {{ t('Request_' + productType + '_EditPositions') }}
      </button>

      <button
        *ngIf="requestContent.information.showQuestionnaires"
        mat-stroked-button
        class="mr-5 ui-test--request-questionnaire-button-save"
        [disabled]="confirmActionLoading"
        [class.button-spinner]="confirmActionLoading && selectedAction === 'Save'"
        (click)="executeAction('Save')">
        <mat-icon class="ui-test--request-questionnaire-mat-icon-save">save</mat-icon>
        {{ t('Request_Edit_Save') }}
      </button>

      <button
        *ngIf="requestContent.information.showQuestionnaires"
        mat-raised-button
        class="continue-button ui-test--request-questionnaire-button-continue"
        color="primary"
        [disabled]="confirmActionLoading"
        [class.button-spinner]="confirmActionLoading && selectedAction === 'Continue'"
        (click)="executeAction('Continue')">
        <mat-icon class="ui-test--request-questionnaire-mat-icon-continue">skip_next</mat-icon>
        {{ t('Request_Edit_Continue') }}
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #requestQuestionnaireLoading>
  <div class="spinner spinner-small"></div>
</ng-template>
