<div class="spinner" *ngIf="loading"></div>

<ng-container *transloco="let t">
  <page-header [title]="t('Menu_Information_Index')"></page-header>
  <ng-container *ngIf="!loading">
    <div *ngIf="entries.length > 0; else noInformation" class="flex-col gap-10">
      <information-entry *ngFor="let entry of entries" [entry]="entry"></information-entry>
    </div>
  </ng-container>
  <ng-template #noInformation>
    <h3 class="text-align-center">{{ t("Information_NoInformation") }}</h3>
  </ng-template>
</ng-container>
