import { Pipe, PipeTransform } from '@angular/core';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';

/**
 * Converts product type key to proper icon
 */
@Pipe({
  name: 'productTypeIcon'
})
export class ProductTypeIconPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param productType A product type key that is going to be translated to icon
   */
  transform(productType: string): string {
    switch (productType as ProductTypeIdentifier) {
      case 'ServiceAndMaintenance':
        return 'build';
      case 'InsuranceRepair':
        return 'car_crash';
      case 'Tires':
        return 'album';
      case 'Mobility':
        return 'transfer_within_a_station';
      case 'Warranty':
        return 'beach_access';
      case 'MotorInsurance':
        return 'security';
      case 'Invoice':
        return 'payments';
      default:
        return '';
    }
  }
}
