import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { WorkInstructionStep } from './work-instructions.types';

@Component({
  selector: 'work-instructions',
  templateUrl: './work-instructions.component.html',
  styleUrls: ['./work-instructions.component.scss']
})
export class WorkInstructionsComponent implements OnInit {
  @Input() texts = 3;
  @Input() step!: WorkInstructionStep;
  @Input() currentProductType!: string | undefined;

  showContent = true;
  workInstructionsResources: string[] = [];

  constructor(private _translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.createResources();
  }

  private createResources(): void {
    const workInstructionsResourceKeys: string[] = [];
    const keyBegin = [this.step, this.currentProductType, 'WorkInstructions_Text'].filter(Boolean).join('_');

    for (let i = 1; i <= this.texts; i++) {
      workInstructionsResourceKeys.push(keyBegin + i);
    }

    workInstructionsResourceKeys.forEach((resourceKey) => {
      const translation = this._translocoService.translate(resourceKey);

      if (translation && translation != resourceKey) {
        this.workInstructionsResources.push(translation);
      }
    });
  }
}
