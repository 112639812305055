<div class="request-important-information" *transloco="let t">
  <mat-expansion-panel hideToggle [expanded]="hasData || initiallyOpen" #importantInformationPanel>
    <mat-expansion-panel-header #importantInformationHeader (click)="importantInformationHeader._toggle()">
      <mat-panel-title class="ui-test--important-information-mat-panel-title-title" (click)="toggle()">
        <h2 class="subtitle ui-test--important-information-header-title">
          {{ t('Request_Summary_ImportantInformation_Headline') }}
          <mat-icon class="mx-10 ui-test--important-information-mat-icon-title">
            {{ importantInformationPanel.expanded ? 'visibility' : 'visibility_off' }}
          </mat-icon>
        </h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="importantInformationForm" novalidate class="mb-20">
      <div class="mb-20" *ngIf="showTextModule">
        <p class="ui-test--important-information-paragraph-headline">{{ t('Request_Summary_TextModules_Headline') }}</p>

        <mat-form-field class="w-full">
          <mat-select
            class="ui-test--important-information-mat-select-option"
            formControlName="selectedTextModuleId"
            [placeholder]="t('Common_PleaseChoose')">
            <mat-option class="ui-test--important-information-mat-option-please-choose">
              {{ t('Common_PleaseChoose') }}
            </mat-option>
            <mat-option
              [value]="option.id"
              *ngFor="let option of textModules; let i = index"
              [class]="'ui-test--importantInformation-mat-option-option-text-' + i">
              {{ option.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <p class="ui-test--important-information-paragraph-user-note-info-text-key">{{ t(userNoteInfoTextKey) }}</p>
      <mat-form-field class="w-full">
        <mat-label class="ui-test--important-information-mat-label-user-notes">
          {{ t('RequestSummaryViewModel_UserNote') }}
        </mat-label>
        <textarea
          class="ui-test--important-information-textarea-user-notes"
          matInput
          formControlName="userNote"
          rows="5"></textarea>
      </mat-form-field>
    </form>
  </mat-expansion-panel>
</div>
