import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TenantSettingsService, UserService } from '@core/services';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { stringFormat } from '@core/utils/helper-functions';
import { TranslocoService } from '@ngneat/transloco';
import { SubType } from 'app/pages/dashboard/payment-choose-dialog';
import { UnsavedChangesStateStore } from '../../unsaved-changes-state-store.service';
import { PaymentInformation } from './payment-choose-panel.types';

@Component({
  selector: 'payment-choose-panel',
  templateUrl: './payment-choose-panel.component.html',
  styleUrls: ['./payment-choose-panel.component.scss']
})
export class PaymentChoosePanelComponent implements OnInit {
  @Input() paymentInformation!: PaymentInformation;
  @Input() productType!: ProductTypeIdentifier;
  @Output() sendPayment = new EventEmitter();
  @Output() updatePayment = new EventEmitter();

  subTypes: SubType[] = [];
  selectedOutstandingAmount: number = 0;

  constructor(
    private translocoService: TranslocoService,
    private router: Router,
    private snackbar: MatSnackBar,
    private tenantSettingsService: TenantSettingsService,
    private unsavedChangesStateStore: UnsavedChangesStateStore,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.prepareSubTypes();
    this.preselectOutstandingAmount();

    if (this.paymentInformation.paymentOptions?.indexOf(this.paymentInformation.selectedPaymentOption) >= 0) {
      this.paymentInformation.paymentSelectionLocked = true;
    }
  }

  private prepareSubTypes() {
    if (this.paymentInformation.outstandingPayments.length === 0) {
      return;
    }

    // Backend can return same product type multiple times, so we need to merge items
    const outstandingPaymentItemsForProductType = this.paymentInformation.outstandingPayments.filter(
      (outstandingPayment) => outstandingPayment.productType === this.productType
    );

    let availableSubTypes: SubType[] = [];

    outstandingPaymentItemsForProductType.forEach((outstandingPayment) => {
      availableSubTypes = availableSubTypes.concat(
        outstandingPayment.items.map(
          (item) =>
            <SubType>{
              subtype: item.subtype,
              text:
                item.subtype +
                ' ' +
                item.outstandingAmount +
                ' ' +
                this.translocoService.translate('Common_CurrencySymbol_' + item.currency),
              outstandingAmount: item.outstandingAmount
            }
        )
      );
    });

    this.subTypes = availableSubTypes;
  }

  preselectOutstandingAmount() {
    if (this.subTypes && this.subTypes.length) {
      let selectedSubType = this.subTypes.find((item) => item.subtype === this.paymentInformation.selectedSubtype);

      // Set default preselection if only one is available
      if (this.subTypes.length === 1) {
        selectedSubType = this.subTypes[0];
      }

      if (selectedSubType) {
        this.paymentInformation.selectedSubtype = selectedSubType.subtype;
        this.selectedOutstandingAmount = selectedSubType.outstandingAmount;
      }
    }
  }

  subTypeSelection(value: string) {
    this.selectedOutstandingAmount = this.subTypes.find((subType) => subType.subtype === value)?.outstandingAmount ?? 0;
  }

  isBlockingPaymentSelected() {
    return (
      this.paymentInformation.blockingPayments &&
      this.paymentInformation.blockingPayments.length > 0 &&
      this.paymentInformation.blockingPayments.indexOf(this.paymentInformation.selectedPaymentOption) >= 0
    );
  }

  sendPaymentInformation() {
    let found = false;
    this.paymentInformation.selectedPaymentOptionsByProductType.forEach((pair) => {
      if (pair.key == this.productType) {
        pair.value = this.paymentInformation.selectedPaymentOption;
        found = true;
      }
    });

    if (!found) {
      this.paymentInformation.selectedPaymentOptionsByProductType.push({
        key: this.productType,
        value: this.paymentInformation.selectedPaymentOption
      });
    }

    this.sendPayment.emit();

    if (
      this.isBlockingPaymentSelected() &&
      this.tenantSettingsService.blockingPaymentOptionsDisableRequestCreation === true
    ) {
      const paymentOptionTranslationKey = this.translocoService.translate(
        `Common_PaymentOptions_${this.paymentInformation.selectedPaymentOption}`
      );
      const stopPaymentOption = stringFormat(
        this.translocoService.translate('Common_PaymentChoose_stopPaymentOption'),
        paymentOptionTranslationKey
      );
      this.snackbar.open(`${stopPaymentOption}`);
      this.router.navigate(['/dashboard']);
    } else {
      this.paymentInformation.paymentSelectionLocked = true;
      this.unsavedChangesStateStore.addEventMessage('Dirty');
      this.updatePayment.emit();
    }
  }

  unlockSelection() {
    this.paymentInformation.paymentSelectionLocked = false;
    this.unsavedChangesStateStore.addEventMessage('Dirty');
    this.updatePayment.emit();
  }
}
