import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PHONE_NUMBER_REGEX } from '@core/constants/pattern';
import { ContextDataService } from '@core/services';
import { PhoneNumberVerificationService } from './phone-number-verification.service';

@Component({
  selector: 'phone-number-verification-dialog',
  templateUrl: './phone-number-verification.component.html'
})
export class PhoneNumberVerificationDialogComponent {
  showError: boolean = false;
  phoneNumber: FormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(20),
    Validators.pattern(PHONE_NUMBER_REGEX)
  ]);
  confirmLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<PhoneNumberVerificationDialogComponent>,
    private phoneNumberVerificationService: PhoneNumberVerificationService,
    private contextDataService: ContextDataService
  ) {}

  confirm(): void {
    if (!this.phoneNumber.valid) {
      return;
    }
    this.showError = false;

    this.confirmLoading = true;
    this.phoneNumberVerificationService.save(this.phoneNumber?.value).subscribe({
      next: () => {
        this.confirmLoading = false;
        this.contextDataService.phoneNumber = this.phoneNumber?.value;
        this.dialogRef.close();
      },
      error: () => {
        this.showError = true;
        this.confirmLoading = false;
      }
    });
  }
}
