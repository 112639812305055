import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Settings } from 'luxon';
import { map } from 'rxjs';
import { TenantSettingKeys } from './tenant-settings.enums';
import { TenantSettingCommon } from './tenant-settings.types';

@Injectable({ providedIn: 'root' })
export class TenantSettingsService {
  private tenantSettings!: TenantSettingCommon[];

  private emptyPositionConfiguration!: string[];

  constructor(private httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get allowedFileTypes(): string {
    return this.getTenantSettingValue(TenantSettingKeys.AllowedFileTypes);
  }

  get culture(): string {
    return this.getTenantSettingValue(TenantSettingKeys.Culture);
  }

  get searchIndexDisplaySettings(): number {
    return parseFloat(this.getTenantSettingValue(TenantSettingKeys.SearchIndexDisplaySettings));
  }

  get searchIndexEnabledFieldsForMissingParameter(): number {
    return parseFloat(this.getTenantSettingValue(TenantSettingKeys.SearchIndexEnabledFieldsForMissingParameter));
  }

  get searchIndexFirstTimeRegistrationDisplaySettings(): number {
    return parseFloat(this.getTenantSettingValue(TenantSettingKeys.SearchIndexFirstTimeRegistrationDisplaySettings));
  }

  get requestStorageLifetime(): number {
    return parseFloat(this.getTenantSettingValue(TenantSettingKeys.RequestStorageLifetime));
  }

  get requestPaymentOptionSelectionInDashboard(): boolean {
    return this.getTenantSettingValue(TenantSettingKeys.RequestPaymentOptionSelectionInDashboard) === 'true';
  }

  get blockingPaymentOptionsDisableRequestCreation(): boolean {
    return this.getTenantSettingValue(TenantSettingKeys.BlockingPaymentOptionsDisableRequestCreation) === 'true';
  }

  get userProfileAvailableFields(): string {
    return this.getTenantSettingValue(TenantSettingKeys.UserProfileAvailableFields);
  }

  get enableCallMeBack(): boolean {
    return this.getTenantSettingValue(TenantSettingKeys.EnableCallMeBack) === 'true';
  }

  get userProfileCategorizedHourlyRatesDisplayColumns(): string {
    return this.getTenantSettingValue(TenantSettingKeys.UserProfileCategorizedHourlyRatesDisplayColumns);
  }

  get displayUserProfileBaseProductHourlyRates(): boolean {
    return this.getTenantSettingValue(TenantSettingKeys.DisplayUserProfileBaseProductHourlyRates) === 'true';
  }

  get showPayableByCustomerOptions(): boolean {
    return this.getTenantSettingValue(TenantSettingKeys.ShowPayableByCustomerOptions) === 'true';
  }

  get requestDoubletCheckingPeriod(): number {
    return parseFloat(this.getTenantSettingValue(TenantSettingKeys.RequestDoubletCheckingPeriod));
  }

  get serviceAndMaintenanceWorkPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.ServiceAndMaintenanceWorkpositionDisplaySettings);
  }

  get insuranceRepairWorkPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.InsuranceRepairWorkpositionDisplaySettings);
  }

  get phoneNumberRenewalPeriod(): number {
    return parseFloat(this.getTenantSettingValue(TenantSettingKeys.PhoneNumberRenewalPeriod));
  }

  get phoneNumberWaitPeriod(): number {
    return parseFloat(this.getTenantSettingValue(TenantSettingKeys.PhoneNumberWaitPeriod));
  }

  get dmsServiceAndMaintenanceWorkPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.DMSServiceAndMaintenanceWorkpositionDisplaySettings);
  }

  get dmsServiceAndMaintenancePackagePositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.DMSServiceAndMaintenancePackagePositionDisplaySettings);
  }

  get dmsInsuranceRepairWorkPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.DMSInsuranceRepairWorkpositionDisplaySettings);
  }

  get dmsInsuranceRepairSparePartPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.DMSInsuranceRepairSparepartsDisplaySettings);
  }

  get dmsInsuranceRepairPackagePositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.DMSInsuranceRepairPackagePositionDisplaySettings);
  }

  get dmsTireServicePositionPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.DMSTireServicePositionDisplaySettings);
  }

  get dmsTirePositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.DMSTirePositionDisplaySettings);
  }

  get dmsTireSparePartPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.DMSTireSparepartsDisplaySettings);
  }

  get dmsTirePackagePositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.DMSTirePackagePositionDisplaySettings);
  }

  get insuranceRepairSparePartPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.InsuranceRepairSparepartsDisplaySettings);
  }

  get dMSServiceAndMaintenanceSparePartPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.DMSServiceAndMaintenanceSparepartsDisplaySettings);
  }

  get enableInvoiceClarificationMenu(): boolean {
    return this.getTenantSettingValue(TenantSettingKeys.EnableInvoiceClarificationMenu) === 'true';
  }

  get serviceAndMaintenanceSparePartPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.ServiceAndMaintenanceSparepartsDisplaySettings);
  }

  get tireServicePositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.TireServicePositionDisplaySettings);
  }

  get tireSparePartPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.TireSparepartsPositionDisplaySettings);
  }

  get shouldAttachmentsSortingAscending(): boolean {
    return this.getTenantSettingValue(TenantSettingKeys.ShouldAttachmentsSortingAscending) === 'true';
  }

  get attachmentsSortingValue(): string {
    return this.getTenantSettingValue(TenantSettingKeys.AttachmentsSortingValue);
  }

  get tirePositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.TirePositionDisplaySettings);
  }

  get packagePositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.PackagePositionDisplaySettings);
  }

  get mobilityPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.MobilityPositionDisplaySettings);
  }

  get mobilitySparePartPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.MobilitySparepartsDisplaySettings);
  }

  get enableAftersalesOverview(): boolean {
    return this.getTenantSettingValue(TenantSettingKeys.EnableAftersalesOverview) === 'true';
  }

  get warrantyPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.WarrantyPositionDisplaySettings);
  }

  get warrantySparePartPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.WarrantySparepartsPositionDisplaySettings);
  }

  get warrantyDiagnosticPositionConfiguration(): string {
    return this.getTenantSettingValue(TenantSettingKeys.WarrantyDiagnosticPositionDisplaySettings);
  }

  get warrantyEditMode(): string {
    return this.getTenantSettingValue(TenantSettingKeys.WarrantyEditMode);
  }

  get disableOfferDetailsPage(): boolean {
    return this.getTenantSettingValue(TenantSettingKeys.DisableOfferDetailPage) === 'true';
  }

  get serviceAdvisorRegexValidation(): string {
    return this.getTenantSettingValue(TenantSettingKeys.ServiceAdvisorRegexValidation);
  }

  get salesNumberRegexValidation(): string {
    return this.getTenantSettingValue(TenantSettingKeys.SalesNumberRegexValidation);
  }

  get isDealerSelfServiceActive(): boolean {
    return this.getTenantSettingValue(TenantSettingKeys.IsDealerSelfServiceActive) === 'true';
  }

  get emptyPositions(): string[] {
    return this.emptyPositionConfiguration;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Find property/option in tenant settings object and then return child property called `value`
   */
  private getTenantSettingValue(key: TenantSettingKeys | string): string {
    return this.tenantSettings.find((ts) => ts.key == key.toString())?.value ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Load tenant settings key/values
   */
  load(tenantName: string) {
    const url = `${environment.isportCoreApiUrl}/api/settings/tenant?tenantName=${tenantName}`;
    return this.httpClient.get<TenantSettingCommon[]>(url).pipe(
      map((result) => {
        this.tenantSettings = result;

        // Setup default culture based on tenant setting
        Settings.defaultLocale = this.culture;
      })
    );
  }

  loadEmptyPositions() {
    const url = `${environment.isportCoreApiUrl}/api/settings/empty-positions`;
    return this.httpClient.get<string[]>(url).pipe(
      map((result) => {
        this.emptyPositionConfiguration = result;
      })
    );
  }
}
