<div *transloco="let t" [style.display]="positionData.isPositionMarkedAsDeleted ? 'none': ''">
  <mat-expansion-panel hideToggle #positionsPanel *transloco="let t">
    <mat-icon
      class="request-position-icon ui-test--request-mobility-position-mat-icon-mobility"
      [ngClass]="['zoom-level-' + visibleRows]">
      {{ positionData.itemType | positionIcon }}
    </mat-icon>

    <mat-expansion-panel-header
      #positionsHeader
      (click)="positionsHeader._toggle()"
      [class.validation-error]="hasValidationError || positionData.validationErrors?.size">
      <mat-panel-title>
        <div class="flex-row flex-grow-100 justify-between content-center align-center">
          <div class="flex-grow-10">
            <small class="mr-10 ui-test--request-mobility-position-small-laborPositionIndex">
              {{ positionData.laborPositionIndex }}
            </small>
            <small class="ui-test--request-mobility-position-small-abbreviation">
              {{ t('Common_MobilityPositionAbbreviation') }}
            </small>
          </div>

          <small class="flex-grow-10 ui-test--request-mobility-position-small-external-product-number">
            {{ positionData.externalProductNumber }}
          </small>

          <small class="flex-grow-20 ui-test--request-mobility-position-small-description">
            {{ displayDescription() }}
          </small>

          <small
            class="flex-grow-10 ui-test--request-mobility-position-small-quantity"
            *ngIf="isEnabled(positionData, 'Quantity')">
            {{ t('Common_MobilityPosition_Quantity') }}: {{ displayQuantity }}
          </small>

          <div class="flex-grow-10">
            <ng-container *ngIf="isEnabled(positionData, 'Discount')">
              <small class="ui-test--request-mobility-position-small-discount">
                {{ t('Common_DiscountAbbreviation') }}: {{ displayDiscount }}
              </small>
            </ng-container>
            <ng-container *ngIf="isEnabled(positionData, 'Goodwill')">
              <small class="ui-test--request-mobility-position-small-goodwill">
                {{ t('Common_GoodwillAbbreviation') }}: {{ displayGoodwill }}
              </small>
            </ng-container>
            <ng-container *ngIf="isEnabled(positionData, 'MaterialGoodwill')">
              <small class="ui-test--request-mobility-position-small-material-goodwill">
                {{ t('Common_MaterialGoodwillAbbreviation') }}: {{ displayMaterialGoodwill }}
              </small>
            </ng-container>
          </div>

          <small
            class="flex-grow-10 justify-end content-center align-center ui-test--request-mobility-position-small-amount-label"
            *ngIf="isEnabled(positionData, 'UnitPrice')">
            <strong class="ui-test--request-mobility-position-strong-amount">
              {{ t('Common_Total') }}: {{ netAmount | currencyOrDash }}
            </strong>
          </small>

          <mat-action-row class="justify-end content-center">
            <button mat-raised-button (click)="savePosition()" class="ui-test--request-mobility-position-button-save">
              <mat-icon class="ui-test--request-mobility-position-mat-icon-save">save</mat-icon>
            </button>
            <button
              mat-raised-button
              class="delete ui-test--request-mobility-position-button-delete"
              (click)="removePosition()">
              <mat-icon class="ui-test--request-mobility-position-mat-icon-delete">delete</mat-icon>
            </button>
            <button
              mat-raised-button
              click-stop-propagation
              class="ui-test--request-mobility-position-button-expand"
              (click)="togglePosition(true)">
              <mat-icon class="ui-test--request-mobility-position-mat-icon-expand">
                {{ positionsPanel.expanded ? 'close_fullscreen' : 'open_in_full' }}
              </mat-icon>
            </button>
          </mat-action-row>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <form [formGroup]="positionFormGroup">
      <div class="flex-row justify-between content-center">
        <div class="flex-grow-25 flex-offset-15">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[0]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[1]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[2]"></ng-container>
        </div>
      </div>
      <div class="flex-row justify-between content-center">
        <div class="flex-grow-25 flex-offset-15">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[3]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[4]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[5]"></ng-container>
        </div>
      </div>
      <div class="flex-row justify-between content-center">
        <div class="flex-grow-25 flex-offset-15">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[6]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[7]"></ng-container>
        </div>
        <div class="flex-grow-25">
          <ng-container [ngTemplateOutlet]="positionParameterConfiguration[8]"></ng-container>
        </div>
      </div>
    </form>

    <mat-action-row class="justify-start content-center">
      <button
        mat-stroked-button
        color="primary"
        class="ui-test--request-mobility-position-button-add-mobility-spare-part"
        [disabled]="hasNoActivityTypeForRentalReason()"
        (click)="addSparePart()">
        {{ t('Request_Edit_AddMobilitySparePart') }}
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</div>

<ng-template name="activityTypeKey">
  <dropdown-with-search
    class="ui-test--request-mobility-position-dropdown-with-search-activity"
    [labelResourceKey]="positionData.templateName + '_ActivityTypeKey'"
    formControlName="ActivityTypeKey"
    [dropDownOptions]="{
      options: activityTypesWithAssignedClass,
      optionsValue: 'id',
      optionsText: 'description'
    }"
    [isDisabled]="true"
    [(value)]="positionData.activityTypeKey"
    [validators]="[Validators.required]"
    [sharedFormGroup]="positionFormGroup"
    (valueChange)="editActivityTypeKeyWithClass($event)"></dropdown-with-search>
</ng-template>

<ng-template name="quantity">
  <input-group
    class="ui-test--request-mobility-position-input-group-quantity"
    [viewModelName]="positionData.templateName"
    name="Quantity"
    type="number"
    [inputSuffix]="displayQuantityUnit()"
    [validators]="{required: true, min: configurationService.mobilityPositionQuantityFrom, max: configurationService.mobilityPositionQuantityTo} | validators"
    [isDisabled]="hasNoComponent || hasNetFixedPrice"
    [value]="positionData.quantity"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editQuantity($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="unitPrice">
  <input-group
    class="ui-test--request-mobility-position-input-group-unit-price"
    [viewModelName]="positionData.templateName"
    name="UnitPrice"
    type="currency"
    [inputSuffix]="requestContent.requestProcess.displayCurrencyUnit"
    [validators]="{required: true, min: configurationService.positionUnitPriceFrom, max: configurationService.positionUnitPriceTo} | validators"
    [isDisabled]="isUnitPriceDisabled"
    [value]="positionData.unitPrice"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editUnitPrice($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="netFixedPrice">
  <input-group
    [viewModelName]="positionData.templateName"
    name="NetFixedPrice"
    type="currency"
    [class]="'ui-test--request-mobility-position-inputGroup-netFixedPrice'"
    [inputSuffix]="requestContent.requestProcess.displayCurrencyUnit"
    [validators]="{required: true, min: configurationService.positionNetFixedPriceFrom, max: configurationService.positionNetFixedPriceTo} | validators"
    [isDisabled]="isNetFixedPriceDisabled"
    [value]="positionData.netFixedPrice"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editNetFixedPrice($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="discount">
  <input-group
    class="ui-test--request-mobility-position-inputGroup-discount"
    [viewModelName]="positionData.templateName"
    name="Discount"
    type="percent"
    [isDisabled]="hasNoComponent"
    [validators]="{min: configurationService.positionDiscountFrom, max: configurationService.positionDiscountTo} | validators"
    [value]="positionData.discount"
    [sharedFormGroup]="positionFormGroup"
    (inputGroupValue)="editDiscount($event)"
    (triggerStatusCheck)="updateHasValidationErrors()"></input-group>
</ng-template>

<ng-template name="rentalReason">
  <dropdown-with-search
    class="ui-test--request-mobility-position-dropdown-with-search-rental-reason"
    [labelResourceKey]="positionData.templateName + '_RentalReasonId'"
    formControlName="RentalReasonId"
    [(value)]="positionData.rentalReasonId"
    [dropDownOptions]="{
      options: rentalReasons,
      optionsText: 'description',
      optionsValue: 'id'
    }"
    [sharedFormGroup]="positionFormGroup"
    (valueChange)="onRentalReasonIdChange($event)"></dropdown-with-search>
</ng-template>

<ng-template name="none"></ng-template>
