import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { RequestStateLabelModule } from '../request-state-label/request-state-label.module';
import { PositionsComponent } from './positions.component';

@NgModule({
  imports: [MatCardModule, MatTooltipModule, MatDividerModule, PipesModule, RequestStateLabelModule, SharedModule],
  exports: [PositionsComponent],
  declarations: [PositionsComponent],
  providers: []
})
export class PositionsModule {}
