<div *transloco="let t">
  <page-header [title]="t('PageTitle_InvoiceClarification')"></page-header>

  <h2 class="ui-test--ic-confirmation-header-confirm">{{ t('Invoice_Clarification_Confirm_Headline') }}</h2>
  <blockquote>
    <p class="ui-test--ic-confirmation-paragraph-confirm">
      {{ t('Invoice_Clarification_SuccessMessage_Transmission_Text') | stringFormat:
      invoiceClarificationConfirmation?.displayNumber ?? '' }}
    </p>
  </blockquote>

  <mat-divider class="my-10"></mat-divider>

  <div *ngIf="invoiceClarificationConfirmation?.requestGuid" class="flex-row justify-end content-center">
    <button class="ui-test--ic-confirmation-button-detail-view" mat-stroked-button (click)="openStatusDetailsDialog()">
      {{ t('Invoice_Clarification_Confirm_StatusOverview_Index') }}
    </button>
  </div>
</div>
