import { Component, Input } from '@angular/core';
import { TotalAmounts } from './request-total-amounts.types';

@Component({
  selector: 'request-total-amounts',
  templateUrl: './request-total-amounts.component.html'
})
export class RequestTotalAmountsComponent {
  @Input() totalAmounts!: TotalAmounts;
  @Input() showDashAsNoValue: boolean = false;
}
