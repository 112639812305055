import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, take, tap } from 'rxjs/operators';
import { BroadcastMessage, SessionStorageData } from './session-sync.types';

/**
 * During initialization, we ask potentially other existing iSport tabs (same origin) for their session data.
 * We wait a certain delay until we stop waiting for session data.
 * The goal is to enable users to open a new tab from an existing iSport tab and keep the existing session.
 *
 * In case a user has multiple iSport tabs already open, we will receive multiple session data.
 * Therefore, we update the `latestInteractionTimestamp` every time the user interacts with iSport.
 * And when receiving multiple session data in a new tab, we use the one with the latest timestamp.
 */
@Injectable({
  providedIn: 'root'
})
export class SessionSyncService {
  private channelName = 'sessionSyncChannel';
  private broadcastChannel: BroadcastChannel;
  private sessionDataResponses: Subject<SessionStorageData> = new Subject();
  private latestSessionData: SessionStorageData | null = null;
  private sessionDataResponses$?: Subscription;

  constructor(private router: Router) {
    this.broadcastChannel = new BroadcastChannel(this.channelName);
    this.listenForUpdates();
    this.subscribeToRouterEvents();
    this.latestInteractionTimestamp = Date.now();
  }

  private set latestInteractionTimestamp(value: number) {
    sessionStorage.setItem('latest-interaction-timestamp', value.toString());
  }

  initialize() {
    this.broadcastChannel.postMessage({ action: 'requestSessionData' } as BroadcastMessage);

    this.sessionDataResponses$ = this.sessionDataResponses
      .pipe(
        tap((data) => {
          if (!this.latestSessionData) {
            this.latestSessionData = data;
            return;
          }

          const dataTimestamp = data['latest-interaction-timestamp'];
          const latestSessionDataTimestamp = this.latestSessionData['latest-interaction-timestamp'];
          if (dataTimestamp && latestSessionDataTimestamp && dataTimestamp > latestSessionDataTimestamp) {
            this.latestSessionData = data;
          }
        }),
        debounceTime(500),
        take(1)
      )
      .subscribe(() => {
        if (this.latestSessionData) {
          this.initializeSessionData(this.latestSessionData);
          this.latestSessionData = null;
        }
      });
  }

  unsubscribe() {
    this.sessionDataResponses$?.unsubscribe();
  }

  subscribeToRouterEvents() {
    this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe(() => {
      this.latestInteractionTimestamp = Date.now();
    });
  }

  private listenForUpdates(): void {
    this.broadcastChannel.onmessage = (event: MessageEvent<BroadcastMessage>) => {
      switch (event.data.action) {
        case 'requestSessionData':
          this.broadcastChannel.postMessage({
            action: 'provideSessionData',
            data: this.getSessionData()
          } as BroadcastMessage);
          break;
        case 'provideSessionData':
          if (event.data.data) {
            this.sessionDataResponses.next(event.data.data);
          }
          break;
      }
    };
  }

  private getSessionData(): SessionStorageData {
    const data: SessionStorageData = {};
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i) as string;
      const value = sessionStorage.getItem(key) as string;
      data[key] = value;
    }
    return data;
  }

  private initializeSessionData(data: SessionStorageData): void {
    Object.keys(data).forEach((key) => {
      const value = data[key] as string;
      sessionStorage.setItem(key, value);
    });
  }
}
