<div *transloco="let t">
  <mat-expansion-panel
    class="ui-test--file-upload-mat-expansion-panel-panel"
    hideToggle
    #fileUploadPanel
    [expanded]="expandedByDefault || files.length > 0">
    <mat-expansion-panel-header
      class="ui-test--file-upload-mat-expansion-panel-header-header"
      #fileUploadHeader
      (click)="fileUploadHeader._toggle()">
      <mat-panel-title class="ui-test--file-upload-mat-panel-title-title" (click)="toggle()">
        <h2 class="subtitle ui-test--file-upload-header-request-summary-attachments">
          {{ t('Request_Summary_Attachments') }}
          <mat-icon class="mx-10 ui-test--file-upload-mat-icon-icon">
            {{ fileUploadPanel.expanded ? 'visibility' : 'visibility_off' }}
          </mat-icon>
        </h2>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <p class="ui-test--file-upload-paragraph-text-1">
      {{ t(uploadInstructionInformationText1Key) | stringFormat: acceptedFileTypesDisplay}}
    </p>
    <p class="ui-test--file-upload-paragraph-text-2">
      {{ t(uploadInstructionInformationText2Key) | stringFormat: maxFileSize : maxFileSizeSum }}
    </p>
    <p class="ui-test--file-upload-paragraph-text-3" *ngIf="uploadInstructionInformationText3Key">
      <b>{{ t(uploadInstructionInformationText3Key) }}</b>
    </p>

    <div class="flex-col gap-10" *ngFor="let uploadFile of files; index as index">
      <div class="flex-row justify-start content-start align-start gap-5">
        <mat-form-field
          class="file"
          [class]="'ui-test--file-upload-mat-form-field-upload-' + index"
          [class.uploaded]="uploadFile.uploaded"
          [class.error]="uploadFile.invalid"
          appearance="outline">
          <input
            [class]="'ui-test--file-upload-input-upload-' + index"
            matInput
            disabled
            [value]="uploadFile.filename" />
          <span matPrefix [class]="'ui-test--file-upload-span-prefix-' + index">
            <mat-icon [class]="'ui-test--file-upload-mat-icon-prefix-icon-' + index">drive_file_move</mat-icon>
          </span>
          <span matSuffix [class]="'ui-test--file-upload-span-size-' + index">
            {{ (uploadFile.contentLength / (1024*1024)).toFixed(2) }}MB
          </span>
          <mat-hint [class]="'ui-test--file-upload-mat-hint-upload-' + index" *ngIf="uploadFile.invalid">
            {{ uploadFile.message }}
          </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label [class]="'ui-test--file-upload-mat-label-category-' + index">
            {{ t('Request_Summary_FileUploadCategory') }}
          </mat-label>
          <mat-select
            [(value)]="uploadFile.category"
            [class]="'ui-test--file-upload-mat-select-category-' + index"
            [disabled]="isButtonDisabled('selectCategory', uploadFile)">
            <mat-option
              *ngFor="let category of fileCategories; let i =index"
              [value]="category.value"
              [class]="'ui-test--file-upload-mat-option-resource-key-' + index + '-option-' + i">
              {{ t(category.resourceKey) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          class="file-button"
          [class]="'ui-test--file-upload-button-upload-' + index"
          [disabled]="isButtonDisabled('upload', uploadFile)"
          [class.button-spinner]="uploadFile.uploadInProgress"
          (click)="uploadFileAtIndex(index)"
          [title]="t('Request_Attachments_UploadFile')"
          mat-stroked-button>
          <mat-icon [class]="'ui-test--file-upload-mat-icon-upload-' + index">file_upload</mat-icon>
        </button>
        <button
          class="file-button delete"
          [class]="'ui-test--file-upload-button-delete' + index"
          [disabled]="isButtonDisabled('delete', uploadFile)"
          [class.button-spinner]="uploadFile.deleteInProgress"
          (click)="deleteFileAtIndex(index)"
          [title]="t('Request_Attachments_DeleteFile')"
          mat-stroked-button>
          <mat-icon [class]="'ui-test--file-upload-mat-icon-delete-' + index">delete</mat-icon>
        </button>
      </div>
    </div>

    <div class="flex-row justify-start content-start align-start gap-5 mt-10">
      <button
        class="ui-test--file-upload-button-add-file"
        mat-stroked-button
        [disabled]="isButtonDisabled('fileSelection')"
        (click)="fileInput.click()">
        <mat-icon class="ui-test--file-upload-mat-icon-add-file">create_new_folder</mat-icon>
        {{ t('Request_Summary_Attachments_AddFiles') }}
      </button>
      <input
        class="ui-test--file-upload-input-add-file"
        hidden
        (change)="onFileSelected($event)"
        #fileInput
        type="file"
        multiple
        [accept]="acceptedFileTypes" />
      <button
        class="ui-test--file-upload-button-upload-file"
        [disabled]="isButtonDisabled('uploadAll')"
        [class.button-spinner]="currentlyUploadingFiles"
        (click)="uploadAll()"
        mat-stroked-button>
        <mat-icon class="ui-test--file-upload-mat-icon-upload-file">cloud_upload</mat-icon>
        {{ t('Request_Summary_Attachments_UploadFiles') }}
      </button>
      <button
        class="ui-test--file-upload-button-cancel-all"
        [disabled]="isButtonDisabled('cancelNotUploadedFiles')"
        (click)="cancelNotUploadedFiles()"
        mat-stroked-button>
        <mat-icon class="ui-test--file-upload-mat-icon-close">close</mat-icon>
        {{ t('Request_Summary_Attachments_CancelAll') }}
      </button>
    </div>
    <mat-progress-bar
      class="ui-test--file-upload-mat-progress-bar-progress-bar"
      [class.display-none]="!currentlyUploadingFiles"
      mode="indeterminate"></mat-progress-bar>
  </mat-expansion-panel>
</div>

<div class="dropzone ui-test--file-upload-div-drop-zone" [class.show]="showDropzone">
  <mat-icon class="ui-test--file-upload-mat-icon-drop-zone">upload_file</mat-icon>
</div>
