import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ContextDataService } from '@core/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private contextDataService: ContextDataService) {}

  canActivate(): Observable<boolean> {
    return this.contextDataService.isAuthenticated();
  }
}
