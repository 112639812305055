import { Pipe, PipeTransform } from '@angular/core';
import { LocaleFormatService } from '@core/services';

@Pipe({
  name: 'currencyOrDash'
})
export class CurrencyOrDashPipe implements PipeTransform {
  constructor(private localeFormatService: LocaleFormatService) {}

  transform(value: number | null | undefined, useDashOnZeroValue: boolean = true): string {
    // use '-' instead of 0, null, undefined value when useDashOnZeroValue is true or always when value is undefined or null
    if ((!value && useDashOnZeroValue) || value === undefined || value === null) {
      return '-';
    }

    return this.localeFormatService.formatNumericString(value.toString(), { fractionDigits: 2, currencySymbol: 'EUR' });
  }
}
