import { Component, Input } from '@angular/core';
import { StatusDetails } from '../status-details-dialog.types';

@Component({
  selector: 'status-details-invoice-history',
  templateUrl: './invoice-history.component.html',
  styleUrls: ['./invoice-history.component.scss']
})
export class StatusDetailsInvoiceHistoryComponent {
  @Input() statusDetails!: StatusDetails;
}
