<div class="spinner" *ngIf="loading"></div>

<validation-summary [errorList]="errorList"></validation-summary>

<div *transloco="let t">
  <page-header
    [title]="t('Menu_OpenRequest_Index')"
    [hint]="t('OpenRequest_DisplayRequestStorageLifetimeInformation') | stringFormat: tenantSettingsService.requestStorageLifetime.toString()"></page-header>
  <div *ngIf="filterCriteria">
    <div class="mb-15">
      <filter
        [filterCriteria]="filterCriteria"
        [visibleFilterParameters]="['datePickerFrom', 'datePickerTo', 'productList', 'advisorList', 'searchButton']"
        [datesMaximumDaysInPastCount]="tenantSettingsService.requestStorageLifetime"
        (submitFilter)="getData($event)"></filter>
    </div>
    <sort
      [visibleComponents]="['panelExpandButton', 'search']"
      [sortKeys]="sortKeys"
      [panelExpanded]="panelExpanded"
      (search)="search($event)"
      (sort)="sort($event)"
      (panelExpandedChange)="onPanelExpandedChange($event)"></sort>
  </div>
  <entries-counter
    *ngIf="overviewData.length > 0 && !loading"
    [shownEntriesCount]="shownEntriesCount"
    [totalEntriesCount]="totalEntriesCount"></entries-counter>
  <div
    class="mb-60"
    *ngIf="!loading && overviewData"
    infinite-scroll
    [infiniteScrollDistance]="5"
    [infiniteScrollThrottle]="500"
    (scrolled)="onScroll()">
    <div *ngFor="let data of overviewData; let i = index">
      <overview-panel
        *ngIf="data.show"
        [data]="data"
        [class]="'ui-test--open-process-overview-panel-panel-' + i"
        [isPanelExpanded]="panelExpanded"
        [isManagingDealer]="isManagingDealer"
        (refreshData)="refreshData()"></overview-panel>
    </div>
    <scroll-top-button></scroll-top-button>
    <mat-progress-bar *ngIf="scrollLoading" mode="buffer" class="my-15"></mat-progress-bar>
  </div>
</div>
