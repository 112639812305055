import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoleCommon } from '@core/services/roles/role.enums';
import { UserInfo } from '@core/services/user/user.types';
import { base64DecodeUnicode } from '@core/utils';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, catchError, map, of, switchMap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthPartnerService } from '../auth-partner';
import { DelayedSessionStorageService } from '../delayed-session-storage';
import { ContextData, IdentityProvider, PartnerClaim } from './context-data.types';

@Injectable({ providedIn: 'root' })
export class ContextDataService {
  private contextData: ContextData = {} as ContextData;
  private allowedAdditionalParameters = ['target', 'appid'];
  private allowedRoutingParameters = ['REFERENCE_ID', 'SOURCEPROC', 'dmsRequestMode', 'requestGuid', 'contractId'];
  /**
   * Only for speedup. Please use the `identityProvider` getter.
   */
  private cachedIdentityProvider: IdentityProvider = '';

  constructor(
    private httpClient: HttpClient,
    private oidcSecurityService: OidcSecurityService,
    private delayedSessionStorageService: DelayedSessionStorageService,
    private authPartnerService: AuthPartnerService
  ) {}

  get userInfo(): UserInfo {
    return this.data.user;
  }

  get data(): ContextData {
    // contextData could be {} (truthy), but then contextData?.user would be falsely => use that instead
    if (!this.contextData?.user) {
      const sessionData = sessionStorage.getItem('context-data');
      if (sessionData != null) {
        this.contextData = JSON.parse(sessionData);
      }
    }
    return this.contextData;
  }

  private set data(contextData: ContextData) {
    this.contextData = contextData;
    this.delayedSessionStorageService.saveToSessionStorage('context-data', JSON.stringify(contextData));
  }

  set processId(processId: string) {
    this.contextData.processId = processId;
    this.data = this.contextData;
  }

  set contextId(contextId: string | undefined) {
    this.contextData.contextId = contextId;
    this.data = this.contextData;
  }

  set tenant(value: string) {
    this.contextData.tenant = value;
    this.data = this.contextData;
  }

  set appId(value: string) {
    this.contextData.appId = value;
    this.data = this.contextData;
  }

  set contractDatabaseId(contractDatabaseId: number) {
    this.contextData.contractDatabaseId = contractDatabaseId;
    this.data = this.contextData;
  }

  set userLanguage(value: string) {
    this.contextData.languageInformation.userLanguage = value;
    this.data = this.contextData;
  }

  set defaultLanguage(value: string) {
    this.contextData.languageInformation.defaultLanguage = value;
    this.data = this.contextData;
  }

  set roles(value: RoleCommon[]) {
    this.contextData.user.roles = value;
    this.data = this.contextData;
  }

  set phoneNumber(value: string) {
    this.contextData.user.phoneNumber = value;
    this.data = this.contextData;
  }

  set timestamp(timestamp: number) {
    this.contextData.timeStamp = timestamp;
    this.data = this.contextData;
  }

  get identityProvider(): IdentityProvider {
    if (!this.cachedIdentityProvider) {
      const identityProvider = sessionStorage.getItem('identity-provider');
      if (identityProvider == 'grpisc' || identityProvider == 'fspartner' || identityProvider == 'fstoolsisc') {
        this.cachedIdentityProvider = identityProvider;
      }
    }

    return this.cachedIdentityProvider;
  }

  set identityProvider(provider: IdentityProvider) {
    sessionStorage.setItem('identity-provider', provider);
    this.cachedIdentityProvider = provider;
  }

  get additionalParameters(): string | null {
    return sessionStorage.getItem('additional-params');
  }

  set additionalParameters(additionalParameters: string | null) {
    if (!additionalParameters) {
      sessionStorage.removeItem('additional-params');
      return;
    }

    sessionStorage.setItem('additional-params', additionalParameters);
  }

  get routingParameters(): string | null {
    return sessionStorage.getItem('routing-params');
  }

  set routingParameters(routingParameters: string | null) {
    if (!routingParameters) {
      sessionStorage.removeItem('routing-params');
      return;
    }

    sessionStorage.setItem('routing-params', routingParameters);
  }

  resetContextData(): void {
    this.contextData = {} as ContextData;
    this.data = this.contextData;
  }

  initialize(): void {
    this.checkForAdditionalParameter();
    this.checkForRoutingParameters();
    this.checkForIdentityProvider(this.additionalParameters);
  }

  isContextDataRenewRequired(atPayload: PartnerClaim, additionalParameters: string): boolean {
    if (!atPayload || !this.contextData.user || !this.isAuthenticated()) {
      return true;
    }
    switch (this.identityProvider) {
      case 'grpisc':
        return (
          this.authPartnerService.parseGrpAdditionalContext(additionalParameters).get('CONTRACT_KEY') !==
            this.contextData.user.dealer.orgId || atPayload.email !== this.contextData.user.mailAddress
        );
      case 'fspartner':
      case 'fstoolsisc':
        return (
          atPayload.kvpsid !== this.contextData.user.dealer.orgId ||
          atPayload.email !== this.contextData.user.mailAddress
        );
    }
    return true;
  }

  initialLogin(): Observable<void> {
    const additionalParams = this.additionalParameters ?? '';

    // if we have a user and a no additional params (e.g. indicates a grp jump off), we do not need to login.
    if (this.data?.user && !additionalParams && this.identityProvider === 'grpisc') {
      return of();
    }

    // If the user switches the login portal (e.g., from fsPartner to GRP), we also wan't to know the correct identity
    // provider and save it in the session storage.
    this.checkForIdentityProvider(additionalParams);

    return this.oidcSecurityService.getPayloadFromAccessToken(false, this.identityProvider).pipe(
      switchMap((atPayload: PartnerClaim) => {
        if (this.isContextDataRenewRequired(atPayload, additionalParams)) {
          return this.getContextData(additionalParams);
        } else {
          // return of(); is not possible here since it terminates propagation of Observable chain in calling function.
          return of(void 0);
        }
      })
    );
  }

  private getContextData(additionalParams: string): Observable<void> {
    return this.httpClient
      .get<ContextData>(`${environment.isportCoreApiUrl}/api/account/generate-context${additionalParams}`)
      .pipe(
        map((res: ContextData) => {
          // If the data we receive is empty, even though we want to re-login, then things are not working as
          // intended, and we should reject.
          if (!res?.user) {
            throw new Error('Error while generating context. Context data is empty.');
          }

          this.mapRoles(res);

          this.data = res;

          if (!this.isAuthorized()) {
            throw new Error('No valid roles available.');
          }
        }),
        catchError((error: HttpErrorResponse | Error) => {
          this.additionalParameters = null;

          if (error instanceof HttpErrorResponse) {
            // Throw a generic error in case of an http error
            return throwError(() => new Error('Error while generating context'));
          }

          // throw our custom errors that are shown on the forbidden page
          return throwError(() => error);
        })
      );
  }

  /**
   * Get the authentication status for the current identity provider.
   * @returns true if the user is authenticated for the selected identity provider.
   */
  isAuthenticated(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated(this.identityProvider);
  }

  extractContextDataFromRequestHeader(contextDataHeader: string | null) {
    if (!contextDataHeader) {
      return;
    }

    const contextData: ContextData = this.parseContextData(contextDataHeader);

    // Check if newly retrieved context data timestamp was created before locally saved timestamp.
    // This would cause incorrect contextId,... values to be saved to local context
    if (!contextData || (this.data.timeStamp && contextData.timeStamp < this.data.timeStamp)) {
      return;
    }

    this.timestamp = contextData.timeStamp;

    if (contextData.processId) {
      this.processId = contextData.processId;
    }

    if (contextData.contextId) {
      this.contextId = contextData.contextId;
    }

    if (contextData.contractDatabaseId) {
      this.contractDatabaseId = contextData.contractDatabaseId;
    }

    if (contextData.user) {
      this.contextData.user.phoneNumber = contextData.user.phoneNumber;
      this.contextData.user.serviceAdvisorNumber = contextData.user.serviceAdvisorNumber;
      this.data = this.contextData;
    }
  }

  /**
   * Determine based on the passed additional URL parameters the respective identity provider.
   * If no additional parameters are passed, as a first fallback we look into the current URL parameters.
   * As second fallback we look into all identity providers and use the one that is already authenticated.
   * @param additionalParams Additional URL Parameters that are set by some identity providers.
   */
  private checkForIdentityProvider(additionalParams?: string | null): void {
    let whrParam = null;
    if (additionalParams) {
      const decodedParams = decodeURIComponent(additionalParams);
      whrParam = decodedParams?.substring(decodedParams.indexOf('whr=') + 4).split('&')[0];
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      whrParam = urlParams.get('whr');
    }

    switch (whrParam) {
      case 'urn://vwfsag/idp/oidc/grp/isc/':
        this.identityProvider = 'grpisc';
        break;
      case 'urn://vwfsag/idp/oidc/fstools/isc/':
        this.identityProvider = 'fstoolsisc';
        break;
      default:
        if (!this.identityProvider) {
          // In case we do not have an identityProvider, set default provider, which is FsPartner
          this.identityProvider = 'fspartner';
        }
        break;
    }
  }

  /**
   * The user roles are represented as string if sent from the backend, so we parse them to the enum type instead.
   * Will be obsolete when we change to sending the role flags as int instead of string.
   */
  private mapRoles(res: ContextData) {
    for (let i = 0; i < res.user.roles.length; i++) {
      res.user.roles[i] =
        typeof res.user.roles[i] == 'string'
          ? RoleCommon[res.user.roles[i].toString() as keyof typeof RoleCommon]
          : res.user.roles[i];
    }
  }

  /**
   * Determine if the user has any other role than `None`.
   * @returns Authorization status of the user.
   */
  private isAuthorized(): boolean {
    return this.data.user.roles.some((role) => role !== RoleCommon.None);
  }

  /**
   * Called during the initialization of the application.
   * In case we were redirected from GRP or any other login portal, which sent us additional parameters,
   * we save the target parameters for authentication later on.
   */
  private checkForAdditionalParameter(): void {
    const parameters = window.location.search;

    if (this.allowedAdditionalParameters.some((ap) => parameters.toLocaleLowerCase().includes(ap))) {
      this.additionalParameters = parameters;
    }
  }

  private checkForRoutingParameters(): void {
    const parameters = window.location.search;
    if (this.allowedRoutingParameters.some((rp) => parameters.toLocaleLowerCase().includes(rp.toLocaleLowerCase()))) {
      const params = parameters.substring(1).split('&');
      const routingParameters = new URLSearchParams();
      params.forEach((param) => {
        const splitParam = param.split('=');
        if (this.allowedRoutingParameters.some((ap) => ap.toLocaleLowerCase() === splitParam[0].toLocaleLowerCase())) {
          routingParameters.append(splitParam[0], splitParam[1]);
        }
      });

      this.routingParameters = routingParameters.toString();
    }
  }

  private parseContextData(rawContextData: string): ContextData {
    return JSON.parse(base64DecodeUnicode(rawContextData)) as ContextData;
  }
}
