<div class="flex-row justify-start content-center align-start" *transloco="let t">
  <div class="flex-col flex-grow-33">
    <div class="flex-row">
      <strong class="flex flex-grow-50 ui-test--sdd-details-stron-request-id">{{ t('Common_RequestId') }}</strong>
      <span class="flex flex-1">
        <span
          class="dms-label ui-test--sdd-details-span-dms-label"
          *ngIf="statusDetails.sourceApplication === 'DMS'"
          [matTooltip]="t('Common_DmsLabel')"
          matTooltipPosition="above">
          {{ t('Common_DmsLabel') }}
        </span>
        <span
          class="ui-test--sdd-details-span-no-request-id"
          [matTooltip]="t('Common_NoRequestIdAssigned')"
          matTooltipPosition="above"
          *ngIf="!statusDetails.requestIdForCustomer || !statusDetails.requestIdForCustomer.trim(); else requestIdForCustomerExist">
          –
        </span>
        <ng-template #requestIdForCustomerExist>
          <span class="ui-test--sdd-details-span-request-id">{{ statusDetails.requestIdForCustomer }}</span>
        </ng-template>
      </span>
    </div>
    <div class="flex-row mt-5">
      <strong class="flex flex-grow-50 ui-test--sdd-details-strong-request-state-label">
        {{ t('Common_RequestState') }}
      </strong>
      <request-state-label
        [state]="statusDetails.requestState"
        [stateText]="t('Common_' + statusDetails.requestState)"
        stateUsage="StatusDetails"
        class="flex-1 ui-test--sdd-details-request-state-label-request-state"></request-state-label>
    </div>
    <div class="flex-row mt-5" *ngIf="statusDetails.isManagingMember">
      <strong class="flex flex-grow-50 ui-test--sdd-details-strong-dealer-label">
        {{ t('AccountLogOnViewModel_Dealer') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-details-span-dealer">{{ statusDetails.dealerName }}</span>
    </div>
    <!-- Only shown in the print version -->
    <div class="flex-row mt-5 version">
      <strong class="flex flex-grow-50 ui-test--sdd-details-strong-version-label">
        {{ t('StatusDetails_RequestVersion_Title') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-details-span-version">{{ statusDetails.versionIndex }}</span>
    </div>
  </div>

  <div
    class="flex-col flex-grow-33"
    *ngIf="statusDetails.invoiceState === 'Unknown' && statusDetails.creditNoteState === 'Unknown'; else stateIsAvailable">
    <div class="flex-row">
      <strong class="flex flex-grow-50 ui-test--sdd-details-strong-invoice-state-label">
        {{ t('Common_InvoiceState') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-details-span-invoice-state">–</span>
    </div>
    <div class="flex-row mt-5">
      <strong class="flex flex-grow-50 ui-test--sdd-details-strong-workshop-order-number-label">
        {{ t('Common_WorkshopOrderNumber') }}:
      </strong>
      <span class="flex flex-1 ui-test--sdd-details-span-workshop-oder-number">
        {{ statusDetails.workshopOrderNumber }}
      </span>
    </div>
  </div>

  <ng-template #stateIsAvailable>
    <div class="flex-col flex-grow-33" *ngIf="statusDetails.invoiceState !== 'Unknown'">
      <div class="flex-row">
        <strong class="flex flex-grow-50 ui-test--sdd-details-strong-invoice-number-label">
          {{ t('Common_InvoiceNumber') }}
        </strong>
        <span class="flex flex-1 ui-test--sdd-sdd-details-span-invoice-number">{{ statusDetails.invoiceNumber }}</span>
      </div>
      <div class="flex-row mt-5">
        <strong class="flex flex-grow-50 ui-test--sdd-details-strong-invoice-state-label">
          {{ t('Common_InvoiceState') }}
        </strong>
        <invoice-state-label
          class="ui-test--sdd-details-invoice-state-label-invoice-state"
          [invoiceState]="statusDetails.invoiceState"></invoice-state-label>
      </div>
    </div>

    <div class="flex-col flex-grow-33" *ngIf="statusDetails.creditNoteState !== 'Unknown'">
      <div class="flex-row">
        <strong class="flex flex-grow-50 ui-test--sdd-details-strong-credit-note-number-label">
          {{ t('Common_CreditNoteNumber') }}
        </strong>
        <span class="flex flex-1 ui-test--sdd-details-span-credit-note-number">
          {{ statusDetails.creditNoteNumber }}
        </span>
      </div>
      <div class="flex-row mt-5">
        <strong class="flex flex-grow-50 ui-test--sdd-details-strong-credit-note-state-label">
          {{ t('Common_CreditNoteState') }}
        </strong>
        <invoice-state-label
          class="ui-test--sdd-details-invoice-state-label-credit-note-state"
          [invoiceState]="statusDetails.creditNoteState"></invoice-state-label>
      </div>
    </div>
  </ng-template>

  <div
    class="flex-col flex-grow-33"
    *ngIf="statusDetails.invoiceClarificationHistoryData.invoiceClarificationState !== 'Unknown'">
    <div class="flex-row">
      <strong class="flex flex-grow-50 ui-test--sdd-details-strong-invoice-clarification-number-label">
        {{ t('Common_InvoiceClarificationNumber') }}
      </strong>
      <span class="flex flex-1 ui-test--sdd-details-span-invoice-clarification-number">
        {{ statusDetails.invoiceClarificationHistoryData.invoiceClarificationNumber }}
      </span>
    </div>
    <div class="flex-row mt-5">
      <strong class="flex flex-grow-50 ui-test--sdd-details-strong-invoice-clarification-state-label">
        {{ t('Common_InvoiceClarificationState') }}
      </strong>
      <invoice-state-label
        class="ui-test--sdd-details-invoice-state-label-invoice-clarification-state"
        [invoiceState]="statusDetails.invoiceClarificationHistoryData.invoiceClarificationState"></invoice-state-label>
    </div>
  </div>
</div>
