import { Component, OnInit } from '@angular/core';
import { ContextDataService } from '@core/services';
import { ForbiddenComponent } from '../forbidden/forbidden.component';

@Component({
  selector: 'unauthorized',
  templateUrl: '../forbidden/forbidden.component.html'
})
export class UnauthorizedComponent extends ForbiddenComponent implements OnInit {
  public override titleKey: string = 'Error_Unauthorized_Title';
  public override message1Key: string = 'Error_Unauthorized_Message1';
  public override message2Key: string = '';

  constructor(contextDataService: ContextDataService) {
    super(contextDataService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
