import { Component } from '@angular/core';
import { RequestStateStore, UserService } from '@core/services';
import { OverviewComponent } from 'app/components/overview/overview.component';
import { StatusOverviewService } from './status-overview.service';
import { StatusOverviewData, StatusOverviewFilterEntries, StatusOverviewSortKeys } from './status-overview.types';

@Component({
  selector: 'status-overview',
  templateUrl: './status-overview.component.html'
})
export class StatusOverviewComponent extends OverviewComponent {
  public override overviewData: StatusOverviewData[] = [];
  public override initialResult: StatusOverviewData[] = [];
  public sortKeys = StatusOverviewSortKeys;
  protected override sortKey = StatusOverviewSortKeys[0][0];
  protected override viewModeKey: string = 'statusOverviewViewMode';
  protected override filterEntries: string[] = StatusOverviewFilterEntries;

  constructor(
    statusOverviewService: StatusOverviewService,
    userService: UserService,
    requestStateStore: RequestStateStore
  ) {
    super(statusOverviewService, userService, requestStateStore);
  }
}
