import { isJson } from '@core/utils/helper-functions';
import { FluentValidationResultSimple, ModelStateError } from './validation-reader.types';

export class ValidationReader {
  readonly values: string[] = [];
  readonly keys: string[] = [];

  constructor(private validationResult: any) {
    if (this.validationResult) {
      // Use global message string if ModelState is null. Usually this global message
      // comes with default bad request or from Fluent Validation without ModelState information
      // On other side, ModelState is generated by ASP.NET Core default validation mechanism
      if (this.validationResult.Message) {
        if (isJson(this.validationResult.Message)) {
          // If message property contains JSON then it should be Fluent Validation result
          // In that case, parse Fluent Validation object and use ErrorMessage and PropertyName
          // from this string array
          const fluentValidationResult = JSON.parse(this.validationResult.Message) as FluentValidationResultSimple;
          fluentValidationResult.Errors.forEach((errorItem) => {
            this.keys.push(errorItem.PropertyName);
            this.values.push(errorItem.ErrorMessage);
          });
        } else if (this.validationResult.Message) {
          this.values.push(this.validationResult.Message);
        }
      } else if ((this.validationResult as FluentValidationResultSimple) != null && this.validationResult.Errors) {
        const fluentValidationResult = this.validationResult as FluentValidationResultSimple;
        fluentValidationResult.Errors.forEach((errorItem) => {
          this.keys.push(errorItem.PropertyName);
          this.values.push(errorItem.ErrorMessage);
        });
      } else {
        // Iterate through ModelState object and find dynamic array names related to
        // validation properties and their messages
        for (const key in this.validationResult) {
          const validationProperty = this.validationResult[key];

          // Skip $type property as part of ModelState
          if (key === '$type' || !validationProperty.errors || validationProperty.errors.length == 0) {
            continue;
          }

          this.keys.push(key);

          // One property can have one or more errors related, because of that it
          // is used another foreach to iterate through that string array
          validationProperty.errors.forEach((errorItem: ModelStateError) => {
            this.values.push(errorItem.errorMessage);
          });
        }
      }
    }
  }
}
