<div class="spinner" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
  <ng-container *transloco="let t">
    <page-header [title]="t('PageTitle_Dashboard')"></page-header>
    <sending-restricted></sending-restricted>
    <validation-summary [errorList]="errorList"></validation-summary>

    <div class="flex-col justify-center content-between">
      <div class="flex flex-grow-100">
        <!-- DASHBOARD SIDEBAR -->
        <div class="flex-grow-25">
          <!-- CONTRACTS -->
          <contract-panel
            *ngIf="dashboardData.contracts.length > 0"
            [dashboardData]="dashboardData"
            (selectedContract)="onContractSelection($event)"></contract-panel>

          <!-- VEHICLE CONTRACTS -->
          <section *ngIf="carportContracts && carportContracts.length > 0">
            <div *ngFor="let carportContract of carportContracts; let i = index">
              <h3 [class]="'ui-test--dashboard-header-carport-contract-label-' + i">{{ carportContract.label }}</h3>
              <pre [class]="'ui-test--dashboard-pre-carport-contract-' + i">{{ carportContract.id }}</pre>
            </div>
          </section>

          <!-- Some progress for car carport vehicles based on isWaitingForCarportContracts property -->

          <p
            class="ui-test--dashboard-paragraph-no-contracts-found"
            *ngIf="!isWaitingForCarportContracts && dashboardData.contracts.length === 0 && carportContracts.length === 0">
            No contracts found.
          </p>

          <a
            mat-stroked-button
            color="primary"
            routerLink="/vehicle-selection"
            class="w-full ui-test--dashboard-link-back-to-vehicle-selection"
            *ngIf="displayBackToVehicleSelection">
            <mat-icon class="ui-test--dashboard-mat-icon-arrow-back" [style.font-size.px]="20">arrow_back</mat-icon>
            {{ t('Dashboard_Index_BackToVehicleSelection') }}
          </a>
        </div>

        <!-- CONTRACT DETAILS -->
        <contract-details
          class="flex-grow-50 my-0 mx-20"
          [dashboardData]="dashboardData"
          [contract]="contract"></contract-details>

        <!-- OFFERS -->
        <div
          class="flex-grow-25 max-height"
          *ngIf="configurationService.enableOfferManagement && dashboardData.isAftersalesUser">
          <offer-panel [vin]="contract.vehicle.vehicleIdentificationNumber"></offer-panel>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
