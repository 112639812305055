import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UserSetting } from './user.types';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(private httpClient: HttpClient) {}

  load(tenant: string, serviceAdvisorId: string): Observable<UserSetting[]> {
    return this.httpClient.get<UserSetting[]>(
      `${environment.isportCoreApiUrl}/api/settings/user?tenantName=${tenant}&serviceAdvisorId=${serviceAdvisorId}`
    );
  }

  setStatusOverviewViewMode(value: string | undefined): Observable<void> {
    return this.httpClient.patch<void>(
      `${environment.isportCoreApiUrl}/api/statusoverview/viewmode`,
      value == 'compact'
    );
  }

  setOpenRequestViewMode(value: string): Observable<void> {
    return this.httpClient.patch<void>(`${environment.isportCoreApiUrl}/api/openrequest/viewmode`, value == 'compact');
  }

  setAftersalesOverviewViewMode(value: string): Observable<void> {
    return this.httpClient.patch<void>(
      `${environment.isportCoreApiUrl}/api/aftersalesoverview/viewmode`,
      value == 'compact'
    );
  }
}
