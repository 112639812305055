<ng-container *transloco="let t">
  <h2 class="ui-test--offer-details-dialog-header-search-criteria" id="potential-offer-details" mat-dialog-title>
    {{ offer.label }}
  </h2>
  <mat-dialog-content>
    <validation-summary [errorList]="errorList"></validation-summary>

    <mat-radio-group [(ngModel)]="selectedVariantSalesProductCode">
      <table
        mat-table
        [dataSource]="offer.variants"
        [multiTemplateDataRows]="true"
        aria-describedby="potential-offer-details">
        <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.columnDef">
          <th
            mat-header-cell
            class="cell-header"
            *matHeaderCellDef
            [class]="'ui-test--offer-details-dialog-th-header-' + i">
            {{ column.header }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let rowIndex = dataIndex"
            [class]="'ui-test--offer-details-dialog-td-cell-' + i + '-row-' + rowIndex">
            {{ column.cell(row) }}
          </td>
        </ng-container>

        <!-- Column definition for the title rows above each data row -->
        <ng-container matColumnDef="title">
          <td
            mat-cell
            *matCellDef="let row; let i = dataIndex"
            class="title-row"
            [class]="'ui-test--offer-details-dialog-td-title-' + i">
            <mat-radio-button
              [value]="row.configuration.salesProductCode"
              [class]="'ui-test--offer-details-dialog-mat-radio-button-title-' + i">
              <em [class]="'ui-test--offer-details-dialog-em-title-' + i">{{ getRowTitle(row) }}</em>
              <a
                *ngIf="offer.productDescriptionLink"
                [class]="'ui-test--offer-details-dialog-link-' + i"
                [href]="offer.productDescriptionLink"
                target="_blank"
                rel="noopener">
                <mat-icon
                  color="primary"
                  class="inline-icon"
                  [class]="'ui-test--offer-details-dialog-mat-icon-title-' + i">
                  info_outline
                </mat-icon>
              </a>
            </mat-radio-button>
          </td>
        </ng-container>
        <tr class="ui-test--offer-details-dialog-tr-title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <!-- Double render the data. Once for the title row, once for the data row -->
        <tr mat-row *matRowDef="let row; columns: ['title'];"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-radio-group>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="ui-test--offer-details-dialog-button-close" mat-stroked-button mat-dialog-close cdkFocusInitial>
      {{ t('Common_Close') }}
    </button>
    <button
      class="ui-test--offer-details-dialog-button-application"
      mat-raised-button
      color="primary"
      [class.button-spinner]="redirectionLoading"
      [disabled]="!selectedVariantSalesProductCode || redirectionLoading"
      (click)="applyOffer(offer)">
      <mat-icon class="ui-test--offer-details-dialog-mat-icon-application">add_shopping_cart</mat-icon>
      {{ t('OfferPanelComponents_Button_Application') }}
    </button>
  </mat-dialog-actions>
</ng-container>
