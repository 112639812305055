import { HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextDataService, CorrelationIdService } from '@core/services';
import { FALLBACK_LANGUAGE } from '@core/transloco';
import { base64EncodeUnicode } from '@core/utils';

export const CONTENT_TYPE = new HttpContextToken(() => 'application/json');
export const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  constructor(private contextDataService: ContextDataService, private correlationIdService: CorrelationIdService) {}
  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    let modifiedReq = req;

    if (!req.headers.has(INTERCEPTOR_SKIP_HEADER)) {
      modifiedReq = modifiedReq.clone({
        headers: modifiedReq.headers.append('Content-Type', modifiedReq.context.get(CONTENT_TYPE))
      });
    } else {
      modifiedReq.headers.delete(INTERCEPTOR_SKIP_HEADER);
    }

    // Update context data timestamp to be able to track chronological sequence of request/response from FE API
    const contextDataClone = structuredClone(this.contextDataService.data);
    contextDataClone.timeStamp = Date.now();

    // Append these properties to every request
    modifiedReq = modifiedReq.clone({
      setHeaders: {
        'X-ISPORT-REQUEST-CONTEXT-DATA': base64EncodeUnicode(JSON.stringify(contextDataClone)),
        'X-CORRELATION-ID': this.correlationIdService.correlationId,
        'Accept-Language': this.getAcceptLanguage()
      }
    });
    return next.handle(modifiedReq);
  }

  private getAcceptLanguage() {
    const userLanguage = this.contextDataService?.data?.languageInformation?.userLanguage;

    if (userLanguage && userLanguage.trim().length > 0) {
      return userLanguage;
    }

    const defaultLanguage = this.contextDataService?.data?.languageInformation?.defaultLanguage;

    if (defaultLanguage) {
      return defaultLanguage;
    }

    return FALLBACK_LANGUAGE;
  }
}
