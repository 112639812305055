<div *transloco="let t">
  <mat-card *ngIf="showAnnouncement">
    <mat-card-header class="flex-col-reverse">
      <mat-card-title class="ui-test--announcement-mat-card-title-title">
        {{t('Common_AnnouncementHeader')}}
      </mat-card-title>
      <a
        mat-icon-button
        class="flex-row justify-end content-start ui-test--announcement-link-button"
        (click)="closeAnnouncement()">
        <mat-icon class="ui-test--announcement-mat-icon-button">close</mat-icon>
      </a>
    </mat-card-header>
    <mat-card-content
      class="ui-test--announcement-mat-card-content-text"
      [innerHTML]="announcementData.message | safe: 'html'"></mat-card-content>
  </mat-card>
</div>
