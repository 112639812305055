<table aria-hidden="true" *transloco="let t">
  <tbody>
    <tr *ngFor="let property of vehicle?.sortedDisplayProperties">
      <td [class]="'ui-test--contract-information-td-' + property.resourceKey + 'Label'">{{ t(property.resourceKey) }}</td>
      <td [class]="[property.propertyName | className, 'ui-test--contract-information-td-' + property.resourceKey]">
        <ng-container *ngIf="property.dataType === 'DateTime'">
          {{ property.value | dateFormat:'DATE_SHORT':false }}
        </ng-container>
        <ng-container *ngIf="property.dataType === 'String'">{{ property.value }}</ng-container>
      </td>
    </tr>
  </tbody>
</table>
