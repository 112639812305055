<ng-container *transloco="let t">
  <h2 mat-dialog-title class="ui-test--request-mileage-dialog-header-title">
    {{ t('Request_UpdateMileage_HeaderTitle') }}
  </h2>
  <mat-dialog-content class="mat-typography">
    <mat-card class="message-state warning">
      <mat-card-content class="ui-test--request-mileage-dialog-mat-card-content-information">
        {{ t('Request_UpdateMileage_HeaderText') }}
      </mat-card-content>
    </mat-card>

    <mat-form-field class="mt-20">
      <mat-label class="ui-test--request-mileage-dialog-mat-label-field-text">
        {{ t('Request_UpdateMileage_FieldText') }}
      </mat-label>
      <input
        class="ui-test--request-mileage-dialog-input-mileage"
        matInput
        [formControl]="mileage"
        type="number"
        cdkFocusInitial />
      <mat-error></mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="ui-test--request-mileage-dialog-button-close" mat-stroked-button [mat-dialog-close]="true">
      {{ t('Common_Close') }}
    </button>
    <button
      class="ui-test--request-mileage-dialog-button-confirm"
      mat-raised-button
      color="primary"
      (click)="confirm()"
      [disabled]="!mileage.valid || confirmLoading"
      [class.button-spinner]="confirmLoading">
      <mat-icon class="ui-test--request-mileage-dialog-mat-icon-confirm">search</mat-icon>
      {{ t('Common_Confirm') }}
    </button>
  </mat-dialog-actions>
</ng-container>
