import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'yesNoUnknown'
})
export class YesNoUnknownPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(value: boolean | string | null | undefined): string {
    if (value === null || value === undefined) {
      return this.translocoService.translate('Common_Unknown');
    }

    if (typeof value === 'string') {
      if (value == '') {
        return this.translocoService.translate('Common_Unknown');
      }

      value = value.toLowerCase();
      if (value == 'true' || value == 'yes') {
        return this.translocoService.translate('Common_Yes');
      }
      if (value == 'false' || value == 'no') {
        return this.translocoService.translate('Common_No');
      }

      return this.translocoService.translate('Common_Unknown');
    }

    return this.translocoService.translate(value ? 'Common_Yes' : 'Common_No');
  }
}
