import { Injectable } from '@angular/core';

/**
 * Mostly when we update the context data, we update the session storage multiple times.
 * When the browser is very busy, this can be a very slow process and produce race conditions.
 */
@Injectable({ providedIn: 'root' })
export class DelayedSessionStorageService {
  private timeoutIds: Map<string, number> = new Map<string, number>();

  saveToSessionStorage(key: string, value: string): void {
    const timeoutId = this.timeoutIds.get(key);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    this.timeoutIds.set(
      key,
      window.setTimeout(() => {
        this.timeoutIds.delete(key);
        sessionStorage.setItem(key, value);
      }, 3000)
    );
  }
}
