import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '@core/modules';

import { PipesModule } from '@core/pipes';
import { ValidationSummaryComponent } from './validation-summary.component';

@NgModule({
  imports: [MatCardModule, SharedModule, PipesModule],
  exports: [ValidationSummaryComponent],
  declarations: [ValidationSummaryComponent],
  providers: []
})
export class ValidationSummaryModule {}
