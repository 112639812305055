import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlertService, ConfigurationService } from '@core/services';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { TranslocoService } from '@ngneat/transloco';
import { ContactDataComponent } from 'app/components/contact-data/contact-data.component';
import { FileUploadComponent } from 'app/components/file-upload';
import { ProcessBarComponent } from '../request/shared/process-bar/process-bar.component';
import { CallMeBackService } from './call-me-back.service';
import { CallMeBackRequest } from './call-me-back.types';
import { VehicleInformationComponent } from './vehicle-information/vehicle-information.component';

@Component({
  selector: 'call-me-back',
  templateUrl: './call-me-back.component.html',
  styleUrls: ['./call-me-back.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CallMeBackComponent implements OnInit {
  @ViewChild(ProcessBarComponent) processBarComponent!: ProcessBarComponent;
  @ViewChild(VehicleInformationComponent) vehicleInformationComponent!: VehicleInformationComponent;
  @ViewChild(ContactDataComponent) contactDataComponent!: ContactDataComponent;
  @ViewChild(FileUploadComponent) fileUploadComponent!: FileUploadComponent;

  constructor(
    private configurationService: ConfigurationService,
    private callMeBackService: CallMeBackService,
    private alertService: AlertService,
    private translocoService: TranslocoService
  ) {}

  showSecondaryEmail = this.configurationService.showSecondaryMailAddressInCallMeBack;
  errorList: string[] = [];
  loading = true;
  sending = false;
  request!: CallMeBackRequest;

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.callMeBackService.getData().subscribe({
      next: (res: CallMeBackRequest) => {
        this.request = res;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get selectedStep(): string {
    return this.processBarComponent?.steps?.find((step) => step.isActive)?.key ?? '';
  }

  get hasInvalidValues(): boolean {
    return (
      this.contactDataComponent.contactDataForm.invalid ||
      this.vehicleInformationComponent.vehicleInformationForm.invalid
    );
  }

  setContactData(): void {
    const phoneNumber = this.contactDataComponent.contactDataForm.get('phoneNumber')?.value;
    const secondaryEmail = this.contactDataComponent.contactDataForm.get('secondaryEmail')?.value;

    // If contact data is valid save the values into global object
    if (phoneNumber) {
      this.request.contactData.phoneNumber = phoneNumber;
    }
    if (secondaryEmail) {
      this.request.contactData.secondaryEmail = secondaryEmail;
    }
  }

  setVehicleInfo(): void {
    this.request.vehicleInformation = {
      actualMileage: this.vehicleInformationComponent.vehicleInformationForm.get('actualMileage')?.value,
      licensePlateNumber: this.vehicleInformationComponent.vehicleInformationForm.get('licensePlateNumber')?.value,
      vehicleIdentificationNumber:
        this.vehicleInformationComponent.vehicleInformationForm.get('vehicleIdentificationNumber')?.value
    };
  }

  send(): void {
    this.errorList = [];

    this.contactDataComponent?.contactDataForm.markAllAsTouched();
    const formData = this.contactDataComponent?.contactDataForm.value;

    if (this.hasInvalidValues || !this.contactDataComponent?.contactDataForm.valid || !formData) {
      return;
    }

    // If contact data is valid save the values into request
    this.request.contactData.phoneNumber = formData.phoneNumber ?? this.request.contactData.phoneNumber;
    if (formData.secondaryEmail) {
      this.request.contactData.secondaryEmail = formData.secondaryEmail;
    }

    if (this.fileUploadComponent.hasNotUploadedFiles) {
      this.alertService.open({
        message: this.translocoService.translate('CallMeBack_CollectData_FileUpload_UncommittedChanges_Warning')
      });
      return;
    }

    this.sending = true;
    this.setVehicleInfo();
    this.setContactData();
    this.callMeBackService.send(this.request).subscribe({
      next: () => {
        this.processBarComponent.nextStep();
        this.sending = false;
      },
      error: (err: HttpErrorResponse) => {
        this.sending = false;
        const validationReader = new ValidationReader(err.error);
        this.errorList = validationReader.values;
      }
    });
  }
}
