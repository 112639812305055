import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterCriteria, FilterRequest } from 'app/components/filter/filter.types';
import { OverviewService } from 'app/components/overview/overview.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { OpenProcessesData } from './open-processes.types';

@Injectable({ providedIn: 'root' })
export class OpenProcessesService extends OverviewService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getData(request: FilterRequest): Observable<OpenProcessesData[]> {
    const url = `${environment.isportCoreApiUrl}/api/openrequest/overview?`;
    return this.httpClient.get<OpenProcessesData[]>(url, {
      params: request.toHttpParams()
    });
  }

  getFilterCriteria(): Observable<FilterCriteria> {
    const url = `${environment.isportCoreApiUrl}/api/openrequest/filtercriteria?`;
    return this.httpClient.get<FilterCriteria>(url);
  }
}
