import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventService } from '@core/services';
import { environment } from 'environments/environment';
import { Message, Messages } from './inbox.types';

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  private _unreadMessageCount = -1;

  constructor(private httpClient: HttpClient, private eventService: EventService) {
    this.subscribeToSignalrNotifications();
  }

  public get unreadMessageCount() {
    if (this._unreadMessageCount === -1) {
      this.updateUnreadMessageCount();
      // temporarily set it to `0` to prevent further API calls
      this._unreadMessageCount = 0;
    }
    return this._unreadMessageCount;
  }

  public set unreadMessageCount(value: number) {
    this._unreadMessageCount = value;
  }

  public updateUnreadMessageCount(): void {
    this.getUnreadMessageCount().subscribe((count: number) => (this.unreadMessageCount = count));
  }

  public getUnreadMessageCount() {
    return this.httpClient.get<number>(`${environment.isportCoreApiUrl}/api/inbox/unreadmessagecount`);
  }

  public getMessages() {
    return this.httpClient.get<Messages>(`${environment.isportCoreApiUrl}/api/inbox/messages`);
  }

  public setMessageRead(messageId: number) {
    return this.httpClient.post<Message>(`${environment.isportCoreApiUrl}/api/inbox/messageread`, { id: messageId });
  }

  public setAllMessageRead() {
    return this.httpClient.post(`${environment.isportCoreApiUrl}/api/inbox/allmessagesread`, {});
  }

  public dismissMessage(messageId: number) {
    return this.httpClient.request<number>('delete', `${environment.isportCoreApiUrl}/api/inbox/dismissMessage`, {
      body: {
        id: messageId
      }
    });
  }

  private subscribeToSignalrNotifications() {
    this.eventService.setHubConnectionChangeListener('NotificationUpdate', () => {
      this.updateUnreadMessageCount();
    });
  }
}
