import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ErrorMail } from '../error-message.types';

@Injectable({ providedIn: 'root' })
export class ErrorContactFormService {
  constructor(private _httpClient: HttpClient) {}

  sendEmail(errorMail: ErrorMail) {
    return this._httpClient.post<boolean>(`${environment.isportCoreApiUrl}/api/error/sendMail`, errorMail);
  }
}
