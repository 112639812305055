import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestProcessStateCommon } from 'app/components/request-state-label/request-state-label.types';
import { StatusDetailsDialogService } from 'app/pages/status-overview/status-details-dialog/status-details-dialog.service';
import { RequestService } from '../request.service';
import { RequestConfirmation } from './confirmation.types';

@Component({
  selector: 'request-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Input() requestGuid!: string;
  @Input() mode!: RequestProcessStateCommon;

  requestConfirmation!: RequestConfirmation;
  informationTextKey!: string;

  /**
   * Constructor
   */
  constructor(
    private requestService: RequestService,
    private statusDetailsDialogService: StatusDetailsDialogService,
    private router: Router
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.loadConfirmationData();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private loadConfirmationData() {
    this.requestService.getConfirmationData(this.requestGuid, this.mode).subscribe({
      next: (res: RequestConfirmation) => {
        this.requestConfirmation = res;

        this.setupInformationTextKey();
      }
    });
  }

  private setupInformationTextKey() {
    if (this.requestConfirmation.mode === 'Transmitted') {
      this.informationTextKey = 'OnlineInvoice_SuccessMessage_Transmission_Text';
    } else {
      this.informationTextKey = 'Request_SuccessMessage_Transmission' + this.requestConfirmation.mode + '_Text';
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  openStatusDetailsDialog(event: Event) {
    event.stopPropagation();

    this.statusDetailsDialogService.openDialog(this.requestGuid, this.requestConfirmation.productType, '');
  }

  newMobilityRequest() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/request/create'], {
        queryParams: {
          productType: 'Mobility',
          contractDatabaseId: this.requestConfirmation.contractDatabaseId,
          referencedRequestGuid: this.requestConfirmation.versionId
        }
      })
    );
  }
}
