import { Pipe, PipeTransform } from '@angular/core';
import { ActivityTypeIdentifierCommon } from 'app/components/positions';

/**
 * Converts item type key to proper icon
 */
@Pipe({
  name: 'positionIcon'
})
export class PositionIconPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param itemType An item type key that is going to be translated to icon
   */
  transform(itemType: string): string {
    switch (itemType as ActivityTypeIdentifierCommon) {
      case 'MobilityPosition':
        return 'transfer_within_a_station';
      case 'MobilitySparePart':
      case 'TireSparePart':
      case 'WarrantySparePart':
      case 'SparePart':
        return 'settings';
      case 'TirePosition':
        return 'album';
      case 'ServicePosition':
      case 'TireServicePosition':
      case 'WarrantyPosition':
      case 'WarrantyDiagnosticPosition':
      case 'MaintenancePackagePosition':
      case 'WorkPosition':
        return 'build';
      default:
        return '';
    }
  }
}
