import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mileage-dialog',
  templateUrl: './mileage-dialog.component.html'
})
export class MileageDialogComponent {
  @Output() mileageUpdate: EventEmitter<number> = new EventEmitter<number>();

  mileage: FormControl = new FormControl<string | null>(null, [
    Validators.required,
    Validators.min(1),
    Validators.max(9999999)
  ]);
  confirmLoading = false;

  constructor(private dialogRef: MatDialogRef<MileageDialogComponent>) {}

  confirm() {
    if (!this.mileage.valid) {
      return;
    }

    this.confirmLoading = true;
    this.mileageUpdate.emit(this.mileage.value);

    this.confirmLoading = false;
    this.dialogRef.close();
  }
}
