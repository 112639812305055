import { Component, Input } from '@angular/core';
import { UserService } from '@core/services';
import { PaymentChoose } from './payment-choose-dialog.types';

@Component({
  selector: 'payment-choose-dialog',
  templateUrl: './payment-choose-dialog.component.html',
  styleUrls: ['./payment-choose-dialog.component.scss']
})
export class PaymentChooseDialogComponent {
  @Input() paymentChoose!: PaymentChoose;

  constructor(public userService: UserService) {}
}
