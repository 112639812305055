import { Injectable } from '@angular/core';
import { LuxonDateAdapter } from '@matheo/datepicker/luxon';
import { DateTime } from 'luxon';

/** Override LuxonDateAdapter class to fix an issue */
@Injectable()
export class LuxonDateAdapterCustom extends LuxonDateAdapter {
  override clone(date: DateTime): DateTime {
    /**
     * This method produces an issue in base library with including config unnecessary
     * So, instead return `DateTime.fromObject(date.toObject({ includeConfig: true }))`.
     * There is code below, and the responsibility of this method is to create new date
     * object based on existing one
     */
    return DateTime.fromObject(date.toObject());
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }
}
