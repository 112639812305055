import { Component, Input } from '@angular/core';
import { Contract, Dashboard } from '../../dashboard.types';

@Component({
  selector: 'print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent {
  @Input() contract!: Contract;
  @Input() dashboardData!: Dashboard;
}
