import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ConfigurationService, LocaleFormatService, RequestStateStore, TenantSettingsService } from '@core/services';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { sort } from 'app/components/sort/sort.helper';
import { UnsavedChangesStateStore } from 'app/pages/request';
import { RequestService } from 'app/pages/request/request.service';
import { RentalReason } from 'app/pages/request/request.types';
import { GenericPositionComponent } from '../generic-position/generic-position.component';

@Component({
  selector: 'mobility-position',
  templateUrl: './mobility-position.component.html'
})
export class MobilityPositionComponent extends GenericPositionComponent implements OnInit {
  rentalReasons: RentalReason[] = [];

  /**
   * Constructor
   */
  constructor(
    formBuilder: UntypedFormBuilder,
    positionConfigurationService: PositionConfigurationService,
    requestService: RequestService,
    requestStore: RequestStateStore,
    unsavedChangesStateStore: UnsavedChangesStateStore,
    localeFormatService: LocaleFormatService,
    configurationService: ConfigurationService,
    tenantSettingsService: TenantSettingsService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(
      formBuilder,
      positionConfigurationService,
      9,
      requestStore,
      unsavedChangesStateStore,
      requestService,
      localeFormatService,
      configurationService,
      tenantSettingsService,
      changeDetector,
      elementRef
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------
  get availableRentalReasons() {
    return this.requestContent.availableRentalReasons;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  override ngOnInit(): void {
    super.ngOnInit();
    this.sortRentalReasons();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Gets the sorted rental reasons depending on the actual sorting configuration.
   */
  private sortRentalReasons(): void {
    const sortedRentalReasons = this.availableRentalReasons;

    this.rentalReasons = sort(
      sortedRentalReasons,
      'description',
      this.requestContent.isRentalReasonsSortingAscending ? 'asc' : 'desc'
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  hasNoActivityTypeForRentalReason(): boolean {
    const rrId = this.positionData.rentalReasonId;
    if (rrId) {
      const selectedRentalReason = this.availableRentalReasons.find((rentalReason) => rentalReason.id === rrId);

      if (selectedRentalReason) {
        const match = this.activityTypesWithAssignedClass.some((activitType) => {
          return (
            activitType.activityTypeCategory === 'Mobility' &&
            selectedRentalReason.activityTypes.find((rrid) => activitType.name === rrid)
          );
        });

        return !match;
      }
    }

    return true;
  }

  override addSparePart(): void {
    super.addSparePart('MobilitySparePart');
  }

  /**
   * Based on rental reason change (if property is shown on the UI), do some additional processing
   * The rental reason is related directly to the mobility so no reason to be in generic component
   */
  onRentalReasonIdChange(rrId: number): void {
    if (rrId) {
      const selectedRentalReason = this.availableRentalReasons.find((rr) => rr.id === rrId);

      if (selectedRentalReason) {
        const theItem = this.activityTypesWithAssignedClass.find((at) => {
          return selectedRentalReason.activityTypes.find(
            (rrat) => at.name === rrat && at.activityTypeCategory === 'CarRental'
          );
        });
        this.editActivityTypeKeyWithClass(theItem ? theItem.id : 0);

        // Select activity types and units based on new selection
        this.selectActivityTypes();
        this.selectUnits();
      }
    }
  }
}
