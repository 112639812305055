import { Component, Input } from '@angular/core';
import { ResponseNote } from '../../status-details-dialog.types';

@Component({
  selector: 'status-details-notes',
  templateUrl: './status-details-notes.component.html',
  styleUrls: []
})
export class StatusDetailsNotesComponent {
  @Input() optionalItemsClass: string = '';
  @Input() responseNotes!: ResponseNote[];
  @Input() annotationDate: string = '';
  @Input() annotation: string = '';
  @Input() textModuleText: string = '';
  @Input() fullWidth: boolean = true;
}
