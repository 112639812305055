import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DropdownOption } from 'app/components/dropdown-with-search/dropdown-with-search.types';
import { DashboardContractDetailsDialogService } from 'app/pages/dashboard/contract-details-dialog/contract-details-dialog.service';
import { ActivityType, RequestContent, RequestPosition } from 'app/pages/request/request.types';
import { TireCommon, TireSelection } from '../tire-position.types';
import { TireSearchDialogService } from './tire-search-dialog.service';
import { TireFilter, TireFilterResult } from './tire-search-dialog.types';

@Component({
  selector: 'tire-search-dialog',
  templateUrl: './tire-search-dialog.component.html',
  styleUrls: ['./tire-search-dialog.component.scss']
})
export class TireSearchDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('spinner') spinner!: ElementRef;

  @Input() requestContent!: RequestContent;
  @Input() positionData!: RequestPosition;
  @Input() availableActivityTypes: ActivityType[] = [];
  @Input() specificAvailableActivityTypes: ActivityType[] = [];
  @Input() parentActivityType!: ActivityType;
  @Input() selectedActivityType!: ActivityType | undefined;

  // Form Controls
  public productNumberControl = this.formBuilder.control({ value: '', disabled: true });
  // two way bindings for the dropdowns
  public tireType?: string;
  public tireSize?: string;
  public tireLoadIndex?: string;
  public tireSpeedIndex?: string;
  public tireBrand?: string;
  public tire?: string;
  // Options for the dropdowns
  public tireTypes: DropdownOption[] = [];
  public tireSizes: DropdownOption[] = [];
  public tireLoadIndexes: DropdownOption[] = [];
  public tireSpeedIndexes: DropdownOption[] = [];
  public tireBrands: DropdownOption[] = [];
  public tires: DropdownOption[] = [];
  // Disable toggles for the dropdowns
  public tireSizeDisabled: boolean = true;
  public tireLoadIndexDisabled: boolean = true;
  public tireSpeedIndexDisabled: boolean = true;
  public tireBrandDisabled: boolean = true;
  public tireDisabled: boolean = true;

  public tireSearchForm = this.formBuilder.group({
    productNumber: this.productNumberControl
  });

  public Validators = Validators;

  private contractTireSizes: string[] = [];
  private companyCode = '';
  private loadedTires: TireCommon[] = [];
  private selectedTire: TireSelection | null = null;

  constructor(
    private dialogRef: MatDialogRef<TireSearchDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private tireSearchDialogService: TireSearchDialogService,
    private dashboardContractDetailsDialogService: DashboardContractDetailsDialogService
  ) {
    dialogRef.disableClose = true;
    dialogRef.updateSize('450px');
  }

  ngOnInit() {
    // Set company code before calling all other methods where companyCode is used
    this.companyCode = this.requestContent.requestProcess?.generalInformation?.companyCode ?? '';

    this.loadContractTireSizes();
    this.loadTireTypesFilter();
  }

  ngAfterViewInit(): void {
    this.hideLoading();
  }

  /**
   * Event that fires when a tire type was selected.
   */
  selectedTireType(tireType: string) {
    this.resetFormControls();
    // excluded this from the reset function, because the reset function is used for the tire size selection too.
    this.tireSize = '';
    this.tireSizeDisabled = false;

    if (!tireType) {
      return;
    }

    this.loadTireSizeFilter(tireType);
  }

  /**
   * Event that fires when a tire size was selected.
   */
  selectedTireSize(tireSize: string) {
    this.resetFormControls();

    if (!tireSize) {
      return;
    }

    this.loadTires(true);
  }

  /**
   * Fired on selection for the LoadIndex, SpeedIndex and Brand Auto-completes.
   */
  selectedFilterValue(selected: string) {
    if (!selected) {
      this.hideLoading();
      return;
    }

    this.loadTires(false);
  }

  /**
   * Fired when a tire was selected.
   */
  selectedTireChanged(tire: string) {
    this.productNumberControl.setValue('');
    this.selectedTire = null;
    if (!tire) {
      return;
    }

    // find the correct tire
    const foundTire = this.loadedTires.find((loadedTire) => loadedTire.id == tire);
    if (!foundTire) {
      return;
    }

    const epn = foundTire.externalProductNumbers.find((externalProductNumber) => externalProductNumber.number);

    if (!epn) {
      return;
    }

    this.productNumberControl.setValue(epn.number);
    this.selectedTire = {
      externalProductNumber: epn.number,
      externalProductNumberSource: epn.source
    } as TireSelection;
  }

  /**
   * Fired when the user clicks on the confirm button.
   */
  confirm() {
    this.dialogRef.close(this.selectedTire);
  }

  showDashboardDialog() {
    this.dashboardContractDetailsDialogService.openDialog();
  }

  /**
   * This validator checks if the selected Tire Size matches the contract tire sizes that are filtered by selected tire type.
   */
  matchingContractTireSizesValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || control.value == '') {
        return null;
      }
      // split the selected tire size into its parts (format: {tireWidth}/{tireHeight}{tireBuild}{tireRadius} - for example 185/70R14
      // then create a regex based on that, where everything except for the tire build must match (tire build can be R, C, D.. though R is normal)
      // this is necessary because even though we only have "R"-Tires, Leasis sometimes uses "C" in the filtered contract sizes, and we need to able to match that.
      const tireSizeMatchRegex = this.getTireSizeRegex(control.value);
      if (!tireSizeMatchRegex) {
        return null;
      }

      const filteredContractTireSizes = this.getFilteredContractTireSizes();
      return filteredContractTireSizes.length !== 0 &&
        filteredContractTireSizes.filter((tireSize) => tireSizeMatchRegex.test(tireSize)).length == 0
        ? { tireSize: '' }
        : null;
    };
  }

  private getTireSizeRegex(tireSize: string): RegExp | null {
    if (!tireSize) {
      tireSize = '';
    }
    const tireSizeRegex = /(\d{2,3})\/(\d{1,2})\w(\d{1,2})$/;
    const matches = tireSizeRegex.exec(tireSize);
    return matches ? new RegExp(`${matches[1]}/${matches[2]}\\w${matches[3]}$`) : null;
  }

  private resetFormControls() {
    this.tireLoadIndex = '';
    this.tireLoadIndexDisabled = true;

    this.tireSpeedIndex = '';
    this.tireSpeedIndexDisabled = true;

    this.tireBrand = '';
    this.tireBrandDisabled = true;

    this.tire = '';
    this.tireDisabled = true;

    this.productNumberControl.setValue('');
    this.selectedTire = null;
  }

  private loadTireTypesFilter() {
    const availableActivityTypesForTires = this.availableActivityTypes.filter(
      (activityType) =>
        activityType.itemType === 'TirePosition' &&
        activityType.damageTypes &&
        activityType.damageTypes.length > 0 &&
        activityType.damageTypes.some((damageType) => damageType.id === this.positionData.damageTypeId)
    );

    if (availableActivityTypesForTires.length === 0) {
      this.loadTireTypesFilterDefault();
      return;
    }

    this.tireTypes = availableActivityTypesForTires.map((activityType) => {
      return { value: activityType.name, text: activityType.description } as DropdownOption;
    });

    this.preselectTireTypeAndLoadTireSizeFilter();
  }

  private loadTireTypesFilterDefault() {
    const activityTypeKey = this.selectedActivityType ? this.selectedActivityType.name : '0';

    this.tireSearchDialogService.getFilterTireTypes(this.companyCode, activityTypeKey).subscribe({
      next: (tireTypes) => {
        this.tireTypes = tireTypes.filter((tireType) =>
          this.availableActivityTypes.some((activityType) => activityType.name === tireType.value)
        );

        this.preselectTireTypeAndLoadTireSizeFilter();
      }
    });
  }

  private preselectTireTypeAndLoadTireSizeFilter() {
    if (this.tireTypes.length === 1) {
      this.tireType = this.tireTypes[0].value;
      this.tireSearchForm.patchValue({ tireType: this.tireTypes[0].value });
      this.loadTireSizeFilter(this.tireTypes[0].value);
    } else if (this.parentActivityType) {
      const preselect = this.tireTypes.find((tireType) => tireType.value === this.parentActivityType.name);
      if (preselect) {
        this.tireType = preselect.value;
        this.tireSearchForm.patchValue({ tireType: preselect.value });
        this.loadTireSizeFilter(preselect.value);
      }
    }
  }

  private loadTireSizeFilter(tireType: string) {
    this.showLoading();

    this.tireSearchDialogService.getTireSizes(this.companyCode, tireType).subscribe((tireSizes) => {
      this.tireSizes = tireSizes as DropdownOption[];
      this.tireSearchForm.controls['tireSize'].enable();
      this.hideLoading();

      this.preselectMatchingContractTireSize();
    });
  }

  private preselectMatchingContractTireSize() {
    // find the first matching tire size and select it
    for (const contractTireSize of this.contractTireSizes) {
      const tireSizeParts = contractTireSize.split(':');
      if (tireSizeParts[0] === this.tireType) {
        const tireSizeMatchRegExp = this.getTireSizeRegex(tireSizeParts[1]);
        const selectedTireSize =
          tireSizeMatchRegExp != null
            ? this.tireSizes.find((tireSize) => tireSizeMatchRegExp.test(tireSize.value))
            : null;
        this.tireSize = selectedTireSize?.value ?? '';
        this.tireSearchForm.patchValue({ tireSize: selectedTireSize?.value });
        this.loadTires(true);
        break;
      }
    }
  }

  /**
   * Load the available tires from the backend.
   * @param all Loads the tires and additional filter information.
   */
  private loadTires(all: boolean) {
    this.showLoading();

    const tireFilter: TireFilter = {
      tireType: this.tireType ?? '',
      tireBrand: this.tireBrand ?? '',
      tireSize: this.tireSize ?? '',
      speedIndex: this.tireSpeedIndex ?? '',
      loadIndex: this.tireLoadIndex ?? '',
      companyCode: this.companyCode
    };

    if (all) {
      this.tireSearchDialogService.getAllTires(tireFilter).subscribe((data: TireFilterResult) => {
        this.tireSpeedIndexes = data.speedIndexes;
        this.tireSpeedIndexDisabled = false;
        this.tireBrands = data.brands;
        this.tireBrandDisabled = false;
        this.tireLoadIndexes = data.loadIndexes;
        this.tireLoadIndexDisabled = false;
        this.loadedTires = data.tires;
        this.tires = data.tires.map((tire) => this.tireCommonToSelectListItem(tire));
        this.tireDisabled = false;
        this.productNumberControl.setValue('');
        this.selectedTire = null;
        this.hideLoading();
      });
    } else {
      this.tireSearchDialogService.getTires(tireFilter).subscribe((data: TireFilterResult) => {
        this.loadedTires = data.tires;
        this.tires = data.tires.map((tire) => this.tireCommonToSelectListItem(tire));
        this.tireDisabled = false;
        this.productNumberControl.setValue('');
        this.selectedTire = null;
        this.tire = '';
        this.hideLoading();
      });
    }
  }

  private tireCommonToSelectListItem(tire: TireCommon) {
    return {
      value: tire.id,
      text: tire.size + ' - ' + tire.brand + ' (' + tire.tireName + ')'
    } as DropdownOption;
  }

  private loadContractTireSizes() {
    this.tireSearchDialogService.getContractTireSizes().subscribe((data: string[]) => (this.contractTireSizes = data));
  }

  private getFilteredContractTireSizes() {
    return this.contractTireSizes.filter((tireSize) => {
      const tireSizeParts = tireSize.split(':');
      return tireSizeParts[0] === this.tireType;
    });
  }

  private hideLoading() {
    if (this.spinner) {
      this.spinner.nativeElement.style.visibility = 'hidden';
    }
  }

  private showLoading() {
    if (this.spinner) {
      this.spinner.nativeElement.style.visibility = 'visible';
    }
  }
}
