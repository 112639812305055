import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocaleFormatService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { OfferDetailsDialogComponent } from '../offer-details-dialog/offer-details-dialog.component';
import { Offer } from '../offer-panel.types';

@Component({
  selector: 'offer-dashboard',
  templateUrl: './offer-dashboard.component.html',
  styleUrls: ['./offer-dashboard.component.scss']
})
export class OfferDashboardComponent implements OnInit {
  @Input() offer?: Offer;
  @Input() offers: Offer[] = [];
  @Input() responseGuid: string = '';
  @Input() visibleDashboardColumns: string[] = [];
  @Input() visibleDetailColumns: string[] = [];

  public offerDetails: string[] = [];

  constructor(
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private localeFormatService: LocaleFormatService
  ) {}

  ngOnInit() {
    this.getOfferDetails();
  }

  public showDetails() {
    const offerDetailsDialogComponent = this.dialog.open(OfferDetailsDialogComponent, {
      position: { top: '50px' },
      width: '80%'
    });
    offerDetailsDialogComponent.componentInstance.offers = this.offers;
    offerDetailsDialogComponent.componentInstance.responseGuid = this.responseGuid;
    offerDetailsDialogComponent.componentInstance.visibleDetailColumns = this.visibleDetailColumns;

    if (this.offer) {
      offerDetailsDialogComponent.componentInstance.offer = this.offer;
    }
  }

  private getOfferDetails() {
    for (const columnName of this.visibleDashboardColumns) {
      const value = this.getValue(columnName);
      if (!value) continue;
      this.offerDetails.push(value);
    }
  }

  private getValue(columnName: string): string {
    if (!this.offer) {
      return '';
    }

    switch (columnName) {
      case 'ProductName':
        return this.offer.label;
      case 'YearlyPremiumWithTax':
        return this.getPriceValue(
          this.offer.minOffer.prices.yearlyRate.value.value.toString(),
          this.offer.minOffer.prices.yearlyRate.unit,
          '/yr'
        );
      case 'MonthlyPremiumWithTax':
        return this.getPriceValue(
          this.offer.minOffer.prices.monthlyRate.value.value.toString(),
          this.offer.minOffer.prices.monthlyRate.unit,
          '/m'
        );
      case 'ProductDescription':
        return this.offer.minOffer.configuration.shortDescription;
      case 'ProductNameSubtitle':
        return this.offer.minOffer.configuration.productNameSubtitle;
      default:
        return '';
    }
  }

  private getPriceValue(price: string, unit: string | undefined, suffix: string) {
    price = this.localeFormatService.formatNumericString(price);
    const label = this.translocoService.translate('OfferPanelComponents_Offer_PriceStartingFrom');
    return `${label} ${price} ${unit ?? '€'}${suffix}`;
  }
}
