import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UnsavedChangesEventName } from './request.types';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesStateStore {
  private _unsavedChangesEventMessages: Subject<UnsavedChangesEventName> = new Subject();

  public readonly unsavedChangesEventMessages: Observable<UnsavedChangesEventName> =
    this._unsavedChangesEventMessages.asObservable();

  public addEventMessage(eventMessage: UnsavedChangesEventName) {
    this._unsavedChangesEventMessages.next(eventMessage);
  }
}
