<div #spinner class="spinner spinner-small"></div>

<div *transloco="let t">
  <h2 mat-dialog-title class="ui-test--request-tire-search-dialg-header-title">{{ t("TireRequest_TireSelection") }}</h2>

  <mat-dialog-content>
    <form [formGroup]="tireSearchForm" class="flex-col center-center w-full">
      <!-- Tire Type-->
      <dropdown-with-search
        ngDefaultControl
        labelResourceKey="TireRequest_TireType"
        formControlName="tireType"
        [validators]="[Validators.required]"
        [(value)]="tireType"
        [dropDownOptions]="tireTypes"
        [sharedFormGroup]="tireSearchForm"
        (valueChange)="selectedTireType($event)"
        class="w-full ui-test--request-tire-search-dialg-dropdown-with-search-tire-type"></dropdown-with-search>

      <!-- Tire Size -->
      <dropdown-with-search
        ngDefaultControl
        labelResourceKey="TireRequest_TireSize"
        errorResourceKey="TireFilter_Warning_For_DefaultTireSizeNotSelected"
        formControlName="tireSize"
        [(value)]="tireSize"
        [validators]="[matchingContractTireSizesValidator(), Validators.required]"
        [isDisabled]="tireSizeDisabled"
        [dropDownOptions]="tireSizes"
        [sharedFormGroup]="tireSearchForm"
        (valueChange)="selectedTireSize($event)"
        class="w-full long-error ui-test--request-tire-search-dialg-dropdown-with-search-tire-size"></dropdown-with-search>

      <!-- Tire Load Index -->
      <dropdown-with-search
        ngDefaultControl
        labelResourceKey="TireRequest_TireLoadIndex"
        formControlName="tireLoadIndex"
        [(value)]="tireLoadIndex"
        [isDisabled]="tireLoadIndexDisabled"
        [dropDownOptions]="tireLoadIndexes"
        [sharedFormGroup]="tireSearchForm"
        (valueChange)="selectedFilterValue($event)"
        class="w-full ui-test--request-tire-search-dialg-dropdown-with-search-tire-load-index"></dropdown-with-search>

      <!-- Tire Speed Index -->
      <dropdown-with-search
        ngDefaultControl
        labelResourceKey="TireRequest_TireSpeedIndex"
        formControlName="tireSpeedIndex"
        [(value)]="tireSpeedIndex"
        [isDisabled]="tireSpeedIndexDisabled"
        [dropDownOptions]="tireSpeedIndexes"
        [sharedFormGroup]="tireSearchForm"
        (valueChange)="selectedFilterValue($event)"
        class="w-full ui-test--request-tire-search-dialg-dropdown-with-search-tire-speed-index"></dropdown-with-search>

      <!-- Tire Brand -->
      <dropdown-with-search
        ngDefaultControl
        labelResourceKey="TireRequest_TireBrand"
        formControlName="tireBrand"
        [(value)]="tireBrand"
        [isDisabled]="tireBrandDisabled"
        [dropDownOptions]="tireBrands"
        [sharedFormGroup]="tireSearchForm"
        (valueChange)="selectedFilterValue($event)"
        class="w-full ui-test--request-tire-search-dialg-dropdown-with-search-tire-brand"></dropdown-with-search>

      <!-- Tires -->
      <dropdown-with-search
        ngDefaultControl
        labelResourceKey="TireRequest_Tires"
        formControlName="tire"
        [required]="true"
        [validators]="[Validators.required]"
        [(value)]="tire"
        [isDisabled]="tireDisabled"
        [dropDownOptions]="tires"
        [sharedFormGroup]="tireSearchForm"
        (valueChange)="selectedTireChanged($event)"
        class="w-full ui-test--request-tire-search-dialg-dropdown-with-search-tires"></dropdown-with-search>

      <mat-form-field class="w-full">
        <mat-label class="ui-test--request-tire-search-dialg-mat-label-product-number">
          {{ t('TireRequest_ProductNumber') }}
        </mat-label>
        <input
          class="ui-test--request-tire-search-dialg-input-product-number"
          type="text"
          matInput
          formControlName="productNumber" />
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      *ngIf="positionData.showContractInformationInTireRequest"
      class="ui-test--request-tire-search-dialg-button-dashboard"
      [title]="t('PageTitle_Dashboard')"
      (click)="showDashboardDialog()">
      <mat-icon class="ui-test--request-tire-search-dialg-mat-icon-dashboard">dashboard</mat-icon>
    </button>
    <button class="ui-test--request-tire-search-dialg-button-close" mat-raised-button mat-dialog-close>
      {{ t('Common_Close') }}
    </button>
    <button
      class="ui-test--request-tire-search-dialg-button-confirm"
      mat-raised-button
      color="primary"
      [disabled]="productNumberControl.value === ''"
      (click)="confirm()">
      {{ t("Common_Confirm") }}
    </button>
  </mat-dialog-actions>
</div>
