<div class="chat" [class.chat-full-width]="fullWidth" *transloco="let t">
  <ng-container *ngFor="let responseNote of responseNotes; let i = index">
    <div class="chat-item chat-you" [ngClass]="optionalItemsClass">
      <div class="chat-when" [class]="'ui-test--sdd-status-details-notes-div-chat-item-' + i">
        <mat-icon [class]="'ui-test--status-details-notes-mat-icon-chat-item-' + i">today</mat-icon>
        {{ responseNote.timestamp | dateFormat:'DATETIME_SHORT_WITH_SECONDS' }}
      </div>
      <p
        class="mt-5 chat-what"
        [innerHtml]="responseNote.text | nl2br"
        [class]="'ui-test--status-details-notes-paragraph-note-' + i"></p>
    </div>
  </ng-container>
  <div *ngIf="annotation || textModuleText" class="chat-item chat-me">
    <div class="chat-when ui-test--status-details-notes-div-annotation-chat-item">
      <mat-icon class="ui-test--status-details-notes-mat-icon-annotation-chat-item">today</mat-icon>
      {{ annotationDate | dateFormat:'DATETIME_SHORT_WITH_SECONDS' }}
    </div>
    <ng-container *ngIf="annotation">
      <div class="chat-title ui-test--status-details-notes-div-annotation-important-information">
        {{ t('Common_YourImportantInformation') }}
      </div>
      <p class="chat-what ui-test--status-details-notes-paragraph-annotation">{{ annotation }}</p>
    </ng-container>
    <ng-container *ngIf="textModuleText">
      <div class="chat-title ui-test--status-details-notes-div-annotation-text-module">
        {{ t('Common_YourTextModule') }}
      </div>
      <p class="chat-what ui-test--status-details-notes-paragraph-annotation-text-module">{{ textModuleText }}</p>
    </ng-container>
  </div>
</div>
