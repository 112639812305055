import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { InvoiceStateLabelModule } from '../invoice-state-label/invoice-state-label.module';
import { InvoiceClarificationHistoryComponent } from './invoice-clarification-history.component';

@NgModule({
  imports: [MatIconModule, MatCardModule, InvoiceStateLabelModule, PipesModule, SharedModule],
  exports: [InvoiceClarificationHistoryComponent],
  declarations: [InvoiceClarificationHistoryComponent],
  providers: []
})
export class InvoiceClarificationHistoryModule {}
