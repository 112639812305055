import { Component, Input, OnInit, ViewChildren, ViewEncapsulation } from '@angular/core';
import { RequestEventMessage, RequestStateStore, TenantSettingsService } from '@core/services';
import { ActivityTypeIdentifierCommon } from 'app/components/positions';
import { TotalAmounts } from 'app/components/request-total-amounts/request-total-amounts.types';
import { RequestService } from '../../request.service';
import { RequestContent, RequestPosition } from '../../request.types';
import { UnsavedChangesStateStore } from '../../unsaved-changes-state-store.service';
import { GenericPositionComponent } from './generic-position/generic-position.component';

@Component({
  selector: 'positions-edit',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PositionsComponent implements OnInit {
  @Input() requestContent!: RequestContent;

  @ViewChildren('position') positions!: GenericPositionComponent[] | undefined;

  isGermanWarrantyEditMode: boolean = this.tenantSettingsService.warrantyEditMode === 'GermanWarranty';
  public tirePositionAvailableActivityTypes: ActivityTypeIdentifierCommon[] = [];

  constructor(
    private requestService: RequestService,
    private requestStore: RequestStateStore,
    private unsavedChangesStateStore: UnsavedChangesStateStore,
    private tenantSettingsService: TenantSettingsService
  ) {}

  ngOnInit(): void {
    this.preloadTirePositionAvailableActivityTypes();
    this.setupDefaultPositionItem();
  }

  preloadTirePositionAvailableActivityTypes(): void {
    this.tirePositionAvailableActivityTypes = <ActivityTypeIdentifierCommon[]>(
      ['WorkPosition', 'MaintenancePackagePosition', 'WarrantyPosition', 'TireServicePosition'].filter((itemType) =>
        this.requestContent.availableActivityTypes.some((at) => at.itemType === itemType)
      )
    );
  }

  setupDefaultPositionItem(): void {
    // Do not add default item if request positions already exists
    if (this.requestContent.requestPositions.length) {
      return;
    }

    // Mobility default position item
    const productType =
      this.requestContent.requestProcess.editProductType || this.requestContent.requestProcess.productType;
    if (productType === 'Mobility') {
      this.addPosition();
      return;
    }

    // Tire service position item
    if (
      this.tirePositionAvailableActivityTypes.length === 1 &&
      this.tirePositionAvailableActivityTypes[0] === 'TireServicePosition'
    ) {
      this.addPosition();
    }
  }

  get totalAmounts(): TotalAmounts {
    // TODO: Not fully tested, introduce and fix these methods for recalculation with positions edit component
    return <TotalAmounts>{
      totalNetAmount: this.totalNetAmount,
      totalGoodwill: this.totalGoodwill,
      totalDeductibles: this.requestContent.totalDeductibles,
      expectedPayout: this.expectedPayout,
      expectedCustomerShare: this.requestContent.expectedCustomerShare,
      isDisplayTotalGoodwillValue: this.requestContent.isDisplayTotalGoodwillValue,
      isDisplayTotalDeductibleValue: this.requestContent.isDisplayTotalDeductibles,
      isDisplayExpectedPayoutValue: this.requestContent.isDisplayExpectedPayout,
      isDisplayPayableByCustomer: this.requestContent.isDisplayPayableByCustomer,
      displayCurrencyUnit: this.requestContent.requestProcess.displayCurrencyUnit
    };
  }

  positionIdentifier(_index: number, item: RequestPosition) {
    return item.positionId;
  }

  get totalNetAmount() {
    let totalNetAmount = 0;
    this.positions?.forEach((position) => {
      if (!position.hasNetAmount) {
        return;
      }
      if (position.excludePositionFromCalculating) {
        return;
      }
      totalNetAmount += position.netAmount;
    });

    return totalNetAmount;
  }

  get hasTotalNetAmount() {
    if (!this.positions || this.positions.length === 0) {
      return false;
    }

    return this.positions.some((position) => position.hasNetAmount);
  }

  get hasTotalGoodwill() {
    if (!this.positions || this.positions.length === 0) {
      return false;
    }

    let hasTotalGoodwill = false;
    let isPriceless = false;
    this.positions.forEach((position) => {
      if (!position.excludePositionFromCalculating) {
        if (position.hasGoodwill) {
          hasTotalGoodwill = true;
        }
        if (position.isPricelessRequestAllowed) {
          isPriceless = true;
        }
      }
      return true;
    });

    return !isPriceless && hasTotalGoodwill;
  }

  get totalGoodwill() {
    let totalGoodwill = 0;

    this.positions?.forEach((position) => {
      if (!position.hasGoodwill) {
        return;
      }
      if (position.excludePositionFromCalculating) {
        return;
      }
      totalGoodwill += position.goodwillAmount;
    });

    return Math.round(totalGoodwill * 100) / 100;
  }

  get hasExpectedPayout() {
    if (this.requestContent.requestPositions.length === 0) {
      return false;
    }
    return this.hasTotalNetAmount || this.hasTotalGoodwill || this.hasTotalDeductibles;
  }

  get expectedPayout(): number | null {
    if (this.hasTotalNetAmount && this.hasExpectedPayout) {
      let expectedPayout = this.totalNetAmount;
      if (this.hasTotalGoodwill) {
        expectedPayout -= this.totalGoodwill;
      }
      if (this.hasTotalDeductibles) {
        expectedPayout -= this.requestContent.totalDeductibles;
      }

      return expectedPayout;
    }
    if (this.hasTotalNetAmount) {
      return this.totalNetAmount;
    }

    return null;
  }

  get hasTotalDeductibles() {
    return this.requestContent.totalDeductibles != null && this.isProductTypeInsuranceRepair;
  }

  get isProductTypeInsuranceRepair() {
    const productType =
      this.requestContent.requestProcess.editProductType || this.requestContent.requestProcess.productType;
    return productType == 'InsuranceRepair';
  }

  savePosition() {
    this.requestService.save(this.requestContent).subscribe(() => {
      this.unsavedChangesStateStore.addEventMessage('Saved');
    });
  }

  removePosition(positionData: RequestPosition): void {
    this.toggleDeletePosition(positionData, true);
  }

  displayDescription(positionId: string): string {
    return (
      this.positions?.find((position) => position.positionData.positionId == positionId)?.displayDescription() ?? ''
    );
  }

  restorePosition(positionData: RequestPosition) {
    this.toggleDeletePosition(positionData, false);
  }

  getGenericPositionComponent(positionId: string) {
    return this.positions?.find((position) => position.positionData.positionId === positionId);
  }

  private toggleDeletePosition(positionData: RequestPosition, toggleDelete: boolean) {
    positionData.isPositionMarkedAsDeleted = toggleDelete;
    positionData.isPositionVisible = !toggleDelete;

    this.requestStore.addEventMessage({
      eventName: 'ToggleDeletePosition',
      payload: { positionId: positionData.positionId, toggleDelete: toggleDelete }
    } as RequestEventMessage);
    this.unsavedChangesStateStore.addEventMessage('Dirty');
  }

  addPosition(activityType: ActivityTypeIdentifierCommon | undefined = undefined) {
    this.requestService.addPosition(this.requestContent, activityType).subscribe({
      next: (res: RequestContent) => {
        this.requestContent.requestPositions = res.requestPositions;
        this.requestStore.addEventMessage({ eventName: 'AddNewPosition' } as RequestEventMessage);
        this.unsavedChangesStateStore.addEventMessage('Dirty');
      }
    });
  }
}
