import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a string to a property name.
 * Removes all blanks.
 */
@Pipe({
  name: 'propertyName'
})
export class PropertyNamePipe implements PipeTransform {
  transform(value?: string): string {
    return value?.replace(/\s/g, '') ?? '';
  }
}
