import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ConfigurationService, LocaleFormatService, RequestStateStore, TenantSettingsService } from '@core/services';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { UnsavedChangesStateStore } from 'app/pages/request';
import { RequestService } from 'app/pages/request/request.service';
import { GenericPositionComponent } from '../generic-position/generic-position.component';

@Component({
  selector: 'tire-service-position',
  templateUrl: './tire-service-position.component.html'
})
export class TireServicePositionComponent extends GenericPositionComponent {
  /**
   * Constructor
   */
  constructor(
    formBuilder: UntypedFormBuilder,
    positionConfigurationService: PositionConfigurationService,
    requestService: RequestService,
    requestStore: RequestStateStore,
    unsavedChangesStateStore: UnsavedChangesStateStore,
    localeFormatService: LocaleFormatService,
    configurationService: ConfigurationService,
    tenantSettingsService: TenantSettingsService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(
      formBuilder,
      positionConfigurationService,
      12,
      requestStore,
      unsavedChangesStateStore,
      requestService,
      localeFormatService,
      configurationService,
      tenantSettingsService,
      changeDetector,
      elementRef
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get canAddSpareParts(): boolean {
    return (
      !this.requestContent.insertTireSparepartsOnSubItem &&
      this.requestAvailableActivityTypes.some((activityType) => activityType.itemType === 'TireSparePart')
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  addTirePosition(): void {
    super.addSparePart('TirePosition');
  }

  override addSparePart(): void {
    super.addSparePart('TireSparePart');
  }
}
