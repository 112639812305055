import { Injectable } from '@angular/core';
import { ContextDataService } from '@core/services/context-data';
import { RoleCommon } from '@core/services/roles/role.enums';
import { DateTime } from 'luxon';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { findIana } from 'windows-iana';
import { UserApiService } from './user-api.service';
import { UserSettingKeyCommon } from './user.enums';
import { UserSetting } from './user.types';

@Injectable({ providedIn: 'root' })
export class UserService {
  [key: string]: unknown;
  isSendingRestricted = new BehaviorSubject<boolean>(false);
  isReadOnly = new BehaviorSubject<boolean>(false);

  private userSettings!: UserSetting[];

  constructor(private contextDataService: ContextDataService, private userApiService: UserApiService) {
    this.updateBehaviorSubjects();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get aftersalesOverviewViewMode(): string | undefined {
    return this.getUserSettingValue(UserSettingKeyCommon.AftersalesOverviewViewMode);
  }

  set aftersalesOverviewViewMode(value: string | undefined) {
    if (!value) {
      return;
    }

    this.userApiService.setAftersalesOverviewViewMode(value).subscribe(() => {
      this.setUserSettingValue(UserSettingKeyCommon.AftersalesOverviewViewMode, value);
    });
  }

  get timeZone(): string {
    const windowsUserTimeZone = this.getUserSettingValue(UserSettingKeyCommon.UserTimeZone);
    const ianaUserTimeZone = windowsUserTimeZone ? findIana(windowsUserTimeZone) : undefined;
    return ianaUserTimeZone && ianaUserTimeZone.length > 0 ? ianaUserTimeZone[0].toString() : '';
  }

  get userLanguage(): string | undefined {
    return this.getUserSettingValue(UserSettingKeyCommon.UserLanguage);
  }

  get defaultLanguage(): string {
    return this.getUserSettingValue(UserSettingKeyCommon.DefaultLanguage) ?? '';
  }

  get isWkmDealer(): boolean {
    return this.contextDataService?.userInfo?.dealer?.dealerExtension?.isWkmDealer;
  }

  get isManagingDealer(): boolean {
    return this.contextDataService?.userInfo?.isManagingDealer;
  }

  get userId(): string {
    return this.contextDataService?.userInfo?.id;
  }

  get dealerId(): string {
    return this.contextDataService?.userInfo?.dealer?.externalDealerNumber;
  }

  get statusOverviewViewMode(): string | undefined {
    return this.getUserSettingValue(UserSettingKeyCommon.StatusOverviewViewMode);
  }

  set statusOverviewViewMode(value: string | undefined) {
    if (!value) {
      return;
    }

    this.userApiService.setStatusOverviewViewMode(value).subscribe(() => {
      this.setUserSettingValue(UserSettingKeyCommon.StatusOverviewViewMode, value);
    });
  }

  get statusOverviewProductTypeFilter(): string | undefined {
    return this.getUserSettingValue(UserSettingKeyCommon.StatusOverviewProductTypeFilter);
  }

  get openRequestViewMode(): string | undefined {
    return this.getUserSettingValue(UserSettingKeyCommon.OpenRequestViewMode);
  }

  set openRequestViewMode(value: string | undefined) {
    if (!value) {
      return;
    }

    this.userApiService.setOpenRequestViewMode(value).subscribe(() => {
      this.setUserSettingValue(UserSettingKeyCommon.OpenRequestViewMode, value);
    });
  }

  get openRequestProductTypeFilter(): string | undefined {
    return this.getUserSettingValue(UserSettingKeyCommon.OpenRequestProductTypeFilter);
  }

  get userPhoneNumber(): string | undefined {
    return this.getUserSettingValue(UserSettingKeyCommon.UserPhoneNumber);
  }

  get userPhoneNumberModifiedDate(): string | undefined {
    return this.getUserSetting(UserSettingKeyCommon.UserPhoneNumber)?.modifiedDate ?? undefined;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Search the user setting based on the key return child property called `value`.
   */
  private getUserSettingValue(userSettingKey: UserSettingKeyCommon): string | undefined {
    return this.getUserSetting(userSettingKey)?.value ?? undefined;
  }

  /**
   * Returned user setting based on the key.
   */
  private getUserSetting(userSettingKey: UserSettingKeyCommon): UserSetting | undefined {
    return this.userSettings.find((us) => us.key === UserSettingKeyCommon[userSettingKey]);
  }

  private setUserSettingValue(userSettingKey: UserSettingKeyCommon, value: string): void {
    const index = this.userSettings.findIndex(
      (userSetting) => userSetting.key === UserSettingKeyCommon[userSettingKey]
    );

    if (index == -1) {
      // create it temporary until we reload the settings from the Core next time
      this.userSettings.push({ key: UserSettingKeyCommon[userSettingKey], value } as UserSetting);
      return;
    }
    this.userSettings[index].value = value;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Determines whether userInfo has the specified role.
   */
  hasRole(role: RoleCommon): boolean {
    return (
      this.contextDataService?.userInfo?.roles?.some(function (r: RoleCommon) {
        return (r & role) === role;
      }) ?? false
    );
  }

  /**
   * Checks if the user has at least one role out of the given array of roles
   */
  hasAnyRoleOf(roles: RoleCommon[]): boolean {
    return (
      this.contextDataService?.userInfo?.roles?.some(function (userRole: RoleCommon) {
        return roles.some((role) => {
          return (userRole & role) === role;
        });
      }) ?? false
    );
  }

  /**
   * Get the utc time. If no DateTime object is passed, we assume local now.
   */
  getUtcFromUserTime(localTime: DateTime): DateTime {
    return localTime.setZone(this.timeZone).toUTC();
  }

  load(): Observable<void> {
    return this.userApiService
      .load(this.contextDataService.data.tenant, this.contextDataService.data.user.serviceAdvisorId)
      .pipe(
        map((result: UserSetting[]) => {
          this.userSettings = result;
          this.contextDataService.userLanguage = this.userLanguage ?? '';
          this.contextDataService.defaultLanguage = this.defaultLanguage;
        })
      );
  }

  updateBehaviorSubjects(): void {
    this.isSendingRestricted.next(this.hasRole(RoleCommon.SendingRestricted));
    this.isReadOnly.next(this.hasRole(RoleCommon.ReadOnly));
  }
}
