import { Component, Input } from '@angular/core';
import { InformationService } from '../information.service';
import { InformationEntry } from './information-entry.types';

@Component({
  selector: 'information-entry',
  templateUrl: './information-entry.component.html',
  styleUrls: ['./information-entry.component.scss']
})
export class InformationEntryComponent {
  @Input() entry?: InformationEntry;

  public markAsReadLoading: boolean = false;

  constructor(private informationService: InformationService) {}

  onMarkAsReadClick() {
    if (!this.entry) {
      return;
    }

    this.markAsReadLoading = true;
    this.informationService.setInformationAsRead(this.entry.id).subscribe({
      next: () => {
        this.markAsReadLoading = false;
        if (this.entry) {
          this.entry.alreadyRead = true;
        }
        this.informationService.updateUnreadCount();
      },
      error: () => {
        this.markAsReadLoading = false;
      }
    });
  }
}
