import { NgModule } from '@angular/core';
import { SharedModule } from '@core/modules';
import { InformationBubbleComponent } from './information-bubble.component';

@NgModule({
  imports: [SharedModule],
  exports: [InformationBubbleComponent],
  declarations: [InformationBubbleComponent]
})
export class InformationBubbleModule {}
