<ng-container *ngIf="statusDetails; else statusDetailsLoading">
  <ng-container *transloco="let t">
    <div class="flex justify-between">
      <h1 mat-dialog-title class="ui-test--status-details-dialog-header-title">
        {{ t('StatusOverview_Index_DetailTitle') }}
      </h1>
      <mat-form-field class="flex-grow-9 version-select" *ngIf="latestIndex > 1">
        <mat-label class="ui-test--status-details-dialog-mat-label-request-version-title">
          {{ t('StatusDetails_RequestVersion_Title') }}
        </mat-label>
        <div *ngIf="versionChangeLoading" class="spinner spinner-mini"></div>
        <mat-select
          [(value)]="selectedVersionIndex"
          [disabled]="versionChangeLoading"
          (selectionChange)="onSelectedVersionChange()">
          <mat-option
            *ngFor="let index of versionDropdownOptions; let i = index"
            [class]="'ui-test--status-details-dialog-mat-option-index-' + i"
            [value]="index">
            {{ index }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div mat-dialog-content class="mat-typography">
      <status-details-detail-header [statusDetails]="statusDetails"></status-details-detail-header>

      <mat-tab-group mat-align-tabs="start" animationDuration="0ms" [(selectedIndex)]="selectedTab">
        <mat-tab #matTab label="details">
          <ng-template mat-tab-label>{{ t('StatusDetails_Details_Title') }}</ng-template>
          <status-details-metadata [statusDetails]="statusDetails"></status-details-metadata>
        </mat-tab>

        <mat-tab #matTab label="events" *ngIf="statusDetails.displayEventPane">
          <ng-template mat-tab-label>{{ t('StatusDetails_Events_Title') }}</ng-template>
          <status-details-events [statusDetails]="statusDetails"></status-details-events>
        </mat-tab>

        <mat-tab #matTab label="positions" *ngIf="statusDetails.displayPositionsPane">
          <ng-template mat-tab-label>{{ t('StatusDetails_Positions_Title') }}</ng-template>
          <status-details-positions [statusDetails]="statusDetails"></status-details-positions>
        </mat-tab>

        <mat-tab #matTab label="answers" *ngIf="statusDetails.displayAnswerPane">
          <ng-template mat-tab-label>{{ t('StatusDetails_QuestionAnswers_Title') }}</ng-template>
          <status-details-answers [statusDetails]="statusDetails"></status-details-answers>
        </mat-tab>

        <mat-tab #matTab label="history" *ngIf="statusDetails.displayInvoiceHistoryPane">
          <ng-template mat-tab-label>{{ t('StatusDetails_InvoiceHistory_Title') }}</ng-template>
          <status-details-invoice-history [statusDetails]="statusDetails"></status-details-invoice-history>
        </mat-tab>

        <mat-tab label="attachments" #matTab *ngIf="statusDetails.displayAttachmentsPane">
          <ng-template mat-tab-label>{{ t('StatusDetails_Attachments_Title') }}</ng-template>
          <status-details-attachments [statusDetails]="statusDetails"></status-details-attachments>
        </mat-tab>

        <mat-tab
          #matTab
          label="invoiceClarificationHistory"
          *ngIf="statusDetails.invoiceClarificationHistoryData.invoiceClarificationHistory.length > 0">
          <ng-template mat-tab-label>{{ t('StatusDetails_InvoiceClarificationHistory_Title') }}</ng-template>
          <div class="mt-20">
            <h3 class="mb-10 section-title">{{ t('StatusDetails_InvoiceClarificationHistory_Title') }}</h3>
            <invoice-clarification-history
              [invoiceClarificationHistoryData]="statusDetails.invoiceClarificationHistoryData"></invoice-clarification-history>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div mat-dialog-actions align="end">
      <ng-container *ngIf="(userService.isReadOnly | async) === false">
        <button
          mat-stroked-button
          class="delete"
          (click)="cancelInvoiceClarification()"
          *ngIf="statusDetails.canCancelInvoiceClarification">
          <mat-icon>delete</mat-icon>
          {{ t('StatusDetails_CancelInvoiceClarification') }}
        </button>

        <button
          mat-stroked-button
          (click)="requestInvoiceClarification()"
          *ngIf="statusDetails.canRequestInvoiceClarification">
          <mat-icon>playlist_add_check</mat-icon>
          {{ t('StatusDetails_RequestInvoiceClarification') }}
        </button>

        <button
          mat-stroked-button
          *ngIf="statusDetails.canRequestMobility"
          (click)="createMobilityRequest(statusDetails.requestGuid, statusDetails.contractDatabaseId, statusDetails.vehicleIdentificationNumber)">
          <mat-icon>transfer_within_a_station</mat-icon>
          {{ t('Common_RequestAddMobilityPositions') }}
        </button>

        <button
          mat-stroked-button
          class="delete"
          [disabled]="deleteInProgress"
          [class.button-spinner]="deleteInProgress"
          *ngIf="statusDetails.canDelete"
          (click)="deleteFromOpenProcesses(statusDetails.requestGuid)">
          <mat-icon>delete</mat-icon>
          {{ t('StatusDetails_DeleteFromOpenProcesses') }}
        </button>

        <button
          mat-stroked-button
          class="delete"
          *ngIf="statusDetails.canCancel"
          (click)="cancelRequest(statusDetails.requestGuid)">
          <mat-icon>delete</mat-icon>
          {{ t('Common_CancelRequest') }}
        </button>

        <button
          mat-stroked-button
          mat-dialog-close
          *ngIf="statusDetails.canEdit"
          class="edit"
          (click)="editRequest(statusDetails.requestGuid)">
          <mat-icon>edit</mat-icon>
          {{ t('Common_Edit') }}
        </button>

        <button
          mat-stroked-button
          *ngIf="statusDetails.canEditExternal"
          (click)="editExternalRequest(statusDetails.requestGuid, statusDetails.productType)">
          <mat-icon>edit</mat-icon>
          {{ t('Common_Edit') }}
        </button>

        <button
          mat-stroked-button
          *ngIf="statusDetails.displayOnlineInvoiceButton"
          (click)="onlineInvoice(statusDetails.requestGuid)">
          <mat-icon>upload_file</mat-icon>
          {{ t('Common_OnlineInvoice_Action') }}
        </button>
      </ng-container>

      <button mat-stroked-button *ngIf="statusDetails.shouldPrintButtonBeVisible" (click)="printSelect.open()">
        <mat-select #printSelect [(value)]="selectedPrintTabs" multiple>
          <mat-select-trigger><mat-icon>print</mat-icon></mat-select-trigger>
          <mat-option value="details" disabled>{{ t('StatusDetails_Details_Title') }}</mat-option>
          <mat-option value="events" *ngIf="statusDetails.displayEventPane">
            {{ t('StatusDetails_Events_Title') }}
          </mat-option>
          <mat-option value="positions" *ngIf="statusDetails.displayPositionsPane">
            {{ t('StatusDetails_Positions_Title') }}
          </mat-option>
          <mat-option
            value="requestDetails"
            *ngIf="statusDetails.displayRequestDetailsPane && !statusDetails.displayPositionsPane">
            {{ t('StatusDetails_RequestDetails_Title') }}
          </mat-option>
          <mat-option value="answer" *ngIf="statusDetails.displayAnswerPane">
            {{ t('StatusDetails_QuestionAnswers_Title') }}
          </mat-option>
          <mat-option value="invoiceHistory" *ngIf="statusDetails.displayInvoiceHistoryPane">
            {{ t('StatusDetails_InvoiceHistory_Title') }}
          </mat-option>
          <mat-option
            value="invoiceClarificationHistory"
            *ngIf="statusDetails.invoiceClarificationHistoryData.invoiceClarificationHistory.length > 0">
            {{ t('StatusDetails_InvoiceClarificationHistory_Title') }}
          </mat-option>
          <hr />
          <div class="flex flex-col">
            <button mat-stroked-button class="print-button" (click)="printDialogData()">
              {{ t('StatusDetails_Button_PrintSelection') }}
            </button>
            <button mat-stroked-button class="print-button" (click)="printAllDialogData()">
              {{ t('StatusDetails_Button_PrintAll') }}
            </button>
          </div>
        </mat-select>
      </button>

      <button mat-stroked-button mat-dialog-close cdkFocusInitial>{{ t('Common_Close') }}</button>
    </div>
  </ng-container>
</ng-container>

<status-details-print
  *ngIf="statusDetails.shouldPrintButtonBeVisible"
  [statusDetails]="statusDetails"
  [printTabs]="selectedPrintTabs"
  (finishedLoading)="printDialogData()"></status-details-print>

<ng-template #statusDetailsLoading>
  <div class="spinner"></div>
</ng-template>
