import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ContextDataService } from '@core/services';

@Injectable({
  providedIn: 'root'
})
export class RedirectOnParamsGuard implements CanActivate {
  constructor(private router: Router, private contextDataService: ContextDataService) {}

  public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    const dmsEditMode = this.getRoutingParameter(route, 'dmsRequestMode');
    const requestGuid = this.getRoutingParameter(route, 'requestGuid');

    if (!requestGuid) {
      return true;
    }
    switch (dmsEditMode) {
      case 'Mobility': {
        const contractId = this.getRoutingParameter(route, 'contractId');
        if (contractId) {
          return this.router.navigate(['request/create'], {
            queryParams: {
              productType: 'Mobility',
              contractDatabaseId: contractId,
              referencedRequestGuid: requestGuid
            }
          });
        }
        break;
      }
      case 'Edit':
        return this.router.navigate(['request/edit'], {
          queryParams: {
            requestGuid: requestGuid
          }
        });
    }
    return true;
  }

  private getRoutingParameter(route: ActivatedRouteSnapshot, key: string): string | null {
    const queryParam = route.queryParamMap;
    let result = queryParam.get(key);

    if (result) {
      return result;
    }

    const urlParams = new URLSearchParams(this.contextDataService.routingParameters ?? '');
    result = urlParams.get(key);
    return result;
  }
}
