import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { InvoiceClarificationConfirmationComponent } from './confirmation.component';

@NgModule({
  imports: [MatDividerModule, MatButtonModule, PageHeaderModule, PipesModule, SharedModule],
  exports: [],
  declarations: [InvoiceClarificationConfirmationComponent],
  providers: []
})
export class InvoiceClarificationConfirmationModule {}
