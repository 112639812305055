import { Component, Input } from '@angular/core';
import { InvoiceClarificationHistoryData } from './invoice-clarification-history.types';

@Component({
  selector: 'invoice-clarification-history',
  templateUrl: './invoice-clarification-history.component.html',
  styleUrls: ['./invoice-clarification-history.component.scss']
})
export class InvoiceClarificationHistoryComponent {
  @Input() invoiceClarificationHistoryData!: InvoiceClarificationHistoryData;
}
