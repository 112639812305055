import { Pipe, PipeTransform } from '@angular/core';
import { stringFormat } from '@core/utils';

/**
 * Formats a string to replace params C# style.
 */
@Pipe({
  name: 'stringFormat'
})
export class StringFormatPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value The string to be formatted
   * @param args: The arguments in order of the index that they will replace
   */
  transform(value: string, ...args: (string | number)[]): string {
    return stringFormat(value, ...args.map((a) => a.toString()));
  }
}
