import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';

import { RequestStateLabelComponent } from './request-state-label.component';

@NgModule({
  imports: [MatTooltipModule, PipesModule, SharedModule],
  exports: [RequestStateLabelComponent],
  declarations: [RequestStateLabelComponent],
  providers: []
})
export class RequestStateLabelModule {}
