<ng-container *transloco="let t">
  <h1 class="ui-test--ic-summary-header-title">{{ t('Request_CancelRequestSummary_Headline') }}</h1>
  <small class="ui-test--ic-summary-small-information">
    {{ t('InvoiceClarification_CancelInvoiceClarificationSummary_InformationText') }}
  </small>

  <mat-divider class="my-10"></mat-divider>

  <sending-restricted></sending-restricted>

  <h1 class="ui-test--ic-summary-header-general-information">{{ t('Request_Edit_GeneralInformation') }}</h1>
  <div class="flex-row">
    <div class="flex-col flex-grow-50 gap-15">
      <div class="flex-row justify-center gap-15">
        <strong
          class="flex flex-grow-50 justify-end content-center ui-test--ic-summary-strong-invoice-clarification-number-label">
          {{ t('InvoiceClarificationCancelSummaryViewModel_InvoiceClarificationNumber') }}
        </strong>
        <span class="flex flex-1 ui-test--ic-summary-span-invoice-clarification-number">
          {{ invoiceClarificationCancelSummary.invoiceClarificationNumber }}
        </span>
      </div>

      <div class="flex-row justify-center gap-15">
        <strong
          class="flex flex-grow-50 justify-end content-center ui-test--ic-summary-strong-license-plate-number-label">
          {{ t('InvoiceClarificationCancelSummaryViewModel_LicensePlateNumber') }}
        </strong>
        <span class="flex flex-1 ui-test--ic-summary-span-license-plate-number">
          {{ invoiceClarificationCancelSummary.licensePlateNumber }}
        </span>
      </div>

      <div class="flex-row justify-center gap-15">
        <strong class="flex flex-grow-50 justify-end content-center ui-test--ic-summary-strong-contract-number-label">
          {{ t('InvoiceClarificationCancelSummaryViewModel_ContractNumber') }}
        </strong>
        <span class="flex flex-1 ui-test--ic-summary-span-contract-number">
          {{ invoiceClarificationCancelSummary.contractNumber }}
        </span>
      </div>
    </div>

    <div class="flex-col flex-grow-50 gap-15">
      <div class="flex-row justify-center gap-15">
        <strong
          class="flex flex-grow-50 justify-end content-center ui-test--ic-summary-strong-vehicle-identification-number-label">
          {{ t('InvoiceClarificationCancelSummaryViewModel_VehicleIdentificationNumber') }}
        </strong>
        <span class="flex flex-1 ui-test--ic-summary-span-vehiclde-identification-number">
          {{ invoiceClarificationCancelSummary.vehicleIdentificationNumber }}
        </span>
      </div>

      <div class="flex-row justify-center gap-15">
        <strong class="flex flex-grow-50 justify-end content-center ui-test--ic-summary-strong-mileage-label">
          {{ t('InvoiceClarificationCancelSummaryViewModel_Mileage') }}
        </strong>
        <span class="flex flex-1 ui-test--ic-summary-span-mileage">
          {{ invoiceClarificationCancelSummary.mileage | mileageFormat }}
        </span>
      </div>
    </div>
  </div>

  <mat-divider class="my-10"></mat-divider>

  <h1 class="ui-test--ic-summary-header-credit-note">{{ t('PageTitle_CreditNote') }}</h1>

  <div class="flex-row">
    <div class="flex-col flex-grow-100 gap-15">
      <div class="flex-row justify-center gap-15">
        <strong class="flex flex-grow-50 justify-end content-center ui-test--ic-summary-strong-partial-credit-label">
          {{ t('InvoiceClarificationCancelSummaryViewModel_IsPartialCreditCreatedChecked') }}
        </strong>
        <span class="flex flex-1 ui-test--ic-summary-span-partial-credit">
          {{ invoiceClarificationCancelSummary.isPartialCreditCreatedChecked | yesNoUnknown }}
        </span>
      </div>

      <div class="flex-row justify-center gap-15">
        <strong class="flex flex-grow-50 justify-end content-center ui-test--ic-summary-strong-new-invoice-label">
          {{ t('InvoiceClarificationCancelSummaryViewModel_IsNewInvoiceChecked') }}
        </strong>
        <span class="flex flex-1 ui-test--ic-summary-span-new-invoice">
          {{ invoiceClarificationCancelSummary.isNewInvoiceChecked | yesNoUnknown }}
        </span>
      </div>
    </div>
  </div>

  <!-- Footer actions-->
  <mat-divider class="my-10"></mat-divider>

  <div class="flex-row justify-end content-center">
    <button
      *ngIf="invoiceClarificationCancelSummary.requestGuid"
      mat-stroked-button
      class="mr-5 ui-test--ic-summary-button-cancel"
      [disabled]="confirming"
      (click)="openStatusDetailsDialog()">
      {{ t('Request_Summary_StatusOverview_Index') }}
    </button>
    <button
      mat-raised-button
      class="continue-button ui-test--ic-summary-button-confirm"
      color="primary"
      [disabled]="confirming || (userService.isSendingRestricted | async)"
      [class.button-spinner]="confirming"
      (click)="confirm()">
      <mat-icon class="ui-test--ic-summary-mat-icon-confirm">skip_next</mat-icon>
      {{ t('Common_Confirm') }}
    </button>
  </div>
</ng-container>
