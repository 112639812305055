<form [formGroup]="sharedFormGroup" class="flex-grow">
  <mat-form-field
    class="w-full ui-test--dropdown-with-search-mat-form-field-dropdown-component"
    [class.mat-form-field-invalid]="backendValidationError || formControl.errors?.length"
    *transloco="let t">
    <mat-label [class]="'ui-test--dropdown-with-search-mat-label-' + t(labelResourceKey)">
      {{ t(labelResourceKey) }}
    </mat-label>
    <mat-select
      class="ui-test--dropdown-with-search-mat-label-selection"
      [formControlName]="formControlName"
      [multiple]="isMultiSelect"
      (selectionChange)="selectedValue()"
      disableOptionCentering>
      <mat-select-trigger class="flex-row flex-wrap" *ngIf="isMultiSelect">
        <div
          *ngFor="let option of selectedOptions | async; trackBy: optionIdentifier; let i = index"
          click-stop-propagation
          class="chip-element gap-3">
          <span [class]="'ui-test--dropdown-with-search-span-' + i">{{ option.text }}</span>
          <mat-icon
            [class]="'ui-test--dropdown-with-search-mat-icon-clear-' + i"
            click-stop-propagation
            (click)="remove(option.value)">
            clear
          </mat-icon>
        </div>
      </mat-select-trigger>
      <mat-option class="ui-test--dropdown-with-search-mat-option-dropdown-search-option">
        <ngx-mat-select-search
          class="ui-test--dropdown-with-search-mat-select-search-dropdown-search"
          [placeholderLabel]="t(placeholderResourceKey)"
          [noEntriesFoundLabel]="t('Common_NoEntriesFound')"
          [formControl]="searchInputControl"
          [alwaysRestoreSelectedOptionsMulti]="true"
          #searchInput></ngx-mat-select-search>
      </mat-option>
      <ng-container *ngFor="let option of filteredOptions | async; trackBy: optionIdentifier; let i = index">
        <mat-option
          *ngIf="option.shown"
          [value]="option.value"
          [class]="'ui-test--dropdown-with-search-mat-option-option-' + i"
          [innerHtml]="(highlightText ? (option.text | highlight:searchInput.value) : option.text)"></mat-option>
      </ng-container>
      <!-- Render the hidden options at the end. If we do not render them here, they are not rendered as selected option,
    because the material select needs them. We are rendering them below the selectable options, because otherwise
    they are selectable via the arrow buttons and that confuses the user. -->
      <ng-container *ngFor="let option of filteredOptions | async; trackBy: optionIdentifier; let i = index">
        <mat-option
          *ngIf="!option.shown"
          class="display-none"
          [value]="option.value"
          [class]="'ui-test--dropdown-with-search-mat-option-option-hidden-' + i"
          [innerHtml]="(highlightText ? (option.text | highlight:searchInput.value) : option.text)"></mat-option>
      </ng-container>
    </mat-select>
    <button
      matSuffix
      mat-icon-button
      class="clear-input ui-test--dropdown-with-search-button-delete-option"
      *ngIf="formControl.value && !isMultiSelect && !hideClearButton"
      [disabled]="formControl.disabled"
      (click)="reset()"
      click-stop-propagation>
      <mat-icon class="ui-test--dropdown-with-search-mat-icon-delete-option">clear</mat-icon>
    </button>
    <mat-error class="ui-test--dropdown-with-search-mat-error-error">{{ t(errorResourceKey) }}</mat-error>
  </mat-form-field>
</form>
