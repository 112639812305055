import { NgModule } from '@angular/core';
import { AnswerRenderPipe } from './answer-render.pipe';
import { ClassNamePipe } from './class-name.pipe';
import { CurrencyOrDashPipe } from './currency-or-dash.pipe';
import { FindByKeyPipe } from './find-by-key.pipe';
import { HighlightPipe } from './highlight.pipe';
import { HintReplacerPipe } from './hint-replacer.pipe';
import { DateFormatPipe } from './luxon-date.pipe';
import { MileageFormatPipe } from './mileage-format.pipe';
import { Nl2brPipe } from './nl2br.pipe';
import { NumericFormatPipe } from './numeric-format.pipe';
import { ParseDatePipe } from './parse-date.pipe';
import { ProductTypeIconPipe } from './product-type-icon.pipe';
import { PropertyNamePipe } from './property-name.pipe';
import { SafePipe } from './safe.pipe';
import { StringFormatPipe } from './string-format.pipe';
import { ValidatorsPipe } from './validators.pipe';
import { YesNoUnknownPipe } from './yes-no-unknown.pipe';

@NgModule({
  declarations: [
    AnswerRenderPipe,
    ClassNamePipe,
    FindByKeyPipe,
    HighlightPipe,
    HintReplacerPipe,
    DateFormatPipe,
    MileageFormatPipe,
    CurrencyOrDashPipe,
    Nl2brPipe,
    ParseDatePipe,
    ProductTypeIconPipe,
    PropertyNamePipe,
    SafePipe,
    StringFormatPipe,
    YesNoUnknownPipe,
    ValidatorsPipe,
    NumericFormatPipe
  ],
  exports: [
    AnswerRenderPipe,
    ClassNamePipe,
    FindByKeyPipe,
    HighlightPipe,
    HintReplacerPipe,
    DateFormatPipe,
    MileageFormatPipe,
    CurrencyOrDashPipe,
    Nl2brPipe,
    ParseDatePipe,
    ProductTypeIconPipe,
    PropertyNamePipe,
    SafePipe,
    StringFormatPipe,
    YesNoUnknownPipe,
    ValidatorsPipe,
    NumericFormatPipe
  ],
  providers: [
    AnswerRenderPipe,
    ClassNamePipe,
    FindByKeyPipe,
    HighlightPipe,
    HintReplacerPipe,
    DateFormatPipe,
    MileageFormatPipe,
    CurrencyOrDashPipe,
    Nl2brPipe,
    ParseDatePipe,
    ProductTypeIconPipe,
    PropertyNamePipe,
    SafePipe,
    StringFormatPipe,
    YesNoUnknownPipe,
    ValidatorsPipe,
    NumericFormatPipe
  ]
})
export class PipesModule {}
