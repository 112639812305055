import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AlertService, TenantSettingsService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';
import { FileUploadService } from './file-upload.service';
import { FileAttachment, FileUploadCategory, UploadFile } from './file-upload.types';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @Input() uploadUrl = '';
  @Input() fileCategories: FileUploadCategory[] = [];
  @Input() acceptedFileTypes = '';
  @Input() acceptedFileTypesDisplay = '';
  /**
   * Maximum file size in MB.
   */
  @Input()
  set maxFileSize(maxFileSize: number | undefined) {
    this._maxFileSize = maxFileSize ?? 25;
  }
  get maxFileSize(): number {
    return this._maxFileSize ?? 25;
  }
  /**
   * Maximum file size of all files in MB.
   */
  @Input() maxFileSizeSum = 50;
  @Input() maxFileCount = 25;
  @Input() existingFiles: FileAttachment[] = [];

  @Input() expandedByDefault: boolean = false;
  @Input() isAttachmentUploadActive: boolean = true;

  /**
   * (Optional) Override the shown texts.
   */
  @Input() uploadInstructionInformationText1Key: string = 'Request_Summary_UploadInstructionInformation_Text1';
  @Input() uploadInstructionInformationText2Key: string = 'Request_Summary_UploadInstructionInformation_Text2';
  @Input() uploadInstructionInformationText3Key: string = 'Request_Summary_UploadInstructionInformation_Text3';

  @Output() filesChange = new EventEmitter<UploadFile[]>();

  @ViewChild('fileUploadPanel') fileUploadPanel!: MatExpansionPanel;

  private _maxFileSize?: number = 25;
  files: UploadFile[] = [];
  showDropzone = false;
  lastDropTarget: EventTarget | null = null;
  private eventHandler: Map<string, EventListener> = new Map([
    ['dragenter', this.dragenterHandler.bind(this)],
    ['dragleave', this.dragleaveHandler.bind(this)],
    ['dragover', this.dragoverHandler.bind(this)],
    ['drop', this.dropHandler.bind(this)]
  ]);

  constructor(
    private fileUploadService: FileUploadService,
    private alertService: AlertService,
    private translocoService: TranslocoService,
    private tenantSettingsService: TenantSettingsService
  ) {}

  ngOnInit() {
    this.initializeFileTypes();
    this.registerWindowEvents();
    this.mapExistingFiles();
  }

  ngOnDestroy() {
    this.removeWindowEvents();
  }

  get hasNotUploadedFiles() {
    return this.files.some((file) => !file.uploaded);
  }

  get hasNotUploadedValidFiles() {
    return this.files.some((file) => !file.uploaded && !file.invalid && file.category !== '');
  }

  get currentlyUploadingFiles() {
    return this.files.some((file) => file.uploadInProgress);
  }

  /**
   * Open/Close the panel. Only closable when there are no selected files.
   * @returns true when the toggle was successful
   */
  toggle(): boolean {
    if (!this.fileUploadPanel.expanded || this.files.length === 0) {
      this.fileUploadPanel.toggle();
      return true;
    }

    // Alert user than he has to remove all files before closing
    this.alertService.open({
      message: this.translocoService.translate('Request_Summary_AttachmentsUploaded'),
      actions: {
        confirm: {
          label: this.translocoService.translate('Common_Yes')
        }
      }
    });
    return false;
  }

  /**
   * Fired when the user clicks the `Upload File`-Button.
   */
  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if (files == null) return;
    this.addFiles(files);
    target.value = '';
  }

  async uploadAll() {
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (file.uploaded || file.invalid || file.category === '') continue;
      await this.uploadFileAtIndex(i);
    }
  }

  async uploadFileAtIndex(index: number): Promise<void> {
    const file = this.files[index];
    if (!file?.file || !file.category) {
      return;
    }

    this.files[index].uploadInProgress = true;

    try {
      const uploadedFiles = await firstValueFrom(this.fileUploadService.postFile(this.uploadUrl, file));
      if (uploadedFiles.length > 0) {
        this.files[index].guid = uploadedFiles[0].item1;
        this.files[index].uploaded = true;
      }

      this.files[index].uploadInProgress = false;
      this.filesChange.emit(this.files);
    } catch {
      this.files[index].invalid = true;
      this.files[index].message = this.translocoService.translate('Common_TechnicalError');
      this.files[index].uploadInProgress = false;
      this.filesChange.emit(this.files);
    }
  }

  cancelNotUploadedFiles() {
    for (let i = this.files.length; i--; ) {
      if (this.files[i].uploaded) continue;
      this.deleteFileAtIndex(i);
    }
  }

  deleteFileAtIndex(index: number) {
    const file = this.files[index];
    if (!file) return;

    this.files[index].deleteInProgress = true;

    if (!file.uploaded) {
      // remove it locally
      this.files.splice(index, 1);
      this.filesChange.emit(this.files);
      return;
    }

    // In case the file was already uploaded
    this.fileUploadService.removeFile(this.uploadUrl, file.guid).subscribe({
      next: () => {
        this.files.splice(index, 1);
        this.filesChange.emit(this.files);
      },
      error: (error: HttpErrorResponse) => {
        this.files[index].invalid = true;
        this.files[index].message = this.translocoService.translate('Request_Summary_FileUpload_ResponseError', {
          statusCode: error.status.toString()
        });
        this.files[index].deleteInProgress = false;
        this.filesChange.emit(this.files);
      }
    });
  }

  isButtonDisabled(process: string, uploadFile?: UploadFile): boolean {
    switch (process) {
      case 'fileSelection':
        return !this.isAttachmentUploadActive;
      case 'selectCategory':
        return uploadFile
          ? uploadFile.uploaded || uploadFile.invalid || uploadFile.uploadInProgress || !this.isAttachmentUploadActive
          : false;
      case 'upload':
        return uploadFile
          ? uploadFile.category === '' ||
              uploadFile.invalid ||
              uploadFile.uploaded ||
              uploadFile.uploadInProgress ||
              !this.isAttachmentUploadActive
          : false;
      case 'uploadAll':
        return !this.hasNotUploadedValidFiles || this.currentlyUploadingFiles || !this.isAttachmentUploadActive;
      case 'cancelNotUploadedFiles':
        return !this.hasNotUploadedFiles || this.currentlyUploadingFiles || !this.isAttachmentUploadActive;
      case 'delete':
        return uploadFile
          ? uploadFile.uploadInProgress ||
              uploadFile.deleteInProgress ||
              uploadFile.isReadOnly ||
              !this.isAttachmentUploadActive
          : false;
      default:
        return false;
    }
  }

  private addFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file == null) continue;

      const { invalid, message } = this.isFileInvalid(file);
      this.files.push({
        file: file,
        filename: file.name,
        contentLength: file.size,
        guid: '',
        category: '',
        uploaded: false,
        uploadInProgress: false,
        deleteInProgress: false,
        invalid,
        message,
        isReadOnly: false
      });
      this.filesChange.emit(this.files);
    }
  }

  /**
   * Determine if the file fulfills the size and type requirements.
   */
  private isFileInvalid(file: File) {
    if (this.files.length >= this.maxFileCount) {
      return {
        invalid: true,
        message: this.translocoService.translate('Request_Summary_FileUpload_MaxFilesExceeded')
      };
    }

    const fileType = file.name.split('.').pop()?.toLocaleLowerCase();

    if (!fileType || !this.acceptedFileTypesDisplay.toLocaleLowerCase().includes(fileType)) {
      return {
        invalid: true,
        message: this.translocoService.translate('Request_Summary_FileUpload_InvalidFileType')
      };
    }

    const fileSizeMB = file.size / (1024 * 1024);
    if (fileSizeMB > this.maxFileSize) {
      const message = this.translocoService.translate('Request_Summary_FileUpload_FileTooBig', {
        filesize: fileSizeMB.toFixed(2).toString(),
        maxFilesize: this.maxFileSize.toString()
      });

      return {
        invalid: true,
        message
      };
    }

    const sumFileSize = this.files.reduce(
      (previousValue, currentValue) => previousValue + currentValue.contentLength,
      file.size
    );

    if (sumFileSize >= this.maxFileSizeSum * 1024 * 1024) {
      return {
        invalid: true,
        message: this.translocoService.translate('Request_Summary_FileUpload_MaxFilesExceeded')
      };
    }

    return {
      invalid: false,
      message: ''
    };
  }

  /**
   * Determine if the DropEvent contains a file
   */
  private isFile(evt: DragEvent) {
    const dt = evt.dataTransfer;
    if (dt == null) return false;

    for (const type of dt.types) {
      if (type === 'Files') {
        return true;
      }
    }
    return false;
  }

  /**
   * Register the global window events used for the dropzone.
   */
  private registerWindowEvents() {
    for (const [event, handler] of this.eventHandler) {
      window.addEventListener(event, handler);
    }
  }

  /**
   * Remove the global window events used for the dropzone.
   */
  private removeWindowEvents() {
    for (const [event, handler] of this.eventHandler) {
      window.removeEventListener(event, handler);
    }
  }

  private dragenterHandler(e: Event) {
    if (this.isFile(e as DragEvent)) {
      this.lastDropTarget = e.target;
      this.showDropzone = true;
    }
  }

  private dragleaveHandler(e: Event) {
    e.preventDefault();
    if (e.target === this.lastDropTarget || e.target === document) {
      this.showDropzone = false;
    }
  }

  private dragoverHandler(e: Event) {
    e.preventDefault();
  }

  private dropHandler(e: Event) {
    e.preventDefault();
    this.showDropzone = false;
    const files = (<DragEvent>e).dataTransfer?.files;
    if (files == null) return;
    this.addFiles(files);

    if (!this.fileUploadPanel.expanded) {
      this.fileUploadPanel.toggle();
    }
  }

  private mapExistingFiles() {
    for (const existingFile of this.existingFiles) {
      this.files.push({
        file: null,
        filename: existingFile.filename,
        contentLength: existingFile.contentLength,
        guid: existingFile.uid,
        category: existingFile.fileCategory.value,
        uploaded: true,
        uploadInProgress: false,
        deleteInProgress: false,
        invalid: false,
        message: '',
        isReadOnly: existingFile.isReadOnly
      });
    }
  }

  private initializeFileTypes(): void {
    if (this.acceptedFileTypesDisplay == '') {
      this.acceptedFileTypesDisplay = this.tenantSettingsService.allowedFileTypes
        .split('|')
        .map((a) => this.translocoService.translate(`Common_FileExtension_${a}`))
        .join(',');
    }
  }
}
