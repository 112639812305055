import { Injectable } from '@angular/core';
import { ContextDataService } from '../context-data';
import { FormatNumericStringOptions } from './locale-format.types';

@Injectable({ providedIn: 'root' })
export class LocaleFormatService {
  private formatter!: Intl.NumberFormat;

  constructor(private contextDataService: ContextDataService) {
    this.setupFormatter();
  }

  setupFormatter(
    preferredLanguage: string | undefined = undefined,
    forcedFractionDigits: number | undefined = undefined,
    removeThousandsSeparator: boolean = false,
    currencySymbol: string | undefined = undefined
  ) {
    this.formatter = new Intl.NumberFormat(this.getLocale(preferredLanguage), {
      currency: currencySymbol,
      minimumFractionDigits: forcedFractionDigits ?? 0,
      maximumFractionDigits: forcedFractionDigits ?? 2,
      useGrouping: !removeThousandsSeparator
    });
  }

  formatNumericStringFromNumber(value?: number, options?: FormatNumericStringOptions): string {
    return this.formatNumericString(value?.toString(), options);
  }

  formatNumericString(value?: string, options?: FormatNumericStringOptions): string {
    if (!value) {
      return '';
    }

    this.setupFormatter(
      options?.preferredLanguage,
      options?.fractionDigits,
      options?.removeThousandsSeparator,
      options?.currencySymbol
    );

    if (options?.fractionDigits) {
      // force fraction Digits
      value = this.formatNumeric(value).toFixed(options?.fractionDigits);
    } else {
      // cut off trailing zeros, but keep fraction digits
      value = this.formatNumeric(value).toString();
    }

    return this.formatter.format(Number(value));
  }

  formatNumericValueFromNumber(value?: number): number {
    return this.formatNumeric(value?.toString());
  }

  /**
   * Takes the input string, and tries to parse it to a string with only a decimal separator, which will be a dot. then parseFloat.
   * the logic is as follows:
   * 1) check if it has one or more thousands and one decimal separator in the right spacing. if yes, remove thousands separators.
   * 2) check if it has either only one separator left (that must then be the decimal one) and replace that with a dot.
   * 3) if more than 1 separator left (or none) check if those are thousands separators. if yes, remove all of them, since it is an integer value.
   * parseFloat of the result. it will either be NaN (if the input string was invalid, like '$&§'), or the desired Number.
   * @param value the number string that is to be converted
   * @returns a number that is the result of the conversion.
   */
  formatNumeric(value?: string): number {
    if (!value) {
      return NaN;
    }
    // thousand and decimal sep => remove thousands, keeping the decimal sep.
    if (this.numberHasThousandAndDecimalSeparator(value)) {
      value = this.removeThousandsSeparators(value);
    }
    // now it either has only thousands separators and no decimal separator, or only exactly one separator, which is the decimal sep. (or none at all)
    if (this.numberHasOnlyDecimalSeparator(value)) {
      value = value.replace(',', '.');
    } else if (this.numberHasOnlyThousandsSeparators(value)) {
      // if it only has thousands separators (meaning more than one of the same separator with 3 digits in between each, and no other separator), we can safely remove all separators.
      value = value.replaceAll(',', '').replaceAll('.', '').replaceAll(' ', '');
    }

    return parseFloat(value);
  }

  private removeThousandsSeparators(value: string): string {
    const decimalSeparatorIndex = this.getDecimalSeparatorIndex(value);
    const preDecimalPart = value.slice(0, decimalSeparatorIndex).replaceAll(',', ' ').replaceAll('.', ' '); // Remove all separators from string
    const decimalPart = value.slice(decimalSeparatorIndex);
    return (preDecimalPart + decimalPart).replaceAll(' ', '');
  }

  private getDecimalSeparatorIndex(value: string): number {
    const validDecimalSeparators = [',', '.'];
    return Math.max(...validDecimalSeparators.map((sep) => value.lastIndexOf(sep)));
  }

  private getLocale(preferredLanguage?: string): string {
    return (
      preferredLanguage ||
      this.contextDataService.data.languageInformation.userLanguage ||
      this.contextDataService.data.languageInformation.defaultLanguage
    );
  }

  private numberHasThousandAndDecimalSeparator(value: string): boolean {
    // optional "-", then one to 3 numbers followed by a dot, comma or space. that symbol is remembered.
    // then 0 to n types 3 numbers followed by that separator (must be thousands sep)
    // then exactly once 3 numbers, that must not be followed by the thousands separator(<tt>), and then either comma or dot. then 1 to n numbers (decimals)
    const numberFormat = /^-?(\d{1,3}(?<tt>[,. ]))?((\d{3}\k<tt>)*(\d{3}(?!\k<tt>)[,.]))\d+$/;
    return numberFormat.test(value);
  }

  private numberHasOnlyThousandsSeparators(value: string): boolean {
    // optional "-", then one to 3 numbers followed by a dot, comma or space. that symbol is remembered.
    // then 0 to n times 3 numbers and that symbol can repeat, then can follow 0 or 1 times 3 numbers and must not end with the original separator.
    const numberFormat = /^-?(\d{1,3}(?<tt>[,. ]))((\d{3}\k<tt>)*(\d{3}(?!\k<tt>)))$/;

    return numberFormat.test(value);
  }

  private numberHasOnlyDecimalSeparator(value: string): boolean {
    // optional "-", then one or more numbers, then comma or dot, then one or more numbers.
    const numberFormat = /^-?\d+([,.])\d+$/;

    return numberFormat.test(value);
  }
}
