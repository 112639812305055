import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { InputWithValidationModule } from 'app/components/input-with-validation/input-with-validation.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { BaseProductHourlyRatesComponent } from './base-product-hourly-rates/base-product-hourly-rates.component';
import { CategorizedHourlyRatesComponent } from './categorized-hourly-rates/categorized-hourly-rates.component';
import { GuidelinesPanelComponent } from './guidelines-panel/guidelines-panel.component';
import { SelfServiceComponent } from './self-service';
import { SelfServiceStatusLabelComponent } from './self-service/self-service-status-label/self-service-status-label.component';
import { UserProfileComponent } from './user-profile.component';

@NgModule({
  imports: [
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    PipesModule,
    ValidationSummaryModule,
    SharedModule,
    InputWithValidationModule
  ],
  exports: [],
  declarations: [
    UserProfileComponent,
    GuidelinesPanelComponent,
    CategorizedHourlyRatesComponent,
    BaseProductHourlyRatesComponent,
    SelfServiceComponent,
    SelfServiceStatusLabelComponent
  ],
  providers: []
})
export class UserProfileModule {}
