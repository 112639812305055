import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { FilterModule } from 'app/components/filter/filter.module';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { PaginationModule } from 'app/components/pagination/pagination.module';
import { SortModule } from 'app/components/sort/sort.module';
import { InboxComponent } from './inbox.component';

@NgModule({
  imports: [
    FilterModule,
    SortModule,
    PaginationModule,
    PageHeaderModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    PipesModule,
    SharedModule
  ],
  declarations: [InboxComponent]
})
export class InboxModule {}
