import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '@core/modules';
import { ErrorHandlingModule } from '@core/services';
import { PageHeaderModule } from 'app/components/page-header';
import { ForbiddenComponent } from './forbidden.component';

@NgModule({
  imports: [ErrorHandlingModule, PageHeaderModule, MatButtonModule, SharedModule],
  declarations: [ForbiddenComponent]
})
export class ForbiddenModule {}
