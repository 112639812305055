import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { Dashboard, ProductType } from '../dashboard.types';

@Component({
  selector: 'contract-panel',
  templateUrl: './contract-panel.component.html',
  styleUrls: ['./contract-panel.component.scss']
})
export class ContractPanelComponent {
  @Input() dashboardData!: Dashboard;
  @Output() selectedContract = new EventEmitter<number>();

  // Static helper methods
  productTypesFromProduct = DashboardService.productTypesFromProduct;

  constructor(public dashboardService: DashboardService) {}

  contractSelected(contractIndex: number) {
    this.selectedContract.emit(contractIndex);
  }

  startRequestForProductType(productType: ProductType): void {
    if (!this.dashboardData.isReadOnly) {
      this.dashboardService.getActionForProductType(productType);
    }
  }
}
