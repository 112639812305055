<div *transloco="let t" class="flex center-center">
  <div class="flex-col flex-grow-66 payment-choose-panel">
    <mat-card>
      <mat-card-title class="ui-test--payment-choose-panel-mat-card-title-info-header">
        {{ t('PaymentInformation_OutstandingPayments_Info_Headline') | stringFormat: t('Common_' + productType)}}
      </mat-card-title>
      <mat-card-content>
        <ng-container *ngIf="subTypes.length > 1">
          <p class="ui-test--payment-choose-panel-paragraph-choose-subtype">
            {{ t('PaymentInformation_OutstandingPayments_PleaseChooseSubtype') }}
          </p>
          <mat-radio-group
            [(ngModel)]="paymentInformation.selectedSubtype"
            (change)="subTypeSelection($event.value)"
            class="flex flex-col mb-10">
            <mat-radio-button
              class="m-5"
              *ngFor="let subType of subTypes; let i = index"
              [class]="'ui-test--payment-choose-panel-mat-radio-button-option-' + i"
              [value]="subType.subtype"
              [disabled]="paymentInformation.paymentSelectionLocked">
              {{ subType.text }}
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>

        <p class="ui-test--payment-choose-panel-paragraph-outstanding-payments-info">
          {{t('PaymentInformation_OutstandingPayments_Info_Text1')}}
        </p>

        <div class="payment-choose-payment-amount ui-test--payment-choose-panel-div-amount">
          {{ selectedOutstandingAmount | currencyOrDash }} €
        </div>

        <p class="ui-test--payment-choose-panel-paragraph-outstanding-payments-info-2">
          {{ t('PaymentInformation_OutstandingPayments_Info_Text2') }}
        </p>
      </mat-card-content>
    </mat-card>

    <div class="flex-row justify-start align-start">
      <div class="flex-col flex-auto">
        <label id="payment-choose-radio-label" class="ui-test--payment-choose-panel-label-option">
          Payment Option:
        </label>
        <mat-radio-group
          [disabled]="paymentInformation.paymentSelectionLocked"
          aria-labelledby="payment-choose-radio-label"
          class="flex-col payment-choose-radio-group"
          [(ngModel)]="paymentInformation.selectedPaymentOption">
          <mat-radio-button
            class="m-5"
            *ngFor="let paymentOption of paymentInformation.paymentOptions; let i = index"
            [class]="'ui-test--payment-choose-panel-mat-radio-button-option-' + i"
            [value]="paymentOption">
            {{ t('Common_PaymentOptions_' + paymentOption) }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div
        class="flex flex-grow-50 payment-choose-payment-warning"
        *ngIf="isBlockingPaymentSelected() && paymentInformation.paymentSelectionLocked">
        <strong class="ui-test--payment-choose-panel-strong-blocking-payment-option-label">
          {{ t('PaymentInformation_BlockingPaymentOption_Headline') }}
        </strong>
        <p class="ui-test--payment-choose-panel-paragraph-blocking-payment-option">
          {{ t('PaymentInformation_BlockingPaymentOption_Text') }}
        </p>
      </div>
    </div>

    <div class="flex flex-row justify-end mt-20">
      <button
        *ngIf="isBlockingPaymentSelected() && !paymentInformation.paymentSelectionLocked"
        class="item-start ui-test--payment-choose-panel-button-blocking-payment-confirm"
        mat-raised-button
        color="primary"
        (click)="sendPaymentInformation()">
        {{t('PaymentInformation_BlockingPayment_Confirm')}}
      </button>

      <button
        *ngIf="!isBlockingPaymentSelected() && !paymentInformation.paymentSelectionLocked"
        [disabled]="!paymentInformation.selectedPaymentOption || (userService.isSendingRestricted | async)"
        class="item-start ui-test--payment-choose-panel-button-action-confirm"
        mat-raised-button
        class="item-end success ui-test--payment-choose-panel-button-action-confirm"
        (click)="sendPaymentInformation()">
        {{t('PaymentInformation_Action_Confirm')}}
      </button>

      <button
        *ngIf="paymentInformation.paymentSelectionLocked"
        class="item-start ui-test--payment-choose-panel-button-action-unlock-selection"
        mat-stroked-button
        color="default"
        (click)="unlockSelection()">
        <mat-icon class="ui-test--payment-choose-panel-mat-icon-action-unlock-selection">lock_open</mat-icon>
        {{t('PaymentInformation_Action_UnlockSelection')}}
      </button>
    </div>
  </div>
</div>
