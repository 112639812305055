import { Injectable } from '@angular/core';
import { v4 as GUID } from 'uuid';

@Injectable({ providedIn: 'root' })
export class CorrelationIdService {
  private _correlationId: string = GUID();

  get correlationId() {
    return this._correlationId;
  }
}
