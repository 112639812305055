import { Component, OnInit } from '@angular/core';
import { LocaleFormatService } from '@core/services';
import { UserProfileService } from '../user-profile.service';
import { BaseProductHourlyRate } from './base-product-hourly-rates.types';

@Component({
  selector: 'base-product-hourly-rates',
  templateUrl: './base-product-hourly-rates.component.html'
})
export class BaseProductHourlyRatesComponent implements OnInit {
  baseProductHourlyRates!: BaseProductHourlyRate;
  selectedBaseProduct!: string;
  displayedColumns: string[] = ['dealerNumber', 'netWagePerHourPrice', 'dropdownChoose'];

  constructor(private userProfileService: UserProfileService, private localeFormatService: LocaleFormatService) {}

  ngOnInit() {
    this.loadBaseProductHourlyRates();
  }

  loadBaseProductHourlyRates() {
    this.userProfileService.loadBaseProductHourlyRates().subscribe({
      next: (res: BaseProductHourlyRate) => {
        this.baseProductHourlyRates = res;
        this.selectedBaseProduct = this.baseProductHourlyRates.baseProductNames[0]?.value;
      }
    });
  }

  getBaseProductHourlyRate(dealerNumber: string, dealerBrand: string, currencySymbol: string) {
    const entryForDealer = this.baseProductHourlyRates.entries.find(
      (entry) => entry.dealerNumber === dealerNumber && entry.dealerBrand === dealerBrand
    );
    const match = entryForDealer?.prices.find((price) => price.baseProductName === this.selectedBaseProduct);

    if (match?.price) {
      return `${this.localeFormatService.formatNumericValueFromNumber(match?.price.value)} ${currencySymbol}`;
    } else {
      return '–';
    }
  }
}
