import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextData } from '@core/services';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Memoize } from 'typescript-memoize';
import { BaseProductHourlyRate } from './base-product-hourly-rates/base-product-hourly-rates.types';
import { CategorizedHourlyRate } from './categorized-hourly-rates/categorized-hourly-rates.types';
import { DealerCommunicationData } from './self-service/self-service.types';
import { Timezone, UserProfile } from './user-profile.types';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  constructor(private _httpClient: HttpClient) {}

  getAvailableLanguages() {
    return this._httpClient.get<string[]>(`${environment.isportCoreApiUrl}/api/settings/languages`);
  }

  @Memoize()
  getTimezones() {
    return this._httpClient.get<Timezone[]>(`${environment.isportCoreApiUrl}/api/settings/timezones`);
  }

  save(userProfileData: UserProfile) {
    return this._httpClient.post<ContextData>(`${environment.isportCoreApiUrl}/api/user/profile`, userProfileData);
  }

  loadUserProfile(tenantName: string, serviceAdvisorId: string) {
    return this._httpClient.get<UserProfile>(
      `${environment.isportCoreApiUrl}/api/user/profile?tenantName=${tenantName}&serviceAdvisorId=${serviceAdvisorId}`
    );
  }

  loadCategorizedHourlyRate() {
    return this._httpClient.get<CategorizedHourlyRate>(`${environment.isportCoreApiUrl}/api/hourlyrate/categorized`);
  }

  loadBaseProductHourlyRates() {
    return this._httpClient.get<BaseProductHourlyRate>(`${environment.isportCoreApiUrl}/api/hourlyrate/baseproduct`);
  }

  loadSelfServiceData() {
    return this._httpClient.get<DealerCommunicationData[]>(`${environment.isportCoreApiUrl}/api/self-service`);
  }

  sendSelfServiceData(data: DealerCommunicationData[]): Observable<void> {
    return this._httpClient.post<void>(`${environment.isportCoreApiUrl}/api/self-service`, data);
  }
}
