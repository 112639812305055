import { NgModule } from '@angular/core';
import { ClickStopPropagationDirective } from './click-stop-propagation.directive';
import { InputTrimDirective } from './input-trim.directive';

@NgModule({
  imports: [],
  exports: [InputTrimDirective, ClickStopPropagationDirective],
  declarations: [InputTrimDirective, ClickStopPropagationDirective],
  providers: []
})
export class DirectivesModule {}
