import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { Contract, Dashboard, ProductType } from '../dashboard.types';

@Component({
  selector: 'contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardContractDetailsComponent {
  @Input() dashboardData!: Dashboard;
  @Input() contract!: Contract;

  constructor(public dashboardService: DashboardService) {}

  startRequestForProductType(productType: ProductType): void {
    if (!this.dashboardData.isReadOnly) {
      this.dashboardService.getActionForProductType(productType);
    }
  }
}
