<mat-form-field class="w-full">
  <mat-label class="ui-test--datepicker-mat-label-placeholder">{{ placeholderTextValue }}</mat-label>
  <input
    class="ui-test--datepicker-input-datepicker"
    matInput
    [required]="required"
    [matDatepicker]="datePicker"
    [min]="minDate"
    [max]="maxDate"
    [placeholder]="placeholderTextValue"
    [formControl]="control"
    maxlength="10"
    (focus)="onDatePickerFocused($event)"
    (keydown)="onDatePickerKeydown($event)"
    (blur)="onDatePickerBlur($event)"
    [name]="name" />
  <mat-datepicker-toggle matSuffix [for]="datePicker" tabindex="-1"></mat-datepicker-toggle>
  <mat-hint class="ui-test--datepicker-mat-hint-mat-hint-text" *ngIf="hintTextValue">{{ hintTextValue }}</mat-hint>
  <mat-datepicker class="ui-test--datepicker-mat-datepicker-datepicker" #datePicker></mat-datepicker>
</mat-form-field>
