<div class="mt-20" *transloco="let t">
  <h3 class="mb-10 section-title ui-test--sdd-invoice-history-header-title">
    {{ t('StatusDetails_InvoiceHistory_Title') }}
  </h3>
  <ul class="timeline">
    <li class="timeline-inverted" *ngFor="let invoice of statusDetails.invoiceHistory; let i = index">
      <mat-icon class="timeline-badge" [class]="'ui-test--sdd-invoice-history-mat-icon-today' + i">today</mat-icon>
      <mat-card class="invoice-history-card mb-10 timeline-panel">
        <mat-card-title [class]="'ui-test--sdd-invoice-history-mat-card-title-invoice-number-' + i">
          {{ invoice.invoiceNumber }}
        </mat-card-title>
        <mat-card-subtitle
          class="flex-row justify-start content-center"
          [class]="'ui-test--sdd-invoice-history-mat-card-subtitle-date-' + i">
          <mat-icon [class]="'ui-test--sdd-invoice-history-mat-card-subtitle-mat-icon-today-' + i">today</mat-icon>
          {{ invoice.transmissionDate | dateFormat:'DATETIME_SHORT_WITH_SECONDS' }}
        </mat-card-subtitle>
        <mat-card-content class="timeline-body">
          <invoice-state-label
            [invoiceState]="invoice.invoiceState"
            [class]="'ui-test--sdd-invoice-history-invoice-state-label-invoice-state-' + i"></invoice-state-label>
          <ul>
            <ul *ngFor="let invoiceNote of invoice.invoiceNotes; let index = index">
              <li
                [innerHtml]="invoiceNote.text | nl2br"
                [class]="'ui-test--sdd-invoice-history-list-text-' + index + '-section-' + i"></li>
            </ul>
          </ul>
        </mat-card-content>
      </mat-card>
    </li>
  </ul>
</div>
