import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationService, LocaleFormatService, RequestStateStore, TenantSettingsService } from '@core/services';
import { DamageType } from 'app/components/positions';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { sort } from 'app/components/sort/sort.helper';
import { UnsavedChangesStateStore } from 'app/pages/request';
import { RequestService } from 'app/pages/request/request.service';
import { GenericPositionComponent } from '../generic-position/generic-position.component';
import { TirePositionService } from './tire-position.service';
import {
  CarTirePositions,
  MotorcycleTirePositions,
  TireCommon,
  TirePriceCommon,
  TireSelection,
  VehicleTirePosition
} from './tire-position.types';
import { TireSearchDialogComponent } from './tire-search-dialog/tire-search-dialog.component';

@Component({
  selector: 'tire-position',
  templateUrl: './tire-position.component.html',
  styleUrls: ['./tire-position.component.scss']
})
export class TirePositionComponent extends GenericPositionComponent implements OnInit {
  loadingTireOptions = false;
  /**
   * Possible Tires to select in the TireSelection Autocomplete.
   * Loaded from the backend as soon as the user types more than three characters.
   */
  tireOptions: TireSelection[] = [];
  /**
   * ngModel Binding for the TireSelection
   */
  selectedTire: TireSelection | string = '';

  constructor(
    formBuilder: UntypedFormBuilder,
    positionConfigurationService: PositionConfigurationService,
    requestService: RequestService,
    private tirePositionService: TirePositionService,
    private dialog: MatDialog,
    unsavedChangesStateStore: UnsavedChangesStateStore,
    localeFormatService: LocaleFormatService,
    requestStore: RequestStateStore,
    configurationService: ConfigurationService,
    tenantSettingsService: TenantSettingsService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(
      formBuilder,
      positionConfigurationService,
      12,
      requestStore,
      unsavedChangesStateStore,
      requestService,
      localeFormatService,
      configurationService,
      tenantSettingsService,
      changeDetector,
      elementRef
    );
  }

  override ngOnInit() {
    super.ngOnInit();
    // populate selectedTire with the minimum data that is shown
    this.selectedTire = { externalProductNumber: this.positionData.externalProductNumber } as TireSelection;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Override inherited logic for isNetFixedPriceDisabled
   */
  override get isNetFixedPriceDisabled(): boolean {
    return this.hasUnitPrice;
  }

  get vehicleType() {
    return this.requestContent.requestProcess.generalInformation.vehicleType;
  }

  get tireReplacements() {
    return this.positionData.tireReplacements;
  }

  get displayDamageType() {
    const damageType = this.requestDamageTypes.find((dt) => dt.id == this.positionData.damageTypeId);
    if (!damageType) {
      return '';
    }

    return damageType.displayText;
  }

  get getTireQuantity() {
    if (!this.positionData.tireReplacements) {
      return 0;
    }

    let quantity = 0;
    const { frontLeft, frontRight, rearLeft, rearRight, rear, front, isDualApplication } =
      this.positionData.tireReplacements;

    if (frontLeft.isChecked) {
      quantity++;
    }

    if (frontRight.isChecked) {
      quantity++;
    }

    if (rearLeft.isChecked) {
      quantity += isDualApplication ? 2 : 1;
    }

    if (rearRight.isChecked) {
      quantity += isDualApplication ? 2 : 1;
    }

    if (rear.isChecked) {
      quantity++;
    }

    if (front.isChecked) {
      quantity++;
    }

    this.positionData.quantity = quantity;

    return quantity > 0 ? quantity : '-';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  asVehicleTirePosition(position: string) {
    return position as VehicleTirePosition;
  }

  /**
   * Used to show only the epn in the tire select.
   */
  tireSelectDisplay(tire: TireSelection) {
    return tire?.externalProductNumber;
  }

  /**
   * Event that fires when the user enters something in the tire select.
   * Loads tire options for the dropdown where the user can select a tire.
   */
  tireDropdownKeyup(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    if (target.value.length < 6) {
      return;
    }

    this.loadingTireOptions = true;

    const activityTypeKeys = this.specificAvailableActivityTypes()
      .filter((activityType) =>
        activityType.damageTypes.some((damageType) => damageType.id === this.positionData.damageTypeId)
      )
      .map((activityType) => {
        return activityType.name;
      })
      .join(',');

    this.tirePositionService
      .loadTireOptions(
        target.value,
        this.requestContent.requestProcess.generalInformation.companyCode,
        activityTypeKeys
      )
      .subscribe((result: TireSelection[]) => {
        this.tireOptions = result;
        this.loadingTireOptions = false;
      });
  }

  /**
   * Event that fires when a user clicks on a specific tire.
   */
  selectedSpecificTire(event: MatAutocompleteSelectedEvent) {
    const tire = event.option.value as TireSelection;
    this.deleteSparePartSubItems();

    if (!tire) {
      this.resetTire();
      return;
    }

    this.loadSpecificTire(tire.externalProductNumber, tire.externalProductNumberSource);
  }

  /**
   * Determines if adding a TireSparePart is allowed.
   */
  canAddSparePartsOnSubItem() {
    if (!this.hasExternalProductNumber || !this.existsTire() || this.isDMS) {
      return false;
    }

    const spareParts = this.filterForRelevantActivities();

    return this.requestContent.insertTireSparepartsOnSubItem && spareParts.length > 0;
  }

  getFilteredDamageTypes(): DamageType[] {
    let damageTypes: DamageType[] | undefined;

    const parentPosition = this.resolveParentPosition();
    if (parentPosition) {
      const parentDamageTypes = this.selectedActivityTypeForPosition(parentPosition)?.damageTypes;

      if (parentDamageTypes && parentDamageTypes.length > 0) {
        damageTypes = this.filterAndSortDamageTypes(parentDamageTypes);
      }
    }

    if (!damageTypes || damageTypes.length === 0) {
      damageTypes = this.sortedDefaultDamageTypes();
    }

    return damageTypes;
  }

  threadDepthDisabled(position: VehicleTirePosition): boolean {
    return (
      !this.isTreadDepthTextBoxEnabled(this.positionData.tireReplacements[position].isChecked) ||
      !this.isTireSelectionEnabled()
    );
  }

  isTreadDepthTextBoxEnabled(isChecked: boolean | undefined) {
    return (
      this.positionData.damageTypeId &&
      this.getDamageTypeNameById(this.positionData.damageTypeId) === 'NormalWearAndTear' &&
      isChecked
    );
  }

  isTireSelectionEnabled() {
    return (
      this.positionData.damageTypeId &&
      this.getDamageTypeNameById(this.positionData.damageTypeId) !== 'FirstTimeUse' &&
      !this.isDMS
    );
  }

  typeOfReplacementChange(damageTypeId: number) {
    if (this.isDMS) {
      return;
    }
    // check for FirstTimeUse with PassengerCar or Motorcycle
    const vehicleType = this.requestContent.requestProcess.generalInformation.vehicleType;

    const isChecked =
      this.getDamageTypeNameById(damageTypeId) === 'FirstTimeUse' &&
      (vehicleType === 'PassengerCar' || vehicleType === 'Motorcycle');

    for (const position of vehicleType === 'Motorcycle' ? MotorcycleTirePositions : CarTirePositions) {
      this.positionData.tireReplacements[position].isChecked = isChecked;
      this.positionData.tireReplacements[position].treadDepth = null;
      this.threadDepthDisabled(position)
        ? this.positionFormGroup.controls[position].disable()
        : this.positionFormGroup.controls[position].enable();
    }

    this.resetTire();
    this.deleteSparePartSubItems();
  }

  tireDepthValueChange(value: string | undefined, position: VehicleTirePosition) {
    const depth = value ? this.ensureInclusiveBetween(value, 0) : null;
    this.positionData.tireReplacements[position].treadDepth = depth;
  }

  checkboxTireDepthChange(event: MatCheckboxChange, position: VehicleTirePosition) {
    this.positionData.tireReplacements[position].isChecked = event.checked;
    this.positionFormGroup.controls[position].reset();
    this.changeDetector.detectChanges();
    this.activateUnsavedChangesPopup();
    if (!this.threadDepthDisabled(position)) {
      this.positionFormGroup.controls[position].enable();
      return;
    }
    this.positionFormGroup.controls[position].disable();
  }

  openTireSearchDialog() {
    const parentPosition = this.resolveParentPosition();
    if (!parentPosition) {
      return;
    }

    const parentActivityType = this.selectedActivityTypeForPosition(parentPosition);
    if (!parentActivityType) {
      return;
    }

    const selectedActivityType = this.selectedActivityType();

    const tireSearchDialog = this.dialog.open(TireSearchDialogComponent);
    tireSearchDialog.componentInstance.requestContent = this.requestContent;
    tireSearchDialog.componentInstance.positionData = this.positionData;
    tireSearchDialog.componentInstance.availableActivityTypes = this.availableActivityTypes;
    tireSearchDialog.componentInstance.specificAvailableActivityTypes = this.specificAvailableActivityTypes();
    tireSearchDialog.componentInstance.parentActivityType = parentActivityType;
    tireSearchDialog.componentInstance.selectedActivityType = selectedActivityType;

    tireSearchDialog.afterClosed().subscribe((tire: TireSelection) => {
      if (!tire) {
        return;
      }
      this.selectedTire = tire;
      this.deleteSparePartSubItems();
      this.loadSpecificTire(tire.externalProductNumber, tire.externalProductNumberSource);
    });
  }

  override addSparePart(): void {
    super.addSparePart('TireSparePart');
  }

  resetTire() {
    this.positionData.externalProductNumberSource = '';
    this.positionData.externalProductNumber = '';
    this.tireOptions = [];
    this.selectedTire = '';
    this.positionData.tire = undefined;
    this.editUnitPrice(0);
    this.activateUnsavedChangesPopup();
  }

  activateUnsavedChangesPopup() {
    this.unsavedChangesStateStore.addEventMessage('Dirty');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private loadSpecificTire(externalProductNumber: string, externalProductNumberSource: string) {
    this.loadingTireOptions = true;

    this.positionData.externalProductNumberSource = externalProductNumberSource;
    this.positionData.externalProductNumber = externalProductNumber;

    this.tirePositionService
      .loadSpecificTire(
        externalProductNumber,
        externalProductNumberSource,
        this.requestContent.requestProcess.generalInformation.companyCode
      )
      .subscribe({
        next: (tire: TireCommon) => {
          this.loadingTireOptions = false;
          if (!tire) {
            this.resetTire();
            return;
          }
          // Update the corresponding ActivityType in the TirePosition
          const activityType = this.getActivityTypeByKey(tire.activityTypeKey);
          // in case the activityTpe is not available, reset the inputs
          if (!activityType) {
            this.resetTire();
            return;
          }

          this.positionData.tire = tire;
          /* We also have to map the tire into the extensionData object to avoid posting the old tire back to the BL in case of updates.
          / The whole construct of the tire position containing tire and extensionData.tire
          needs to be refactored with US 449359 since the tire property is used in FE and the extensionData.tire is used in BL.*/
          this.positionData.extensionData.tire = tire;
          this.editActivityTypeKeyWithClass(activityType.id);
          this.loadTirePrice(tire.databaseId);
        },
        error: () => {
          this.resetTire();
          this.loadingTireOptions = false;
        }
      });
  }

  private loadTirePrice(tireDefinitionId: number): void {
    this.tirePositionService.loadTirePrice(tireDefinitionId).subscribe({
      next: (tirePrice: TirePriceCommon) => {
        this.positionData.unitPrice = tirePrice?.price;
      }
    });
  }

  private filterForRelevantActivities() {
    let filteredActivityTypes = [];

    let restrictByClass = true;
    const activityTypeClass = this.positionData.activityTypeClassKey;
    const itemType = 'TireSparePart';
    const selectedActivityType = this.selectedActivityType();

    if (this.hasExternalProductNumber && this.isValidExternalProductNumber) {
      restrictByClass = false;
    }

    const allActivityTypes = this.requestAvailableActivityTypes;

    if (!activityTypeClass && restrictByClass) {
      return [];
    }

    for (const current of allActivityTypes) {
      if (current.itemType !== itemType) {
        continue;
      }

      let isRelationCheckOk = false;

      if (
        selectedActivityType &&
        selectedActivityType.activityTypeKeys &&
        selectedActivityType.activityTypeKeys.length > 0
      ) {
        isRelationCheckOk =
          selectedActivityType.activityTypeKeys.find((activityType) => activityType == current.name) != null;
      } else {
        isRelationCheckOk = true;
      }

      if (!isRelationCheckOk) {
        continue;
      }

      if (restrictByClass) {
        for (const currentAtc of current.activityTypeClasses) {
          if (currentAtc.id == activityTypeClass) {
            filteredActivityTypes.push(current);
          }
        }
      } else {
        filteredActivityTypes.push(current);
      }
    }

    filteredActivityTypes = sort(
      filteredActivityTypes,
      'description',
      this.requestContent.isActivityTypesSortingAscending ? 'asc' : 'desc'
    );
    return filteredActivityTypes;
  }

  private deleteSparePartSubItems() {
    const children = this.positionData.subitems;
    if (!children || children.length == 0) {
      return;
    }

    children.forEach((child) => {
      const position = this.requestContent.requestPositions.find(
        (requestPosition) => requestPosition.positionId === child.positionId
      );

      if (!position || position.isPositionMarkedAsDeleted) {
        return;
      }

      this.removedPosition.emit(position);
    });
  }

  private existsTire(): boolean {
    return !!this.positionData.tire?.databaseId;
  }
}
