import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TenantSettingsService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import * as saveAs from 'file-saver';
import { FileAttachment, StatusDetails } from '../status-details-dialog.types';
import { AttachmentsService } from './attachments.service';

@Component({
  selector: 'status-details-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatusDetailsAttachmentsComponent implements OnInit {
  @Input() statusDetails!: StatusDetails;

  displayedColumns: string[] = ['filename', 'fileCategory', 'createdDate'];
  dataSource!: MatTableDataSource<FileAttachment>;
  defaultSort: Sort = {
    active: this.tenantSettings.attachmentsSortingValue == 'Timestamp' ? 'createdDate' : 'filename',
    direction: this.tenantSettings.shouldAttachmentsSortingAscending ? 'asc' : 'desc'
  };

  constructor(
    private attachmentService: AttachmentsService,
    private translocoService: TranslocoService,
    private tenantSettings: TenantSettingsService
  ) {}

  ngOnInit() {
    // Sort data using default sorting configuration
    this.sortFileAttachments(this.defaultSort);

    this.dataSource = new MatTableDataSource(this.statusDetails.fileAttachments);
  }

  sortFileAttachments(sortState: Sort) {
    this.statusDetails.fileAttachments.sort((a: FileAttachment, b: FileAttachment) => {
      if (sortState.direction === 'asc') {
        switch (sortState.active) {
          case 'filename':
            return a.filename?.localeCompare(b.filename);
          case 'fileCategory':
            return this.translocoService
              .translate(a.fileCategory.resourceKey)
              ?.localeCompare(this.translocoService.translate(b.fileCategory.resourceKey));
          case 'createdDate':
            return a.createdDate?.localeCompare(b.createdDate);
        }
      }
      switch (sortState.active) {
        case 'filename':
          return b.filename?.localeCompare(a.filename);
        case 'fileCategory':
          return this.translocoService
            .translate(b.fileCategory.resourceKey)
            ?.localeCompare(this.translocoService.translate(a.fileCategory.resourceKey));
        case 'createdDate':
          return b.createdDate?.localeCompare(a.createdDate);
      }
      return 0;
    });
    this.dataSource = new MatTableDataSource(this.statusDetails.fileAttachments);
  }

  downloadFile(attachmentGuid: string, filename: string) {
    this.attachmentService.getAttachment(this.statusDetails.requestGuid, attachmentGuid).subscribe((file) => {
      saveAs(file, filename);
    });
  }
}
