import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { ErrorHandlingModule } from '@core/services';
import { EntriesCounterModule } from 'app/components/entries-counter';
import { FilterModule } from 'app/components/filter/filter.module';
import { OverviewModule } from 'app/components/overview/overview.module';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { ScrollTopButtonModule } from 'app/components/scroll-top-button';
import { SortModule } from 'app/components/sort/sort.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { StatusOverviewComponent } from './status-overview.component';

@NgModule({
  imports: [
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    OverviewModule,
    FilterModule,
    ErrorHandlingModule,
    SortModule,
    EntriesCounterModule,
    PageHeaderModule,
    ValidationSummaryModule,
    PipesModule,
    SharedModule,
    InfiniteScrollModule,
    MatProgressBarModule,
    ScrollTopButtonModule
  ],
  exports: [],
  declarations: [StatusOverviewComponent],
  providers: []
})
export class StatusOverviewModule {}
