import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
  constructor(private translocoService: TranslocoService) {
    super();

    this.firstPageLabel = this.translocoService.translate('Common_TablePage_FirstButton');
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      return this.translocoService
        .translate('Common_TablePage_Info')
        .replace('_FIRST_', String(Math.min(page * pageSize + 1, length)))
        .replace('_LAST_', String(Math.min((page + 1) * pageSize, length)))
        .replace('_TOTAL_', String(length));
    };
    this.itemsPerPageLabel = this.translocoService.translate('Common_TablePage_Menue').replace(' _MENU_', '');
    this.lastPageLabel = this.translocoService.translate('Common_TablePage_LastButton');
    this.nextPageLabel = this.translocoService.translate('Common_TablePage_NextButton');
    this.previousPageLabel = this.translocoService.translate('Common_TablePage_PreviousButton');
  }
}
