<div class="vehicle-information" *transloco="let t">
  <mat-card-content>
    <h2 class="subtitle ui-test--vehicle-information-header-title">
      {{t('CallMeBack_CollectData_VehicleInformation')}}
    </h2>
    <div class="flex-row justify-center content-between">
      <div class="flex-grow-30">
        <form [formGroup]="vehicleInformationForm" class="flex-col justify-around content-center">
          <mat-form-field>
            <mat-label class="ui-test--vehicle-information-mat-label-vehicle-identification-number-label">
              {{ t('Common_VehicleIdentificationNumber') }}
            </mat-label>
            <input
              class="ui-test--vehicle-information-input-vehicle-identification-number"
              matInput
              #vehicleIdentificationNumber
              formControlName="vehicleIdentificationNumber"
              id="vehicleIdentificationNumber" />
            <mat-hint align="end" class="ui-test--vehicle-information-mat-hint-hint-length">
              {{vehicleIdentificationNumber.value.length || 0}}/17
            </mat-hint>
          </mat-form-field>

          <mat-form-field>
            <mat-label class="ui-test--vehicle-information-mat-label-license-plate-number-label">
              {{ t('Common_LicensePlateNumber') }}
            </mat-label>
            <input
              class="ui-test--vehicle-information-input-license-plate-number"
              matInput
              formControlName="licensePlateNumber"
              id="licensePlateNumber" />
          </mat-form-field>

          <mat-form-field>
            <mat-label class="ui-test--vehicle-information-mat-label-mileage-label">
              {{ t('Common_Mileage') }}
            </mat-label>
            <input
              class="ui-test--vehicle-information-input-mileage"
              matInput
              formControlName="actualMileage"
              autocomplete="off"
              type="number"
              (focus)="actualMileage.select()"
              #actualMileage />
          </mat-form-field>

          <button
            (click)="resetVehicle()"
            class="reset-button ui-test--vehicle-information-button-reset"
            mat-raised-button
            color="primary"
            [disabled]="!vehicle?.vehicleIdentificationNumber || !vehicle?.licensePlateNumber">
            <mat-icon class="ui-test--vehicle-information-mat-icon-reset" aria-label="Reset icon">
              skip_previous
            </mat-icon>
            <span class="ui-test--vehicle-information-span-reset">{{ t('CallMeBack_CollectData_Reset') }}</span>
          </button>
        </form>
      </div>
    </div>
  </mat-card-content>
</div>
