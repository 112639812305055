<div *transloco="let t">
  <page-header
    [title]="t('RequestDoublet_Title') | stringFormat: t('Common_' + searchParams.get('productType'))"></page-header>
  <validation-summary [errorList]="errorList"></validation-summary>

  <p class="ui-test--request-duplicates-paragraph-information-text">
    {{ t('RequestDoublet_InformationText') | stringFormat: t('Common_' + searchParams.get('productType')):
    period.toString() }}
  </p>
  <sort [sortKeys]="sortKeys" [sortDirection]="sortDirection" (sort)="sort($event)"></sort>
  <pagination
    #paginatorTop
    [pageSize]="5"
    [dataLength]="requestDuplicateData.length"
    (page)="onPaginatorChange($event)"></pagination>

  <ng-container *ngFor="let requestDuplicate of requestDuplicateData; let i = index">
    <mat-card *ngIf="requestDuplicate.show" [class.mat-card-highlighted]="requestDuplicate.isHighlighted" class="my-20">
      <mat-card-content>
        <div class="flex-row justify-between content-center">
          <div class="flex-col flex-grow-25">
            <div class="flex-row justify-start content-end">
              <mat-icon [class]="'ui-test--request-duplicates-mat-icon-product-type-' + i">
                {{ requestDuplicate.productType | productTypeIcon }}
              </mat-icon>
              <strong
                [class]="'ui-test--request-duplicates-strong-request-id-' + i"
                matTooltip="{{ requestDuplicate.displayRequestIdTitle }}"
                matTooltipPosition="above">
                {{ requestDuplicate.displayRequestId }}
              </strong>
            </div>
            <div class="flex-row">
              <request-state-label
                [class]="'ui-test--request-duplicates-request-state-label-request-state' + i"
                [state]="requestDuplicate.requestState"
                [stateText]="requestDuplicate.requestStateText"
                class="flex-row mt-5 flex-grow-80"></request-state-label>
            </div>
          </div>
          <div class="flex-col flex-grow-25">
            <div class="flex-row">
              <strong class="flex flex-grow-50" [class]="'ui-test--request-duplicates-strong-created-label-' + i">
                {{ t('Common_Created') }}:
              </strong>
              <span class="flex flex-1" [class]="'ui-test--request-duplicates-span-created' + i">
                {{ requestDuplicate.createdDate | dateFormat }}
              </span>
            </div>
            <div class="flex-row mt-5">
              <strong class="flex flex-grow-50" [class]="'ui-test--request-duplicates-strong-valid-until-label-' + i">
                {{ t('Common_ValidUntil') }}:
              </strong>
              <span class="flex flex-1" [class]="'ui-test--request-duplicates-span-valid-until-' + i">
                {{ requestDuplicate.validUntil | dateFormat }}
              </span>
            </div>
          </div>
          <div class="flex-col flex-grow-40">
            <div class="flex-row">
              <strong
                class="flex flex-grow-50"
                [class]="'ui-test--request-duplicates-strong-service-advisor-label-' + i">
                {{ t('Common_ServiceAdvisor') }}:
              </strong>
              <span class="flex flex-1" [class]="'ui-test--request-duplicates-span-service-advisor-' + i">
                {{ requestDuplicate.serviceAdvisor }}
              </span>
            </div>
            <div class="flex-row mt-5">
              <strong
                class="flex flex-grow-50"
                [class]="'ui-test--request-duplicates-strong-license-plate-number-label-' + i">
                {{ t('Common_LicensePlateNumber') }}:
              </strong>
              <span class="flex flex-1" [class]="'ui-test--request-duplicates-span-license-platen-number-' + i">
                {{ requestDuplicate.licensePlateNumber }}
              </span>
            </div>
          </div>
          <div class="flex-col justify-center content-end">
            <button
              mat-mini-fab
              class="details-button"
              [class]="'ui-test--request-duplicates-button-details-' + i"
              (click)="openStatusDetailsDialog(requestDuplicate.requestGuid, requestDuplicate.productType)">
              <mat-icon [class]="'ui-test--request-duplicates-mat-icon-details-' + i">summarize</mat-icon>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>

  <pagination
    #paginatorBottom
    [pageSize]="5"
    [dataLength]="requestDuplicateData.length"
    [pageSizeOptions]="[]"
    (page)="onPaginatorChange($event)"></pagination>

  <div class="flex-row justify-end">
    <button
      mat-raised-button
      class="create-request ui-test--request-duplicates-button-create-request"
      color="primary"
      [disabled]="createRequestLoading"
      [class.button-spinner]="createRequestLoading"
      (click)="newRequest()">
      {{ t('Common_CreateRequest') }}
    </button>
  </div>
</div>
