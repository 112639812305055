import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@core/modules';
import { FileUploadModule } from 'app/components/file-upload/file-upload.module';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { ImportantInformationModule } from '../../components/important-information/important-information.module';

import { ContactDataModule } from 'app/components/contact-data/contact-data.module';
import { WorkInstructionsModule } from 'app/components/work-instructions/work-instructions.module';
import { ProcessBarModule } from '../request/shared/process-bar/process-bar.module';
import { CallMeBackComponent } from './call-me-back.component';
import { VehicleInformationModule } from './vehicle-information/vehicle-information.module';

@NgModule({
  imports: [
    ProcessBarModule,
    ContactDataModule,
    FileUploadModule,
    ImportantInformationModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    PageHeaderModule,
    SharedModule,
    ValidationSummaryModule,
    VehicleInformationModule,
    WorkInstructionsModule
  ],
  exports: [],
  declarations: [CallMeBackComponent],
  providers: []
})
export class CallMeBackModule {}
