import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Contract } from '../dashboard/dashboard.types';
import { GetVehicleInformationResponse, SearchIndexViewModel } from './contract-search.types';

@Injectable({ providedIn: 'root' })
export class ContractSearchService {
  constructor(private httpClient: HttpClient) {}

  search(searchParams: URLSearchParams): Observable<Contract[]> {
    const url = `${environment.isportCoreApiUrl}/api/contracts?${searchParams.toString()}`;
    return this.httpClient.get<Contract[]>(url);
  }

  externalSearch(referenceId: string, sourceApplication: string | null): Observable<Contract[] | SearchIndexViewModel> {
    let url = `${environment.isportCoreApiUrl}/api/contracts/external?referenceId=${referenceId}`;
    if (sourceApplication != null) {
      url += `&sourceApplication=${sourceApplication}`;
    }
    return this.httpClient.get<Contract[] | SearchIndexViewModel>(url);
  }

  getVehicleInformation(processId: string, vin: string): Observable<GetVehicleInformationResponse> {
    const url = `${environment.isportCoreApiUrl}/api/vehicles/${processId}/${vin}`;
    return this.httpClient.get<GetVehicleInformationResponse>(url, {});
  }
}
