import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ConfigurationService, LocaleFormatService, RequestStateStore, TenantSettingsService } from '@core/services';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { UnsavedChangesStateStore } from 'app/pages/request';
import { RequestService } from 'app/pages/request/request.service';
import { GenericPositionComponent } from '../generic-position/generic-position.component';

@Component({
  selector: 'work-position',
  templateUrl: './work-position.component.html'
})
export class WorkPositionComponent extends GenericPositionComponent {
  constructor(
    formBuilder: UntypedFormBuilder,
    positionConfigurationService: PositionConfigurationService,
    requestService: RequestService,
    requestStore: RequestStateStore,
    unsavedChangesStateStore: UnsavedChangesStateStore,
    localeFormatService: LocaleFormatService,
    configurationService: ConfigurationService,
    tenantSettingsService: TenantSettingsService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(
      formBuilder,
      positionConfigurationService,
      12,
      requestStore,
      unsavedChangesStateStore,
      requestService,
      localeFormatService,
      configurationService,
      tenantSettingsService,
      changeDetector,
      elementRef
    );
  }

  isChildOfPackagePosition(): boolean {
    if (!this.hasParentPositionId) {
      return false;
    }

    return this.requestContent.requestPositions.some(
      (requestPosition) =>
        requestPosition.laborPositionIndex === this.positionData.laborPositionIndex &&
        requestPosition.templateName === 'PackagePositionViewModel'
    );
  }

  updateActivityTypeKey(value: number): void {
    this.requestStore.addEventMessage({
      eventName: 'ActivityTypeUpdated',
      payload: { activityType: value, positionId: this.positionData.positionId }
    });
  }

  override addSparePart(): void {
    super.addSparePart('SparePart');
  }
}
