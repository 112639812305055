import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(errorHandling);

function errorHandling(err: Error) {
  console.error(err);
  const errorMsgElement = document.querySelector('.error-msg-element');
  const spinner = document.querySelector('.spinner');
  if (!errorMsgElement || !spinner) return;
  spinner.classList.add('display-none');
  let message = 'Application initialization failed';
  if (err) {
    if (err.message) {
      message = message + ': ' + err.message;
    } else {
      message = message + ': ' + err;
    }
  }
  errorMsgElement.textContent = message;
}
