import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { capitalizeFirstLetter } from '@core/utils';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'input-with-validation',
  templateUrl: './input-with-validation.component.html'
})
export class InputWithValidationComponent implements OnInit {
  @Input() resourceKeyPrefix!: string;
  @Input() name: string = '';
  @Input() validators!: ValidatorFn[] | ValidatorFn;
  @Input() showHint: boolean = false;
  @Input() hintResource: string | undefined;
  @Input() control!: FormControl;
  /**
   * optional - allows to set the input element type (e.g., number / text / tel ...)
   */
  @Input() type: string = '';

  public nameFirstLetterCapitalized: string = '';

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.nameFirstLetterCapitalized = capitalizeFirstLetter(this.name);
  }

  getErrorMessage(): string {
    if (!this.control) {
      return '';
    }
    if (this.control.hasError('required')) {
      return this.translocoService.translate('Common_ErrorMessageFor_NotEmpty');
    } else if (this.control.hasError('maxlength')) {
      return this.translocoService.translate('Common_ErrorMessageFor_MaximumLength', {
        MaxLength: this.control.getError('maxlength').requiredLength,
        TotalLength: this.control.value.length.toString()
      });
    } else if (this.control.hasError('pattern')) {
      return this.translocoService.translate('Common_ErrorMessageFor_InvalidFormat');
    } else if (this.control.hasError('email')) {
      return this.translocoService.translate('Common_ErrorMessageFor_Email');
    }

    return '';
  }
}
