import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { DirectivesModule } from '@core/directives';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { DropdownWithSearchModule } from 'app/components/dropdown-with-search/dropdown-with-search.module';
import { RequestTotalAmountsModule } from 'app/components/request-total-amounts/request-total-amounts.module';
import { ScrollTopButtonModule } from 'app/components/scroll-top-button';
import { WorkInstructionsModule } from 'app/components/work-instructions/work-instructions.module';
import { GeneralInformationModule } from '../shared/general-information/general-information.module';
import { ClaimPositionFilterComponent } from './claim-position-filter/claim-position-filter.component';
import { DropdownPredictiveSearchComponent } from './dropdown-predictive-search/dropdown-predictive-search.component';
import { RequestEditComponent } from './edit.component';
import { LeasingReturnConfirmationComponent } from './leasing-return-confirmation/leasing-return-confirmation.component';
import { PaymentChoosePanelComponent } from './payment-choose-panel/payment-choose-panel.component';
import { InputGroupComponent } from './positions/input-group/input-group.component';
import { MobilityPositionComponent } from './positions/mobility-position/mobility-position.component';
import { MobilitySparePartPositionComponent } from './positions/mobility-spare-part-position/mobility-spare-part-position.component';
import { PackagePositionComponent } from './positions/package-position/package-position.component';
import { PositionsComponent } from './positions/positions.component';
import { PositionsTemplateNameDirective } from './positions/positions.directive';
import { PositionIconPipe } from './positions/positions.pipe';
import { SparePartPositionComponent } from './positions/spare-part-position/spare-part-position.component';
import { TirePositionComponent } from './positions/tire-position/tire-position.component';
import { TireSearchDialogComponent } from './positions/tire-position/tire-search-dialog/tire-search-dialog.component';
import { TireServicePositionComponent } from './positions/tire-service-position/tire-service-position.component';
import { TireSparePartPositionComponent } from './positions/tire-spare-part-position/tire-spare-part-position.component';
import { WarrantyDiagnosticPositionComponent } from './positions/warranty/warranty-diagnostic-position/warranty-diagnostic-position.component';
import { WarrantyPositionComponent } from './positions/warranty/warranty-position/warranty-position.component';
import { WarrantySparePartPositionComponent } from './positions/warranty/warranty-spare-part-position/warranty-spare-part-position.component';
import { WorkPositionFilterComponent } from './positions/work-position-filter/work-position-filter.component';
import { WorkPositionComponent } from './positions/work-position/work-position.component';

@NgModule({
  imports: [
    DirectivesModule,
    DropdownWithSearchModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatRadioModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatListModule,
    MatSelectModule,
    MatDialogModule,
    MatDividerModule,
    MatMenuModule,
    WorkInstructionsModule,
    GeneralInformationModule,
    RequestTotalAmountsModule,
    PipesModule,
    ScrollTopButtonModule,
    SharedModule
  ],
  exports: [RequestEditComponent],
  declarations: [
    RequestEditComponent,
    PaymentChoosePanelComponent,
    MobilityPositionComponent,
    PositionsComponent,
    PositionsTemplateNameDirective,
    InputGroupComponent,
    SparePartPositionComponent,
    WorkPositionComponent,
    PackagePositionComponent,
    WarrantyPositionComponent,
    WarrantyDiagnosticPositionComponent,
    WarrantySparePartPositionComponent,
    MobilitySparePartPositionComponent,
    TirePositionComponent,
    TireServicePositionComponent,
    TireSearchDialogComponent,
    TireSparePartPositionComponent,
    ClaimPositionFilterComponent,
    WorkPositionFilterComponent,
    PositionIconPipe,
    LeasingReturnConfirmationComponent,
    DropdownPredictiveSearchComponent
  ],
  providers: []
})
export class RequestEditModule {}
