<ng-container *transloco="let t">
  <h2 class="ui-test--print-header-contract-information">{{ t('Common_ContractInformation')}}</h2>
  <contract-information [contract]="contract" [dashboardData]="dashboardData"></contract-information>

  <ng-container *ngIf="contract.warrantorDealer?.sortedDetailDisplayProperties?.length">
    <h2 class="ui-test--print-header-warrantor-dealer">{{ t('Common_WarrantorDealerInformation')}}</h2>
    <warrantor-dealer [warrantorDealer]="contract.warrantorDealer"></warrantor-dealer>
  </ng-container>

  <ng-container *ngIf="contract.customer?.sortedDetailDisplayProperties?.length">
    <h2 class="ui-test--print-header-customer-information">{{ t('Common_CustomerInformation')}}</h2>
    <customer-information [customer]="contract.customer"></customer-information>
  </ng-container>
  <h2 class="ui-test--print-header-vehicle-information">{{ t('Common_VehicleInformation')}}</h2>
  <vehicle-information [vehicle]="contract.vehicle"></vehicle-information>
</ng-container>
