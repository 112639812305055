<div *transloco="let t">
  <page-header [title]="t('CommonLicence')" class="ui-test--license-pageHeader-title"></page-header>

  <section class="flex-col" *ngFor="let license of licenses, let i = index">
    <h2 [id]="license.id" [class]="'ui-test--license-header-name-' + i">{{ license.name }}</h2>
    <table mat-table attr.aria-describedby="{{ license.id }}" [dataSource]="license.elements" class="w-full mb-10">
      <ng-container matColumnDef="component">
        <th mat-header-cell *matHeaderCellDef [class]="'ui-test--license-th-component-' + i">Component</th>
        <td mat-cell *matCellDef="let element" [class]="'ui-test--license-td-component-' + i + '-' + element.component">
          {{ element.component }}
        </td>
      </ng-container>
      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef [class]="'ui-test--license-th-version-' + i">Version</th>
        <td mat-cell *matCellDef="let element" [class]="'ui-test--license-td-version-' + i + '-' + element.version">
          {{ element.version }}
        </td>
      </ng-container>
      <ng-container matColumnDef="copyright">
        <th mat-header-cell *matHeaderCellDef [class]="'ui-test--license-th-copyright-' + i">Copyright</th>
        <td mat-cell *matCellDef="let element" [class]="'ui-test--license-td-copyright-' + i + '-' + element.copyright">
          {{ element.copyright }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <p [class]="'ui-test--license-paragraph-linkLabel' + i">
      Link to the license conditions:
      <a [href]="license.link" target="_blank" rel="noopener" [class]="'ui-test--license-link-linkName-' + i">
        {{ license.name }}
      </a>
    </p>
    <div class="mt-10" [innerHtml]="license.text" [class]="'ui-test--license-div-text-' + i"></div>
  </section>
</div>
