import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { InputWithValidationModule } from '../input-with-validation/input-with-validation.module';
import { ContactDataComponent } from './contact-data.component';

@NgModule({
  imports: [MatInputModule, MatFormFieldModule, PipesModule, SharedModule, InputWithValidationModule],
  exports: [ContactDataComponent],
  declarations: [ContactDataComponent],
  providers: []
})
export class ContactDataModule {}
