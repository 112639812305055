import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService, ContextDataService, ErrorDialogOptions, ErrorHandlingService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  private pagesExcludedFromErrorHandling: string[] = [
    // do not show on error pages
    '/forbidden',
    '/unauthorized'
  ];

  private apiCallsExcludedFromErrorHandling: string[] = [
    // skip generate context call
    'api/account/generate-context',
    // skip send error mail -> Would cause error dialog over error dialog
    'api/error/sendMail',
    // skip file upload and download
    'attachment',
    // offer panel
    'api/offer',
    // hourly rates
    'api/hourlyrate',
    // announcement
    'api/announcement'
  ];

  constructor(
    private router: Router,
    private translocoService: TranslocoService,
    private configurationService: ConfigurationService,
    private errorHandlingService: ErrorHandlingService,
    private contextDataService: ContextDataService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(catchError((error: HttpErrorResponse) => this.handleErrorResponse(error)));
  }

  /**
   * Handle forbidden (403) and unauthorized (401) Http errors.
   * In the 5xx error case, show the error dialog.
   * @returns Thrown Error with a custom message
   */
  private handleErrorResponse(error: HttpErrorResponse) {
    if (error.status >= 500) {
      if (!this.skipErrorHandling(error)) {
        this.showErrorDialog(error);
      }
      throw error;
    }

    let message = '';
    switch (error.status) {
      case 404:
      case 422:
        this.setContextDataFromHeader(error);
        throw error;
      case 403:
        this.router.navigate(['forbidden']);
        message = 'Access denied.';
        break;
      case 401:
        this.router.navigate(['unauthorized']);
        message = 'User is unauthorized.';
        break;
      default:
        throw error;
    }
    return throwError(() => new Error(message));
  }

  /**
   * Add the cases here, where we do not want to show the error dialog.
   */
  private skipErrorHandling(error: HttpErrorResponse) {
    return (
      this.pagesExcludedFromErrorHandling.includes(this.router.url) ||
      this.apiCallsExcludedFromErrorHandling.some((url) => error.url?.includes(url))
    );
  }

  private showErrorDialog(error: HttpErrorResponse) {
    let options = { closeAllDialogs: true } as ErrorDialogOptions;

    if (error.error?.ExceptionType?.includes('MultipleSessionException')) {
      options = this.getMultipleSessionExceptionOptions();
    }
    if (typeof error.error == 'string') {
      options.errorMessage = error.error.toString();
    }

    this.errorHandlingService.showErrorDialog(error, options);
  }

  private getMultipleSessionExceptionOptions(): ErrorDialogOptions {
    return {
      errorMessage: this.translocoService.translate('Error_SessionMismatch_Message1'),
      title: this.translocoService.translate('Error_SessionMismatch'),
      redirectUrl: this.configurationService.infocenterUrl,
      redirectUrlText: this.translocoService.translate('Common_Infocenter'),
      showContactButton: false,
      isRedirectUrlExternal: true
    } as ErrorDialogOptions;
  }

  private setContextDataFromHeader(error: HttpErrorResponse) {
    const contextDataHeader = error.headers.get('X-ISPORT-REQUEST-CONTEXT-DATA');
    this.contextDataService.extractContextDataFromRequestHeader(contextDataHeader);
  }
}
