<div *transloco="let t">
  <h2 mat-dialog-title class="ui-test--error-contact-form-header-title">{{ t('ErrorContactModal_ModalTitle') }}</h2>

  <mat-dialog-content class="flex-col">
    <p class="my-10 ui-test--error-contact-form-paragraph-description">{{t('ErrorContactModal_Description') }}</p>
    <form class="row flex-col flex-grow-100 center-center" [formGroup]="errorContactForm">
      <mat-form-field class="row w-full">
        <mat-label class="ui-test--error-contact-form-error-contact-form-user-journey">
          {{ t('ErrorContactModal_UserJourneyDescription') }}
        </mat-label>
        <textarea
          class="ui-test--error-contact-form-textarea-user-journey"
          cdkFocusInitial
          matInput
          rows="3"
          autocomplete="off"
          formControlName="userJourneyDescription"
          [placeholder]="t('ErrorContactModal_UserJourneyDescription_Hint')"></textarea>
      </mat-form-field>
      <mat-form-field class="row w-full">
        <mat-label class="ui-test--error-contact-form-mat-label-data-description">
          {{ t('ErrorContactModal_InputDataDescription') }}
        </mat-label>
        <textarea
          class="ui-test--error-contact-form-textarea-data-description"
          matInput
          rows="3"
          autocomplete="off"
          formControlName="inputDataDescription"
          [placeholder]="t('ErrorContactModal_InputDataDescription_Hint')"></textarea>
      </mat-form-field>
      <mat-form-field class="row w-full">
        <mat-label class="ui-test--error-contact-form-mat-label-dealer-id">
          {{ t('ErrorContactModal_DealerId') }}
        </mat-label>
        <input
          class="ui-test--error-contact-form-input-dealer-id"
          matInput
          name="dealerId"
          formControlName="dealerId" />
      </mat-form-field>
      <mat-form-field class="row w-full">
        <mat-label class="ui-test--error-contact-form-mat-label-email">
          {{ t('ErrorContactModal_EmailAddress') }}
        </mat-label>
        <input
          class="ui-test--error-contact-form-input-email"
          matInput
          type="email"
          name="emailAddress"
          formControlName="emailAddress" />
      </mat-form-field>
      <mat-form-field class="row w-full">
        <mat-label class="ui-test--error-contact-form-mat-label-phone-number">
          {{ t('ErrorContactModal_PhoneNumber') }}
        </mat-label>
        <input
          class="ui-test--error-contact-form-input-phone-number"
          matInput
          type="tel"
          name="phoneNumber"
          formControlName="phoneNumber" />
      </mat-form-field>
      <mat-form-field class="row w-full">
        <mat-label class="ui-test--error-contact-form-mat-label-user-id">{{ t('ErrorContactModal_UserId') }}</mat-label>
        <input class="ui-test--error-contact-form-input-user-id" matInput name="userId" formControlName="userId" />
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      class="ui-test--error-contact-form-button-send"
      mat-raised-button
      color="primary"
      [class.button-spinner]="sendMailInProgress"
      [disabled]="sendMailInProgress || mailSent || !errorContactForm.valid"
      (click)="sendErrorMail()">
      {{ t('ErrorContactModal_SendButton') }}
    </button>
    <button class="ui-test--error-contact-form-button-close" mat-button mat-dialog-close>
      {{ t('Common_Close') }}
    </button>
  </mat-dialog-actions>
</div>
