import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '@core/directives';
import { SharedModule } from '@core/modules';
import { InvoiceClarificationHistoryModule } from 'app/components/invoice-clarification-history/invoice-clarification-history.module';
import { PageHeaderModule } from 'app/components/page-header/page-header.module';
import { ValidationSummaryModule } from 'app/components/validation-summary/validation-summary.module';
import { InvoiceClarificationComponent } from './invoice-clarification.component';

@NgModule({
  imports: [
    DirectivesModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    PageHeaderModule,
    ValidationSummaryModule,
    SharedModule,
    InvoiceClarificationHistoryModule
  ],
  exports: [],
  declarations: [InvoiceClarificationComponent],
  providers: []
})
export class InvoiceClarificationModule {}
