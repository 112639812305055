import { Injectable } from '@angular/core';
import { FilterCriteria, FilterRequest } from 'app/components/filter/filter.types';
import { Observable } from 'rxjs';
import { Filterable } from '../sort/sort.types';

@Injectable({ providedIn: 'root' })
export abstract class OverviewService {
  abstract getData(request: FilterRequest): Observable<Filterable[]>;

  abstract getFilterCriteria(): Observable<FilterCriteria>;
}
