import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { catchError, forkJoin, of } from 'rxjs';
import { RequestDuplicatesService } from './request-duplicates.service';
import { RequestDuplicate } from './request-duplicates.types';

@Injectable({ providedIn: 'root' })
export class RequestDuplicatesResolver
  implements Resolve<{ searchParams: URLSearchParams; requestDuplicates: RequestDuplicate[] }>
{
  constructor(private _requestDuplicatesService: RequestDuplicatesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const searchParams = new URLSearchParams();
    route.queryParamMap.keys.forEach((key) => {
      searchParams.append(key, route.queryParamMap.get(key) ?? '');
    });
    const searchParams$ = of(searchParams);

    const requestDuplicates$ = this._requestDuplicatesService.getRequestDuplicates(searchParams).pipe(
      catchError((error: HttpErrorResponse) => {
        return of();
      })
    );

    return forkJoin({ searchParams: searchParams$, requestDuplicates: requestDuplicates$ });
  }
}
