import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AnnouncementData } from './announcement.types';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  constructor(private client: HttpClient) {}

  loadAnnouncementData(modifiedTime: string, announcementIsHidden: boolean): Observable<AnnouncementData> {
    return this.client.get<AnnouncementData>(
      `${environment.isportCoreApiUrl}/api/announcement?announcementModifiedTime=${modifiedTime}&announcementIsHidden=${announcementIsHidden}`
    );
  }
}
