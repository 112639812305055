import { Injectable } from '@angular/core';
import { ContextDataService } from '@core/services';
import { groupBy } from '@core/utils/helper-functions';
import { Contract, Vehicle } from '../dashboard/dashboard.types';
import { VehicleInfo, VehicleSelectionLoadResult } from './vehicle-selection.types';

@Injectable({ providedIn: 'root' })
export class VehicleSelectionService {
  private _availableVehicles: VehicleInfo[] = [];
  private relatedProcessId?: string;

  constructor(private contextDataService: ContextDataService) {}

  get availableVehicles(): VehicleInfo[] {
    if (this.contextDataService.data.processId == this.relatedProcessId) {
      return this._availableVehicles;
    }

    return [];
  }

  load(contracts: Contract[]): VehicleSelectionLoadResult {
    // Reset available vehicles
    this._availableVehicles = [];
    // Save the process id related to the current search
    this.relatedProcessId = this.contextDataService.data.processId;

    // Find all contract vehicles
    const contractVehicles = contracts.map((contract: Contract) => {
      return contract.vehicle;
    });

    // Checks if duplicate vehicles by VIN exists
    if (
      contractVehicles.map((vehicle) => vehicle.vehicleIdentificationNumber).filter((v, i, a) => a.indexOf(v) === i)
        .length <= 1
    ) {
      // If no ambiguous search result was found, redirect directly to dashboard
      return VehicleSelectionLoadResult.Single;
    }

    // If found ambiguous search results, need to select specific contract by vehicle selection
    // Find duplicate vehicles by VIN
    const distinctVehicles: Vehicle[] = [];
    groupBy(contractVehicles, (vehicle: Vehicle) => vehicle.vehicleIdentificationNumber).forEach((vin) => {
      distinctVehicles.push(vin[0]);
    });

    distinctVehicles.forEach((vehicle) => {
      // Get all license plates for current vehicle
      const licensePlates = contractVehicles
        .filter((v) => v.vehicleIdentificationNumber == vehicle.vehicleIdentificationNumber)
        .map((contractVehicle) => contractVehicle.licensePlateNumber);

      // Basic model mapper and preparing data for table preview
      this._availableVehicles.push({
        name: vehicle.modelName,
        dateOfFirstRegistration: vehicle.dateOfFirstRegistration,
        vehicleIdentificationNumber: vehicle.vehicleIdentificationNumber,
        licensePlateNumbers: licensePlates
      });
    });

    return VehicleSelectionLoadResult.Multiple;
  }
}
