import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ConfigurationService, LocaleFormatService, RequestStateStore, TenantSettingsService } from '@core/services';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { UnsavedChangesStateStore } from 'app/pages/request';
import { RequestService } from 'app/pages/request/request.service';
import { GenericPositionComponent } from '../generic-position/generic-position.component';

@Component({
  selector: 'mobility-spare-part-position',
  templateUrl: './mobility-spare-part-position.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobilitySparePartPositionComponent extends GenericPositionComponent {
  /**
   * Constructor
   */
  constructor(
    formBuilder: UntypedFormBuilder,
    positionConfigurationService: PositionConfigurationService,
    requestService: RequestService,
    requestStore: RequestStateStore,
    unsavedChangesStateStore: UnsavedChangesStateStore,
    localeFormatService: LocaleFormatService,
    configurationService: ConfigurationService,
    tenantSettingsService: TenantSettingsService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(
      formBuilder,
      positionConfigurationService,
      9,
      requestStore,
      unsavedChangesStateStore,
      requestService,
      localeFormatService,
      configurationService,
      tenantSettingsService,
      changeDetector,
      elementRef
    );
  }
}
