import { Component, OnInit } from '@angular/core';
import { ContextDataService, EventService, TimedSchedulerService } from '@core/services';
import { DateTime } from 'luxon';
import { AnnouncementService } from './announcement.service';
import { AnnouncementData, AnnouncementSettings } from './announcement.types';
@Component({
  selector: 'announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  announcementData: AnnouncementData = { message: '', isVisible: false } as AnnouncementData;
  _announcementSettings: AnnouncementSettings = {} as AnnouncementSettings;
  showAnnouncement: boolean = false;

  constructor(
    private contextDataService: ContextDataService,
    private service: AnnouncementService,
    private eventService: EventService,
    private timedSchedulerService: TimedSchedulerService
  ) {}

  ngOnInit(): void {
    this.loadAnnouncement();
    this.subscribeToSignalrNotifications();
  }

  loadAnnouncement(): void {
    this.service.loadAnnouncementData(this.announcementModifiedTime, this.announcementHidden).subscribe({
      next: (res: AnnouncementData) => {
        if (!res) {
          return;
        }
        this.announcementData = res;
        // if we load the announcement, and announcementHidden was sent as "true", it will be respected in announcementData.isVisible
        // this means as long as the announcement hasn't been updated since closing it, isVisible will be false. otherwise true.
        this.announcementHidden = false;
        this.announcementModifiedTime = DateTime.now().toUTC().toISO();
        this.updateShowAnnouncement();

        // Schedule announcement visibility in case its in future
        this.timedSchedulerService.scheduleExecution(
          DateTime.fromISO(res.visibleSince, { zone: 'utc' }),
          () => {
            this.announcementHidden = false;
            this.announcementData.isVisible = true;
            this.updateShowAnnouncement();
          },
          true
        );

        // Schedule announcement hiding
        this.timedSchedulerService.scheduleExecution(
          DateTime.fromISO(res.visibleUntil, { zone: 'utc' }),
          () => this.closeAnnouncement(),
          false
        );
      }
    });
  }

  updateShowAnnouncement(): void {
    this.showAnnouncement =
      !this.announcementHidden && this.announcementData.isVisible && this.announcementData.message.length > 0;
  }

  closeAnnouncement(): void {
    this.announcementHidden = true;
    this.showAnnouncement = false;
  }

  get announcementSettings(): AnnouncementSettings {
    // this is always undefined when user hits f5 or logs in fresh. And to prevent the announcement re-opening when hitting f5, we need the session store.
    if (!this._announcementSettings.announcementModifiedTime) {
      const data = sessionStorage.getItem(`announcement-data-${this.contextDataService.data.tenant}`);
      if (data) {
        this._announcementSettings = JSON.parse(data);
      }
    }
    return this._announcementSettings;
  }

  set announcementSettings(val: AnnouncementSettings) {
    this._announcementSettings = val;
    sessionStorage.setItem(
      `announcement-data-${this.contextDataService.data.tenant}`,
      JSON.stringify(this._announcementSettings)
    );
  }

  get announcementHidden(): boolean {
    return this.announcementSettings.announcementHidden ?? false;
  }

  set announcementHidden(val: boolean) {
    this._announcementSettings.announcementHidden = val;
    this.announcementSettings = this._announcementSettings;
  }

  get announcementModifiedTime(): string {
    return this.announcementSettings.announcementModifiedTime ?? DateTime.fromMillis(0).toUTC().toISO();
  }

  set announcementModifiedTime(val: string) {
    this._announcementSettings.announcementModifiedTime = val;
    this.announcementSettings = this._announcementSettings;
  }

  private subscribeToSignalrNotifications() {
    this.eventService.setHubConnectionChangeListener('AnnouncementUpdate', () => {
      this.loadAnnouncement();
    });
  }
}
