import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { catchError, forkJoin, of } from 'rxjs';
import { InvoiceClarificationCancelService } from './cancel.service';
import { InvoiceClarificationCancelSummary } from './cancel.types';

@Injectable({ providedIn: 'root' })
export class InvoiceClarificationCancelResolver
  implements
    Resolve<{ searchParams: URLSearchParams; invoiceClarificationCancelSummary: InvoiceClarificationCancelSummary }>
{
  constructor(private invoiceClarificationCancelService: InvoiceClarificationCancelService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot) {
    const searchParams = new URLSearchParams();
    route.queryParamMap.keys.forEach((key) => {
      searchParams.append(key, route.queryParamMap.get(key) ?? '');
    });
    const searchParams$ = of(searchParams);

    const invoiceClarificationCancelSummary$ = this.invoiceClarificationCancelService
      .getInvoiceClarificationCancelSummary(
        searchParams.get('invoiceClarificationId'),
        searchParams.get('productType'),
        searchParams.get('editOptions'),
        searchParams.get('requestGuid')
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.router.navigate(['/invoice-clarification']);
          return of();
        })
      );

    return forkJoin({
      searchParams: searchParams$,
      invoiceClarificationCancelSummary: invoiceClarificationCancelSummary$
    });
  }
}
