export interface VehicleInfo {
  name: string;
  licensePlateNumbers: string[];
  dateOfFirstRegistration: string;
  vehicleIdentificationNumber: string;
}

export enum VehicleSelectionLoadResult {
  Single,
  Multiple
}
