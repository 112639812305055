import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExternalApplicationAssignResult } from '@core/types';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { StatusDetails } from './status-details-dialog.types';

@Injectable({ providedIn: 'root' })
export class StatusDetailsService {
  constructor(private httpClient: HttpClient) {}

  editExternalRequest(
    correlationId: string,
    productType: ProductTypeIdentifier
  ): Observable<ExternalApplicationAssignResult> {
    return this.httpClient.put<ExternalApplicationAssignResult>(`${environment.isportCoreApiUrl}/api/externalrequest`, {
      correlationId: correlationId,
      productType: productType
    });
  }

  getRelatedRequestVersion(
    requestGuid: string,
    versionIndex: number,
    productTypeIdentifier: ProductTypeIdentifier
  ): Observable<StatusDetails> {
    return this.httpClient.get<StatusDetails>(
      `${environment.isportCoreApiUrl}/api/statusoverview/version?requestGuid=${requestGuid}&versionIndex=${versionIndex}&productTypeIdentifier=${productTypeIdentifier}`
    );
  }
}
