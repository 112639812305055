import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  ConfigurationKeys,
  ConfigurationService,
  ContextDataService,
  DateTimeService,
  RoleCommon,
  RoleServiceService,
  TenantSettingsService,
  UserService
} from '@core/services';
import { SelectListItem } from '@core/types/mvc.types';
import { parseJwt } from '@core/utils/jwt-reader/jwt-reader';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription, forkJoin, map } from 'rxjs';

@Component({
  selector: 'developer-mode',
  templateUrl: './developer-mode.component.html',
  styleUrls: ['./developer-mode.component.scss']
})
export class DeveloperModeComponent implements OnInit, OnDestroy {
  reloadSubscription!: Subscription;
  availableTenants: SelectListItem[] = [
    { text: 'Skynet', value: 'TENANT_01' } as SelectListItem,
    { text: 'Forty2', value: 'TENANT_02' } as SelectListItem,
    { text: 'Borg', value: 'TENANT_03' } as SelectListItem,
    { text: 'Volkswagen Finance SA Spain', value: 'VolkswagenFinanceSpainSA' } as SelectListItem,
    { text: 'VolkswagenFinanzdienstleistungen', value: 'VolkswagenFinanzdienstleistungen' } as SelectListItem
  ];

  availableAppIds: SelectListItem[] = [
    { text: 'Autohaus', value: '56' } as SelectListItem,
    { text: 'Reifenhändler', value: '53' } as SelectListItem,
    { text: 'AEB', value: '50' } as SelectListItem,
    { text: 'Mobilität', value: '55' } as SelectListItem,
    { text: 'Schadenmanagement', value: '90' } as SelectListItem
  ];

  changeAccountForm = this.formBuilder.group({
    tenant: null,
    appId: null
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private configurationService: ConfigurationService,
    private tenantSettingsService: TenantSettingsService,
    private userService: UserService,
    private dateTimeService: DateTimeService,
    private oidcSecurityService: OidcSecurityService,
    private contextDataService: ContextDataService
  ) {}

  ngOnInit(): void {
    this.preloadFormData();
  }

  ngOnDestroy(): void {
    this.reloadSubscription?.unsubscribe();
  }

  preloadFormData(): void {
    this.changeAccountForm.get('tenant')?.setValue(this.contextDataService.data.tenant);
    this.changeAccountForm.get('appId')?.setValue(this.contextDataService.data.appId);
  }

  onSubmit(): void {
    this.contextDataService.tenant = this.changeAccountForm.get('tenant')?.value;
    this.contextDataService.appId = this.changeAccountForm.get('appId')?.value;

    this.reloadApp();
  }

  reloadApp(): void {
    const reloadObservable$ = forkJoin([
      this.configurationService.load(Object.values(ConfigurationKeys)),
      this.tenantSettingsService.loadEmptyPositions(),
      this.tenantSettingsService.load(this.contextDataService.data.tenant).pipe(
        map(() => {
          // Reconfigure culture since tenant could be changed
          this.dateTimeService.configureCulture(this.tenantSettingsService.culture);
        })
      ),
      this.userService.load().pipe()
    ]).pipe(
      map(() => {
        this.contextDataService.roles = [];
        this.oidcSecurityService.getAccessToken().pipe(
          map((tokenData) => {
            const jwtPayload = parseJwt(tokenData);
            this.contextDataService.roles = jwtPayload.role.map((role) =>
              RoleServiceService.getUserRoleForFsPartner(role, this.contextDataService.data.appId)
            );

            if (
              this.configurationService.sendingRestrictedDealers.includes(
                this.contextDataService.data.user.dealer.externalDealerNumber
              )
            ) {
              this.contextDataService.data.user.roles.push(RoleCommon.SendingRestricted);
              this.userService.updateBehaviorSubjects();
            }
          })
        );
      })
    );

    this.reloadSubscription = reloadObservable$.subscribe();
  }
}
