<ng-container *ngIf="errorList && errorList.length > 0">
  <mat-card class="message-state error" *transloco="let t">
    <mat-card-title class="ui-test--validation-summary-mat-card-title-validation-header">
      {{ t('Common_ValidationSummaryHeader') }}
    </mat-card-title>
    <mat-card-content>
      <ul>
        <li
          *ngFor="let errorItem of errorList; let i = index"
          [class]="'ui-test--validation-summary-list-error-item-' + i">
          {{ errorItem | numericFormat }}
        </li>
      </ul>
    </mat-card-content>
  </mat-card>
</ng-container>
