/*
    Uncomment when you need some of these settings to load, and
    setup getter for that key as part of ConfigurationService
*/

export enum TenantSettingKeys {
  // None = "None",
  Culture = 'Culture',
  // Language = "Language",
  // ActiveProductTypes = "ActiveProductTypes",
  // TimeZone = "TimeZone",
  // DefaultMailOption = "DefaultMailOption",
  // ActiveFileUploadCategoriesTire = "ActiveFileUploadCategoriesTire",
  // ActiveFileUploadCategoriesMobility = "ActiveFileUploadCategoriesMobility",
  // ActiveFileUploadCategoriesServiceAndMaintenance = "ActiveFileUploadCategoriesServiceAndMaintenance",
  // ActiveFileUploadCategoriesWarranty = "ActiveFileUploadCategoriesWarranty",
  // ActiveFileUploadCategoriesMotorInsurance = "ActiveFileUploadCategoriesMotorInsurance",
  // ActiveFileUploadCategoriesCallMeBack = "ActiveFileUploadCategoriesCallMeBack",
  // ActiveFileUploadCategoriesInsuranceRepair = "ActiveFileUploadCategoriesInsuranceRepair",
  // ActiveFileUploadCategoriesInvoiceClarification = "ActiveFileUploadCategoriesInvoiceClarification",
  // TireTreadDepthMeasurementUnit = "TireTreadDepthMeasurementUnit",
  // CurrencyUnit = "CurrencyUnit",
  // DashboardCustomerDisplaySettings = "DashboardCustomerDisplaySettings",
  // DashboardVehicleDisplaySettings = "DashboardVehicleDisplaySettings",
  SearchIndexDisplaySettings = 'SearchIndexDisplaySettings',
  SearchIndexFirstTimeRegistrationDisplaySettings = 'SearchIndexFirstTimeRegistrationDisplaySettings',
  SearchIndexEnabledFieldsForMissingParameter = 'SearchIndexEnabledFieldsForMissingParameter',
  // SearchParameterFirstRegistrationDate = "SearchParameterFirstRegistrationDate",
  // SearchParameterLicensePlateNumberRegex = "SearchParameterLicensePlateNumberRegex",
  SalesNumberRegexValidation = 'SalesNumberRegexValidation',
  // AlphanumericRegexValidation = "AlphanumericRegexValidation",
  // SearchAlphanumericRegexValidation = "SearchAlphanumericRegexValidation",
  RequestStorageLifetime = 'RequestStorageLifetime',
  // RequestDoubletCheckingPeriod = "RequestDoubletCheckingPeriod",
  // SparepartProductNumberRegexValidation = "SparepartProductNumberRegexValidation",
  AllowedFileTypes = 'AllowedFileTypes',
  // SagaIdValueValidation = "SagaIdValueValidation",
  BlockingPaymentOptions = 'BlockingPaymentOptions',
  ShowPayableByCustomerOptions = 'ShowPayableByCustomerOptions',
  BlockingPaymentOptionsDisableRequestCreation = 'BlockingPaymentOptionsDisableRequestCreation',
  RequestPaymentOptionSelectionInDashboard = 'RequestPaymentOptionSelectionInDashboard',
  // ShouldImportantNotificationVisible = "ShouldImportantNotificationVisible",
  // DefaultStatusDetailPane = "DefaultStatusDetailPane",
  // ShouldAttachmentsVisible = "ShouldAttachmentsVisible",
  ServiceAndMaintenanceWorkpositionDisplaySettings = 'ServiceAndMaintenanceWorkpositionDisplaySettings',
  ServiceAndMaintenanceSparepartsDisplaySettings = 'ServiceAndMaintenanceSparepartsDisplaySettings',
  // UseDefaultPrices = "UseDefaultPrices",
  TireServicePositionDisplaySettings = 'TireServicePositionDisplaySettings',
  TirePositionDisplaySettings = 'TirePositionDisplaySettings',
  PackagePositionDisplaySettings = 'PackagePositionDisplaySettings',
  TireSparepartsPositionDisplaySettings = 'TireSparepartsPositionDisplaySettings',
  ShouldAttachmentsSortingAscending = 'ShouldAttachmentsSortingAscending',
  AttachmentsSortingValue = 'AttachmentsSortingValue',
  MobilityPositionDisplaySettings = 'MobilityPositionDisplaySettings',
  // AvailableInvoiceStates = "AvailableInvoiceStates",
  // AreMobilityPositionsInServiceAndMaintenanceRequestEnabled = "AreMobilityPositionsInServiceAndMaintenanceRequestEnabled",
  // AreMobilityPositionsInTireRequestEnabled = "AreMobilityPositionsInTireRequestEnabled",
  // AreMobilityPositionsInMotorInsuranceRequestEnabled = "AreMobilityPositionsInMotorInsuranceRequestEnabled",
  // AreMobilityPositionsInWarrantyRequestEnabled = "AreMobilityPositionsInWarrantyRequestEnabled",
  MobilitySparepartsDisplaySettings = 'MobilitySparepartsDisplaySettings',
  // IsEditRequestAllowedForMotorInsurance = "IsEditRequestAllowedForMotorInsurance",
  // IsEditRequestAllowedForTire = "IsEditRequestAllowedForTire",
  // IsEditRequestAllowedForServiceAndMaintenance = "IsEditRequestAllowedForServiceAndMaintenance",
  // IsEditRequestAllowedForWarranty = "IsEditRequestAllowedForWarranty",
  // IsEditRequestAllowedForMobility = "IsEditRequestAllowedForMobility",
  // MotorInsuranceRequestEditOptions = "MotorInsuranceRequestEditOptions",
  // TireRequestEditOptions = "TireRequestEditOptions",
  // ServiceAndMaintenanceRequestEditOptions = "ServiceAndMaintenanceRequestEditOptions",
  // WarrantyRequestEditOptions = "WarrantyRequestEditOptions",
  // MobilityRequestEditOptions = "MobilityRequestEditOptions",
  // ActiveProductTypesForImportantNotifications = "ActiveProductTypesForImportantNotifications",
  // IsTireDualApplicationEnabled = "IsTireDualApplicationEnabled",
  UserProfileAvailableFields = 'UserProfileAvailableFields',
  // AreMobilityPositionsInInsuranceRepairRequestEnabled = "AreMobilityPositionsInInsuranceRepairRequestEnabled",
  // InsuranceRepairRequestEditOptions = "InsuranceRepairRequestEditOptions",
  InsuranceRepairSparepartsDisplaySettings = 'InsuranceRepairSparepartsDisplaySettings',
  InsuranceRepairWorkpositionDisplaySettings = 'InsuranceRepairWorkpositionDisplaySettings',
  // InsuranceReferenceNumberRegexValidation = "InsuranceReferenceNumberRegexValidation",
  EnableAftersalesOverview = 'EnableAftersalesOverview',
  WarrantyPositionDisplaySettings = 'WarrantyPositionDisplaySettings',
  WarrantySparepartsPositionDisplaySettings = 'WarrantySparepartsPositionDisplaySettings',
  WarrantyDiagnosticPositionDisplaySettings = 'WarrantyDiagnosticPositionDisplaySettings',
  // ServiceAndMaintenanceEditMode = "ServiceAndMaintenanceEditMode",
  // TiresEditMode = "TiresEditMode",
  WarrantyEditMode = 'WarrantyEditMode',
  // MobilityEditMode = "MobilityEditMode",
  // MotorInsuranceEditMode = "MotorInsuranceEditMode",
  // InsuranceRepairEditMode = "InsuranceRepairEditMode",
  // DeactivateCancelForProductTypes = "DeactivateCancelForProductTypes",
  // DeactivateDisplayTotalGoodWillForProductTypes = "DeactivateDisplayTotalGoodWillForProductTypes",
  // DeactivateDisplayExpectedPayoutForProductTypes = "DeactivateDisplayExpectedPayoutForProductTypes",
  // DisableMixedTireTypes = "DisableMixedTireTypes",
  EnableCallMeBack = 'EnableCallMeBack',
  UserProfileCategorizedHourlyRatesDisplayColumns = 'UserProfileCategorizedHourlyRatesDisplayColumns',
  DisplayUserProfileBaseProductHourlyRates = 'DisplayUserProfileBaseProductHourlyRates',
  DisableOfferDetailPage = 'DisableOfferDetailPage',
  // AvailableAftersalesProducts = "AvailableAftersalesProducts",
  // InsertTireSparepartsOnSubItem = "InsertTireSparepartsOnSubItem",
  // DashboardWarrantorDealerDisplaySettings = "DashboardWarrantorDealerDisplaySettings",
  ServiceAdvisorRegexValidation = 'ServiceAdvisorRegexValidation',
  // LocalHolidays = "LocalHolidays",
  PhoneNumberRenewalPeriod = 'PhoneNumberRenewalPeriod',
  PhoneNumberWaitPeriod = 'PhoneNumberWaitPeriod',
  DMSServiceAndMaintenanceWorkpositionDisplaySettings = 'DMSServiceAndMaintenanceWorkpositionDisplaySettings',
  DMSServiceAndMaintenanceSparepartsDisplaySettings = 'DMSServiceAndMaintenanceSparepartsDisplaySettings',
  EnableInvoiceClarificationMenu = 'EnableInvoiceClarificationMenu',
  // DisableCalculationForProductType = "DisableCalculationForProductType"
  RequestDoubletCheckingPeriod = 'RequestDoubletCheckingPeriod',
  DMSInsuranceRepairWorkpositionDisplaySettings = 'DMSInsuranceRepairWorkpositionDisplaySettings',
  DMSTireServicePositionDisplaySettings = 'DMSTireServicePositionDisplaySettings',
  DMSInsuranceRepairSparepartsDisplaySettings = 'DMSInsuranceRepairSparepartsDisplaySettings',
  DMSTireSparepartsDisplaySettings = 'DMSTireSparepartsDisplaySettings',
  DMSTirePositionDisplaySettings = 'DMSTirePositionDisplaySettings',
  DMSServiceAndMaintenancePackagePositionDisplaySettings = 'DMSServiceAndMaintenancePackagePositionDisplaySettings',
  DMSInsuranceRepairPackagePositionDisplaySettings = 'DMSInsuranceRepairPackagePositionDisplaySettings',
  DMSTirePackagePositionDisplaySettings = 'DMSTirePackagePositionDisplaySettings',
  IsDealerSelfServiceActive = 'IsDealerSelfServiceActive'
}
