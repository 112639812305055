import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '@core/modules';

import { DeveloperModeComponent } from './developer-mode.component';

@NgModule({
  imports: [MatCardModule, MatSelectModule, MatFormFieldModule, MatButtonModule, SharedModule],
  exports: [],
  declarations: [DeveloperModeComponent],
  providers: []
})
export class DeveloperModeModule {}
