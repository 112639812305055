import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectListItem } from '@core/types/mvc.types';
import { getUrlParamsFromObject } from '@core/utils/helper-functions';
import { environment } from 'environments/environment';
import { TireFilter, TireFilterResult } from './tire-search-dialog.types';

@Injectable({ providedIn: 'root' })
export class TireSearchDialogService {
  constructor(private httpClient: HttpClient) {}

  getFilterTireTypes(companyCode: string, activityTypeKey: string) {
    return this.httpClient.get<SelectListItem[]>(
      `${environment.isportCoreApiUrl}/api/tirerequest/tiretypes?companyCode=${companyCode}&activityTypeKey=${activityTypeKey}`
    );
  }

  getTireSizes(companyCode: string, tireType: string) {
    return this.httpClient.get<SelectListItem[]>(
      `${environment.isportCoreApiUrl}/api/tirerequest/tiresize?companyCode=${companyCode}&tireType=${tireType}`
    );
  }

  getContractTireSizes() {
    return this.httpClient.get<string[]>(`${environment.isportCoreApiUrl}/api/tirerequest/contractTireSizes`);
  }

  getTires(tireFilter: TireFilter) {
    return this.httpClient.get<TireFilterResult>(
      `${environment.isportCoreApiUrl}/api/tirerequest/tires?${getUrlParamsFromObject(tireFilter)}`
    );
  }

  getAllTires(tireFilter: TireFilter) {
    return this.httpClient.get<TireFilterResult>(
      `${environment.isportCoreApiUrl}/api/tirerequest/tiresfilterall?${getUrlParamsFromObject(tireFilter)}`
    );
  }
}
