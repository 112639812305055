import { Injectable } from '@angular/core';
import { BrowserWarningButtonService } from './browser-warning-button';
import { BrowserWarningDialogService } from './browser-warning-dialog';

@Injectable({ providedIn: 'root' })
export class BrowserCompatibilityService {
  constructor(
    private browserWarningButtonService: BrowserWarningButtonService,
    private browserWarningDialogService: BrowserWarningDialogService
  ) {}

  public get isFirefox(): boolean {
    return navigator.userAgent.indexOf('Firefox') > 0;
  }

  public set hintConfirmed(value: boolean) {
    localStorage.setItem('ff_hint_confirmed', value.toString());
  }

  public get hintConfirmed(): boolean {
    return localStorage.getItem('ff_hint_confirmed') !== null;
  }

  private get isIframeLoginCompatible(): boolean {
    return !this.isFirefox;
  }

  public checkBrowserCompatibility() {
    if (this.isIframeLoginCompatible || this.hintConfirmed) {
      // everything is fine :)
      return;
    }

    this.browserWarningButtonService.showButton();
    this.browserWarningDialogService.openDialog();
  }
}
