import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ConfigurationService, RequestEventMessage, RequestStateStore, TenantSettingsService } from '@core/services';
import { LocaleFormatService } from '@core/services/locale/locale-format.service';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { deCapitalizeFirstLetter, ensureInclusiveBetween } from '@core/utils';
import { DropdownWithSearchComponent } from 'app/components/dropdown-with-search';
import { ActivityTypeIdentifierCommon, DamageType } from 'app/components/positions';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { sort } from 'app/components/sort/sort.helper';
import { RequestService } from 'app/pages/request/request.service';
import { UnsavedChangesStateStore } from 'app/pages/request/unsaved-changes-state-store.service';
import { Subscription, debounce, groupBy, mergeMap, timer } from 'rxjs';
import { Memoize } from 'typescript-memoize';
import {
  ActivityType,
  ActivityTypeClass,
  ExternalProductNumber,
  PrimaryNetWagePerHourSource,
  RentalReason,
  RequestContent,
  RequestPosition,
  Unit
} from '../../../request.types';
import { InputGroupComponent } from '../input-group';
import { PositionsTemplateNameDirective } from '../positions.directive';

@Component({
  selector: 'generic-position',
  template: ''
})
export abstract class GenericPositionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() requestContent!: RequestContent;
  @Input('availableActivityTypes') requestAvailableActivityTypes!: ActivityType[];
  @Input('damageTypes') requestDamageTypes!: DamageType[];
  @Input() positionData!: RequestPosition;
  @Input() productType!: ProductTypeIdentifier;
  @Input() index!: number;
  @Output() removedPosition = new EventEmitter<RequestPosition>();
  @Output() savedPosition = new EventEmitter<void>();
  @Output() requestContentChange = new EventEmitter<RequestContent>();

  positionParameterConfiguration: TemplateRef<any>[] = [];
  positionFormGroup = this.formBuilder.group({});

  // NOTE: Please use the specificAvailableActivityTypes() method to get expected ActivityTypes for current positions ItemType.
  availableActivityTypes!: ActivityType[];
  availableActivityTypeClasses!: ActivityTypeClass[];
  activityTypes!: ActivityType[];
  activityTypeClasses!: ActivityTypeClass[];
  activityTypesWithAssignedClass!: ActivityType[]; // Contains all activity types which has at least one activity type class.
  filteredActivityTypes!: ActivityTypeClass[]; // used by Spare Part Positions
  units!: Unit[];

  Validators = Validators;

  visibleRows!: number;
  itemType!: ActivityTypeIdentifierCommon;
  disablePriceEdit = false;
  possibleActivityTypeClassesByEpn: ActivityTypeClass[] = [];
  // TODO: Update this when adding a new position
  // see page.request.js line 1546
  isValidExternalProductNumber = false;
  isParentDeleted = false;
  isAfterRender = false;
  hasValidationError: boolean = false;
  protected eventSubscription!: Subscription;
  protected positionPanelBodyChangesSubscription!: Subscription;

  @ViewChild('positionsPanel') positionsPanel!: MatExpansionPanel;
  @ViewChildren(InputGroupComponent) inputGroups!: InputGroupComponent[];
  @ViewChildren(DropdownWithSearchComponent) dropdowns!: DropdownWithSearchComponent[];
  @ViewChildren(PositionsTemplateNameDirective, { read: PositionsTemplateNameDirective })
  set cellTemplates(availableTemplateRef: QueryList<PositionsTemplateNameDirective>) {
    // Based on available templates and tenant settings will configure and set data to positionParameterConfiguration
    const parameterNames = this.positionConfigurationService.getParameters(
      this.positionData.itemType,
      this.productType,
      this.requestContent.requestProcess.sourceApplication
    );

    const emptyTemplate = availableTemplateRef.find((template) => template.name === 'none')?.template;
    if (!emptyTemplate) {
      throw new Error('No empty template');
    }

    for (let index = 0; index < this.maximumParameters; index++) {
      this.positionParameterConfiguration.push(emptyTemplate);
      if (index < parameterNames.length) {
        const parameterName = parameterNames[index];
        const template = availableTemplateRef.find(
          (templateRef) => templateRef.name === deCapitalizeFirstLetter(parameterName)
        )?.template;

        if (template) {
          this.positionParameterConfiguration[index] = template;
        }
      }
    }

    // Setup visible rows used for height of background icon
    if (this.itemType === 'TirePosition') {
      this.visibleRows = 4;
    } else {
      this.visibleRows = 0;
      const chunkSize = 3;
      for (let i = 0; i < parameterNames.length; i += chunkSize) {
        const chunk = parameterNames.slice(i, i + chunkSize);
        if (chunk.some((parameter) => parameter.length > 0)) {
          this.visibleRows++;
        }
      }
    }
  }

  /**
   * Constructor
   */
  constructor(
    protected formBuilder: UntypedFormBuilder,
    private positionConfigurationService: PositionConfigurationService,
    @Inject(Number) public maximumParameters: number,
    protected requestStore: RequestStateStore,
    protected unsavedChangesStateStore: UnsavedChangesStateStore,
    protected requestService: RequestService,
    protected localeFormatService: LocaleFormatService,
    protected configurationService: ConfigurationService,
    protected tenantSettingsService: TenantSettingsService,
    protected changeDetector: ChangeDetectorRef,
    public elementRef: ElementRef
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.prepareRequestForEdit();
    if (this.requestAvailableActivityTypes) {
      this.selectAvailableActivityTypes();
      this.selectAvailableActivityTypeClasses();
      this.selectActivityTypes();
      this.selectActivityTypeClasses();
      this.filterActivityTypes();
      this.selectActivityTypesWithAssignedClass();
      this.selectUnits();
      this.displayQuantityUnit();
    }

    this.updateItemTypeForDb();
    this.subscribeRequestFormValueChanges();
  }

  ngAfterViewInit(): void {
    // has to be checked here and not in the OnInit, because the positionPanel will be created after this component
    if (!this.positionData.isOld && this.positionData.isExpanded && !this.positionsPanel.expanded) {
      this.positionsPanel.open();
    }
    // when hiding the body, the mat-expansion-panel sets the height of the body to 0px. For some reason though,
    // sometimes, the open() animation fails, keeping the height at 0px, but the expanded state is true, and the visible style is also set to visible.
    // To prevent this, we'll check after the animation is done if the height is correctly set.
    this.positionPanelBodyChangesSubscription = this.positionsPanel._bodyAnimationDone.subscribe(() => {
      if (this.positionsPanel._body.nativeElement.style.height === '0px' && this.positionsPanel.expanded) {
        this.positionsPanel._body.nativeElement.style.height = '';
      }
    });
    this.subscribeToRequestStore();

    // we need to wait until the inputGroups are initialized, so the specific input field will also be highlighted.
    setTimeout(() => {
      if (this.positionData.validationErrors) {
        this.updateValidators();
      }
    }, 200);
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
    this.positionPanelBodyChangesSubscription.unsubscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Helper methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Ensure a numeric value is in value range. Default maximum is 1e9.
   */
  ensureInclusiveBetween(value: number | string | undefined, min: number, max?: number, throwException?: boolean) {
    // ignore userCulture here and use international culture to ensure consistent numbers
    value = this.localeFormatService.formatNumeric(value?.toString());
    return ensureInclusiveBetween(value, min, max, throwException);
  }

  @Memoize((activityTypeType: string, positionType: string) => {
    return activityTypeType + ';' + positionType;
  })
  isMatchingItemType(activityTypeType: string, positionType: string): boolean {
    if (activityTypeType === positionType) {
      return true;
    }

    switch (activityTypeType) {
      case 'WorkPosition':
      case 'MaintenancePackagePosition':
        return (
          'WorkPosition|ServicePosition|MaintenancePackagePosition|TireServicePosition|MobilityPosition'.indexOf(
            positionType
          ) >= 0
        );
      case 'SparePart':
        return 'SparePart|TirePosition'.indexOf(positionType) >= 0;
      case 'TireSparePart':
        return 'TireSparePart|TirePosition'.indexOf(positionType) >= 0;
      case 'WarrantyPosition':
        return 'WarrantyPosition|WarrantyDiagnosticPosition'.indexOf(positionType) >= 0;
      default:
        return false;
    }
  }

  @Memoize()
  getDamageTypeNameById(id: number) {
    if (!id) {
      return '';
    }

    const damageType = this.requestContent.damageTypes.find((dt) => dt.id === id);
    return damageType ? damageType.name : '';
  }

  @Memoize()
  getAvailableActivityTypesByItemType(itemType: ActivityTypeIdentifierCommon) {
    return this.requestAvailableActivityTypes.filter((activityType) => {
      return this.isMatchingItemType(activityType.itemType, itemType);
    });
  }

  private toggleDeleteSelf(toggleDelete: boolean): void {
    this.positionData.isPositionMarkedAsDeleted = toggleDelete;
    this.positionData.isPositionVisible = !toggleDelete;
    this.isParentDeleted = toggleDelete;
    if (this.positionData.subitems.length > 0) {
      this.requestStore.addEventMessage({
        eventName: 'ToggleDeletePosition',
        payload: { positionId: this.positionData.positionId, toggleDelete: toggleDelete }
      } as RequestEventMessage);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  @Memoize()
  get isDMS(): boolean {
    return this.positionData.sourceApplication === 'DMS';
  }

  get hasExternalProductNumber(): boolean {
    return this.positionData.externalProductNumber != null && this.positionData.externalProductNumber !== '';
  }

  get hasExternalProductNumberSource(): boolean {
    return this.positionData.externalProductNumberSource !== '';
  }

  get hasNoComponent(): boolean {
    return (this.positionData.activityTypeKey ?? 0) <= 0;
  }

  get hasNoActivityTypeClass(): boolean {
    return (this.positionData.activityTypeClassKey ?? 0) <= 0;
  }

  get isDamageTypeRequired(): boolean {
    if (this.hasNoComponent) {
      return false;
    }

    return this.availableActivityTypes.some(
      (activityType) => activityType.id === this.positionData.activityTypeKey && activityType.damageTypeRequired
    );
  }

  get isMaterialGoodwillVisible(): boolean {
    return this.isSparePartDenied && !this.hasNoComponent;
  }

  get hasSubitems(): boolean {
    return this.positionData.subitems?.length > 0;
  }

  /**
   * Gets a value indicating whether the position is an unparented position or not.
   */
  get isUnparentedPosition(): boolean {
    const foundUnparentedWorkPositions =
      this.hasExternalParentPositionId &&
      !this.hasParentPositionId &&
      (this.itemType === 'WorkPosition' || this.itemType === 'ServicePosition' || this.itemType === 'WarrantyPosition');

    const foundUnparentedSpareParts =
      (!this.hasExternalParentPositionId || (this.hasExternalParentPositionId && !this.hasParentPositionId)) &&
      (this.itemType === 'SparePart' || this.itemType === 'WarrantySparePart');

    return foundUnparentedWorkPositions || foundUnparentedSpareParts;
  }

  get hasQuantity(): boolean {
    return this.positionData.quantity > 0;
  }

  get hasQuantityUnit(): boolean {
    return !!this.positionData.quantityUnit && this.positionData.quantityUnit > 0;
  }

  get hasUnitPrice(): boolean {
    return this.positionData.unitPrice > 0;
  }

  get hasNetFixedPrice(): boolean {
    return this.positionData.netFixedPrice > 0;
  }

  get hasDiscount(): boolean {
    return this.positionData.discount > 0;
  }

  get hasGoodwill(): boolean {
    return this.positionData.goodwill > 0;
  }

  get hasMaterialGoodwill(): boolean {
    return !!this.positionData.materialGoodwill && this.positionData.materialGoodwill > 0;
  }

  get hasNetAmount(): boolean {
    if (this.disablePriceEdit) {
      return false;
    }

    return this.hasNetFixedPrice || (this.hasQuantity && this.hasUnitPrice);
  }

  get enableSagaId(): boolean {
    return !this.hasNoComponent && (this.hasGoodwill || this.hasMaterialGoodwill);
  }

  get isSparePartDenied(): boolean {
    if (this.hasNoComponent) {
      return true;
    }

    return this.availableActivityTypes.some(
      (activityType) => activityType.id === this.positionData.activityTypeKey && activityType.isSparePartDenied
    );
  }

  get displayQuantity() {
    if (!this.hasQuantity) {
      return '-';
    }

    return this.localeFormatService.formatNumericString(this.positionData.quantity.toString());
  }

  get displayQuantityUnitShort() {
    let result = '';
    const units = this.units;

    if (!units) return result;

    const first = units[0];
    if (typeof first !== 'undefined') {
      result = first.descriptionShort;
    }
    return result;
  }

  get displayQuantityWithUnit() {
    if (!this.hasQuantity) {
      return '-';
    }

    return (
      this.localeFormatService.formatNumericStringFromNumber(this.positionData.quantity) +
      ' ' +
      this.displayQuantityUnitShort
    );
  }

  get displayDiscount() {
    if (!this.hasDiscount) {
      return '-';
    }

    return (
      this.localeFormatService.formatNumericStringFromNumber(Math.round(this.positionData.discount * 100) / 100) + '%'
    );
  }

  get displayGoodwill() {
    if (!this.hasGoodwill) {
      return '-';
    }

    return (
      this.localeFormatService.formatNumericStringFromNumber(Math.round(this.positionData.goodwill * 100) / 100) + '%'
    );
  }

  get displayMaterialGoodwill() {
    if (!this.hasMaterialGoodwill || !this.positionData.materialGoodwill) {
      return '-';
    }

    return (
      this.localeFormatService.formatNumericStringFromNumber(
        Math.round(this.positionData.materialGoodwill * 100) / 100
      ) + '%'
    );
  }

  get netAmount() {
    if (this.disablePriceEdit) {
      return 0;
    }

    let netAmount = 0;

    if (this.hasNetFixedPrice) {
      netAmount = this.positionData.netFixedPrice;
    } else if (this.hasQuantity) {
      netAmount = this.positionData.quantity * this.positionData.unitPrice;
    }

    if (this.hasDiscount && netAmount > 0) {
      const percent = netAmount / 100;
      const discountAmount = percent * this.positionData.discount;
      netAmount -= discountAmount;
    }
    return Math.round(netAmount * 100) / 100;
  }

  get goodwillAmount() {
    let goodwillAmount = 0.0;
    if (this.hasNetFixedPrice) {
      goodwillAmount = this.positionData.netFixedPrice;
    } else if (this.hasQuantity) {
      goodwillAmount = this.positionData.quantity * this.positionData.unitPrice;
    }

    if (this.hasGoodwill) {
      goodwillAmount = Math.round(goodwillAmount * this.positionData.goodwill) / 100;
    }

    return goodwillAmount;
  }

  get isActivityTypeClassVisible() {
    return !this.positionData.isInnerPosition;
  }

  get isActivityTypeVisible() {
    return !this.positionData.isInnerPosition;
  }

  get isPricelessRequestAllowed() {
    if (this.hasNoComponent) {
      return false;
    }

    const pricelessRequestAllowedForContract =
      this.requestContent.requestProcess.generalInformation.isPricelessRequestAllowed;
    return this.availableActivityTypes.some(
      (activityType) =>
        activityType.id == this.positionData.activityTypeKey &&
        activityType.isPricelessRequestAllowed &&
        pricelessRequestAllowedForContract
    );
  }

  /**
   * Gets a value indicating whether the external parent position id has a value or not.
   */
  get hasExternalParentPositionId(): boolean {
    return this.positionData.externalParentPositionId !== '';
  }

  /**
   * Gets a value indicating whether the internal parent position id has a value or not.
   */
  get hasParentPositionId(): boolean {
    return this.positionData.parentPositionId !== '';
  }

  /**
   * Gets a value indicating whether the unit price should be disabled or not.
   */
  get isUnitPriceDisabled(): boolean {
    return this.disablePriceEdit || this.hasNoComponent || this.hasNetFixedPrice;
  }

  /**
   * Gets a value indicating whether the net fixed price should be disabled or not.
   */
  get isNetFixedPriceDisabled(): boolean {
    return (
      this.disablePriceEdit ||
      this.hasNoComponent ||
      this.hasQuantity ||
      this.hasUnitPrice ||
      this.isPricelessRequestAllowed
    );
  }

  /**
   * Gets a value indicating whether the discount should be disabled or not.
   */
  get isDiscountDisabled(): boolean {
    return this.hasNoComponent || this.disablePriceEdit;
  }

  /**
   * Gets a value indicating whether the goodwill should be disabled or not.
   */
  get isGoodWillDisabled(): boolean {
    return this.hasNoComponent || this.disablePriceEdit;
  }

  /**
   * Gets a value indicating whether the activity type class should be disabled or not.
   */
  get isActivityTypeClassDisabled(): boolean {
    if (this.isDMS) {
      return !this.positionData.canEditActivityForWso;
    }

    let isActivityTypeClassDisabled = false;
    if (this.possibleActivityTypeClassesByEpn.length <= 1) {
      isActivityTypeClassDisabled =
        this.hasUndeletedSubitems() ||
        this.positionData.isInnerPosition ||
        (!this.hasNoComponent && !this.hasNoActivityTypeClass && this.hasExternalProductNumber);
    }

    return isActivityTypeClassDisabled;
  }

  /**
   * Gets a value indicating whether the activity type should be disabled or not.
   */
  get isActivityTypeDisabled() {
    if (this.isDMS) {
      return !this.positionData.canEditActivityForWso;
    }

    return (
      this.hasUndeletedSubitems() ||
      this.positionData.isInnerPosition ||
      (!this.hasNoActivityTypeClass && !this.hasNoComponent && this.hasKnownExternalProductNumber()) ||
      (this.hasExternalProductNumber && this.isValidExternalProductNumber)
    );
  }

  get hasUnitSelection() {
    const units = this.units;
    return units && units.length > 1;
  }

  get hasQuantityUnitDefined() {
    const units = this.units;
    if (units && units.length > 0) {
      return this.displayQuantityUnit() !== '';
    }
    return false;
  }

  get excludePositionFromCalculating() {
    return this.positionData.isPositionMarkedAsDeleted || this.isParentDeleted;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Protected methods
  // -----------------------------------------------------------------------------------------------------

  protected resolveParentPosition() {
    if (!this.positionData.parentPositionId) {
      return undefined;
    }

    return this.requestContent.requestPositions.find(
      (position) => position.positionId === this.positionData.parentPositionId
    );
  }

  protected selectedActivityTypeForPosition(position: RequestPosition) {
    const activityTypes = this.getAvailableActivityTypesByItemType(position.itemType);
    const key = position.activityTypeKey;
    return activityTypes.find((activityType) => activityType.id === key);
  }

  protected selectedActivityType() {
    const key = this.positionData.activityTypeKey;
    return this.availableActivityTypes.find((activityType) => activityType.id === key);
  }

  protected sortedDefaultDamageTypes() {
    const filteredDamageTypes = this.requestDamageTypes.filter((damageType) => {
      return damageType.productType === this.positionData.productTypeForDamageType;
    });

    return sort(filteredDamageTypes, 'displayText', this.requestContent.isDamageTypesSortingAscending ? 'asc' : 'desc');
  }

  protected filterAndSortDamageTypes(damageTypes: DamageType[] | undefined) {
    let result;

    if (damageTypes && damageTypes.length > 0) {
      result = damageTypes.filter((damageType) => {
        return this.sortedDefaultDamageTypes().some(function (dt) {
          return dt.id === damageType.id;
        });
      });

      if (result && result.length > 1) {
        return sort(result, 'displayText', this.requestContent.isDamageTypesSortingAscending ? 'asc' : 'desc');
      }
    }

    return result;
  }

  /**
   * Gets only the activity types for the current positions item type.
   */
  protected specificAvailableActivityTypes(): ActivityType[] {
    return this.availableActivityTypes.filter((activityType) => activityType.itemType === this.positionData.itemType);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private subscribeToRequestStore() {
    this.eventSubscription = this.requestStore.requestEventMessages
      .pipe(
        groupBy((m) => m.eventName),
        mergeMap((group$) =>
          group$.pipe(
            debounce((o$) => {
              return timer(o$.debounceMillis ?? 0);
            })
          )
        )
      )
      .subscribe({
        next: (eventMessage: RequestEventMessage) => {
          switch (eventMessage.eventName) {
            case 'AddNewPosition':
              this.collapseOldPositionOnEvent(eventMessage);
              break;
            case 'ChangeExpandStateOfChildren':
              this.changeExpandStateOfChildrenOnEvent(eventMessage);
              break;
            case 'DisablePriceEditUpdate':
              this.disablePriceEditForChildrenOnEvent(eventMessage);
              break;
            case 'EpnValueUpdated':
              if (this.positionData.positionId === eventMessage.payload.positionId) {
                this.editEpnForItemTypes(eventMessage.payload.value, eventMessage.payload.itemTypes);
              }
              break;
            case 'ActivityTypeUpdated':
              if (this.positionData.positionId === eventMessage.payload.positionId) {
                this.initializeDealerPrice();
                this.presetDiscountFromActivityType();
                this.updateItemTypeForDb();
                this.selectUnits();
                this.displayQuantityUnit();
                this.presetDamageIdFromParent();
              }
              break;
            case 'ToggleDeletePosition':
              this.toggleDeletePosition(eventMessage.payload.positionId, eventMessage.payload.toggleDelete);
              break;
            case 'CollapseExceptPositionIndex':
              if (
                this.positionData.laborPositionIndex !== eventMessage.payload.positionIndex &&
                this.positionsPanel.expanded
              ) {
                this.togglePosition();
              }
              break;
            case 'BackendValidation':
              this.updateValidators();
              break;
            case 'SubmitFailed':
              this.highlightInvalidControls();
              break;
            case 'UpdateDamageId':
              if (this.positionData.parentPositionId === eventMessage.payload.parentId) {
                this.updateDamageId(eventMessage.payload.damageId);
              }
              break;
          }
        }
      });
  }

  /**
   * Checks if the entered external product number is in the available activity types.
   */
  private hasKnownExternalProductNumber(): boolean {
    if (this.hasExternalProductNumber) {
      for (const currentActivityType of this.specificAvailableActivityTypes()) {
        if (
          currentActivityType &&
          currentActivityType.externalProductNumbers &&
          currentActivityType.id === this.positionData.activityTypeKey
        ) {
          const result = currentActivityType.externalProductNumbers.filter(
            (epn) => epn.number === this.positionData.externalProductNumber
          );
          if (result.length === 1) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private selectAvailableActivityTypeClasses() {
    const activityTypeClasses: ActivityTypeClass[] = [];
    this.availableActivityTypes.forEach((activityType) => {
      if (activityType.itemType !== this.itemType) {
        return;
      }

      activityType.activityTypeClasses.forEach((activityTypeClass) => {
        if (!activityTypeClasses.some((item) => item.id === activityTypeClass.id)) {
          activityTypeClasses.push(activityTypeClass);
        }
      });
    });

    this.availableActivityTypeClasses = activityTypeClasses;
  }

  /**
   * Get discount for an activity type
   */
  private getActivityTypeDiscount(id: number) {
    return this.availableActivityTypes.find((activityType) => activityType.id === id)?.discountPercentage ?? 0;
  }

  /**
   * Preset discount from selected activity type
   */
  private presetDiscountFromActivityType() {
    const discount = this.getActivityTypeDiscount(this.positionData.activityTypeKey ?? 0);
    if (discount != null) {
      this.editDiscount(discount);
    }
  }

  private presetDamageIdFromParent() {
    if (this.positionData.parentPositionId && this.isDamageTypeRequired) {
      const parent = this.resolveParentPosition();
      if (parent?.damageTypeId) {
        this.positionData.damageTypeId = parent.damageTypeId;
      }
    }
  }

  /**
   * Initialize the unit price depending on the current activity type.
   */
  private initializeDealerPrice() {
    // Prefill prices for specific positions of a selected activity type.
    if (
      this.itemType === 'WorkPosition' ||
      this.itemType === 'ServicePosition' ||
      this.itemType === 'WarrantyPosition' ||
      this.itemType === 'TireServicePosition'
    ) {
      const netAmount = this.getActivityTypeNetAmount(this.positionData.activityTypeKey ?? 0);
      if (netAmount) {
        this.editUnitPrice(netAmount);
      }
    }
  }

  private getActivityTypeNetAmount(id: number) {
    return this.availableActivityTypes.find((activityType) => activityType.id === id)?.netAmount;
  }

  /**
   * Gets the activity types which are matching the External Product Number (epn).
   */
  private getActivityTypesByExternalProductNumber() {
    const activityTypes: ActivityType[] = [];

    if (this.hasExternalProductNumber) {
      // Loop for each Activity Type
      for (const currentActivityType of this.specificAvailableActivityTypes()) {
        if (currentActivityType && currentActivityType.externalProductNumbers) {
          // Select the Activity Type which is matching the positions External Product Number (epn).
          const result = currentActivityType.externalProductNumbers.filter((epn) => {
            return epn.number == this.positionData.externalProductNumber;
          });

          if (result.length === 1) {
            activityTypes.push(currentActivityType);
          }
        }
      }
    }

    return activityTypes;
  }

  /**
   * Gets the display activity types which are matching the External Product Number (epn).
   */
  private getActivityTypesByExternalProductNumberDisplay() {
    const matchedActivityTypes = this.getActivityTypesByExternalProductNumber();

    // Take the existing matched activity types for an EPN, otherwise the available activity types.
    const targetActivityTypes =
      matchedActivityTypes.length > 0 ? matchedActivityTypes : this.specificAvailableActivityTypes();

    return sort(
      targetActivityTypes,
      'description',
      this.requestContent.isActivityTypesSortingAscending ? 'asc' : 'desc'
    );
  }

  /**
   * Gets the display activity type classes from the activity types with matched External Product Number (epn).
   */
  private getActivityTypeClassesByExternalProductNumberDisplay() {
    // const distinctSelect: ActivityTypeClass[] = [];
    // const distinct = {};
    // const matchedActivityTypes = this.getActivityTypesByExternalProductNumber();
    // // Take the existing matched activity types for an EPN, otherwise the available activity types.
    // const targetActivityTypes =
    //   matchedActivityTypes.length > 0 ? matchedActivityTypes : this.specificAvailableActivityTypes();
    // // Loop for each matched Activity Type
    // for (let atIndex = 0; atIndex < targetActivityTypes.length; atIndex++) {
    //   const atcs = targetActivityTypes[atIndex].activityTypeClasses;
    //   for (let atcIndex = 0; atcIndex < atcs.length; atcIndex++) {
    //     distinct[atcs[atcIndex].id] = atcs[atcIndex];
    //   }
    // }
    // for (const key in distinct) {
    //   if (distinct.hasOwnProperty(key)) {
    //     distinctSelect.push(distinct[key]);
    //   }
    // }
    // // Fix sorting based on isActivityTypeClassesSortingAscending
    // distinctSelect.sort((a: any, b: any) => {
    //   return (a.text < b.text) as unknown as number;
    // });
    // return distinctSelect;
  }

  private filterAllActivityTypes(
    allActivityTypes: ActivityType[],
    itemType: string,
    selectedRentalReason: RentalReason | undefined,
    parentSelectedActivityType: ActivityType | null | undefined,
    restrictByClass: boolean,
    atc: number | undefined
  ) {
    const filteredActivityTypes: ActivityType[] = [];

    for (const current of allActivityTypes) {
      if (itemType && current.itemType !== itemType) {
        continue;
      }

      if (selectedRentalReason && !this.activityTypeAllowedByRentalReason(selectedRentalReason, current)) {
        continue;
      }

      if (!this.checkParentHasActivityType(parentSelectedActivityType, current)) {
        continue;
      }

      if (
        !restrictByClass ||
        current.activityTypeClasses.some((currentAtc: ActivityTypeClass) => currentAtc.id == atc)
      ) {
        filteredActivityTypes.push(current);
      }
    }

    return filteredActivityTypes;
  }

  private checkParentHasActivityType(
    parentSelectedActivityType: ActivityType | null | undefined,
    currentActivityType: ActivityType
  ): boolean {
    if (parentSelectedActivityType?.activityTypeKeys && parentSelectedActivityType.activityTypeKeys.length > 0) {
      return parentSelectedActivityType.activityTypeKeys.some((key: string) => key == currentActivityType.name);
    }

    return true;
  }

  private activityTypeAllowedByRentalReason(rentalReason: RentalReason, activityType: ActivityType): boolean {
    return (
      activityType.activityTypeCategory === 'Mobility' &&
      rentalReason.activityTypes.some((act: string) => act === activityType.name)
    );
  }

  private disablePriceEditForChildrenOnEvent(eventMessage: RequestEventMessage): void {
    if (this.positionData.parentPositionId === eventMessage.payload.parentPositionId) {
      this.disablePriceEdit = eventMessage.payload.value;
      this.editUnitPrice(0);
    }
  }

  private changeExpandStateOfChildrenOnEvent(eventMessage: RequestEventMessage): void {
    if (this.positionData.parentPositionId !== eventMessage.payload.parentPositionId) {
      return;
    }

    // since toggling changes this positions state and we want it to match its parent if this is called, we only run it if it doesn't already match its parent state.
    if (this.positionsPanel?.expanded !== eventMessage.payload.expanded) {
      this.togglePosition();
      return;
    }

    // make sure we propagate the event to grandchildren, even if this position doesn't need to be toggled.
    // important for example when parent and child of this position are expanded, and this (the middle) position is not,
    // then collapsing the parent needs to collapse its grandchild (child of this position) as well.
    if (this.hasSubitems) {
      this.requestStore.addEventMessage({
        eventName: 'ChangeExpandStateOfChildren',
        payload: { expanded: this.positionsPanel.expanded, parentPositionId: this.positionData.positionId }
      });
    }
  }

  private collapseOldPositionOnEvent(eventMessage: RequestEventMessage): void {
    // In case we add other children (e.g. Tire req with tire pos and spare part), all children of the parent position (and their children) should stay expanded
    if (
      eventMessage.payload &&
      (eventMessage.payload === this.positionData.parentPositionId ||
        this.positionData.subitems?.some((p) => p.positionId === eventMessage.payload))
    ) {
      return;
    }
    if (
      (this.positionData.isPositionMarkedAsDeleted ||
        (this.positionData.isOld && eventMessage.payload !== this.positionData.positionId)) && // do not collapse parent position in case of adding children
      this.positionsPanel?.expanded
    ) {
      this.togglePosition();
    }
  }

  private updateEpnQuantity(foundEpn: ExternalProductNumber) {
    if (typeof foundEpn.price !== 'undefined' && foundEpn.price !== null) {
      if (
        !(
          this.requestContent.requestProcess.primaryNetWagePerHourSource ===
            PrimaryNetWagePerHourSource.DealerContract && this.positionData.unitPrice > 0
        )
      ) {
        this.editUnitPrice(foundEpn.price);
        this.editQuantity(1);
      }
    }
  }

  /**
   * Clear all fields of the position. If the resetAllFields is not been set or set to false,
   * the activityTypeClass wont be cleared.
   */
  private clear(resetAllFields?: boolean) {
    if (this.isDMS) {
      return;
    }
    this.clearActivityTypeKey();
    this.editDiscount(0.0);
    this.editGoodwill(0);
    this.editMaterialGoodwill(0);
    this.editNetFixedPrice(0);
    this.editQuantity(0);
    this.editUnitPrice(0.0);
    if (resetAllFields) {
      this.isValidExternalProductNumber = false;
      if (['WarrantyPosition', 'ServicePosition', 'WorkPosition'].includes(this.positionData.itemType)) {
        this.positionData.activityTypeClassKey = undefined;
        this.possibleActivityTypeClassesByEpn = [];
        this.selectActivityTypeClasses();
      }
    }
  }

  private updateItemTypeForDb() {
    this.positionData.itemTypeForDb =
      this.itemType === 'WorkPosition' && this.isMaterialGoodwillVisible ? 'ServicePosition' : this.itemType;
    if (this.isMaterialGoodwillVisible && !this.hasMaterialGoodwill) {
      this.positionData.materialGoodwill = 0;
    }
  }

  private updateValidators() {
    const errors = this.positionData.validationErrors ?? new Set<string>();
    for (const group of this.inputGroups) {
      group.backendValidationError = errors.has(group.name);
    }
    for (const dropdown of this.dropdowns) {
      dropdown.backendValidationError = errors.has(dropdown.formControlName);
    }
    // Expand position panel only of the validation error is related to specific position property not to the whole position
    if (
      this.positionData.validationErrors?.size &&
      !Array.from(this.positionData.validationErrors).some((ve) => ve.includes('RequestPositions'))
    ) {
      this.positionsPanel.open();
    }
  }

  private searchActivityForEpn(allowedItemTypes: ActivityTypeIdentifierCommon[]): boolean {
    for (const at of this.specificAvailableActivityTypes()) {
      const foundEpn = at.externalProductNumbers.find(
        (item: ExternalProductNumber) =>
          item.number.toUpperCase() === this.positionData.externalProductNumber.toUpperCase()
      );

      if (foundEpn) {
        if (allowedItemTypes.includes(this.positionData.itemType)) {
          this.possibleActivityTypeClassesByEpn = at.activityTypeClasses;
          if (at.activityTypeClasses.length === 1) {
            this.positionData.activityTypeClassKey = at.activityTypeClasses[0].id;
          } else {
            this.positionData.activityTypeClassKey = undefined;
          }
        }

        if (this.positionData.activityTypeKey !== at.id) {
          this.positionData.activityTypeKey = at.id;
          // load activity types which will also be necessary for the dropdown options of the input element
          this.selectActivityTypes();
          this.selectActivityTypeClasses();
          this.requestStore.addEventMessage({
            eventName: 'ActivityTypeUpdated',
            payload: { activityType: at.id, positionId: this.positionData.positionId }
          });
        }

        this.updateEpnQuantity(foundEpn);

        return true;
      }
    }

    return false;
  }

  private subscribeRequestFormValueChanges() {
    this.positionFormGroup.valueChanges.subscribe(() => {
      if (this.positionFormGroup.dirty) {
        this.unsavedChangesStateStore.addEventMessage('Dirty');
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * When component is initialized this method will prepare availableActivityTypes based
   * on availableActivityTypes from response content
   */
  selectAvailableActivityTypes() {
    this.availableActivityTypes = this.getAvailableActivityTypesByItemType(this.itemType);
  }

  /**
   * When component is initialized this method will prepare activityTypesWithAssignedClass
   * based on already prepared availableActivityTypes in selectAvailableActivityTypes() method
   */
  selectActivityTypesWithAssignedClass() {
    const selectedItems: ActivityType[] = [];

    this.availableActivityTypes.forEach((activityType) => {
      const allowedItemTypes = [this.itemType];
      if (allowedItemTypes.indexOf(activityType.itemType) < 0) {
        return;
      }

      if (activityType.activityTypeClasses.length === 0) {
        return;
      }

      selectedItems.push(activityType);
    });

    this.activityTypesWithAssignedClass = sort(
      selectedItems,
      'description',
      this.requestContent.isActivityTypesSortingAscending ? 'asc' : 'desc'
    );
  }

  /**
   * Returns only activity types for the actual selected activity type class.
   */
  selectActivityTypes() {
    const selectedItems: ActivityType[] = [];
    const classKey = this.positionData.activityTypeClassKey;

    this.availableActivityTypes
      .filter((at) => !this.tenantSettingsService.emptyPositions.includes(at.name))
      .forEach((activityType) => {
        const allowedItemTypes = [this.itemType];
        if (allowedItemTypes.indexOf(activityType.itemType) < 0) {
          return;
        }

        const isInArray = activityType.activityTypeClasses.some(
          (activityTypeClass) => activityTypeClass.id === classKey
        );
        // DMS requests always contain an `externalProductNumber` that probably does not match with our known ones.
        // Therefore, we can circumvent the `hasExternalProductNumber` check for DMS requests here, because we still need to filter the activity types.
        if ((!this.hasExternalProductNumber || this.isDMS) && !isInArray) {
          return;
        }

        selectedItems.push(activityType);
      });

    this.activityTypes = sort(
      selectedItems,
      'description',
      this.requestContent.isActivityTypesSortingAscending ? 'asc' : 'desc'
    );

    if (
      this.positionData.activityTypeKey &&
      this.activityTypes.length === 1 &&
      this.positionData.activityTypeKey !== this.activityTypes[0].id
    ) {
      this.positionData.activityTypeKey = this.activityTypes[0].id;
      this.requestStore.addEventMessage({
        eventName: 'ActivityTypeUpdated',
        payload: { activityType: this.positionData.activityTypeKey, positionId: this.positionData.positionId }
      });
    }
  }

  selectActivityTypeClasses() {
    const selectedItems: ActivityTypeClass[] = [];
    const restrictClasses = this.possibleActivityTypeClassesByEpn.length > 0;
    this.availableActivityTypeClasses
      .filter((at) => !this.tenantSettingsService.emptyPositions.includes(at.name))
      .forEach((activityTypeClass) => {
        if (!restrictClasses || this.possibleActivityTypeClassesByEpn.some((atc) => atc.id === activityTypeClass.id)) {
          selectedItems.push(activityTypeClass);
        }
      });

    this.activityTypeClasses = sort(
      selectedItems,
      'description',
      this.requestContent.isActivityTypeClassesSortingAscending ? 'asc' : 'desc'
    );
  }

  hasUndeletedSubitems(): boolean {
    return this.positionData.subitems.filter((p) => !p.isPositionMarkedAsDeleted).length > 0;
  }

  filterActivityTypes() {
    const restrictByClass = !(this.hasExternalProductNumber && this.isValidExternalProductNumber) && !this.isDMS;

    let selectedRentalReason;
    const parent = this.resolveParentPosition();
    let parentSelectedActivityType = null;
    if (parent) {
      parentSelectedActivityType = this.selectedActivityTypeForPosition(parent);
      const rentalReasonId = parent.rentalReasonId;
      if (rentalReasonId) {
        selectedRentalReason = this.requestContent.availableRentalReasons.find(
          (rentalReason) => rentalReason.id === rentalReasonId
        );
      }
    }

    const allActivityTypes = this.requestAvailableActivityTypes;
    const atc = this.positionData.activityTypeClassKey;
    const itemType = this.positionData.itemType;

    if (!atc && restrictByClass) {
      this.filteredActivityTypes = [];
      return;
    }

    const filteredActivityTypes: ActivityType[] = this.filterAllActivityTypes(
      allActivityTypes,
      itemType,
      selectedRentalReason,
      parentSelectedActivityType,
      restrictByClass,
      atc
    );

    this.filteredActivityTypes = sort(
      filteredActivityTypes,
      'description',
      this.requestContent.isActivityTypesSortingAscending ? 'asc' : 'desc'
    );
  }

  displayDescription() {
    if (this.hasNoComponent) {
      if (this.positionData.activityTypeDescriptionOriginal) {
        return this.positionData.activityTypeDescriptionOriginal;
      }

      if (this.isDMS) {
        return this.positionData.externalPositionDescription;
      }

      return '';
    }

    let result =
      this.availableActivityTypes.find((activityType) => activityType.id === this.positionData.activityTypeKey)
        ?.description ?? '';

    if (this.positionData.rentalReason) {
      result += ' / ' + this.positionData.rentalReason.description;
    }

    if (this.isDMS) {
      result += ' / ' + this.positionData.externalPositionDescription;
    }
    return result;
  }

  editQuantity(value?: string | number) {
    this.positionData.quantity = this.ensureInclusiveBetween(value, 0);
  }

  editUnitPrice(value?: string | number) {
    this.positionData.unitPrice = this.ensureInclusiveBetween(value, 0);
  }

  editNetFixedPrice(value?: string | number) {
    this.positionData.netFixedPrice = this.ensureInclusiveBetween(value, 0);
  }

  editDiscount(value?: string | number) {
    this.positionData.discount = this.ensureInclusiveBetween(value, 0, 100);
  }

  editGoodwill(value?: string | number) {
    this.positionData.goodwill = this.ensureInclusiveBetween(value, 0, 100);
  }

  editMaterialGoodwill(value?: string | number) {
    this.positionData.materialGoodwill = this.ensureInclusiveBetween(value, 0, 100);
  }

  editSagaId(value?: string) {
    this.positionData.sagaId = value;
  }

  private clearActivityTypeKey() {
    this.positionData.activityTypeKey = undefined;
  }

  /**
   * Sets activity type and the first existing activity type class to the position view model (Tire, Mobility).
   */
  editActivityTypeKeyWithClass(value: number) {
    // this is necessary in case the property should be updated from other methods (e.g. when updating rentalReason)
    this.positionData.activityTypeKey = value;

    const foundActivityType = this.availableActivityTypes.find((activityType) => activityType.id === value);
    if (foundActivityType) {
      // Take the first activity type class id.
      const firstActivityTypeClassId = foundActivityType.activityTypeClasses[0]?.id;
      // Set activity type class id.
      this.positionData.activityTypeClassKey = firstActivityTypeClassId;

      this.selectUnits();
      this.displayQuantityUnit();

      this.initializeDealerPrice();
      this.presetDiscountFromActivityType();
    }
  }

  displayQuantityUnit() {
    let result = '';
    const units = this.units;
    if (!units) return result;

    const first = units[0];
    if (typeof first !== 'undefined') {
      result = first.descriptionLong;
    }

    if (typeof first !== 'undefined') {
      if (!this.positionData.quantityUnit || this.positionData.quantityUnit !== first.id) {
        this.positionData.quantityUnit = first.id;
      }
    }

    if (!this.hasUnitSelection) {
      if (typeof first === 'undefined') {
        this.positionData.quantityUnit = undefined;
        return '';
      }

      return result;
    }

    result = units?.find((unit) => unit.id === this.positionData.quantityUnit)?.descriptionLong ?? '';
    return result;
  }

  selectUnits() {
    if (this.hasNoComponent) {
      this.units = [];
    }

    this.units = this.getPositionUnits();
  }

  updateHasValidationErrors() {
    this.hasValidationError = Object.keys(this.positionFormGroup.controls).some(
      (c) => this.positionFormGroup.controls[c].invalid && this.positionFormGroup.controls[c].touched
    );
    this.changeDetector.detectChanges();
  }

  /**
   * Gets the sorted damage types depending on the actual sorting configuration.
   */
  sortedDamageTypes() {
    let result;
    let damageTypes;

    const selectedActivityType = this.selectedActivityType();
    if (selectedActivityType) {
      damageTypes = selectedActivityType.damageTypes;
    }

    result = this.filterAndSortDamageTypes(damageTypes);

    if (!result || result.length === 0) {
      result = this.sortedDefaultDamageTypes();
    }

    return result;
  }

  /**
   * Returns an ActivityType by key for actual positions item type.
   */
  getActivityTypeByKey(activityTypeKey: string): ActivityType | undefined {
    return this.specificAvailableActivityTypes().find((activityType) => activityType.name === activityTypeKey);
  }

  canAddSubitem() {
    if (!this.positionData.activityTypeClassKey || this.positionData.isInnerPosition) {
      return false;
    }

    const atc = this.positionData.activityTypeClassKey;
    const subitemType = this.positionData.subitemType;
    const possibleSubitems = this.filterActivityTypesByClassAndItemType(atc, subitemType);

    return possibleSubitems.length > 0;
  }

  filterActivityTypesByClassAndItemType(activityTypeClassKey: number, itemType: ActivityTypeIdentifierCommon) {
    if (!activityTypeClassKey) return [];

    const filteredActivityTypes = [];

    for (const current of this.requestAvailableActivityTypes) {
      if (itemType && current.itemType !== itemType) {
        continue;
      }

      for (const currentAtc of current.activityTypeClasses) {
        if (currentAtc.id === activityTypeClassKey) {
          filteredActivityTypes.push(current);
        }
      }
    }

    return filteredActivityTypes;
  }

  updateActivityTypeClassKey(): void {
    if (!this.hasExternalProductNumber || this.isDMS) {
      this.clear();
      this.selectActivityTypes();
    }
  }

  addSparePart(itemType: ActivityTypeIdentifierCommon | undefined = undefined): void {
    this.requestService.addPositionTo(this.requestContent, this.index, itemType).subscribe({
      next: (res: RequestContent) => {
        this.requestContent.requestPositions = res.requestPositions;
        this.requestStore.addEventMessage({ eventName: 'AddNewPosition', payload: this.positionData.positionId });
        this.unsavedChangesStateStore.addEventMessage('Dirty');
      }
    });
  }

  editEpn(value?: string, itemTypes: ActivityTypeIdentifierCommon[] = []) {
    this.requestStore.addEventMessage({
      eventName: 'EpnValueUpdated',
      debounceMillis: 200,
      payload: { value, itemTypes, positionId: this.positionData.positionId }
    });
  }

  editEpnForItemTypes(value: string, allowedItemTypes: ActivityTypeIdentifierCommon[]): void {
    let isValidEpn = false;
    this.positionData.externalProductNumber = value.trim();

    if (this.positionData.externalProductNumber !== '') {
      isValidEpn = this.searchActivityForEpn(allowedItemTypes);
    }

    this.isValidExternalProductNumber = isValidEpn;

    this.filterActivityTypes();
    if (!isValidEpn) {
      this.possibleActivityTypeClassesByEpn = [];
      this.selectActivityTypeClasses();
    }
  }

  editDamageId(value: number): void {
    if (value) {
      this.positionData.damageTypeId = value;
      this.requestStore.addEventMessage({
        eventName: 'UpdateDamageId',
        payload: { parentId: this.positionData.positionId, damageId: value }
      });
    }
  }

  updateDamageId(damageId: number): void {
    const allowedItemTypeForUpdate: ActivityTypeIdentifierCommon[] = [
      'SparePart',
      'TireSparePart',
      'WarrantySparePart'
    ];
    if (allowedItemTypeForUpdate.includes(this.positionData.itemType)) {
      this.positionData.damageTypeId = damageId;
    }
  }

  private toggleDeletePosition(positionId: string, toggleDelete: boolean): void {
    // Modify if the parent was deleted
    if (this.positionData.parentPositionId === positionId) {
      if (this.positionData.isPositionMarkedAsDeleted !== toggleDelete) {
        this.toggleDeleteSelf(toggleDelete);
      } else {
        this.isParentDeleted = toggleDelete;
      }
    } else {
      // Modify if a child was deleted
      const child = this.positionData.subitems.find((p) => p.positionId == positionId);
      if (child) {
        child.isPositionMarkedAsDeleted = toggleDelete;
        child.isPositionVisible = !toggleDelete;
      }
    }
  }

  private highlightInvalidControls(): void {
    if (this.positionFormGroup.invalid) {
      this.positionData.validationErrors ??= new Set<string>();
      for (const field in this.positionFormGroup.controls) {
        const control = this.positionFormGroup.controls[field];
        if (control.invalid) {
          this.positionData.validationErrors?.add(field);
          control.markAsTouched();
        }
      }
      this.positionsPanel.open();
    }
  }

  protected getPositionUnits(): Unit[] {
    return (
      this.availableActivityTypes.find((activityType) => activityType.id === this.positionData.activityTypeKey)
        ?.units ?? []
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Parent component needed methods
  // -----------------------------------------------------------------------------------------------------

  savePosition(): void {
    this.savedPosition.emit();
  }

  removePosition(): void {
    this.removedPosition.emit(this.positionData);
  }

  togglePosition(collapseOthersOnExpand: boolean = false): void {
    this.positionsPanel.toggle();
    this.positionData.isExpanded = !this.positionData.isExpanded;

    // collapse all subitems, if the parent will be collapsed
    if (this.hasUndeletedSubitems() && !this.positionsPanel.expanded) {
      this.requestStore.addEventMessage({
        eventName: 'ChangeExpandStateOfChildren',
        payload: { expanded: this.positionsPanel.expanded, parentPositionId: this.positionData.positionId }
      });
    }

    if (this.positionsPanel.expanded && collapseOthersOnExpand) {
      this.requestStore.addEventMessage({
        eventName: 'CollapseExceptPositionIndex',
        payload: { positionIndex: this.positionData.laborPositionIndex }
      });
    }
  }

  isEnabled(position: RequestPosition, parameter: string) {
    return this.positionConfigurationService.isEnabled(
      position.itemType,
      this.productType,
      parameter,
      position.sourceApplication
    );
  }

  prepareRequestForEdit(): void {
    this.itemType = this.positionData.itemType == 'ServicePosition' ? 'WorkPosition' : this.positionData.itemType;
  }
}
