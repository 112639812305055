<div *transloco="let t" class="general-information">
  <h2 class="subtitle ui-test--request-general-information-header-edit-general-information">
    {{ t('Request_Edit_GeneralInformation') }}
  </h2>
  <div class="flex-row">
    <div class="flex-col flex-grow-50 column-data">
      <div class="flex-row justify-center align-center gap-15">
        <strong
          class="flex flex-grow-50 justify-end content-center align-center ui-test--request-general-information-strong-model-name-label">
          {{ t('Common_ModelName') }}
        </strong>
        <span
          class="flex flex-1 justify-start content-center align-center ui-test--ui-test--request-general-information-span-model-name">
          {{ generalInformation.modelName }}
        </span>
      </div>

      <div class="flex-row justify-center align-center gap-15">
        <strong
          class="flex flex-grow-50 justify-end content-center align-center ui-test--ui-test--request-general-information-strong-license-plate-number-label">
          {{ t('Common_LicensePlateNumber') }}
        </strong>
        <span
          class="flex flex-1 justify-start content-center align-center ui-test--ui-test--request-general-information-span-license-plate-number">
          {{ generalInformation.licensePlateNumber }}
        </span>
      </div>

      <div class="flex-row justify-center align-center gap-15">
        <strong
          class="flex flex-grow-50 justify-end content-center align-center ui-test--ui-test--request-general-information-strong-vehicle-identification-number-label">
          {{ t('Common_VehicleIdentificationNumber') }}
        </strong>
        <span
          class="flex flex-1 justify-start content-center align-center ui-test--ui-test--request-general-information-span-vehicle-identification-number">
          {{ generalInformation.vehicleIdentificationNumber }}
        </span>
      </div>

      <div class="flex-row justify-center align-center gap-15">
        <strong
          class="flex flex-grow-50 justify-end content-center align-center ui-test--ui-test--request-general-information-strong-date-of-first-registration-label">
          {{ t('Common_DateOfFirstRegistration') }}
        </strong>
        <span
          class="flex flex-1 justify-start content-center align-center ui-test--ui-test--request-general-information-span-date-of-first-registration">
          {{ generalInformation.dateOfFirstRegistration | dateFormat }}
        </span>
      </div>

      <div class="flex-row justify-center align-center gap-15" *ngIf="requestContent.isDateOfEventVisible">
        <strong
          class="flex flex-grow-50 justify-end content-center align-center ui-test--ui-test--request-general-information-strong-date-of-event-label">
          {{ t('Request_' + requestContent.requestProcess.productType + '_DateOfEvent') }}
        </strong>
        <span
          class="flex flex-1 justify-start content-center align-center ui-test--ui-test--request-general-information-span-date-of-event">
          {{ generalInformation.dateOfEvent | dateFormat }}
          <mat-icon
            class="ui-test--ui-test--request-general-information-mat-icon-date-of-event"
            *ngIf="!isDisplayOnly"
            [matTooltip]="t('ContractInformationViewModel_' + requestContent.requestProcess.editProductType + '_DateOfEvent_Popover_Content')"
            matTooltipPosition="above">
            info
          </mat-icon>
        </span>
      </div>

      <ng-container *ngIf="requestContent.requestProcess.editProductType === 'InsuranceRepair' && !isDisplayOnly">
        <div class="flex-row justify-center align-center gap-15">
          <strong
            class="flex flex-grow-50 justify-end content-center align-center ui-test--ui-test--request-general-information-strong-deductible-amount-label">
            {{ t('ContractInformationViewModel_DeductibleAmount') }}
          </strong>
          <span
            class="flex flex-1 justify-start content-center align-center ui-test--ui-test--request-general-information-span-deductible-amount">
            {{ generalInformation.deductibleAmount | currencyOrDash }} {{
            requestContent.requestProcess.displayCurrencyUnit }}
          </span>
        </div>
        <div class="flex-row justify-center align-center gap-15">
          <strong
            class="flex flex-grow-50 justify-end content-center align-center ui-test--ui-test--request-general-information-strong-deductible-quantity-label">
            {{ t('ContractInformationViewModel_DeductibleQuantity') }}
          </strong>
          <mat-form-field class="flex flex-1">
            <input
              class="ui-test--ui-test--request-general-information-input-deductible-quantity"
              matInput
              #deductibleQuantity
              autocomplete="off"
              [ngModel]="generalInformation.deductibleQuantity"
              (ngModelChange)="activateUnsavedChangesPopup()"
              (blur)="onDeductibleQuantityChange(deductibleQuantity.value)" />
          </mat-form-field>
        </div>
      </ng-container>
    </div>

    <div class="flex-col flex flex-1 column-data">
      <div class="flex-row justify-center align-center gap-15">
        <strong
          class="flex flex-grow-50 justify-end content-center align-center ui-test--ui-test--request-general-information-strong-contract-number-label">
          {{ t('Common_ContractNumber') }}
        </strong>
        <span
          class="flex-grow-50 justify-start content-center align-center ui-test--ui-test--request-general-information-span-contract-number">
          {{ generalInformation.contractNumber }}
        </span>
      </div>

      <div class="flex-row justify-center align-center gap-15">
        <strong
          class="flex flex-grow-50 justify-end content-center align-center ui-test--ui-test--request-general-information-strong-vehicle-acceptance-date-label">
          {{ t('Common_VehicleAcceptance') }}
        </strong>
        <span
          class="flex-grow-50 justify-start content-center align-center ui-test--ui-test--request-general-information-span-vehicle-acceptance-date">
          {{ generalInformation.vehicleAcceptance | dateFormat }}
        </span>
      </div>

      <div class="flex-row justify-center align-center gap-15">
        <strong
          class="flex flex-grow-50 justify-end content-center align-center ui-test--ui-test--request-general-information-strong-actual-mileage-label">
          {{ t('Common_ActualMileage') }}
        </strong>
        <div
          class="flex-row flex-grow-50 justify-start content-center align-center ui-test--ui-test--request-general-information-div-actual-mileage"
          *ngIf="!isDisplayOnly; else displayActualMileage">
          {{ generalInformation.actualMileage | mileageFormat }}
          <div class="mileage-row ml-15">
            <button
              class="ui-test--ui-test--request-general-information-button-mileage-edit"
              mat-raised-button
              (click)="openEditDialog()"
              [matTooltip]="t('Request_Mileage_Tooltip_Edit')"
              matTooltipPosition="above"
              [disabled]="isDMS"
              *ngIf="configurationService.enableMileageUpdate">
              <mat-icon class="ui-test--ui-test--request-general-information-mat-icon-mileage-edit">edit</mat-icon>
            </button>
            <button
              class="ui-test--ui-test--request-general-information-button-mileage-update"
              mat-raised-button
              (click)="openConfirmationDialog()"
              [matTooltip]="t('Request_Mileage_Tooltip_SearchAgain')"
              matTooltipPosition="above"
              [disabled]="isDMS"
              *ngIf="configurationService.enableMileageUpdate">
              <mat-icon class="ui-test--ui-test--request-general-information-mat-icon-mileage-update">
                autorenew
              </mat-icon>
            </button>
            <button
              class="ui-test--ui-test--request-general-information-button-mileage-info"
              mat-raised-button
              (click)="openInfoDialog()"
              *ngIf="!configurationService.enableMileageUpdate">
              <mat-icon class="ui-test--ui-test--request-general-information-mat-icon-mileage-info">
                info_outline
              </mat-icon>
            </button>
          </div>
        </div>
        <ng-template #displayActualMileage>
          <span
            class="flex-grow-50 justify-start content-center align-center ui-test--ui-test--request-general-information-span-mileage">
            {{ generalInformation.actualMileage | mileageFormat }}
          </span>
        </ng-template>
      </div>

      <div class="flex-row justify-center align-center gap-15">
        <strong
          class="flex flex-grow-50 justify-end content-center align-center ui-test--ui-test--request-general-information-strong-workshop-order-number-label">
          {{ t('Common_WorkshopOrderNumber') }}
        </strong>
        <div class="flex-grow-50 align-center" *ngIf="!isDisplayOnly; else displayWorkshopOrderNumber">
          <mat-form-field>
            <input
              class="workshop-order-number ui-test--ui-test--request-general-information-input-workshop-order-number"
              matInput
              maxlength="40"
              [(ngModel)]="generalInformation.workshopOrderNumber"
              (ngModelChange)="activateUnsavedChangesPopup()"
              [disabled]="isDMS" />
          </mat-form-field>
        </div>
        <ng-template #displayWorkshopOrderNumber>
          <span
            class="flex-grow-50 justify-start content-center align-center ui-test--ui-test--request-general-information-span-workshop-order-number">
            {{ generalInformation.workshopOrderNumber }}
          </span>
        </ng-template>
      </div>

      <div
        class="flex-row justify-center align-center gap-15"
        *ngIf="generalInformation.isInsuranceReferenceNumberActive">
        <strong
          class="flex flex-grow-50 justify-end content-center align-center ui-test--ui-test--request-general-information-strong-insurance-reference-number-label">
          {{ t('Common_InsuranceReferenceNumber') }}*
        </strong>
        <div class="flex-grow-50" *ngIf="!isDisplayOnly && !isDMS; else displayInsuranceReferenceNumber">
          <mat-form-field>
            <input
              class="ui-test--ui-test--request-general-information-input-insurance-reference-number"
              matInput
              required
              [(ngModel)]="generalInformation.insuranceReferenceNumber"
              (ngModelChange)="activateUnsavedChangesPopup()"
              maxlength="50" />
          </mat-form-field>
        </div>
        <ng-template #displayInsuranceReferenceNumber>
          <span
            class="flex flex-1 justify-start content-center align-center ui-test--ui-test--request-general-information-span-insurance-reference-number">
            {{ generalInformation.insuranceReferenceNumber }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</div>
