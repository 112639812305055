<div *transloco="let t" class="flex-col gap-10 mt-20">
  <div *ngIf="statusDetails.isLeasingReturnUserAnswerVisible">
    <h3 class="section-title ui-test--sdd-answer-header-leasing-return">
      {{ t('Common_RequestDetailIsLeasingReturnHead') }}
    </h3>

    <div class="flex-col justify-start content-stretch mb-20">
      <div class="flex-row justify-start content-center">
        <strong class="flex flex-grow-50 justify-end pr-15 ui-test--sdd-answer-strong-leasing-return-label">
          {{ t('Request_IsLeasingReturnContent') }}
        </strong>
        <span class="flex flex-1 ui-test--sdd-answer-span-leasing-return">
          {{ statusDetails.isLeasingReturnUserAnswer | yesNoUnknown }}
        </span>
      </div>
    </div>
  </div>

  <div *ngFor="let questionnaire of statusDetails.questionnaires; let i = index">
    <h3 class="section-title" [class]="'ui-test--sdd-answer-header-section-' + i">{{ questionnaire.displayText }}</h3>

    <div class="flex-col justify-start content-stretch">
      <div
        class="flex-row justify-start content-center mb-5"
        *ngFor="let question of questionnaire.questions; let index = index">
        <strong
          class="flex flex-grow-50 pr-15 justify-end"
          [class]="'ui-test--sdd-answer-strong-question-' + index + '-section-' + i">
          {{ question.displayText }}
        </strong>
        <span
          class="flex flex-1"
          [class]="'ui-test--sdd-answer-span-answer-' + index + '-section-' + i"
          [innerHtml]="getQuestionAnswerType(question) | answerRender"></span>
      </div>
    </div>
  </div>
</div>
