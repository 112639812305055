<ng-container *transloco="let t">
  <h2 mat-dialog-title>{{ t("Common_Warning") }}</h2>
  <div mat-dialog-content class="flex-grow-90 mb-10">
    <span>{{ t("Browser_Firefox_Hint") }} </span>
    <!--
      Using `[innerText]` here on purpose.
      Otherwise due to formatting, a leading space is added to the anchor text
    -->
    <a
      [href]="t('Browser_Firefox_HintLink')"
      target="_blank"
      (click)="onLinkClick()"
      [innerText]="t('Browser_Firefox_HintLinkText')">
    </a>
  </div>

  <mat-dialog-actions align="end">
    <button mat-flat-button color="primary" [mat-dialog-close]="true">{{ t('Common_OK') }}</button>
  </mat-dialog-actions>
</ng-container>
