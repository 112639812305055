import { Pipe, PipeTransform } from '@angular/core';
import { hintReplace } from '@core/utils';

/**
 * Set property for hint/error in form field component with translation
 */
@Pipe({
  name: 'hintReplacer'
})
export class HintReplacerPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value A text based on translation with some missing information like property name
   */
  transform(value: string, propertyName: string): string {
    return hintReplace(value, propertyName);
  }
}
