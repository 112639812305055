<ng-container *transloco="let t">
  <validation-summary [errorList]="errorList"></validation-summary>

  <mat-card *ngIf="this.errorList.length > 0" class="message-state error">
    <mat-card-content class="ui-test--offer-panel-mat-card-content-search-failed">
      {{t('OfferPanelComponents_Error_SearchFailed')}}
    </mat-card-content>
  </mat-card>

  <div class="offers-area list-group-item-info mb-5 justify-start content-center" *ngIf="offerSearchLoading">
    <div class="spinner"></div>
    <span class="ui-test--offer-panel-span-searching">{{ t('OfferPanelComponents_Searching') }}</span>
  </div>

  <div *ngIf="potentialOffersLength > 0 && !isMinimumRequiredParameterMissing" class="list-group-item-info mb-5">
    <span class="ui-test--offer-panel-span-additional-information">
      {{ potentialOffersLength }} {{ t('OfferPanelComponents_Hint_OffersNeedAdditionalInformation') }}
    </span>
  </div>

  <!-- spanish tenant warning area-->
  <div class="list-group-item-warning text-align-center" *ngIf="isMinimumRequiredParameterMissing && offers.length > 0">
    <p class="text-style ui-test--offer-panel-paragraph-warning">
      {{ t('OfferPanelComponents_Warning_MissingMandatoryParameters') }}
    </p>
    <button class="ui-test--offer-panel-button-edit-search-criteria" mat-raised-button (click)="editSearchCriteria()">
      {{ t('OfferPanelComponents_Button_EditSearchCriteria') }}
    </button>
  </div>
  <!-- end of spanish tenant warning area-->

  <div *ngIf="offers.length > 0 && !offerSearchLoading && !isMinimumRequiredParameterMissing">
    <div *ngFor="let offer of offers; let index = index">
      <div class="list-group-item-offer mb-5" *ngIf="!offer.isPotentialOffer && tenantService.disableOfferDetailsPage">
        <h5
          class="list-group-item-heading-offer font-size-15"
          [class]="'ui-test--offer-panel-header-offer-label-' + index">
          {{ offer.label }}
        </h5>
        <div class="text-align-center">
          <p class="text-style" [class]="'ui-test--offer-panel-paragraph-offer-text-' + index">{{ offer.text }}</p>
          <button
            [class]="'ui-test--offer-panel-button-shopping-cart-' + index"
            mat-raised-button
            color="primary"
            (click)="applyOffer(offer)">
            <mat-icon [class]="'ui-test--offer-panel-mat-icon-shopping-cart-' + index">add_shopping_cart</mat-icon>
            {{ t('OfferPanelComponents_Button_Application') }}
          </button>
        </div>
      </div>

      <!-- spanish tenant extra area-->
      <div
        class="list-group-item-offer mb-5"
        *ngIf="!offer.isPotentialOffer && offer.variants.length >=0  && !tenantService.disableOfferDetailsPage">
        <offer-dashboard
          class="ui-test--offer-panel-offer-dashboard-potential-offer"
          [offer]="offer"
          [offers]="offers"
          [responseGuid]="responseGuid"
          [visibleDashboardColumns]="visibleDashboardColumns"
          [visibleDetailColumns]="visibleDetailsColumns"></offer-dashboard>
      </div>
      <!-- end of spanish tenant extra area-->

      <div class="list-group-item mb-5" *ngIf="offer.isPotentialOffer">
        <h5
          class="list-group-item-heading font-size-15"
          [class]="'ui-test--offer-panel-header-potential-offer-label-' + index">
          {{offer.label}}
        </h5>
        <div class="text-align-center">
          <p class="text-style" [class]="'ui-test--offer-panel-paragraph-potential-offer-' + index">
            {{ t('OfferPanelComponents_Hint_AdditionalInformationRequired') }}
          </p>
          <button
            mat-raised-button
            (click)="editSearchCriteria(offer)"
            [class]="'ui-test--offer-panel-button-search-' + index">
            {{ t('OfferPanelComponents_Button_EditSearchCriteria') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="list-group-item mb-5"
    *ngIf="!offerSearchLoading && offers.length > 0  && !isMinimumRequiredParameterMissing">
    <span class="ui-test--offer-panel-span-offers-found">
      {{offers.length}} {{ t('OfferPanelComponents_OffersFoundSuffix') }}
    </span>
  </div>
  <div class="list-group-item mb-5 text-style" *ngIf="!this.offerSearchLoading && offers.length === 0">
    <span class="ui-test--offer-panel-span-no-result">{{ t('OfferPanelComponents_NoSearchResult') }}</span>
    <br />
    <span class="ui-test--offer-panel-span-show-teide-for-offer">
      {{ t('OfferPanelComponents_ShowTeideForOffer') }}
    </span>
    <button
      class="ui-test--offer-panel-button-show-teide-for-offer-button"
      mat-raised-button
      color="primary"
      (click)="applyOffer()"
      *ngIf="!tenantService.disableOfferDetailsPage">
      <mat-icon class="ui-test--offer-panel-mat-icon-show-teide-for-offer-button">add_shopping_cart</mat-icon>
      {{ t('OfferPanelComponents_Button_Application') }}
    </button>
  </div>
</ng-container>
