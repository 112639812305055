<div class="flex-row gap-15 claim-position-filter">
  <section
    *ngFor="let groupedActivityTypesByClass of groupedSelectObj | keyvalue:asIsOrder, let i = index"
    class="flex-grow-25">
    <h3
      class="center-center gap-5 mb-5 section-title"
      [class]="'ui-test--request-claim-position-filter-header-title-' + i">
      <mat-icon
        *ngIf="isActivityTypeClassSelected(groupedActivityTypesByClass.value.id)"
        [class]="'ui-test--request-claim-position-filter-mat-icon-check-' + i">
        check_circle
      </mat-icon>
      <span [class]="'ui-test--request-claim-position-filter-span-description-' + i">
        {{ groupedActivityTypesByClass.value.description }}
      </span>
    </h3>
    <mat-selection-list [multiple]="false">
      <mat-list-option
        *ngFor="let activityType of groupedActivityTypesByClass.value.activityTypes; let index = index"
        [class]="'ui-test--request-claim-position-filter-mat-list-option-option-' + index + '-title-' + i"
        [value]="activityType.id"
        [disabled]="isDisabled(groupedActivityTypesByClass.value.id)"
        [selected]="isActivityTypeSelectedInClass(activityType.id, groupedActivityTypesByClass.value.id)"
        (click)="toggleSelection(activityType.itemType, activityType.id, groupedActivityTypesByClass.value.id)">
        {{ activityType.description }}
      </mat-list-option>
    </mat-selection-list>
  </section>
</div>
