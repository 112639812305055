import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextDataService } from '@core/services';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';
import { mergeMap, Observable } from 'rxjs';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService, private contextDataService: ContextDataService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.oidcSecurityService.getAccessToken(this.contextDataService.identityProvider).pipe(
      mergeMap((token) => {
        // only add a valid token to requests that target our backend API.
        if (token == '' || !req.url.startsWith(environment.isportCoreApiUrl)) {
          return next.handle(req);
        }

        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(req);
      })
    );
  }
}
