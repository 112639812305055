<div class="spinner" *ngIf="loading"></div>

<validation-summary [errorList]="errorList"></validation-summary>

<div *transloco="let t">
  <page-header [title]="t('Menu_AftersalesOverview_Index')"></page-header>

  <div class="mb-15" *ngIf="filterCriteria">
    <filter
      [filterCriteria]="filterCriteria"
      [visibleFilterParameters]="['datePickerFrom', 'datePickerTo', 'productList', 'searchButton']"
      (submitFilter)="getData($event)"></filter>
  </div>
  <sort
    [visibleComponents]="['panelExpandButton', 'search']"
    [sortKeys]="sortKeys"
    [panelExpanded]="panelExpanded"
    [searchTooltip]="t('AftersalesOverview_SearchField_Tooltip')"
    (search)="search($event)"
    (sort)="sort($event)"
    (panelExpandedChange)="onPanelExpandedChange($event)"></sort>
  <entries-counter
    *ngIf="overviewData.length > 0 && !loading"
    [shownEntriesCount]="shownEntriesCount"
    [totalEntriesCount]="totalEntriesCount"></entries-counter>
  <div
    class="mb-60"
    *ngIf="!loading && overviewData"
    infinite-scroll
    [infiniteScrollDistance]="5"
    [infiniteScrollThrottle]="500"
    (scrolled)="onScroll()">
    <div class="py-10" *ngFor="let data of overviewData; let i = index">
      <mat-expansion-panel hideToggle click-stop-propagation [expanded]="panelExpanded">
        <mat-expansion-panel-header
          [collapsedHeight]="'90px'"
          [expandedHeight]="'90px'"
          class="disable_ripple flex-row">
          <mat-panel-title class="flex-col justify-start align-start content-between flex-grow-20">
            <div class="w-full flex-grow-100">
              <div>
                <mat-icon [class]="'ui-test--aftersales-overview-mat-icon-product-type-' + i">
                  {{data.productType | productTypeIcon}}
                </mat-icon>
                <strong [class]="'ui-test--aftersales-overview-strong-product-type-' + i">{{data.productName}}</strong>
              </div>
              <request-state-label
                [state]="data.aftersalesProcessState"
                [stateText]="t('Common_' + data.aftersalesProcessState)"
                class="status-overview-box"
                [class]="'ui-test--aftersales-overview-request-state-label-state-' + i"></request-state-label>
            </div>
          </mat-panel-title>
          <mat-panel-title class="flex-grow-25">
            <div class="flex-col justify-center content-between flex-grow-100">
              <div class="flex-row flex-grow-100 gap-20">
                <strong
                  class="flex flex-grow-40 justify-end text-end"
                  [class]="'ui-test--aftersales-overview-strong-customer-label-' + i">
                  {{ t('Common_Customer') }}:
                </strong>
                <div class="flex-grow-60" [class]="'ui-test--aftersales-overview-div-customer-' + i">
                  {{data.customer}}
                </div>
              </div>
              <div class="flex-row justify-center content-evenly flex-grow-100 gap-20">
                <strong
                  class="flex flex-grow-40 justify-end text-end"
                  [class]="'ui-test--aftersales-overview-strong-license-plate-number-label-' + i">
                  {{ t('Common_LicensePlateNumber') }}:
                </strong>
                <div class="flex-grow-60" [class]="'ui-test--aftersales-overview-div-license-plate-number-' + i">
                  {{data.licensePlateNumber}}
                </div>
              </div>
            </div>
          </mat-panel-title>
          <mat-panel-title class="flex-grow-45">
            <div class="flex-col justify-center content-between flex-grow-100">
              <div class="flex-row justify-center content-evenly flex-grow-100 gap-20">
                <strong
                  class="flex flex-grow-35 justify-end text-end"
                  [class]="'ui-test--aftersales-overview-strong-created-date-label-' + i">
                  {{ t('Common_Created') }}:
                </strong>
                <div class="flex-grow-55" [class]="'ui-test--aftersales-overview-div-created-date-' + i">
                  {{data.createdDate | dateFormat}}
                </div>
              </div>
              <div class="flex-row justify-center content-evenly flex-grow-100 gap-20">
                <strong
                  class="flex flex-grow-35 justify-end text-end"
                  [class]="'ui-test--aftersales-overview-strong-updated-date-label-' + i">
                  {{ t('Common_Updated') }}:
                </strong>
                <div class="flex-grow-55" [class]="'ui-test--aftersales-overview-div-update-date-' + i">
                  {{data.modifiedDate | dateFormat}}
                </div>
              </div>
              <div class="flex-row justify-center content-evenly flex-grow-100 gap-20">
                <strong
                  class="flex flex-grow-35 justify-end text-end"
                  [class]="'ui-test--aftersales-overview-strong-service-advisor-label-' + i">
                  {{ t('Common_ServiceAdvisor') }}:
                </strong>
                <div class="flex-grow-55" [class]="'ui-test--aftersales-overview-div-service-advisor-' + i">
                  {{data.serviceAdvisor}}
                </div>
              </div>
            </div>
          </mat-panel-title>
          <mat-panel-title class="flex-grow-7">
            <div class="flex flex-grow-100">
              <div class="flex-grow-50"></div>
              <button
                mat-raised-button
                [class]="'ui-test--aftersales-overview-button-jump-to-journey-' + i"
                [class.button-spinner]="data.loading"
                *ngIf="data.canJumpToJourney"
                class="flex-grow-50"
                aria-label="Jump to process"
                (click)="$event.stopPropagation(); postContinueUrl(data);">
                <mat-icon
                  [class]="'ui-test--aftersales-overview-mat-icon-jump-to-journey-' + i"
                  [class.hidden-icon]="data.loading">
                  arrow_forward
                </mat-icon>
              </button>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="flex-row flex-grow-100 pt-10">
          <div class="flex-col flex-grow-48 gap-10">
            <div class="flex-row justify-center content-evenly flex-grow-100 gap-20">
              <strong
                class="flex flex-grow-30 justify-end text-end"
                [class]="'ui-test--aftersales-overview-strong-vehicle-identification-number-label-' + i">
                {{ t('Common_VehicleIdentificationNumber') }}:
              </strong>
              <div class="flex-grow-70" [class]="'ui-test--aftersales-overview-div-vehicle-identification-number-' + i">
                {{data.chassisNumber}}
              </div>
            </div>
            <div class="flex-row justify-center content-evenly flex-grow-100 gap-20">
              <strong
                class="flex flex-grow-30 justify-end text-end"
                [class]="'ui-test--aftersales-overview-strong-model-name-label-' + i">
                {{ t('Common_ModelName') }}:
              </strong>
              <div class="flex-grow-70" [class]="'ui-test--aftersales-overview-div-model-name-' + i">
                {{data.modelName}}
              </div>
            </div>
          </div>
          <div class="flex-col flex-grow-52 gap-10">
            <div class="flex-row justify-center content-evenly flex-grow-100 gap-20">
              <div class="flex-row flex-grow-100 justify-center content-evenly gap-20">
                <strong
                  class="flex flex-grow-30 justify-end text-end"
                  [class]="'ui-test--aftersales-overview-strong-model-code-label-' + i">
                  {{ t('Common_ModelCode') }}:
                </strong>
                <div class="flex-grow-70" [class]="'ui-test--aftersales-overview-div-model-code-' + i">
                  {{data.modelCode}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <scroll-top-button></scroll-top-button>
    <mat-progress-bar *ngIf="scrollLoading" mode="buffer" class="my-15"></mat-progress-bar>
  </div>
</div>
