import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  ConfigurationService,
  LocaleFormatService,
  RequestEventMessage,
  RequestStateStore,
  TenantSettingsService
} from '@core/services';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { UnsavedChangesStateStore } from 'app/pages/request';
import { RequestService } from 'app/pages/request/request.service';
import { RequestContent } from 'app/pages/request/request.types';

import { GenericPositionComponent } from '../generic-position/generic-position.component';

@Component({
  selector: 'package-position',
  templateUrl: './package-position.component.html'
})
export class PackagePositionComponent extends GenericPositionComponent implements OnInit {
  constructor(
    formBuilder: FormBuilder,
    positionConfigurationService: PositionConfigurationService,
    requestService: RequestService,
    requestStore: RequestStateStore,
    unsavedChangesStateStore: UnsavedChangesStateStore,
    localeFormatService: LocaleFormatService,
    configurationService: ConfigurationService,
    tenantSettingsService: TenantSettingsService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(
      formBuilder,
      positionConfigurationService,
      9,
      requestStore,
      unsavedChangesStateStore,
      requestService,
      localeFormatService,
      configurationService,
      tenantSettingsService,
      changeDetector,
      elementRef
    );
  }

  // Ensure on page reload or edit again, that the disablePriceEdit will be set to true for each child component
  override ngOnInit(): void {
    super.ngOnInit();
    if (this.hasNetFixedPrice) {
      this.disableOrEnablePriceEditForChildren();
    }
    this.eventSubscription = this.requestStore.requestEventMessages.subscribe({
      next: (eventMessage: RequestEventMessage) => {
        if (
          eventMessage.eventName === 'ActivityTypeUpdated' &&
          eventMessage.payload.positionId === this.positionData.positionId
        ) {
          this.enrichPositions(eventMessage.payload.activityType);
        }
      }
    });
  }

  enrichPositions(newValue: number | undefined): void {
    if (newValue && !this.isDMS) {
      this.requestService.enrichPosition(this.requestContent).subscribe({
        next: (res: RequestContent) => {
          this.requestContent.requestPositions = res.requestPositions;
          this.requestStore.addEventMessage({ eventName: 'AddNewPosition', payload: this.positionData.positionId });
        }
      });
    }
  }

  applyNetFixedPriceDisableSubitemPrices(value?: number | string): void {
    const convertedValue = this.ensureInclusiveBetween(value, 0);

    if (convertedValue !== this.positionData.netFixedPrice) {
      this.editNetFixedPrice(convertedValue);
      this.disableOrEnablePriceEditForChildren();
    }
  }

  // Depending of the netFixedPrice the hasNetFixedPrice will decide the state of the child components
  private disableOrEnablePriceEditForChildren(): void {
    const packagePositionId = this.positionData.positionId;
    this.requestStore.addEventMessage({
      eventName: 'DisablePriceEditUpdate',
      payload: { value: this.hasNetFixedPrice, parentPositionId: packagePositionId }
    });
  }
}
