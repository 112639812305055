import { ViewportScroller } from '@angular/common';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Observable, Subject, fromEvent, map, takeUntil } from 'rxjs';

@Component({
  selector: 'scroll-top-button',
  templateUrl: './scroll-top-button.component.html',
  styleUrls: ['./scroll-top-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScrollTopButtonComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  readonly showScroll$: Observable<boolean> = fromEvent(window, 'scroll').pipe(
    takeUntil(this.destroy$),
    map(() => this.viewportScroller.getScrollPosition()[1] > 0)
  );

  constructor(private viewportScroller: ViewportScroller) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onScrollTop(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
