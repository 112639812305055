import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@core/services';
import { TotalAmounts } from 'app/components/request-total-amounts/request-total-amounts.types';
import { StatusDetailsDialogService } from 'app/pages/status-overview/status-details-dialog/status-details-dialog.service';
import { RequestService } from '../request.service';
import { RequestContent } from '../request.types';
import { ProcessBarComponent } from '../shared/process-bar/process-bar.component';
import { CancelRequestSummary } from './cancel.types';

@Component({
  selector: 'request-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RequestCancelComponent implements OnInit {
  @ViewChild(ProcessBarComponent) processBarComponent!: ProcessBarComponent;

  cancelRequestSummary!: CancelRequestSummary;
  requestGuid!: string;
  totalAmounts!: TotalAmounts;
  confirmActionLoading = false;

  constructor(
    private route: ActivatedRoute,
    private requestService: RequestService,
    private statusDetailsDialogService: StatusDetailsDialogService,
    public userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.requestGuid = this.route.snapshot.queryParamMap.get('requestGuid') ?? '';
    this.getCancelRequestData(this.requestGuid);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get requestContent(): RequestContent {
    return this.cancelRequestSummary.requestContent;
  }

  get selectedStep(): string {
    return this.processBarComponent?.steps?.find((step) => step.isActive === true)?.key ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private getCancelRequestData(requestGuid: string) {
    this.requestService.getCancelRequestData(requestGuid).subscribe({
      next: (res: CancelRequestSummary) => {
        this.cancelRequestSummary = res;

        this.setupTotalAmounts();
      }
    });
  }

  private setupTotalAmounts() {
    this.totalAmounts = <TotalAmounts>{
      totalNetAmount: this.cancelRequestSummary.totalNetAmount,
      totalGoodwill: this.cancelRequestSummary.totalGoodwill,
      expectedPayout: this.cancelRequestSummary.expectedPayout,
      isDisplayExpectedPayoutValue: true,
      isDisplayTotalGoodwillValue: this.requestContent.isDisplayTotalGoodwillValue,
      displayCurrencyUnit: this.requestContent.requestProcess.displayCurrencyUnit
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  openStatusDetailsDialog(event: Event) {
    event.stopPropagation();

    this.statusDetailsDialogService.openDialog(
      this.requestContent.versionId,
      this.requestContent.requestProcess.productType,
      'readonly'
    );
  }

  continue() {
    this.confirmActionLoading = true;
    this.requestService.cancelRequest(this.requestGuid).subscribe({
      next: (res: any) => {
        if (res.requestGuid) {
          this.requestGuid = res.requestGuid;
          this.processBarComponent.nextStep();
        }
      },
      complete: () => {
        this.confirmActionLoading = false;
      }
    });
  }
}
