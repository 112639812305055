import { Injectable } from '@angular/core';
import { TenantSettingsService } from '@core/services';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { SourceApplication } from '@core/types/source-application.types';
import { ActivityTypeIdentifierCommon, ConfigurablePositionParameter } from './positions.types';

@Injectable({ providedIn: 'root' })
export class PositionConfigurationService {
  constructor(private tenantSettingsService: TenantSettingsService) {}

  isEnabled(
    identifier: ActivityTypeIdentifierCommon | string,
    productType: ProductTypeIdentifier,
    parameter: ConfigurablePositionParameter | string,
    sourceApplication: SourceApplication = 'SPORT'
  ): boolean {
    if (
      identifier === 'TirePosition' &&
      (parameter === 'ExternalProductNumber' || parameter === 'DamageType' || parameter === 'Quantity')
    ) {
      return true;
    }
    const config = this.loadConfiguration(identifier, sourceApplication, productType);
    return config ? config.includes(parameter) : true;
  }

  getParameters(
    identifier: ActivityTypeIdentifierCommon,
    productType: ProductTypeIdentifier,
    sourceApplication: SourceApplication = 'SPORT'
  ): string[] {
    return this.loadConfiguration(identifier, sourceApplication, productType)?.split('|') ?? [];
  }

  private loadConfiguration(
    identifier: ActivityTypeIdentifierCommon | string,
    sourceApplication: SourceApplication = 'SPORT',
    productType: ProductTypeIdentifier | null = null
  ) {
    if (sourceApplication === 'DMS') {
      return this.getDMSConfiguration(identifier, productType);
    }

    return this.getConfiguration(identifier, productType);
  }

  private getDMSConfiguration(
    identifier: ActivityTypeIdentifierCommon | string,
    productType: ProductTypeIdentifier | null = null
  ) {
    switch (identifier) {
      case 'WorkPosition':
      case 'ServicePosition':
        return productType === 'InsuranceRepair'
          ? this.tenantSettingsService.dmsInsuranceRepairWorkPositionConfiguration
          : this.tenantSettingsService.dmsServiceAndMaintenanceWorkPositionConfiguration;
      case 'TirePosition':
        return this.tenantSettingsService.dmsTirePositionConfiguration;
      case 'TireServicePosition':
        return this.tenantSettingsService.dmsTireServicePositionPositionConfiguration;
      case 'TireSparePart':
        return this.tenantSettingsService.dmsTireSparePartPositionConfiguration;
      case 'SparePart':
        return productType === 'InsuranceRepair'
          ? this.tenantSettingsService.dmsInsuranceRepairSparePartPositionConfiguration
          : this.tenantSettingsService.dMSServiceAndMaintenanceSparePartPositionConfiguration;
      case 'MaintenancePackagePosition':
        switch (productType) {
          case 'Tires':
            return this.tenantSettingsService.dmsTirePackagePositionConfiguration;
          case 'InsuranceRepair':
            return this.tenantSettingsService.dmsInsuranceRepairPackagePositionConfiguration;
          case 'ServiceAndMaintenance':
            return this.tenantSettingsService.dmsServiceAndMaintenancePackagePositionConfiguration;
        }
    }
    return null;
  }

  private getConfiguration(
    identifier: ActivityTypeIdentifierCommon | string,
    productType: ProductTypeIdentifier | null = null
  ) {
    switch (identifier) {
      case 'MobilityPosition':
        return this.tenantSettingsService.mobilityPositionConfiguration;
      case 'MobilitySparePart':
        return this.tenantSettingsService.mobilitySparePartPositionConfiguration;
      case 'WorkPosition':
      case 'ServicePosition':
        return productType === 'InsuranceRepair'
          ? this.tenantSettingsService.insuranceRepairWorkPositionConfiguration
          : this.tenantSettingsService.serviceAndMaintenanceWorkPositionConfiguration;
      case 'WarrantyPosition':
        return this.tenantSettingsService.warrantyPositionConfiguration;
      case 'WarrantySparePart':
        return this.tenantSettingsService.warrantySparePartPositionConfiguration;
      case 'WarrantyDiagnosticPosition':
        return this.tenantSettingsService.warrantyDiagnosticPositionConfiguration;
      case 'TirePosition':
        return this.tenantSettingsService.tirePositionConfiguration;
      case 'TireServicePosition':
        return this.tenantSettingsService.tireServicePositionConfiguration;
      case 'TireSparePart':
        return this.tenantSettingsService.tireSparePartPositionConfiguration;
      case 'SparePart':
        return productType === 'InsuranceRepair'
          ? this.tenantSettingsService.insuranceRepairSparePartPositionConfiguration
          : this.tenantSettingsService.serviceAndMaintenanceSparePartPositionConfiguration;
      case 'MaintenancePackagePosition':
        return this.tenantSettingsService.packagePositionConfiguration;
      default:
        return null;
    }
  }
}
