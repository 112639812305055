<mat-card *transloco="let t">
  <mat-card-title class="ui-test--guidelines-panel-mat-card-title-guidelines-title">
    {{ t('Account_UserProfile_GuidelinesPanel_Title') }}
  </mat-card-title>
  <mat-card-content>
    <div
      class="guideline-item ui-test--guidelines-panel-div-worlshop-guidelines-text"
      *ngIf="userProfileAvailableFields.includes('WorkshopGuidelinesUrl')">
      {{ t('AccountUserProfileViewModel_WorkshopGuidelinesUrl') }}

      <a
        class="workshop-guideline ui-test--guidelines-panel-a-guidelines"
        [href]="workshopGuidelinesUrl"
        target="_blank"
        rel="noopener">
        <mat-icon color="primary" class="ui-test--guidelines-panel-mat-icon-guidelines-info">info_outline</mat-icon>
      </a>
    </div>
    <div
      class="guideline-item ui-test--guidelines-panel-div-guidelines-wkm-title"
      *ngIf="userProfileAvailableFields.includes('ClearingGuidelinesWkmUrl')">
      {{ t('AccountUserProfileViewModel_ClearingGuidelinesWkmUrl') }}

      <a
        class="clearing-guideline-wkm ui-test--guidelines-panel-link-guidelines-wkm"
        [href]="clearingGuidelinesWkmUrl"
        target="_blank"
        rel="noopener">
        <mat-icon class="ui-test--guidelines-panel-mat-icon-guidelines-wkm" color="primary">info_outline</mat-icon>
      </a>
    </div>
    <div
      class="guideline-item ui-test--guidelines-panel-div-guidelines-warranty-title"
      *ngIf="userProfileAvailableFields.includes('ClearingGuidelinesWarrantyUrl')">
      {{ t('AccountUserProfileViewModel_ClearingGuidelinesWarrantyUrl') }}

      <a
        class="clearing-guideline-warranty ui-test--guidelines-panel-link-guidelines-warranty"
        [href]="clearingGuidelinesWarrantyUrl"
        target="_blank"
        rel="noopener">
        <mat-icon class="ui-test--guidelines-panel-mat-icon-guidelines-warranty" color="primary">info_outline</mat-icon>
      </a>
    </div>
    <div
      class="guideline-item ui-test--guidelines-panel-div-is-wkm-dealer-title"
      *ngIf="userProfileAvailableFields.includes('IsWkmDealer')">
      {{ t('AccountUserProfileViewModel_IsWkmDealer') }}

      <strong class="wkm-partner ui-test--guidelines-panel-strong-is-wkm-dealer'">
        {{ isWkmDealer | yesNoUnknown }}
      </strong>
    </div>
  </mat-card-content>
</mat-card>
