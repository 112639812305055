import { Component, OnInit } from '@angular/core';
import { ContextDataService } from '@core/services';

@Component({
  selector: 'forbidden',
  templateUrl: './forbidden.component.html'
})
export class ForbiddenComponent implements OnInit {
  public titleKey: string = 'Error_Forbidden_Title';
  public subtitleKey: string = 'Error_Forbidden_Subtitle';
  public message1Key: string = 'Error_Forbidden_Message1';
  public message2Key: string = 'Error_Forbidden_Message2';

  constructor(private contextDataService: ContextDataService) {}

  ngOnInit(): void {
    this.setMessageFromUrlParameters();
  }

  /**
   * Redirect via auth provider to re-authenticate
   */
  public redirect(): void {
    this.contextDataService.resetContextData();
    window.location.href = '/';
  }

  protected setMessageFromUrlParameters(): void {
    const targetParams = new URLSearchParams(window.location.search);
    const errorMessage = targetParams.get('message');

    if (!errorMessage) {
      return;
    }

    this.message1Key = decodeURIComponent(errorMessage);
  }
}
