import { ErrorHandler, NgModule } from '@angular/core';
/** Disclaimer: We have to import the Request component first. Otherwise we can get "access before initialization" issues */
import { RequestModule } from './pages/request/request.module';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@core/core.module';
import { AppComponent } from './app.component';
import { AppErrorHandler } from './app.error-handler';
import { appRoutes } from './app.routing';
import { InboxNotificationComponent } from './components/inbox-notification/inbox-notification.component';
import { LayoutModule } from './components/layout/layout.module';
import { AftersalesOverviewModule } from './pages/aftersales-overview/aftersales-overview.module';
import { CallMeBackModule } from './pages/call-me-back/call-me-back.module';
import { ContractSearchModule } from './pages/contract-search/contract-search.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { DeveloperModeModule } from './pages/developer-mode/developer-mode.module';
import { ForbiddenModule, PageNotFoundModule, UnauthorizedModule } from './pages/error';
import { InboxModule } from './pages/inbox/inbox.module';
import { InformationModule } from './pages/information';
import { InvoiceClarificationCancelModule } from './pages/invoice-clarification/cancel/cancel.module';
import { InvoiceClarificationConfirmationModule } from './pages/invoice-clarification/confirmation/confirmation.module';
import { InvoiceClarificationEditModule } from './pages/invoice-clarification/edit/edit.module';
import { InvoiceClarificationModule } from './pages/invoice-clarification/invoice-clarification.module';
import { LicenseModule } from './pages/license/license.module';
import { OpenProcessesModule } from './pages/open-processes/open-processes.module';
import { RequestDuplicatesModule } from './pages/request-duplicates/request-duplicates.module';
import { RequestCancelModule } from './pages/request/cancel/cancel.module';
import { RequestOnlineInvoiceModule } from './pages/request/online-invoice/online-invoice.module';
import { StatusDetailsDialogModule } from './pages/status-overview/status-details-dialog/status-details-dialog.module';
import { StatusOverviewModule } from './pages/status-overview/status-overview.module';
import { UserProfileModule } from './pages/user-profile/user-profile.module';
import { VehicleSelectionModule } from './pages/vehicle-selection/vehicle-selection.module';

const routerConfig: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  /**
   * To mimic the behavior of the MVC, we want to reload components when we navigate
   * to the same URL.
   */
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    MatSnackBarModule,

    // Core module of our application (Authorization, Configurations, Translations)
    CoreModule,

    // Layout modules
    LayoutModule,

    // Preload all application modules
    ContractSearchModule,
    StatusOverviewModule,
    UserProfileModule,
    AftersalesOverviewModule,
    CallMeBackModule,
    DashboardModule,
    InboxModule,
    InformationModule,
    InvoiceClarificationModule,
    InvoiceClarificationEditModule,
    InvoiceClarificationConfirmationModule,
    InvoiceClarificationCancelModule,
    RequestOnlineInvoiceModule,
    LicenseModule,
    OpenProcessesModule,
    RequestModule,
    RequestCancelModule,
    RequestDuplicatesModule,
    VehicleSelectionModule,
    UnauthorizedModule,
    PageNotFoundModule,
    DeveloperModeModule,
    ForbiddenModule,

    // Preload module that is shared through other modules
    StatusDetailsDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.production,
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  declarations: [AppComponent, InboxNotificationComponent],
  providers: [{ provide: ErrorHandler, useClass: AppErrorHandler }],
  bootstrap: [AppComponent]
})
export class AppModule {}
