export type VehicleTirePosition = 'front' | 'frontLeft' | 'frontRight' | 'rear' | 'rearLeft' | 'rearRight';
export const CarTirePositions: VehicleTirePosition[] = ['frontLeft', 'frontRight', 'rearLeft', 'rearRight'];
export const MotorcycleTirePositions: VehicleTirePosition[] = ['front', 'rear'];

export interface TireCommon {
  id: string;
  databaseId: number;
  companyCode: string;
  externalProductNumbers: ExternalProductNumberCommon[];
  activityTypeKey: string;
  type: string;
  brand: string;
  size: string;
  speedIndex: string;
  loadIndex: string;
  tireName: string;
  width: string;
  height: string;
  shape: string;
  diameter: string;
}

export interface ExternalProductNumberCommon {
  number: string;
  source: string;
  price?: number;
}

export type TireReplacements = {
  [position in VehicleTirePosition]: TireReplacement;
} & {
  tireTreadDepthMeasurementUnit: string;
  rear: TireReplacement;
  rearRight: TireReplacement;
  rearLeft: TireReplacement;
  front: TireReplacement;
  frontRight: TireReplacement;
  frontLeft: TireReplacement;
  frontLeftTreadDepthDisplayText: string;
  frontRightTreadDepthDisplayText: string;
  rearLeftTreadDepthDisplayText: string;
  rearRightTreadDepthDisplayText: string;
  rearTreadDepthDisplayText: string;
  frontTreadDepthDisplayText: string;
  isDualApplication: boolean;
  isTireDualApplicationEnabled: boolean;
};

export interface TireReplacement {
  isChecked: boolean;
  treadDepth: number | null;
}

export interface TireRequestExtensionData {
  tireTreadDepthMeasurementUnit: string;
  front: TireReplacement;
  frontLeft: TireReplacement;
  frontRight: TireReplacement;
  rear: TireReplacement;
  rearLeft: TireReplacement;
  rearRight: TireReplacement;
  isDualApplication: boolean;
  tire: TireCommon;
}

export interface TireSelection {
  tire: TireCommon;
  externalProductNumber: string;
  externalProductNumberSource: string;
  key: string;
  displayText: string;
}

export interface TirePriceCommon {
  price: number;
  validFrom: Date;
  validUntil: Date;
}
