import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { LocaleFormatService } from '@core/services';
import { ExternalApplicationAssignResult } from '@core/types';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { TranslocoService } from '@ngneat/transloco';
import { OfferPanelService } from '../offer-panel.service';
import { ApplicationAssignData, Offer, Variant } from '../offer-panel.types';
import { OfferDetailsDialogColumn } from './offer-details-dialog.types';

@Component({
  selector: 'offer-details-dialog',
  templateUrl: './offer-details-dialog.component.html',
  styleUrls: ['./offer-details-dialog.component.scss']
})
export class OfferDetailsDialogComponent implements OnInit {
  @Input() offer!: Offer;
  @Input() visibleDetailColumns: string[] = [];
  @Input() offers: Offer[] = [];
  @Input() responseGuid: string = '';

  public displayedColumns: string[] = [];
  public columns: OfferDetailsDialogColumn[] = [];
  public redirectionLoading: boolean = false;
  public errorList!: string[];
  public selectedVariantSalesProductCode: string = '';

  constructor(
    private offerPanelService: OfferPanelService,
    private localeFormatService: LocaleFormatService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.initializeColumns();
    this.sortVariants();
  }

  public applyOffer(offer: Offer) {
    const applicationRequest: ApplicationAssignData = {
      offerId: offer.id,
      responseGuid: this.responseGuid,
      variantId: this.selectedVariantSalesProductCode
    };

    this.errorList = [];
    this.redirectionLoading = true;

    this.offerPanelService.offerApplication(applicationRequest).subscribe({
      next: (result: ExternalApplicationAssignResult) => {
        this.redirectionLoading = false;
        if (!result.isValid) {
          this.errorList.push(
            result.errorMessage ?? this.translocoService.translate('OfferItemPanel_ErrorFor_ApplicationAssign')
          );
          return;
        }

        window.open(result.targetUri, '_blank');
      },
      error: (response: HttpErrorResponse) => {
        const validationReader = new ValidationReader(response.error);
        this.errorList = validationReader.values;
        this.redirectionLoading = false;
      }
    });
  }

  public getRowTitle(row: Variant) {
    let title = this.offer.label;
    if (row.configuration.productNameSubtitle) {
      title += ` ${row.configuration.productNameSubtitle}`;
    }

    return title.trim();
  }

  private initializeColumns() {
    this.columns = this.visibleDetailColumns.map((columnName: string) => {
      return {
        columnDef: columnName,
        header: this.getColumnHeader(columnName),
        cell: this.getCellValueFunction(columnName)
      };
    });
    this.displayedColumns = this.columns.map((column) => column.columnDef);
  }

  private getCellValueFunction(columnName: string): (element: Variant) => string {
    switch (columnName) {
      case 'DisplayProductType':
        return () => this.offer.productType ?? 'Extended Warranty';
      case 'ProductName':
        return () => this.offer.label;
      case 'ProductCode':
        return (element: Variant) => element.configuration.salesProductCode;
      case 'ProductNameSubtitle':
        return (element: Variant) => element.configuration.productNameSubtitle;
      case 'ProductVariant':
        return (element: Variant) => element.configuration.productVariant;
      case 'AgreedMileage':
        return (element: Variant) => this.getStringValue(element.configuration.agreedMileage.value?.value, 'km');
      case 'MileageUnit':
        return (element: Variant) => element.configuration.agreedMileage.unit;
      case 'Duration':
        return (element: Variant) => this.getStringValue(element.configuration.contractDuration.value?.value, 'months');
      case 'YearlyPremiumWithTax':
      case 'YearlyPremiumWithoutTax':
        return (element: Variant) =>
          this.getNumericStringValue(element.prices.yearlyRate.value?.value, element.prices.yearlyRate.unit || '€');
      case 'MonthlyPremiumWithTax':
        return (element: Variant) =>
          this.getNumericStringValue(element.prices.monthlyRate.value?.value, element.prices.monthlyRate.unit || '€');
      case 'ProductDescription':
        return (element: Variant) => element.configuration.shortDescription;
      default:
        return () => '';
    }
  }

  private getStringValue(value: number | undefined, suffix: string) {
    if (!value || value < 0) {
      return '-';
    }
    return `${value} ${suffix}`;
  }

  private getNumericStringValue(value: number | undefined, suffix: string) {
    if (!value || value < 0) {
      return '-';
    }
    return `${this.localeFormatService.formatNumericString(value.toString())} ${suffix}`;
  }

  private getColumnHeader(columnName: string) {
    return this.translocoService.translate(`OfferPanelComponent_VisibleColumnFor_${columnName}`);
  }

  private sortVariants() {
    this.offer.variants.sort((a: Variant, b: Variant) => {
      const monthlyRateA = a.prices.monthlyRate.value?.value ?? '-1';
      const monthlyRateB = b.prices.monthlyRate.value?.value ?? '-1';

      if (monthlyRateA == monthlyRateB) {
        return 0;
      }
      return monthlyRateA < monthlyRateB ? -1 : 1;
    });
  }
}
