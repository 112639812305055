import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { StatusDetailsDialogModule } from 'app/pages/status-overview/status-details-dialog/status-details-dialog.module';
import { InvoiceStateLabelModule } from '../invoice-state-label/invoice-state-label.module';
import { RequestStateLabelModule } from '../request-state-label/request-state-label.module';
import { OverviewPanelComponent } from './overview-panel/overview-panel.component';
import { OverviewComponent } from './overview.component';

@NgModule({
  imports: [
    InvoiceStateLabelModule,
    MatIconModule,
    MatFormFieldModule,
    MatTooltipModule,
    RequestStateLabelModule,
    MatExpansionModule,
    StatusDetailsDialogModule,
    PipesModule,
    SharedModule
  ],
  exports: [OverviewComponent, OverviewPanelComponent],
  declarations: [OverviewComponent, OverviewPanelComponent],
  providers: []
})
export class OverviewModule {}
