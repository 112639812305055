import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { PositionConfigurationService } from 'app/components/positions/positions.service';
import { Position } from './positions.types';

@Component({
  selector: 'positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class PositionsComponent implements OnInit {
  @Input() position!: Position;

  private _labelKeys = [
    'Quantity',
    'UnitPrice',
    'NetFixedPrice',
    'Discount',
    'Goodwill',
    'MaterialGoodwill',
    'NetAmount',
    'DamageType'
  ];
  acronym = '';
  abbreviation = '';
  labelKeysMap = new Map<string, string>();
  displayQuantityUnit = true;

  /**
   * Constructor
   */
  constructor(
    private _positionConfigurationService: PositionConfigurationService,
    private _translocoService: TranslocoService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.acronym = this.getAcronym();
    this.abbreviation = this.getAbbreviation();

    // Since there are resource keys for different itemTypes they are configured there dynamically
    // Usually they are combined like {templateName}_{labelKey} except DamageType on MobilityPosition
    this._labelKeys.forEach((labelKey) => {
      this.labelKeysMap.set(labelKey, `${this.position.templateName}_${labelKey}`);

      if (this.position.templateName === 'MobilityPositionViewModel' && labelKey === 'DamageType') {
        this.labelKeysMap.set(labelKey, 'Common_ReasonForMobility');
      }
    });

    // Do not show quantity unit for these item types
    this.displayQuantityUnit = !['MobilityPosition', 'MobilitySparePartPosition'].some(
      (itemType) => itemType === this.position.itemType
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private getAcronym() {
    // Usually these acronyms are hardcoded, but some are read from resources
    switch (this.position.itemType) {
      case 'MobilityPosition':
        return this._translocoService.translate('Common_MobilityPositionNumberAbbreviation');
      case 'MobilitySparePart':
        return this._translocoService.translate('Common_MobilitySparePartPositionNumberAbbreviation');
      case 'ServicePosition':
      case 'WarrantyPosition':
      case 'WarrantyDiagnosticPosition':
      case 'WorkPosition':
        return 'WPN';
      case 'SparePart':
      case 'TireSparePart':
      case 'WarrantySparePart':
        return 'SPN';
      case 'MaintenancePackagePosition':
        return this._translocoService.translate('PackagePositionViewModel_ExternalProductNumber');
      default:
        return '';
    }
  }

  private getAbbreviation() {
    // For the abbreviation usually resources are combined as Common_{ItemTypeName}Abbreviation
    let positionType: string;
    switch (this.position.itemType) {
      case 'ServicePosition':
        positionType = 'WorkPosition';
        break;
      case 'MaintenancePackagePosition':
        positionType = 'PackagePosition';
        break;
      default:
        positionType = this.position.itemType;
    }
    return this._translocoService.translate('Common_' + positionType + 'Abbreviation');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  isEnabled(position: Position, parameter: string) {
    return this._positionConfigurationService.isEnabled(
      position.itemType,
      position.productType,
      parameter,
      position.sourceApplication
    );
  }
}
