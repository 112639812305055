import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@core/modules';
import { WorkInstructionsComponent } from './work-instructions.component';

@NgModule({
  imports: [MatIconModule, MatButtonModule, SharedModule],
  exports: [WorkInstructionsComponent],
  declarations: [WorkInstructionsComponent],
  providers: []
})
export class WorkInstructionsModule {}
