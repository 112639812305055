<div id="PaymentChoose" *transloco="let t">
  <h1 class="ui-test--payment-choose-dialog-header-title" mat-dialog-title>{{ t('Common_PaymentChoose_Header') }}</h1>

  <div mat-dialog-content class="mat-typography">
    <div *ngIf="paymentChoose.mustSubtypeChoose">
      <h3 class="ui-test--payment-choose-dialog-header-chosen-subtype-text">
        {{ paymentChoose.chosenSubtypeDisplayText }}
      </h3>
      <mat-radio-group [value]="paymentChoose.chosenSubtype" (change)="paymentChoose.chosenSubtype = $event.value">
        <mat-radio-button
          *ngFor="let subType of paymentChoose.availableSubtypes; index as i"
          [class]="'ui-test--payment-choose-dialog-mat-radio-button-chosen-subtype-' + i"
          [value]="subType.subtype">
          {{subType.text }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <h3 class="ui-test--payment-choose-dialog-header-chosen-payment">
      {{ paymentChoose.chosenPaymentOptionDisplayText }}
    </h3>
    <mat-radio-group
      [value]="paymentChoose.chosenPaymentOption"
      (change)="paymentChoose.chosenPaymentOption = $event.value">
      <mat-radio-button
        *ngFor="let paymentOption of paymentChoose.availablePaymentOptions; index as i"
        [class]="'ui-test--payment-choose-dialog-mat-radio-button-payment-option-' + i"
        [value]="paymentOption.key">
        {{ paymentOption.text }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div mat-dialog-actions align="end">
    <button class="ui-test--payment-choose-dialog-button-close" mat-button mat-dialog-close cdkFocusInitial>
      {{ t('Common_Close') }}
    </button>
    <button
      class="ui-test--payment-choose-dialog-button-action"
      mat-button
      (click)="paymentChoose.action()"
      [disabled]="userService.isSendingRestricted | async"
      [mat-dialog-close]="true">
      {{ t('Common_PaymentChoose_ActionButton') }}
    </button>
  </div>
</div>
