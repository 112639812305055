import { Injectable } from '@angular/core';

/**
 * Custom Style Handling:
 * Because Angular adds component-styles once when initializing a component the first time, the print styles
 * are in the DOM forever. Angular GitHub Issue: https://github.com/angular/angular/issues/14238
 * This results in the print style always being loaded and it will prevent printing anything else.
 *
 * The workaround: We remove the style tag (the print styles) as soon as we destroy the print component
 * (when we close the Status Details dialog). When we open the Status Details dialog again, we add the styles
 * manually back to the DOM.
 *
 *
 * Custom Print-Component Movement:
 * Due to browser misbehavior, `Ctrl + P` (1) and `window.print()` (2) produce different outputs.
 * E.g., in Firefox, using (1) cuts of the second page, while (2) ruins the layout. In Chrome, (1) works fine
 * but (2) works fine, but with different font sizings.
 * This seems to be a result of us trying to print a deeply nested DOM element only and hiding everything else.
 * The solution: Move the Print-Element directly to the body and hide everything else.
 */
@Injectable({
  providedIn: 'root'
})
export class PrintService {
  private printStyleElement?: HTMLStyleElement;
  private printComponentElement?: Element;

  addPrintStyle() {
    if (!this.printStyleElement) {
      // first time open -> get the print style and save it
      const styleElements = document.getElementsByTagName('style');

      for (const styleElement of styleElements) {
        if (styleElement.innerText.includes('.unique-identifier-class-to-ensure-we-find-this-style')) {
          this.printStyleElement = styleElement;
          break;
        }
      }
      return;
    }

    // after the first time, add the print style to te DOM
    document.head.append(this.printStyleElement);
  }

  removePrintStyle() {
    if (this.printStyleElement) {
      document.head.removeChild(this.printStyleElement);
    }
  }

  movePrintComponentToBody() {
    this.printComponentElement = document.getElementsByTagName('status-details-print')[0];
    document.body.appendChild(this.printComponentElement);
  }

  removePrintComponent() {
    if (!this.printComponentElement) {
      return;
    }

    document.body.removeChild(this.printComponentElement);
    this.printComponentElement = undefined;
  }
}
