import { NgModule } from '@angular/core';
import { TranslocoHttpLoader } from '@core/transloco/transloco.http-loader';
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, TranslocoModule, translocoConfig } from '@ngneat/transloco';
import { environment } from 'environments/environment';

export const FALLBACK_LANGUAGE: string = 'en-GB';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      // Provide the default Transloco configuration
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        missingHandler: {
          allowEmpty: true
        },
        availableLangs: [
          // TODO: this needs to be loaded from the available languages setting
          {
            id: 'en-GB',
            label: 'English'
          },
          // we have to add de and de-DE because the defaultLang for germany and culture is set to de-DE, but the user setting list and resource provider has just "de". both work in the backend it seems.
          {
            id: 'de-DE',
            label: 'German'
          },
          {
            id: 'de',
            label: 'German'
          },
          {
            id: 'de-AT',
            label: 'German'
          },
          {
            id: 'es',
            label: 'Spanish'
          },
          {
            // we have to add es and es-ES because the defaultLang for spain and culture is set to es-ES, but the user setting list and resource provider has just "es". both work in the backend it seems.
            id: 'es-ES',
            label: 'Spanish'
          }
        ],
        defaultLang: 'en-GB',
        fallbackLang: FALLBACK_LANGUAGE,
        reRenderOnLangChange: true,
        prodMode: environment.production
      })
    },
    {
      // Provide the default Transloco loader
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoHttpLoader
    }
  ]
})
export class TranslocoCoreModule {}
