<div class="mb-15 flex-row justify-center content-stretch" *transloco="let t">
  <div class="flex-grow-60">
    <mat-card class="message-state info">
      <mat-card-content>
        <p>
          <strong class="ui-test--request-leasing-return-confirmation-strong-title">
            {{ t('Request_LeasingReturn_Confirmation_Header') }}
          </strong>
        </p>
        <p class="ui-test--request-leasing-return-confirmation-paragraph-info-1">
          {{ t('Request_LeasingReturn_Information1') }}
        </p>
        <p class="ui-test--request-leasing-return-confirmation-paragraph-info-2">
          {{ t('Request_LeasingReturn_Information2') }}
        </p>
        <p class="ui-test--request-leasing-return-confirmation-paragraph-question">
          {{ t('Request_LeasingReturn_Confirmation_Question') }}
        </p>
      </mat-card-content>
    </mat-card>

    <mat-radio-group
      color="primary"
      [(ngModel)]="requestContent.leasingReturnUserAnswer"
      (ngModelChange)="activateUnsavedChangesPopup()"
      class="flex-col gap-15">
      <mat-radio-button
        [value]="true"
        class="leasing-yes mr-20 ui-test--request-leasing-return-confirmation-mat-radio-button-yes">
        {{ t('Request_LeasingReturn_Confirmation_Yes') }}
      </mat-radio-button>
      <mat-radio-button
        [value]="false"
        class="leasing-no ui-test--request-leasing-return-confirmation-mat-radio-button-no">
        {{ t('Request_LeasingReturn_Confirmation_No') }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
