<ng-container *transloco="let t">
  <h5 class="list-group-item-heading-offer font-size-15 ui-test--offer-dashboard-header-title">{{ offer?.label }}</h5>
  <ul class="font-size-13" *ngFor="let detail of offerDetails; let i = index">
    <li [class]="'ui-test--offerDashboard-list-detail-' + i">{{ detail }}</li>
  </ul>
  <div class="text-align-center">
    <button class="ui-test--offer-dashboard-button-details" mat-raised-button (click)="showDetails()">
      <mat-icon class="ui-test--offer-dashboard-mat-icon-details">info</mat-icon>
      {{ t('OfferPanelComponents_Button_ShowDetails') }}
    </button>
  </div>
</ng-container>
