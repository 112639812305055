<div class="spinner" *ngIf="loading"></div>
<ng-container *ngIf="!loading && request">
  <div class="call-me-back" *transloco="let t">
    <page-header [title]="t('PageTitle_CallMeBack')"></page-header>
    <validation-summary [errorList]="errorList"></validation-summary>
    <mat-card class="main mb-40">
      <process-bar type="CallMeBack"></process-bar>
      <ng-container *ngIf="selectedStep === 'CollectData'">
        <work-instructions step="CallMeBack" currentProductType="CollectData" [texts]="2"></work-instructions>
        <mat-divider class="my-10"></mat-divider>
        <vehicle-information [vehicle]="request.vehicleInformation"></vehicle-information>

        <mat-divider class="my-10"></mat-divider>
        <file-upload
          [uploadUrl]="'api/callmeback/attachment/' + request.dealerId +  '/' + request.processId"
          [fileCategories]="request.fileUploadCategories"
          [acceptedFileTypes]="request.acceptedFiles"
          [existingFiles]="request.attachments"
          uploadInstructionInformationText1Key="CallMeBack_CollectData_UploadInstructionInformation_Text1"
          uploadInstructionInformationText2Key="CallMeBack_CollectData_UploadInstructionInformation_Text2"
          uploadInstructionInformationText3Key=""
          [maxFileSize]="10"
          [maxFileSizeSum]="10"
          [expandedByDefault]="true"></file-upload>

        <mat-divider class="my-10"></mat-divider>
        <important-information
          [(userNote)]="request.userNote"
          userNoteInfoTextKey="CallMeBack_CollectData_ImportantInformation_Text"></important-information>

        <mat-divider class="my-10"></mat-divider>
        <contact-data
          [contactData]="request.contactData"
          [showSecondaryEmail]="showSecondaryEmail"
          resourceKeyPrefix="CallMeBackContactDataViewModel"></contact-data>

        <mat-divider class="my-10"></mat-divider>
        <div class="flex justify-end">
          <button
            (click)="send()"
            class="continue-button ui-test--call-me-back-button-send"
            color="primary"
            mat-raised-button
            [class.button-spinner]="sending"
            [disabled]="sending"
            aria-label="send">
            <mat-icon class="ui-test--call-me-back-mat-icon-send">skip_next</mat-icon>
            <span class="ui-test--call-me-back-span-send">{{ t('CallMeBack_CollectData_Send') }}</span>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedStep === 'TransmissionReport'">
        <work-instructions
          class="ui-test--call-me-back-work-instructions-work-instructions"
          step="CallMeBack"
          currentProductType="TransmissionReport"
          [texts]="1"></work-instructions>
      </ng-container>
    </mat-card>
  </div>
</ng-container>
