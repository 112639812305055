<div class="work-instructions" *ngIf="showContent && workInstructionsResources && workInstructionsResources.length > 0">
  <blockquote class="flex-row justify-between content-start mt-20" *transloco="let t">
    <div>
      <h4 class="ui-test--work-instruction-header-work-instruction-headline">
        {{ t(step + '_' + currentProductType + '_WorkInstructions_Headline') }}
      </h4>
      <small
        *ngFor="let workInstructionsResource of workInstructionsResources, let i = index"
        [class]="'ui-test--work-instruction-small-work-instruction-' + i">
        {{ workInstructionsResource }}
      </small>
    </div>
    <div>
      <button class="ui-test--work-instruction-button-close" mat-icon-button (click)="showContent = false">
        <mat-icon class="ui-test--work-instruction-mat-icon-close">close</mat-icon>
      </button>
    </div>
  </blockquote>
</div>
