import { Inject, Injectable, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { ContextDataService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { filter, take } from 'rxjs';

/**
 * A page can configure its title by setting it to a resource key that we translate in here.
 *
 * Example:
 * {
 *   path: 'status-overview',
 *   loadChildren: () => ...,
 *   title: 'Menu_StatusOverview_Index'
 * }
 */
@Injectable()
export class TranslatedTitleStrategy extends TitleStrategy {
  private languageLoaded: boolean = false;
  constructor(
    private readonly title: Title,
    private translocoService: TranslocoService,
    @Inject(Injector) private injector: Injector
  ) {
    super();

    this.waitUntilLanguageLoaded();
  }

  /**
   * To avoid circular dependency we need to resolve via injector
   */
  private get contextDataService(): ContextDataService {
    return this.injector.get(ContextDataService);
  }

  /**
   * To avoid circular dependency we need to resolve via injector
   */
  private get router(): Router {
    return this.injector.get(Router);
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const translationKey = this.buildTitle(routerState);

    if (translationKey && this.languageLoaded) {
      this.setCustomTitle(translationKey);
    }
  }

  private setCustomTitle(translationKey: string) {
    this.translocoService.selectTranslate(translationKey).subscribe((translation) => {
      let title = 'iSport';
      const externalDealerNumber = this.contextDataService.data?.user?.dealer?.externalDealerNumber;
      if (externalDealerNumber) {
        // Add external dealer number to differentiate different tabs
        title += ` (${externalDealerNumber})`;
      }

      // Check if the translation found something. We do not want to show technical keys.
      if (translationKey != translation) {
        title += ` | ${translation}`;
      }

      this.title.setTitle(title);
    });
  }

  /**
   * Explanation:
   * If we do not wait until the first language is loaded, we encounter a race condition during initialization:
   * Angular triggers this title strategy and in here we call transloco. This can happen before the resources are loaded.
   * Then transloco itself tries to load its default language. Our backend might have no context at this point and
   * will return a 500. This results in transloco performing a fallback to the local resources (used for Error pages).
   * And after the initialization is done, we see only technical keys instead of translations.
   *
   * The solution is to wait until transloco has the first language loaded and the enable translation for the titles.
   */
  private waitUntilLanguageLoaded() {
    this.translocoService.events$
      .pipe(filter((e) => e.type === 'translationLoadSuccess'))
      .pipe(take(1))
      .subscribe(() => {
        this.languageLoaded = true;
        this.updateTitle(this.router.routerState.snapshot);
      });
  }
}
