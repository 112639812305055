import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';

import { InvoiceStateLabelComponent } from './invoice-state-label.component';

@NgModule({
  imports: [MatFormFieldModule, MatTooltipModule, PipesModule, SharedModule],
  exports: [InvoiceStateLabelComponent],
  declarations: [InvoiceStateLabelComponent],
  providers: []
})
export class InvoiceStateLabelModule {}
