import { Component, Input } from '@angular/core';
import { StatusDetails } from '../status-details-dialog.types';

@Component({
  selector: 'status-details-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss']
})
export class StatusDetailsDetailHeaderComponent {
  @Input() statusDetails!: StatusDetails;
}
