import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ConfigurationService, ContextDataService, LocaleFormatService } from '@core/services';
import { isNullUndefinedEmpty } from '@core/utils/helper-functions';
import { TranslocoService } from '@ngneat/transloco';
import { remove } from 'lodash';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { Answer, AnswerVisibility, Question, QuestionOptions } from '../../request.types';
import { UnsavedChangesStateStore } from '../../unsaved-changes-state-store.service';

@Component({
  selector: 'questionnaire-question',
  templateUrl: './question.component.html'
})
export class QuestionComponent implements OnInit, OnDestroy {
  @Input() groupId!: number;
  @Input() question!: Question;
  @Input() answerCollection!: Answer[];
  @Input() isPrimaryQuestionnaire!: boolean;
  @Input() hasMultipleAnswersInGroup!: boolean;

  public today: DateTime = DateTime.now();

  private controlSubscription$!: Subscription;
  sharedFormGroup!: FormGroup;
  questionTitle = '';
  configUrl!: string;
  backendValidationError: string | undefined = undefined;

  constructor(
    private configurationService: ConfigurationService,
    private translocoService: TranslocoService,
    private formGroupDirective: FormGroupDirective,
    private contextDataService: ContextDataService,
    private localeFormatService: LocaleFormatService,
    private unsavedChangesStateStore: UnsavedChangesStateStore
  ) {}

  ngOnInit(): void {
    this.formatQuestionData(true);

    this.questionTitle = this.translocoService.translate(
      'Request_Questionnaires_Question_' + this.question.questionTypeKey + '_' + this.question.questionKey
    );

    this.sharedFormGroup = this.formGroupDirective.form;

    this.sharedFormGroup.addControl(
      this.uniqueKey,
      new FormControl(
        { value: this.question.answer.value, disabled: this.question.options.isDisabled },
        this.getValidators(this.question.options)
      )
    );

    this.controlSubscription$ = this.sharedFormGroup.get(this.uniqueKey)!.valueChanges.subscribe((value) => {
      this.setValue(value);
    });

    this.checkForAdditionalInformation();
  }

  ngOnDestroy(): void {
    this.controlSubscription$?.unsubscribe();
    if (this.sharedFormGroup) {
      this.sharedFormGroup.removeControl(this.uniqueKey);
    }
  }

  private get uniqueKey() {
    return this.question.questionTypeKey + '_' + this.question.questionKey + '_' + this.groupId;
  }

  setBackEndValidationError(error: string | undefined): void {
    this.backendValidationError = error;
  }

  clearBackEndValidationError(): void {
    this.backendValidationError = undefined;
  }

  shouldQuestionBeDisplayed(): boolean {
    return (
      this.question.options.hidden !== true &&
      this.isVisible &&
      (this.isPrimaryQuestionnaire || !this.question.options.isDisplayOnceInGroup) &&
      (!this.hasMultipleAnswersInGroup || !this.question.isVisibilityTarget)
    );
  }

  setValidDateTime(evt: DateTime | null) {
    // Manual value entry into datetimepicker causes the selected time to reset to midnight.
    // For most questions time part of the select is also important.
    if (evt?.equals(evt.startOf('day'))) {
      const now = DateTime.now();
      const dateWithTime = evt.set({
        hour: now.hour,
        minute: now.minute,
        second: now.second,
        millisecond: now.millisecond
      });
      this.sharedFormGroup.controls[this.uniqueKey].setValue(dateWithTime);
    }
  }

  get isVisible() {
    const visibilityConfig = this.question.options.visibility;
    // If visibility is not configured then show input element by default
    if (!visibilityConfig || visibilityConfig.length == 0) {
      return true;
    }

    // If yes, then take visibility configuration and prepare target keys to compare
    const targetQuestionKeys: string[] = [];

    this.configureTargetQuestionKeys(visibilityConfig, targetQuestionKeys);

    // Take all related answers based on target keys
    const targetAnswers = this.answerCollection.filter((item) => {
      return targetQuestionKeys.includes(item.questionTypeKey + '_' + item.questionKey);
    });

    // If answers does not exist, do not show field
    if (!targetAnswers) {
      return false;
    }

    const result = visibilityConfig.every((statement) => {
      return statement.some((visibilityCondition) => {
        const targetExpectedValue = parseInt(visibilityCondition.targetExpectedValue);
        return this.checkVisibilityCondition(visibilityCondition, targetAnswers, targetExpectedValue);
      });
    });

    // Change visibility
    if (!result && this.question.options.dataType !== 'InfoText') {
      this.formGroupDirective.form.get(this.uniqueKey)?.setValue(null, { emitEvent: false });

      this.setValue(null);
    }

    return result;
  }

  get infoTextResourceByPreAllocationSourcePath(): string {
    const preAllocationSourcePath = this.question.options?.preAllocationSourcePath;
    return preAllocationSourcePath?.includes('Resource') ? preAllocationSourcePath.split(':')[1] : '';
  }

  dateTimePickerFilter = (d: DateTime | null): boolean => {
    let resultCondition = true;
    const now = DateTime.now();

    if (!this.question || !d) {
      return resultCondition;
    }

    if (this.question.options.isWeekendDaysSelectionDisabled) {
      // Prevent Saturday and Sunday from being selected.
      const weekDay = (d || now).weekday;
      resultCondition = weekDay !== 6 && weekDay !== 7;
    }
    if (this.question.options.isPastDateSelectionDisabled) {
      const todayMidnight = DateTime.utc().startOf('day');
      resultCondition &&= d > todayMidnight;
    }
    if (this.question.questionKey == 'InspektionDate' && now.hour >= 12) {
      resultCondition &&= d.startOf('day') > now.plus({ days: 1 }).startOf('day');
    }

    return resultCondition;
  };

  dateTimePickerFilterBounded = this.dateTimePickerFilter.bind(this);

  isVehicleDamageSelected(position: string) {
    // Check is it one of these selected
    // ["FrontLeft","MiddleLeft","RearLeft","FrontMiddle","RearMiddle","FrontRight","MiddleRight","RearRight","Hood","Windshield","RoofTop","RearWindow","Tailgate","SideMirrorLeft","SideMirrorRight","Body","Underbody"]
    return this.sharedFormGroup.get(this.uniqueKey)?.value?.indexOf(position) >= 0 ?? false;
  }

  onVehicleDamageSelection($event: MatCheckboxChange, position: string) {
    this.unsavedChangesStateStore.addEventMessage('Dirty');
    const answers = this.question.answer.value?.split(',') ?? [];

    if ($event.checked && !answers.includes(position)) {
      // Add to array if position is checked
      answers.push(position);
    } else if (!$event.checked && answers.includes(position)) {
      // Remove from array if position is unchecked
      remove(answers, (item) => {
        return item === position;
      });
    }

    // Save the value through shared method
    this.setValue(answers.join(','));
  }

  checkForAdditionalInformation() {
    if (
      this.question.options.additionalInformation &&
      this.question.options.additionalInformation.type === 'Config' &&
      this.question.options.additionalInformation.subType === 'Url'
    ) {
      if (this.question.options.additionalInformation.value === 'AutomotiveExpertOfficeListUrl') {
        this.configUrl = this.configurationService.automotiveExpertOfficeListUrl?.trim();
      }
    }
  }

  private checkMatchingQuestionKey(answer: Answer, visibilityCondition: AnswerVisibility) {
    return answer.questionTypeKey + '_' + answer.questionKey === visibilityCondition.targetQuestionKey;
  }

  private checkVisibilityCondition(
    visibilityCondition: AnswerVisibility,
    targetAnswers: Answer[],
    targetExpectedValue: number
  ) {
    let result = false;
    switch (visibilityCondition.compareType) {
      case 'Equals':
        result = targetAnswers.some((answer) => {
          return (
            this.checkMatchingQuestionKey(answer, visibilityCondition) &&
            answer.value === visibilityCondition.targetExpectedValue
          );
        });
        break;
      case 'NotEquals':
        if (!targetAnswers?.length) {
          return true;
        }
        result = targetAnswers.some((answer) => {
          return (
            this.checkMatchingQuestionKey(answer, visibilityCondition) &&
            (!answer.value || answer.value !== visibilityCondition.targetExpectedValue)
          );
        });
        break;
      case 'LessThan':
        result = targetAnswers.some((answer) => {
          return (
            this.checkMatchingQuestionKey(answer, visibilityCondition) &&
            this.getAnswerValue(answer) < targetExpectedValue
          );
        });
        break;
      case 'LessOrEqual':
        result = targetAnswers.some((answer) => {
          return (
            this.checkMatchingQuestionKey(answer, visibilityCondition) &&
            this.getAnswerValue(answer) <= targetExpectedValue
          );
        });
        break;
      case 'GreaterThan':
        result = targetAnswers.some((answer) => {
          return (
            this.checkMatchingQuestionKey(answer, visibilityCondition) &&
            this.getAnswerValue(answer) > targetExpectedValue
          );
        });
        break;
      case 'GreaterOrEqual':
        result = targetAnswers.some((answer) => {
          return (
            this.checkMatchingQuestionKey(answer, visibilityCondition) &&
            this.getAnswerValue(answer) >= targetExpectedValue
          );
        });
        break;
    }
    return result;
  }

  private configureTargetQuestionKeys(visibilityConfig: AnswerVisibility[][], targetQuestionKeys: string[]) {
    visibilityConfig.forEach(function (rule) {
      rule.forEach((item) => {
        if (!targetQuestionKeys.includes(item.targetQuestionKey)) {
          targetQuestionKeys.push(item.targetQuestionKey);
        }
      });
    });
  }

  /**
   * Format question values based on locale settings.
   */
  private formatQuestionData(allowUserLanguage: boolean = false) {
    if (this.question?.answer.hasValue && this.question.answer.value) {
      if (this.question.options.dataType == 'Numeric') {
        const language =
          (allowUserLanguage && this.contextDataService.data.languageInformation.userLanguage) ||
          this.contextDataService.data.languageInformation.defaultLanguage;
        this.question.answer.value = this.localeFormatService.formatNumericString(this.question.answer.value, {
          preferredLanguage: language,
          removeThousandsSeparator: true
        });
      } else if (this.question.options.dataType == 'DateTime' && !this.question.answer.value.includes('T')) {
        // Support for old iSport-MVC stored datetime formats
        const dt = DateTime.fromFormat(this.question.answer.value, 'dd.MM.yyyy', { zone: 'UTC' });
        this.question.answer.value = dt.toISO().toString();
      } else if (this.question.options.dataType == 'Time') {
        const dt = DateTime.fromISO(this.question.answer.value);
        this.question.answer.value = dt.toFormat('HH:mm');
      }
    }
  }

  private getAnswerValue(answer: Answer) {
    return parseInt(answer.value ?? '');
  }

  private setValue(value: string | null) {
    const isValidValue = !isNullUndefinedEmpty(value);

    let questionId = 0;
    if (this.question.databaseId > 0) {
      questionId = this.question.databaseId;
    }

    if (this.question.answer.questionId > 0) {
      questionId = this.question.answer.questionId;
    }

    const defaultValue = this.question.questionKey === 'VehicleDamageParts' ? undefined : '';
    this.question.answer.value = isValidValue ? value!.toString() : defaultValue;
    this.question.answer.hasValue = isValidValue;

    if (isValidValue) {
      if (questionId > 0) {
        // Try to search existing answer in collection
        const rootAnswer = this.answerCollection.find((rootAnswerElm) => {
          return rootAnswerElm.questionId === questionId && rootAnswerElm.groupId === this.groupId;
        });

        this.formatQuestionData();

        if (rootAnswer) {
          // Update existing answer in collection
          rootAnswer.value = this.question.answer.value;
          rootAnswer.hasValue = this.question.answer.hasValue;
        } else {
          // The answer does not exist in collection, so add it
          this.answerCollection.push(<Answer>{
            questionId: questionId,
            questionKey: this.question.questionKey ?? '',
            questionTypeKey: this.question.questionTypeKey ?? '',
            value: this.question.answer.value,
            hasValue: this.question.answer.hasValue,
            groupId: this.question.answer.groupId
          });
        }
      }
    } else {
      remove(this.answerCollection, (item) => {
        if (this.question.questionKey) {
          return (
            item.questionTypeKey === this.question.questionTypeKey &&
            item.questionId === questionId &&
            this.question.answer.groupId == item.groupId
          );
        }
        return false;
      });
    }
  }

  private getValidators(questionOptions: QuestionOptions) {
    const validators: ValidatorFn[] = [];

    if (questionOptions.maxLength > 0) {
      validators.push(Validators.maxLength(questionOptions.maxLength));
    }

    if (questionOptions.isMandatory) {
      validators.push(Validators.required);
    }

    return validators;
  }
}
