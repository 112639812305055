<mat-form-field class="w-full" *transloco="let t">
  <mat-label class="ui-test--input-with-validation-mat-label-mat-label-text">
    {{ t(resourceKeyPrefix + '_' + nameFirstLetterCapitalized) }}
  </mat-label>
  <input
    class="ui-test--input-with-validation-input-input-text"
    matInput
    [class]="name | className"
    [formControl]="control"
    [type]="type" />
  <mat-error class="ui-test--input-with-validation-mat-error-mat-error-text">
    {{ getErrorMessage() | hintReplacer:t(resourceKeyPrefix + '_' + nameFirstLetterCapitalized) }}
  </mat-error>

  <mat-hint class="ui-test--input-with-validation-mat-hint-mat-hint-text" *ngIf="showHint">
    {{ t(resourceKeyPrefix + '_' + hintResource) }}
  </mat-hint>
</mat-form-field>
