import { Injectable } from '@angular/core';
import { PwaCacheService } from './pwa-cache.service';
import { PwaPromptUpdateService } from './pwa-prompt-update.service';
import { PwaUpdateCheckService } from './pwa-update-check.service';

@Injectable({ providedIn: 'root' })
export class PwaService {
  constructor(
    private pwaUpdateCheckService: PwaUpdateCheckService,
    private pwaPromptUpdateService: PwaPromptUpdateService,
    private pwaCacheService: PwaCacheService
  ) {}

  initialize(): void {
    this.pwaCacheService.clearApiDataCache();
    this.pwaUpdateCheckService.checkForUpdates();
    this.pwaPromptUpdateService.subscribeToVersionUpdates();
  }
}
