import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getUrlParamsFromObject } from '@core/utils';
import { environment } from 'environments/environment';
import { InvoiceClarification, InvoiceClarificationConfirmation } from './edit.types';

@Injectable({
  providedIn: 'root'
})
export class InvoiceClarificationEditService {
  constructor(private httpClient: HttpClient) {}

  getInvoiceClarification(
    internalInvoiceNumber: string | null,
    productType: string | null,
    requestGuid: string | null
  ) {
    const urlParameters = getUrlParamsFromObject({
      internalInvoiceNumber: internalInvoiceNumber,
      productType: productType,
      requestGuid: requestGuid
    });

    return this.httpClient.get<InvoiceClarification>(
      `${environment.isportCoreApiUrl}/api/invoiceclarification?${urlParameters}`
    );
  }

  saveInvoiceClarification(invoiceClarification: InvoiceClarification) {
    return this.httpClient.put<InvoiceClarification>(
      `${environment.isportCoreApiUrl}/api/invoiceclarification`,
      invoiceClarification
    );
  }

  confirmInvoiceClarification(invoiceClarification: InvoiceClarification) {
    return this.httpClient.post<InvoiceClarificationConfirmation>(
      `${environment.isportCoreApiUrl}/api/invoiceclarification`,
      invoiceClarification
    );
  }
}
