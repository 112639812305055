import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogResult, AlertService, ConfigurationService, LocaleFormatService } from '@core/services';
import { ensureInclusiveBetween } from '@core/utils';
import { TranslocoService } from '@ngneat/transloco';
import { RequestContent } from '../../request.types';
import { UnsavedChangesStateStore } from '../../unsaved-changes-state-store.service';
import { MileageDialogComponent } from './mileage-dialog/mileage-dialog.component';

@Component({
  selector: 'request-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GeneralInformationComponent implements OnInit {
  @Input() requestContent!: RequestContent;
  @Input() isDisplayOnly: boolean = false;

  @Output() mileageUpdate: EventEmitter<number> = new EventEmitter<number>();
  @Output() deductibleAmountUpdate: EventEmitter<number> = new EventEmitter<number>();

  oldMileage!: number;
  get generalInformation() {
    return this.requestContent.requestProcess.generalInformation;
  }

  constructor(
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private alertService: AlertService,
    private numberFormatService: LocaleFormatService,
    private unsavedChangesStateService: UnsavedChangesStateStore,
    public configurationService: ConfigurationService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.oldMileage = this.requestContent.requestProcess.generalInformation.actualMileage;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get isDMS(): boolean {
    return this.requestContent.requestProcess.sourceApplication === 'DMS';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onDeductibleQuantityChange(value: string | number) {
    value = this.numberFormatService.formatNumeric(value.toString());
    this.generalInformation.deductibleQuantity = Math.floor(ensureInclusiveBetween(value, 0, 99999));
    this.deductibleAmountUpdate.emit();
  }

  activateUnsavedChangesPopup() {
    this.unsavedChangesStateService.addEventMessage('Dirty');
  }

  openEditDialog() {
    const updateMileageDialogRef = this.dialog.open(MileageDialogComponent, { width: '600px' });
    updateMileageDialogRef.componentInstance.mileage.setValue(this.generalInformation.actualMileage);

    updateMileageDialogRef.componentInstance.mileageUpdate.subscribe((mileage) => {
      this.mileageUpdate.emit(mileage);
    });
  }

  openInfoDialog() {
    this.alertService.open({
      message: this.translocoService.translate('Request_Mileage_UpdateDisabledInfo'),
      actions: {
        confirm: {
          label: this.translocoService.translate('Common_OK')
        }
      }
    });
  }

  openConfirmationDialog() {
    const confirmationDialogRef = this.alertService.open({
      title: this.translocoService.translate('Request_Edit_ContractSearchUpdateTitle'),
      message: this.translocoService.translate('Request_Edit_ContractSearchUpdateMessage'),
      icon: {
        show: true,
        name: 'info',
        color: 'primary'
      },
      actions: {
        confirm: {
          label: this.translocoService.translate('Common_Confirm'),
          icon: 'search'
        },
        cancel: {
          show: true,
          label: this.translocoService.translate('Common_Cancel')
        }
      }
    });

    confirmationDialogRef.afterClosed().subscribe((result: AlertDialogResult) => {
      if (result === 'confirmed') {
        this.mileageUpdate.emit(this.generalInformation.actualMileage);
      }
    });
  }
}
