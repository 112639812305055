import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../dashboard.service';
import { Contract, Dashboard, ProductType } from '../../dashboard.types';
import { SpecialAgreementDialogComponent } from '../../special-agreement-dialog';

@Component({
  selector: 'contract-information',
  templateUrl: './contract-information.component.html'
})
export class ContractInformationComponent {
  @Input() contract!: Contract;
  @Input() dashboardData!: Dashboard;

  productTypesFromProduct = DashboardService.productTypesFromProduct;

  constructor(public dialog: MatDialog, public dashboardService: DashboardService) {}

  startRequestForProductType(productType: ProductType): void {
    if (this.dashboardData.isReadOnly) {
      return;
    }

    this.dashboardService.getActionForProductType(productType);
  }

  showAdditionalAgreement() {
    const agreementDialogRef = this.dialog.open(SpecialAgreementDialogComponent, { width: '600px' });
    agreementDialogRef.componentInstance.agreementText = this.contract.additionalAgreement;
  }
}
