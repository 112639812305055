import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { DirectivesModule } from '@core/directives';
import { SharedModule } from '@core/modules';
import { PipesModule } from '@core/pipes';
import { MatDatepickerModule } from '@matheo/datepicker';
import { WorkInstructionsModule } from 'app/components/work-instructions/work-instructions.module';
import { InformationComponent } from './information/information.component';
import { QuestionComponent } from './question/question.component';
import { QuestionnaireComponent } from './questionnaire.component';

@NgModule({
  imports: [
    DirectivesModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatCardModule,
    MatDividerModule,
    PipesModule,
    WorkInstructionsModule,
    SharedModule
  ],
  exports: [QuestionnaireComponent],
  declarations: [QuestionnaireComponent, QuestionComponent, InformationComponent],
  providers: []
})
export class RequestQuestionnaireModule {}
