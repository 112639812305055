import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService, UserService } from '@core/services';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { ContactDataComponent } from 'app/components/contact-data/contact-data.component';
import { TotalAmounts } from 'app/components/request-total-amounts/request-total-amounts.types';
import { StatusDetailsDialogService } from 'app/pages/status-overview/status-details-dialog/status-details-dialog.service';
import { RequestService } from '../request.service';
import { RequestContent } from '../request.types';
import { ProcessBarComponent } from '../shared/process-bar/process-bar.component';
import { OnlineInvoiceConfirmation, RequestInvoiceSummary } from './online-invoice.types';

@Component({
  selector: 'request-online-invoice',
  templateUrl: './online-invoice.component.html',
  styleUrls: ['./online-invoice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RequestOnlineInvoiceComponent implements OnInit {
  @ViewChild(ProcessBarComponent) processBarComponent!: ProcessBarComponent;
  @ViewChild(ContactDataComponent) contactDataComponent!: ContactDataComponent;

  requestInvoiceSummary!: RequestInvoiceSummary;
  requestGuid!: string;
  totalAmounts!: TotalAmounts;
  showSecondaryEmail = this.configurationService.shouldSecondaryEmailAddressVisible;
  errorList: string[] = [];
  confirmActionLoading = false;

  constructor(
    private scroller: ViewportScroller,
    private route: ActivatedRoute,
    private configurationService: ConfigurationService,
    private requestService: RequestService,
    private statusDetailsDialogService: StatusDetailsDialogService,
    public userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.requestGuid = this.route.snapshot.queryParamMap.get('requestGuid') ?? '';
    this.getRequestInvoiceData(this.requestGuid);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get requestContent(): RequestContent {
    return this.requestInvoiceSummary.requestContent;
  }

  get selectedStep(): string {
    return this.processBarComponent?.steps?.find((step) => step.isActive === true)?.key ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  openStatusDetailsDialog(event: Event): void {
    event.stopPropagation();

    this.statusDetailsDialogService.openDialog(
      this.requestContent.versionId,
      this.requestContent.requestProcess.productType,
      'readonly'
    );
  }

  continue(): void {
    if (!this.validateAndSave()) {
      return;
    }

    this.confirmActionLoading = true;
    this.errorList = [];

    this.requestService.confirmRequestInvoice(this.requestInvoiceSummary).subscribe({
      next: (confirmation: OnlineInvoiceConfirmation) => {
        if (confirmation.requestGuid) {
          this.requestGuid = confirmation.requestGuid;
          this.processBarComponent.nextStep();
        }

        this.confirmActionLoading = false;
      },
      error: (res: HttpErrorResponse) => {
        this.confirmActionLoading = false;
        if (res.status !== HttpStatusCode.BadRequest) {
          return;
        }

        const validationReader = new ValidationReader(res.error);
        this.errorList = validationReader.values;
        this.scroller.scrollToPosition([0, 0]);
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private getRequestInvoiceData(requestGuid: string): void {
    this.requestService.getRequestInvoiceData(requestGuid).subscribe({
      next: (res: RequestInvoiceSummary) => {
        if (res.isOnlineInvoiceRequested) {
          this.requestGuid = res.requestContent.versionId;
          this.processBarComponent.nextStep();
        }

        this.requestInvoiceSummary = res;

        this.setupTotalAmounts();
      }
    });
  }

  private setupTotalAmounts(): void {
    this.totalAmounts = <TotalAmounts>{
      totalNetAmount: this.requestInvoiceSummary.totalNetAmount,
      totalGoodwill: this.requestInvoiceSummary.totalGoodwill,
      expectedPayout: this.requestInvoiceSummary.expectedPayout,
      expectedCustomerShare: this.requestContent.expectedCustomerShare,
      isDisplayExpectedPayoutValue: true,
      isDisplayTotalGoodwillValue: this.requestContent.isDisplayTotalGoodwillValue,
      isDisplayPayableByCustomer: this.requestContent.isDisplayPayableByCustomer,
      displayCurrencyUnit: this.requestContent.requestProcess.displayCurrencyUnit
    };
  }

  private validateAndSave(): boolean {
    if (this.requestContent.requestEditOptions !== 'Limited' && !this.contactDataComponent?.contactDataForm.valid) {
      return false;
    }

    const formData = this.contactDataComponent.contactDataForm.value;

    // If contact data is valid save the values into global object
    if (formData.phoneNumber) {
      this.requestContent.contactData.phoneNumber = formData.phoneNumber;
    }
    if (formData.secondaryEmail) {
      this.requestContent.contactData.secondaryEmail = formData.secondaryEmail;
    }

    return true;
  }
}
